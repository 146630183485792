/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";

const WizardStep = ({ name, step, onClick, isActive }) => {
  return (
    <>
      <li
        onClick={() => onClick(step)}
        className={`output-basic-information-indicator ${
          isActive && "active-step"
        }`}>
        <span>{step + 1}</span>
        <p>{name}</p>
      </li>

      <hr className="second-line" />
    </>
  );
};

export default WizardStep;
