import './pagination.scss';
import React, { Component } from 'react';
import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
} from '@material-ui/icons';
import { SHOWING } from '../../../helpers/constants';
import { getLabelByName } from '../../../helpers/helperFunctions';

export default class Pagination extends Component {
  state = {
    pages: null,
    currentPage: 1,
    options: [],
    selectedOption: 1,
  };

  componentDidMount() {
    const { setDisplayedData, data, dataLimit } =
      this.props;

    const setPagesNumber = Math.round(
      data?.length / dataLimit,
    );

    setDisplayedData(this.getPaginatedData());

    this.setState({
      options: this.getPaginationGroup(),
      pages: setPagesNumber,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { setDisplayedData, setTriggerPagination } =
      this.props;

    const { currentPage } = this.state;

    if (prevState.currentPage !== currentPage) {
      setDisplayedData(this.getPaginatedData());
      setTriggerPagination();
    }
  }

  gotToFirstPage = () => {
    this.setState({
      currentPage: 1,
      selectedOption: 1,
    });
  };

  gotToLastPage = () => {
    const { pages } = this.state;

    this.setState({
      currentPage: pages,
      selectedOption: pages,
    });
  };

  goToNextPage = () => {
    const { currentPage } = this.state;

    this.setState({ currentPage: currentPage + 1 });

    this.setState({
      selectedOption: currentPage + 1,
    });
  };

  goToPreviousPage = () => {
    const { currentPage } = this.state;

    this.setState({ currentPage: currentPage - 1 });

    this.setState({
      selectedOption: currentPage - 1,
    });
  };

  changePage = (event) => {
    const pageNumber = Number(event.target.value);

    this.setState({
      currentPage: pageNumber,
      selectedOption: pageNumber,
    });
  };

  getPaginatedData = () => {
    const { currentPage } = this.state;

    const { dataLimit, data } = this.props;

    const startIndex = currentPage * dataLimit - dataLimit;

    const endIndex = startIndex + dataLimit;

    return data?.slice(startIndex, endIndex);
  };

  getPaginationGroup = () => {
    const { currentPage } = this.state;

    const { pageLimit } = this.props;

    const start =
      Math.floor((currentPage - 1) / pageLimit) * pageLimit;

    return new Array(pageLimit)
      .fill()
      .map((_, idx) => start + idx + 1);
  };

  render() {
    const { currentPage, pages, options, selectedOption } =
      this.state;

    const { displayedData, data, isClubMembers } =
      this.props;

    return (
      <div className="pagination">
        <div className="items-per-page">
          {(isClubMembers && (
            <p>
              {`${getLabelByName(
                SHOWING,
              )} ${selectedOption} - ${
                displayedData?.length
              } of ${data.length}`}
            </p>
          )) || (
            <p>
              {`${getLabelByName(
                SHOWING,
              )} ${selectedOption} - ${
                displayedData?.length
              } of ${data.length}`}
            </p>
          )}
        </div>
        <div className="navigate-buttons">
          {/* last page button */}
          <button
            type="button"
            onClick={this.gotToFirstPage}
            disabled={currentPage === 1}
            className={`first ${
              currentPage === 1 ? 'disabled' : ''
            }`}>
            <FirstPageIcon className="navigate-button" />
          </button>
          {/* previous button */}
          <button
            type="button"
            onClick={this.goToPreviousPage}
            disabled={currentPage === 1}
            className={`prev ${
              currentPage === 1 ? 'disabled' : ''
            }`}>
            <NavigateBeforeIcon className="navigate-button" />
          </button>

          {/* show page numbers */}

          <select
            onChange={this.changePage}
            value={selectedOption}>
            {options.slice(0, pages).map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          {/* next button */}
          <button
            type="button"
            onClick={this.goToNextPage}
            disabled={currentPage === pages}
            className={`next ${
              currentPage === pages ? 'disabled' : ''
            }`}>
            <NavigateNextIcon className="navigate-button" />
          </button>

          {/* last page button */}
          <button
            type="button"
            onClick={this.gotToLastPage}
            disabled={currentPage === pages}
            className={`last ${
              currentPage === pages ? 'disabled' : ''
            }`}>
            <LastPageIcon className="navigate-button" />
          </button>
        </div>
      </div>
    );
  }
}
