import React from 'react';
import apiCall from '../../../helpers/apiCall';

import { CLUBS_CRUD, CLUBS_SEARCH } from '../types';
import { getUserAuthHeaders } from '../users/functions/session';

export const createClub = (club) => (dispatch) => {
    const apiEndpoint = "/club/new";

    apiCall.post(
        apiEndpoint,
        club,
        {
            headers: getUserAuthHeaders(
                apiEndpoint,
                club,
            )
        }
    ).then((response) => {
        dispatch({
            type: CLUBS_CRUD,
            payload: response.data,
        });
    }).catch((err) => {
        dispatch({
            type: CLUBS_CRUD,
            payload: err
        })
    })
}

export const retrieveClub = (clubId) => (dispatch) => {
    const apiEndpoint = `/club/${clubId}`;

    apiCall.get(
        apiEndpoint,
        {
            headers: getUserAuthHeaders(
                apiEndpoint
            )
        }
    ).then((response) => {
        dispatch({
            type: CLUBS_CRUD,
            payload: response.data,
        });
    }).catch((err) => {
        dispatch({
            type: CLUBS_CRUD,
            payload: err
        })
    })
}

export const updateClub = (clubId, club) => (dispatch) => {
    const apiEndpoint = `/club/${clubId}`;

    apiCall.put(
        apiEndpoint,
        club,
        {
            headers: getUserAuthHeaders(
                apiEndpoint,
                club,
            )
        }
    ).then((response) => {
        dispatch({
            type: CLUBS_CRUD,
            payload: response.data,
        });
    }).catch((err) => {
        dispatch({
            type: CLUBS_CRUD,
            payload: err
        })
    })
}

export const deleteClub = (clubId) => (dispatch) => {
    const apiEndpoint = `/club/${clubId}`;

    apiCall.delete(
        apiEndpoint,
        {
            headers: getUserAuthHeaders(
                apiEndpoint
            )
        }
    ).then((response) => {
        dispatch({
            type: CLUBS_CRUD,
            payload: response.data,
        });
    }).catch((err) => {
        dispatch({
            type: CLUBS_CRUD,
            payload: err
        })
    })
}

export const searchClubs = (query = null) => (dispatch) => {
    let apiEndpoint = `/clubs`;
    if (query !== null) {
        apiEndpoint += `?q=${JSON.stringify(query)}`;
    }

    apiCall.get(
        apiEndpoint,
        {
            headers: getUserAuthHeaders(
                apiEndpoint
            )
        }
    ).then((response) => {
        dispatch({
            type: CLUBS_SEARCH,
            payload: response.data,
        });
    }).catch((err) => {
        dispatch({
            type: CLUBS_SEARCH,
            payload: err
        })
    })
}

export const searchAdminClubs = (query = null) => (dispatch) => {
    let apiEndpoint = `/admin/clubs`;
    if (query !== null) {
        apiEndpoint += `?q=${JSON.stringify(query)}`;
    }

    apiCall.get(
        apiEndpoint,
        {
            headers: getUserAuthHeaders(
                apiEndpoint
            )
        }
    ).then((response) => {
        dispatch({
            type: CLUBS_SEARCH,
            payload: response.data,
        });
    }).catch((err) => {
        dispatch({
            type: CLUBS_SEARCH,
            payload: err
        })
    })
}