import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  withRouter,
  useHistory,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  Button,
  MenuItem,
} from "@material-ui/core";
import { toast } from "react-toastify";
import {
  getAllClubsFromLocalStorage,
  getLabelByName,
  isActionSuccess,
} from "../../helpers/helperFunctions";
import FormStyles from "./questionnaires.styles";
import {
  CANCEL,
  CLUB,
  SAVE,
  SAVING,
} from "../../helpers/constants";
import {
  cloneQuestionnaire,
  createQuestionnaire,
} from "../../redux/actions/adminActions";

const CloneQuestionnairesModal = ({
  isCreateModalVisible,
  cloneActivity,
  closeModal,
  cloneQuestionnaire,
  cloneQuestionnaireData,
  cloneQuestionnaireDataError,
  cloneQuestionnaireStatus,
}) => {
  const [clubsData] = useState(
    getAllClubsFromLocalStorage(),
  );

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [clubId, setClubId] = useState(null);
  const [
    isClubFromQuestionnaire,
    setIsClubFromQuestionnaire,
  ] = useState(false);
  const [clubHandle, setClubHandle] = useState(null);

  const classes = FormStyles();
  const mobile = useMediaQuery("(max-width:768px)");
  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    if (cloneActivity !== null) {
      setName(`${cloneActivity.name} copy`);
      setDescription(cloneActivity.description);
      setClubId(cloneActivity?.parentId);
    }
  }, [cloneActivity]);

  useEffect(() => {
    if (location?.state?.clubId) {
      setClubId(location?.state?.clubId);
      setIsClubFromQuestionnaire(true);
      setClubHandle(location?.state?.handle);
    }
  }, [location]);

  useEffect(() => {
    const props = {
      cloneQuestionnaireStatus,
      cloneQuestionnaireData,
    };

    if (
      isActionSuccess(
        props,
        "cloneQuestionnaireStatus",
        "cloneQuestionnaireData",
      )
    ) {
      setIsLoading(false);

      toast.success(
        `${cloneActivity?.name} cloned successfully.`,
      );

      closeModal();

      const { id: questionnaireId, parentId } =
        cloneQuestionnaireData.data;

      history.push(
        `/manage/questionnaires/club/${parentId}/questionnaire/${questionnaireId}/edit`,
        { isClubFromQuestionnaire, clubHandle },
      );
    }

    if (cloneQuestionnaireDataError === "error") {
      toast.error(
        "Questionnaire was not cloned successfully",
      );
    }
  }, [
    cloneQuestionnaireStatus,
    cloneQuestionnaireData,
    cloneQuestionnaireDataError,
  ]);

  const clubsOptions = clubsData
    ? clubsData.map((club) => ({
        value: club.id,
        text: club.name,
      }))
    : [];

  const Options = clubsOptions.map((club) => {
    return (
      <MenuItem value={club.value}>{club.text}</MenuItem>
    );
  });

  const handleChange = (e) => {
    const { name: inputName, value } = e.target;

    if (inputName === "name") {
      setName(value);
    }

    if (inputName === "description") {
      setDescription(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const payload = {
      name,
      description,
      parentId: clubId,
      actions: {},
      meta: {},
    };

    const questionnaireId = cloneActivity.id;

    await cloneQuestionnaire(
      cloneActivity.parentId,
      questionnaireId,
      payload,
    );
  };

  return (
    <Modal
      size="lg"
      show={isCreateModalVisible}
      onHide={closeModal}>
      <div>
        <div
          style={{
            padding: mobile
              ? "1rem 1rem 0 1rem"
              : "1rem 2rem 0 2rem",
            font: ".8rem",
          }}>
          <div>
            <h2>Copy of {`"${cloneActivity?.name}"`}</h2>
            <p>
              Manage the information collected from people
              through this cloned questionnaire
            </p>
          </div>
        </div>

        <div
          style={{
            padding: mobile ? "0 1rem" : "0 2rem",
          }}>
          <form
            id="form"
            className={classes.form}
            onSubmit={(e) => handleSubmit(e)}>
            <FormControl
              className={
                mobile ? classes.input : classes.inputLeft50
              }
              variant="outlined">
              <TextField
                id="name"
                label="Form Title"
                name="name"
                variant="outlined"
                onChange={handleChange}
                value={name}
                required
              />
            </FormControl>

            <FormControl
              className={
                mobile
                  ? classes.input
                  : classes.inputRight50
              }
              fullWidth
              variant="outlined">
              <InputLabel id="club">
                {getLabelByName(CLUB)}
              </InputLabel>

              <Select
                labelId="club"
                id="type"
                onChange={(e) =>
                  // eslint-disable-next-line radix
                  setClubId(parseInt(e.target.value))
                }
                value={clubId}
                required
                label="Club">
                {Options}
              </Select>
            </FormControl>

            <FormControl
              className={classes.input}
              variant="outlined">
              <TextField
                id="description"
                label="Description"
                name="description"
                multiline
                margin="normal"
                rows={10}
                variant="outlined"
                onChange={handleChange}
                required
                value={description}
              />
            </FormControl>
          </form>
        </div>

        <div className={classes.footerWrapper}>
          <Modal.Footer className={classes.footer}>
            <Button
              className={classes.button}
              color="primary"
              onClick={closeModal}>
              {getLabelByName(CANCEL)}
            </Button>

            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              form="form"
              type="submit">
              {isLoading
                ? `${getLabelByName(SAVING)}...`
                : `${getLabelByName(SAVE)}`}
            </Button>
          </Modal.Footer>
        </div>
      </div>
    </Modal>
  );
};

export const mapStateToProps = (state) => ({
  cloneQuestionnaireData:
    state.cloneQuestionnaireReducer.cloneQuestionnaireData,
  cloneQuestionnaireDataError:
    state.cloneQuestionnaireReducer
      .cloneQuestionnaireDataError,
  cloneQuestionnaireStatus:
    state.cloneQuestionnaireReducer
      .cloneQuestionnaireStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    createQuestionnaire,
    cloneQuestionnaire,
  }),
)(CloneQuestionnairesModal);
