import {
  GET_ACTION_SUCCESS, GET_ACTION_ERROR,
} from '../actions/types';

const initialState = {
  getActionData: null,
  getActionDataError: null,
  getActionStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTION_SUCCESS:
      return {
        ...state,
        getActionData: action.payload,
        getActionDataError: null,
        getActionStatus: 'success',
      };
    case GET_ACTION_ERROR:
      return {
        ...state,
        getActionDataError: action.payload,
        getActionData: null,
        getActionStatus: 'error',
      };
    default:
      return {
        ...state,
        getActionStatus: null,
      };
  }
};
