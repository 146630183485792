/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable global-require */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { getLabelByName } from "../../helpers/helperFunctions";
import {
  CANCEL,
  LOGIN,
  LOGIN_TO_CONTINUE,
} from "../../helpers/constants";

class LoginModal extends Component {
  render() {
    const { isModalOpen, toggleModal } = this.props;

    return (
      <Modal
        size="md"
        show={isModalOpen}
        onHide={toggleModal}
        className="behalf-modal">
        <div className="create-club-header">
          <div className="header-left">
            <p className="header-modal-title">
              {getLabelByName(LOGIN_TO_CONTINUE)}
            </p>
          </div>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            style={{ marginTop: "-5rem", outline: "none" }}
            onClick={() => toggleModal()}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="row d-flex justify-content-around create-club-buttons">
          <>
            <Link
              to={`/login?redirect_to=${window.location.pathname}`}>
              <button
                type="button"
                className="btn btn-lg submit-btn">
                {getLabelByName(LOGIN)}
              </button>
            </Link>
            <button
              type="button"
              className="btn btn-lg submit-btn btn-reverse"
              onClick={() => toggleModal()}>
              {getLabelByName(CANCEL)}
            </button>
          </>
        </div>
      </Modal>
    );
  }
}

export default LoginModal;
