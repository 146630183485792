import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import "./clubContentNav.scss";
import { compose } from "redux";
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@material-ui/icons";

const getPathname = (params) => {
  const { id: clubId, handle: clubHandle } = params;

  return `/manage/clubs/${clubId}/${clubHandle}/content`;
};

export class ClubContentNav extends Component {
  state = {
    showMenu: false,
    navItems: [
      {
        title: "Posts",
        link: `${getPathname(
          this.props.match.params,
        )}/posts`,
      },
      {
        title: "Articles",
        link: `${getPathname(
          this.props.match.params,
        )}/articles`,
      },
      {
        title: "Lessons",
        link: `${getPathname(
          this.props.match.params,
        )}/lessons`,
      },
    ],
  };

  toggleMenu = () =>
    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }));

  render() {
    const { navItems, showMenu } = this.state;
    const { category } = this.props.match.params;

    const navItem = navItems.find(
      (item) => item.title.toLowerCase() === category,
    );

    return (
      <div className="nav-bar">
        <div className="responsive-nav title-responsiveness">
          <div
            aria-hidden
            className="current-entity"
            onClick={() => this.toggleMenu()}>
            {navItem.title}
            {showMenu ? (
              <KeyboardArrowUpRounded className="ml-3" />
            ) : (
              <KeyboardArrowDownRounded className="ml-3" />
            )}
          </div>
        </div>

        <div className="web-content-container-fluid-responsiveness">
          {showMenu &&
            navItems.map((item) => (
              <Link
                to={item.link}
                className={`nav-link web-content-side-nav-item ${
                  category === item.title.toLowerCase()
                    ? "web-content-nav-item__active"
                    : ""
                }`}
                onClick={() =>
                  showMenu ? this.toggleMenu() : {}
                }>
                {item.title}
              </Link>
            ))}
        </div>

        <div className="web-content-container-fluid">
          {" "}
          {navItems.map((item) => (
            <Link
              key={navItems.indexOf(item)}
              to={item.link}
              className={`nav-link web-content-side-nav-item ${
                category === item.title.toLowerCase()
                  ? "web-content-nav-item__active"
                  : ""
              }`}
              onClick={() =>
                showMenu ? this.toggleMenu() : {}
              }>
              <div> {item.title} </div>{" "}
            </Link>
          ))}{" "}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(ClubContentNav);
