/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import SuccessMessage from "./SuccessMessage";
import CustomCheckbox from "./CustomCheckbox";

import "./exportActionModal.scss";
import ErrorMessage from "./ErrorMessage";

const ExportActionModal = ({
  isOpen,
  onClose,
  actions,
  exportActivities,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [actionIds, setActionId] = useState([]);
  const [exportFormat, setExportFormat] = useState("csv");
  const [error, setError] = useState("");

  const params = useParams();

  const handleChange = (e, id) => {
    const { checked } = e.target;

    if (checked) {
      setActionId([...actionIds, id]);
    } else {
      setActionId(
        actionIds.filter((actionId) => actionId !== id),
      );
    }
  };

  const handleSelectExportFormat = (e) => {
    const format = e.target.name;

    setExportFormat(format);
  };

  const handleSubmit = async () => {
    if (actionIds.length === 0) {
      return toast.error(
        "Please select at least one action.",
      );
    }

    const payload = {
      activityId: params?.id,
      actions: actionIds.map((id) => ({
        id,
        fields: [],
      })),
      format: exportFormat,
    };

    const response = await exportActivities(payload);

    if (response.status === 200) {
      setIsSuccess(true);
    } else {
      setError(
        response?.error?.message || "An error occured.",
      );
    }
  };

  return (
    <Modal
      size="lg"
      show={isOpen}
      onHide={onClose}
      dialogClassName={
        isSuccess
          ? "success-modal-dialog modal-max-width"
          : error
          ? "error-modal-dialog modal-max-width"
          : "modal-max-width"
      }>
      <h3
        className={isSuccess ? "title mb-5" : "title mb-3"}>
        Export Actions
      </h3>

      {isSuccess ? (
        <SuccessMessage />
      ) : error ? (
        <ErrorMessage />
      ) : (
        <div className="export-modal-body">
          <p>
            Follow these simple steps to export actions for
            this Activity.
          </p>

          <div className="steps">
            <div className="step one">
              <header className="mb-2">
                Step One: Select actions to export
              </header>

              <div className="body mb-5 pl-3 pr-1 py-3">
                {actions?.map((action, idx) => (
                  <div
                    key={action?.id}
                    className="d-flex mb-3">
                    <p className="mb-0">Step {idx + 1}:</p>
                    <div className="d-flex justify-content-between flex-grow-1 ml-3">
                      <p className="mb-0">
                        {action?.name}
                        <br />
                        <span className="font-small">
                          {action?.profile?.description}
                        </span>
                      </p>

                      <CustomCheckbox
                        actionId={action?.id}
                        className="d-block"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="step two mb-5">
              <header className="mb-2">
                Step Two: Choose data export option
              </header>

              <div className="ml-3">
                <p className="mb-2">
                  <span />
                  <span>Export as:</span>
                </p>

                <CustomCheckbox
                  CustomCheckbox
                  name="csv"
                  checked={exportFormat === "csv"}
                  onChange={handleSelectExportFormat}>
                  CSV
                </CustomCheckbox>

                <CustomCheckbox
                  name="json"
                  checked={exportFormat === "json"}
                  onChange={handleSelectExportFormat}>
                  JSON
                </CustomCheckbox>
              </div>
            </div>
          </div>

          <div className="modal__footer d-flex align-center justify-content-end">
            <button
              type="button"
              onClick={onClose}
              className="btn-cancel">
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="btn-done"
              disabled={isLoading}>
              Done
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ExportActionModal;
