import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Clear } from '@material-ui/icons';
import { getLabelByName } from '../../helpers/helperFunctions';
import searchOrange from '../../assets/icons/search-orange.svg';
import { SEARCH } from '../../helpers/constants';

class SearchComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      resourceType, searchInput, handleChange, clearSearch, submitSearch,
    } = this.props;

    return (
      <div id="search-component" className="row mt-5">
        <form className="form" onSubmit={submitSearch} style={{ width: '100%' }}>
          <div className="input-group mb-2 col-md-12">
            <input
              type="text"
              className="form-control"
              id="inputSearch"
              placeholder={`${getLabelByName(SEARCH)} ${getLabelByName(resourceType)}`}
              name="searchInput"
              value={searchInput}
              onChange={handleChange}
            />
            {searchInput && (
              <div className="input-group-append">
                <button
                  id="clear-search-btn"
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={clearSearch}
                >
                  <Clear />
                </button>
              </div>
            )}
            <div className="input-group-append">
              <button
                id="search-btn"
                className="btn btn-outline-secondary"
                type="submit"
              >
                <img src={searchOrange} alt="search-icon" style={{ width: '20px' }} />
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

SearchComponent.propTypes = {
  resourceType: PropTypes.any,
  searchInput: PropTypes.any,
  handleChange: PropTypes.func,
  clearSearch: PropTypes.func,
  submitSearch: PropTypes.func,
};

export default SearchComponent;
