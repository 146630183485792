import {
  GET_CONTRIBUTIONS_SUCCESS,
  GET_CONTRIBUTIONS_ERROR,
  SUBMIT_CONTRIBUTION_SUCCESS,
  SUBMIT_CONTRIBUTION_ERROR,
  GET_OUTPUTS_SUCCESS,
  GET_OUTPUTS_ERROR,
  GET_OUTPUT_SUCCESS,
  GET_OUTPUT_ERROR,
  UPDATE_CONTRIBUTION_SUCCESS,
  UPDATE_CONTRIBUTION_ERROR,
  CREATE_OUTPUT_SUCCESS,
  CREATE_OUTPUT_ERROR,
  UPDATE_OUTPUT_SUCCESS,
  UPDATE_OUTPUT_ERROR,
  POST_CONTRIBUTIONS_COMMENTS_SUCCESS,
  POST_CONTRIBUTIONS_COMMENTS_ERROR,
  GET_CONTRIBUTIONS_COMMENTS_SUCCESS,
  GET_CONTRIBUTIONS_COMMENTS_ERROR,
  CLONE_OUTPUT_SUCCESS,
  CLONE_OUTPUT_ERROR,
  INITIATE_PAYMENT_SUCCESS,
  INITIATE_PAYMENT_ERROR,
} from "./types";
import apiCall from "../../helpers/apiCall";
import {
  getGuestHeaders,
  getHeaders,
} from "../../helpers/getHeaders";

const contributionType = (type, payload) => ({
  type,
  payload,
});

export const getContributions =
  (
    query,
    headers = getHeaders(
      null,
      `/contributions${query || ""}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/contributions${query || ""}`, { headers })
      .then((response) => {
        dispatch(
          contributionType(
            GET_CONTRIBUTIONS_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          contributionType(GET_CONTRIBUTIONS_ERROR, err),
        );
      });
  };

export const submitContribution =
  (
    campaignId,
    participantId,
    payload,
    isAnonymousActivity,
    headers = getHeaders(
      payload,
      `/activity/${campaignId}/participant/${participantId}/contribute`,
    ),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.post(
        `/activity/${campaignId}/participant/${participantId}/contribute`,
        payload,
        {
          headers: isAnonymousActivity
            ? getGuestHeaders({})
            : headers,
        },
      );

      dispatch(
        contributionType(
          SUBMIT_CONTRIBUTION_SUCCESS,
          response.data,
        ),
      );
      return response.data;
    } catch (err) {
      dispatch(
        contributionType(SUBMIT_CONTRIBUTION_ERROR, err),
      );
    }
  };

export const makePayment =
  (
    actionId,
    participantId,
    payload,
    headers = getHeaders(
      payload,
      `/stripe/payment/action/${actionId}/participant/${participantId}/card`,
    ),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.post(
        `/stripe/payment/action/${actionId}/participant/${participantId}/card`,
        payload,
        { headers },
      );

      dispatch(
        contributionType(
          INITIATE_PAYMENT_SUCCESS,
          response.data,
        ),
      );

      return response.data;
    } catch (err) {
      dispatch(
        contributionType(INITIATE_PAYMENT_ERROR, err),
      );
    }
  };

export const getOutputs =
  (
    query,
    headers = getHeaders(null, `/outputs${query || ""}`),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/user/outputs${query || ""}`, { headers })
      .then((response) => {
        dispatch(
          contributionType(
            GET_OUTPUTS_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(contributionType(GET_OUTPUTS_ERROR, err));
      });
  };

export const getOutput =
  (
    clubId,
    outputId,
    headers = getHeaders(
      null,
      `/club/${clubId}/output/${outputId}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/club/${clubId}/output/${outputId}`, {
        headers,
      })
      .then((response) => {
        dispatch(
          contributionType(
            GET_OUTPUT_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(contributionType(GET_OUTPUT_ERROR, err));
      });
  };

export const updateContribution =
  (
    activityId,
    contributionId,
    payload,
    headers = getHeaders(
      payload,
      `/activity/${activityId}/contribution/${contributionId}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .put(
        `/activity/${activityId}/contribution/${contributionId}`,
        payload,
        { headers },
      )
      .then((response) => {
        dispatch(
          contributionType(
            UPDATE_CONTRIBUTION_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          contributionType(UPDATE_CONTRIBUTION_ERROR, err),
        );
      });
  };

export const createOutput =
  (
    clubId,
    payload,
    headers = getHeaders(payload, `/club/${clubId}/output`),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.post(
        `/club/${clubId}/output`,
        payload,
        { headers },
      );

      dispatch(
        contributionType(
          CREATE_OUTPUT_SUCCESS,
          response.data,
        ),
      );

      return response.data;
    } catch (err) {
      dispatch(contributionType(CREATE_OUTPUT_ERROR, err));
    }
  };

export const cloneOutput =
  (
    clubId,
    outputId,
    payload,
    headers = getHeaders(
      payload,
      `/clone/club/${clubId}/output/${outputId}`,
    ),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.post(
        `/clone/club/${clubId}/output/${outputId}`,
        payload,
        { headers },
      );

      dispatch(
        contributionType(
          CLONE_OUTPUT_SUCCESS,
          response.data,
        ),
      );

      return response.data;
    } catch (err) {
      dispatch(contributionType(CLONE_OUTPUT_ERROR, err));
    }
  };

export const updateOutput =
  (
    clubId,
    outputId,
    payload,
    headers = getHeaders(
      payload,
      `/club/${clubId}/output/${outputId}`,
    ),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.put(
        `/club/${clubId}/output/${outputId}`,
        payload,
        {
          headers,
        },
      );

      dispatch(
        contributionType(
          UPDATE_OUTPUT_SUCCESS,
          response.data,
        ),
      );

      return response.data;
    } catch (err) {
      dispatch(contributionType(UPDATE_OUTPUT_ERROR, err));
    }
  };

export const updateOutputReq =
  (
    clubId,
    outputId,
    payload,
    headers = getHeaders(
      payload,
      `/club/${clubId}/output/${outputId}`,
    ),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.put(
        `/club/${clubId}/output/${outputId}`,
        payload,
        {
          headers,
        },
      );

      dispatch(
        contributionType(
          UPDATE_OUTPUT_SUCCESS,
          response.data,
        ),
      );

      return response.data;
    } catch (err) {
      dispatch(contributionType(UPDATE_OUTPUT_ERROR, err));

      return err;
    }
  };

export const contributionComment =
  (
    contributionId,
    payload,
    headers = getHeaders(
      payload,
      `/contribution/${contributionId}/comment`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .post(
        `/contribution/${contributionId}/comment`,
        payload,
        { headers },
      )
      .then((response) => {
        dispatch(
          contributionType(
            POST_CONTRIBUTIONS_COMMENTS_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          contributionType(
            POST_CONTRIBUTIONS_COMMENTS_ERROR,
            err,
          ),
        );
      });
  };

export const getContributionComments =
  (
    contributionId,
    headers = getHeaders(
      null,
      `/contribution/${contributionId}/comments`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/contribution/${contributionId}/comments`, {
        headers,
      })
      .then((response) => {
        dispatch(
          contributionType(
            GET_CONTRIBUTIONS_COMMENTS_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          contributionType(
            GET_CONTRIBUTIONS_COMMENTS_ERROR,
            err,
          ),
        );
      });
  };
