/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { BACK_TO, PROFILE } from '../helpers/constants';
import { getLabelByName } from '../helpers/helperFunctions';

const NotFound = () => (
  <div className="not-found">
    <div
      className="back"
      onClick={() => window.location.href = '/home'}
    >
        {`< ${getLabelByName(BACK_TO)} ${getLabelByName(PROFILE)}`}
    </div>
  </div>
);

export default NotFound;
