/* eslint-disable react/no-array-index-key */
import React, { Component } from "react";
import PropTypes from "prop-types";
import Loader from "../Loader";

class ResourceTab extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { resource, isLoading } = this.props;
    return (
      <div className="institute-content">
        <ul
          className="nav"
          id="instituteTabs"
          role="tablist">
          {Object.keys(resource).map((key, index) => (
            <li
              className="nav-item"
              role="presentation"
              key={index}>
              <a
                className={`nav-link ${
                  index === 0 ? "active" : ""
                }`}
                id={`${key.toLowerCase()}-tab`}
                data-toggle="tab"
                href={`#${key.toLowerCase()}`}
                role="tab"
                aria-controls={`${key.toLowerCase()}`}
                aria-selected="true">
                {key}
              </a>
            </li>
          ))}
        </ul>
        <hr />
        {isLoading && <Loader />}
        <div
          className="tab-content px-0"
          id="instituteTabsContent">
          {Object.keys(resource).map((key, index) => (
            <div
              key={index}
              className={`tab-pane fade ${
                index === 0 ? "show active" : ""
              }`}
              id={`${key.toLowerCase()}`}
              role="tabpanel"
              aria-labelledby={`${key.toLowerCase()}-tab`}>
              {resource[key]}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

ResourceTab.propTypes = {
  resource: PropTypes.any,
  isLoading: PropTypes.any,
};

export default ResourceTab;
