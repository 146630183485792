/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Card } from "react-bootstrap";
import moment from "moment";
import likingIcon from "../../assets/reactions/like.svg";
import commentIcon from "../../assets/icons/comment.svg";
import upvote from "../../assets/upvote.png";
import { getLabelByName } from "../../helpers/helperFunctions";
import { POSTED } from "../../helpers/constants";
import GalleryModal from "./GalleryModal";

export default class GalleryOutput extends Component {
  state = {
    photoIndex: 0,
    isOpen: false,
  };

  getContributionLink = () => {
    const { resource, listing_profile_output, urlParams } = this.props;
    const { clubId, clubHandle } = urlParams;
    const link = `/club/${clubId}/${clubHandle}/output/${listing_profile_output}/participant/${resource.participantId}`;
    return link;
  };

  render() {
    const { photoIndex, isOpen } = this.state;
    const { resource, logo, allResources, index } = this.props;
    console.log("Resource", resource);
    return (
      <div>
        <Card
          className="contribution__card"
          onClick={() => this.setState({ isOpen: true })}
        >
          <Card.Img src={JSON.parse(resource.listing_image)[0] || logo} />
          <Card.Body className="output-card">
            <div className="details">
              <span className="details__main">
                <Card.Subtitle>{resource.listing_title}</Card.Subtitle>
                <small>
                  {`${getLabelByName(POSTED)} `}
                  {moment
                    .unix(resource.dateCreated)
                    .format("DD MMM YYYY hh:mm")}
                </small>
              </span>
            </div>
            {resource.enable_voting === "1" ? (
              <div className="reactions">
                <span className="reactions__main">
                  <span>vote:</span>
                  <span>
                    <img src={upvote} alt="like-icon" width="24px" />
                    Yes
                  </span>
                  <span>
                    <img src={upvote} alt="like-icon" width="24px" />
                    No
                  </span>
                </span>
                <span className="reactions__sub">
                  <span>
                    <img src={likingIcon} alt="like-icon" width="20px" />
                    47
                  </span>
                  <span>
                    <img src={commentIcon} alt="like-icon" width="20px" />
                    132
                  </span>
                </span>
              </div>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
        <GalleryModal
          isModalOpen={isOpen}
          resource={resource}
          toggleModal={() => this.setState({ isOpen: !isOpen })}
          allResources={allResources}
          index={index}
        />
      </div>
    );
  }
}
