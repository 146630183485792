import {
  FILTER_MOST_ACTIVE_ACTIVITIES,
  FILTER_MOST_ACTIVE_ACTIVITIES_ERROR,
  FILTER_MOST_POPULAR_CLUBS,
  FILTER_MOST_POPULAR_CLUBS_ERROR,
  FILTER_MOST_ACTIVE_CLUBS,
  FILTER_MOST_ACTIVE_CLUBS_ERROR,
} from '../actions/types';

const initialState = {
  data: null,
  error: null,
  status: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_MOST_ACTIVE_ACTIVITIES:
      return {
        ...state,
        data: action.payload,
        erro: null,
        status: 'success',
      };
    case FILTER_MOST_ACTIVE_ACTIVITIES_ERROR:
      return {
        ...state,
        error: action.payload,
        data: null,
        status: 'error',
      };
    case FILTER_MOST_POPULAR_CLUBS:
      return {
        ...state,
        data: action.payload,
        error: null,
        status: 'success',
      };
    case FILTER_MOST_POPULAR_CLUBS_ERROR:
      return {
        ...state,
        error: action.payload,
        data: null,
        status: 'error',
      };
    case FILTER_MOST_ACTIVE_CLUBS:
      return {
        ...state,
        data: action.payload,
        error: null,
        status: 'success',
      };
    case FILTER_MOST_ACTIVE_CLUBS_ERROR:
      return {
        ...state,
        error: action.payload,
        data: null,
        status: 'error',
      };
    default:
      return {
        ...state,
        status: null,
      };
  }
};
