/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import clubIcon from '../../assets/manage-clubs.svg';
import { calculateTime, getLabelByName } from '../../helpers/helperFunctions';
import {
  CAMPAIGN, colors, ENDED, END_ON, HOSTED, START, STARTS_ON,
} from '../../helpers/constants';

class CampaignBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: this.props.time,
      seconds: this.props.seconds,
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  render() {
    const { campaignInfo, clubHost } = this.props;

    return (
      <div className="d-flex align-items-center justify-content-center">
        <div
          className="institute-card campaign-page-card"
          style={{ minHeight: 'unset', borderRadius: 'unset' }}
        >
          <div className="institute-header">
            <div className="header-details">
              <div className="institute-details">
                <h6>{campaignInfo && campaignInfo.name}</h6>
                <p>
                  <img
                    src={
                      clubHost && clubHost.profile.picture
                        ? clubHost.profile.picture
                        : clubIcon
                    }
                    alt="club-avatar"
                    width="22px"
                  />
                  {`${getLabelByName(HOSTED)} by `}
                  {clubHost && clubHost.name}
                </p>
              </div>
            </div>
            <div>
              <div className="header-countdown">
                {this.displayCountdown()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { seconds, time } = nextProps;
    this.setState({
      seconds,
      time,
    });
  }

  componentDidUpdate() {
    this.startTimer();
  }

  startTimer = () => {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown = () => {
    const seconds = this.state.seconds - 1;
    if (seconds <= 0) {
      clearInterval(this.timer);
    }

    this.setState({
      time: calculateTime(seconds),
      seconds,
    });
  }

  displayCountdown = () => {
    if (this.props?.campaignInfo?.endDate && this.state.seconds <= 0) return this.renderActivityEnded();

    if (this.props.campaignInfo
      && (this.props.campaignInfo.startDate * 1000 < new Date())
      && this.state.seconds > 0) return this.renderCountDown(getLabelByName(END_ON), this.props.campaignInfo.endDate);

    if (this.props.campaignInfo
      && (this.props.campaignInfo.startDate * 1000 > new Date())
      && this.state.seconds > 0) return this.renderCountDown(getLabelByName(STARTS_ON), this.props.campaignInfo.startDate);
  };

  renderCountDown = (label, date) => (
    <>
    <div className="d-flex">
      {this.state.time.map((item, index) => (
        <div className="countdown-item" key={index}>
          <div className="countdown-number">{item.number}</div>
          <div className="countdown-title">{item.title}</div>
        </div>
      ))}
    </div>
      <div className="d-flex justify-content-end mr-2">
        <small className="font-italic" style={{ color: colors.textSecondary }}>
          {`${label}: `}
          {moment(date * 1000).format('DD MMM YYYY, hh:mm')}
        </small>
      </div>
    </>
  );

  renderActivityEnded = () => `${getLabelByName(CAMPAIGN)} ${getLabelByName(ENDED)}`;

  renderActivityStart = () => (
    <div className="d-flex justify-content-end mr-2">
      <small className="font-italic" style={{ color: colors.textSecondary }}>
        {`${getLabelByName(START)}: `}
        {moment(this.props.campaignInfo.startDate * 1000).format('DD MMM YYYY, hh:mm')}
      </small>
    </div>
  );
}

CampaignBanner.propTypes = {
  campaignInfo: PropTypes.any,
  clubHost: PropTypes.any,
  seconds: PropTypes.number,
  time: PropTypes.array,
};

export default CampaignBanner;
