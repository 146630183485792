/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardElement,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Modal } from "react-bootstrap";
import { Button } from "@material-ui/core";
import Loader from "../../../Loader";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import { PAY } from "../../../../helpers/constants";
import Loading from "../../../generics/Loading/Loading";

const PaymentModal = ({
  closeModal,
  togglePaymentOverlay,
  isPaymentModalOpen,
  setPaymentStatus,
  location,
  activityId,
  activityName,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const returnUrl = `${window.location.origin}/campaign/${activityId}/${activityName}`;

  console.log({ returnUrl });

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    setIsLoading(false);

    // console.log("PAYMENT_RESULT", result);

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      //   console.log(result.error.message);
      closeModal();
      togglePaymentOverlay();
      setPaymentStatus("ERROR");
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.

      closeModal();
      togglePaymentOverlay();
      setPaymentStatus("SUCCESS");
    }
  };

  return (
    <Modal show={isPaymentModalOpen} onHide={closeModal}>
      <div className="mb-5 text-center font-weight-bold">
        <h3>Make Payment</h3>
      </div>

      {!stripe || !elements ? (
        <Loading />
      ) : (
        <>
          <PaymentElement />

          <Button
            // className={classes.button}
            className="mt-5"
            variant="contained"
            color="primary"
            form="form"
            onClick={handleSubmit}>
            {isLoading ? <Loader /> : getLabelByName(PAY)}
          </Button>
        </>
      )}
    </Modal>
  );
};

export default PaymentModal;
