import {
  GET_CONTRIBUTIONS_ERROR,
  GET_CONTRIBUTIONS_SUCCESS,
} from "../actions/types";

const initialState = {
  getContributionsData: null,
  getContributionsDataError: null,
  getContributionsStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTRIBUTIONS_SUCCESS:
      return {
        ...state,
        getContributionsData: action.payload,
        getContributionsDataError: null,
        getContributionsStatus: "success",
      };
    case GET_CONTRIBUTIONS_ERROR:
      return {
        ...state,
        getContributionsDataError: action.payload,
        getContributionsData: null,
        getContributionsStatus: "error",
      };
    default:
      return {
        ...state,
        getContributionsStatus: null,
      };
  }
};
