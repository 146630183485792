/* eslint-disable react/prefer-stateless-function */
import React, { Component, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  useLocation
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import store from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import ParticipantsPage from "./components/new/ParticipantsPage";
import NotFound from "./components/NotFound";
import Authentication from "./views/Authentication";
import Landing from "./views/Landing";
import Profile from "./components/Profile";
import Search from "./components/explore/Search";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import Verify from "./components/Verify";
// TODO: Site Mgmgt
import SiteManagement from "./components/SiteManagement";
import MemberDetails from "./components/MemberDetails";
import ActivityFeed from "./components/home/ActivityFeed";
import CreateArticle from "./components/CreateArticle";
import CreateLesson from "./components/CreateLesson";
import EditArticle from "./components/EditArticle";
import Contribute from "./components/campaign/Contribute";
import UserContribute from "./components/campaign/UserContribute/UserContribute";
import Post from "./components/posts/Post";
import OutputPage from "./components/contributions/OutputPage";
import ClubFeed from "./components/club/ClubFeed";
import Notifications from "./components/notifications/Notifications";
import Promote from "./components/promotions/Promote";
import QuestionnairePage from "./components/questionnaires/admin/QuestionnairePage/index";
import NewQuestionnaire from "./components/questionnaires/admin/NewQuestionnaire";
import ActivityContributions from "./components/campaign/admin/ActivityContributions";
import ClubOutputs from "./components/club/admin/ClubOutputs";
import Activity from "./components/campaign/Activity/Activity";
import NewLanding from "./components/landing/Landing";
import ActivityDetailsPage from "./components/new/ActivityDetailsPage";
import AdminRoute from "./views/AdminRoute";
import Club from "./components/club/Club/Club";
import AdminClubActivities from "./components/club/admin/ClubActivities/ClubActivities";
import ClubActivities from "./components/club/ClubActivities/ClubActivities";
import UserClubOutputs from "./components/club/ClubOutputs/ClubOutputs";
import ClubMembers from "./components/club/ClubMembers/ClubMembers";
import ClubLayout from "./components/club/admin/ClubLayout/ClubLayout";
import AdminClubMembers from "./components/club/admin/ClubMembers/ClubMembers";
import AdminClubContent from "./components/club/admin/ClubContent/ClubContent";
import AdminClubQuestionnaires from "./components/club/admin/ClubQuestionnaires/ClubQuestionnaires";
import Settings from "./components/settings/Settings/Settings";
import ParticipantsProfile from "./components/campaign/admin/ParticipantProfile";
import ActivitySettings from "./components/campaign/admin/ActivitySettings/ActivitySettings";
import ManageContribute from "./components/campaign/UserContribute/ManageContribute";
import ClubSettings from "./components/club/admin/ClubSettings/ClubSettings";
import HowToParticipate from "./components/campaign/admin/HowToParticipate/HowToParticipate";
import NewOutputProfilePage from "./components/contributions/NewOutputProfilePage/NewOutputProfilePage";
import ClubPromotions from "./components/club/admin/ClubPromotions/ClubPromotions";
// import NotificationProvider from "./NotificationProvider";
import ContributeToLessons from "./components/campaign/UserContribute/ContributeToLessons/index";
import withAuth from "./components/WithAuthentication";
import EditLesson from "./components/EditLesson";
import ImportUsers from "./components/users/admin/importUsers";
import ParticipantConsole from "./components/campaign/ParticipantConsole/ParticipantConsole"
import ParticipantAction from "./components/campaign/ParticipantAction/ParticipantAction";

import {
  userIsLoggedIn,
  getProfileDataFromLocalStorage,
} from "./helpers/helperFunctions";
import { GET_SESSION_SUCCESS } from "./redux/actions/types";

import Play from "./Play";
import ContributionsConsole from "./components/campaign/admin/ContributionsConsole";
import SessionUser from "./components/playground/SessionUser";
import Clubs from "./components/playground/Clubs";

// TODO: Multilang on Create/Edit Modal pages (Mgmt Details components)
// TODO: multilang toast messages

const userLanguage = navigator.language.split("-")[0];
localStorage.setItem("userLanguage", userLanguage);


// Pages Scroll Should always start from the top
const NewPageScrollFromTop = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (<>{children}</>)
};

// =============== CHECK USER SESSION BEFORE APP COMPONENT IS MOUNTED ==================
const profileData = getProfileDataFromLocalStorage();

if (userIsLoggedIn(profileData)) {
  store.dispatch({
    type: GET_SESSION_SUCCESS,
    payload: profileData,
  });
}
// =============== CHECK USER SESSION BEFORE APP COMPONENT IS MOUNTED ==================

export class App extends Component {
  render() {
    return (
      <Router>
        {/* <NotificationProvider> */}
        <NewPageScrollFromTop>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/activity/:activityId/participant/:participantId/console" component={ParticipantConsole} />
            <Route exact path="/activity/:activityId/:handle/participant/:participantId/console" component={ParticipantConsole} />
            <Route exact path="/activity/:activityId/participant/:participantId/action/:actionId" component={ParticipantAction} />
            <Route exact path="/activity/:activityId/participant/:participantId/action/:actionId/:pageAction" component={ParticipantAction} />
            <Route exact path="/activity/:activityId/participant/:participantId/action/:actionId/:pageAction/:participantActionId" component={ParticipantAction} />
            <Route exact path="/play" component={Play} />
            <Route exact path="/playground/session" component={SessionUser} />
            <Route exact path="/playground/clubs" component={Clubs} />
            <Route
              exact
              path="/landing"
              component={NewLanding}
            />
            <Route
              exact
              path="/privacy"
              component={Privacy}
            />
            <Route exact path="/terms" component={Terms} />
            <Route
              exact
              path="/home"
              component={withAuth(ActivityFeed)}
            />
            <Route
              exact
              path="/login"
              component={Authentication}
            />
            <Route
              exact
              path="/signup"
              component={Authentication}
            />
            <Route exact path="/verify" component={Verify} />
            <Route
              exact
              path="/password/reset"
              component={Authentication}
            />
            <Route
              exact
              path="/profile"
              component={withAuth(Profile)}
            />
            <Route
              exact
              path="/explore"
              component={withAuth(Search)}
            />
            <Route
              exact
              path="/search/:searchTerm"
              component={Search}
            />
            <Route
              exact
              path="/club/:id/:handle/about"
              component={Club}
            />
            <Route
              exact
              path="/club/:id/:handle"
              component={ClubFeed}
            />
            <Route
              exact
              path="/club/:id/:handle/activities"
              component={ClubActivities}
            />
            <Route
              exact
              path="/club/:id/:handle/outputs"
              component={UserClubOutputs}
            />
            <Route
              exact
              path="/club/:id/:handle/members"
              component={ClubMembers}
            />
            <Route
              exact
              path="/member/:id"
              component={MemberDetails}
            />
            <Route
              exact
              path="/create-article"
              component={CreateArticle}
            />
            <Route
              exact
              path="/create-lesson"
              component={CreateLesson}
            />
            <Route
              exact
              path="/club/:clubId/:clubHandle/article/:articleId/edit"
              component={EditArticle}
            />
            <Route
              exact
              path="/club/:clubId/:clubHandle/lesson/:lessonId/edit"
              component={EditLesson}
            />
            <Route
              exact
              path="/campaign/:id/:handle"
              component={Activity}
            />
            <Route
              exact
              path="/campaign/:id/:handle/participate/action/:actionId"
              component={Contribute}
            />
            <Route
              exact
              path="/club/:clubId/:clubHandle/post/:postId"
              component={Post}
            />
            <Route
              exact
              path="/campaign/:id/:handle/participate"
              component={UserContribute}
            />
            <Route
              exact
              path="/campaign/:id/:handle/participate/course/:actionId"
              component={ContributeToLessons}
            />
            <Route
              exact
              path="/campaign/:id/:handle/participate/action/:actionId/my-action/:participantActionId/edit"
              component={Contribute}
            />
            {/* <Route
              exact
              path="/club/:clubId/:clubHandle/output/:outputId/participant/:participantId"
              component={OutputProfilePage}
            /> */}
            <Route
              exact
              path="/club/:clubId/:clubHandle/output/:outputId/participant/:participantId"
              component={NewOutputProfilePage}
            />
            <Route
              exact
              path="/club/:clubId/:clubHandle/output/:outputId/:outputName"
              component={OutputPage}
            />
            <Route
              exact
              path="/notifications"
              component={Notifications}
            />
            <Route
              exact
              path="/promote/:resourceType"
              component={Promote}
            />

            {/* Admin Routes */}
            <AdminRoute
              exact
              path="/manage/:entity"
              component={withAuth(Settings)}
              authRole={[
                "site_admin",
                "club_admin",
                "activity_admin",
                "users_admin",
                "posts_admin",
                "questionnaires_admin",
              ]}
            />

            <AdminRoute
              exact
              path="/manage/promotions/:category"
              component={withAuth(Settings)}
              authRole={[
                "site_admin",
                "club_admin",
                "activity_admin",
                "users_admin",
                "posts_admin",
                "questionnaires_admin",
              ]}
            />

            <AdminRoute
              exact
              path="/manage/users/import-csv"
              component={ImportUsers}
              authRole={["users_admin"]}
            />

            <AdminRoute
              exact
              path="/manage-site"
              component={SiteManagement}
              authRole={["site_admin"]}
            />
            {/* <AdminRoute
              exact
              path="/manage-clubs"
              component={ClubsManagement}
              authRole={["club_admin"]}
            /> */}
            <AdminRoute
              exact
              path="/manage/clubs/:id/:handle"
              component={ClubLayout}
              authRole={["club_admin"]}
            />
            <AdminRoute
              exact
              path="/manage/clubs/:id/:handle/content/:category"
              component={AdminClubContent}
              authRole={["club_admin"]}
            />

            <AdminRoute
              exact
              path="/manage/clubs/:id/:handle/activities"
              component={AdminClubActivities}
              authRole={["club_admin"]}
            />
            <AdminRoute
              exact
              path="/manage/clubs/:id/:handle/questionnaires"
              component={AdminClubQuestionnaires}
              authRole={["club_admin"]}
            />
            <AdminRoute
              exact
              path="/manage/clubs/:id/:handle/members"
              component={AdminClubMembers}
              authRole={["club_admin"]}
            />
            <AdminRoute
              exact
              path="/manage/clubs/:id/:handle/outputs"
              component={ClubOutputs}
              authRole={["club_admin"]}
            />
            <AdminRoute
              exact
              path="/manage/clubs/:id/:handle/promotions"
              component={ClubPromotions}
              authRole={["club_admin"]}
            />
            <AdminRoute
              exact
              path="/manage/clubs/:id/:handle/settings/:category"
              component={ClubSettings}
              authRole={["club_admin"]}
            />
            {/* <AdminRoute
              exact
              path="/manage-activities"
              component={ActivitiesManagement}
              authRole={["activity_admin"]}
            /> */}
            <AdminRoute
              exact
              path="/manage/activities/:id/:handle"
              component={ActivityDetailsPage}
              authRole={["activity_admin"]}
            />
            <AdminRoute
              exact
              path="/manage/activities/:id/:handle/how-to-participate"
              component={HowToParticipate}
              authRole={["activity_admin"]}
            />
            <AdminRoute
              exact
              path="/manage/activities/:id/:handle/how-to-participate"
              component={HowToParticipate}
              authRole={["activity_admin"]}
            />
            <AdminRoute
              exact
              path="/manage/activities/:activityId/:handle/contributions"
              component={ContributionsConsole}
              authRole={["activity_admin"]}
            />
            <AdminRoute
              exact
              path="/manage/activities/:id/:handle/settings/:category"
              component={ActivitySettings}
              authRole={["activity_admin"]}
            />
            <AdminRoute
              exact
              path="/manage/web/:category"
              component={withAuth(Settings)}
              authRole={[
                "site_admin",
                "club_admin",
                "activity_admin",
                "users_admin",
                "posts_admin",
                "questionnaires_admin",
              ]}
            />
            {/* <AdminRoute
              exact
              path="/manage-users"
              component={UsersDashboard}
              authRole={["users_admin"]}
            />
            <AdminRoute
              exact
              path="/manage-posts"
              component={PostsDashboard}
              authRole={["posts_admin"]}
            /> */}
            <AdminRoute
              exact
              path="/manage/activities/:id/:handle/participants"
              component={ParticipantsPage}
              authRole={["activity_admin"]}
            />
            <AdminRoute
              exact
              path="/manage/activities/:id/:handle/participants/:participantId"
              component={ParticipantsProfile}
              authRole={["activity_admin"]}
            />
            {/* <AdminRoute
              exact
              path="/manage/questionnaires"
              component={QuestionnairesDashboard}
              authRole={["questionnaires_admin"]}
            /> */}
            <AdminRoute
              exact
              path="/manage/questionnaires/club/:clubId/questionnaire/:questionnaireId/edit"
              component={QuestionnairePage}
              authRole={["questionnaires_admin"]}
            />
            <AdminRoute
              exact
              path="/manage/questionnaires/new"
              component={NewQuestionnaire}
              authRole={["questionnaires_admin"]}
            />

            <AdminRoute
              exact
              path="/manage/activities/:id/:handle/participant/:participantId/action/:actionId"
              component={ManageContribute}
              authRole={[
                "site_admin",
                "club_admin",
                "activity_admin",
              ]}
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </NewPageScrollFromTop>
        {/* </NotificationProvider> */}

        <ToastContainer />
      </Router>
    );
  }
}

export default withRouter(App);
