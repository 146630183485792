/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { COPIED, COPY_LINK, DOWNLOAD } from '../../../../helpers/constants';
import { getLabelByName } from '../../../../helpers/helperFunctions';
import { getFormattedSetting } from "../../../../helpers/helperFunctions";

class ItemDropdown extends Component {
  state = {
    linkCopied: false,
    contribution: this.props.contribution,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ contribution: nextProps.contribution })
  }

  getUrl = () => {
    let url = '';
    try {
      url = JSON.parse(getFormattedSetting(
        this.props.sources.listings.settings.listing_image,
        this.props.contribution.data
      ))[0];
    } catch (error) {
      console.error('error', error);
    }

    return url;
  }

  copyLink = (url) => {
    navigator.clipboard.writeText(url);
    this.setState({ linkCopied: true });
    setTimeout(() => {
      this.setState({ linkCopied: false });
    }, 3000);
  };

  render() {
    const { linkCopied } = this.state;

    return (
      <>
        <div
          className="dropdown-menu library-item-dropdown"
          aria-labelledby="dropdownItem"
        >
          <a className="dropdown-item nav-link" href={this.getUrl()} download target={`_blank`}>
            <div className="side-nav-item">
              {getLabelByName(DOWNLOAD)}
            </div>
          </a>
          <a className="dropdown-item nav-link" onClick={() => this.copyLink(this.getUrl())}>
            <div className="side-nav-item">
              {linkCopied ? getLabelByName(COPIED) : getLabelByName(COPY_LINK)}
            </div>
          </a>
        </div>
      </>
    );
  }
}

ItemDropdown.propTypes = {
  contribution: PropTypes.any,
};

export default ItemDropdown;
