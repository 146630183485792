/* eslint-disable react/prop-types */
// @ts-nocheck
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { compose } from "redux";
import { ArrowBackIos } from "@material-ui/icons";
import {
  IconButton,
  FormControl,
  Card,
  CardContent,
  Typography,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Avatar,
} from "@material-ui/core";
import SearchSharpIcon from "@material-ui/icons/SearchSharp";
import DeleteOutlineSharpIcon from "@material-ui/icons/DeleteOutlineSharp";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { toast } from "react-toastify";
import {
  PARTICIPANT,
  UPDATE_ROLE_NOTIFICATION_SUCCESSFULLY,
  DELETE_PARTICIPANT_NOTIFICATION_SUCCESSFULLY,
  UPDATE_ROLE_NOTIFICATION_ERROR,
  DELETE_PARTICIPANT_NOTIFICATION_ERROR,
  SEARCH,
  PARTICIPANTS,
  ADD,
  NEW,
  ADD_PARTICIPANT_DESCRIPTION,
} from "../../helpers/constants";
import {
  checkSession,
  getLabelByName,
  isActionError,
  isActionSuccess,
} from "../../helpers/helperFunctions";
import Navbar from "../Navbar";
import Footer from "../Footer";
import {
  getParticipants,
  updateParticipantAction,
  updateParticipantRole,
  addMemberToActivityAction,
  deleteParticipant,
} from "../../redux/actions/campaignsActions";
import "./Participants.css";
import RoleDialog from "./RoleDialog";
import ConfirmationDialog from "./ConfirmationDialog";
import Loader from "../Loader";
import SearchBar from "../generics/SearchBar/SearchBar";
import { getSessionAction } from "../../redux/actions/authActions";
import AddMemberModal from "../generics/AddMemberModal/AddMemberModal";
import {
  getInstituteMembers,
  joinClub,
} from "../../redux/actions/institutesActions";
import { getUsers } from "../../redux/actions/usersActions";

class ParticipantsPage extends Component {
  state = {
    isModalOpen: false,
    dialogOpen: false,
    confirmationDialogOpen: false,
    activityParticipants: [],
    participants: [],
    selectedParticipants: [],
    selectedValue: null,
    loading: { id: null, status: false },
    searchedParticipants: null,
    displayedParticipants: null,
    pathname: this.props.location.pathname,
    isAdding: false,
    isAddMemberModalOpen: false,
    isButtonLoading: false,
  };

  async UNSAFE_componentWillMount() {
    const {
      getParticipants: getParticipantsAction,
      getSessionAction: getSession,
      getInstituteMembers: getInstituteMembersAction,
      match,
      location,
    } = this.props;
    await getSession();

    await getParticipantsAction(match.params.id);

    await getInstituteMembersAction(
      location.activityData?.parentId,
    );
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, status } = nextProps;

    checkSession(
      data,
      status,
      this.state.pathname,
      nextProps,
    );

    const {
      getParticipantsData,
      addParticipantData,
    } = this.props;

    if (
      nextProps.getParticipantsData?.data !==
      getParticipantsData?.data
    ) {
      this.setState({
        displayedParticipants:
          nextProps.getParticipantsData?.data,
      });
    }

    const {
      addParticipantStatus,
      addParticipantError,
      getParticipants: getParticipantsAction,
      match,
    } = nextProps;

    if (
      addParticipantStatus === "success" &&
      nextProps.addParticipantData?.data !==
        addParticipantData?.data
    ) {
      toast.success("Participant(s) added successfully!");

      this.setState({
        isAddMemberModalOpen: false,
        isButtonLoading: false,
      });

      await getParticipantsAction(match.params.id);
    }

    if (
      addParticipantStatus === "error" &&
      addParticipantError
    ) {
      this.setState({
        isButtonLoading: false,
      });
      toast.error(
        "Oooops! Something went wrong.\nPlease try again!",
      );
    }
  }

  componentDidUpdate(prevProps) {
    const {
      getParticipantsData,
      updateParticipantActionStatus,
      match,
      getParticipants: getParticipantsAction,
      deleteParticipantActionStatus,
      deleteParticipantActionData,
    } = this.props;
    if (
      prevProps.deleteParticipantActionData !==
      deleteParticipantActionData
    ) {
      if (deleteParticipantActionStatus === "success") {
        getParticipantsAction(match.params.id);
        toast.success(
          getLabelByName(
            DELETE_PARTICIPANT_NOTIFICATION_SUCCESSFULLY,
          ),
        );
        this.updateLoader({ id: null, status: false });
        this.handleConfirmationDialogClose();
      }
      if (deleteParticipantActionStatus === "error") {
        toast.success(
          getLabelByName(
            DELETE_PARTICIPANT_NOTIFICATION_ERROR,
          ),
        );
        this.updateLoader({ id: null, status: false });
      }
    }
    if (
      prevProps.updateParticipantActionStatus !==
      updateParticipantActionStatus
    ) {
      if (updateParticipantActionStatus === "success") {
        getParticipantsAction(match.params.id);
        toast.success(
          getLabelByName(
            UPDATE_ROLE_NOTIFICATION_SUCCESSFULLY,
          ),
        );
        this.updateLoader({ id: null, status: false });
      }
      if (updateParticipantActionStatus === "error") {
        toast.success(
          getLabelByName(UPDATE_ROLE_NOTIFICATION_ERROR),
        );
        this.updateLoader({ id: null, status: false });
      }
    }
    if (
      prevProps.getParticipantsData?.data !==
      getParticipantsData?.data
    ) {
      this.updateParticipants(getParticipantsData.data);
    }
  }

  updateLoader = (status) => {
    this.setState({ loading: status });
  };

  updateParticipants = (data) => {
    this.setState({ activityParticipants: data });
  };

  handleSelectedUser = (participant) => {
    const { selectedParticipants } = this.state;
    const selectedParticipantsIndex = selectedParticipants.findIndex(
      (_participant) => {
        return participant.user.id === _participant.user.id;
      },
    );

    if (selectedParticipantsIndex === -1) {
      this.setState({
        selectedParticipants: [
          ...selectedParticipants,
          participant,
        ],
      });
    } else {
      const newSelectedParticipants = selectedParticipants.filter(
        (_participant) => {
          return (
            participant.user.id !== _participant.user.id
          );
        },
      );
      this.setState({
        selectedParticipants: newSelectedParticipants,
      });
    }
  };

  handleUpdateParticipant = (participant) => {
    const {
      updateParticipantRole: updateParticipantRoleAction,
      match,
    } = this.props;
    updateParticipantRoleAction(
      match.params.id,
      participant.id,
      participant,
    );
  };

  handleModalOpen = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  setParticipantRoleHandler = (
    participantRole,
    participantIndex,
  ) => {
    const { activityParticipants } = this.state;

    const participantToUpdate = activityParticipants.find(
      (participant, index) => participantIndex === index,
    );
    participantToUpdate.role = participantRole;

    const newParticipants = activityParticipants.filter(
      (participant, index) => index !== participantIndex,
    );

    newParticipants.splice(
      participantIndex,
      0,
      participantToUpdate,
    );

    this.setState({
      activityParticipants: newParticipants,
    });
  };

  handleDeleteParticipant = (participant) => {
    const {
      deleteParticipant: deleteParticipantAction,
      match,
    } = this.props;
    deleteParticipantAction(
      match.params.id,
      participant.id,
    );
    this.setState({
      loading: { id: participant.user.id, status: true },
    });
  };

  handleClickOpen = (_participant) => {
    this.setState({
      dialogOpen: true,
      selectedValue: _participant,
    });
  };

  handleDialogClose = (_participant) => {
    const {
      updateParticipantRole: updateParticipantRoleAction,
      match,
    } = this.props;
    updateParticipantRoleAction(
      match.params.id,
      _participant.id,
      _participant,
    );
    this.setState({
      dialogOpen: false,
      selectedValue: null,
    });
  };

  handleClickOpenConfirmation = (_participant) => {
    this.setState({
      confirmationDialogOpen: true,
      selectedValue: _participant,
    });
  };

  handleConfirmationDialogClose = () => {
    this.setState({
      confirmationDialogOpen: false,
      selectedValue: null,
    });
  };

  render() {
    const {
      participants,
      activityParticipants,
      confirmationDialogOpen,
      selectedValue,
      dialogOpen,
      loading,
      displayedParticipants,
      searchedParticipants,
      triggerPagination,
      isAdding,
      isAddMemberModalOpen,
      isButtonLoading,
    } = this.state;

    const {
      match,
      addMemberToActivityAction: addMemberToActivity,
      getParticipantsData,
    } = this.props;

    return (
      <div className="participant-page">
        <Navbar pathname="manage" />
        <div style={{ position: "relative", top: "130px" }}>
          <div style={{ margin: "0 1rem" }}>
            <div className="participant-page-header">
              <div className="participant-back-title">
                <Link
                  to={`/manage/activities/${match.params.id}/${match.params.handle}`}
                  style={{
                    color: "black",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                  }}>
                  <ArrowBackIos className="arrow-back-icon" />
                  <Typography
                    display="inline"
                    variant="h4"
                    component="h4">
                    {getLabelByName(PARTICIPANT)}
                  </Typography>
                </Link>
              </div>
              <div className="edit-button">
                <button
                  type="button"
                  onClick={() =>
                    this.setState({
                      isAddMemberModalOpen: true,
                    })
                  }
                  className="btn btn-lg submit-btn"
                  data-toggle="modal"
                  data-target=".create-club-modal">
                  {isAdding && (
                    <>
                      <span>
                        {`${getLabelByName(
                          ADD,
                        )} ${getLabelByName(
                          NEW,
                        ).toLowerCase()}`}
                      </span>
                      <Loader />
                    </>
                  )}
                  {!isAdding &&
                    `${getLabelByName(
                      ADD,
                    )} ${getLabelByName(
                      NEW,
                    ).toLowerCase()}`}
                </button>
              </div>
            </div>

            <div className="participants-header-details">
              <div className="participants-count bigger-screens">
                {(!triggerPagination &&
                  searchedParticipants && (
                    <p>{`${searchedParticipants.length} ${
                      (searchedParticipants.length > 1 &&
                        getLabelByName(PARTICIPANTS)) ||
                      getLabelByName(PARTICIPANT)
                    }`}</p>
                  )) ||
                  (displayedParticipants && (
                    <p>{`${displayedParticipants.length} ${
                      (displayedParticipants.length > 1 &&
                        getLabelByName(PARTICIPANTS)) ||
                      getLabelByName(PARTICIPANT)
                    }`}</p>
                  ))}
              </div>
              <div className="search-participants">
                <SearchBar
                  setSearchedItems={(searchedItems) =>
                    this.setState({
                      searchedParticipants: searchedItems,
                    })
                  }
                  triggerPagination
                  isClubMembers
                  setTriggerPagination={() =>
                    this.setState({
                      triggerPagination: false,
                    })
                  }
                  displayedItems={displayedParticipants}
                  setDisplayedItems={(displayedItems) => {
                    this.setState({
                      displayedParticipants: displayedItems,
                    });
                  }}
                  placeholderText={`${getLabelByName(
                    SEARCH,
                  )} ${getLabelByName(
                    PARTICIPANT,
                  ).toLowerCase()}`}
                />
              </div>
              <div className="participants-count small-screens">
                {(!triggerPagination &&
                  searchedParticipants && (
                    <p>{`${searchedParticipants.length} ${
                      (searchedParticipants.length > 1 &&
                        getLabelByName(PARTICIPANTS)) ||
                      getLabelByName(PARTICIPANT)
                    }`}</p>
                  )) ||
                  (displayedParticipants && (
                    <p>{`${displayedParticipants.length} ${
                      (displayedParticipants.length > 1 &&
                        getLabelByName(PARTICIPANTS)) ||
                      getLabelByName(PARTICIPANT)
                    }`}</p>
                  ))}
              </div>
            </div>
            <div className="participant-list">
              {(
                searchedParticipants || activityParticipants
              ).map((participant) => (
                <Card
                  key={participant.user.id}
                  className="participant-card participant-card__width"
                  onClick={() =>
                    this.handleSelectedUser(participant)
                  }
                  variant="outlined"
                  raised={false}>
                  <span>
                    {participant.user.id ===
                      Number(loading.id) &&
                    loading.status === true ? (
                      <span
                        style={{
                          color: "grey",
                          position: "absolute",
                          zIndex: "100",
                          left: "4px",
                          top: "4px",
                          fontSize: "1rem",
                        }}>
                        <Loader />
                      </span>
                    ) : (
                      <DeleteOutlineSharpIcon
                        style={{
                          color: "grey",
                          position: "absolute",
                          zIndex: "100",
                          left: "4px",
                          top: "4px",
                          fontSize: "1rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.handleClickOpenConfirmation(
                            participant,
                          );
                        }}
                        role="button"
                        aria-hidden="true"
                      />
                    )}
                    <EditOutlinedIcon
                      onClick={() =>
                        this.handleClickOpen(participant)
                      }
                      role="button"
                      aria-hidden="true"
                      style={{
                        color: "grey",
                        position: "absolute",
                        right: "4px",
                        top: "4px",
                        fontSize: "1rem",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                  <Avatar
                    alt={`${participant.user.profile.firstName} ${participant.user.profile.lastName}`}
                    src={participant.user.profile.picture}
                    style={{
                      width: "60px",
                      height: "60px",
                    }}
                  />
                  <Link
                    to={`/manage/activities/${match.params.id}/${match.params.handle}/participants/${participant.id}`}
                    style={{
                      color: "unset",
                      textDecoration: "none",
                    }}>
                    <CardContent>
                      <Typography variant="subtitle1">
                        {participant.user.profile.firstName}{" "}
                        {participant.user.profile.lastName}
                      </Typography>
                      <Typography color="textSecondary">
                        {participant.role}
                      </Typography>
                    </CardContent>
                  </Link>
                </Card>
              ))}
            </div>
          </div>
          <div className="footer-block">
            <div className="footer-component">
              <Footer />
            </div>
          </div>
        </div>
        <RoleDialog
          selectedValue={selectedValue}
          open={dialogOpen}
          onClose={this.handleDialogClose}
          handleUpdateParticipant={this.updateParticipants}
        />
        <ConfirmationDialog
          selectedValue={selectedValue}
          open={confirmationDialogOpen}
          onClose={this.handleConfirmationDialogClose}
          handleDeleteParticipant={
            this.handleDeleteParticipant
          }
        />
        {isAddMemberModalOpen && (
          <AddMemberModal
            isClub={false}
            actionId={match.params.id}
            isOpen={isAddMemberModalOpen}
            getParticipantsData={getParticipantsData}
            modalTitle={`${getLabelByName(
              ADD,
            )} ${getLabelByName(PARTICIPANT)}`}
            modalDescription={getLabelByName(
              ADD_PARTICIPANT_DESCRIPTION,
            )}
            addMemberAction={(actionId, payload) =>
              addMemberToActivity(actionId, payload)
            }
            handleUpdateParticipant={
              this.handleUpdateParticipant
            }
            participants={participants}
            setParticipantRole={
              this.setParticipantRoleHandler
            }
            handleAddUser={this.handleAddUser}
            setParticipants={(userId, role) =>
              this.setState({
                participants: [
                  ...participants,
                  { userId, role },
                ],
              })
            }
            isButtonLoading={isButtonLoading}
            membersFromReducer={getParticipantsData}
            setIsButtonLoading={() =>
              this.setState({ isButtonLoading: true })
            }
            setIsOpen={() =>
              this.setState({ isAddMemberModalOpen: false })
            }
          />
        )}
      </div>
    );
  }
}

ParticipantsPage.propTypes = {
  updateParticipantActionData: PropTypes.any,
  updateParticipantAction: PropTypes.func,
  getParticipantsData: PropTypes.shape({
    data: PropTypes.array,
  }).isRequired,
  getParticipants: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  addMemberToActivityAction: PropTypes.func,
};

export const mapStateToProps = (state) => ({
  updateParticipantActionDataError:
    state.updateParticipantActionReducer
      .updateParticipantActionDataError,
  updateParticipantActionData:
    state.updateParticipantActionReducer
      .updateParticipantActionData,
  updateParticipantActionStatus:
    state.updateParticipantActionReducer
      .updateParticipantActionStatus,
  deleteParticipantActionStatus:
    state.deleteParticipantActionReducer
      .deleteParticipantActionStatus,
  deleteParticipantActionData:
    state.deleteParticipantActionReducer
      .deleteParticipantActionData,
  getParticipantsData:
    state.getParticipantsReducer.getParticipantsData,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
  clubMembersData: state.clubMembersReducer.clubMembersData,
  getUsersData: state.getUsersReducer.getUsersData,
  addParticipantError:
    state.addParticipantReducer.addParticipantError,
  addParticipantData:
    state.addParticipantReducer.addParticipantData,
  addParticipantStatus:
    state.addParticipantReducer.addParticipantStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    updateParticipantAction,
    addMemberToActivityAction,
    getParticipants,
    deleteParticipant,
    updateParticipantRole,
    getSessionAction,
    getInstituteMembers,
    joinClub,
    getUsers,
  }),
)(ParticipantsPage);
