import "../WebContent/WebContentNav/webContentNav.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@material-ui/icons";
import {
  ACTIVITIES,
  ALL_WORD,
  CLUBS,
  OUTPUTS,
  POSTS,
} from "../../../helpers/constants";
import { getLabelByName } from "../../../helpers/helperFunctions";

export class PromotionsNav extends Component {
  state = {
    showMenu: false,
    navItems: [
      {
        title: getLabelByName(ALL_WORD),
        link: "/manage/promotions/all",
      },
      {
        title: getLabelByName(POSTS),
        link: "/manage/promotions/posts",
      },
      {
        title: getLabelByName(ACTIVITIES),
        link: "/manage/promotions/activities",
      },
      {
        title: getLabelByName(CLUBS),
        link: "/manage/promotions/clubs",
      },
      {
        title: getLabelByName(OUTPUTS),
        link: "/manage/promotions/outputs",
      },
    ],
  };

  toggleMenu = () =>
    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }));

  render() {
    const { navItems, showMenu } = this.state;
    const { category } = this.props.match.params;

    const navItem = navItems.find(
      (item) => item.title.toLowerCase() === category,
    );

    return (
      <div className="nav-bar mb-5">
        <div className="responsive-nav title-responsiveness">
          <div
            aria-hidden
            className="current-entity"
            onClick={() => this.toggleMenu()}>
            {navItem.title}
            {showMenu ? (
              <KeyboardArrowUpRounded className="ml-3" />
            ) : (
              <KeyboardArrowDownRounded className="ml-3" />
            )}
          </div>
        </div>

        <div className="web-content-container-fluid-responsiveness">
          {showMenu &&
            navItems.map((item) => (
              <Link
                to={item.link}
                className={`nav-link web-content-side-nav-item ${
                  category === item.title.toLowerCase()
                    ? "web-content-nav-item__active"
                    : ""
                }`}
                onClick={() =>
                  showMenu ? this.toggleMenu() : {}
                }>
                {item.title}
              </Link>
            ))}
        </div>

        <div className="web-content-container-fluid">
          {" "}
          {navItems.map((item) => (
            <Link
              key={navItems.indexOf(item)}
              to={item.link}
              className={`nav-link web-content-side-nav-item ${
                category === item.title.toLowerCase()
                  ? "web-content-nav-item__active"
                  : ""
              }`}
              onClick={() =>
                showMenu ? this.toggleMenu() : {}
              }>
              <div> {item.title} </div>{" "}
            </Link>
          ))}{" "}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(PromotionsNav);
