import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import {
    getGuestDataFromLocalStorage,
    getLabelByName,
    getProfileDataFromLocalStorage,
} from "../../../helpers/helperFunctions";
import { getInstituteMembers } from "../../../redux/actions/institutesActions";
import { PARTICIPANT_WELCOME_MESSAGE } from "../../../helpers/constants";
import ContributionCard from '../../generics/Contribution/ContributionCard/ContributionCard';
import noContributionImage from "../../../assets/undraw_Portfolio_update.svg";
import { getContributionComments, contributionComment, getContributions } from "../../../redux/actions/contributionsActions";
import { Modal } from "react-bootstrap";
import ActionRenderer from '../ParticipantAction/ActionRenderer';
import { ActionPreviewConversation } from './PreviewConversation'


const ConsoleDashboard = (props) => {
    const {
        activity,
        participant,
        participantActions,
        participantContributions,
        clubMembersData,
        getContributionsCommentsData
    } = props;

    const dispatch = useDispatch();

    /**  Conversation Logic */

    const [commentButtonLoading, setCommentButtonLoading] = useState(false)
    const [postComment, setPostComment] = useState("")
    const [userIds, setUserIds] = useState([])
    const [clubMembersNames, setClubMembersNames] = useState([])
    const [clubMembersIndex, setClubMembersIndex] = useState({})
    const [selectedContribution, setSelectedContribution] = useState({
        show: false,
        participantActionId: null,
        action: null,
        id: 0
    })


    useEffect(() => {
        if (!clubMembersData) {
            props.getInstituteMembers(activity.parentId)
        }
    }, [clubMembersData])


    useEffect(() => {
        if (selectedContribution?.action?.id) {
            props.getContributionComments(selectedContribution?.action?.id)
        }
    }, [selectedContribution?.action?.id])

    const commentPayload = {
        authorId: participant.user.id,
        content: {
            body: postComment,
        },
        files: {},
        images: {},
        mentions: userIds,
        parentId: selectedContribution?.id,
        status: "APPROVED",
        videos: {},
        visibility: "PUBLIC",
    };

    const handleReplyChange = (e) => {
        const newUserIds = [];

        e.split(" ").forEach((value) => {

            if (value && clubMembersIndex[value]) {
                const newId = clubMembersIndex[value];

                const alreadyNewUserId = newUserIds.find(
                    (id) => id === newId,
                );

                if (!alreadyNewUserId) {
                    newUserIds.push(newId);
                }
            }
        });

        setPostComment(e)
        setUserIds(newUserIds)
    };

    const openContribution = (contribution) => {
        const clubMembers = [];
        const clubMembersIndex_ = {};

        if (clubMembersData?.data) {
            clubMembersData.data.forEach((member) => {
                const memberName = `${member.user.profile.firstName.replace(
                    /\s/g,
                    "",
                )}${member.user.profile.lastName.replace(
                    /\s/g,
                    "",
                )}`;
                clubMembers.push(memberName);

                clubMembersIndex_[`@${memberName}`] = member.user.id;
            });
        }
        setClubMembersIndex({ ...clubMembersIndex_ })
        setClubMembersNames([...clubMembers])
        setSelectedContribution({
            ...selectedContribution,
            show: true,
            participantActionId: contribution.participantActionId,
            action: activity.actions.find(activityAction => activityAction.id == contribution.actionId),
            id: contribution.id
        })
    }

    const closeContribution = () => {

        setSelectedContribution({
            ...selectedContribution,
            show: false,
            id: 0,
        })
    }

    const replyComment = () => {
        setCommentButtonLoading(true)
        dispatch(
            contributionComment(selectedContribution?.id, commentPayload)
        )
        setCommentButtonLoading(false)
    }

    const handleContribute = () => {
        window.location.href = `/activity/${activity.id}/participant/${participant.id}/action/${activity.actions[0].id}`;
    }

    return <>
        <div className="right-div px-3">
            {!participantContributions &&
                <div className="contributions-content">
                    Loading...
                </div>
            }

            {participantContributions && Boolean(participantContributions.contributions.length) &&
                <div className="contributions-content">
                    <div className="contribution-cards-content">
                        {participantContributions &&
                            participantContributions.contributions.map(
                                (contribution, index) => (
                                    <div className="contribution-card-area" key={contribution.id}>
                                        <ContributionCard
                                            contributionData={contribution}
                                            contributionIndex={index}
                                            selectContribution={() => openContribution(contribution)}
                                        />
                                    </div>
                                ),
                            )
                        }
                    </div>
                </div>
            }

            {participantContributions && !participantContributions.contributions.length &&
                <div className="inside-content">
                    <img src={noContributionImage} alt="" />
                    <p>{getLabelByName(PARTICIPANT_WELCOME_MESSAGE)}</p>
                    <button type="button" onClick={handleContribute}>
                        {activity?.settings?.contribute_call_to_action || 'Start Contributing'}
                    </button>
                </div>
            }
        </div>

        <Modal size="xl" show={selectedContribution.show} onHide={closeContribution} className="behalf-modal">
            <ActionRenderer
                activity={activity}
                action={selectedContribution.action}
                participant={participant}
                participantActions={participantActions}
                pageAction="review"
                isPreviewMode={true}
                participantActionId={selectedContribution.participantActionId}
            />
            <ActionPreviewConversation
                getContributionsCommentsData={getContributionsCommentsData}
                commentButtonLoading={commentButtonLoading}
                participant={participant}
                replyComment={replyComment}
                handleReplyChange={handleReplyChange}
                postComment={postComment}
                clubMembersNames={clubMembersNames}
            />
        </Modal>
    </>;
}

const mapStateToProps = (state) => ({
    getContributionsCommentsData: state.getContributionsCommentsReducer.getContributionsCommentsData,
});

export default compose(
    withRouter,
    connect(mapStateToProps, {
        getContributionComments,
        contributionComment,
        getInstituteMembers
    })
)(ConsoleDashboard);