/* eslint-disable no-nested-ternary */
import "./contributionCard.scss";
import React, { Component } from "react";
import moment from "moment";
import ContributionComment from "../ContribuitonComment/ContributionComment";
import commentIcon from "../../../../assets/icons/comment.svg";

export default class ContributionCard extends Component {
  state = {
    pendingStatus: {
      text: "Pending",
      backgroundColor: "#E6B100",
    },
    acceptedStatus: {
      text: "Accepted",
      backgroundColor: "#10A728",
    },
    rejectedStatus: {
      text: "Rejected",
      backgroundColor: "#F40505",
    },
    showComment: false,
    currentModalIndex: 0,
    isAddedDiv: false,
  };

  showCommentModal = (index) => {
    const { showComment, isAddedDiv } = this.state;

    this.setState({
      showComment: !showComment,
      currentModalIndex: index,
      isAddedDiv: !isAddedDiv,
    });
  };

  render() {
    const {
      contributionData,
      contributionIndex,
      selectContribution,
    } = this.props;

    const {
      pendingStatus,
      acceptedStatus,
      rejectedStatus,
      showComment,
      currentModalIndex,
    } = this.state;

    return (
      <div className="contribution-card">
        <div className="inside-content">
          <div
            className="contribution-details"
            aria-hidden
            onClick={() =>
              selectContribution(contributionData)
            }>
            <h1>{contributionData.action[0].name}</h1>
            <p>
              {moment(
                contributionData.dateCreated * 1000,
              ).format("DD MMM YYYY hh:mm ") ||
                "DD MMM YYYY hh:mm"}
            </p>
          </div>
          <div className="flex-content">
            <div className="status-div">
              <button
                type="button"
                style={{
                  backgroundColor:
                    contributionData.status ===
                    "PENDING_REVIEW"
                      ? pendingStatus.backgroundColor
                      : contributionData.status ===
                        "REJECTED"
                      ? rejectedStatus.backgroundColor
                      : acceptedStatus.backgroundColor,
                  cursor: "default",
                }}>
                {contributionData.status ===
                "PENDING_REVIEW"
                  ? pendingStatus.text
                  : contributionData.status === "REJECTED"
                  ? rejectedStatus.text
                  : acceptedStatus.text}
              </button>
            </div>
            <div className="comment-toggle-icon">
              <div
                className="comment-icon"
                aria-hidden
                onClick={() =>
                  this.showCommentModal(contributionIndex)
                }>
                <img
                  src={commentIcon}
                  alt="comment-icon"
                  width="25px"
                />
                <span className="comment-number">
                  {contributionData?.comments}
                </span>
              </div>
              {showComment &&
                contributionIndex === currentModalIndex && (
                  <ContributionComment
                    closeModal={() => {
                      this.setState({
                        showComment: false,
                        isAddedDiv: false,
                      });
                    }}
                    contribution={contributionData}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
