import React from 'react';
import Navbar from './Navbar';

const Fallback = () => (
  <>
    <Navbar pathname="" />
    <div className="fallback-block">
      <div className="fallback-text">
        There was an unexpected error loading some data!
        <br />
        <br />
        Kindly press the button below to reload the page.
      </div>
      <div className="fallback-button mt-4">
        <button
          className="fallback-reload btn btn-lg submit-btn"
          type="button"
          onClick={() => (window.location.reload())}
        >
          Reload
        </button>
      </div>
    </div>
  </>
);

export default Fallback;
