/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import clubIcon from "../../assets/manage-clubs.svg";
import {
  EXPLORE,
  NO_CLUBS_JOINED,
} from "../../helpers/constants";
import {
  getAllClubsFromLocalStorage,
  getLabelByName,
  getProfileDataFromLocalStorage,
} from "../../helpers/helperFunctions";
import ShareResource from "../generics/ShareResource";

class MyClubs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clubsData: getAllClubsFromLocalStorage(),
      profileData: getProfileDataFromLocalStorage(),
      myClubs: [],
    };
  }

  render() {
    const myClubs = this.getMyClubs();

    return (
      <div className="list-items">
        {myClubs.length > 0 ? (
          myClubs.map((item, index) => (
            <div className="card item-card text-center pb-2 d-flex align-items-center justify-content-center">
              <Link
                key={index}
                to={`/club/${item.id}/${item.handle}`}
                className="w-100"
                style={{
                  color: "unset",
                  textDecoration: "none",
                }}>
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    className="img-fluid rounded-circle mr-3"
                    src={
                      item && item.profile.picture
                        ? item.profile.picture
                        : clubIcon
                    }
                    alt="club-avatar"
                    style={{
                      width: "110px",
                      height: "110px",
                      objectFit: "cover",
                      padding: "1rem",
                    }}
                  />
                </div>

                <hr className="card-hr" />

                <div className="card-body p-0 mb-2">
                  <p className="card-text text-center">
                    {item.name}
                  </p>
                </div>
              </Link>

              <ShareResource
                resourceType="Club"
                postId={item?.id}
                postLink={`/club/${item?.id}/${item?.handle}/about`}
              />
            </div>
          ))
        ) : (
          <div>
            <div className="mb-3">
              {getLabelByName(NO_CLUBS_JOINED)}
            </div>
            <div>
              <button
                type="submit"
                className="btn btn-lg submit-btn">
                <Link
                  to="/explore"
                  style={{ color: "unset" }}>
                  {getLabelByName(EXPLORE)}
                </Link>
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  getMyClubs = () => {
    const { clubsData, profileData } = this.state;
    const myClubsIds = Object.keys(profileData.data.clubs);
    const myClubs = clubsData.filter((club) =>
      myClubsIds.includes(club.id),
    );

    return myClubs;
  };
}

export default withRouter(MyClubs);
