import {
  GET_POSTS_ERROR,
  GET_POSTS_SUCCESS,
  CREATE_POST_ERROR,
  CREATE_POST_SUCCESS,
  GET_NEWSFEED_SUCCESS,
  GET_NEWSFEED_ERROR,
  GET_POST_SUCCESS,
  DELETE_POST_SUCCESS,
  DELETE_POST_ERROR,
  GET_POST_ERROR,
  UPDATE_POST_SUCCESS,
  UPDATE_POST_ERROR,
  POST_REACTION_SUCCESS,
  POST_REACTION_ERROR,
  GET_REACTIONS_SUCCESS,
  GET_REACTIONS_ERROR,
  DELETE_REACTION_SUCCESS,
  DELETE_REACTION_ERROR,
  GET_CLUB_POSTS_SUCCESS,
  GET_CLUB_POSTS_ERROR,
  GET_CLUB_ARTICLES_SUCCESS,
  GET_CLUB_ARTICLES_ERROR,
  GET_CLUB_LESSONS_SUCCESS,
  GET_CLUB_LESSONS_ERROR,
} from "./types";
import apiCall from "../../helpers/apiCall";
import { getHeaders } from "../../helpers/getHeaders";

const postsType = (type, payload) => ({
  type,
  payload,
});

export const getPosts =
  (
    clubId,
    postType,
    headers = getHeaders(null, `/club/${clubId}/feed`),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.get(
        `/club/${clubId}/feed`,
        { headers },
      );

      dispatch(
        postsType(
          GET_POSTS_SUCCESS(postType),
          response.data,
        ),
      );

      return response.data;
    } catch (err) {
      dispatch(postsType(GET_POSTS_ERROR(postType), err));
    }
  };

export const createPost =
  (
    payload,
    clubId,
    postType,
    headers = getHeaders(
      payload,
      `/club/${clubId}/${postType}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .post(`/club/${clubId}/${postType}`, payload, {
        headers,
      })
      .then((response) => {
        dispatch(
          postsType(CREATE_POST_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(postsType(CREATE_POST_ERROR, err));
      });
  };

export const createLessonPost =
  (
    payload,
    clubId,
    headers = getHeaders(payload, `/club/${clubId}/lesson`),
  ) =>
  (dispatch) => {
    apiCall
      .post(`/club/${clubId}/lesson`, payload, {
        headers,
      })
      .then((response) => {
        dispatch(
          postsType(CREATE_POST_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(postsType(CREATE_POST_ERROR, err));
      });
  };

export const createPromo =
  (
    payload,
    resourceId,
    resourceType,
    promoType,
    headers = getHeaders(
      payload,
      `/${resourceType}/${resourceId}/${promoType}/promo`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .post(
        `/${resourceType}/${resourceId}/${promoType}/promo`,
        payload,
        { headers },
      )
      .then((response) => {
        dispatch(
          postsType(CREATE_POST_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(postsType(CREATE_POST_ERROR, err));
      });
  };

export const getNewsFeed =
  (
    query,
    headers = getHeaders(null, `/newsfeed${query || ""}`),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/newsfeed${query || ""}`, { headers })
      .then((response) => {
        dispatch(
          postsType(GET_NEWSFEED_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(postsType(GET_NEWSFEED_ERROR, err));
      });
  };

export const getUserPosts =
  (
    query,
    headers = getHeaders(null, `user/posts${query || ""}`),
  ) =>
  (dispatch) => {
    apiCall
      .get(`user/posts${query || ""}`, { headers })
      .then((response) => {
        dispatch(
          postsType(GET_NEWSFEED_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(postsType(GET_NEWSFEED_ERROR, err));
      });
  };

export const getClubPosts =
  (
    clubId,
    headers = getHeaders(null, `/club/${clubId}/posts`),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/club/${clubId}/posts`, { headers })
      .then((response) => {
        dispatch(
          postsType(GET_CLUB_POSTS_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(postsType(GET_CLUB_POSTS_ERROR, err));
      });
  };

export const getClubArticles =
  (
    clubId,
    headers = getHeaders(null, `/club/${clubId}/articles`),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/club/${clubId}/articles`, { headers })
      .then((response) => {
        dispatch(
          postsType(
            GET_CLUB_ARTICLES_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(postsType(GET_CLUB_ARTICLES_ERROR, err));
      });
  };

export const getClubLessons =
  (
    clubId,
    headers = getHeaders(null, `/club/${clubId}/lessons`),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/club/${clubId}/lessons`, { headers })
      .then((response) => {
        dispatch(
          postsType(
            GET_CLUB_LESSONS_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(postsType(GET_CLUB_LESSONS_ERROR, err));
      });
  };

export const getPost =
  (
    clubId,
    articleId,
    headers = getHeaders(
      null,
      `/user/posts?q={"id":${articleId}}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/user/posts?q={"id":${articleId}}`, { headers })
      .then((response) => {
        dispatch(
          postsType(GET_POST_SUCCESS, {
            ...response.data,
            data: response.data.data[0],
          }),
        );
      })
      .catch((err) => {
        dispatch(postsType(GET_POST_ERROR, err));
      });
  };
export const deletePost =
  (
    clubId,
    articleId,
    postType,
    headers = getHeaders(
      null,
      `/club/${clubId}/${postType}/${articleId}`,
    ),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.delete(
        `/club/${clubId}/${postType}/${articleId}`,
        {
          headers,
        },
      );

      dispatch(
        postsType(DELETE_POST_SUCCESS, {
          status: response.status,
          data: "Deleted Successfully",
        }),
      );

      return { status: response.status };
    } catch (err) {
      dispatch(postsType(DELETE_POST_ERROR, err));
    }
  };

export const updatePost =
  (
    payload,
    clubId,
    articleId,
    postType,
    headers = getHeaders(
      payload,
      `/club/${clubId}/${postType}/${articleId}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .put(
        `/club/${clubId}/${postType}/${articleId}`,
        payload,
        { headers },
      )
      .then((response) => {
        dispatch(
          postsType(UPDATE_POST_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(postsType(UPDATE_POST_ERROR, err));
      });
  };

export const updateLesson =
  (
    payload,
    clubId,
    lessonId,
    headers = getHeaders(
      payload,
      `/club/${clubId}/lesson/${lessonId}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .put(`/club/${clubId}/lesson/${lessonId}`, payload, {
        headers,
      })
      .then((response) => {
        dispatch(
          postsType(UPDATE_POST_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(postsType(UPDATE_POST_ERROR, err));
      });
  };

export const postReaction =
  (
    payload,
    postId,
    reaction,
    headers = getHeaders(
      payload,
      `/post/${postId}/reaction/${reaction}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .post(
        `/post/${postId}/reaction/${reaction}`,
        payload,
        { headers },
      )
      .then((response) => {
        dispatch(
          postsType(POST_REACTION_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(postsType(POST_REACTION_ERROR, err));
      });
  };

export const deleteReaction =
  (
    postId,
    reaction,
    headers = getHeaders(
      null,
      `/post/${postId}/reaction/${reaction}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .delete(`/post/${postId}/reaction/${reaction}`, {
        headers,
      })
      .then((response) => {
        dispatch(
          postsType(DELETE_REACTION_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(postsType(DELETE_REACTION_ERROR, err));
      });
  };

export const getReactions =
  (
    postId,
    headers = getHeaders(null, `/post/${postId}/reactions`),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/post/${postId}/reactions`, { headers })
      .then((response) => {
        dispatch(
          postsType(GET_REACTIONS_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(postsType(GET_REACTIONS_ERROR, err));
      });
  };
