/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable dot-notation */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  ArrowBackIos,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@material-ui/icons";
import { toast } from "react-toastify";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import {
  convertToHTML,
  convertFromHTML,
} from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { getSessionAction } from "../redux/actions/authActions";
import {
  getPost,
  updatePost,
  updateLesson,
} from "../redux/actions/postActions";
import Footer from "./Footer";
import Navbar from "./Navbar";
import ImageGridGallery from "./ImageGridGallery";
import likeIcon from "../assets/icons/like.svg";
import commentIcon from "../assets/icons/comment.svg";
import imagePlaceholder from "../assets/icons/image-placeholder.svg";
import {
  checkSession,
  getAllClubsFromLocalStorage,
  getLabelByName,
  getProfileDataFromLocalStorage,
} from "../helpers/helperFunctions";
import FileUpload from "./generics/FileUpload";
import FilePreview from "./generics/FilePreview";
import VideoPlayer from "./generics/VideoPlayer";
import FileDownload from "./generics/FileDownload";
import {
  colors,
  A_WORD,
  BACK,
  BY_WORD,
  CLOSE,
  CONTENT,
  CREATE,
  DESCRIPTION,
  DESCRIPTION_USE_PREVIEW,
  EDIT,
  EDIT_ARTICLE_DESCRIPTION,
  HEADER_IMAGE,
  IN_WORD,
  POSTED,
  PREVIEW,
  TITLE,
  UPDATE,
  UPDATING,
  WRITE,
  VISIBILITY,
  SELECT,
  ALL_USERS,
  MEMBERS_ONLY,
  INVITATION_ONLY,
} from "../helpers/constants";

export class EditLesson extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    textarea: null,
    title: null,
    description: null,
    previewArticle: false,
    isUploading: false,

    headerFile: null,
    headerPreview: null,
    imageFiles: [],
    videoFiles: [],
    documentFiles: [],
    imagePreview: [],
    videoPreview: [],
    documentPreview: [],
    imagesToRemove: [],
    videosToRemove: [],
    documentsToRemove: [],

    profileData: getProfileDataFromLocalStorage(),
    clubsData: getAllClubsFromLocalStorage(),
    pathname: this.props.location.pathname,
    visibility: "",
    visibilityOptions: [
      { value: "PUBLIC", name: getLabelByName(ALL_USERS) },
      {
        value: "PRIVATE",
        name: getLabelByName(MEMBERS_ONLY),
      },
      {
        value: "RESTRICTED",
        name: getLabelByName(INVITATION_ONLY),
      },
    ],
  };

  async UNSAFE_componentWillMount() {
    const { props, state } = this;
    const { profileData, pathname } = state;
    if (!profileData || profileData.status !== 200) {
      window.location.href = `/login?redirect_to=${pathname}`;
    }
    await props.getSessionAction();
    await this.fetchData();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { props, state } = this;
    const { pathname, clubsData } = state;
    const { clubId, lessonId } = props.match.params;
    const {
      dataError,
      status,
      data,
      getPostData,
      updatePostStatus,
      updatePostData,
      history,
    } = nextProps;

    checkSession(data, status, pathname, props);

    if (props.getPostData !== getPostData) {
      const htmlData = getPostData?.data?.content?.body;
      this.setState({
        htmlData,
        editorState: EditorState.createWithContent(
          convertFromHTML(htmlData),
        ),
      });
    }

    switch (updatePostStatus) {
      case "success":
        switch (updatePostData.status) {
          case 200:
            toast.success("Successfully updated!");
            history.push(
              `/club/${clubId}/${
                clubsData.filter(
                  (club) => club.id === clubId,
                )[0].handle
              }/post/${lessonId}`,
            );
            break;
          default:
            toast.error(
              "Posting error!\nPlease try again!",
            );
            break;
        }
        break;
      case "error":
        toast.error("Posting error!\nPlease try again!");
        break;
      default:
        break;
    }
  }

  fetchData = async () => {
    const { props } = this;
    const { clubId, lessonId } = props.match.params;
    await props.getPost(clubId, lessonId);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleEditorChange = (content) => {
    this.setState({ editorState: content });
    this.convertContentToHTML();
  };

  convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(
      this.state.editorState.getCurrentContent(),
    );

    this.setState({ textarea: currentContentAsHTML });
  };

  fileChange = (e) => {
    this.setState({ headerFile: e.target.files[0] });
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.setState({
        headerPreview: fileReader.result,
      });
    };
    fileReader.readAsDataURL(e.target.files[0]);
  };

  switchPreviewArticle = () => {
    this.setState({
      previewArticle: !this.state.previewArticle,
    });
  };

  updateArticle = async (e, contentMedia, authorId) => {
    e.preventDefault();
    const { props, state } = this;
    const { clubId, lessonId } = props.match.params;
    const {
      imageFiles,
      videoFiles,
      documentFiles,
      headerFile,
      visibility,
    } = state;
    const { getPostData } = props;
    const articleData = getPostData.data;
    const listImages = {};
    const listVideos = {};
    const listFiles = {};
    let headerImage;

    const fileMapping = {
      0: "image",
      1: "video",
      2: "file",
    };

    const {
      contentImages,
      contentVideos,
      contentDocuments,
    } = contentMedia;

    let lastImageIndex = 0;
    if (Object.keys(contentImages).length > 0) {
      lastImageIndex =
        Object.keys(contentImages)[
          Object.keys(contentImages).length - 1
        ].split("_")[1];
    }
    let lastVideoIndex = 0;
    if (Object.keys(contentVideos).length > 0) {
      lastVideoIndex =
        Object.keys(contentVideos)[
          Object.keys(contentVideos).length - 1
        ].split("_")[1];
    }
    let lastDocumentIndex = 0;
    if (Object.keys(contentDocuments).length > 0) {
      lastDocumentIndex = Object.keys(contentDocuments)[
        Object.keys(contentDocuments).length - 1
      ].split("_")[1];
    }

    this.setState({ isUploading: true });
    await Promise.all(
      [imageFiles, videoFiles, documentFiles].map(
        async (list, index1) => {
          await Promise.all(
            list.map(async (file, index2) => {
              const formData = new FormData();
              formData.append("file", file.fileItem);
              formData.append("upload_preset", "qtt2g8ao");
              const options = {
                method: "POST",
                body: formData,
              };
              await fetch(
                "https://api.Cloudinary.com/v1_1/ayudigital/auto/upload",
                options,
              )
                .then((res) => res.json())
                .then(async (res) => {
                  const { secure_url } = res;
                  switch (index1) {
                    case 0:
                      listImages[
                        `${fileMapping[index1]}_${
                          parseInt(lastImageIndex, 10) +
                          index2 +
                          1
                        }`
                      ] = secure_url;
                      break;
                    case 1:
                      listVideos[
                        `${fileMapping[index1]}_${
                          parseInt(lastVideoIndex, 10) +
                          index2 +
                          1
                        }`
                      ] = secure_url;
                      break;
                    case 2:
                      listFiles[
                        `${fileMapping[index1]}_${
                          parseInt(lastDocumentIndex, 10) +
                          index2 +
                          1
                        }`
                      ] = secure_url;
                      break;
                    default:
                      break;
                  }
                })
                .catch((err) =>
                  toast.error(
                    "Error uploading media!\nPlease try again.",
                  ),
                );
            }),
          );
        },
      ),
    );

    if (headerFile) {
      const formData = new FormData();
      formData.append("file", headerFile);
      formData.append("upload_preset", "qtt2g8ao");
      const options = {
        method: "POST",
        body: formData,
      };
      await fetch(
        "https://api.Cloudinary.com/v1_1/ayudigital/image/upload",
        options,
      )
        .then((res) => res.json())
        .then(async (res) => {
          const { secure_url } = res;
          headerImage = secure_url;
        })
        .catch((err) =>
          toast.error(
            "Error uploading the picture!\nPlease try again.",
          ),
        );
    }

    const payload = {
      ...articleData,
      authorId,
      content: {
        body: state.textarea || articleData.content.body,
        title: state.title || articleData.content.title,
        description:
          state.description ||
          articleData.content.description,
      },
      visibility,
      images: {
        headerImage:
          headerImage || articleData.images.headerImage,
        ...contentImages,
        ...listImages,
      },
      videos: {
        ...contentVideos,
        ...listVideos,
      },
      files: {
        ...contentDocuments,
        ...listFiles,
      },
    };

    await props.updateLesson(payload, clubId, lessonId);
  };

  multiFileChange = (e, fileType) => {
    const {
      imageFiles,
      videoFiles,
      documentFiles,
      imagePreview,
      videoPreview,
      documentPreview,
    } = this.state;
    let selectedFiles = [];

    if (fileType === "imageFiles") {
      selectedFiles = imageFiles;
    }
    if (fileType === "videoFiles") {
      selectedFiles = videoFiles;
    }
    if (fileType === "documentFiles") {
      selectedFiles = documentFiles;
    }

    let lastIndex = 0;
    let newFiles = [];
    if (selectedFiles.length > 0) {
      lastIndex =
        selectedFiles[selectedFiles.length - 1].index;
    }

    [...e.target.files].forEach((fileItem) => {
      lastIndex += 1;
      newFiles = [
        ...newFiles,
        {
          index: lastIndex,
          fileItem,
        },
      ];
      const fileReader = new FileReader();
      switch (fileType) {
        case "imageFiles":
          fileReader.onloadend = () => {
            this.setState({
              imagePreview: [
                ...imagePreview,
                {
                  index: lastIndex,
                  fileData: fileReader.result,
                },
              ],
            });
          };
          break;
        case "videoFiles":
          fileReader.onloadend = () => {
            this.setState({
              videoPreview: [
                ...videoPreview,
                {
                  index: lastIndex,
                  fileData: fileReader.result,
                },
              ],
            });
          };
          break;
        case "documentFiles":
          fileReader.onloadend = () => {
            this.setState({
              documentPreview: [
                ...documentPreview,
                {
                  index: lastIndex,
                  fileData: fileReader.result,
                },
              ],
            });
          };
          break;
        default:
          break;
      }
      fileReader.readAsDataURL(fileItem);
    });

    this.setState({
      [fileType]: [...selectedFiles, ...newFiles],
    });
  };

  removeFile = (
    image,
    fileType,
    previewList,
    filesList,
  ) => {
    const newPreview = previewList.filter(
      (previewItem) => previewItem !== image,
    );
    const selectedImage = filesList.filter(
      (fileItem) => fileItem.index === image.index,
    );
    const newSelectedFiles = filesList.filter(
      (selectedItem) => selectedItem !== selectedImage[0],
    );
    this.setState({
      [`${fileType}Preview`]: newPreview,
      [`${fileType}Files`]: newSelectedFiles,
    });
  };

  removeExistingFile = (key, fileType, filesToRemove) => {
    this.setState({
      [`${fileType}sToRemove`]: [...filesToRemove, key],
    });
  };

  render() {
    const { REACT_APP_APPLICATION_NAME } = process.env;
    const { clubId, lessonId } = this.props.match.params;
    document.title = `${
      REACT_APP_APPLICATION_NAME || "SOSO"
    } - ${getLabelByName(EDIT)} Lesson`;

    const {
      textarea,
      title,
      previewArticle,
      headerPreview,
      imagePreview,
      description,
      imagesToRemove,
      isUploading,
      imageFiles,
      videoFiles,
      videoPreview,
      documentFiles,
      documentPreview,
      videosToRemove,
      documentsToRemove,
      clubsData,
      profileData,
    } = this.state;
    const { getPostData } = this.props;

    const clubsOptions = clubsData
      ? clubsData.map((club) => ({
          value: club.id,
          text: club.name,
        }))
      : [];

    const userRoles = profileData
      ? profileData.data.roles
      : [];
    const userClubs = profileData
      ? Object.keys(profileData.data.clubs)
      : [];
    let filteredClubs = [];
    if (!userRoles.includes("admin")) {
      filteredClubs = clubsOptions.filter(
        (club) => !userClubs.includes(club.value),
      );
    } else {
      filteredClubs = clubsOptions;
    }

    let imageHeaderDisplay = imagePlaceholder;
    if (
      getPostData &&
      getPostData.data &&
      getPostData.data.images.headerImage
    ) {
      imageHeaderDisplay =
        getPostData.data.images.headerImage;
    }
    if (headerPreview) {
      imageHeaderDisplay = headerPreview;
    }

    let contentImages = {};
    let contentVideos = {};
    let contentDocuments = {};
    let visibility;

    if (
      getPostData &&
      getPostData.data &&
      getPostData.data.visibility
    ) {
      visibility = getPostData.data.visibility;
    }

    if (getPostData && getPostData.data) {
      contentImages = { ...getPostData.data.images };
      delete contentImages.headerImage;
    }
    if (getPostData && getPostData.data) {
      contentVideos = { ...getPostData.data.videos };
    }
    if (getPostData && getPostData.data) {
      contentDocuments = { ...getPostData.data.files };
    }
    imagesToRemove.forEach(
      (item) => delete contentImages[item],
    );
    videosToRemove.forEach(
      (item) => delete contentVideos[item],
    );
    documentsToRemove.forEach(
      (item) => delete contentDocuments[item],
    );
    const bodyImages = Object.values(contentImages);
    const imagesKeys = Object.keys(contentImages);
    const bodyVideos = Object.values(contentVideos);
    const videosKeys = Object.keys(contentVideos);
    const bodyDocuments = Object.values(contentDocuments);
    const documentsKeys = Object.keys(contentDocuments);

    const bodyImagesObjects = bodyImages.map(
      (image, index) => ({
        key: imagesKeys[index],
        value: image,
      }),
    );
    const bodyVideosObjects = bodyVideos.map(
      (video, index) => ({
        key: videosKeys[index],
        value: video,
      }),
    );
    const bodyDocumentsObjects = bodyDocuments.map(
      (document, index) => ({
        key: documentsKeys[index],
        value: document,
      }),
    );

    const contentMedia = {
      contentImages,
      contentVideos,
      contentDocuments,
    };

    const userIsEditor =
      profileData.data.clubs[clubId] &&
      profileData.data.clubs[clubId].role === "editor";
    const userIsClubAdmin =
      profileData.data.clubs[clubId] &&
      profileData.data.clubs[clubId].role === "admin";

    const userCanEdit =
      userIsEditor ||
      userIsClubAdmin ||
      userRoles.includes("admin") ||
      (profileData.data.id === getPostData &&
        getPostData.data &&
        getPostData.data.author.id);

    if (!userCanEdit) {
      toast.error("You cannot edit this article!");
      this.props.history.push(
        `/club/${clubId}/${
          clubsData.filter((club) => club.id === clubId)[0]
            .handle
        }/post/${lessonId}`,
      );
    }

    return (
      <>
        <Navbar pathname="create-article" />
        <div className="club-page">
          <div className="container">
            <div className="buttons-row">
              <div id="back-link">
                <div
                  className="d-flex justify-content-center align-items-center"
                  onClick={() =>
                    this.props.history.goBack()
                  }
                  style={{ cursor: "pointer" }}>
                  <ArrowBackIos className="arrow-back-icon" />
                  {"  "}
                  {getLabelByName(BACK)}
                </div>
              </div>
            </div>

            <form
              onSubmit={(e) =>
                this.updateArticle(
                  e,
                  contentMedia,
                  profileData.data.id,
                )
              }>
              <div className="create-article-header">
                <div className="create-article-title">
                  <div className="article-title">{`${getLabelByName(
                    EDIT,
                  )} Lesson`}</div>
                  <div className="article-helper">
                    {getLabelByName(
                      EDIT_ARTICLE_DESCRIPTION,
                    )}
                  </div>
                </div>
                <div>
                  <div className="edit-button">
                    {!previewArticle ? (
                      <span
                        style={{
                          color: colors.primaryColor,
                        }}
                        onClick={() =>
                          this.switchPreviewArticle()
                        }
                        title={`${getLabelByName(
                          PREVIEW,
                        )} article`}>
                        <VisibilityOutlined />
                      </span>
                    ) : (
                      <span
                        style={{
                          color: colors.primaryColor,
                        }}
                        onClick={() =>
                          this.switchPreviewArticle()
                        }
                        title={`${getLabelByName(
                          CLOSE,
                        )} ${getLabelByName(PREVIEW)}`}>
                        <VisibilityOffOutlined />
                      </span>
                    )}
                    <button
                      type="submit"
                      className="btn btn-lg submit-btn"
                      disabled={
                        !getPostData ||
                        !getPostData.data ||
                        isUploading
                      }>
                      {!isUploading
                        ? getLabelByName(UPDATE)
                        : `${getLabelByName(UPDATING)}...`}
                    </button>
                  </div>
                  <div className="form-group club-select-row">
                    <label htmlFor="club-select">{`${getLabelByName(
                      POSTED,
                    )} ${getLabelByName(IN_WORD)}:`}</label>
                    <select
                      className="form-control"
                      id="club-select"
                      defaultValue={clubId}
                      disabled>
                      {filteredClubs.map((club) => (
                        <option
                          value={club.value}
                          selected={club.value === clubId}>
                          {club.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  {visibility && (
                    <div className="form-group club-select-row">
                      <label htmlFor="club-select">{`${getLabelByName(
                        VISIBILITY,
                      )}:`}</label>
                      <select
                        className="form-control"
                        id="club-select"
                        onChange={(e) =>
                          this.setState({
                            visibility: e.target.value,
                          })
                        }
                        defaultValue={visibility}
                        placeholder={`${getLabelByName(
                          SELECT,
                        )} ${getLabelByName(VISIBILITY)}`}
                        required>
                        {this.state.visibilityOptions.map(
                          (visibility) => (
                            <option
                              value={visibility.value}
                              selected={
                                visibility.value ===
                                visibility
                              }>
                              {visibility.name}
                            </option>
                          ),
                        )}
                      </select>
                    </div>
                  )}
                </div>
              </div>

              {!previewArticle && (
                <>
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="institute-card article-card">
                      <div className="create-card-header">
                        <div className="card-header-title">
                          <h5>{`${getLabelByName(
                            EDIT,
                          )} ${getLabelByName(
                            HEADER_IMAGE,
                          ).toLowerCase()}`}</h5>
                        </div>
                      </div>
                      <div className="create-card-image-preview">
                        <div className="actions-buttons">
                          <div className="action-button">
                            <label htmlFor="customFileHeader">
                              <img
                                src={imageHeaderDisplay}
                                alt="image-preview"
                                width={
                                  (getPostData &&
                                    getPostData.data) ||
                                  headerPreview
                                    ? "100%"
                                    : "100px"
                                }
                                height={
                                  (getPostData &&
                                    getPostData.data) ||
                                  headerPreview
                                    ? "100%"
                                    : "100px"
                                }
                              />
                            </label>
                            <input
                              type="file"
                              className="custom-file-inputz mb-5"
                              id="customFileHeader"
                              onChange={(e) =>
                                this.fileChange(e)
                              }
                              accept="image/*"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-center">
                    <div className="institute-card article-card">
                      <div className="create-card-header">
                        <div className="card-header-title">
                          <h5>
                            {getLabelByName(TITLE)}{" "}
                            <span className="asterisk">
                              *
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="input-name"
                          placeholder={`${getLabelByName(
                            WRITE,
                          )} ${getLabelByName(
                            A_WORD,
                          ).toLowerCase()} ${getLabelByName(
                            TITLE,
                          ).toLowerCase()}...`}
                          value={
                            title !== null
                              ? title
                              : getPostData &&
                                getPostData.data.content
                                  .title
                          }
                          onChange={this.handleChange}
                          style={{
                            backgroundColor: "#FFFFFF",
                          }}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-center">
                    <div className="institute-card article-card">
                      <div className="create-card-header">
                        <div className="card-header-title">
                          <h5>
                            {getLabelByName(DESCRIPTION)}{" "}
                            <span className="asterisk">
                              *
                            </span>
                          </h5>
                          <div className="helper-text">
                            {getLabelByName(
                              DESCRIPTION_USE_PREVIEW,
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="post-textarea description-textarea">
                        <textarea
                          name="description"
                          rows="3"
                          placeholder={`${getLabelByName(
                            WRITE,
                          )} ${getLabelByName(
                            A_WORD,
                          ).toLowerCase()} ${getLabelByName(
                            DESCRIPTION,
                          ).toLowerCase()}...`}
                          value={
                            description !== null
                              ? description
                              : getPostData &&
                                getPostData.data.content
                                  .description
                          }
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-center">
                    <div className="institute-card article-card">
                      <div className="create-card-header">
                        <div className="card-header-title">
                          <h5>
                            {getLabelByName(CONTENT)}{" "}
                            <span className="asterisk">
                              *
                            </span>
                          </h5>
                        </div>
                        <div className="post-buttons">
                          <div className="actions-buttons">
                            <FileUpload
                              componentId="customFileImage"
                              fileType="image"
                              accept="image/*"
                              multiFileChange={
                                this.multiFileChange
                              }
                            />
                            <FileUpload
                              componentId="customFileVideo"
                              fileType="video"
                              accept="video/*"
                              multiFileChange={
                                this.multiFileChange
                              }
                            />
                            <FileUpload
                              componentId="customFileDocument"
                              fileType="document"
                              accept=".pdf, .docx, .doc, .xls, .xlsx, .txt"
                              multiFileChange={
                                this.multiFileChange
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card activity-card activity-post">
                        <div className="card-body">
                          <Editor
                            editorState={
                              this.state.editorState
                            }
                            onEditorStateChange={
                              this.handleEditorChange
                            }
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                          />

                          <div className="image-preview-row mt-2">
                            <div className="d-flex flex-wrap">
                              <FilePreview
                                isUploading={isUploading}
                                fileType="image"
                                filesToRemove={
                                  imagesToRemove
                                }
                                listPreview={
                                  bodyImagesObjects
                                }
                                removeExistingFile={
                                  this.removeExistingFile
                                }
                                existingContent={true}
                              />
                              <FilePreview
                                isUploading={isUploading}
                                fileType="video"
                                filesToRemove={
                                  videosToRemove
                                }
                                listPreview={
                                  bodyVideosObjects
                                }
                                removeExistingFile={
                                  this.removeExistingFile
                                }
                                existingContent={true}
                              />
                              <FilePreview
                                isUploading={isUploading}
                                fileType="document"
                                filesToRemove={
                                  documentsToRemove
                                }
                                listPreview={
                                  bodyDocumentsObjects
                                }
                                removeExistingFile={
                                  this.removeExistingFile
                                }
                                existingContent={true}
                              />
                            </div>
                            <div className="d-flex flex-wrap">
                              <FilePreview
                                isUploading={isUploading}
                                fileType="image"
                                listFiles={imageFiles}
                                listPreview={imagePreview}
                                removeFile={this.removeFile}
                              />
                              <FilePreview
                                isUploading={isUploading}
                                fileType="video"
                                listFiles={videoFiles}
                                listPreview={videoPreview}
                                removeFile={this.removeFile}
                              />
                              <FilePreview
                                isUploading={isUploading}
                                fileType="document"
                                listFiles={documentFiles}
                                listPreview={
                                  documentPreview
                                }
                                removeFile={this.removeFile}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {previewArticle && (
                <div className="institute-card article-card">
                  <div className="campaign-main col-md-12">
                    <div className="container">
                      <div className="display-article-title">
                        {title !== null
                          ? title
                          : getPostData &&
                            getPostData.data.content.title}
                      </div>
                      <div className="display-article-title-small">
                        {description !== null
                          ? description
                          : getPostData &&
                            getPostData.data.content
                              .description}
                      </div>
                      <div className="campaign-author">
                        {getLabelByName(BY_WORD)}{" "}
                        {`${profileData.data.profile.firstName} ${profileData.data.profile.lastName}`}
                      </div>
                      <div className="activity-stats">
                        <div className="activity-stat-item">
                          <div className="activity-stat-icon handicon">
                            <img
                              src={likeIcon}
                              alt="like-icon"
                              width="25px"
                            />
                          </div>
                          0
                        </div>
                        <div className="activity-stat-item">
                          <div className="activity-stat-icon">
                            <img
                              src={commentIcon}
                              alt="comment-icon"
                              width="25px"
                            />
                          </div>
                          0
                        </div>
                      </div>
                      <div className="campaign-content mt-3">
                        {(headerPreview ||
                          (getPostData &&
                            getPostData.data &&
                            getPostData.data.images
                              .headerImage)) && (
                          <div className="article-header-image mb-2">
                            <img
                              src={
                                headerPreview ||
                                (getPostData &&
                                  getPostData.data &&
                                  getPostData.data.images
                                    .headerImage)
                              }
                              alt="header-image"
                              style={{ width: "100%" }}
                            />
                          </div>
                        )}
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              textarea !== null
                                ? textarea
                                : getPostData &&
                                  getPostData.data.content
                                    .body,
                          }}
                        />
                        {imagePreview && (
                          <div className="activity-content-images pb-3">
                            <ImageGridGallery
                              images={bodyImagesObjects
                                .map((image) => image.value)
                                .concat(
                                  imagePreview.map(
                                    (image) =>
                                      image.fileData,
                                  ),
                                )}
                            />
                          </div>
                        )}
                        {bodyVideosObjects.map((video) => (
                          <VideoPlayer
                            video={video.value}
                          />
                        ))}
                        {videoPreview &&
                          videoPreview.map((video) => (
                            <VideoPlayer
                              video={video.fileData}
                            />
                          ))}
                        {bodyDocumentsObjects.map(
                          (document) => (
                            <FileDownload
                              file={document.value}
                            />
                          ),
                        )}
                        {documentPreview &&
                          documentPreview.map(
                            (document) => (
                              <FileDownload
                                file={document.fileData}
                              />
                            ),
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="publish-article-button d-flex justify-content-end mt-3">
                <div className="edit-button">
                  {!previewArticle ? (
                    <span
                      style={{ color: colors.primaryColor }}
                      onClick={() =>
                        this.switchPreviewArticle()
                      }
                      title={`${getLabelByName(
                        PREVIEW,
                      )} article`}>
                      <VisibilityOutlined />
                    </span>
                  ) : (
                    <span
                      style={{ color: colors.primaryColor }}
                      onClick={() =>
                        this.switchPreviewArticle()
                      }
                      title={`${getLabelByName(
                        CLOSE,
                      )} ${getLabelByName(PREVIEW)}`}>
                      <VisibilityOffOutlined />
                    </span>
                  )}
                  <button
                    type="submit"
                    className="btn btn-lg submit-btn"
                    disabled={
                      !getPostData ||
                      !getPostData.data ||
                      isUploading
                    }>
                    {!isUploading
                      ? getLabelByName(UPDATE)
                      : `${getLabelByName(UPDATING)}...`}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="footer-block">
            <div className="footer-component">
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  }
}

EditLesson.propTypes = {
  getSessionAction: PropTypes.func,
  updatePost: PropTypes.func,
  getPost: PropTypes.func,
  match: PropTypes.any,
  dataError: PropTypes.object,
  data: PropTypes.object,
  status: PropTypes.string,
  history: PropTypes.any,
  location: PropTypes.any,
  landingDataError: PropTypes.object,
  landingData: PropTypes.object,
  landingStatus: PropTypes.string,
  updatePostData: PropTypes.object,
  updatePostDataError: PropTypes.object,
  updatePostStatus: PropTypes.string,

  getPostData: PropTypes.any,
  getPostDataError: PropTypes.any,
  getPostStatus: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,

  updatePostData: state.updatePostReducer.updatePostData,
  updatePostDataError:
    state.updatePostReducer.updatePostDataError,
  updatePostStatus:
    state.updatePostReducer.updatePostStatus,

  getPostData: state.getPostReducer.getPostData,
  getPostDataError: state.getPostReducer.getPostDataError,
  getPostStatus: state.getPostReducer.getPostStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getSessionAction,
    updatePost,
    updateLesson,
    getPost,
  }),
)(EditLesson);
