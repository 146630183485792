/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable dot-notation */
/* eslint-disable global-require */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  LockOpenOutlined,
  CheckCircleOutline,
  CancelOutlined,
} from "@material-ui/icons";
import { toast } from "react-toastify";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import {
  changePasswordAction,
  getSessionAction,
} from "../redux/actions/authActions";
import {
  changeUsername,
  editProfile,
  uploadPicture,
} from "../redux/actions/editProfileActions";
import { getUserClubs } from "../redux/actions/institutesActions";
import { getActivities } from "../redux/actions/campaignsActions";
import { getHeaders } from "../helpers/getHeaders";
import Footer from "./Footer";
import avatar from "../assets/avatar-placeholder.png";
import lock from "../assets/icons/lock.svg";
import logo from "../assets/logo-opac.svg";
import activityDefaultImage from "../assets/manage-campaigns.svg";
import Navbar from "./Navbar";
import Fallback from "./Fallback";
import {
  clearLocalStorage,
  getLabelByName,
  isSectionAdmin,
} from "../helpers/helperFunctions";
import {
  colors,
  ACCOUNT,
  CHANGE,
  CITY,
  COUNTRY,
  EDIT,
  EMAIL,
  FEMALE,
  FIRSTNAME,
  GENDER,
  LASTNAME,
  LOGOUT,
  MALE,
  MEMBER,
  MY_PLURAL,
  MY_SINGULAR,
  OTHER,
  PASSWORD,
  PROFILE,
  NO_WORD,
  YET_WORD,
  SAVING,
  SAVE,
  CLOSE,
  CANCEL,
  CONFIRM,
  CHANGING,
  CHANGED_PASSWORD_SUCCESS,
  USERNAME,
  CURRENT,
  NEW,
  CHANGED_USERNAME_SUCCESS,
  PICTURE,
  UPLOADING,
  UPLOAD,
  CHANGED_PICTURE_SUCCESS,
  ACTIVITIES,
  ACTIVITY,
} from "../helpers/constants";
import logoutIcon from "../assets/icons/logout-orange.svg";
import editIcon from "../assets/icons/edit-orange.svg";
import cameraOutline from "../assets/icons/cameraOutline.svg";

export class Profile extends Component {
  state = {
    componentDisplay: "profile",

    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    isSending: false,
    isSent: false,
    isEditing: false,

    firstName: null,
    lastName: null,
    location_country: null,
    location_city: null,
    gender: null,

    currentEmailAddress: "",
    newEmailAddress: "",

    photoFile: null,
    photoUrl: null,
    crop: {
      unit: "%",
      width: 30,
      aspect: 1 / 1,
    },
    croppedImageUrl: null,
    croppedImage: null,

    pathname: this.props.location.pathname,
  };

  async UNSAFE_componentWillMount() {
    const { props } = this;

    const query = new URLSearchParams(
      props.location.search,
    );
    if (query.get("open_admin") === "true") {
      this.setState({ componentDisplay: "admin" });
    }
    await props.getSessionAction();

    this.setState({ isLoading: true });
    await this.fetchData();
    this.setState({ isLoading: false });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      changeData,
      changeDataError,
      changeStatus,
      userEditData,
      userEditDataError,
      userEditStatus,
    } = nextProps;

    switch (changeStatus) {
      case "success":
        this.setState((state) => ({
          ...state,
          isLoading: false,
          isSending: false,
        }));
        switch (changeData.status) {
          case 500:
            toast.error(changeData.error.message);
            break;
          default:
            this.setState((state) => ({
              ...state,
              isSent: true,
            }));
            break;
        }
        break;
      case "error":
        this.setState((state) => ({
          ...state,
          isLoading: false,
          isSending: false,
        }));
        toast.error(changeDataError.message);
        break;
      default:
        break;
    }

    switch (userEditStatus) {
      case "success":
        this.setState((state) => ({
          ...state,
          isLoading: false,
          isSending: false,
        }));
        switch (userEditData.status) {
          case 500:
            toast.error(userEditData.error.message);
            break;
          default:
            localStorage.setItem(
              "profileData",
              JSON.stringify(userEditData),
            );
            setTimeout(() => {
              window.location.href = "/profile";
            }, 1500);
            this.setState((state) => ({
              ...state,
              isSent: true,
            }));
            break;
        }
        break;
      case "error":
        this.setState((state) => ({
          ...state,
          isLoading: false,
          isSending: false,
        }));
        toast.error(userEditDataError.message);
        break;
      default:
        break;
    }
  }

  fetchData = async () => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    const { props } = this;
    await props.getUserClubs();
    await props.getActivities();
    this.setState((state) => ({
      ...state,
      isLoading: false,
    }));
  };

  changePassword = async (e) => {
    e.preventDefault();
    const { props } = this;
    const {
      currentPassword,
      newPassword,
      confirmPassword,
    } = this.state;
    const payload = {
      currentPassword,
      newPassword,
      confirmPassword,
    };
    const userHeaders = getHeaders(
      payload,
      "/user/password",
    );

    this.setState((state) => ({
      ...state,
      isSending: true,
    }));
    await props.changePasswordAction(payload, userHeaders);
  };

  logout = () => {
    clearLocalStorage();
    window.location.href = "/";
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  fileChange = (e) => {
    this.setState({ croppedImage: e.target.files[0] });
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.setState({
        photoFile: fileReader.result,
      });
    };
    fileReader.readAsDataURL(e.target.files[0]);
  };

  onFileUpload = async (userData) => {
    const { props } = this;
    this.setState((state) => ({
      ...state,
      isSending: true,
    }));
    const formData = new FormData();
    formData.append("file", this.state.croppedImage);
    formData.append("upload_preset", "qtt2g8ao");
    const options = {
      method: "POST",
      body: formData,
    };
    fetch(
      "https://api.Cloudinary.com/v1_1/ayudigital/image/upload",
      options,
    )
      .then((res) => res.json())
      .then(async (res) => {
        const { secure_url } = res;
        const payload = userData;
        payload.profile.picture = secure_url;

        await props.editProfile(payload, userData.id);
      })
      .catch((err) =>
        toast.error(
          "Error uploading the picture!\nPlease try again.",
        ),
      )
      .finally(() =>
        this.setState((state) => ({
          ...state,
          isSending: false,
        })),
      );
  };

  saveProfileChanges = async (userData) => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    const { props } = this;
    const {
      firstName,
      lastName,
      location_country,
      location_city,
      gender,
    } = this.state;

    const newProfile = {
      firstName: firstName || userData.profile.firstName,
      lastName: lastName || userData.profile.lastName,
      location_country:
        location_country ||
        userData.profile.location_country,
      location_city:
        location_city || userData.profile.location_city,
      gender: gender || userData.profile.gender,
    };

    if (userData.profile.picture) {
      newProfile.picture = userData.profile.picture;
    }

    const payload = userData;
    payload.profile = newProfile;

    await props.editProfile(payload, userData.id);
  };

  saveUsernameChange = async (e, currentEmail) => {
    e.preventDefault();
    const { newEmailAddress } = this.state;
    if (
      newEmailAddress &&
      newEmailAddress !== currentEmail
    ) {
      const { props } = this;
      const payload = {
        currentEmailAddress: currentEmail,
        newEmailAddress,
      };
      this.setState((state) => ({
        ...state,
        isSending: true,
      }));
      await props.changeUsername(payload);
    }
  };

  cancelProfileChanges = () => {
    window.location.href = "/profile";
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(
        this.imageRef,
        crop,
      );
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.dataURLtoFile(
          reader.result,
          `profile_pic${Math.random()
            .toString(36)
            .slice(-8)}.jpg`,
        );
      };
    });
  };

  dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const croppedImage = new File([u8arr], filename, {
      type: mime,
    });
    this.setState({ croppedImage });
  };

  renderItems = (
    items,
    listItems,
    itemType,
    defaultLogo,
  ) => {
    const displayData = [];
    const { data } = items;

    listItems.forEach((item) => {
      displayData.push(
        data.filter(
          (club) =>
            club.id === item && club.status === "ACTIVE",
        )[0],
      );
    });

    return (
      <div className="list-items">
        {displayData.length === 0 &&
          `${getLabelByName(
            NO_WORD,
          )} ${itemType} ${getLabelByName(YET_WORD)}!`}
        {itemType === "campaign" &&
          displayData.map(
            (item, index) =>
              item &&
              item !== undefined && (
                <div
                  className="card item-card"
                  key={index}
                  onClick={() =>
                    this.props.history.push(
                      `${itemType}/${item.id}/${item.handle}`,
                    )
                  }>
                  <div
                    style={{
                      backgroundImage: `url(${
                        item && item.profile.picture
                          ? item.profile.picture
                          : defaultLogo
                      })`,
                      height: "200px",
                      maxHeight: "220px",
                      objectFit: "cover",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}></div>
                  <div className="card-body">
                    <p className="card-text d-flex align-items-center">
                      <img
                        className="img-fluid rounded-circle mr-3"
                        src={
                          item && item.profile.avatar
                            ? `http://www.almamaters.club/image/avatar/${item.profile.avatar}`
                            : defaultLogo
                        }
                        alt="club-avatar"
                      />
                      {item.name}
                    </p>
                  </div>
                </div>
              ),
          )}
        {itemType === "club" &&
          displayData.map(
            (item, index) =>
              item &&
              item !== undefined && (
                <div
                  className="card item-card text-center d-flex align-items-center justify-content-center"
                  key={index}
                  onClick={() =>
                    this.props.history.push(
                      `${itemType}/${item.id}/${item.handle}`,
                    )
                  }>
                  <div className="d-flex align-items-center justify-content-center">
                    <img
                      className="img-fluid rounded-circle mr-3"
                      src={
                        item && item.profile.picture
                          ? item.profile.picture
                          : defaultLogo
                      }
                      alt="club-avatar"
                      style={{
                        width: "110px",
                        height: "110px",
                        objectFit: "cover",
                        padding: "1rem",
                      }}
                    />
                  </div>
                  <hr className="card-hr" />
                  <div className="card-body">
                    <p className="card-text text-center">
                      {item.name}
                    </p>
                  </div>
                </div>
              ),
          )}
      </div>
    );
  };

  ClubsComponent = ({
    clubs,
    listInstitutes,
    defaultLogo,
  }) => (
    <div className="card my-profile">
      <div className="card-body">
        <h5 className="card-title">
          {getLabelByName(MY_PLURAL)} Clubs
        </h5>
        {this.renderItems(
          clubs,
          listInstitutes,
          "club",
          defaultLogo,
        )}
      </div>
    </div>
  );

  CampaignsComponent = ({
    campaigns,
    listCampaigns,
    defaultLogo,
  }) => (
    <div className="card my-profile">
      <div className="card-body">
        <h5 className="card-title">
          {getLabelByName(MY_PLURAL)}{" "}
          {getLabelByName(ACTIVITIES)}
        </h5>
        {this.renderItems(
          campaigns,
          listCampaigns,
          "campaign",
          defaultLogo,
        )}
      </div>
    </div>
  );

  render() {
    const { REACT_APP_APPLICATION_NAME } = process.env;
    document.title = `${
      REACT_APP_APPLICATION_NAME || "The Alma Maters Club"
    } - ${getLabelByName(PROFILE)}`;

    const {
      getUserClubsData,
      getActivitiesData,
      getUserClubsStatus,
      getActivitiesStatus,
      landingData,
      profileData,
    } = this.props;

    const isAdmin =
      profileData &&
      profileData.data &&
      profileData.data.roles.includes("admin");

    const userIsASectionAdmin =
      isSectionAdmin(["site_admin"]) ||
      isSectionAdmin(["club_admin"]) ||
      isSectionAdmin(["activity_admin"]) ||
      isSectionAdmin(["users_admin"]) ||
      isSectionAdmin(["posts_admin"]) ||
      isSectionAdmin(["questionnaires_admin"]);

    if (
      getUserClubsStatus &&
      getActivitiesStatus &&
      (!getUserClubsData || !getActivitiesData)
    ) {
      return <Fallback route="/profile" />;
    }

    if (
      getUserClubsStatus &&
      getActivitiesStatus &&
      getUserClubsData &&
      getActivitiesData &&
      (getUserClubsData.status === 500 ||
        getActivitiesData.status === 500)
    ) {
      return <Fallback route="/profile" />;
    }

    const userData = profileData ? profileData.data : {};

    const activities = userData?.activities;
    const clubs = userData?.clubs;

    const listCampaigns = activities
      ? Object.keys(activities)
      : [];
    const listInstitutes = clubs ? Object.keys(clubs) : [];

    const myClubs = [];

    listInstitutes.forEach((institute) => {
      myClubs.push(
        getUserClubsData?.data?.filter(
          (club) => club.id === institute,
        ),
      );
    });

    const myClubsLength = myClubs.filter(
      (clubs) => clubs?.length > 0,
    ).length;

    const myActivities = [];

    listCampaigns.forEach((campaign) => {
      myActivities.push(
        getActivitiesData?.data?.filter(
          (activity) =>
            activity.id === campaign &&
            activity.status === "ACTIVE",
        ),
      );
    });

    const myActivitiesLength = myActivities.filter(
      (activities) => activities?.length > 0,
    ).length;

    const {
      componentDisplay,

      currentPassword,
      newPassword,
      confirmPassword,
      isSending,
      isSent,
      isEditing,
      isLoading,
      firstName,
      lastName,
      location_country,
      location_city,
      newEmailAddress,
      currentEmailAddress,

      photoFile,
      crop,
    } = this.state;

    let defaultLogo = logo;
    if (landingData && landingData.data) {
      const landingInfo = landingData.data.filter(
        (x) => x.name === "landing-page",
      )[0];
      if (
        landingInfo &&
        landingInfo.data &&
        landingInfo.data["logo"]
      ) {
        defaultLogo = landingInfo.data["logo"];
      }
    }

    return (
      <>
        <Navbar pathname="profile" />
        <div className="profile-page">
          <div className="container-fluid">
            <div className="row mt-5">
              <div className="col-md-5 left-block">
                <div className="row d-flex justify-content-around align-items-center">
                  <div className="col-xs-4 my-profile-picture">
                    <img
                      src={
                        userData?.profile?.picture
                          ? userData.profile.picture
                          : avatar
                      }
                      alt="avatar"
                    />
                  </div>
                  <div className="col-xs-8">
                    <p className="profile-left-name">
                      {userData?.profile.firstName}{" "}
                      {userData?.profile.lastName}
                    </p>
                    <p className="profile-left-details">
                      {getLabelByName(MEMBER)}
                      <br />
                      {userData?.profile.location_city}
                      {", "}
                      {userData?.profile.location_country}
                    </p>
                  </div>
                </div>
                <div
                  className={`card profile-card-sm ${
                    componentDisplay === "profile" &&
                    "card-sm-active"
                  }`}
                  onClick={() =>
                    this.setState({
                      componentDisplay: "profile",
                    })
                  }>
                  <div className="card-body">
                    <h5 className="card-title">
                      {getLabelByName(MY_SINGULAR)}{" "}
                      {getLabelByName(ACCOUNT)}
                    </h5>
                  </div>
                </div>
                <div
                  className={`card profile-card-sm ${
                    componentDisplay === "campaigns" &&
                    "card-sm-active"
                  }`}
                  onClick={() =>
                    this.setState({
                      componentDisplay: "campaigns",
                    })
                  }>
                  <div className="card-body d-flex justify-content-between align-items-center">
                    <h5 className="card-title">
                      {getLabelByName(MY_PLURAL)}{" "}
                      {getLabelByName(ACTIVITIES)}
                    </h5>
                    <p className="card-sm-right">
                      {myActivitiesLength}{" "}
                      {myActivitiesLength > 1
                        ? getLabelByName(ACTIVITIES)
                        : getLabelByName(ACTIVITY)}
                    </p>
                  </div>
                </div>
                <div
                  className={`card profile-card-sm ${
                    componentDisplay === "clubs" &&
                    "card-sm-active"
                  }`}
                  onClick={() =>
                    this.setState({
                      componentDisplay: "clubs",
                    })
                  }>
                  <div className="card-body d-flex justify-content-between align-items-center">
                    <h5 className="card-title">
                      {getLabelByName(MY_PLURAL)} Clubs
                    </h5>
                    <p className="card-sm-right">
                      {myClubsLength}{" "}
                      {`Club${
                        myClubsLength > 1 ? "s" : ""
                      }`}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-7 right-block">
                {componentDisplay === "profile" && (
                  <div className="card my-profile">
                    <div className="card-body">
                      <h5 className="card-title">
                        {getLabelByName(MY_SINGULAR)}{" "}
                        {getLabelByName(PROFILE)}
                      </h5>
                      <div className="text-center mb-1">
                        <div className="my-profile-picture">
                          <img
                            src={
                              userData &&
                              userData.profile.picture
                                ? userData.profile.picture
                                : avatar
                            }
                            alt="avatar"
                          />
                        </div>
                        <div>
                          <div
                            className="my-profile-label my-profile-actions mt-1 mb-1"
                            data-toggle="modal"
                            data-target=".upload-picture-modal">
                            <img
                              src={cameraOutline}
                              style={{ width: "20px" }}
                              alt="nav-icon"
                            />{" "}
                            {getLabelByName(CHANGE)}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row d-flex justify-content-around">
                        <div className="col-xs-4 mt-2 mb-2">
                          <div>
                            <span className="my-profile-label">
                              {getLabelByName(FIRSTNAME)}
                            </span>
                            <br />
                            {!isEditing ? (
                              <span className="my-profile-value">
                                {
                                  userData?.profile
                                    ?.firstName
                                }
                              </span>
                            ) : (
                              <input
                                type="text"
                                name="firstName"
                                className="form-control"
                                id="input-firstname"
                                placeholder={getLabelByName(
                                  FIRSTNAME,
                                )}
                                value={
                                  firstName === null
                                    ? userData?.profile
                                        .firstName
                                    : firstName
                                }
                                onChange={this.handleChange}
                                required
                              />
                            )}
                          </div>
                          <br />
                          <div>
                            <span className="my-profile-label">
                              {getLabelByName(EMAIL)}
                            </span>
                            <br />
                            <span className="my-profile-value">
                              {`${userData.username.slice(
                                0,
                                5,
                              )}***${userData.username.slice(
                                userData.username.length -
                                  4,
                              )}`}{" "}
                              <span
                                data-toggle="modal"
                                data-target=".change-username-modal"
                                style={{
                                  cursor: "pointer",
                                }}>
                                <img
                                  src={editIcon}
                                  style={{ width: "20px" }}
                                  alt="nav-icon"
                                />
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="col-xs-4 mt-2 mb-2">
                          <div>
                            <span className="my-profile-label">
                              {getLabelByName(LASTNAME)}
                            </span>
                            <br />
                            {!isEditing ? (
                              <span className="my-profile-value">
                                {userData.profile.lastName}
                              </span>
                            ) : (
                              <input
                                type="text"
                                name="lastName"
                                className="form-control"
                                id="input-lastname"
                                placeholder={getLabelByName(
                                  LASTNAME,
                                )}
                                value={
                                  lastName === null
                                    ? userData.profile
                                        .lastName
                                    : lastName
                                }
                                onChange={this.handleChange}
                                required
                              />
                            )}
                          </div>
                          <br />
                          <div>
                            <span className="my-profile-label">
                              {getLabelByName(CITY)}
                            </span>
                            <br />
                            {!isEditing ? (
                              <span className="my-profile-value">
                                {
                                  userData.profile
                                    .location_city
                                }
                              </span>
                            ) : (
                              <input
                                type="text"
                                name="location_city"
                                className="form-control"
                                id="input-city"
                                placeholder={getLabelByName(
                                  CITY,
                                )}
                                value={
                                  location_city === null
                                    ? userData.profile
                                        .location_city
                                    : location_city
                                }
                                onChange={this.handleChange}
                                required
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-xs-4 mt-2 mb-2">
                          <div>
                            <span className="my-profile-label">
                              {getLabelByName(GENDER)}
                            </span>
                            <br />
                            {!isEditing ? (
                              <span className="my-profile-value">
                                {getLabelByName(
                                  userData.profile.gender,
                                )}
                              </span>
                            ) : (
                              <select
                                className="form-control"
                                id="input-gender"
                                defaultValue={
                                  userData.profile.gender
                                }
                                onChange={(e) =>
                                  this.setState({
                                    gender: e.target.value,
                                  })
                                }>
                                <option value="female">
                                  {getLabelByName(FEMALE)}
                                </option>
                                <option value="male">
                                  {getLabelByName(MALE)}
                                </option>
                                <option value="other">
                                  {getLabelByName(OTHER)}
                                </option>
                              </select>
                            )}
                          </div>
                          <br />
                          <div>
                            <span className="my-profile-label">
                              {getLabelByName(COUNTRY)}
                            </span>
                            <br />
                            {!isEditing ? (
                              <span className="my-profile-value">
                                {
                                  userData.profile
                                    .location_country
                                }
                              </span>
                            ) : (
                              <input
                                type="text"
                                name="location_country"
                                className="form-control"
                                id="input-country"
                                placeholder={getLabelByName(
                                  COUNTRY,
                                )}
                                value={
                                  location_country === null
                                    ? userData.profile
                                        .location_country
                                    : location_country
                                }
                                onChange={this.handleChange}
                                required
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-around">
                        <div
                          className="my-profile-label my-profile-actions mt-1 mb-1"
                          data-toggle="modal"
                          data-target=".change-password-modal">
                          <LockOpenOutlined
                            style={{
                              color: colors.primaryColor,
                            }}
                          />{" "}
                          {getLabelByName(CHANGE)}{" "}
                          {getLabelByName(PASSWORD)}
                        </div>
                        <div
                          className="my-profile-label my-profile-actions mt-1 mb-1"
                          onClick={() =>
                            this.setState({
                              isEditing: true,
                            })
                          }>
                          {!isEditing ? (
                            <>
                              <img
                                src={editIcon}
                                style={{ width: "20px" }}
                                alt="nav-icon"
                              />{" "}
                              {getLabelByName(EDIT)}{" "}
                              {getLabelByName(PROFILE)}
                            </>
                          ) : (
                            <>
                              {isLoading ? (
                                <>
                                  <div
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    style={{
                                      color:
                                        colors.primaryColor,
                                    }}>
                                    <span className="sr-only">
                                      Loading...
                                    </span>
                                  </div>{" "}
                                  {`${getLabelByName(
                                    SAVING,
                                  )}...`}
                                </>
                              ) : (
                                <span
                                  onClick={() =>
                                    this.saveProfileChanges(
                                      userData,
                                    )
                                  }>
                                  <CheckCircleOutline
                                    style={{
                                      color:
                                        colors.primaryColor,
                                    }}
                                  />{" "}
                                  {getLabelByName(SAVE)}
                                  {" / "}
                                </span>
                              )}
                              {!isLoading && (
                                <span
                                  onClick={() =>
                                    this.cancelProfileChanges()
                                  }>
                                  <CancelOutlined
                                    style={{
                                      color:
                                        colors.primaryColor,
                                    }}
                                  />{" "}
                                  {getLabelByName(CANCEL)}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                        <div
                          className="my-profile-label my-profile-actions mt-1 mb-1"
                          onClick={this.logout}>
                          <img
                            src={logoutIcon}
                            style={{
                              color: colors.primaryColor,
                              width: "20px",
                            }}
                            alt="nav-icon"
                          />
                          {/* <ExitToAppTwoTone style={{ color: colors.primaryColor }} /> */}{" "}
                          {getLabelByName(LOGOUT)}
                        </div>
                      </div>

                      {/* Change Password Modal */}
                      <div
                        className="modal fade change-password-modal"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="changePasswordModal"
                        aria-hidden="true">
                        <div
                          className="modal-dialog modal-lg modal-dialog-centered"
                          role="document">
                          <div className="modal-content modal-card p-4">
                            <div className="d-flex justify-content-center">
                              <img
                                className="logo-modal"
                                src={defaultLogo}
                                alt="Logo"
                              />
                            </div>
                            <p className="forgot-modal-title text-center">
                              {getLabelByName(CHANGE)}{" "}
                              {getLabelByName(PASSWORD)}
                            </p>
                            {!isSent ? (
                              <>
                                <form
                                  onSubmit={
                                    this.changePassword
                                  }>
                                  <div className="row d-flex justify-content-center">
                                    <div className="form-icon col-md-12">
                                      <div className="form-group form-label-group">
                                        <input
                                          type="password"
                                          name="currentPassword"
                                          className="form-control"
                                          id="input-current-password"
                                          placeholder={`${getLabelByName(
                                            CURRENT,
                                          )} ${getLabelByName(
                                            PASSWORD,
                                          )}`}
                                          value={
                                            currentPassword
                                          }
                                          onChange={
                                            this
                                              .handleChange
                                          }
                                          style={{
                                            backgroundColor:
                                              "none",
                                          }}
                                          required
                                        />
                                        <label htmlFor="input-current-password">
                                          {`${getLabelByName(
                                            CURRENT,
                                          )} ${getLabelByName(
                                            PASSWORD,
                                          )}`}
                                        </label>
                                      </div>
                                      <img
                                        src={lock}
                                        alt="lock icon"
                                      />
                                    </div>
                                  </div>
                                  <div className="row d-flex justify-content-center">
                                    <div className="form-icon col-md-12">
                                      <div className="form-group form-label-group">
                                        <input
                                          type="password"
                                          name="newPassword"
                                          className="form-control"
                                          id="input-new-password"
                                          placeholder={`${getLabelByName(
                                            NEW,
                                          )} ${getLabelByName(
                                            PASSWORD,
                                          )}`}
                                          value={
                                            newPassword
                                          }
                                          onChange={
                                            this
                                              .handleChange
                                          }
                                          style={{
                                            backgroundColor:
                                              "none",
                                          }}
                                          required
                                        />
                                        <label htmlFor="input-new-password">
                                          {`${getLabelByName(
                                            NEW,
                                          )} ${getLabelByName(
                                            PASSWORD,
                                          )}`}
                                        </label>
                                      </div>
                                      <img
                                        src={lock}
                                        alt="lock icon"
                                      />
                                    </div>
                                  </div>
                                  <div className="row d-flex justify-content-center">
                                    <div className="form-icon col-md-12">
                                      <div className="form-group form-label-group">
                                        <input
                                          type="password"
                                          name="confirmPassword"
                                          className="form-control"
                                          id="input-confirm-password"
                                          placeholder={`${getLabelByName(
                                            CONFIRM,
                                          )} ${getLabelByName(
                                            PASSWORD,
                                          )}`}
                                          value={
                                            confirmPassword
                                          }
                                          onChange={
                                            this
                                              .handleChange
                                          }
                                          style={{
                                            backgroundColor:
                                              "none",
                                          }}
                                          required
                                        />
                                        <label htmlFor="input-confirm-password">
                                          {`${getLabelByName(
                                            CONFIRM,
                                          )} ${getLabelByName(
                                            PASSWORD,
                                          )}`}
                                        </label>
                                      </div>
                                      <img
                                        src={lock}
                                        alt="lock icon"
                                      />
                                    </div>
                                  </div>
                                  <div className="row d-flex justify-content-center">
                                    <button
                                      type="submit"
                                      className="btn btn-lg submit-btn">
                                      {isSending ? (
                                        <>
                                          <span>
                                            {getLabelByName(
                                              CHANGING,
                                            )}
                                            {"  "}
                                          </span>
                                          <div
                                            className="spinner-border spinner-border-sm"
                                            role="status">
                                            <span className="sr-only">
                                              Loading...
                                            </span>
                                          </div>
                                        </>
                                      ) : (
                                        `${getLabelByName(
                                          CHANGE,
                                        )} ${getLabelByName(
                                          PASSWORD,
                                        )}`
                                      )}
                                    </button>
                                  </div>
                                </form>
                              </>
                            ) : (
                              <p className="forgot-modal-text text-center">
                                {getLabelByName(
                                  CHANGED_PASSWORD_SUCCESS,
                                )}
                              </p>
                            )}

                            <br />
                            <div className="row d-flex justify-content-center">
                              <hr
                                style={{
                                  border:
                                    ".5px solid #A8A8A8",
                                  width: "50%",
                                }}
                              />
                            </div>
                            <br />
                            <div className="row d-flex justify-content-center">
                              <small
                                onClick={() =>
                                  this.setState(
                                    (state) => ({
                                      ...state,
                                      isSent: false,
                                    }),
                                  )
                                }>
                                <p
                                  className="back-to-sign"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  style={{
                                    color:
                                      colors.textPrimary,
                                    textDecoration:
                                      "underline",
                                  }}>
                                  {getLabelByName(CLOSE)}
                                </p>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Change Username Modal */}
                      <div
                        className="modal fade change-username-modal"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="changeUsernameModal"
                        aria-hidden="true">
                        <div
                          className="modal-dialog modal-lg modal-dialog-centered"
                          role="document">
                          <div className="modal-content modal-card p-4">
                            <div className="d-flex justify-content-center">
                              <img
                                className="logo-modal"
                                src={defaultLogo}
                                alt="Logo"
                              />
                            </div>
                            <p className="forgot-modal-title text-center">
                              {`${getLabelByName(
                                CHANGE,
                              )} ${getLabelByName(
                                USERNAME,
                              )}`}
                            </p>
                            {!isSent ? (
                              <>
                                <form
                                  onSubmit={(e) =>
                                    this.saveUsernameChange(
                                      e,
                                      userData.username,
                                    )
                                  }>
                                  <div className="row d-flex justify-content-center">
                                    <div className="form-icon col-md-12">
                                      <div className="form-group form-label-group">
                                        <input
                                          type="email"
                                          name="currentEmailAddress"
                                          className="form-control"
                                          id="input-current-email"
                                          placeholder={`${getLabelByName(
                                            CURRENT,
                                          )} ${getLabelByName(
                                            EMAIL,
                                          )}`}
                                          value={
                                            currentEmailAddress
                                          }
                                          onChange={
                                            this
                                              .handleChange
                                          }
                                          style={{
                                            backgroundColor:
                                              "none",
                                          }}
                                          required
                                        />
                                        <label htmlFor="input-current-email">
                                          {`${getLabelByName(
                                            CURRENT,
                                          )} ${getLabelByName(
                                            EMAIL,
                                          )}`}
                                        </label>
                                      </div>
                                      <img
                                        src={lock}
                                        alt="lock icon"
                                      />
                                    </div>
                                  </div>
                                  <div className="row d-flex justify-content-center">
                                    <div className="form-icon col-md-12">
                                      <div className="form-group form-label-group">
                                        <input
                                          type="email"
                                          name="newEmailAddress"
                                          className="form-control"
                                          id="input-new-email"
                                          placeholder={`${getLabelByName(
                                            NEW,
                                          )} ${getLabelByName(
                                            EMAIL,
                                          )}`}
                                          value={
                                            newEmailAddress
                                          }
                                          onChange={
                                            this
                                              .handleChange
                                          }
                                          style={{
                                            backgroundColor:
                                              "none",
                                          }}
                                          required
                                        />
                                        <label htmlFor="input-new-email">
                                          {`${getLabelByName(
                                            NEW,
                                          )} ${getLabelByName(
                                            EMAIL,
                                          )}`}
                                        </label>
                                      </div>
                                      <img
                                        src={lock}
                                        alt="lock icon"
                                      />
                                    </div>
                                  </div>
                                  <div className="row d-flex justify-content-center">
                                    <button
                                      type="submit"
                                      className="btn btn-lg submit-btn">
                                      {isSending ? (
                                        <>
                                          <span>
                                            {getLabelByName(
                                              CHANGING,
                                            )}
                                            {"  "}
                                          </span>
                                          <div
                                            className="spinner-border spinner-border-sm"
                                            role="status">
                                            <span className="sr-only">
                                              Loading...
                                            </span>
                                          </div>
                                        </>
                                      ) : (
                                        `${getLabelByName(
                                          CHANGE,
                                        )} ${getLabelByName(
                                          USERNAME,
                                        )}`
                                      )}
                                    </button>
                                  </div>
                                </form>
                              </>
                            ) : (
                              <p className="forgot-modal-text text-center">
                                {getLabelByName(
                                  CHANGED_USERNAME_SUCCESS,
                                )}
                              </p>
                            )}

                            <br />
                            <div className="row d-flex justify-content-center">
                              <hr
                                style={{
                                  border:
                                    ".5px solid #A8A8A8",
                                  width: "50%",
                                }}
                              />
                            </div>
                            <br />
                            <div className="row d-flex justify-content-center">
                              <small
                                onClick={() =>
                                  this.setState(
                                    (state) => ({
                                      ...state,
                                      isSent: false,
                                    }),
                                  )
                                }>
                                <p
                                  className="back-to-sign"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  style={{
                                    color:
                                      colors.textPrimary,
                                    textDecoration:
                                      "underline",
                                  }}>
                                  {getLabelByName(CLOSE)}
                                </p>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Change Picture Modal */}
                      <div
                        className="modal fade upload-picture-modal"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="changePictureModal"
                        aria-hidden="true">
                        <div
                          className="modal-dialog modal-lg modal-dialog-centered"
                          role="document">
                          <div className="modal-content modal-card p-4">
                            <div className="d-flex justify-content-center">
                              <img
                                className="logo-modal"
                                src={defaultLogo}
                                alt="Logo"
                              />
                            </div>
                            <p className="forgot-modal-title text-center">
                              {`${getLabelByName(
                                CHANGE,
                              )} ${getLabelByName(
                                PICTURE,
                              )}`}
                            </p>
                            {!isSent ? (
                              <>
                                <div className="text-center d-flex flex-column justify-content-center align-items-center mb-2">
                                  <div className="picture-upload-preview mb-2">
                                    {photoFile && (
                                      <ReactCrop
                                        src={photoFile}
                                        crop={crop}
                                        onImageLoaded={
                                          this.onImageLoaded
                                        }
                                        onComplete={
                                          this
                                            .onCropComplete
                                        }
                                        onChange={
                                          this.onCropChange
                                        }
                                      />
                                    )}
                                  </div>
                                  <input
                                    type="file"
                                    className="custom-file-inputz"
                                    id="customFile"
                                    onChange={
                                      this.fileChange
                                    }
                                  />
                                </div>
                                <div className="row d-flex justify-content-center">
                                  <button
                                    type="submit"
                                    className="btn btn-lg submit-btn"
                                    onClick={() =>
                                      this.onFileUpload(
                                        userData,
                                      )
                                    }>
                                    {isSending ? (
                                      <>
                                        <span>
                                          {getLabelByName(
                                            UPLOADING,
                                          )}
                                          {"  "}
                                        </span>
                                        <div
                                          className="spinner-border spinner-border-sm"
                                          role="status">
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </>
                                    ) : (
                                      getLabelByName(UPLOAD)
                                    )}
                                  </button>
                                </div>
                              </>
                            ) : (
                              <p className="forgot-modal-text text-center">
                                {getLabelByName(
                                  CHANGED_PICTURE_SUCCESS,
                                )}
                              </p>
                            )}

                            <br />
                            <div className="row d-flex justify-content-center">
                              <hr
                                style={{
                                  border:
                                    ".5px solid #A8A8A8",
                                  width: "50%",
                                }}
                              />
                            </div>
                            <br />
                            <div className="row d-flex justify-content-center">
                              <small
                                onClick={() =>
                                  this.setState(
                                    (state) => ({
                                      ...state,
                                      isSent: false,
                                    }),
                                  )
                                }>
                                <p
                                  className="back-to-sign"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  style={{
                                    color:
                                      colors.textPrimary,
                                    textDecoration:
                                      "underline",
                                  }}>
                                  {getLabelByName(CLOSE)}
                                </p>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {componentDisplay === "campaigns" &&
                  getActivitiesData && (
                    <this.CampaignsComponent
                      campaigns={getActivitiesData}
                      listCampaigns={listCampaigns}
                      defaultLogo={activityDefaultImage}
                    />
                  )}
                {componentDisplay === "clubs" &&
                  getUserClubsData && (
                    <this.ClubsComponent
                      clubs={getUserClubsData}
                      listInstitutes={listInstitutes}
                      defaultLogo={defaultLogo}
                    />
                  )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

Profile.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  changePasswordAction: PropTypes.func.isRequired,
  changeUsername: PropTypes.func.isRequired,
  editProfile: PropTypes.func.isRequired,
  getUserClubs: PropTypes.func.isRequired,
  getActivities: PropTypes.func.isRequired,
  getSessionAction: PropTypes.func.isRequired,
  changeDataError: PropTypes.object,
  changeData: PropTypes.object,
  changeStatus: PropTypes.string,
  userEditDataError: PropTypes.object,
  userEditData: PropTypes.object,
  userEditStatus: PropTypes.string,
  data: PropTypes.object,
  status: PropTypes.string,

  getUserClubsData: PropTypes.any,
  getUserClubsStatus: PropTypes.any,
  getActivitiesData: PropTypes.any,
  getActivitiesStatus: PropTypes.any,

  landingData: PropTypes.object,
};

export const mapStateToProps = (state) => ({
  changeDataError:
    state.changePasswordReducer.changeDataError,
  changeData: state.changePasswordReducer.changeData,
  changeStatus: state.changePasswordReducer.changeStatus,
  userEditDataError:
    state.editProfileReducer.userEditDataError,
  userEditData: state.editProfileReducer.userEditData,
  userEditStatus: state.editProfileReducer.userEditStatus,

  getUserClubsData:
    state.getUserClubsReducer.getUserClubsData,
  getUserClubsDataError:
    state.getUserClubsReducer.getUserClubsDataError,
  getUserClubsStatus:
    state.getUserClubsReducer.getUserClubsStatus,

  getActivitiesDataError:
    state.getActivitiesReducer.getActivitiesDataError,
  getActivitiesData:
    state.getActivitiesReducer.getActivitiesData,
  getActivitiesStatus:
    state.getActivitiesReducer.getActivitiesStatus,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  profileData: state.authReducer.data,

  status: state.authReducer.status,
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    changePasswordAction,
    changeUsername,
    editProfile,
    uploadPicture,
    getUserClubs,
    getActivities,
    getSessionAction,
  }),
)(Profile);
