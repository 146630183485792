import React, { Component } from 'react';
import { getLabelByName } from '../../../../helpers/helperFunctions';
import { TEXT_AREA } from '../../../../helpers/constants';

class TextArea extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="post-textarea">
        <textarea
          rows="4"
          placeholder={getLabelByName(TEXT_AREA)}
        />
      </div>
    );
  }
}

export default TextArea;
