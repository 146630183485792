import {
  GET_POSTS_SUCCESS, GET_POSTS_ERROR,
} from '../actions/types';

const initialState = {
  getCommentsData: null,
  getCommentsDataError: null,
  getCommentsStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_POSTS_SUCCESS('comment'):
      return {
        ...state,
        getCommentsData: action.payload,
        getCommentsDataError: null,
        getCommentsStatus: 'success',
      };
    case GET_POSTS_ERROR('comment'):
      return {
        ...state,
        getCommentsDataError: action.payload,
        getCommentsData: null,
        getCommentsStatus: 'error',
      };
    default:
      return {
        ...state,
        getCommentsStatus: null,
      };
  }
};
