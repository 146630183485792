import {
  DELETE_POST_SUCCESS, DELETE_POST_ERROR,
} from '../actions/types';

const initialState = {
  getPostData: null,
  getPostDataError: null,
  getPostStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DELETE_POST_SUCCESS:
      return {
        ...state,
        deletePostData: action.payload,
        deletePostDataError: null,
        deletePostStatus: 'success',
      };
    case DELETE_POST_ERROR:
      return {
        ...state,
        deletePostDataError: action.payload,
        deletePostData: null,
        deletePostStatus: 'error',
      };
    default:
      return {
        ...state,
        deletePostStatus: null,
      };
  }
};
