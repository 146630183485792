import React from "react";
import {
  CircularProgressbar,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const LessonsOverlay = ({
  toggleOverlay,
  currLesson,
  isLastLesson,
  isSubmitting,
  percentage,
  routeToNextLesson,
  handleSubmitContribution,
}) => {
  return (
    <div className="lessons-overlay">
      <div className="content-wrapper">
        <div className="content">
          <div className="progress">
            <CircularProgressbar
              value={percentage}
              text={`${percentage}%`}
              styles={buildStyles({
                // Colors
                pathColor: "#f39550",
                textColor: "#f39550",
                trailColor: "#d6d6d6",
                backgroundColor: "#f39550",
              })}
            />
          </div>

          <div className="details">
            <h3 className="title">
              {percentage === "100" ? "Course" : "Lesson"}{" "}
              Completed
            </h3>
            <p className="desc">
              You just completed the lesson:
              <br />
              <span>{currLesson?.content?.title}</span>
            </p>
          </div>

          <div className="btn-wrapper">
            {isLastLesson ? (
              <button
                type="button"
                onClick={handleSubmitContribution}
                className="submit-lesson-btn"
                disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            ) : (
              <button
                type="button"
                onClick={() =>
                  routeToNextLesson(currLesson)
                }
                className="next-lesson-btn">
                Go To Next Lesson
              </button>
            )}

            <button
              type="button"
              onClick={() => toggleOverlay((open) => !open)}
              className="cancel-btn">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonsOverlay;
