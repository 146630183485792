import {
  GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_ERROR,
} from '../actions/types';

const initialState = {
  getNotificationsData: null,
  getNotificationsDataError: null,
  getNotificationsStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        getNotificationsData: action.payload,
        getNotificationsDataError: null,
        getNotificationsStatus: 'success',
      };
    case GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        getNotificationsDataError: action.payload,
        getNotificationsData: null,
        getNotificationsStatus: 'error',
      };
    default:
      return {
        ...state,
        getNotificationsStatus: null,
      };
  }
};
