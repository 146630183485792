import React, { Component } from 'react';
import { getLabelByName } from '../../../../helpers/helperFunctions';
import { IMAGE, TEXT, UPLOAD } from '../../../../helpers/constants';
import imagePlaceholder from '../../../../assets/icons/image-placeholder.svg';

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="questionnaire-section">
        <div className="card section-card image-card">
          <div className="card-body image-card">
            <div className="card-details">
              <h5 className="card-title">
                {getLabelByName(IMAGE)}
              </h5>
              <p className="helper-text">
                {`${getLabelByName(UPLOAD)} ${getLabelByName(IMAGE)}.`}
              </p>
              <br />
              <p className="helper-text font-italic">
                Formats: png, jpeg, jpg and svg
              </p>
              <div>
                <input
                  type="file"
                  className="custom-file-inputz mb-5"
                  id="customFile"
                  accept="image/*"
                />
              </div>
              <button
                type="button"
                className="btn btn-sm submit-btn"
                disabled
              >
                {getLabelByName(UPLOAD)}
              </button>
            </div>
            <div className="card-image-preview">
              <img
                src={imagePlaceholder}
                alt="card-preview"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UploadImage;
