import {
  RESET_PASSWORD_ERROR, RESET_PASSWORD_SUCCESS,
} from '../actions/types';

const initialState = {
  resetData: null,
  resetDataError: null,
  resetStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetData: action.payload,
        resetDataError: null,
        resetStatus: 'success',
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetDataError: action.payload,
        resetData: null,
        resetStatus: 'error',
      };
    default:
      return {
        ...state,
        resetStatus: null,
      };
  }
};
