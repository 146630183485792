/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Button, Card,
} from 'react-bootstrap';
import clubsIcon from '../assets/manage-clubs.svg';
import clubsWhiteIcon from '../assets/manage-clubs-white.svg';
import campaignIcon from '../assets/manage-campaigns.svg';
import campaignWhiteIcon from '../assets/manage-campaigns-white.svg';
import contributionsIcon from '../assets/contributions-dark.svg';
import contributionsWhiteIcon from '../assets/contributions-White.svg';
import { getLabelByName } from '../helpers/helperFunctions';
import {
  CANCEL, NEXT, ACTIVITY, CLUB, CONTRIBUTION, ARTICLE, OUTPUT, SELECT_PROMOTION_TYPE,
} from '../helpers/constants';

export default class PromotionModal extends Component {
  state = {
    selectedPromotionType: '',
  }

  handleSelected = (type) => {
    this.setState({ selectedPromotionType: type });
  };

  handleStyles = (type) => {
    if (this.state.selectedPromotionType === type) {
      return { backgroundColor: '#F38F46' };
    }
    return null;
  }

  render() {
    const { showPromotionModal, handleModalDisplay } = this.props;
    const { selectedPromotionType } = this.state;
    return (
      <Modal
        size="xl"
        centered
        show={showPromotionModal}
        onHide={handleModalDisplay}
      >
          <Modal.Body>
            <Modal.Title>{getLabelByName(SELECT_PROMOTION_TYPE)}</Modal.Title>
            <div className="promotion-section" style={{ display: 'flex', justifyContent: 'space-around', margin: '1rem 0' }}>
              <Card
                className="promotionTypeCard"
                style={this.handleStyles('club')}
                onClick={() => this.handleSelected('club')}
              >
                  <Card.Img
                    variant="top"
                    src={selectedPromotionType === 'club'
                      ? clubsWhiteIcon : clubsIcon}
                  />
                  <Card.Body style={selectedPromotionType === 'club'
                    ? { borderTop: '.1rem solid #efdac3', color: 'white' } : null}
                  >
                      <Card.Text>{getLabelByName(CLUB)}</Card.Text>
                  </Card.Body>
              </Card>
              <Card
                className="promotionTypeCard"
                style={this.handleStyles('output')}
                onClick={() => this.handleSelected('output')}
              >
                  <Card.Img
                    variant="top"
                    src={selectedPromotionType === 'output'
                      ? clubsWhiteIcon : clubsIcon}
                  />
                  <Card.Body style={selectedPromotionType === 'output'
                    ? { borderTop: '.1rem solid #efdac3', color: 'white' } : null}
                  >
                      <Card.Text>{getLabelByName(OUTPUT)}</Card.Text>
                  </Card.Body>
              </Card>
              <Card
                className="promotionTypeCard"
                style={selectedPromotionType === 'article'
                  ? {
                    backgroundColor: '#F38F46',
                  } : null}
                onClick={() => this.handleSelected('article')}
              >
                  <Card.Img
                    variant="top"
                    src={selectedPromotionType === 'article'
                      ? clubsWhiteIcon : clubsIcon}
                  />
                  <Card.Body style={selectedPromotionType === 'article'
                    ? { borderTop: '.1rem solid #efdac3', color: 'white' } : null}
                  >
                      <Card.Text>{getLabelByName(ARTICLE)}</Card.Text>
                  </Card.Body>
              </Card>
              <Card
                className="promotionTypeCard"
                style={selectedPromotionType === 'activity'
                  ? {
                    backgroundColor: '#F38F46',
                  } : null}
                onClick={() => this.handleSelected('activity')}
              >
                  <Card.Img
                    variant="top"
                    src={selectedPromotionType === 'activity'
                      ? campaignWhiteIcon : campaignIcon}
                  />
                  <Card.Body style={selectedPromotionType === 'activity'
                    ? { borderTop: '.1rem solid #efdac3', color: 'white' } : null}
                  >
                      <Card.Text>{getLabelByName(ACTIVITY)}</Card.Text>
                  </Card.Body>
              </Card>
              <Card
                className="promotionTypeCard"
                onClick={() => this.handleSelected('contribution')}
                style={selectedPromotionType === 'contribution'
                  ? {
                    backgroundColor: '#F38F46',
                  } : null}
              >
              <Card.Img
                style={{ paddingBottom: '3.5rem' }}
                variant="top"
                src={selectedPromotionType === 'contribution'
                  ? contributionsWhiteIcon : contributionsIcon}
              />
              <Card.Body
                style={selectedPromotionType === 'contribution'
                  ? { borderTop: '.1rem solid #efdac3', color: 'white' } : null}
              >
                <Card.Text>{getLabelByName(CONTRIBUTION)}</Card.Text>
              </Card.Body>
              </Card>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="link" style={{ color: '#F38F46', height: '2.5rem' }} onClick={handleModalDisplay}>
                {getLabelByName(CANCEL)}
              </Button>
              <button
                type="button"
                className="btn btn-sm submit-btn"
                style={{ height: '2.5rem' }}
                disabled={selectedPromotionType === ''}
                onClick={() => { window.location.href = `/promote/${selectedPromotionType}`; }}
              >
                {getLabelByName(NEXT)}
              </button>
          </Modal.Footer>
      </Modal>
    );
  }
}

PromotionModal.propTypes = {
  showPromotionModal: PropTypes.any,
  handleModalDisplay: PropTypes.any,
};
