/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import {
  withRouter,
  Link,
  Redirect,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";
import { ArrowBackIos } from "@material-ui/icons";
import { toast } from "react-toastify";
import { Image } from "react-bootstrap";
import moment from "moment";
import Footer from "../Footer";
import ClubInfoModal from "./ClubInfoModal";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  checkSession,
  getLabelByName,
  isActionSuccess,
  isActionError,
  getProfileDataFromLocalStorage,
  userIsLoggedIn,
} from "../../helpers/helperFunctions";
import {
  BACK,
  colors,
  STARTED,
  VIEW_CLUB_INFORMATION,
} from "../../helpers/constants";
import { getSessionAction } from "../../redux/actions/authActions";
import { getOutputs } from "../../redux/actions/contributionsActions";
import {
  getInstitute,
  getInstituteMembers,
} from "../../redux/actions/institutesActions";
import {
  getActivity,
  getClubActivities,
} from "../../redux/actions/campaignsActions";
import {
  getNewsFeed,
  getPosts,
} from "../../redux/actions/postActions";
import {
  getMostActiveActivities,
  getMostActiveClubs,
  getMostPopularClubs,
} from "../../redux/actions/filterActions";
import Navbar from "../Navbar";
import ReactionsModal from "../ReactionsModal";
import FeedArticle from "../FeedArticle";
import FeedPost from "../FeedPost";
import PromotionTemplate from "../generics/PromotionTemplate";
import FeedPostForm from "./FeedPostForm";
import ResourceTab from "../generics/ResourceTab";

import campaignIconDark from "../../assets/manage-campaigns.svg";
import members from "../../assets/manage-members.svg";
import ClubsImage from "../../assets/manage-clubs.svg";

import ItemCard from "../explore/ItemCard";
import SideNav from "../home/SideNav";
import MyClubs from "../home/MyClubs";
import MyActivities from "../home/MyActivities";
import OutputsRow from "../OutputsRow";
import ViewClubFeedCard from "./ViewClubFeedCard";
import Loading from "../generics/Loading/Loading";

const ClubFeed = (props) => {
  const [state, setState] = useState({
    profileData: getProfileDataFromLocalStorage(),
    pageTitle: `${
      process.env.REACT_APP_APPLICATION_NAME ||
      "The Alma Maters Club"
    } - Club`,
    pathname: props.location.pathname,
    selectedReactions: null,
    showInformation: false,
    currentDisplay: "default",
    displayPersonalFeed: false,
    updateLinkItemCount: false
  });

  const init = () => {
    document.title = state.pageTitle;
  };

  const handleLinkItemCountUpdate = () => {
    setState((prevState) => ({
      ...prevState,
      updateLinkItemCount: !prevState.updateLinkItemCount,
    }));
  };

  const fetchData = async () => {
    const { id: clubId, handle } = props.match.params;
    await props.getInstitute(handle);
    await props.getOutputs(`?q={"clubId":${clubId}}`);
    await props.getPosts(clubId);
    await props.getClubActivities(clubId);
    await props.getInstituteMembers(clubId);
  };

  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    init();
    props.getSessionAction();
    fetchData();
  }, []);

  useEffect(() => {
    const { instituteData } = props;

    if (instituteData) {
      if (
        isActionSuccess(
          props,
          "createPostStatus",
          "createPostData",
        ) ||
        isActionSuccess(
          props,
          "postReactionStatus",
          "postReactionData",
        ) ||
        isActionSuccess(
          props,
          "deleteReactionStatus",
          "deleteReactionData",
        )
      ) {
        fetchData();
      }

      if (
        isActionError(
          props,
          "createPostStatus",
          "createPostData",
        ) ||
        isActionError(
          props,
          "postReactionStatus",
          "postReactionData",
        ) ||
        isActionError(
          props,
          "deleteReactionStatus",
          "deleteReactionData",
        )
      ) {
        toast.error("Posting error!\nPlease try again!");
      }
    }
  }, [props.instituteData]);

  const toggleFeed = async (feedType) => {
    const { id: clubId } = props.match.params;
    const { displayPersonalFeed } = state;

    if (match.path === "/club/:id/:handle") {
      history.push("/home", { from: match.path });
    }

    if (feedType === "all" && displayPersonalFeed) {
      setState((prevState) => ({
        ...prevState,
        currentDisplay: "default",
        displayPersonalFeed: false,
      }));

      await props.getPosts(clubId);
    }

    if (feedType === "personal" && !displayPersonalFeed) {
      setState((prevState) => ({
        ...prevState,
        currentDisplay: "default",
        displayPersonalFeed: true,
      }));

      await props.getNewsFeed("?personal=true");
    }

    if (feedType === "my-clubs") {
      setState((prevState) => ({
        ...prevState,
        currentDisplay: "my-clubs",
      }));
    }

    if (feedType === "my-activities") {
      setState((prevState) => ({
        ...prevState,
        currentDisplay: "my-activities",
      }));
    }

    if (feedType === "most-active-activities") {
      setState((prevState) => ({
        ...prevState,
        currentDisplay: "most-active-activities",
      }));

      await props.getMostActiveActivities();
    }

    if (feedType === "most-popular-clubs") {
      setState((prevState) => ({
        ...prevState,
        currentDisplay: "most-popular-clubs",
      }));

      await props.getMostPopularClubs();
    }

    if (feedType === "clubs-with-recent-activity") {
      setState((prevState) => ({
        ...prevState,
        currentDisplay: "clubs-with-recent-activity",
      }));

      await props.getMostActiveClubs();
    }
  };

  const dataHasLoaded = (state) => !!(state && state.data);

  const getInstituteData = () =>
    dataHasLoaded(props.instituteData) &&
    props.instituteData.data;

  const getCampaignsData = () =>
    dataHasLoaded(props.clubCampaignsData) &&
    props.clubCampaignsData.data;

  const isMember = () => {
    const { profileData } = state;
    let isMemberValue = false;

    if (
      dataHasLoaded(profileData) &&
      dataHasLoaded(props.instituteData)
    ) {
      isMemberValue = Object.keys(
        profileData.data.clubs,
      ).includes(getInstituteData().id.toString());
    }

    return isMemberValue;
  };

  const getFeedData = () =>
    dataHasLoaded(props.getPostsData) &&
    props.getPostsData.data;

  const getMembersData = () =>
    dataHasLoaded(props.clubMembersData) &&
    props.clubMembersData.data;

  const updateSelectedReactions = (reactions) => {
    const selectedReactions =
      reactions && Object.values(reactions).length > 0
        ? Object.values(reactions)
        : null;
    setState({ selectedReactions });
  };

  const NewsFeedTabContent = () => (
    <div className="feed-section">
      <FeedPostForm />
      {dataHasLoaded(props.getPostsData) &&
        getFeedData() &&
        getFeedData().map((postItem) => {
          if (postItem.type === "POST") {
            return (
              <FeedPost
                postItem={postItem}
                updateSelectedReactions={
                  updateSelectedReactions
                }
              />
            );
          }
          if (postItem.type === "ARTICLE") {
            return (
              <FeedArticle
                postItem={postItem}
                updateSelectedReactions={
                  updateSelectedReactions
                }
              />
            );
          }
          if (postItem.type === "PROMO") {
            return (
              <PromotionTemplate
                handleLinkItemCountUpdate={
                  handleLinkItemCountUpdate
                }
                postItem={postItem}
                profileData={state.profileData}
                updateSelectedReactions={
                  updateSelectedReactions
                }
              />
            );
          }
        })}
    </div>
  );

  const activityTabContent = () => (
    <div className="members-list">
      {getCampaignsData().length > 0 &&
        getCampaignsData().map((campaign, index) => (
          <ItemCard
            item={campaign}
            index={index}
            type="campaign"
            defaultIcon={campaignIconDark}
          />
        ))}
    </div>
  );

  const participantsTabContent = () => (
    <>
      <div className="members-list">
        {getMembersData().length > 0 &&
          getMembersData().map((member, index) => {
            const firstName =
              member.user.profile?.firstName || "Unknown";
            const lastName =
              member.user.profile?.lastName || "User";

            return (
              <div className="member-info" key={index}>
                <div className="member-avatar">
                  {member.user.profile.picture && (
                    <img
                      className="img-fluid"
                      src={member.user.profile.picture}
                      alt="club-avatar"
                    />
                  )}
                  {!member.user.profile.picture && (
                    <div className="member-initials">
                      {firstName[0] + lastName[0]}
                    </div>
                  )}
                </div>
                <div className="member-name">
                  {firstName} {lastName}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );

  const getTabResource = () => ({
    News: NewsFeedTabContent(),
    Activities: activityTabContent(),
    Members: participantsTabContent(),
  });

  const { updateLinkItemCount, profileData } = state;
  const { mostActiveActivities } = props;

  const isAuthenticated = userIsLoggedIn(profileData);

  if (
    !isAuthenticated &&
    props?.instituteData?.data?.visibility
  ) {
    switch (props?.instituteData?.data?.visibility) {
      case "PUBLIC":
      case "PRIVATE":
        return (
          <Redirect
            push
            to={props.location.pathname + "/about"}
          />
        );
        break;

      case "RESTRICTED":

      default:
        return <Redirect push to="/404" />;
    }
  }

  if (!props.getOutputsData?.data) {
    return <Loading />;
  }

  let displayContent;

  if (state.currentDisplay === "default") {
    displayContent = (
      <ResourceTab
        resource={getTabResource()}
        isLoading={false}
      />
    );
  }

  if (state.currentDisplay === "my-clubs")
    displayContent = <MyClubs />;

  if (state.currentDisplay === "my-activities")
    displayContent = <MyActivities />;

  if (state.currentDisplay === "most-active-activities") {
    displayContent =
      mostActiveActivities &&
      mostActiveActivities.data &&
      mostActiveActivities.data.data.map((activity) => (
        <div className="card activity-card">
          <div className="card-body">
            <div className="activity-content-row">
              <div className="activity-content">
                <div className="article-banner">
                  <img
                    src={ClubsImage}
                    alt="activity-banner"
                  />
                </div>
                <h4>{activity.name}</h4>
                <small>@{activity.handle}</small>
                <ul
                  style={{
                    display: "flex",
                    listStyle: "none",
                    padding: 0,
                    fontSize: ".8rem",
                    margin: "8px 0",
                    color: "grey",
                  }}>
                  <li>
                    Start Date:
                    <span
                      style={{
                        padding: "0 8px",
                        color: "#555353",
                      }}>
                      {moment(
                        Number(activity.startDate) * 1000,
                      ).format("DD/MM/YYYY")}
                    </span>
                  </li>
                  <li
                    style={{
                      padding: "0 8px",
                    }}>
                    End Date:
                    <span
                      style={{
                        padding: "0 8px",
                        color: "#555353",
                      }}>
                      {activity.endDate === null
                        ? ""
                        : moment(
                            Number(activity.endDate) * 1000,
                          ).format("DD/MM/YYYY")}
                    </span>
                  </li>
                  <li
                    style={{
                      padding: "0 8px",
                    }}>
                    status:
                    <span
                      style={{
                        padding: "0 8px",
                        color: "#555353",
                      }}>
                      {activity.status}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ));
  }

  if (state.currentDisplay === "most-popular-clubs") {
    displayContent =
      mostActiveActivities &&
      mostActiveActivities.data &&
      mostActiveActivities.data.data.map((activity) => (
        <div className="card activity-card">
          <div className="card-body">
            <div className="activity-content-row">
              <div className="activity-content">
                <div className="article-banner">
                  <img
                    src={
                      activity.profile.picture === undefined
                        ? ClubsImage
                        : activity.profile.picture
                    }
                    alt="article-banner"
                  />
                </div>
                <h4>{activity.name}</h4>
                <div className="article-description">
                  <Link
                    to=""
                    style={{
                      textDecoration: "none",
                      color: "unset",
                    }}>
                    {activity.profile.description}
                  </Link>
                </div>

                <ul
                  style={{
                    display: "flex",
                    listStyle: "none",
                    padding: 0,
                    fontSize: ".8rem",
                    margin: "8px 0",
                    color: "grey",
                  }}>
                  <li>
                    Posts:
                    <span
                      style={{
                        padding: "0 8px",
                        color: "#555353",
                      }}>
                      {activity.posts}
                    </span>
                  </li>
                  <li
                    style={{
                      padding: "0 8px",
                    }}>
                    Members:
                    <span
                      style={{
                        padding: "0 8px",
                        color: "#555353",
                      }}>
                      {activity.members}
                    </span>
                  </li>
                  <li
                    style={{
                      padding: "0 8px",
                    }}>
                    campaigns:
                    <span
                      style={{
                        padding: "0 8px",
                        color: "#555353",
                      }}>
                      {activity.campaigns}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ));
  }

  if (
    state.currentDisplay === "clubs-with-recent-activity"
  ) {
    displayContent =
      mostActiveActivities &&
      mostActiveActivities.data &&
      mostActiveActivities.data.data.map((activity) => (
        <div className="card activity-card">
          <div className="card-body">
            <div className="activity-content-row">
              <div className="activity-content">
                <div className="article-banner">
                  <img
                    style={{ objectFit: "cover" }}
                    src={
                      activity.profile.picture === undefined
                        ? ClubsImage
                        : activity.profile.picture
                    }
                    alt="article-banner"
                  />
                </div>
                <h4>{activity.name}</h4>
                <div className="article-description">
                  <Link
                    to=""
                    style={{
                      textDecoration: "none",
                      color: "unset",
                    }}>
                    {activity.profile.description}
                  </Link>
                </div>

                <ul
                  style={{
                    display: "flex",
                    listStyle: "none",
                    padding: 0,
                    fontSize: ".8rem",
                    margin: "8px 0",
                    color: "grey",
                  }}>
                  <li>
                    Posts:
                    <span
                      style={{
                        padding: "0 8px",
                        color: "#555353",
                      }}>
                      {activity.posts}
                    </span>
                  </li>
                  <li
                    style={{
                      padding: "0 8px",
                    }}>
                    Members:
                    <span
                      style={{
                        padding: "0 8px",
                        color: "#555353",
                      }}>
                      {activity.members}
                    </span>
                  </li>
                  <li
                    style={{
                      padding: "0 8px",
                    }}>
                    campaigns:
                    <span
                      style={{
                        padding: "0 8px",
                        color: "#555353",
                      }}>
                      {activity.campaigns}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ));
  }

  return (
    <>
      <Navbar pathname="club" />
      <div className="club-page feed-page">
        <div
          className={`row ${
            !isAuthenticated ? "justify-content-center" : ""
          }`}>
          {isAuthenticated ? (
            <div className="feed-nav">
              <SideNav
                updateLinkItemCount={updateLinkItemCount}
                toggleFeed={toggleFeed}
              />
            </div>
          ) : null}

          <div className="feed-main mt-5 px-5">
            <div className="container-fluid">
              <div className="title d-flex mb-3">
                {isAuthenticated ? (
                  <button
                    type="button"
                    style={{
                      background: "none",
                      border: "none",
                      outline: "none",
                      marginTop: "-8px",
                      marginLeft: "-5px",
                    }}>
                    <Link
                      to="#"
                      onClick={() => props.history.goBack()}
                      style={{
                        outline: "none",
                        color: "#424242",
                      }}>
                      <ArrowBackIosIcon />
                    </Link>
                  </button>
                ) : null}

                {props.getOutputsData?.data.length > 0 ? (
                  <h1 className="header-title">
                    Promoted Outputs
                  </h1>
                ) : (
                  <h1 className="header-title">Back</h1>
                )}
              </div>

              {props.getOutputsData?.data &&
                props.getOutputsData?.data.length > 0 && (
                  <div className="outputs-row-section">
                    <OutputsRow
                      outputs={props.getOutputsData.data.filter(
                        (output) => output?.meta?.promoted,
                      )}
                    />
                  </div>
                )}

              {getInstituteData().profile && (
                <>
                  <div
                    className="feed-card mt-5"
                    style={{ minHeight: "unset" }}>
                    <div className="clubInform">
                      <div className="clubInform-container">
                        <Image
                          className="clubImage"
                          src={
                            getInstituteData().profile
                              .picture
                          }
                          roundedCircle
                        />
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent:
                                "space-evenly",
                              paddingLeft: "30px",
                            }}>
                            <h3>
                              {getInstituteData().name}
                            </h3>
                            <small
                              style={{
                                color: "#A3A3A3",
                              }}>
                              Started:{" "}
                              {moment(
                                Number(
                                  getInstituteData()
                                    .dateCreated,
                                ) * 1000,
                              ).format("DD/MM/YYYY")}
                            </small>
                            <div
                              onClick={() =>
                                history.push(
                                  `${window.location.pathname}/about`,
                                )
                              }
                              style={{
                                color: colors.primaryColor,
                                cursor: "pointer",
                              }}>
                              {getLabelByName(
                                VIEW_CLUB_INFORMATION,
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul className="clubInformMeta">
                        <li>
                          <Image src={campaignIconDark} />
                          {getInstituteData().campaigns}
                        </li>
                        <li>
                          <Image src={members} />
                          {getInstituteData().members}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="club-info-title-responsive">
                    <div className="club-name">
                      <div
                        className="back-icon"
                        onClick={() =>
                          props.history.goBack()
                        }
                        style={{
                          cursor: "pointer",
                        }}>
                        <ArrowBackIos className="arrow-back-icon" />
                      </div>
                      <h3>{getInstituteData().name}</h3>
                    </div>
                    <div className="club-more-details">
                      <div className="club-profile-pic">
                        <Image
                          className="clubImage"
                          src={
                            getInstituteData().profile
                              .picture
                          }
                          roundedCircle
                        />
                      </div>
                      <div className="club-details">
                        <small style={{ color: "#A3A3A3" }}>
                          {getLabelByName(STARTED)}:{" "}
                          {moment(
                            Number(
                              getInstituteData()
                                .dateCreated,
                            ) * 1000,
                          ).format("DD/MM/YYYY")}
                        </small>
                        <div
                          onClick={() =>
                            history.push(
                              `${window.location.pathname}/about`,
                            )
                          }
                          style={{
                            color: colors.primaryColor,
                            cursor: "pointer",
                            fontSize: "13px",
                          }}>
                          {getLabelByName(
                            VIEW_CLUB_INFORMATION,
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="d-flex align-items-center justify-content-center mt-4">
                <div className="feed-card">
                  {displayContent}
                </div>
              </div>

              {/* Reactions Modal */}
              <ReactionsModal
                reactions={state.selectedReactions}
              />

              <ClubInfoModal />
            </div>
          </div>
        </div>

        <div className="footer-block">
          <div className="footer-component">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

ClubFeed.propTypes = {
  getSessionAction: PropTypes.func,
  getInstitute: PropTypes.func,
  getOutputs: PropTypes.func,
  getNewsFeed: PropTypes.func,
  getPosts: PropTypes.func,
  history: PropTypes.any,
  location: PropTypes.any,
  match: PropTypes.any,
  instituteData: PropTypes.any,
  getPostsData: PropTypes.any,
  clubCampaignsData: PropTypes.any,
  getClubActivities: PropTypes.func,
  clubMembersData: PropTypes.any,
  getInstituteMembers: PropTypes.func,
};

export const mapStateToProps = (state) => ({
  instituteDataError:
    state.instituteReducer.instituteDataError,
  instituteData: state.instituteReducer.instituteData,
  instituteStatus: state.instituteReducer.instituteStatus,

  getOutputsDataError:
    state.getOutputsReducer.getOutputsDataError,
  getOutputsData: state.getOutputsReducer.getOutputsData,
  getOutputsStatus:
    state.getOutputsReducer.getOutputsStatus,

  getNewsFeedDataError:
    state.getNewsFeedReducer.getNewsFeedDataError,
  getNewsFeedData: state.getNewsFeedReducer.getNewsFeedData,
  getNewsFeedStatus:
    state.getNewsFeedReducer.getNewsFeedStatus,

  getPostsData: state.getPostsReducer.getPostsData,
  getPostsDataError:
    state.getPostsReducer.getPostsDataError,
  getPostsStatus: state.getPostsReducer.getPostsStatus,

  createPostData: state.createPostReducer.createPostData,
  createPostDataError:
    state.createPostReducer.createPostDataError,
  createPostStatus:
    state.createPostReducer.createPostStatus,

  postReactionDataError:
    state.postReactionReducer.postReactionDataError,
  postReactionData:
    state.postReactionReducer.postReactionData,
  postReactionStatus:
    state.postReactionReducer.postReactionStatus,
  deleteReactionDataError:
    state.deleteReactionReducer.deleteReactionDataError,
  deleteReactionData:
    state.deleteReactionReducer.deleteReactionData,
  deleteReactionStatus:
    state.deleteReactionReducer.deleteReactionStatus,

  getActivityDataError:
    state.getActivityReducer.getActivityDataError,
  getActivityData: state.getActivityReducer.getActivityData,
  getActivityStatus:
    state.getActivityReducer.getActivityStatus,

  clubCampaignsDataError:
    state.clubCampaignsReducer.clubCampaignsDataError,
  clubCampaignsData:
    state.clubCampaignsReducer.clubCampaignsData,
  clubCampaignsStatus:
    state.clubCampaignsReducer.clubCampaignsStatus,

  clubMembersData: state.clubMembersReducer.clubMembersData,
  clubMembersDataError:
    state.clubMembersReducer.clubMembersDataError,
  clubMembersStatus:
    state.clubMembersReducer.clubMembersStatus,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,

  mostActiveActivities: state.filtersReducer,
  getMostActiveActivities: PropTypes.any,
  getMostActiveClubs: PropTypes.any,
  getMostPopularClubs: PropTypes.any,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getInstitute,
    getOutputs,
    getSessionAction,
    getNewsFeed,
    getPosts,
    getActivity,
    getClubActivities,
    getInstituteMembers,
    getMostActiveActivities,
    getMostPopularClubs,
    getMostActiveClubs,
  }),
)(ClubFeed);
