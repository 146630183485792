/* eslint-disable prefer-destructuring */
/* eslint-disable quote-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable dot-notation */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ArrowBackIos } from '@material-ui/icons';
import { toast } from 'react-toastify';

import {
  getActivity,
  getAction,
  postParticipantAction,
  getParticipantActions,
  updateParticipantAction,
  getParticipantAllActions,
  getQuestionnaires,
} from '../../redux/actions/campaignsActions';
import { getSessionAction } from '../../redux/actions/authActions';
import Footer from '../Footer';
import Navbar from '../Navbar';
import Fallback from '../Fallback';
import {
  calculateTime,
  checkSession,
  getAllClubsFromLocalStorage,
  getLabelByName,
  getProfileDataFromLocalStorage,
  isActionError,
  isActionSuccess,
} from '../../helpers/helperFunctions';
import Questionnaire from '../Questionnaire';
import CampaignBanner from '../generics/CampaignBanner';
import { submitContribution } from '../../redux/actions/contributionsActions';
import { BACK, EDIT, PROCESSING, SAVE, SUBMIT } from '../../helpers/constants';

export class Contribute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: `${
        process.env.REACT_APP_APPLICATION_NAME || 'The Alma Maters Club'
      } - Contribute`,
      profileData: getProfileDataFromLocalStorage(),
      clubsData: getAllClubsFromLocalStorage(),
      pathname: this.props.location.pathname,
      clubHost: null,
      getActivityData: null,
      getActionData: null,
      questionnaireId: null,
      time: [],
      seconds: 0,
      questionnaireState: {},
      isSaving: false,
      saveAndSubmit: false,
      enableSubmission: false,
      allParticipantActions: [],
    };
    this.init();
  }

  init() {
    const { state } = this;
    const { profileData, pathname, pageTitle } = state;
    document.title = pageTitle;
    if (!profileData || profileData.status !== 200) {
      window.location.href = `/login?redirect_to=${pathname}`;
    }
  }

  render() {
    const {
      getActivityData,
      getActionData,
      seconds,
      time,
      clubHost,
      questionnaireId,
    } = this.state;

    const campaignInfo = getActivityData;
    const { participantActionId } = this.props.match.params;

    return (
      <>
        <Navbar pathname="club" />
        <div className="club-page">
          <div className="container">
            <div className="buttons-row">
              <div id="back-link">
                <div
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => this.gotoBack()}
                  style={{ cursor: 'pointer' }}>
                  <ArrowBackIos className="arrow-back-icon" />
                  {'  '}
                  {getLabelByName(BACK)}
                </div>
              </div>
              {this.saveButtonsRow()}
            </div>

            <CampaignBanner
              clubHost={clubHost}
              campaignInfo={campaignInfo}
              seconds={seconds}
              time={time}
            />

            <p
              className="font-weight-bold mt-4 ml-4"
              style={{ fontSize: '20px' }}>
              {getActionData && getActionData.name}
            </p>

            <div className="d-flex align-items-center justify-content-center flex-column">
              {questionnaireId && clubHost && (
                <Questionnaire
                  questionnaireId={questionnaireId}
                  clubId={clubHost.id}
                  updateQuestionnaireState={this.updateQuestionnaireState}
                  editAction={
                    participantActionId && this.getParticipantAction()
                  }
                  reviewMode={this.state.reviewMode}
                  allParticipantActions={this.state.allParticipantActions}
                />
              )}
            </div>
          </div>
          <div className="footer-block">
            <div className="footer-component">
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  }

  async UNSAFE_componentWillMount() {
    const { props } = this;
    await props.getSessionAction();
    await this.fetchData();
    const query = new URLSearchParams(props.location.search);
    const reviewMode = query.get('review');
    this.setState({ reviewMode: !!reviewMode });
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { props, state } = this;
    const { pathname, clubsData } = state;
    const { participantActionId } = props.match.params;
    const {
      status,
      data,
      getActivityData,
      getActivityStatus,
      getActionData,
      getActionStatus,
      postParticipantActionData,
      updateParticipantActionData,
      getParticipantActionsData,
    } = nextProps;

    checkSession(data, status, pathname, props);

    if (getActivityStatus && !getActivityData) {
      return <Fallback route={pathname} />;
    }

    if (
      getActivityStatus &&
      getActivityData &&
      getActivityData.status === 500
    ) {
      return <Fallback route={pathname} />;
    }

    if (getActionStatus === 'success' && getActionData.status === 200) {
      const { questionnaireId } = getActionData.data.meta;
      this.setState({
        questionnaireId,
        getActionData: getActionData.data,
      });
    }

    if (getActivityStatus === 'success' && getActivityData.status === 200) {
      const endDate =
        getActivityData.data &&
        getActivityData.data.endDate &&
        getActivityData.data.endDate * 1000;
      const startDate =
        getActivityData.data &&
        getActivityData.data.startDate &&
        getActivityData.data.startDate * 1000;
      let seconds = 0;

      if (startDate > new Date()) {
        seconds = Math.floor((startDate - new Date()) / 1000);
      } else if (endDate > new Date()) {
        seconds = Math.floor((endDate - new Date()) / 1000);
      }
      const timeLeftVar = calculateTime(seconds);
      const activityClubId = getActivityData.data.parentId;
      const activityClub =
        clubsData &&
        clubsData.filter((activity) => activity.id === activityClubId)[0];

      this.setState({
        getActivityData: getActivityData.data,
        seconds,
        time: timeLeftVar,
        clubHost: activityClub,
      });
    }

    if (
      isActionSuccess(
        nextProps,
        'getParticipantActionsStatus',
        'getParticipantActionsData',
      ) &&
      nextProps.getParticipantActionsData &&
      nextProps.getParticipantActionsData.length > 0 &&
      nextProps.getParticipantActionsData.data[0].status === 'COMPLETE'
    ) {
      this.setState({ enableSubmission: true });
    }

    if (
      isActionSuccess(
        nextProps,
        'getParticipantAllActionsStatus',
        'getParticipantAllActionsData',
      )
    ) {
      this.setState({
        allParticipantActions: nextProps.getParticipantAllActionsData.data,
      });
    }

    if (
      isActionSuccess(
        nextProps,
        'postParticipantActionStatus',
        'postParticipantActionData',
      )
    ) {
      this.actionAfterSave(postParticipantActionData);
    }

    if (
      isActionSuccess(
        nextProps,
        'updateParticipantActionStatus',
        'updateParticipantActionData',
      )
    ) {
      this.actionAfterSave(updateParticipantActionData);
    }

    if (
      isActionSuccess(
        nextProps,
        'submitContributionStatus',
        'submitContributionData',
      )
    ) {
      this.redirectOnSuccessSave();
    }

    if (
      isActionError(
        nextProps,
        'postParticipantActionStatus',
        'postParticipantActionData',
      ) ||
      isActionError(
        nextProps,
        'updateParticipantActionStatus',
        'updateParticipantActionData',
      ) ||
      isActionError(
        nextProps,
        'submitContributionStatus',
        'submitContributionData',
      )
    ) {
      this.setState({ isSaving: false });
      toast.error("There's been an error!\nPlease try again!");
    }

    if (getActionData?.data?.meta?.maxSubmissions !== '-1') {
      if (
        !participantActionId &&
        this.dataHasLoaded(getActionData) &&
        this.dataHasLoaded(getParticipantActionsData) &&
        getParticipantActionsData.status === 200 &&
        getActionData.data.meta.maxSubmissions === 0
      ) {
        toast.error('You have reached the maximum of listings you can create.');
        this.props.history.push(`${this.state.pathname}/listings`);
      }
    }
  }

  fetchData = async () => {
    const { props } = this;
    const {
      id: campaignId,
      actionId,
      participantActionId,
    } = props.match.params;
    await props.getActivity(campaignId);
    await props.getAction(campaignId, actionId);
    const participantId = this.getParticipantId();
    if (participantActionId) {
      const query = `?q={"id":${participantActionId}}`;
      await props.getParticipantActions(campaignId, participantId, query);
    } else {
      const query = `?q={"actionId":${actionId}}`;
      await props.getParticipantActions(campaignId, participantId, query);
    }
    await props.getParticipantAllActions(campaignId, participantId);
    await props.getQuestionnaires();
  };

  getParticipantId = () => {
    const { id: campaignId } = this.props.match.params;
    const { activities } = this.state.profileData.data;
    const { participantId } = Object.values(activities).find(
      (activity) => activity.activityId === campaignId,
    );

    return participantId;
  };

  saveAction = () => {
    this.setState({ isSaving: true });
    const { participantActionId } = this.props.match.params;
    if (participantActionId) {
      this.updateAction();
    } else {
      this.postAction();
    }
  };

  saveSubmitAction = () => {
    this.setState({ saveAndSubmit: true });
    this.saveAction();
  };

  postAction = async () => {
    const { props } = this;
    const { id: campaignId, actionId } = props.match.params;
    const participantId = this.getParticipantId();
    const payload = {
      status: 'ACTIVE',
      visibility: 'PUBLIC',
      data: { ...this.state.questionnaireState },
      meta: {},
    };
    await props.postParticipantAction(
      campaignId,
      participantId,
      actionId,
      payload,
    );
  };

  actionAfterSave = async (participantActionData) => {
    const { props } = this;
    if (this.state.saveAndSubmit) {
      const { id: campaignId } = props.match.params;
      const participantId = this.getParticipantId();
      const payload = { participantActionId: participantActionData.data.id };
      await props.submitContribution(campaignId, participantId, payload);
    } else {
      this.redirectOnSuccessSave();
    }
    this.setState({ isSaving: false });
  };

  updateAction = async () => {
    const { props } = this;
    const { id: campaignId } = props.match.params;
    const participantId = this.getParticipantId();
    const participantAction = this.getParticipantAction();

    participantAction.data = {
      ...participantAction.data,
      ...this.state.questionnaireState,
    };
    await props.updateParticipantAction(
      campaignId,
      participantId,
      participantAction,
      participantAction.actionId,
    );
  };

  updateQuestionnaireState = (input_key, value) => {
    const { questionnaireState } = this.state;
    this.setState({
      questionnaireState: {
        ...questionnaireState,
        [input_key]: value,
      },
    });
  };

  getParticipantAction = () => {
    let participantAction;
    if (this.dataHasLoaded(this.props.getParticipantActionsData)) {
      participantAction = this.props.getParticipantActionsData.data[0];
    }

    return participantAction;
  };

  dataHasLoaded = (state) => !!(state && state.data);

  questionnaireHasUpdated = () =>
    Object.keys(this.state.questionnaireState).length > 0;

  redirectOnSuccessSave = () => {
    const { id: campaignId, handle, actionId } = this.props.match.params;
    let manageListingsUrl = `/campaign/${campaignId}/${handle}/participate`;

    if (this.isMultiEntryAction()) {
      manageListingsUrl += `/action/${actionId}/listings`;
    }

    window.location.href = manageListingsUrl;
  };

  isMultiEntryAction = () =>
    this.dataHasLoaded(this.props.getActionData) &&
    this.props.getActionData.data.meta.maxSubmissions > 1;

  saveButtonsRow = () => (
    <div className="complete-button">
      {!this.state.isSaving ? (
        <>
          <button
            type="button"
            className="btn btn-lg submit-btn mr-2"
            disabled={
              (!this.state.enableSubmission &&
                !this.questionnaireHasUpdated()) ||
              this.state.isSaving
            }
            onClick={() => this.saveAction()}>
            {getLabelByName(SAVE)}
          </button>
          <button
            type="button"
            className="btn btn-lg submit-btn"
            disabled={
              (!this.state.enableSubmission &&
                !this.questionnaireHasUpdated()) ||
              this.state.isSaving
            }
            onClick={() => this.saveSubmitAction()}>
            {`${getLabelByName(SAVE)} & ${getLabelByName(SUBMIT)}`}
          </button>
        </>
      ) : (
        <button type="button" className="btn btn-lg submit-btn" disabled>
          {`${getLabelByName(PROCESSING)}...`}
        </button>
      )}
    </div>
  );

  gotoBack = () => {
    const { id: campaignId, handle, actionId } = this.props.match.params;
    if (this.state.getActionData.meta.maxSubmissions > 1) {
      window.location.href = `/campaign/${campaignId}/${handle}/participate/action/${actionId}/listings`;
    } else {
      window.location.href = `/campaign/${campaignId}/${handle}/participate`;
    }
  };

  getListingsData = () => {
    let listings = [];
    if (this.dataHasLoaded(this.props.getParticipantActionsData)) {
      listings = this.props.getParticipantActionsData.data;
    }

    return listings;
  };
}

Contribute.propTypes = {
  getActivity: PropTypes.func,
  getSessionAction: PropTypes.func,
  getAction: PropTypes.func,
  postParticipantAction: PropTypes.func,
  getParticipantActions: PropTypes.func,
  updateParticipantAction: PropTypes.func,
  submitContribution: PropTypes.func,
  getParticipantAllActions: PropTypes.func,
  getQuestionnaires: PropTypes.func,
  getActivityData: PropTypes.any,
  getActivityStatus: PropTypes.any,
  getActionData: PropTypes.any,
  getActionStatus: PropTypes.any,
  postParticipantActionData: PropTypes.any,
  updateParticipantActionData: PropTypes.any,
  getParticipantActionsData: PropTypes.any,
  match: PropTypes.any,
  data: PropTypes.object,
  status: PropTypes.string,
  history: PropTypes.any,
  location: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  getActivityDataError: state.getActivityReducer.getActivityDataError,
  getActivityData: state.getActivityReducer.getActivityData,
  getActivityStatus: state.getActivityReducer.getActivityStatus,
  getActionDataError: state.getActionReducer.getActionDataError,
  getActionData: state.getActionReducer.getActionData,
  getActionStatus: state.getActionReducer.getActionStatus,
  postParticipantActionDataError:
    state.postParticipantActionReducer.postParticipantActionDataError,
  postParticipantActionData:
    state.postParticipantActionReducer.postParticipantActionData,
  postParticipantActionStatus:
    state.postParticipantActionReducer.postParticipantActionStatus,

  updateParticipantActionDataError:
    state.updateParticipantActionReducer.updateParticipantActionDataError,
  updateParticipantActionData:
    state.updateParticipantActionReducer.updateParticipantActionData,
  updateParticipantActionStatus:
    state.updateParticipantActionReducer.updateParticipantActionStatus,

  getParticipantActionsDataError:
    state.getParticipantActionsReducer.getParticipantActionsDataError,
  getParticipantActionsData:
    state.getParticipantActionsReducer.getParticipantActionsData,
  getParticipantActionsStatus:
    state.getParticipantActionsReducer.getParticipantActionsStatus,

  submitContributionDataError:
    state.submitContributionReducer.submitContributionDataError,
  submitContributionData:
    state.submitContributionReducer.submitContributionData,
  submitContributionStatus:
    state.submitContributionReducer.submitContributionStatus,

  getParticipantAllActionsDataError:
    state.getParticipantAllActionsReducer.getParticipantAllActionsDataError,
  getParticipantAllActionsData:
    state.getParticipantAllActionsReducer.getParticipantAllActionsData,
  getParticipantAllActionsStatus:
    state.getParticipantAllActionsReducer.getParticipantAllActionsStatus,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getActivity,
    getSessionAction,
    getAction,
    postParticipantAction,
    getParticipantActions,
    updateParticipantAction,
    submitContribution,
    getParticipantAllActions,
    getQuestionnaires,
  }),
)(Contribute);
