import {
  GET_CAMPAIGNS_ERROR, GET_CAMPAIGNS_SUCCESS,
} from '../actions/types';

const initialState = {
  campaignsData: null,
  campaignsDataError: null,
  campaignsStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaignsData: action.payload,
        campaignsDataError: null,
        campaignsStatus: 'success',
      };
    case GET_CAMPAIGNS_ERROR:
      return {
        ...state,
        campaignsDataError: action.payload,
        campaignsData: null,
        campaignsStatus: 'error',
      };
    default:
      return {
        ...state,
        // campaignsStatus: null,
      };
  }
};
