/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import {
  isAdmin,
  isSectionAdmin,
} from "../helpers/helperFunctions";

export default class AdminRoute extends Component {
  render() {
    const {
      component: ComponentToRender,
      authRole,
      ...rest
    } = this.props;
    return isAdmin() || isSectionAdmin(authRole) ? (
      <Route
        {...rest}
        render={(props) => <ComponentToRender {...props} />}
      />
    ) : (
      <Redirect to="/home" />
    );
  }
}

AdminRoute.propTypes = {
  component: PropTypes.any,
};
