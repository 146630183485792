import React, { useEffect, useState } from "react";
import Checkbox from '../../../components/common/checkbox/checkbox'

const CheckboxesInput = (props) => {
    const { settings, currentValue, participantAction, handleUpdate, isPreviewMode } = props;

    let selectedOptions = JSON.parse(currentValue || "[]");

    const handleChange = (e) => {
        if (e.target.checked && !selectedOptions.includes(e.target.value)) {
            selectedOptions.push(e.target.value);
        } else if (!e.target.checked && selectedOptions.includes(e.target.value)) {
            selectedOptions = selectedOptions.filter(option => option !== e.target.value);
        }

        handleUpdate({
            [settings.input_key]: JSON.stringify(selectedOptions)
        })
    }

    return (
        <div className="card section-card article-card p-0">
            <div className="card-body">
                <div className="card-details">
                    <h5 className="card-title">
                        {settings.required ? '*' : ''}
                        {settings.label}
                    </h5>
                    <br />
                    {settings.options.map((option, key) => {
                        return <Checkbox
                            key={key}
                            className="mb-3"
                            id={`${settings.input_key}_${key}`}
                            onChange={handleChange}
                            disabled={isPreviewMode}
                            value={option}
                            checked={selectedOptions.includes(option)}
                        >
                            {option}
                        </Checkbox>
                    })}
                </div>
            </div>
        </div>
    )
}

export default CheckboxesInput;
