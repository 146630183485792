/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import PropTypes from "prop-types";
import OutputCard from "./OutputCard";

class OutputsRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { outputs } = this.props;

    return (
      <div className="outputs-row d-flex">
        {outputs?.map((output) => (
          <OutputCard output={output} />
        ))}

        {this.props.children}
      </div>
    );
  }
}

OutputsRow.propTypes = {
  outputs: PropTypes.any,
  children: PropTypes.any,
};

export default OutputsRow;
