export const labelsDefaults = {
  en: {
    about_us: "About Us",
    already_registered: "Already have an account?",
    back: "Back",
    back_to: "Back to",
    city: "City",
    communities: "Communities",
    country: "Country",
    create: "Create",
    email: "Email",
    explore: "Explore",
    female: "Female",
    firstname: "Firstname",
    forgot_password: "Forgot Password?",
    forgot_password_description:
      "Enter the email address for your registered account below and we'll send you a password reset link",
    gender: "Gender",
    home: "Home",
    landing_welcome: "Welcome",
    lastname: "Lastname",
    author_name: "Author's Name",
    logging_in: "Logging in",
    login: "Login",
    male: "Male",
    ok: "Ok",
    payment_description: "Payment Description",
    amount: "Amount",
    currency: "Currency",
    select_frequency: "Select Frequency",
    number_of_payments: "Number of Payments",
    notification_settings: "Notification Settings",
    not_registered: "Not a member yet?",
    other: "Other",
    another: "Another",
    password: "Password",
    post: "Post",
    lesson: "Lesson",
    posting: "Posting",
    posted: "Posted",
    privacy_policy: "Privacy Policy",
    profile: "Profile",
    request_link: "Request Link",
    request_link_sent:
      "The email has been sent! Check your email inbox for the reset link.",
    search: "Search",
    send: "Send",
    sending: "Sending",
    signing_up: "Signing up",
    signup: "Signup",
    support: "Support",
    terms_conditions: "Terms & Conditions",
    notifications: "Notifications",
    view: "View",
    promotion: "Promotion",
    find: "Find",
    pay: "Pay",
    paying: "Paying",
    contribution: "Contribution",
    contribution_description:
      "Manage the information collected from participants of this activity",
    club: "Club",
    multiple: "Multiple",
    account: "account",
    clone: "Clone",
    cloning: "cloning",
    activity: "Activity",
    find_activity_description:
      "Search for an activity to promote",
    find_club_description: "Search for a club to promote",
    find_contribution_description:
      "Search for a contribution to promote",
    promote: "Promote",
    promote_form_description:
      "Use this publishing tool to encourage people to engage with your post",
    caption: "Caption",
    summary: "Summary",
    call_to_action: "Call to Action",
    select_action_type: "Select action type",
    promo_image_description:
      "Select an image to promote this activity",
    image_type_allowed:
      "Can upload png, jpeg, jpg and svg format",
    create_activity_subtitle: "Create a new Activity here",
    edit_activity_subtitle: "Update Activity details here",
    change_logo: "Change Logo",
    preview_uploaded_image_desc:
      "Can upload png, jpeg, jpg and svg format. Preview your upload on the right area of this card",
    background_image: "Background image",
    change_background_image_desc:
      "Upload your preferred background image, this would appear only on the homepage",
    start_date: "Start Date",
    end_date: "End Date",
    date: "Date",
    activity_continues: "Activity continues indefinitely",
    activity_status_select_description:
      "Select the status of your activity, you can change this at a later time",
    activities: "Activities",
    select: "Select",
    visit: "Visit",
    coming_soon: "coming soon",
    add_member_description:
      "Fill in the details of the user",
    edit_member_description:
      "Update the details of the selected user",
    role: "Role",
    article: "Article",
    questionnaire: "Questionnaire",
    question: "Question",
    questionnaires: "Questionnaires",
    post_description: "View and manage posts of this club",
    content_description:
      "View and manage contents of this club",
    questionnaire_description:
      "Manage the information collected from people through a questionnaire",
    questionnaire_description2:
      "View the information collected from people before joining the activity",
    form_title: "Form Title",
    form_description: "Form Description",
    type: "Type",
    question_type: "Question Type",
    label: "Label",
    required: "Required",
    text_area: "Text area",
    text: "Text",
    image: "Image",
    option: "Option",
    input_key: "Input Key",
    outputs: "Outputs",
    output: "Output",
    select_promotion_type: "Select Promotion Type",
    actions: "Actions",
    action: "Action",
    step: "Step",
    create_action_subtitle:
      "Provide details for a new Action",
    edit_action_subtitle: "Update details for an Action",
    max_submissions: "Max submissions",
    no_questionnaire_in_club:
      "No questionnaire available in this club!",
    contributions: "Contributions",
    reject: "Reject",
    rejected: "Rejected",
    approve: "Approve",
    updated: "updated",
    about_club: "About Club",
    view_club_information: "View Club information",
    starts_on: "Starts on",
    mininum: "Minimum",
    maximum: "Maximum",
    choices: "Choices",
    multiple_choice: "Multiple Choice",
    parameters: "Parameters",
    max_choices_reached: "Maximum choices reached!",
    defaults: "Defaults",
    add_listing_description_unlimited:
      "Create and manage your submissions",
    contributions_pending_review:
      "Contributions pending review",
    manage_outputs_description:
      "Manage your club's outputs here",
    target_audience: "Target Audience",
    join_the_campaign_to_view_participants:
      "Join the campaign to view participants",
    participant: "Participant",
    participants: "Participants",
    here: "here",
    create_output_subtitle:
      "Add the information of the output you want to create",
    edit_output_subtitle:
      "Edit the information of the output you have created",
    output_type: "Output Type",
    key: "Key",
    value: "Value",
    sources: "Sources",
    source: "Source",
    none: "None",
    remove: "Remove",
    removing: "Removing",
    most_active_activities: "Most active activities",
    most_popular_clubs: "Most popular clubs",
    clubs_with_recent_activity:
      "Clubs with recent activity",
    no_results_to_display: "No results to display!",
    no_clubs_joined: "You have not joined any clubs!",
    no_activities_participation:
      "You are not participating in any activities!",
    delete_post: "Delete Post",
    confirmation_text: "This action cannot be undone",
    delete: "Delete",
    deleting: "Deleting",
    calendar: "Calendar",
    today: "Today",
    no_action_Available: "No previously action was added",
    add_participant_description:
      "Invite people to participate in this activity",
    user: "User(s)",
    add_participant_success_message:
      "Participant(s) have been added successfully",
    response: "response",
    advanced_settings: "Advanced Settings",
    activity_status_description:
      "Select the status of your entity, you can change this at a later time",
    no_options_available:
      "No options available at this time",
    welcome_back: "Welcome Back",
    hello: "Hello",
    hello_description:
      "To start connecting with us please sign up with your account information",
    welcome_back_description:
      "To keep connecting with us please sign in with your account information",
    sign_in: "Sign In",
    answer_prev_question:
      "Please answer the following question(s) first:",
    all_users: "All users",
    users: "Users",
    view_all: "View all",
    no_description_found:
      "No description provided at the moment!",
    more_information: "More Information",
    no_more_activity_information:
      "No more information provided for this activity!",
    follow_the_steps:
      "Follow the steps below to contribute in this activity",
    this_word: "This",
    no_participants:
      "No one has participated in this activity yet!",
    how: "how",
    an_word: "an",
    and_word: "and",
    sign_word: "sign",
    to_word: "to",
    your_word: "your",
    into_word: "into",
    social_media_connect: "Connect with us on social media",
    overview_word: "Overview",
    discover: "Discover",
    members_only: "Members only",
    invitation_only: "Invitation only",
    error_uploading_picture:
      "Error uploading the picture!\nPlease try again.",
    create_club_subtitle:
      "Add the information of the club you want to create",
    edit_club_subtitle:
      "Edit the information of the club you have created",
    about: "About",
    select_club_status:
      "Select the status of your club, you can change this at a later time",
    select_from_computer: "Select from computer",
    error_processing_request:
      "There's been an error processing your request!",
    success_processing_request:
      "Your request was successful!",
    created_successfully: "Created successfully",
    cloned_successfully: "Cloned Successfully",
    updated_successfully: "Updated successfully",
    exit: "Exit",
    participant_Welcome_message:
      " You are a participant in this activity, view previously submitted contributions or add a new contribution",
    no_contribution_submitted_message:
      "You have no previously submitted contributions, continue to add a new contribution",
    contributing: "Contributing",
    has: "has",
    site_admin: "Site Admin",
    club_admin: "Club Admin",
    activity_admin: "Activity Admin",
    users_admin: "Users Admin",
    posts_admin: "Posts Admin",
    questionnaires_admin: "Questionnaires Admin",
    admin: "Admin",
    update_role_notification_successfully:
      "The role was updated successfully",
    update_role_notification_error:
      "Something went wrong while trying to update the role",
    delete_participant_notification_successfully:
      "The participant was removed successfully",
    delete_participant_notification_error:
      "Something went wrong while trying to remove the participant",
    no_contribution_steps:
      "No contribution methods(steps) were provided for this activity, please come back later!",
    Add_New_Participant: "Add New Participant",
    enter_text: "Enter Text",
    members: "Members",
    last_activity: "Last Activity",
    no_members: "No Members",
    no_activities: "No Activities",
    no_outputs: "No Outputs",
    club_activities: " Club - Activities",
    club_outputs: " Club - Outputs",
    club_members: " Club - Members",
    last_updated: "Last updated",
    entry: "Entry",
    entries: "Entries",
    add_new_entry: "Add new entry",
    add_new_action: "Add new action",
    add_lesson: "Add Lesson",
    edit_entry: "Edit Entry",
    save_draft: "Save Draft",
    complete_entries: "Complete entries",
    incomplete_entries: "Incomplete entries",
    complete_draft_entries: "Complete draft entries",
    incomplete_draft_entries: "Incomplete draft entries",
    remaining: "Remaining",
    created: "Created",
    advanced_settings_description:
      "Configure advanced club settings",
    club_activities_description:
      "View and manage activities of this club",
    club_members_description:
      "View and manage participants of this club",
    club_outputs_description:
      "View the information collected from people before joining the club",
    manage_profile_description:
      "View the information collected from people before joining the club",
    promotions_description:
      "Manage all promotions like outputs, activities, contributions and more for this club",
    specify_date_range: "Specify date range",
    filters: "Filters",
    date_added: "Date added",
    document_library: "Document Library",
    files: "Files",
    export: "Export",
    sort: "Sort",
    search_library: "Search library",
    profile_directory: "Profile Directory",
    profile_page: "Profile Page",
    gallery_page: "Gallery",
    media_library: "Media Library",
    media: "Media",
    listings: "Listings",
    listing: "Listing",
    download: "Download",
    uploaded: "Uploaded",
    copy_link: "Copy Link",
    copied: "Copied",
    deleted: "Deleted",
    successfully: "Successfully",
    are_you_sure_text: "Are you sure",
    want: "Want",
    from: "From",
    the_word: "The",
    confirmation: "Confirmation",
    action_is_removed_successfully:
      "Action is removed successfully",
    post_is_deleted_successfully:
      "Post is deleted successfully",
    remove_member_confirmation_text:
      "This action cannot be undone. Click “Remove” if you’re sure you’d like to remove this member",
    remove_confirmation_text:
      "This action cannot be undone. Click “Remove” if you’re sure you’d like to remove this",
    delete_confirmation_text:
      "This action cannot be undone. Click “Delete” if you’re sure you’d like to delete this",
    add_activity_information_description:
      "Add the information of the activity you want to",
    what_i_currently_do: "What I currently do",
    posts: "Posts",
    content: "Content",
    clubs: "Clubs",
    web_content: "Web Content",
    started_by: "Started By",
    started_on: "Started On",
    unknown: "Unknown",
    create_new: "Create new",
    select_user_status: "Select the status of your user",
    rows_per_page: "Rows per page",
    anonymous: "Anonymous",
    click_to_review_contribution:
      "Click to review this contribution",
    open_participant_view: "Open participant’s view",
    activity_settings_description:
      "Configure advanced activity settings",
    how_to_participate: "How to participate",
    how_to_participate_description:
      "Configure questionnaires and required actions to participate in this activity",
    previously_added_actions: "Previously added actions",
    participant_description:
      "View and manage participants of this activity",
    click_here_to_upload: "Click here to upload",
    logo: "Logo",
    landing_page: "Landing page",
    footer: "Footer",
    social_media: "Social media",
    create_output_status_description:
      "Select the status of your output, you can change this at a later time",
    basic: "Basic",
    header: "Header",
    line: "Line",
    subtitle: "Subtitle",
    global: "Global",
    directory: "Directory",
    choose_output_source_description:
      "Choose where the output data will be sources from",
    general: "General",
    configure_activity_advanced_settings:
      "Configure activity advanced settings",
    participate_call_to_action:
      "Participate Call-to-Action",
    participate_call_to_action_description:
      "This text will appear on the 'Participate' button for the activity. E.g 'Apply Now'",
    contribute_call_to_action: "Contribute Call-to-Action",
    contribute_call_to_action_description:
      "This text will appear on the 'Contribute' button for the activity. E.g 'Get started'",
    display_contributions: "Display Contributions",
    display_contributions_description:
      "Controls whether the number of contributions submitted should be displayed on the activity page. The default is yes. Turn off to hide this information.",
    display_participants: "Display Participants",
    display_participants_description:
      "Controls whether the number of participants and their profiles should be displayed on the activity page. The default is yes. Turn off to hide this information.",
    cta_text: "CTA Text",
    activity_details_visibility:
      "Activity Details Visibility",
    customize_url_handle: "Customize URL handle",
    back_to_activity_page: "Back to activity page",
    privacy_settings: "Privacy Settings",
    anonymous_contributions_description:
      "Controls whether contributions submitted on this activity are anonymous or not. The default is no. Turn on to anonymise contributions.",
    data_export_description:
      "Controls whether the data collected on this activity is allowed to be exported. The default is no. Turn on to allow.",
    anonymous_contributions: "Anonymous Contributions",
    data_export: "Data Export",
    contribution_saved_successfully:
      "Contribution is saved successfully",
    contribution_submitted_successfully:
      "Contribution is submitted successfully",
    welcome_email: "Welcome Email",
    send_participant_welcome_email:
      "Send Participant Welcome Email",
    welcome_email_sender: "Welcome Email Sender",
    welcome_email_subject: "Welcome Email Subject",
    welcome_email_content: "Welcome Email Content",
    contribution_confirmation_email:
      "Contribution Confirmation Email",
    send_contribution_confirmation_email:
      "Send Contribution Confirmation Email",
    contribution_confirmation_email_sender:
      "Contribution Confirmation Email Sender",
    contribution_confirmation_email_subject:
      "Contribution Confirmation Email Subject",
    contribution_confirmation_email_content:
      "Contribution Confirmation Email Content",
    review_notification_email: "Review Notification Email",
    send_review_notification_email:
      "Send Review Notification Email",
    review_notification_email_sender:
      "Review Notification Email Sender",
    review_notification_email_subject:
      "Review Notification Email Subject",
    review_notification_email_content:
      "Review Notification Email Content",
    status_approved: "Approved",
    status_rejected: "Rejected",
    status_pending: "Pending",
    contribution_intro:
      "Review contributions to this activity",
    no_contributions: "No contributions",
    pending: "Pending",
    go_to_profile: "Go to profile",
    add_contributions: "Add contributions",
    select_action_get_started:
      "Select action to get started",
    choose_action_contribute_behalf:
      "Choose the action you want to contribute on behalf of",
    submit_contribution_behalf:
      "Submit contribution on behalf",
    configure_club_advanced_settings:
      "Configure club advanced settings",
    back_to_club_page: "Back to club page",
    join_call_to_action: "Join Call-to-Action",
    join_call_to_action_description:
      "This text will appear on the 'Join' button for the club. E.g 'Join Now'",
    club_details_visibility: "Club Details Visibility",
    enable_activity_feed: "Enable Activity Feed",
    members_create_posts: "Members can create posts",
    display_members_panel: "Display Members Panel",
    enable_activity_feed_description:
      "Controls whether the activity feed on this club is displayed. The default is yes.",
    members_create_posts_description:
      "Controls whether the members of this clubs can create posts. The default is yes.",
    display_members_panel_description:
      "Controls whether the members panel is displayed. The default is yes.",
    send_member_welcome_email: "Send Member Welcome Email",
    login_to_continue: "Login to continue",
    participate: "Participate",
    cancel: "Cancel",
    join: "Join",
    configure_your: "Configure your",
    event_details: "Event Details",
    changes_saved: "Changes saved successfully",
    message: "Message",
    section: "Section",
    services_section: "Services section",
    about_section: "About section",
    upload_image_tooltip:
      "Change the image before uploading",
    promotions: "Promotions",
    en_labels: "EN Labels",
    fr_labels: "FR Labels",
  },

  fr: {
    upload_image_tooltip:
      "Changer l'image avant de télécharger",
    about_section: "à propos de la rubrique",
    service_section: "sections de services",
    message: "Message",
    changes_saved: "Changements sauvegardés avec succès",
    about_us: "Qui sommes nous?",
    agree_to: "J'accepte les",
    already_registered: "Déjà inscrit?",
    back: "Retour",
    back_to: "Retour à",
    city: "Ville",
    communities: "Communautés",
    country: "Pays",
    create: "Créer",
    email: "Email",
    enter_text: "saisir le texte",
    multiple: "Plusieurs",
    explore: "Explorer",
    female: "Femme",
    section: "Section",
    firstname: "Prénom",
    author_name: "Le nom de l'auteur",
    forgot_password: "Mot de passe oublié?",
    forgot_password_description:
      "Entrez l'addresse email associée à votre compte et vous recevrez un lien de restauration",
    gender: "Genre",
    home: "Accueil",
    landing_welcome: "Bienvenue",
    lastname: "Nom",
    logging_in: "Connexion en cours",
    login: "Se connecter",
    subtitle: "Sous-titre",
    global: "Global",
    directory: "Annuaire",
    choose_output_source_description:
      "Choisissez d'où proviendront les données de sortie",
    male: "Homme",
    not_registered: "Pas encore inscrit?",
    of_word: "de",
    an_word: "un",
    and_word: "et",
    post_description: "View and manage posts of this club",
    content_description:
      "View and manage contents of this club",
    other: "Autre",
    another: "Un autre",
    password: "Mot de passe",
    post: "Publier",
    posting: "Publier",
    posted: "Publie",
    privacy_policy: "Confidentialité",
    profile: "Profil",
    request_link: "Envoyer le lien",
    request_link_sent:
      "Email envoyé! Regardez votre boite de réception pour voir le lien.",
    search: "Rechercher",
    send: "Envoyer",
    sending: "Envoi en cours",
    signing_up: "Inscription en cours",
    signup: "S'inscrire",
    support: "Support",
    terms_conditions: "Conditions d'utilisation",
    notifications: "Notifications",
    view: "Voir",

    payment_description: "Description de paiement",
    amount: "Montant",
    currency: "Devise",
    select_frequency: "Sélectionnez la fréquence",
    number_of_payments: "Nombre de paiements",

    promotion: "Promotion",
    find: "Trouver",
    contribution: "Contribution",
    contribution_description:
      "Gérer les informations recueillies auprès des participants à cette activité",
    club: "Club",
    activity: "Activite",
    find_activity_description:
      "Rechercher les activites pour celle a promouvoir",
    find_club_description:
      "Rechercher les clubs pour celle a promouvoir",
    find_contribution_description:
      "Rechercher les contributions pour celle a promouvoir",
    promote: "Promouvoir",
    promote_form_description:
      "Utilisez cet outil pour encourager vos membres a participer a votre post",
    caption: "Titre",
    summary: "Resume",
    call_to_action: "Appel a Action",
    select_action_type: "Choisir un type d'action",
    promo_image_description:
      "Uploader une image pour cette activite, elle s'affichera dans la promotion",
    image_type_allowed:
      "Formats authorises: png, jpeg, jpg et svg",
    create_activity_subtitle:
      "Ajouter les infos de l'activite que vous voulez creer",
    edit_activity_subtitle:
      "Modifier les infos de l'activite que vous avez cree",
    change_logo: "Changer Logo",
    preview_uploaded_image_desc:
      "Can upload png, jpeg, jpg and svg format. Preview your upload on the right area of this card",
    background_image: "Image de fond",
    change_background_image_desc:
      "Téléchargez votre image de fond préférée, elle n'apparaîtra que sur la page d'accueil",
    start_date: "Commence le",
    end_date: "Finit le",
    activity_continues: "L'activite continue indefiniment",
    activity_status_select_description:
      "Choisissez le statut de votre activite, vous pouvez changer ulterieurement",
    activities: "Activites",
    select: "Choisir",
    visit: "Visite",
    coming_soon: "Bientôt disponible",
    add_member_description:
      "Entrez les informations du membre",
    edit_member_description:
      "Mettez a jour les informations du membre",
    role: "Role",
    article: "Article",
    questionnaire: "Questionnaire",
    question: "Question",
    questionnaires: "Questionnaires",
    questionnaire_description:
      "Gerer les informations collectees des utilisateurs via un questionnaire",
    questionnaire_description2:
      "Afficher les informations recueillies auprès des personnes avant de rejoindre l'activité",
    form_title: "Titre du formulaire",
    form_description: "Description du formulaire",
    type: "Type",
    question_type: "Type de Question",
    label: "Titre",
    required: "Requis",
    text_area: "Zone de texte",
    text: "Texte",
    image: "Image",
    option: "Option",
    input_key: "Input Key",
    output: "Production",
    outputs: "Productions",
    select_promotion_type:
      "Sélectionnez le type de promotion",
    actions: "Actions",
    action: "Action",
    step: "Etape",
    pay: "Payer",
    paying: "payant",
    create_action_subtitle:
      "Ajoutez les infos de l'action que vous voulez creer",
    edit_action_subtitle:
      "Modifiez les infos de l'action que vous avez creer",
    max_submissions: "Max de soumissions",
    no_questionnaire_in_club:
      "Pas de questionnaire disponible dans ce club!",
    contributions: "Contributions",
    reject: "Rejeter",
    rejected: "rejetée",
    approve: "Approuver",
    approved: "Approuvée",
    updated: "mis a jour",
    about_club: "À propos du club",
    view_club_information:
      "Afficher les informations du club",
    starts_on: "Commence le",
    mininum: "Minimum",
    maximum: "Maximum",
    choices: "Choix",
    multiple_choice: "Choix Multiple",
    parameters: "Parametres",
    max_choices_reached: "Maximum de choix atteint!",
    defaults: "Defaut",
    add_listing_description_unlimited:
      "Ajoutez une liste qui vous convient le mieux",
    contributions_pending_review:
      "Contributions en attente de revision",
    manage_outputs_description:
      "Gerer les resultats de votre club ici",
    date: "dated",
    target_audience: "Public Cible",
    join_the_campaign_to_view_participants:
      "Rejoignez la campagne pour voir les participants",
    participant: "participant(e)",
    participants: "participants",
    here: "ici",
    create_output_subtitle:
      "Ajouter les infos de la production que vous voulez creer",
    edit_output_subtitle:
      "Modifier les infos de la production que vous avez cree",
    output_type: "Type de production",
    key: "Cle",
    value: "Valeur",
    sources: "Sources",
    remove: "Supprimer",
    removing: "Supprimer",
    most_active_activities: "Activites les plus actives",
    most_popular_clubs: "Clubs les plus populaires",
    clubs_with_recent_activity:
      "Clubs avec activite recente",
    no_results_to_display: "Pas de contenu a afficher!",
    no_clubs_joined:
      "Vous n'avez encore rejoint aucun club!",
    no_activities_participation:
      "Vous ne participez a aucune activite!",
    delete_post: "Supprimer le message",
    confirmation_text:
      "Cette action ne peut pas être annulée",
    source: "Source",
    none: "Rien",
    delete: "Effacer",
    deleting: "suppression",
    calendar: "Calendrier",
    today: "Aujourd'hui",
    add_participant_description:
      "Inviter vos membres à participer à cette activité",
    user: "Utilisateur(s)",
    add_participant_success_message:
      "Les participants ont été ajoutés avec succès",
    response: "reponse",
    advanced_settings: "Paramètres avancés",
    no_options_available: "Pas d'options disponibles",
    welcome_back: "Content de te revoir",
    welcome_back_description:
      "Pour continuer à nous contacter, veuillez vous connecter avec les informations de votre compte",
    sign_in: "s'identifier",
    hello: "Bonjour",
    hello_description:
      "Pour commencer à vous connecter avec nous, veuillez vous inscrire avec les informations de votre compte",
    answer_prev_question: "Veuillez répondre à:",
    all_users: "Tous les utilisateurs",
    users: "Utilisateurs",
    notification_settings: "Paramètres de notification",
    no_description_found:
      "Aucune description trouvée pour le moment!",
    more_information: "plus d'informations français",
    no_more_activity_information:
      "Aucune autre information fournie pour cette activité",
    follow_the_steps:
      "Suivez les étapes ci-dessous pour contribuer à cette activité",
    this_word: "Cette",
    no_participants:
      "Personne n'a participé à cette activité pour le moment!",
    how: "comment",
    to_word: "à",
    into_word: "dans",
    ok: "Ok",
    social_media_connect:
      "Connectez-vous avec nous sur nos comptes de médias sociaux",
    overview_word: "Aperçu",
    discover: "Découvrir",
    account: "compte",
    members_only: "Membres seulement",
    invitation_only: "Sur invitation",
    error_uploading_picture:
      "Erreur en postant l'image!\nEssayez encore!.",
    create_club_subtitle:
      "Ajoutez les infos du club que vous voulez créer",
    edit_club_subtitle:
      "Modifiez les infos du club que vous avez créé",
    about: "A propos",
    select_club_status:
      "Selectionnez le statut de votre club, vous pouvez le changer plus tard",
    select_from_computer:
      "Sélectionnez depuis votre ordinateur",
    error_processing_request:
      "Il y a eu un erreur lors traitement de votre requete",
    success_processing_request:
      "Votre requête a été traitée avec succès!",
    created_successfully: "Créé avec succès",
    cloned_successfully: "Cloné avec succès",
    updated_successfully: "Mis à jour avec succès",
    exit: "Sortir",
    participant_Welcome_message:
      "Vous participez à cette activité, consultez précédemment contributions soumises ou ajouter une nouvelle contribution",
    no_contribution_submitted_message:
      "Vous n'avez pas encore soumis de contributions, continuez d'ajouter une nouvelle contribution",
    contributing: "Contribuant",
    has: "possède",
    site_admin: "Admin Site",
    club_admin: "Admin Club",
    activity_admin: "Admin Activité",
    users_admin: "Admin Utilisateurs",
    posts_admin: "Admin Posts",
    questionnaires_admin: "Admin Questionnaires",
    admin: "Admin",
    view_all: "Voir tout",
    update_role_notification_successfully:
      "Le rôle a été mis à jour avec succès",
    update_role_notification_error:
      "Une erreur s'est produite lors de la tentative de mise à jour du rôle",
    delete_participant_notification_successfully:
      "Le participant a été supprimé avec succès",
    delete_participant_notification_error:
      "Une erreur s'est produite lors de la tentative de suppression du participant",
    no_contribution_steps:
      "Aucune méthode de contribution n'a été fournie pour cette activité, merci de revenir plus tard!",
    members: "Membres",
    last_activity: "Dernière activité",
    no_members: "Pas de members",
    no_activities: "Pad d'activités",
    no_outputs: "Pas de productions",
    club_activities: " Club - Activités",
    club_outputs: " Club - Outputs",
    club_members: " Club - Membres",
    last_updated: "Dernière mise à jour",
    add_new_entry: "Ajouter une nouvelle entrée",
    add_new_action: "Ajouter une nouvelle action",
    add_lesson: "Ajouter une leçon",
    edit_entry: "Modifier l'entrée",
    entry: "Entrée",
    entries: "Entrées",
    complete_entries: "Entrées complètes",
    incomplete_entries: "entrées incomplètes",
    complete_draft_entries: "Terminer les brouillons",
    incomplete_draft_entries: "Brouillons incomplets",
    remaining: "restant(e)",
    created: "Créé",
    advanced_settings_description:
      "Configurer les paramètres avancés du club",
    specify_date_range: "Specifiez dates",
    filters: "Filtres",
    date_added: "Date d'ajout",
    document_library: "Docuthèque",
    files: "Fichiers",
    export: "Exporter",
    sort: "Trier",
    search_library: "Rechercher docuthèque",
    profile_directory: "Répertoire des profils",
    profile_page: "Page de profil",
    gallery_page: "Gallerie",
    media_library: "Docuthèque",
    media: "Media",
    listings: "Listings",
    club_activities_description:
      "Afficher et gérer les activités de cette club ",
    club_members_description:
      "Afficher et gérer les participants à cette club ",
    club_outputs_description:
      "Afficher les informations recueillies auprès des personnes avant de rejoindre club",
    manage_profile_description:
      "Afficher les informations recueillies auprès des personnes avant de rejoindre club",
    promotions_description:
      "Gérez toutes les promotions telles que les sorties, les activités, les contributions et plus encore pour ce club",
    download: "Télécharger",
    uploaded: "Uploadé",
    copy_link: "Copier Lien",
    copied: "Copié",
    deleted: "supprimé",
    successfully: "avec succès",
    remove_confirmation_description_text:
      "Cette action est irréversible, êtes-vous sûr de vouloir supprimer ce membre de",
    are_you_sure_text: "Es-tu sûr",
    want: "Vouloir",
    from: "De",
    the_word: "Le",
    confirmation: "Confirmation",
    action_is_removed_successfully:
      "L'action a été supprimée avec succès",
    post_is_deleted_successfully:
      "La post a été supprimée avec succès",
    remove_member_confirmation_text:
      "Cette action ne peut pas être annulée. Cliquez sur « Supprimer » si vous êtes sûr de vouloir supprimer ce membre",
    remove_confirmation_text:
      "Cette action ne peut pas être annulée. Cliquez sur « Supprimer » si vous êtes sûr de vouloir supprimer ce",
    delete_confirmation_text:
      "Cette action ne peut pas être annulée. Cliquez sur « Supprimer » si vous êtes sûr de vouloir supprimer ce",
    add_activity_information_description:
      "Ajoutez les informations de l'activité que vous souhaitez",
    what_i_currently_do: "Ce que je fais actuellement",
    posts: "Posts",
    clubs: "Clubs",
    web_content: "Contenu Web",
    started_by: "Commencé par",
    started_on: "Commencé le",
    unknown: "Inconnu",
    create_new: "Créer nouveau",
    select_user_status:
      "Sélectionnez le statut de votre utilisateur",
    rows_per_page: "Lignes par page",
    anonymous: "Anonyme",
    click_to_review_contribution:
      "Cliquez pour réviser cette contribution",
    open_participant_view: "Ouvrir la vue des participants",
    activity_settings_description:
      "Configurer les paramètres d'activité avancés",
    how_to_participate: "comment participer",
    how_to_participate_description:
      "Configurer les questionnaires et les actions requises pour participer à cette activité",
    previously_added_actions:
      "Actions précédemment ajoutées",
    no_action_Available:
      "Aucune action précédente n'a été ajoutée",
    participant_description:
      "Afficher et gérer les participants à cette activité",
    listing: "Listing",
    general: "Général",
    configure_activity_advanced_settings:
      "Configurer les paramètres avancés sur activité",
    participate_call_to_action: "Appel à participer",
    participate_call_to_action_description:
      "Ce texte sera affiché sur le bouton 'Participer' de cette activité. E.g 'Participer Maintenant'",
    contribute_call_to_action: "Appel à Contribuer",
    contribute_call_to_action_description:
      "Ce texte sera affiché sur le bouton 'Participer' de cette activité. E.g 'Commencer!'",
    display_contributions: "Afficher les contributions",
    display_contributions_description:
      "Controle si le nombre de contributions soumises doit être affiché sur la page de l'activité. Par défaut, oui. Désactiver pour cacher cette information.",
    display_participants: "Afficher les participants",
    display_participants_description:
      "Controle si le nombre de participants soumises doit être affiché sur la page de l'activité. Par défaut, oui. Désactiver pour cacher cette information.",
    cta_text: "Texte d'Appel à l'Action",
    activity_details_visibility:
      "Visibilité des détails de l'activité",
    customize_url_handle: "Customiser le lien",
    back_to_activity_page: "Retour à l'activité",
    click_here_to_upload: "Cliquez ici pour télécharger",
    logo: "Logo",
    landing_page: "Page de destination",
    footer: "Bas de page",
    social_media: "Des médias sociaux",
    create_output_status_description:
      "Sélectionnez l'état de votre sortie, vous pouvez le modifier ultérieurement",
    basic: "De base",
    header: "Entête",
    line: "Ligne",
    privacy_settings: "Paramètres de Confidentialité",
    anonymous_contributions_description:
      "Controle si les contributions soumises sur cette activité sont anonymes. Par défaut, non. Activer pour rendre les contributions anonymes.",
    data_export_description:
      "Controle si les données collectées sur cette activité sont exportables. Par défaut, non. Activer pour authoriser.",
    anonymous_contributions: "Contributions anonymes",
    data_export: "Export de données",
    contribution_saved_successfully:
      "La contribution est enregistrée avec succès",
    contribution_submitted_successfully:
      "La contribution est soumise avec succès",
    welcome_email: "Email de bienvenue",
    send_participant_welcome_email:
      "Envoyer au participant un email de bienvenue",
    welcome_email_sender:
      "Expéditeur de l'email de bienvenue",
    welcome_email_subject: "Sujet de l'email de bienvenue",
    welcome_email_content:
      "Contenu de l'email de bienvenue",
    contribution_confirmation_email:
      "Email de confirmation de contribution",
    send_contribution_confirmation_email:
      "Envoyer email de confirmation de contribution",
    contribution_confirmation_email_sender:
      "Expéditeur de l'email de confirmation de contribution",
    contribution_confirmation_email_subject:
      "Sujet de l'email de confirmation de contribution",
    contribution_confirmation_email_content:
      "Contenu de l'email de confirmation de contribution",
    review_notification_email:
      "Email de notification de revue",
    send_review_notification_email:
      "Envoyer Email de notification de revue",
    review_notification_email_sender:
      "Expéditeur de l'email de notification de revue",
    review_notification_email_subject:
      "Sujet de l'email de notification de revue",
    review_notification_email_content:
      "Contenu de l'email de notification de revue",
    status_approved: "Approuvé",
    status_rejected: "Rejeté",
    status_pending: "en attendant",
    contribution_intro:
      "Examiner les contributions à cette activité",
    no_contributions: "Aucune cotisation",
    pending: "En attendant",
    go_to_profile: "Aller au profil",
    add_contributions: "Ajouter une contribution",
    select_action_get_started:
      "Sélectionner une action pour commencer",
    choose_action_contribute_behalf:
      "Choisir l'action à laquelle vous voulez contribuer pour autrui",
    submit_contribution_behalf:
      "Soumettre la contribution pour autrui",
    configure_club_advanced_settings:
      "Configurer les paramètres avancés sur club",
    back_to_club_page: "Retour au club",
    join_call_to_action: "Appel à joindre",
    join_call_to_action_description:
      "Ce texte sera affiché sur le bouton 'Joindre' de ce club. E.g 'Joindre Maintenant'",
    club_details_visibility:
      "Visibilité des détails du club",
    enable_activity_feed: "Activer le fil d'actualité",
    members_create_posts:
      "Les membres peuvent créer des posts",
    display_members_panel: "Afficher les membres",
    enable_activity_feed_description:
      "Controle si le fil d'actualité de ce club sera affiché. Par défaut, oui.",
    members_create_posts_description:
      "Controle si les membres de ce club sera peuvent poster sur le fil d'actualité. Par défaut, oui.",
    display_members_panel_description:
      "Controle si les membres de ce club sera seront afficher sur la page du club. Par défaut, oui.",
    send_member_welcome_email:
      "Envoyer au membre un email de bienvenue",
    login_to_continue: "Connectez-vous pour continuer",
    participate: "Participer",
    cancel: "Annuler",
    join: "Joindre",
    configure_your: "Configurez votre",
    event_details: "Détails de l'évènement",
    clone: "Cloner",
    cloning: "clonage",
    promotions: "promotions",
    en_labels: "Labels EN",
    fr_labels: "Labels FR",
  },
};
