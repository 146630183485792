import {
  GET_INSTITUTE_ERROR, GET_INSTITUTE_LOADING, GET_INSTITUTE_SUCCESS,
} from '../actions/types';

const initialState = {
  instituteData: null,
  instituteDataError: null,
  instituteStatus: null,
  instituteLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INSTITUTE_SUCCESS:
      return {
        ...state,
        instituteData: action.payload,
        instituteDataError: null,
        instituteStatus: 'success',
        instituteLoading: false,
      };
    case GET_INSTITUTE_ERROR:
      return {
        ...state,
        instituteDataError: action.payload,
        instituteData: null,
        instituteStatus: 'error',
        instituteLoading: false,
      };
    case GET_INSTITUTE_LOADING:
      return {
        ...state,
        instituteDataError: null,
        instituteData: null,
        instituteStatus: 'loading',
        instituteLoading: action.payload,
      };
    default:
      return {
        ...state,
        instituteStatus: null,
      };
  }
};
