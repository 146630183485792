export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const GUEST_LOGIN_ERROR = "GUEST_LOGIN_ERROR";
export const GUEST_LOGIN_SUCCESS = "GUEST_LOGIN_SUCCESS";
export const GET_SESSION_ERROR = "GET_SESSION_ERROR";
export const GET_SESSION_SUCCESS = "GET_SESSION_SUCCESS";
export const FORGOT_PASSWORD_ERROR =
  "FORGOT_PASSWORD_ERROR";
export const FORGOT_PASSWORD_SUCCESS =
  "FORGOT_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_SUCCESS =
  "RESET_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR =
  "CHANGE_PASSWORD_ERROR";
export const CHANGE_PASSWORD_SUCCESS =
  "CHANGE_PASSWORD_SUCCESS";
export const GET_INSTITUTES_ERROR = "GET_INSTITUTES_ERROR";
export const GET_INSTITUTES_SUCCESS =
  "GET_INSTITUTES_SUCCESS";
export const GET_USER_CLUBS_ERROR = "GET_USER_CLUBS_ERROR";
export const GET_USER_CLUBS_SUCCESS =
  "GET_USER_CLUBS_SUCCESS";
export const GET_INSTITUTE_ERROR = "GET_INSTITUTE_ERROR";
export const GET_INSTITUTE_SUCCESS =
  "GET_INSTITUTE_SUCCESS";
export const GET_INSTITUTE_LOADING =
  "GET_INSTITUTE_LOADING";
export const GET_CAMPAIGNS_ERROR = "GET_CAMPAIGNS_ERROR";
export const GET_CAMPAIGNS_SUCCESS =
  "GET_CAMPAIGNS_SUCCESS";
export const GET_CLUB_CAMPAIGNS_ERROR =
  "GET_CLUB_CAMPAIGNS_ERROR";
export const GET_CLUB_CAMPAIGNS_SUCCESS =
  "GET_CLUB_CAMPAIGNS_SUCCESS";
export const GET_ADMIN_CLUB_ACTIVITIES_ERROR =
  "GET_ADMIN_CLUB_ACTIVITIES_ERROR";
export const GET_ADMIN_CLUB_ACTIVITIES_SUCCESS =
  "GET_ADMIN_CLUB_ACTIVITIES_SUCCESS";
export const GET_ADMIN_CLUB_ACTIVITIES_LOADING =
  "GET_ADMIN_CLUB_ACTIVITIES_LOADING";
export const CHANGE_USERNAME_ERROR =
  "CHANGE_USERNAME_ERROR";
export const CHANGE_USERNAME_SUCCESS =
  "CHANGE_USERNAME_SUCCESS";
export const UPLOAD_PICTURE_ERROR = "UPLOAD_PICTURE_ERROR";
export const UPLOAD_PICTURE_SUCCESS =
  "UPLOAD_PICTURE_SUCCESS";
export const GET_CLUB_MEMBERS_ERROR =
  "GET_CLUB_MEMBERS_ERROR";
export const GET_CLUB_MEMBERS_SUCCESS =
  "GET_CLUB_MEMBERS_SUCCESS";
export const EDIT_CLUB_MEMBERS_SUCCESS =
  "EDIT_CLUB_MEMBERS_SUCCESS";
export const EDIT_CLUB_MEMBERS_ERROR =
  "EDIT_CLUB_MEMBERS_ERROR";
export const EDIT_CLUB_MEMBERS_LOADING =
  "EDIT_CLUB_MEMBERS_LOADING";
export const DELETE_CLUB_MEMBERS_SUCCESS =
  "DELETE_CLUB_MEMBERS_SUCCESS";
export const DELETE_CLUB_MEMBERS_ERROR =
  "DELETE_CLUB_MEMBERS_ERROR";
export const DELETE_CLUB_MEMBERS_LOADING =
  "DELETE_CLUB_MEMBERS_LOADING";
export const JOIN_CLUB_ERROR = "JOIN_CLUB_ERROR";
export const JOIN_CLUB_SUCCESS = "JOIN_CLUB_SUCCESS";
export const SEARCH_CLUBS_NAME_ERROR =
  "SEARCH_CLUBS_NAME_ERROR";
export const SEARCH_CLUBS_NAME_SUCCESS =
  "SEARCH_CLUBS_NAME_SUCCESS";
export const SEARCH_CLUBS_DESCRIPTION_ERROR =
  "SEARCH_CLUBS_DESCRIPTION_ERROR";
export const SEARCH_CLUBS_DESCRIPTION_SUCCESS =
  "SEARCH_CLUBS_DESCRIPTION_SUCCESS";
export const SEARCH_CAMPAIGNS_NAME_ERROR =
  "SEARCH_CAMPAIGNS_NAME_ERROR";
export const SEARCH_CAMPAIGNS_NAME_SUCCESS =
  "SEARCH_CAMPAIGNS_NAME_SUCCESS";
export const SEARCH_CAMPAIGNS_DESCRIPTION_ERROR =
  "SEARCH_CAMPAIGNS_DESCRIPTION_ERROR";
export const SEARCH_CAMPAIGNS_DESCRIPTION_SUCCESS =
  "SEARCH_CAMPAIGNS_DESCRIPTION_SUCCESS";
export const LANDING_ERROR = "LANDING_ERROR";
export const LANDING_SUCCESS = "LANDING_SUCCESS";
export const LANDING_LOADING = "LANDING_LOADING";
export const SITE_CONTENT_ERROR = "SITE_CONTENT_ERROR";
export const SITE_CONTENT_SUCCESS = "SITE_CONTENT_SUCCESS";
export const CREATE_POST_ERROR = "CREATE_POST_ERROR";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const GET_CLUB_LESSONS_ERROR = "CLUB_LESSONS_ERROR";
export const GET_CLUB_LESSONS_SUCCESS =
  "CLUB_LESSONS_SUCCESS";
export const GET_CLUB_ARTICLES_ERROR =
  "CLUB_ARTICLES_ERROR";
export const GET_CLUB_ARTICLES_SUCCESS =
  "CLUB_ARTICLES_SUCCESS";
export const GET_CLUB_POSTS_ERROR = "CLUB_POSTS_ERROR";
export const GET_CLUB_POSTS_SUCCESS = "CLUB_POSTS_SUCCESS";
export const GET_POSTS_ERROR = (postType = "post") =>
  `GET_${postType.toUpperCase()}S_ERROR`;
export const GET_POSTS_SUCCESS = (postType = "post") =>
  `GET_${postType.toUpperCase()}S_SUCCESS`;
export const CREATE_CLUB_ERROR = "CREATE_CLUB_ERROR";
export const CREATE_CLUB_SUCCESS = "CREATE_CLUB_SUCCESS";
export const UPDATE_CLUB_ERROR = "UPDATE_CLUB_ERROR";
export const UPDATE_CLUB_SUCCESS = "UPDATE_CLUB_SUCCESS";
export const GET_MEMBER_ERROR = "GET_MEMBER_ERROR";
export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
export const GET_NEWSFEED_ERROR = "GET_NEWSFEED_ERROR";
export const GET_NEWSFEED_SUCCESS = "GET_NEWSFEED_SUCCESS";
export const GET_POST_ERROR = "GET_POST_ERROR";
export const GET_POST_SUCCESS = "GET_POST_SUCCESS";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_ERROR = "DELETE_POST_ERROR";
export const GET_ACTIVITIES_ERROR = "GET_ACTIVITIES_ERROR";
export const GET_ACTIVITIES_SUCCESS =
  "GET_ACTIVITIES_SUCCESS";
export const GET_ACTIVITY_ERROR = "GET_ACTIVITY_ERROR";
export const GET_ACTIVITY_LOADING = "GET_ACTIVITY_LOADING";
export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";
export const GET_PARTICIPANT_CONTRIBUTION_ERROR =
  "GET_PARTICIPANT_CONTRIBUTION_ERROR";
export const GET_PARTICIPANT_CONTRIBUTION_LOADING =
  "GET_PARTICIPANT_CONTRIBUTION_LOADING";
export const GET_PARTICIPANT_CONTRIBUTION_SUCCESS =
  "GET_PARTICIPANT_CONTRIBUTION_SUCCESS";
export const CREATE_ACTIVITY_ERROR =
  "CREATE_ACTIVITY_ERROR";
export const CREATE_ACTIVITY_SUCCESS =
  "CREATE_ACTIVITY_SUCCESS";
export const EXPORT_ACTIVITY_SUCCESS =
  "EXPORT_ACTIVITY_SUCCESS";
export const EXPORT_ACTIVITY_ERROR =
  "EXPORT_ACTIVITY_ERROR";
export const CLONE_ACTIVITY_ERROR = "CLONE_ACTIVITY_ERROR";
export const CLONE_ACTIVITY_SUCCESS =
  "CLONE_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITY_ERROR =
  "UPDATE_ACTIVITY_ERROR";
export const UPDATE_ACTIVITY_SUCCESS =
  "UPDATE_ACTIVITY_SUCCESS";
export const GET_PARTICIPANTS_ERROR =
  "GET_PARTICIPANTS_ERROR";
export const GET_PARTICIPANTS_SUCCESS =
  "GET_PARTICIPANTS_SUCCESS";
export const UPDATE_POST_ERROR = "UPDATE_POST_ERROR";
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS";
export const POST_REACTION_ERROR = "POST_REACTION_ERROR";
export const POST_REACTION_SUCCESS =
  "POST_REACTION_SUCCESS";
export const DELETE_REACTION_ERROR =
  "DELETE_REACTION_ERROR";
export const DELETE_REACTION_SUCCESS =
  "DELETE_REACTION_SUCCESS";
export const GET_REACTIONS_ERROR = "GET_REACTIONS_ERROR";
export const GET_REACTIONS_SUCCESS =
  "GET_REACTIONS_SUCCESS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";
export const REQUEST_PASSWORD_SUCCESS =
  "REQUEST_PASSWORD_SUCCESS";
export const REQUEST_PASSWORD_ERROR =
  "REQUEST_PASSWORD_ERROR";
export const GET_QUESTIONNAIRE_SUCCESS =
  "GET_QUESTIONNAIRE_SUCCESS";
export const GET_QUESTIONNAIRE_ERROR =
  "GET_QUESTIONNAIRE_ERROR";
export const GET_QUESTIONNAIRE_LOADING =
  "GET_QUESTIONNAIRE_LOADING";
export const PARTICIPATE_CAMPAIGN_SUCCESS =
  "PARTICIPATE_CAMPAIGN_SUCCESS";
export const PARTICIPATE_CAMPAIGN_ERROR =
  "PARTICIPATE_CAMPAIGN_ERROR";
export const GET_ACTION_SUCCESS = "GET_ACTION_SUCCESS";
export const GET_ACTION_ERROR = "GET_ACTION_ERROR";
export const POST_PARTICIPANT_ACTION_SUCCESS =
  "POST_PARTICIPANT_ACTION_SUCCESS";
export const POST_PARTICIPANT_ACTION_ERROR =
  "POST_PARTICIPANT_ACTION_ERROR";
export const GET_PARTICIPANT_ACTIONS_SUCCESS =
  "GET_PARTICIPANT_ACTIONS_SUCCESS";
export const GET_PARTICIPANT_ACTIONS_ERROR =
  "GET_PARTICIPANT_ACTIONS_ERROR";
export const GET_PARTICIPANT_ACTIONS_LOADING =
  "GET_PARTICIPANT_ACTIONS_LOADING";
export const UPDATE_PARTICIPANT_ACTION_SUCCESS =
  "UPDATE_PARTICIPANT_ACTION_SUCCESS";
export const UPDATE_PARTICIPANT_ACTION_ERROR =
  "UPDATE_PARTICIPANT_ACTION_ERROR";
export const DELETE_PARTICIPANT_ACTION_ERROR =
  "DELETE_PARTICIPANT_ACTION_ERROR";
export const DELETE_PARTICIPANT_ACTION_SUCCESS =
  "DELETE_PARTICIPANT_ACTION_SUCCESS";
export const SUBMIT_CONTRIBUTION_SUCCESS =
  "SUBMIT_CONTRIBUTION_SUCCESS";
export const SUBMIT_CONTRIBUTION_ERROR =
  "SUBMIT_CONTRIBUTION_ERROR";
export const GET_CONTRIBUTIONS_SUCCESS =
  "GET_CONTRIBUTIONS_SUCCESS";
export const GET_CONTRIBUTIONS_ERROR =
  "GET_CONTRIBUTIONS_ERROR";
export const GET_OUTPUTS_SUCCESS = "GET_OUTPUTS_SUCCESS";
export const GET_OUTPUTS_ERROR = "GET_OUTPUTS_ERROR";
export const GET_OUTPUT_SUCCESS = "GET_OUTPUT_SUCCESS";
export const GET_OUTPUT_ERROR = "GET_OUTPUT_ERROR";
export const GET_LABELS_SUCCESS = "GET_LABELS_SUCCESS";
export const GET_LABELS_ERROR = "GET_LABELS_ERROR";
export const GET_POST_LESSON_SUCCESS =
  "GET_POST_LESSON_SUCCESS";
export const GET_POST_LESSON_ERROR =
  "GET_POST_LESSON_ERROR";

// FILTER ACTION TYPES
export const FILTER_MOST_ACTIVE_ACTIVITIES =
  "FILTER_MOST_ACTIVE_ACTIVITIES";
export const FILTER_MOST_ACTIVE_ACTIVITIES_ERROR =
  "FILTER_MOST_ACTIVE_ACTIVITIES_ERROR";

export const FILTER_MOST_POPULAR_CLUBS =
  "FILTER_MOST_POPULAR_CLUBS";
export const FILTER_MOST_POPULAR_CLUBS_ERROR =
  "FILTER_MOST_POPULAR_CLUBS_ERROR";

export const FILTER_MOST_ACTIVE_CLUBS =
  "FILTER_MOST_ACTIVE_CLUBS";
export const FILTER_MOST_ACTIVE_CLUBS_ERROR =
  "FILTER_MOST_ACTIVE_CLUBS_ERROR";

// NOTIFICATIONS ACTION TYPES
export const GET_NOTIFICATIONS_SUCCESS =
  "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_ERROR =
  "GET_NOTIFICATIONS_ERROR";
export const MARK_NOTIFICATION_SUCCESS =
  "MARK_NOTIFICATION_SUCCESS";
export const MARK_NOTIFICATION_ERROR =
  "MARK_NOTIFICATION_ERROR";
export const ADD_DEVICE_TOKEN_SUCCESS =
  "ADD_DEVICE_TOKEN_SUCCESS";
export const ADD_DEVICE_TOKEN_ERROR =
  "ADD_DEVICE_TOKEN_ERROR";

// CREATE USER ACTION TYPES
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

// GET QUESTIONNAIRES TYPES
export const GET_QUESTIONNAIRES_SUCCESS =
  "GET_QUESTIONNAIRES_SUCCESS";
export const GET_QUESTIONNAIRES_ERROR =
  "GET_QUESTIONNAIRES_ERROR";

// CREATE QUESTIONNAIRES TYPES
export const CREATE_QUESTIONNAIRE_SUCCESS =
  "CREATE_QUESTIONNAIRE_SUCCESS";
export const CREATE_QUESTIONNAIRE_ERROR =
  "CREATE_QUESTIONNAIRE_ERROR";

// CLONE QUESTIONNAIRES TYPES
export const CLONE_QUESTIONNAIRE_SUCCESS =
  "CLONE_QUESTIONNAIRE_SUCCESS";
export const CLONE_QUESTIONNAIRE_ERROR =
  "CLONE_QUESTIONNAIRE_ERROR";

// UPDATE QUESTIONNAIRES TYPES
export const UPDATE_QUESTIONNAIRE_SUCCESS =
  "UPDATE_QUESTIONNAIRE_SUCCESS";
export const UPDATE_QUESTIONNAIRE_ERROR =
  "UPDATE_QUESTIONNAIRE_ERROR";

// CREATE QUESTION TYPES
export const CREATE_QUESTION_SUCCESS =
  "CREATE_QUESTION_SUCCESS";
export const CREATE_QUESTION_ERROR =
  "CREATE_QUESTION_ERROR";

// UPDATE QUESTION TYPES
export const UPDATE_QUESTION_SUCCESS =
  "UPDATE_QUESTION_SUCCESS";
export const UPDATE_QUESTION_ERROR =
  "UPDATE_QUESTION_ERROR";

// DELETE QUESTION TYPES
export const DELETE_QUESTION_SUCCESS =
  "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_ERROR =
  "DELETE_QUESTION_ERROR";

// CREATE ACTION TYPES
export const CREATE_ACTION_SUCCESS =
  "CREATE_ACTION_SUCCESS";
export const CREATE_ACTION_ERROR = "CREATE_ACTION_ERROR";

// CREATE LESSON TYPES
export const CREATE_LESSON_SUCCESS =
  "CREATE_LESSON_SUCCESS";
export const CREATE_LESSON_ERROR = "CREATE_LESSON_ERROR";

// UPDATE LESSON TYPES
export const UPDATE_LESSON_SUCCESS =
  "UPDATE_LESSON_SUCCESS";
export const UPDATE_LESSON_ERROR = "UPDATE_LESSON_ERROR";

// GET LESSONS TYPES
export const GET_LESSONS_SUCCESS = "GET_LESSONS_SUCCESS";
export const GET_LESSONS_ERROR = "GET_LESSONS_ERROR";

// EDIT ACTION TYPES
export const EDIT_ACTION_SUCCESS = "EDIT_ACTION_SUCCESS";
export const EDIT_ACTION_ERROR = "EDIT_ACTION_ERROR";

// EDIT ACTION TYPES
export const DELETE_ACTION_SUCCESS =
  "DELETE_ACTION_SUCCESS";
export const DELETE_ACTION_ERROR = "DELETE_ACTION_ERROR";

// UPDATE CONTRIBUTION TYPES
export const UPDATE_CONTRIBUTION_SUCCESS =
  "UPDATE_CONTRIBUTION_SUCCESS";
export const UPDATE_CONTRIBUTION_ERROR =
  "UPDATE_CONTRIBUTION_ERROR";

// CREATE OUTPUT TYPES
export const CREATE_OUTPUT_SUCCESS =
  "CREATE_OUTPUT_SUCCESS";
export const CREATE_OUTPUT_ERROR = "CREATE_OUTPUT_ERROR";

// CLONE OUTPUT TYPES
export const CLONE_OUTPUT_SUCCESS = "CLONE_OUTPUT_SUCCESS";
export const CLONE_OUTPUT_ERROR = "CLONE_OUTPUT_ERROR";

// UPDATE OUTPUT TYPES
export const UPDATE_OUTPUT_SUCCESS =
  "UPDATE_OUTPUT_SUCCESS";
export const UPDATE_OUTPUT_ERROR = "UPDATE_OUTPUT_ERROR";

// ADD PARTICIPANT TYPES
export const ADD_PARTICIPANT_SUCCESS =
  "ADD_PARTICIPANT_SUCCESS";
export const ADD_PARTICIPANT_ERROR =
  "ADD_PARTICIPANT_ERROR";

// GET PARTICIPANTS ALL ACTIONS
export const GET_PARTICIPANT_ALL_ACTIONS_SUCCESS =
  "GET_PARTICIPANT_ALL_ACTIONS_SUCCESS";
export const GET_PARTICIPANT_ALL_ACTIONS_ERROR =
  "GET_PARTICIPANT_ALL_ACTIONS_ERROR";

// POST CONTRIBUTIONS COMMENTS
export const POST_CONTRIBUTIONS_COMMENTS_SUCCESS =
  "POST_CONTRIBUTIONS_COMMENTS_SUCCESS";
export const POST_CONTRIBUTIONS_COMMENTS_ERROR =
  "POST_CONTRIBUTIONS_COMMENTS_ERROR";

// POST CONTRIBUTIONS COMMENTS
export const GET_CONTRIBUTIONS_COMMENTS_SUCCESS =
  "GET_CONTRIBUTIONS_COMMENTS_SUCCESS";
export const GET_CONTRIBUTIONS_COMMENTS_ERROR =
  "GET_CONTRIBUTIONS_COMMENTS_ERROR";

// MAKE PAYMENT
export const INITIATE_PAYMENT_SUCCESS =
  "INITIATE_PAYMENT_SUCCESS";
export const INITIATE_PAYMENT_ERROR =
  "INITIATE_PAYMENT_ERROR";

// IMPORT USERS
export const IMPORT_USERS_SUCCESS = "IMPORT_USERS_SUCCESS";
export const IMPORT_USERS_ERROR = "IMPORT_USERS_ERROR";

/*******************************
 *     User Actions Start      *
 ******************************/

export const USERS_SESSION_USER = "USERS_SESSION_USER";
export const USERS_USER_CRUD = "USERS_USER_CRUD";

/*******************************
 *      User Actions End       *
 ******************************/

/*******************************
 *     Club Actions Start      *
 ******************************/

export const CLUBS_CRUD = "CLUBS_CRUD";
export const CLUBS_SEARCH = "CLUBS_SEARCH";
export const CLUBS_MEMBERS_CRUD = "CLUBS_MEMBERS_CRUD";
export const CLUBS_MEMBERS_SEARCH = "CLUBS_MEMBERS_SEARCH";

/*******************************
 *      Club Actions End       *
 ******************************/


/**************************
 * Activity Actions Start *
 *************************/

export const ACTIVITIES_ACTIVITY_CRUD = "ACTIVITIES_ACTIVITY_CRUD";
export const ACTIVITIES_PARTICIPANT_CRUD = "ACTIVITIES_PARTICIPANT_CREATE";

export const ACTIVITIES_PARTICIPANT_ACTION_CRUD = "ACTIVITIES_PARTICIPANT_ACTION_CRUD";
export const ACTIVITIES_PARTICIPANT_ACTION_CREATE = "ACTIVITIES_PARTICIPANT_ACTION_CREATE";
export const ACTIVITIES_PARTICIPANT_ACTION_RETRIEVE = "ACTIVITIES_PARTICIPANT_ACTION_RETRIEVE";
export const ACTIVITIES_PARTICIPANT_ACTION_UPDATE = "ACTIVITIES_PARTICIPANT_ACTION_UPDATE";
export const ACTIVITIES_PARTICIPANT_ACTION_DELETE = "ACTIVITIES_PARTICIPANT_ACTION_DELETE";
export const ACTIVITIES_PARTICIPANT_ACTION_SEARCH = "ACTIVITIES_PARTICIPANT_ACTION_SEARCH";

export const ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_CRUD = "ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_CREATE";
export const ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_SEARCH = "ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_SEARCH";

export const ACTIVITIES_CONTRIBUTIONS_SEARCH = "ACTIVITIES_CONTRIBUTIONS_SEARCH";
export const ACTIVITIES_CONTRIBUTIONS_COMMENT_SEARCH = "ACTIVITIES_CONTRIBUTIONS_COMMENT_SEARCH";

/**************************
 *  Activity Actions End  *
 *************************/

/*******************************
 * Questionnaire Actions Start *
 ******************************/

export const QUESTIONNAIRES_CREATE = "QUESTIONNAIRES_CREATE";
export const QUESTIONNAIRES_RETRIEVE = "QUESTIONNAIRES_RETRIEVE";
export const QUESTIONNAIRES_UPDATE = "QUESTIONNAIRES_UPDATE";
export const QUESTIONNAIRES_DELETE = "QUESTIONNAIRES_DELETE";
export const QUESTIONNAIRES_SEARCH = "QUESTIONNAIRES_SEARCH";

/*******************************
 *  Questionnaire Actions End  *
 ******************************/

/*******************************
 *     Course Actions Start    *
 ******************************/

export const COURSES_CREATE = "COURSES_CREATE";
export const COURSES_RETRIEVE = "COURSES_RETRIEVE";
export const COURSES_UPDATE = "COURSES_UPDATE";
export const COURSES_DELETE = "COURSES_DELETE";
export const COURSES_SEARCH = "COURSES_SEARCH";
export const COURSES_SEARCH_LESSONS = "COURSES_SEARCH_LESSONS";

/******************************
*      Course Actions End     *
******************************/
