/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment } from "react";
import moment from "moment";
import CommentForm from "../generics/CommentForm";
import ReactionsMenu from "../generics/ReactionsMenu";
import timeIcon from "../../assets/icons/time.svg";
import likingIcon from "../../assets/reactions/like.svg";
import commentIcon from "../../assets/icons/comment.svg";
import commentIconOrange from "../../assets/icons/comment-orange.svg";
import { colors } from "../../helpers/constants";

const Comments = ({
  postItem,
  commentHash,
  reactions,
  listReactions,
  openComments,
  displayComments,
  onToggleComments,
  createPost,
  clubMembersData,
  createPostReaction,
  updateSelectedReactions,
}) => {
  return (
    <div
      className="comments-sections"
      style={{
        borderTop: postItem.totalResponses <= 0 && "unset",
        paddingTop: postItem.totalResponses <= 0 && "unset",
      }}>
      <div className="comments-list">
        {postItem.responses.length > 0 &&
          postItem.responses.map((comment) => (
            <Fragment key={comment.id}>
              <div
                id={`commentId-${comment.id}`}
                className="post-comment-block">
                <div className="post-comment-item-author d-none d-lg-block d-xl-block">
                  <div className="post-avatar">
                    {comment.author.profile.picture && (
                      <img
                        className="img-fluid"
                        src={comment.author.profile.picture}
                        alt="club-avatar"
                      />
                    )}
                    {!comment.author.profile.picture && (
                      <div className="member-initials">
                        {comment.author.profile
                          .firstName[0] +
                          comment.author.profile
                            .lastName[0]}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  id={
                    `commentId-${comment.id}` ===
                    commentHash
                      ? "highlighted-comment"
                      : ""
                  }
                  className="post-comment-item">
                  <div className="activity-content">
                    <div className="post-item-author">
                      <div className="post-avatar d-lg-none d-sm-block">
                        {comment.author.profile.picture && (
                          <img
                            className="img-fluid"
                            src={
                              comment.author.profile.picture
                            }
                            alt="club-avatar"
                          />
                        )}
                        {!comment.author.profile
                          .picture && (
                          <div className="member-initials">
                            {comment.author.profile
                              .firstName[0] +
                              comment.author.profile
                                .lastName[0]}
                          </div>
                        )}
                      </div>
                      <div className="post-author-name">
                        {`${comment.author.profile.firstName} ${comment.author.profile.lastName}`}
                        <br />
                        <span className="post-item-member">
                          {`${comment.author.roles[0]
                            .slice(0, 1)
                            .toUpperCase()}${comment.author.roles[0].slice(
                            comment.author.roles[0].length -
                              (comment.author.roles[0]
                                .length -
                                1),
                          )}`}
                        </span>
                      </div>
                    </div>
                    <p className="activity-content-text pb-3">
                      {comment.content.body}
                    </p>
                  </div>
                  <div className="activity-stats">
                    <div className="activity-stat-item dropup">
                      <div
                        className="dropdown-toggle activity-stat-icon handicon"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={() =>
                          listReactions(comment.id)
                        }>
                        <img
                          src={likingIcon}
                          alt="like-icon"
                          width="25px"
                        />
                      </div>

                      {Object.values(comment.reactions)
                        .map((reaction) =>
                          parseInt(reaction.total, 10),
                        )
                        .reduce((a, b) => a + b, 0)}
                      <ReactionsMenu
                        reactions={reactions}
                        resourceItem={comment}
                        createPostReaction={
                          createPostReaction
                        }
                        updateSelectedReactions={
                          updateSelectedReactions
                        }
                      />
                    </div>
                    <div
                      className="activity-stat-item"
                      style={{
                        color:
                          displayComments.includes(
                            comment.id,
                          ) && colors.primaryColor,
                      }}
                      onClick={() =>
                        onToggleComments(comment.id)
                      }>
                      <div className="activity-stat-icon">
                        <img
                          src={
                            displayComments.includes(
                              comment.id,
                            )
                              ? commentIconOrange
                              : commentIcon
                          }
                          alt="comment-icon"
                          width="25px"
                        />
                      </div>
                      {comment.totalResponses}
                    </div>
                    <div className="activity-stat-item">
                      <div className="activity-stat-icon">
                        <img
                          src={timeIcon}
                          alt="time-icon"
                          width="25px"
                        />
                      </div>
                      {moment
                        .unix(comment.dateCreated)
                        .format("hh:mm, DD MMM YYYY") ||
                        "hh:mm, DD MMM YYYY"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="comment-replies-block">
                {(openComments ||
                  displayComments.includes(comment.id)) && (
                  <div>
                    <div className="comments-list">
                      {comment.responses.length > 0 &&
                        comment.responses.map((reply) => (
                          <>
                            <div
                              id={`commentId-${reply.id}`}
                              className="post-comment-block">
                              <div className="post-comment-item-author d-none d-lg-block d-xl-block">
                                <div className="post-avatar">
                                  {reply.author.profile
                                    .picture && (
                                    <img
                                      className="img-fluid"
                                      src={
                                        reply.author.profile
                                          .picture
                                      }
                                      alt="club-avatar"
                                    />
                                  )}
                                  {!reply.author.profile
                                    .picture && (
                                    <div className="member-initials">
                                      {reply.author.profile
                                        .firstName[0] +
                                        reply.author.profile
                                          .lastName[0]}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                id={
                                  `commentId-${reply.id}` ===
                                  commentHash
                                    ? "highlighted-comment"
                                    : ""
                                }
                                className="post-comment-item">
                                <div className="activity-content">
                                  <div className="post-item-author">
                                    <div className="post-avatar d-lg-none d-sm-block">
                                      {reply.author.profile
                                        .picture && (
                                        <img
                                          className="img-fluid"
                                          src={
                                            reply.author
                                              .profile
                                              .picture
                                          }
                                          alt="club-avatar"
                                        />
                                      )}
                                      {!reply.author.profile
                                        .picture && (
                                        <div className="member-initials">
                                          {reply.author
                                            .profile
                                            .firstName[0] +
                                            reply.author
                                              .profile
                                              .lastName[0]}
                                        </div>
                                      )}
                                    </div>
                                    <div className="post-author-name">
                                      {`${reply.author.profile.firstName} ${reply.author.profile.lastName}`}
                                      <br />
                                      <span className="post-item-member">
                                        {`${reply.author.roles[0]
                                          .slice(0, 1)
                                          .toUpperCase()}${reply.author.roles[0].slice(
                                          reply.author
                                            .roles[0]
                                            .length -
                                            (reply.author
                                              .roles[0]
                                              .length -
                                              1),
                                        )}`}
                                      </span>
                                    </div>
                                  </div>
                                  <p className="activity-content-text pb-3">
                                    {reply.content.body}
                                  </p>
                                </div>
                                <div className="activity-stats">
                                  <div className="activity-stat-item dropup">
                                    <div
                                      className="dropdown-toggle activity-stat-icon handicon"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      onClick={() =>
                                        listReactions(
                                          reply.id,
                                        )
                                      }>
                                      <img
                                        src={likingIcon}
                                        alt="like-icon"
                                        width="25px"
                                      />
                                    </div>
                                    {Object.values(
                                      reply.reactions,
                                    )
                                      .map((reaction) =>
                                        parseInt(
                                          reaction.total,
                                          10,
                                        ),
                                      )
                                      .reduce(
                                        (a, b) => a + b,
                                        0,
                                      )}
                                    <ReactionsMenu
                                      reactions={reactions}
                                      resourceItem={reply}
                                      createPostReaction={
                                        createPostReaction
                                      }
                                      updateSelectedReactions={
                                        updateSelectedReactions
                                      }
                                    />
                                  </div>
                                  <div className="activity-stat-item">
                                    <div className="activity-stat-icon">
                                      <img
                                        src={timeIcon}
                                        alt="time-icon"
                                        width="25px"
                                      />
                                    </div>
                                    {moment
                                      .unix(
                                        reply.dateCreated,
                                      )
                                      .format(
                                        "hh:mm, DD MMM YYYY",
                                      ) ||
                                      "hh:mm, DD MMM YYYY"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>

                    <div className="post-comment post-comment-reply">
                      <CommentForm
                        resource={comment}
                        createPost={createPost}
                        commentType="Reply"
                        clubMembersData={clubMembersData}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Fragment>
          ))}
      </div>
      <div className="post-comment">
        <CommentForm
          resource={postItem}
          createPost={createPost}
          commentType="Comment"
          clubMembersData={clubMembersData}
        />
      </div>
    </div>
  );
};

export default Comments;
