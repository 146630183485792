import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './library-sidebar.css';
import { FilterListOutlined, MoreHoriz } from '@material-ui/icons';
import { Checkbox, FormControl, FormControlLabel, Slider } from '@material-ui/core';
import { DATE_ADDED, FILTERS, SPECIFY_DATE_RANGE, TYPE } from '../../../../helpers/constants';
import { getLabelByName } from '../../../../helpers/helperFunctions';

class LibrarySidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderValue: 30,
      fileTypes: [
        { name: 'Images', checked: false },
        { name: 'Videos', checked: false },
        { name: 'Audio', checked: false },
        { name: 'PowerPoint', checked: false },
        { name: 'Excel', checked: false },
        { name: 'Word', checked: false },
        { name: 'PDF', checked: false }
      ],
    };
  }

  handleSliderChange = (e, newValue) => this.setState({ sliderValue: newValue });

  handleTypeFilterChange = (index) => {
    const { fileTypes } = this.state;
    const selectedType = fileTypes[index];
    selectedType.checked = !selectedType.checked;
    fileTypes.splice(index, 1, selectedType);
    this.setState({ fileTypes });
    this.handleTypeFilter();
  };

  handleTypeFilter = () => {
    const types = [];
    this.state.fileTypes.forEach((type) => {
      if (type.checked) {
        types.push(type.name);
      }
    })
    this.props.filterContributionsbyType(types);
  };
  
  render() {
    const { settings } = this.props;

    return (
      <>
        {settings.enable_filters === 'true' && (
          <div className="sidebar-item">
            <div className="sidebar-item__title">{getLabelByName(FILTERS)}</div>
            <div className="sidebar-item__action"><FilterListOutlined /></div>
          </div>
        )}
        {settings.enable_date_filter === 'true' && (
          <>
            <div className="sidebar-item">
              <div className="sidebar-item__title">{getLabelByName(DATE_ADDED)}</div>
              <div className="sidebar-item__action"><MoreHoriz /></div>
            </div><div className="date-slider">
              <Slider value={this.state.sliderValue} onChange={this.handleSliderChange} aria-labelledby="continuous-slider" />
            </div>
            <div>{getLabelByName(SPECIFY_DATE_RANGE)}</div>
          </>
        )}

        {settings.enable_type_filter === 'true' && (
          <>
            <hr style={{ width: '100%', margin: '1rem 0' }} />
            <div className="sidebar-item">
              <div className="sidebar-item__title">{getLabelByName(TYPE)}</div>
              <div className="sidebar-item__action"><MoreHoriz /></div>
            </div>
            <div>
              {this.state.fileTypes.map((fileType, index) => (
                <FormControl fullWidth component="fieldset">
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={fileType.checked}
                        onChange={() => this.handleTypeFilterChange(index)}
                        name="filetype"
                        value={fileType}
                        color="primary" />
                    )}
                    label={fileType.name} />
                </FormControl>
              ))}
            </div>
          </>)}
      </>
    );
  }
}

LibrarySidebar.propTypes = {};

export default LibrarySidebar;
