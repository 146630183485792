import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

const ConfirmationModal = (props) => {
  const { isModalOpen, closeModal, header, description, button_1_text, button_2_text, button_1_Handler, button_2_Handler } = props;

  return (
    <Modal
        size="md"
        show={isModalOpen}
        onHide={closeModal}
        className="behalf-modal">
        <div className="create-club-header">
          <div className="header-left">
            <p className="header-modal-title">{header}</p>
            <p className="header-modal-subtitle">{description}</p>
          </div>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            style={{ marginTop: "-5rem", outline: "none" }}
            onClick={closeModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="row d-flex justify-content-around create-club-buttons">
          <Link
            to='#'>
            <button type="button"
              onClick={button_1_Handler}
              className="btn btn-lg submit-btn">
              {button_1_text}
            </button>
          </Link>
          <button
            type="button"
            className="btn btn-lg submit-btn btn-reverse"
            onClick={button_2_Handler}
          >
            {button_2_text}
          </button>
        </div>
      </Modal>
  )
}

export default ConfirmationModal