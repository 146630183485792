/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment";

import {
  postReaction,
  getReactions,
  deleteReaction,
} from "../redux/actions/postActions";
import timeIcon from "../assets/icons/time.svg";
import arrowCircleIcon from "../assets/icons/arrow-circled-orange-light.svg";
import likingIcon from "../assets/reactions/like.svg";
import commentIcon from "../assets/icons/comment.svg";
import ReactionsMenu from "./generics/ReactionsMenu";
import SharePost from "./generics/ShareResource";
import {
  getAllClubsFromLocalStorage,
  getLabelByName,
  getProfileDataFromLocalStorage,
} from "../helpers/helperFunctions";
import { colors, READ_MORE } from "../helpers/constants";

const FeedArticle = (props) => {
  const [state, setState] = useState({
    listReactions: (postId) => fetchReactions(postId),
    clickedPostId: null,
    clubsData: getAllClubsFromLocalStorage(),
  });

  const reactions = {};

  useEffect(() => {
    const profileData = getProfileDataFromLocalStorage();

    const { clickedPostId } = state;
    const {
      getReactionsData,
      postReactionData,
      postReactionStatus,
    } = props;

    if (
      clickedPostId === props.postItem.id &&
      getReactionsData &&
      getReactionsData.data &&
      Object.keys(getReactionsData.data).length > 0 &&
      !reactions[clickedPostId]
    ) {
      reactions[clickedPostId] = getReactionsData.data;
    }

    if (
      postReactionStatus &&
      postReactionData &&
      postReactionData.data &&
      clickedPostId !== null
    ) {
      if (!reactions[clickedPostId]) {
        reactions[clickedPostId] = {};
      }
      if (!reactions[clickedPostId][profileData.data.id]) {
        reactions[clickedPostId][profileData.data.id] =
          postReactionData.data;
      }
    }
  }, [
    props.getReactionsData,
    props.postReactionData,
    props.postReactionStatus,
  ]);

  async function fetchReactions(postId) {
    setState((prevState) => ({
      ...prevState,
      clickedPostId: postId,
    }));

    if (!reactions[postId]) {
      await props.getReactions(postId);
    }
  }

  const createPostReaction = async (
    postId,
    reaction,
    myReaction,
  ) => {
    const profileData = getProfileDataFromLocalStorage();

    if (myReaction && myReaction === reaction) {
      await props.deleteReaction(postId, reaction);
      delete reactions[postId][profileData.data.id];
    }
    if (!myReaction) {
      await props.postReaction({}, postId, reaction);
    }
  };

  const updateSelectedReactions = (postId) => {
    props.updateSelectedReactions(reactions[postId]);
  };

  const getPostClub = () => {
    const { postItem } = props;
    const { clubsData } = state;

    const postClub =
      clubsData !== null &&
      clubsData.filter(
        (club) =>
          Number(club.id) === Number(postItem.clubId),
      )[0];
    return postClub;
  };

  const getPostLink = () => {
    const { postItem } = props;
    const link = `/club/${postItem.clubId}/${
      getPostClub()?.handle
    }/post/${postItem.id}`;
    return link;
  };

  const { postItem } = props;
  const firstName =
    postItem.author.profile?.firstName || "Unknown";
  const lastName =
    postItem.author.profile?.lastName || "User";

  return (
    <div className="card activity-card d-flex flex-row">
      {postItem.images.headerImage && (
        <Link
          to={getPostLink()}
          style={{
            textDecoration: "none",
            width: "250px",
          }}>
          <div
            style={{
              height: "200px",
              background: `url(${postItem.images.headerImage})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            }}
          />
        </Link>
      )}

      <div className="card-body d-flex">
        <div className="activity-content-row article-row">
          <div className="activity-content">
            <div className="article-title">
              <Link
                to={getPostLink()}
                style={{
                  textDecoration: "none",
                  color: "unset",
                }}>
                {postItem.content.title}
              </Link>
            </div>
            <div className="article-description">
              <Link
                to={getPostLink()}
                style={{
                  textDecoration: "none",
                  color: "unset",
                }}>
                {postItem.content.description}
              </Link>
            </div>
            <div className="article-bottom-row">
              <div className="article-author-row">
                <div className="article-author-name">
                  {`${firstName} ${lastName}`.length > 50
                    ? `${`${firstName} ${lastName}`.slice(
                        0,
                        50,
                      )}...`
                    : `${firstName} ${lastName}`}
                </div>
                <div className="article-club-name">
                  <Link
                    to={{
                      pathname: `/club/${postItem.clubId}/${
                        getPostClub()?.handle
                      }`,
                      hash: `#postid${postItem.id}`,
                    }}
                    style={{ textDecoration: "none" }}>
                    <span className="post-item-member">
                      {getPostClub()?.name?.length > 50
                        ? `${getPostClub()?.name.slice(
                            0,
                            50,
                          )}...`
                        : getPostClub()?.name}
                    </span>
                  </Link>
                </div>
              </div>
              <div className="article-see-more">
                <Link
                  to={getPostLink()}
                  style={{ textDecoration: "none" }}>
                  <div
                    className="activity-stat-item mr-0"
                    style={{
                      color: colors.primaryColor,
                    }}>
                    {getLabelByName(READ_MORE)}
                    <img
                      className="ml-2"
                      src={arrowCircleIcon}
                      alt="arrow-icon"
                      width="16px"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="activity-stats">
            <div className="d-flex flex-column align-self-center">
              <div className="activity-stat-item dropup">
                <div
                  className="dropdown-toggle activity-stat-icon handicon"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => props.handleIfVerified(()=> state.listReactions(postItem.id))}>
                  <img
                    src={likingIcon}
                    alt="like-icon"
                    width="25px"
                  />
                </div>
                {Object.values(postItem.reactions)
                  .map((reaction) =>
                    parseInt(reaction.total, 10),
                  )
                  .reduce((a, b) => a + b, 0)}
                <ReactionsMenu
                  reactions={reactions}
                  resourceItem={postItem}
                  createPostReaction={createPostReaction}
                  updateSelectedReactions={
                    updateSelectedReactions
                  }
                  position="right"
                />
              </div>
              <div className="activity-stat-item">
                <div className="activity-stat-icon">
                  <img
                    src={commentIcon}
                    alt="comment-icon"
                    width="25px"
                  />
                </div>
                {postItem.totalResponses}
              </div>
              <div className="activity-stat-item">
                <div className="activity-stat-icon">
                  <img
                    src={timeIcon}
                    alt="time-icon"
                    width="25px"
                  />
                </div>
                {moment(postItem.dateCreated * 1000).format(
                  "hh:mm, DD/MM/YY",
                ) || "hh:mm, DD/MM/YY"}
              </div>
              <SharePost
                postId={postItem.id}
                postLink={getPostLink()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FeedArticle.propTypes = {
  postItem: PropTypes.any,
  postReaction: PropTypes.func,
  deleteReaction: PropTypes.func,
  getReactions: PropTypes.func,
  updateSelectedReactions: PropTypes.func,

  postReactionData: PropTypes.object,
  postReactionStatus: PropTypes.string,

  getReactionsData: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  postReactionDataError:
    state.postReactionReducer.postReactionDataError,
  postReactionData:
    state.postReactionReducer.postReactionData,
  postReactionStatus:
    state.postReactionReducer.postReactionStatus,

  deleteReactionDataError:
    state.deleteReactionReducer.deleteReactionDataError,
  deleteReactionData:
    state.deleteReactionReducer.deleteReactionData,
  deleteReactionStatus:
    state.deleteReactionReducer.deleteReactionStatus,

  getReactionsData:
    state.getReactionsReducer.getReactionsData,
  getReactionsDataError:
    state.getReactionsReducer.getReactionsDataError,
  getReactionsStatus:
    state.getReactionsReducer.getReactionsStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    postReaction,
    deleteReaction,
    getReactions,
  }),
)(FeedArticle);
