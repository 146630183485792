import React, { useEffect, useState } from 'react';
import { withRouter, } from "react-router-dom";
import { connect } from 'react-redux';
import { compose } from 'redux';

import { createParticipantAction, retrieveParticipantAction, updateParticipantAction } from '../../../redux/actions/activities/participantActionActions';
import { searchCourseLessons } from '../../../redux/actions/courses/coursesActions';
import ConfirmationModal from '../../notifications/ConfirmationModal'

import CourseSidebar from './CourseSidebar'
import CourseMainContent from './CourseMainContent'
import './CourseConsole.scss'

const CourseConsole = (props) => {
    const { activity, action, participant, participantActions, lessons, participantAction, history, match, isPreviewMode } = props;

    const [isSidebarOpen, setIsSidebarOpen] = useState(true)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [currentLesson, setCurrentLesson] = useState({
        id: 0,
        content: {
            title: "Loading...",
            body: "Loading...",
        }
    });
    const [courseProgress, setCourseProgress] = useState({});
    const [courseCompletion, setCourseCompletion] = useState(0);



    useEffect(() => {
        if (participantActions && participantActions.hasOwnProperty('participantActions')) {
            let matchingParticipantAction = participantActions.participantActions
                .find(pa => parseInt(pa.actionId) === parseInt(action.id));

            if (matchingParticipantAction) {
                props.retrieveParticipantAction(
                    activity.id,
                    participant.id,
                    matchingParticipantAction.id
                );
            } else {
                props.createParticipantAction(
                    activity.id,
                    participant.id,
                    action.id,
                    {
                        status: "INCOMPLETE",
                        visibility: "PUBLIC",
                        meta: {},
                        data: {}
                    }
                );
            }
        }
    }, [participantActions]);

    useEffect(() => {
        props.searchCourseLessons(
            activity.parentId,
            {
                id: {
                    _in: action.lessons.map((lesson) => lesson.articleId)
                },
            }
        );
    }, []);

    useEffect(() => {
        if (lessons.length && currentLesson.id === 0) {
            setCurrentLesson(lessons[0]);
        }
    }, [lessons]);

    useEffect(() => {
        setCourseProgress(JSON.parse(participantAction.data.progress));
    }, [participantAction]);

    useEffect(() => {
        let totalLessons = 0, totalComplete = 0;
        Object.values(courseProgress).forEach((lesson) => {
            totalLessons++;
            if (lesson.status === "complete") {
                totalComplete++;
            }
            setCourseCompletion(Math.floor((totalComplete / totalLessons) * 100));
        });
    }, [courseProgress]);

    const hasNextLesson = () => {
        let lessonIndex = -1;
        lessons.find((lesson, index) => {
            if (
                lesson.id === currentLesson.id &&
                index < (lessons.length - 1)
            ) {
                lessonIndex = index;
                return true;
            }
        });
        return (lessonIndex > -1) ? true : false;
    }

    const hasPreviousLesson = () => {
        let lessonIndex;
        lessons.find((lesson, index) => {
            if (
                lesson.id === currentLesson.id &&
                index > 0
            ) {
                lessonIndex = index;
                return true;
            }
        });
        return lessonIndex ? true : false;
    }

    const getLessonIndex = () => {
        let lessonIndex;
        lessons.find((lesson, index) => {
            if (lesson.id === currentLesson.id) {
                lessonIndex = index;
                return true;
            }
        });

        return (lessonIndex > -1) ? lessonIndex : -1;
    }

    const showNextLesson = () => {
        if (hasNextLesson()) {
            setCurrentLesson(lessons[getLessonIndex() + 1]);
        } else {
            setShowConfirmationModal(true)
        }
    }

    const showPreviousLesson = () => {
        if (hasPreviousLesson()) {
            setCurrentLesson(lessons[(getLessonIndex() - 1)]);
        }
    }

    const selectLesson = (lessonId) => setCurrentLesson(lessons[lessonId])

    const updateLessonProgress = (lessonArticle, status) => {
        let lesson = action.lessons.find(l => l.articleId == lessonArticle.id);
        let courseProgress = JSON.parse(participantAction.data.progress);
        courseProgress[lesson.id].status = status;

        props.updateParticipantAction(
            activity.id,
            participant.id,
            action.id,
            {
                ...participantAction,
                ...{
                    data: {
                        ...participantAction.data,
                        progress: courseProgress
                    }
                },
            }
        );
    }

    const handleLessonComplete = (setToComplete) => {
        setToComplete ? markLessonAsComplete() : markLessonAsIncomplete();
    }

    const markLessonAsComplete = () => {
        updateLessonProgress(currentLesson, "complete")
    }

    const markLessonAsIncomplete = () => {
        updateLessonProgress(currentLesson, "incomplete");
    }

    const getSlideIsComplete = (slide) => {
        if (Object.keys(courseProgress).length === 0) {
            return false;
        }

        let lesson = action.lessons.find(l => parseInt(l.articleId) === parseInt(slide.id));
        if (!lesson) {
            return false;
        }

        if (courseProgress[lesson.id].status === "complete") {
            return true;
        } else {
            return false;
        }
    }

    if (isPreviewMode) {
        return (
            <div className='preview-lessons'>
                {lessons.map((lesson, index) => {
                    return (
                        <h4 key={lesson.id}>
                            Lesson {index + 1}: {lesson.content.title}
                        </h4>
                    )
                })}
            </div>
        )
    }

    return <>
        <section className="contribute-to-lessons">
            <section className="section-wrapper">
                <CourseSidebar
                    isOpen={isSidebarOpen}
                    selectLesson={selectLesson}
                    courseProgress={courseProgress}
                    action={action}
                    slides={lessons || []}
                    getSlideIsComplete={getSlideIsComplete}
                />
                <CourseMainContent
                    isOpen={isSidebarOpen}
                    toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
                    lessonArticle={currentLesson}
                    courseProgress={courseProgress}
                    courseCompletion={courseCompletion}
                    previousLessonHandler={showPreviousLesson}
                    nextLessonHandler={showNextLesson}
                    handleLessonCompletion={handleLessonComplete} //
                    action={action}
                />
            </section>
        </section>
        <ConfirmationModal
            isModalOpen={showConfirmationModal}
            closeModal={() => setShowConfirmationModal(false)}
            header="Congratulations!"
            description="Your have completed this course."
            button_1_text="Return to Dashboard"
            button_1_Handler={() => history.push(`/activity/${match.params.activityId}/participant/${match.params.participantId}/console`)}
            button_2_text="Continue reading"
            button_2_Handler={() => setShowConfirmationModal(false)}
        />
    </>
}

const mapStateToProps = (state) => ({
    lessons: state.courseRetrieveLessonsReducer,
    participantAction: state.participantActionsCRUDReducer,
})

export default compose(
    withRouter,
    connect(mapStateToProps, {
        searchCourseLessons,
        createParticipantAction,
        retrieveParticipantAction,
        updateParticipantAction,
    }),
)(CourseConsole);