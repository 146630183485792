/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import {
  ACTIVITY,
  ACTIVITY_DETAILS_VISIBILITY,
  ALL_USERS,
  ANONYMOUS_CONTRIBUTIONS,
  ANONYMOUS_CONTRIBUTIONS_DESCRIPTION,
  CONTRIBUTE_CALL_TO_ACTION,
  CONTRIBUTE_CALL_TO_ACTION_DESCRIPTION,
  CONTRIBUTION_CONFIRMATION_EMAIL,
  CONTRIBUTION_CONFIRMATION_EMAIL_CONTENT,
  CONTRIBUTION_CONFIRMATION_EMAIL_SENDER,
  CONTRIBUTION_CONFIRMATION_EMAIL_SUBJECT,
  CTA_TEXT,
  CUSTOMIZE_URL_HANDLE,
  DATA_EXPORT,
  DATA_EXPORT_DESCRIPTION,
  DISPLAY_CONTRIBUTIONS,
  DISPLAY_CONTRIBUTIONS_DESCRIPTION,
  DISPLAY_PARTICIPANTS,
  DISPLAY_PARTICIPANTS_DESCRIPTION,
  INFORMATION,
  INVITATION_ONLY,
  MEMBERS_ONLY,
  NOTIFICATIONS,
  PARTICIPATE_CALL_TO_ACTION,
  PARTICIPATE_CALL_TO_ACTION_DESCRIPTION,
  PRIVACY_SETTINGS,
  REVIEW_NOTIFICATION_EMAIL,
  REVIEW_NOTIFICATION_EMAIL_CONTENT,
  REVIEW_NOTIFICATION_EMAIL_SENDER,
  REVIEW_NOTIFICATION_EMAIL_SUBJECT,
  SEND_CONTRIBUTION_CONFIRMATION_EMAIL,
  SEND_PARTICIPANT_wELCOME_EMAIL,
  SEND_REVIEW_NOTIFICATION_EMAIL,
  WELCOME_EMAIL,
  wELCOME_EMAIL_CONTENT,
  wELCOME_EMAIL_SENDER,
  wELCOME_EMAIL_SUBJECT,
  WHO_CAN_CONTRIBUTE,
} from "../../../../helpers/constants";
import Switch from "../../../generics/Switch";

const visibilityOptions = [
  { value: "PUBLIC", name: getLabelByName(ALL_USERS) },
  { value: "PRIVATE", name: getLabelByName(MEMBERS_ONLY) },
  {
    value: "RESTRICTED",
    name: getLabelByName(INVITATION_ONLY),
  },
];

const statuses = [
  { index: 0, text: "Active", value: "ACTIVE" },
  { index: 1, text: "Inactive", value: "INACTIVE" },
];

export class SettingsContent extends Component {
  render() {
    const {
      category,
      activityData,
      handleChange,
      handleRadio,
      settingsChange,
      switchChange,
      notificationsChange,
      notifSwitchChange,
    } = this.props;

    return (
      <div>
        {category === "general" && (
          <>
            <div className="activity-card other-activity-information">
              <div className="activity-card__heading">
                <h5>
                  {" "}
                  {`${getLabelByName(ACTIVITY)} ${getLabelByName(INFORMATION)}`}
                </h5>
              </div>
              <div className="activity-card__main">
                <form id="form" onSubmit={() => {}}>
                  <FormControl className="inputLeft50" variant="outlined">
                    <TextField
                      id="handle"
                      label={getLabelByName(CUSTOMIZE_URL_HANDLE)}
                      name="handle"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">{`${window.location.hostname}/campaign/${activityData?.id}/`}</InputAdornment>
                        ),
                      }}
                      value={activityData?.handle}
                      onChange={handleChange}
                      required
                    />
                  </FormControl>
                  {activityData && (
                    <FormControl className="inputRight50" variant="outlined">
                      <InputLabel id="visibility">
                        {getLabelByName(WHO_CAN_CONTRIBUTE)}?
                      </InputLabel>
                      <Select
                        labelId="visibility"
                        id="visibility"
                        name="visibility"
                        required
                        value={activityData && activityData.visibility}
                        onChange={handleChange}
                        label={getLabelByName(WHO_CAN_CONTRIBUTE)}
                      >
                        {visibilityOptions.map((visibility) => {
                          return (
                            <MenuItem
                              selected={
                                activityData &&
                                activityData.visibility === visibility.value
                              }
                              value={visibility.value}
                            >
                              {visibility.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                  <FormControl
                    className="input100"
                    margin="normal"
                    component="fieldset"
                  >
                    <FormLabel component="legend">
                      Select the status of your entity, you can change this at a
                      later time
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="Activity-status"
                      name="Status"
                      onChange={handleRadio}
                    >
                      {statuses.map((statusItem) => (
                        <FormControlLabel
                          value={statusItem.value}
                          id={`check-${statusItem.value}`}
                          labelPlacement="end"
                          checked={
                            activityData &&
                            activityData.status === statusItem.value
                          }
                          control={<Radio color="primary" />}
                          label={statusItem.text}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </form>
              </div>
            </div>

            <div className="activity-card other-activity-information">
              <div className="activity-card__heading">
                <h5>{getLabelByName(PARTICIPATE_CALL_TO_ACTION)}</h5>
              </div>
              <div className="activity-card__main">
                <form id="form_participate_cta" onSubmit={() => {}}>
                  <div className="row">
                    <div className="col-md">
                      <FormControl className="w-100" variant="outlined">
                        <TextField
                          id="participate_call_to_action"
                          label={getLabelByName(CTA_TEXT)}
                          name="participate_call_to_action"
                          variant="outlined"
                          value={
                            activityData?.settings
                              ?.participate_call_to_action || ""
                          }
                          onChange={settingsChange}
                        />
                      </FormControl>
                    </div>
                    <div className="col-md">
                      {getLabelByName(PARTICIPATE_CALL_TO_ACTION_DESCRIPTION)}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="activity-card other-activity-information">
              <div className="activity-card__heading">
                <h5>{getLabelByName(CONTRIBUTE_CALL_TO_ACTION)}</h5>
              </div>
              <div className="activity-card__main">
                <form id="form" onSubmit={() => {}}>
                  <div className="row">
                    <div className="col-md">
                      <FormControl className="w-100" variant="outlined">
                        <TextField
                          id="contribute_call_to_action"
                          label={getLabelByName(CTA_TEXT)}
                          name="contribute_call_to_action"
                          variant="outlined"
                          value={
                            activityData?.settings?.contribute_call_to_action ||
                            ""
                          }
                          onChange={settingsChange}
                        />
                      </FormControl>
                    </div>
                    <div className="col-md">
                      {getLabelByName(CONTRIBUTE_CALL_TO_ACTION_DESCRIPTION)}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="activity-card other-activity-information">
              <div className="activity-card__heading">
                <h5>{getLabelByName(ACTIVITY_DETAILS_VISIBILITY)}</h5>
              </div>
              <div className="activity-card__main">
                <form id="form" onSubmit={() => {}}>
                  <FormControl className="input100">
                    <FormControlLabel
                      className="p-0 m-0"
                      name="displayParticipants"
                      control={
                        <Switch
                          name="displayParticipants"
                          checked={
                            !!activityData?.settings?.displayParticipants
                          }
                          onChange={() => switchChange("displayParticipants")}
                        />
                      }
                      label={getLabelByName(DISPLAY_PARTICIPANTS)}
                      labelPlacement="end"
                    />
                  </FormControl>
                  <div>{getLabelByName(DISPLAY_PARTICIPANTS_DESCRIPTION)}</div>
                  <FormControl className="mt-5 input100">
                    <FormControlLabel
                      className="p-0 m-0"
                      name="displayContributions"
                      control={
                        <Switch
                          name="displayContributions"
                          checked={
                            !!activityData?.settings?.displayContributions
                          }
                          onChange={() => switchChange("displayContributions")}
                        />
                      }
                      label={getLabelByName(DISPLAY_CONTRIBUTIONS)}
                      labelPlacement="end"
                    />
                  </FormControl>
                  <div>{getLabelByName(DISPLAY_CONTRIBUTIONS_DESCRIPTION)}</div>
                </form>
              </div>
            </div>
          </>
        )}

        {category === "privacy" && (
          <div className="activity-card other-activity-information">
            <div className="activity-card__heading">
              <h5>{getLabelByName(PRIVACY_SETTINGS)}</h5>
            </div>
            <div className="activity-card__main">
              <form id="form" onSubmit={() => {}}>
                <FormControl className="input100">
                  <FormControlLabel
                    className="p-0 m-0"
                    name="anonymous"
                    control={
                      <Switch
                        name="anonymous"
                        checked={!!activityData?.settings?.anonymous}
                        onChange={() => switchChange("anonymous")}
                      />
                    }
                    label={getLabelByName(ANONYMOUS_CONTRIBUTIONS)}
                    labelPlacement="end"
                  />
                </FormControl>
                <div>{getLabelByName(ANONYMOUS_CONTRIBUTIONS_DESCRIPTION)}</div>

                <FormControl className="mt-5 input100">
                  <FormControlLabel
                    className="p-0 m-0"
                    name="data_export"
                    control={
                      <Switch
                        name="data_export"
                        checked={!!activityData?.settings?.data_export}
                        onChange={() => switchChange("data_export")}
                      />
                    }
                    label={getLabelByName(DATA_EXPORT)}
                    labelPlacement="end"
                  />
                </FormControl>
                <div>{getLabelByName(DATA_EXPORT_DESCRIPTION)}</div>
              </form>
            </div>
          </div>
        )}

        {category === "notifications" && (
          <>
            <div className="activity-card other-activity-information">
              <div className="d-flex justify-content-between align-items-center activity-card__heading">
                <div>
                  <h5>{getLabelByName(SEND_PARTICIPANT_wELCOME_EMAIL)}</h5>
                </div>
                <div>
                  <FormControl className="input100">
                    <FormControlLabel
                      className="p-0 m-0"
                      name="send_participant_welcome_email"
                      control={
                        <Switch
                          name="send_participant_welcome_email"
                          checked={
                            !!activityData?.settings?.notifications
                              ?.welcome_email?.send_participant_welcome_email
                          }
                          onChange={(e) =>
                            notifSwitchChange(
                              e,
                              "welcome_email",
                              "send_participant_welcome_email"
                            )
                          }
                        />
                      }
                      label=""
                      labelPlacement="end"
                    />
                  </FormControl>
                </div>
              </div>
              <div className="activity-card__main">
                <form id="form" onSubmit={() => {}}>
                  <FormControl className="inputLeft50" variant="outlined">
                    <TextField
                      id="welcome_email_sender"
                      label={getLabelByName(wELCOME_EMAIL_SENDER)}
                      name="welcome_email_sender"
                      variant="outlined"
                      value={
                        activityData?.settings?.notifications?.welcome_email
                          ?.welcome_email_sender || ""
                      }
                      onChange={(e) =>
                        notificationsChange(
                          e.target.value,
                          "welcome_email",
                          "welcome_email_sender"
                        )
                      }
                    />
                  </FormControl>
                  <FormControl className="inputRight50" variant="outlined">
                    <TextField
                      id="welcome_email_subject"
                      label={getLabelByName(wELCOME_EMAIL_SUBJECT)}
                      name="welcome_email_subject"
                      variant="outlined"
                      value={
                        activityData?.settings?.notifications?.welcome_email
                          ?.welcome_email_subject || ""
                      }
                      onChange={(e) =>
                        notificationsChange(
                          e.target.value,
                          "welcome_email",
                          "welcome_email_subject"
                        )
                      }
                    />
                  </FormControl>
                  <FormControl className="mt-3 input100" variant="outlined">
                    <TextField
                      multiline
                      rows={10}
                      id="welcome_email_content"
                      label={getLabelByName(wELCOME_EMAIL_CONTENT)}
                      name="welcome_email_content"
                      variant="outlined"
                      value={
                        activityData?.settings?.notifications?.welcome_email
                          ?.welcome_email_content || ""
                      }
                      onChange={(e) =>
                        notificationsChange(
                          e.target.value,
                          "welcome_email",
                          "welcome_email_content"
                        )
                      }
                    />
                  </FormControl>
                </form>
              </div>
            </div>

            <div className="activity-card other-activity-information">
              <div className="d-flex justify-content-between align-items-center activity-card__heading">
                <div>
                  <h5>
                    {getLabelByName(SEND_CONTRIBUTION_CONFIRMATION_EMAIL)}
                  </h5>
                </div>
                <div>
                  <FormControl className="input100">
                    <FormControlLabel
                      className="p-0 m-0"
                      name="send_contribution_confirmation_email"
                      control={
                        <Switch
                          name="send_contribution_confirmation_email"
                          checked={
                            !!activityData?.settings?.notifications
                              ?.contribution_confirmation_email
                              ?.send_contribution_confirmation_email
                          }
                          onChange={(e) =>
                            notifSwitchChange(
                              e,
                              "contribution_confirmation_email",
                              "send_contribution_confirmation_email"
                            )
                          }
                        />
                      }
                      label=""
                      labelPlacement="end"
                    />
                  </FormControl>
                </div>
              </div>
              <div className="activity-card__main">
                <form id="form" onSubmit={() => {}}>
                  <FormControl className="inputLeft50" variant="outlined">
                    <TextField
                      id="contribution_confirmation_email_sender"
                      label={getLabelByName(
                        CONTRIBUTION_CONFIRMATION_EMAIL_SENDER
                      )}
                      name="contribution_confirmation_email_sender"
                      variant="outlined"
                      value={
                        activityData?.settings?.notifications
                          ?.contribution_confirmation_email
                          ?.contribution_confirmation_email_sender || ""
                      }
                      onChange={(e) =>
                        notificationsChange(
                          e.target.value,
                          "contribution_confirmation_email",
                          "contribution_confirmation_email_sender"
                        )
                      }
                    />
                  </FormControl>
                  <FormControl className="inputRight50" variant="outlined">
                    <TextField
                      id="contribution_confirmation_email_subject"
                      label={getLabelByName(
                        CONTRIBUTION_CONFIRMATION_EMAIL_SUBJECT
                      )}
                      name="contribution_confirmation_email_subject"
                      variant="outlined"
                      value={
                        activityData?.settings?.notifications
                          ?.contribution_confirmation_email
                          ?.contribution_confirmation_email_subject || ""
                      }
                      onChange={(e) =>
                        notificationsChange(
                          e.target.value,
                          "contribution_confirmation_email",
                          "contribution_confirmation_email_subject"
                        )
                      }
                    />
                  </FormControl>
                  <FormControl className="mt-3 input100" variant="outlined">
                    <TextField
                      multiline
                      rows={10}
                      id="contribution_confirmation_email_content"
                      label={getLabelByName(
                        CONTRIBUTION_CONFIRMATION_EMAIL_CONTENT
                      )}
                      name="contribution_confirmation_email_content"
                      variant="outlined"
                      value={
                        activityData?.settings?.notifications
                          ?.contribution_confirmation_email
                          ?.contribution_confirmation_email_content || ""
                      }
                      onChange={(e) =>
                        notificationsChange(
                          e.target.value,
                          "contribution_confirmation_email",
                          "contribution_confirmation_email_content"
                        )
                      }
                    />
                  </FormControl>
                </form>
              </div>
            </div>

            <div className="activity-card other-activity-information">
              <div className="d-flex justify-content-between align-items-center activity-card__heading">
                <div>
                  <h5>{getLabelByName(SEND_REVIEW_NOTIFICATION_EMAIL)}</h5>
                </div>
                <div>
                  <FormControl className="input100">
                    <FormControlLabel
                      className="p-0 m-0"
                      name="send_review_notification_email"
                      control={
                        <Switch
                          name="send_review_notification_email"
                          checked={
                            !!activityData?.settings?.notifications
                              ?.review_notification_email
                              ?.send_review_notification_email
                          }
                          onChange={(e) =>
                            notifSwitchChange(
                              e,
                              "review_notification_email",
                              "send_review_notification_email"
                            )
                          }
                        />
                      }
                      label=""
                      labelPlacement="end"
                    />
                  </FormControl>
                </div>
              </div>
              <div className="activity-card__main">
                <form id="form" onSubmit={() => {}}>
                  <FormControl className="inputLeft50" variant="outlined">
                    <TextField
                      id="review_notification_email_sender"
                      label={getLabelByName(REVIEW_NOTIFICATION_EMAIL_SENDER)}
                      name="review_notification_email_sender"
                      variant="outlined"
                      value={
                        activityData?.settings?.notifications
                          ?.review_notification_email
                          ?.review_notification_email_sender || ""
                      }
                      onChange={(e) =>
                        notificationsChange(
                          e.target.value,
                          "review_notification_email",
                          "review_notification_email_sender"
                        )
                      }
                    />
                  </FormControl>
                  <FormControl className="inputRight50" variant="outlined">
                    <TextField
                      id="review_notification_email_subject"
                      label={getLabelByName(REVIEW_NOTIFICATION_EMAIL_SUBJECT)}
                      name="review_notification_email_subject"
                      variant="outlined"
                      value={
                        activityData?.settings?.notifications
                          ?.review_notification_email
                          ?.review_notification_email_subject || ""
                      }
                      onChange={(e) =>
                        notificationsChange(
                          e.target.value,
                          "review_notification_email",
                          "review_notification_email_subject"
                        )
                      }
                    />
                  </FormControl>
                  <FormControl className="mt-3 input100" variant="outlined">
                    <TextField
                      multiline
                      rows={10}
                      id="review_notification_email_content"
                      label={getLabelByName(REVIEW_NOTIFICATION_EMAIL_CONTENT)}
                      name="review_notification_email_content"
                      variant="outlined"
                      value={
                        activityData?.settings?.notifications
                          ?.review_notification_email
                          ?.review_notification_email_content || ""
                      }
                      onChange={(e) =>
                        notificationsChange(
                          e.target.value,
                          "review_notification_email",
                          "review_notification_email_content"
                        )
                      }
                    />
                  </FormControl>
                </form>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default SettingsContent;
