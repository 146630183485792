import {
  GET_CLUB_CAMPAIGNS_ERROR, GET_CLUB_CAMPAIGNS_SUCCESS,
} from '../actions/types';

const initialState = {
  clubCampaignsData: null,
  clubCampaignsDataError: null,
  clubCampaignsStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CLUB_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        clubCampaignsData: action.payload,
        clubCampaignsDataError: null,
        clubCampaignsStatus: 'success',
      };
    case GET_CLUB_CAMPAIGNS_ERROR:
      return {
        ...state,
        clubCampaignsDataError: action.payload,
        clubCampaignsData: null,
        clubCampaignsStatus: 'error',
      };
    default:
      return {
        ...state,
        clubCampaignsStatus: null,
      };
  }
};
