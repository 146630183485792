/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { FiberManualRecord } from "@material-ui/icons";
import { Link, withRouter } from "react-router-dom";
import campaignIcon from "../../assets/manage-campaigns.svg";
import clubIcon from "../../assets/manage-clubs.svg";
import clubIconOrange from "../../assets/icons/club-orange-light.svg";
import campaignIconOrange from "../../assets/icons/campaign-light-orange.svg";
import feedIconOrange from "../../assets/icons/feed-orange-light.svg";
import feedIcon from "../../assets/icons/feed.svg";
import logoutIcon from "../../assets/icons/logout.svg";
import settingsIcon from "../../assets/icons/settings.svg";
import notificationsIcon from "../../assets/icons/notifications-black.svg";

import {
  clearLocalStorage,
  getLabelByName,
  getAllActivitiesFromLocalStorage,
  getAllClubsFromLocalStorage,
} from "../../helpers/helperFunctions";
import {
  ABOUT_US,
  LOGOUT,
  SUPPORT,
  MOST_ACTIVE_CAMPAIGNS,
  ADVERTISING,
  POPULAR_CLUBS,
  CLUBS_RECENT_ACTIVITY,
  NOTIFICATION_SETTINGS,
  TERMS,
  COOKIES,
  MY_PLURAL,
  CLUB,
  ALL_ACTIVITY,
  MY_CLUBS_ACTIVITY,
  ACCOUNT,
  MY_SINGULAR,
  ACTIVITIES,
  colors,
} from "../../helpers/constants";
import NotificationSettingsModal from "./NotificationSettingsModal";

class SideNav extends Component {
  state = {
    feedType: "all",
    allActivities: getAllActivitiesFromLocalStorage(),
    userClubsData: getAllClubsFromLocalStorage(),
    isNotificationSettingsModalOpen: false,
  };

  toggleFeed = (feedType) => {
    this.setState({ feedType });
    this.props.toggleFeed(feedType);
  };

  toggleNotificationSettingsModal = () =>
    this.setState((prevState) => ({
      isNotificationSettingsModalOpen:
        !prevState.isNotificationSettingsModalOpen,
    }));

  onUpdate = () => {
    const { profileData } = this.props;

    this.setState({
      profileData,
    });
  };

  componentDidUpdate(prevProps) {
    const { updateLinkItemCount } = this.props;

    if (
      updateLinkItemCount !== prevProps.updateLinkItemCount
    ) {
      this.onUpdate();
    }
  }

  render() {
    const { landingData, match } = this.props;
    const { isNotificationSettingsModalOpen } = this.state;

    return (
      <div className="container-fluid">
        <div className="nav-section">
          {(match.path === "/home" ||
            match.path === "/club/:id/:handle") && (
            <>
              <div
                className={`nav-link side-nav-item ${
                  this.state.feedType === "all"
                    ? "side-nav-item__active"
                    : ""
                }`}
                onClick={() => this.toggleFeed("all")}>
                <img
                  src={
                    this.state.feedType === "all"
                      ? feedIconOrange
                      : feedIcon
                  }
                  alt="feed-icon"
                />
                {getLabelByName(ALL_ACTIVITY)}
              </div>
              <div
                className={`nav-link side-nav-item ${
                  this.state.feedType === "personal"
                    ? "side-nav-item__active"
                    : ""
                }`}
                onClick={() => this.toggleFeed("personal")}>
                <img
                  src={
                    this.state.feedType === "personal"
                      ? clubIconOrange
                      : clubIcon
                  }
                  alt="feed-icon"
                  width="18px"
                />
                {getLabelByName(MY_CLUBS_ACTIVITY)}
              </div>

              <div
                className={`nav-link side-nav-item ${
                  this.state.feedType === "my-clubs"
                    ? "side-nav-item__active"
                    : ""
                }`}
                onClick={() => this.toggleFeed("my-clubs")}>
                <img
                  src={
                    this.state.feedType === "my-clubs"
                      ? clubIconOrange
                      : clubIcon
                  }
                  alt="feed-icon"
                  width="18px"
                />
                {`${getLabelByName(
                  MY_PLURAL,
                )} ${getLabelByName(CLUB)}s `}
                ({this.getMyClubsLength()})
              </div>

              <div
                className={`nav-link side-nav-item ${
                  this.state.feedType === "my-activities"
                    ? "side-nav-item__active"
                    : ""
                }`}
                onClick={() =>
                  this.toggleFeed("my-activities")
                }>
                <img
                  src={
                    this.state.feedType === "my-activities"
                      ? campaignIconOrange
                      : campaignIcon
                  }
                  alt="feed-icon"
                  width="18px"
                />
                {`${getLabelByName(
                  MY_PLURAL,
                )} ${getLabelByName(ACTIVITIES)} `}
                ({this.getMyActivitiesLength()})
              </div>

              <div
                className={`nav-link side-nav-item ${
                  this.state.feedType ===
                  "most-active-activities"
                    ? "side-nav-item__active"
                    : ""
                }`}
                onClick={() => {
                  this.toggleFeed("most-active-activities");
                }}>
                <img
                  src={
                    this.state.feedType ===
                    "most-active-activities"
                      ? campaignIconOrange
                      : campaignIcon
                  }
                  alt="nav-icon"
                  width="18px"
                />
                {getLabelByName(MOST_ACTIVE_CAMPAIGNS)}
              </div>

              <div
                className={`nav-link side-nav-item ${
                  this.state.feedType ===
                  "most-popular-clubs"
                    ? "side-nav-item__active"
                    : ""
                }`}
                onClick={() => {
                  this.toggleFeed("most-popular-clubs");
                }}>
                <img
                  src={
                    this.state.feedType ===
                    "most-popular-clubs"
                      ? clubIconOrange
                      : clubIcon
                  }
                  alt="nav-icon"
                />
                {getLabelByName(POPULAR_CLUBS)}
              </div>

              <div
                className={`nav-link side-nav-item ${
                  this.state.feedType ===
                  "clubs-with-recent-activity"
                    ? "side-nav-item__active"
                    : ""
                }`}
                onClick={() => {
                  this.toggleFeed(
                    "clubs-with-recent-activity",
                  );
                }}>
                <img
                  src={
                    this.state.feedType ===
                    "clubs-with-recent-activity"
                      ? clubIconOrange
                      : clubIcon
                  }
                  alt="nav-icon"
                />
                {getLabelByName(CLUBS_RECENT_ACTIVITY)}
              </div>
              <hr />
            </>
          )}
          <Link
            className="nav-link"
            to="/profile"
            style={{ color: colors.textPrimary }}>
            <div className="side-nav-item">
              <img src={settingsIcon} alt="nav-icon" />
              {`${getLabelByName(
                MY_SINGULAR,
              )} ${getLabelByName(ACCOUNT)}`}
            </div>
          </Link>
          <Link
            className="nav-link"
            to="#"
            onClick={(e) => {
              e.preventDefault();

              this.toggleNotificationSettingsModal();
            }}
            style={{ color: colors.textPrimary }}>
            <div className="side-nav-item">
              <img src={notificationsIcon} alt="nav-icon" />
              {`${getLabelByName(NOTIFICATION_SETTINGS)}`}
            </div>
          </Link>
          <div
            className="nav-link side-nav-item"
            onClick={() => this.logout()}>
            <img src={logoutIcon} alt="nav-icon" />
            {getLabelByName(LOGOUT)}
          </div>
        </div>

        <div className="bottom-section">
          {!this.props.mobile && (
            <>
              <hr />
              <div className="side-nav-footer">
                <div className="side-nav-footer-row">
                  <Link
                    className="side-nav-footer-link"
                    to="/">
                    <div className="side-nav-item">
                      {getLabelByName(ABOUT_US)}
                    </div>
                  </Link>
                  <FiberManualRecord
                    style={{ fontSize: "4px" }}
                  />
                  <Link
                    className="side-nav-footer-link"
                    to="/privacy">
                    <div className="side-nav-item">
                      Privacy
                    </div>
                  </Link>
                  <FiberManualRecord
                    style={{ fontSize: "4px" }}
                  />
                  <Link
                    className="side-nav-footer-link"
                    to="/terms">
                    <div className="side-nav-item">
                      {getLabelByName(TERMS)}
                    </div>
                  </Link>
                  <FiberManualRecord
                    style={{ fontSize: "4px" }}
                  />
                  <Link
                    className="side-nav-footer-link"
                    to="/">
                    <div className="side-nav-item">
                      {getLabelByName(SUPPORT)}
                    </div>
                  </Link>
                </div>
                <div className="side-nav-footer-row">
                  <Link
                    className="side-nav-footer-link"
                    to="/">
                    <div className="side-nav-item">
                      {getLabelByName(COOKIES)}
                    </div>
                  </Link>
                  <FiberManualRecord
                    style={{ fontSize: "4px" }}
                  />
                  <Link
                    className="side-nav-footer-link"
                    to="/">
                    <div className="side-nav-item">
                      {getLabelByName(ADVERTISING)}
                    </div>
                  </Link>
                  <FiberManualRecord
                    style={{ fontSize: "4px" }}
                  />
                  <Link
                    className="side-nav-footer-link"
                    to="/">
                    <div className="side-nav-item side-nav-copyright">
                      {
                        this.getLandingInfo(landingData)[
                          "copyright-text"
                        ]
                      }
                    </div>
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>

        {isNotificationSettingsModalOpen ? (
          <NotificationSettingsModal
            isOpen={isNotificationSettingsModalOpen}
            closeModal={
              this.toggleNotificationSettingsModal
            }
          />
        ) : null}
      </div>
    );
  }

  getLandingInfo = (landingData) => {
    let landingInfo = {};
    if (landingData && landingData.data) {
      landingInfo = landingData.data.filter(
        (x) => x.name === "landing-page",
      )[0].data;
    }

    return landingInfo;
  };

  getLinksInfo = (landingData) => {
    let linksInfo = {};
    if (landingData && landingData.data) {
      linksInfo = landingData.data.filter(
        (x) => x.name === "links",
      )[0].data;
    }

    return linksInfo;
  };

  logout = () => {
    clearLocalStorage();
    // window.location.href = "/";

    this.props.history.push("/");
  };

  getMyClubsLength = () => {
    const { userClubsData } = this.state;
    const { profileData } = this.props;

    if (!Array.isArray(userClubsData)) {
      return 0;
    }
    const activeClubs =
      userClubsData?.filter(
        (club) => club.status === "ACTIVE",
      ) || [];

    const profileClubsArray = profileData?.data?.clubs
      ? Object.values(profileData?.data?.clubs)
      : [];

    const myClubs = [];

    activeClubs.forEach((club) => {
      profileClubsArray.forEach((profileClub) => {
        if (profileClub.clubId === club.id) {
          myClubs.push(club);
        }
      });
    });

    return myClubs.length;
  };

  getMyActivitiesLength = () => {
    const { allActivities } = this.state;
    const { profileData } = this.props;

    let activeActivities = [];

    if (allActivities.length) {
      activeActivities =
        allActivities?.filter(
          (activity) => activity.status === "ACTIVE",
        ) || [];
    }

    const myProfileDataActivities = profileData?.data
      ?.activities
      ? Object.values(profileData.data.activities)
      : [];

    const myActivities = [];

    activeActivities.forEach((activeActivity) => {
      myProfileDataActivities.forEach(
        (profileDataActivity) => {
          if (
            activeActivity.id ===
            profileDataActivity.activityId
          ) {
            myActivities.push(activeActivity);
          }
        },
      );
    });

    return myActivities.length;
  };
}

SideNav.propTypes = {
  landingData: PropTypes.any,
  toggleFeed: PropTypes.func,
  location: PropTypes.shape({ pathname: PropTypes.string })
    .isRequired,
  updateLinkItemCount: PropTypes.string,
  mobile: PropTypes.bool,
};

export const mapStateToProps = (state) => ({
  profileData: state.authReducer.data,
  landingData: state.landingReducer.landingData,
});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(SideNav);
