/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
import {
  ArrowBackIos,
  ArrowForwardIos,
  Clear,
  FiberManualRecord,
} from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import Navbar from "../../Navbar";
import searchOrange from "../../../assets/icons/search-orange.svg";
import Footer from "../../Footer";
import ResourceTable from "../../generics/ResourceTable";
import { getOutputs } from "../../../redux/actions/contributionsActions";
import { getSessionAction } from "../../../redux/actions/authActions";
import {
  checkSession,
  getLabelByName,
  getProfileDataFromLocalStorage,
  isActionSuccess,
} from "../../../helpers/helperFunctions";
import {
  BACK,
  CHANGES_SAVED,
  colors,
  CREATE,
  CREATED_ON,
  CREATED_SUCCESSFULLY,
  LAST,
  NAME,
  NEXT,
  OF_WORD,
  OUTPUT,
  OUTPUTS,
  outputTypes,
  PREVIOUS,
  SHOWING,
  STATUS,
  TYPE,
  UPDATED,
  visibilityOptions,
} from "../../../helpers/constants";
import cloneIcon from "../../../assets/icons/clone.svg";
import { getInstitute } from "../../../redux/actions/institutesActions";
import editIcon from "../../../assets/icons/edit-dark-orange.svg";
import deleteIcon from "../../../assets/icons/delete-dark-orange.svg";
import { getClubActivities } from "../../../redux/actions/campaignsActions";
import CreateClubOutput from "./ClubOutputs/CreateClubOutput/CreateClubOutput";
import OutputWizard from "./ClubOutputs/OutputWizard";

class ClubOutputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileData: getProfileDataFromLocalStorage(),
      pageTitle: getLabelByName(OUTPUTS),
      primaryCallToAction: `${getLabelByName(
        CREATE,
      )} ${getLabelByName(OUTPUT)}`,
      isLoading: false,
      nbPages: 1,
      searchValue: "",
      displaySearch: false,
      currentPage: 1,
      pageDivider: 10,
      pathname: this.props.location.pathname,
      clubName: null,
      createOutputModal: false,
      editOutputModal: false,
      isCloneOutputModalOpen: false,
      outputStatuses: {
        ACTIVE: "Active",
        INACTIVE: "Inactive",
      },
      statusColor: {
        ACTIVE: "#10A728",
        INACTIVE: "#FF0D0D",
      },
      editOutput: null,
      cloneOutput: null,
      clearModal: false,
    };
  }

  getTableHeaders() {
    return {
      id: "ID",
      name: getLabelByName(NAME),
      type: getLabelByName(TYPE),
      status: getLabelByName(STATUS),
      dateCreated: getLabelByName(CREATED_ON),
      lastUpdated: `${getLabelByName(
        LAST,
      )} ${getLabelByName(UPDATED)}`,
      clone: "",
      edit: "",
      delete: "",
    };
  }

  getTableData() {
    if (!this.feedDataHasLoaded()) {
      return [];
    }

    const {
      currentPage,
      pageDivider,
      outputStatuses,
      statusColor,
    } = this.state;

    return this.props.getOutputsData.data
      .slice(
        (currentPage - 1) * pageDivider,
        currentPage * pageDivider,
      )
      .map((output) => {
        const outputStatus = (
          <span>
            <FiberManualRecord
              style={{
                color: statusColor[output.status],
                width: "15px",
                marginRight: "0.5rem",
              }}
            />
            {outputStatuses[output.status]}
          </span>
        );

        const renderCloneButton = (output) => (
          <span
            className="club-icon"
            data-toggle="modal"
            onClick={() => {
              this.setState({
                cloneOutput: output,
                isCloneOutputModalOpen: true,
              });
            }}
            style={{ cursor: "pointer" }}>
            <img
              src={cloneIcon}
              alt="clone-action"
              width="24px"
            />
          </span>
        );

        const renderEditButton = () => (
          <span
            className="club-icon"
            data-toggle={`${
              output.type !== "Default"
                ? "modal"
                : undefined
            }`}
            data-target={`${
              output.type !== "Default"
                ? ".create-output-modal"
                : undefined
            }`}
            onClick={() =>
              this.setState({
                editOutput: output,
                editOutputModal: true,
              })
            }
            style={{ cursor: "pointer" }}>
            <img
              src={editIcon}
              alt="edit-action"
              width="24px"
            />
          </span>
        );

        const renderDeleteButton = () => (
          <span
            className="club-icon"
            data-toggle="modal"
            style={{ cursor: "pointer" }}>
            <img
              src={deleteIcon}
              alt="edit-action"
              width="24px"
            />
          </span>
        );

        return {
          id: output.id,
          name: output.name,
          type: outputTypes[output.type],
          status: outputStatus,
          dateCreated: this.timestampToDate(
            output.dateCreated,
          ),
          lastUpdated: output.lastUpdated
            ? this.timestampToDate(output.lastUpdated)
            : "N/A",
          clone: renderCloneButton(output),
          edit: renderEditButton(),
          delete: renderDeleteButton(),
        };
      });
  }

  feedDataHasLoaded() {
    return !!(
      this.props.getOutputsData &&
      this.props.getOutputsData.data
    );
  }

  timestampToDate(
    timestamp,
    format = "DD MMM YYYY, hh:mm",
  ) {
    if (!timestamp) {
      return null;
    }
    return moment(timestamp * 1000).format(format);
  }

  getPageRange() {
    const { currentPage, pageDivider } = this.state;
    const first = (currentPage - 1) * pageDivider + 1;
    let last = first + (pageDivider - 1);
    if (
      this.feedDataHasLoaded() &&
      last > this.props.getOutputsData.data.length
    ) {
      last = this.props.getOutputsData.data.length;
    }
    return { first, last };
  }

  getLastListItem() {
    return 10;
  }

  getTotalEntitites() {
    if (!this.feedDataHasLoaded()) {
      return 0;
    }
    return this.props.getOutputsData.data.length;
  }

  incrementPage = () => {
    let { nbPages, currentPage } = this.state;
    currentPage =
      currentPage === nbPages
        ? currentPage
        : currentPage + 1;
    this.setState({ currentPage });
  };

  decrementPage = () => {
    let { currentPage } = this.state;
    currentPage =
      currentPage === 1 ? currentPage : currentPage - 1;
    this.setState({ currentPage });
  };

  handleCloseOutputModal = () => {
    this.setState({
      createOutputModal: false,
      editOutputModal: false,
      editOutput: null,
      cloneOutput: null,
      isCloneOutputModalOpen: false,
    });
  };

  render() {
    document.title = this.state.pageTitle;
    document.title = `${
      process.env.REACT_APP_APPLICATION_NAME ||
      "The Alma Maters Club"
    } - ${this.state.pageTitle}`;
    const { id, handle } = this.props.match.params;

    return (
      <>
        <Navbar pathname="profile" />
        <div className="manage-clubs-page">
          <div className="container-fluid">
            <div className="buttons-row">
              <div id="back-link">
                <div className="d-flex justify-content-center align-items-center">
                  <Link
                    to={`/manage/clubs/${id}/${handle}`}
                    style={{
                      color: colors.textPrimary,
                      font: "Book 20px/17px Avenir",
                    }}>
                    <ArrowBackIos className="arrow-back-icon" />
                    {`  ${getLabelByName(BACK)}`}
                  </Link>
                </div>
              </div>

              <div className="join-button">
                <button
                  type="submit"
                  className="btn btn-lg submit-btn"
                  disabled={this.state.isLoading}
                  onClick={() =>
                    this.setState({
                      createOutputModal: true,
                      editOutput: null,
                    })
                  }>
                  {this.state.primaryCallToAction}
                </button>
              </div>
            </div>

            <div className="manage-clubs-content">
              <div className="clubs-details">
                <div className="clubs-title">
                  {this.state.clubName &&
                    `${this.state.clubName}: `}
                  {this.state.pageTitle}
                </div>

                <div className="clubs-stats">
                  <div className="clubs-page-indicator">
                    {getLabelByName(SHOWING)}
                    <span className="font-weight-bold pl-1 pr-1">
                      {this.getPageRange().first}-
                      {this.getPageRange().last}
                    </span>
                    {`  ${getLabelByName(OF_WORD)}  `}
                    <span className="font-weight-bold pl-1 pr-1">
                      {this.getTotalEntitites()}
                    </span>
                    {this.state.pageTitle}
                  </div>

                  <div className="search-clubs">
                    <form
                      className="form"
                      onSubmit={this.searchCampaigns}>
                      <div className="input-group mb-2">
                        <input
                          type="text"
                          className="form-control"
                          id="inputSearch"
                          placeholder={`Search ${this.state.pageTitle}`}
                          name="searchValue"
                          value={this.state.searchValue}
                        />
                        {this.state.displaySearch && (
                          <div className="input-group-append">
                            <button
                              id="clear-search-btn"
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={() =>
                                this.clearSearch()
                              }>
                              <Clear />
                            </button>
                          </div>
                        )}

                        <div className="input-group-append">
                          <button
                            id="search-btn"
                            className="btn btn-outline-secondary"
                            type="submit">
                            <img
                              src={searchOrange}
                              alt="search-icon"
                              style={{ width: "20px" }}
                            />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="clubs-table table-responsive">
                <ResourceTable
                  headers={this.getTableHeaders()}
                  tableData={this.getTableData()}
                />

                <table className="table table-hover" />

                <div className="navigation-buttons">
                  <button
                    type="button"
                    className="btn btn-sm prev-btn"
                    disabled={this.state.currentPage === 1}
                    onClick={() => this.decrementPage()}>
                    <ArrowBackIos className="pagination-icon" />
                    {getLabelByName(PREVIOUS)}
                  </button>

                  <button
                    type="button"
                    className="btn btn-sm next-btn"
                    disabled={
                      this.state.currentPage ===
                      this.state.nbPages
                    }
                    onClick={() => this.incrementPage()}>
                    {getLabelByName(NEXT)}
                    <ArrowForwardIos className="pagination-icon pl-1" />
                  </button>

                  <div className="page-indicator">
                    Page{" "}
                    <span className="current-page">
                      {this.state.currentPage}
                    </span>
                    {" / "}
                    {this.state.nbPages}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Create/Edit/Clone Output Modal */}
        {this.state.editOutputModal ? (
          <OutputWizard
            open={this.state.editOutputModal}
            outputData={{
              ...this.state.editOutput,
              isEdit: true,
            }}
            handleClose={this.handleCloseOutputModal}
            title="Configure an Output"
            description="Follow the steps to edit an output for your club"
          />
        ) : null}

        {this.state.cloneOutput && (
          <OutputWizard
            open={this.state.cloneOutput}
            outputData={{
              ...this.state.cloneOutput,
              isClone: true,
            }}
            handleClose={this.handleCloseOutputModal}
            title="Clone an Output"
            description="Follow the steps to clone an output for your club"
          />
        )}

        {this.state.createOutputModal && (
          <OutputWizard
            open={this.state.createOutputModal}
            handleClose={this.handleCloseOutputModal}
            title="Configure an Output"
            description="Follow the steps to create a new output for your club"
          />
        )}

        <div className="footer-block">
          <div className="footer-component">
            <Footer />
          </div>
        </div>
      </>
    );
  }

  async UNSAFE_componentWillMount() {
    const { props, state } = this;
    const { profileData, pathname } = state;
    const { id: clubId, handle } = props.match.params;
    if (!profileData || profileData.status !== 200) {
      window.location.href = `/login?redirect_to=${pathname}`;
    }
    await this.props.getSessionAction();
    await props.getInstitute(handle);
    await props.getOutputs(`?q={"clubId":${clubId}}`);
    await props.getClubActivities(clubId);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { pageDivider, pathname } = this.state;
    const { getOutputsData, data, status } = nextProps;

    checkSession(data, status, pathname, this.props);

    if (getOutputsData && getOutputsData.data) {
      this.setState({
        nbPages: Math.ceil(
          getOutputsData.data.length / pageDivider,
        ),
      });
    }

    if (
      isActionSuccess(
        nextProps,
        "instituteStatus",
        "instituteData",
      )
    ) {
      this.setState({
        clubName: nextProps.instituteData.data?.name,
      });
    }

    const { props } = this;

    if (
      props.updateOutputStatus !==
        nextProps.updateOutputStatus &&
      nextProps.updateOutputStatus === "success"
    ) {
      const { id: clubId } = props.match.params;

      await props.getOutputs(`?q={"clubId":${clubId}}`);

      toast.success(getLabelByName(CHANGES_SAVED));
    }

    if (
      props.createOutputStatus !==
        nextProps.createOutputStatus &&
      nextProps.createOutputStatus === "success"
    ) {
      const { id: clubId } = props.match.params;

      await props.getOutputs(`?q={"clubId":${clubId}}`);

      this.setState({ clearModal: true });

      toast.success(
        `${getLabelByName(OUTPUT)} ${getLabelByName(
          CREATED_SUCCESSFULLY,
        )}`,
      );
    }
  }
}

ClubOutputs.propTypes = {
  getOutputs: PropTypes.func,
  getSessionAction: PropTypes.func,
  getInstitute: PropTypes.func,
  getClubActivities: PropTypes.func,
  data: PropTypes.any,
  status: PropTypes.any,
  match: PropTypes.any,
  location: PropTypes.any,
  getOutputsData: PropTypes.any,
  instituteData: PropTypes.any,
};

const mapStateToProps = (state) => ({
  getOutputsDataError:
    state.getOutputsReducer.getOutputsDataError,
  getOutputsData: state.getOutputsReducer.getOutputsData,
  getOutputsStatus:
    state.getOutputsReducer.getOutputsStatus,

  instituteDataError:
    state.instituteReducer.instituteDataError,
  instituteData: state.instituteReducer.instituteData,
  instituteStatus: state.instituteReducer.instituteStatus,

  clubCampaignsDataError:
    state.clubCampaignsReducer.clubCampaignsDataError,
  clubCampaignsData:
    state.clubCampaignsReducer.clubCampaignsData,
  clubCampaignsStatus:
    state.clubCampaignsReducer.clubCampaignsStatus,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
  updateOutputStatus:
    state.updateOutputReducer.updateOutputStatus,
  createOutputStatus:
    state.createOutputReducer.createOutputStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getSessionAction,
    getOutputs,
    getInstitute,
    getClubActivities,
  }),
)(ClubOutputs);
