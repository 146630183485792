import {
  GET_POSTS_SUCCESS, GET_POSTS_ERROR,
} from '../actions/types';

const initialState = {
  getPostsData: null,
  getPostsDataError: null,
  getPostsStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_POSTS_SUCCESS('post'):
      return {
        ...state,
        getPostsData: action.payload,
        getPostsDataError: null,
        getPostsStatus: 'success',
      };
    case GET_POSTS_ERROR('post'):
      return {
        ...state,
        getPostsDataError: action.payload,
        getPostsData: null,
        getPostsStatus: 'error',
      };
    default:
      return {
        ...state,
        getPostsStatus: null,
      };
  }
};
