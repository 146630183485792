/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  EXPLORE,
  NO_ACTIVITIES_PARTICIPATION,
} from "../../helpers/constants";
import {
  getAllActivitiesFromLocalStorage,
  getLabelByName,
  getProfileDataFromLocalStorage,
} from "../../helpers/helperFunctions";
import UserVerificationModal from "../generics/UserVerificationModal";
import ActivityCard from "./ActivityCard";

class MyActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignsData: getAllActivitiesFromLocalStorage(),
      profileData: getProfileDataFromLocalStorage(),
      isUserVerificationModalOpen: false,
    };
  }

  toggleUserVerificationModal = () =>
    this.setState({
      isUserVerificationModalOpen:
        !this.state.isUserVerificationModalOpen,
    });

  render() {
    const myActivities = this.getMyActivities();

    const { isUserVerificationModalOpen } = this.state;

    return (
      <>
        <div className="list-items">
          {myActivities.length > 0 ? (
            myActivities.map((item) => (
              <ActivityCard
                key={item.id}
                item={item}
                toggleUserVerificationModal={
                  this.toggleUserVerificationModal
                }
                userActivities={this.state.profileData.data.activities}
              />
            ))
          ) : (
            <div>
              <div className="mb-3">
                {getLabelByName(
                  NO_ACTIVITIES_PARTICIPATION,
                )}
              </div>
              <div>
                <button
                  type="submit"
                  className="btn btn-lg submit-btn">
                  <Link
                    to="/explore"
                    style={{ color: "unset" }}>
                    {getLabelByName(EXPLORE)}
                  </Link>
                </button>
              </div>
            </div>
          )}
        </div>

        {isUserVerificationModalOpen ? (
          <UserVerificationModal
            isModalOpen={isUserVerificationModalOpen}
            toggleModal={this.toggleUserVerificationModal}
          />
        ) : null}
      </>
    );
  }

  getMyActivities = () => {
    const { campaignsData, profileData } = this.state;
    const myActivitiesIds = Object.keys(
      profileData.data.activities,
    );
    const myActivities = campaignsData.filter((activity) =>
      myActivitiesIds.includes(activity.id),
    );

    const myActiveActivities = myActivities.filter(
      (activity) => activity.status === "ACTIVE",
    );

    return myActiveActivities;
  };
}

export default MyActivities;
