import {
  GET_PARTICIPANT_ACTIONS_SUCCESS,
  GET_PARTICIPANT_ACTIONS_ERROR,
  GET_PARTICIPANT_ACTIONS_LOADING,
} from '../actions/types';

const initialState = {
  getParticipantActionsData: null,
  getParticipantActionsDataError: null,
  getParticipantActionsStatus: null,
  getParticipantActionsLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTICIPANT_ACTIONS_SUCCESS:
      return {
        ...state,
        getParticipantActionsData: action.payload,
        getParticipantActionsDataError: null,
        getParticipantActionsStatus: 'success',
        getParticipantActionsLoading: false,
      };
    case GET_PARTICIPANT_ACTIONS_ERROR:
      return {
        ...state,
        getParticipantActionsDataError: action.payload,
        getParticipantActionsData: null,
        getParticipantActionsStatus: 'error',
        getParticipantActionsLoading: false,
      };
    case GET_PARTICIPANT_ACTIONS_LOADING:
      return {
        ...state,
        getParticipantActionsDataError: null,
        getParticipantActionsData: null,
        getParticipantActionsStatus: 'loading',
        getParticipantActionsLoading: true,
      };
    default:
      return {
        ...state,
        getParticipantActionsStatus: null,
      };
  }
};
