import React from "react";

const DropDownInput = (props) => {
    const { settings, currentValue, participantAction, handleUpdate, isPreviewMode } = props;

    const handleChange = (e) => {
        if (settings.options.includes(e.target.value)) {
            handleUpdate({
                [settings.input_key]: e.target.value
            });
        }
    }

    const getSettingOrDefault = (property, defaultValue) => {
        if (settings.hasOwnProperty(property)) {
            return settings[property];
        } else {
            return defaultValue;
        }
    }

    return (<>
        <p>{settings.label}</p>
        <div
        id="welcome-header"
        className="card section-card">
        <div className="card-body">
          <div className="card-details">
            <h5 className="card-title">{settings.label}{settings.required ? ' (required)' : null}</h5>
            <div className="form-group">
              <div className="handle-card-input">
                <select
                    defaultValue={(currentValue || getSettingOrDefault('default', ''))}
                    className="form-control"
                    disabled={isPreviewMode}
                    onChange={handleChange}>
                    <option key="__">Select an option</option>
                    {settings.options.map((option, index) => {
                        return <option
                            key={index}
                            value={option}>
                            {option}
                        </option>;
                    })}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>)
}

export default DropDownInput;