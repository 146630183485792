import {
  CLONE_ACTIVITY_ERROR, CLONE_ACTIVITY_SUCCESS,
} from '../actions/types';

const initialState = {
  cloneActivityData: null,
  cloneActivityDataError: null,
  cloneActivityStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLONE_ACTIVITY_SUCCESS:
      return {
        ...state,
        cloneActivityData: action.payload,
        cloneActivityDataError: null,
        cloneActivityStatus: 'success',
      };
    case CLONE_ACTIVITY_ERROR:
      return {
        ...state,
        cloneActivityDataError: action.payload,
        cloneActivityData: null,
        cloneActivityStatus: 'error',
      };
    default:
      return {
        ...state,
        cloneActivityStatus: null,
      };
  }
};
