import {
  CREATE_QUESTIONNAIRE_ERROR,
  CREATE_QUESTIONNAIRE_SUCCESS,
  CREATE_QUESTION_ERROR,
  CREATE_QUESTION_SUCCESS,
  SITE_CONTENT_ERROR,
  SITE_CONTENT_SUCCESS,
  UPDATE_QUESTIONNAIRE_ERROR,
  UPDATE_QUESTIONNAIRE_SUCCESS,
  DELETE_QUESTION_ERROR,
  DELETE_QUESTION_SUCCESS,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_ERROR,
  CLONE_QUESTIONNAIRE_ERROR,
  CLONE_QUESTIONNAIRE_SUCCESS,
} from "./types";
import apiCall from "../../helpers/apiCall";
import { getHeaders } from "../../helpers/getHeaders";

const adminType = (type, payload) => ({
  type,
  payload,
});

export const postContent =
  (
    payload,
    id,
    headers = getHeaders(
      payload,
      `/utils/value-object/${id}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .put(`/utils/value-object/${id}`, payload, {
        headers,
      })
      .then((response) => {
        dispatch(
          adminType(SITE_CONTENT_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(adminType(SITE_CONTENT_ERROR, err));
      });
  };

export const createQuestionnaire =
  (
    clubId,
    payload,
    headers = getHeaders(
      payload,
      `/club/${clubId}/questionnaire`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .post(`/club/${clubId}/questionnaire`, payload, {
        headers,
      })
      .then((response) => {
        dispatch(
          adminType(
            CREATE_QUESTIONNAIRE_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          adminType(CREATE_QUESTIONNAIRE_ERROR, err),
        );
      });
  };

export const updateQuestionnaire =
  (
    clubId,
    questionnaireId,
    payload,
    headers = getHeaders(
      payload,
      `/club/${clubId}/questionnaire/${questionnaireId}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .put(
        `/club/${clubId}/questionnaire/${questionnaireId}`,
        payload,
        { headers },
      )
      .then((response) => {
        dispatch(
          adminType(
            UPDATE_QUESTIONNAIRE_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          adminType(UPDATE_QUESTIONNAIRE_ERROR, err),
        );
      });
  };

export const cloneQuestionnaire =
  (
    clubId,
    questionnaireId,
    payload,
    headers = getHeaders(
      payload,
      `/clone/club/${clubId}/questionnaire/${questionnaireId}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .post(
        `/clone/club/${clubId}/questionnaire/${questionnaireId}`,
        payload,
        {
          headers,
        },
      )
      .then((response) => {
        dispatch(
          adminType(
            CLONE_QUESTIONNAIRE_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(adminType(CLONE_QUESTIONNAIRE_ERROR, err));
      });
  };

export const createQuestion =
  (
    clubId,
    questionnaireId,
    payload,
    headers = getHeaders(
      payload,
      `/club/${clubId}/questionnaire/${questionnaireId}/question`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .post(
        `/club/${clubId}/questionnaire/${questionnaireId}/question`,
        payload,
        { headers },
      )
      .then((response) => {
        dispatch(
          adminType(CREATE_QUESTION_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(adminType(CREATE_QUESTION_ERROR, err));
      });
  };

export const updateQuestion =
  (
    clubId,
    questionnaireId,
    questionId,
    payload,
    headers = getHeaders(
      payload,
      `/club/${clubId}/questionnaire/${questionnaireId}/question/${questionId}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .put(
        `/club/${clubId}/questionnaire/${questionnaireId}/question/${questionId}`,
        payload,
        { headers },
      )
      .then((response) => {
        dispatch(
          adminType(UPDATE_QUESTION_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(adminType(UPDATE_QUESTION_ERROR, err));
      });
  };

export const deleteQuestion =
  (
    clubId,
    questionnaireId,
    questionId,
    payload,
    headers = getHeaders(
      null,
      `/club/${clubId}/questionnaire/${questionnaireId}/question/${questionId}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .delete(
        `/club/${clubId}/questionnaire/${questionnaireId}/question/${questionId}`,
        { headers },
      )
      .then((response) => {
        dispatch(
          adminType(DELETE_QUESTION_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(adminType(DELETE_QUESTION_ERROR, err));
      });
  };
