import React from 'react';

const Loading = () => {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col d-flex justify-content-center">
            <div
              className="spinner-border text-warning"
              role="status"
              style={{
                margin: '20rem 0',

                height: '50px',

                width: '50px',
              }}>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
