import "./footer.scss";
import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ContentCard from "../ContentCard/ContentCard";
import { webContentSection } from "../../../../helpers/constants";

export class Footer extends Component {
  state = {};

  setValue = (section, landingInfo) => {
    const { stateValues, triggerCheckbox } = this.props;

    if (section === webContentSection.footerText) {
      return !stateValues[webContentSection.footerText] &&
        stateValues[webContentSection.footerText] !== null
        ? landingInfo &&
            landingInfo.data[webContentSection.footerText]
        : stateValues[webContentSection.footerText];
    }
    if (section === webContentSection.footerButton) {
      return !stateValues[webContentSection.footerButton] &&
        stateValues[webContentSection.footerButton] !== null
        ? landingInfo &&
            landingInfo.data[webContentSection.footerButton]
        : stateValues[webContentSection.footerButton];
    }
    if (section === webContentSection.footerLink) {
      return !stateValues[webContentSection.footerLink] &&
        stateValues[webContentSection.footerLink] !== null
        ? landingInfo &&
            landingInfo.data[webContentSection.footerLink]
        : stateValues[webContentSection.footerLink];
    }
    if (section === webContentSection.copyrightText) {
      return !stateValues[
        webContentSection.copyrightText
      ] &&
        stateValues[webContentSection.copyrightText] !==
          null
        ? landingInfo &&
            landingInfo.data[
              webContentSection.copyrightText
            ]
        : stateValues[webContentSection.copyrightText];
    }
    if (section === webContentSection.displayEmail) {
      return stateValues[webContentSection.displayEmail] ===
        false && triggerCheckbox === false
        ? landingInfo &&
            landingInfo.data[webContentSection.displayEmail]
        : stateValues[webContentSection.displayEmail];
    }
    if (section === webContentSection.displayHome) {
      return stateValues[webContentSection.displayHome] ===
        false && triggerCheckbox === false
        ? landingInfo &&
            landingInfo.data[webContentSection.displayHome]
        : stateValues[webContentSection.displayHome];
    }
    if (
      section === webContentSection.displayPrivacyPolicy
    ) {
      return stateValues[
        webContentSection.displayPrivacyPolicy
      ] === false && triggerCheckbox === false
        ? landingInfo &&
            landingInfo.data[
              webContentSection.displayPrivacyPolicy
            ]
        : stateValues[
            webContentSection.displayPrivacyPolicy
          ];
    }
    if (section === webContentSection.displaySupport) {
      return stateValues[
        webContentSection.displaySupport
      ] === false && triggerCheckbox === false
        ? landingInfo &&
            landingInfo.data[
              webContentSection.displaySupport
            ]
        : stateValues[webContentSection.displaySupport];
    }
    if (
      section === webContentSection.displayTermsConditions
    ) {
      return stateValues[
        webContentSection.displayTermsConditions
      ] === false && triggerCheckbox === false
        ? landingInfo &&
            landingInfo.data[
              webContentSection.displayTermsConditions
            ]
        : stateValues[
            webContentSection.displayTermsConditions
          ];
    }
    if (section === webContentSection.displaySubscription) {
      return stateValues[
        webContentSection.displaySubscription
      ] === false && triggerCheckbox === false
        ? landingInfo &&
            landingInfo.data[
              webContentSection.displaySubscription
            ]
        : stateValues[
            webContentSection.displaySubscription
          ];
    }
    if (section === webContentSection.displayInstagram) {
      return stateValues[
        webContentSection.displayInstagram
      ] === false && triggerCheckbox === false
        ? landingInfo &&
            landingInfo.data[
              webContentSection.displayInstagram
            ]
        : stateValues[webContentSection.displayInstagram];
    }
    if (section === webContentSection.displayLinkedIn) {
      return stateValues[
        webContentSection.displayLinkedIn
      ] === false && triggerCheckbox === false
        ? landingInfo &&
            landingInfo.data[
              webContentSection.displayLinkedIn
            ]
        : stateValues[webContentSection.displayLinkedIn];
    }
    if (section === webContentSection.displayFacebook) {
      return stateValues[
        webContentSection.displayFacebook
      ] === false && triggerCheckbox === false
        ? landingInfo &&
            landingInfo.data[
              webContentSection.displayFacebook
            ]
        : stateValues[webContentSection.displayFacebook];
    }
    if (section === webContentSection.displayTwitter) {
      return stateValues[
        webContentSection.displayTwitter
      ] === false && triggerCheckbox === false
        ? landingInfo &&
            landingInfo.data[
              webContentSection.displayTwitter
            ]
        : stateValues[webContentSection.displayTwitter];
    }
    return "";
  };

  render() {
    const {
      handleChange,
      section,
      setSection,
      landingInfo,
      socialInfo,
    } = this.props;

    return (
      <div>
        <ContentCard
          header="Footer Text"
          oneInputField
          title="Ealing or Message"
          handleChange={handleChange}
          contentSection={webContentSection.footerText}
          section={section}
          setSection={setSection}
          landingInfo={landingInfo}
          value={this.setValue(
            webContentSection.footerText,
            landingInfo,
          )}
        />
        <ContentCard
          header="Linked Pages"
          checkboxes
          titleParagraph="Select the pages you want to users to access from the footer section of your page"
          handleChange={handleChange}
          contentSection={webContentSection.footerText}
          section={section}
          setSection={setSection}
          landingInfo={landingInfo}
          displayHomeValue={this.setValue(
            webContentSection.displayHome,
            landingInfo,
          )}
          displayPrivacyPolicyValue={this.setValue(
            webContentSection.displayPrivacyPolicy,
            landingInfo,
          )}
          displaySubscriptionValue={this.setValue(
            webContentSection.displaySubscription,
            landingInfo,
          )}
          displaySupportValue={this.setValue(
            webContentSection.displaySupport,
            landingInfo,
          )}
          displayTermsConditionsValue={this.setValue(
            webContentSection.displayTermsConditions,
            landingInfo,
          )}
          displayInstagramValue={this.setValue(
            webContentSection.displayInstagram,
            socialInfo,
          )}
          displayEmailValue={this.setValue(
            webContentSection.displayEmail,
            socialInfo,
          )}
          displayLinkedInValue={this.setValue(
            webContentSection.displayLinkedIn,
            socialInfo,
          )}
          displayFacebookValue={this.setValue(
            webContentSection.displayFacebook,
            socialInfo,
          )}
          displayTwitterValue={this.setValue(
            webContentSection.displayTwitter,
            socialInfo,
          )}
        />
        <ContentCard
          header="Footer Button"
          FieldAndDropdown
          title="Button Text"
          title2="Select route"
          title2Exp="people will be directed to the selected page when the button is clicked"
          handleChange={handleChange}
          contentSection={webContentSection.footerButton}
          contentSection2={webContentSection.footerLink}
          section={section}
          setSection={setSection}
          landingInfo={landingInfo}
          value={this.setValue(
            webContentSection.footerButton,
            landingInfo,
          )}
          value2={this.setValue(
            webContentSection.footerLink,
            landingInfo,
          )}
        />
        <ContentCard
          header="Copyright Statement"
          oneInputField
          title="Text"
          handleChange={handleChange}
          contentSection={webContentSection.copyrightText}
          section={section}
          setSection={setSection}
          landingInfo={landingInfo}
          value={this.setValue(
            webContentSection.copyrightText,
            landingInfo,
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(Footer);
