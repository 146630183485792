import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Add } from "@material-ui/icons";
import {
  ACTION,
  ADD,
  HOW,
  NO_MORE_ACTIVITY_INFORMATION,
  PARTICIPATE,
  STEP,
  TO_WORD,
} from "../../../../helpers/constants";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import "./activityMoreInformation.css";

export class ActivityMoreInformation extends Component {
  UNSAFE_componentWillMount() {
    const {
      activityData,
      getParticipants: getParticipantsAction,
      getInstitute: getInstituteAction,
    } = this.props;
    if (activityData?.parentId) {
      getInstituteAction(activityData.parentId);
    }
    if (activityData?.id) {
      getParticipantsAction(activityData.id);
    }
  }
// ??

  render() {
    const { clubData, activityData, match, handleEditAction } = this.props;
    return (
      <div className="activityReadMore">
        <div className="headerTitle">
          <h1>
            {`${getLabelByName(HOW)} ${getLabelByName(
              TO_WORD
            )} ${getLabelByName(PARTICIPATE).toLowerCase()}`}
          </h1>
          {match.path === "/manage/activities/:id/:handle" && (
            <Add
              titleAccess={`${getLabelByName(ADD)} ${getLabelByName(ACTION)}`}
              style={{
                color: "#C6A177",
                cursor: "pointer",
                fontSize: "2rem",
              }}
              data-toggle="modal"
              data-target=".create-action-modal"
              onClick={() => handleEditAction(null)}
            />
          )}
        </div>
        <div className="body">
          {(activityData?.actions?.length > 0 && (
            <>
              <p>
                {activityData?.profile?.howToParticipateText &&
                  activityData.profile.howToParticipateText}
              </p>
              <Grid container className="steps">
                {match.path === "/manage/activities/:id/:handle"
                  ? activityData?.actions?.map((action, index) => (
                      <>
                        <Grid
                          item
                          sm={3}
                          xs={12}
                          className="step"
                          key={index}
                          data-toggle="modal"
                          data-target=".create-action-modal"
                          onClick={() => handleEditAction(action)}
                          style={{ cursor: "pointer" }}
                        >
                          <h1>{`${getLabelByName(STEP)} ${index + 1}`}</h1>
                          <p>{action.name}</p>
                        </Grid>
                      </>
                    ))
                  : activityData?.actions?.map(
                      (action, index) =>
                        action.status === "ACTIVE" && (
                          <>
                            <Grid
                              item
                              sm={3}
                              xs={12}
                              className="step"
                              key={index}
                            >
                              <h1>{`${getLabelByName(STEP)} ${index + 1}`}</h1>
                              <p>{action.name}</p>
                            </Grid>
                          </>
                        )
                    )}
              </Grid>
            </>
          )) || <p>{getLabelByName(NO_MORE_ACTIVITY_INFORMATION)}</p>}{" "}
        </div>
      </div>
    );
  }
}

ActivityMoreInformation.propTypes = {
  getParticipants: PropTypes.func,
  getInstitute: PropTypes.func,
  activityData: PropTypes.object,
  clubData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  clubData: state.instituteReducer.instituteData,
});

const mapDispatchToProps = {};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ActivityMoreInformation);
