import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../../helpers/constants';

const OrangeSwitch = withStyles({
  switchBase: {
    color: '#FFFFFF',
    '&$checked': {
      color: colors.primaryColor,
    },
    '&$checked + $track': {
      backgroundColor: colors.primaryColor,
    },
  },
  checked: {},
  track: {},
})(Switch);

export default OrangeSwitch;
