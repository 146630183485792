import "./clubQuestionnaires.scss";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  ArrowBackIos as ArrowBackIosIcon,
  EditOutlined as EditOutlineIcon,
} from "@material-ui/icons";
import moment from "moment";
import Navbar from "../../../Navbar";
import {
  checkSession,
  getLabelByName,
} from "../../../../helpers/helperFunctions";
import {
  ADD,
  CREATED,
  LAST_UPDATED,
  NEW,
  QUESTIONNAIRE,
  QUESTIONNAIRES,
  SEARCH,
} from "../../../../helpers/constants";
import Loader from "../../../Loader";
import SearchBar from "../../../generics/SearchBar/SearchBar";
import { getClubQuestionnaires } from "../../../../redux/actions/campaignsActions";
import Loading from "../../../generics/Loading/Loading";
import ViewContributionModal from "../../../generics/Contribution/ViewContributionModal";
import { getSessionAction } from "../../../../redux/actions/authActions";

export class ClubQuestionnaires extends Component {
  state = {
    isAdding: false,
    triggerPagination: false,
    searchedQuestionnaires: null,
    displayedQuestionnaires: null,
    viewQuestionnaireModal: false,
    pathname: this.props.location.pathname,
  };

  UNSAFE_componentWillMount() {
    const {
      match: {
        params: { id: clubId },
      },
      getClubQuestionnaires: getClubQuestionnairesAction,
      getSessionAction: getSession,
    } = this.props;

    getSession();
    getClubQuestionnairesAction(clubId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, status } = nextProps;
    checkSession(data, status, this.state.pathname, nextProps);

    this.setState({
      displayedQuestionnaires: nextProps.getQuestionnairesData?.data,
    });
  }

  render() {
    const {
      isAdding,
      triggerPagination,
      searchedQuestionnaires,
      displayedQuestionnaires,
      viewQuestionnaireModal,
      viewQuestionnaire,
    } = this.state;

    const {
      history,
      match: {
        params: { id: clubId, handle },
      },
    } = this.props;

    if (!displayedQuestionnaires) {
      return <Loading />;
    }

    return (
      <>
        <Navbar pathname="manage" />
        <div className="admin-club-questionnaires">
          <div className="title">
            <div className="questionnaire-name">
              <button
                type="button"
                style={{
                  background: "none",
                  border: "none",
                  outline: "none",
                  marginTop: "-8px",
                  marginLeft: "-5px",
                }}
              >
                <Link
                  to={`/manage/clubs/${clubId}/${handle}`}
                  style={{
                    outline: "none",
                    color: "#424242",
                  }}
                >
                  <ArrowBackIosIcon />
                </Link>
              </button>
              <h1 style={{ paddingLeft: "10px" }}>
                {getLabelByName(QUESTIONNAIRES)}
              </h1>
            </div>
            <div className="edit-button bigger-screens">
              <button
                onClick={() => {
                  history.push("/manage/questionnaires/new", {
                    clubId,
                    handle,
                  });
                }}
                type="button"
                className="btn btn-lg submit-btn"
                data-toggle="modal"
                data-target=".create-club-modal"
              >
                {isAdding && (
                  <>
                    <span>
                      {`${getLabelByName(ADD)} ${getLabelByName(
                        NEW
                      ).toLowerCase()}`}
                    </span>
                    <Loader />
                  </>
                )}
                {!isAdding &&
                  `${getLabelByName(ADD)} ${getLabelByName(NEW).toLowerCase()}`}
              </button>
            </div>
          </div>
          <div className="club-questionnaire-header">
            <div className="questionnaires-count bigger-screens">
              {(!triggerPagination && searchedQuestionnaires && (
                <p>{`${searchedQuestionnaires.length} ${
                  (searchedQuestionnaires.length > 1 &&
                    getLabelByName(QUESTIONNAIRES)) ||
                  getLabelByName(QUESTIONNAIRE)
                }`}</p>
              )) ||
                (displayedQuestionnaires && (
                  <p>{`${displayedQuestionnaires.length} ${
                    (displayedQuestionnaires.length > 1 &&
                      getLabelByName(QUESTIONNAIRES)) ||
                    getLabelByName(QUESTIONNAIRE)
                  }`}</p>
                ))}
            </div>
            <div className="search-questionnaire">
              <SearchBar
                setSearchedItems={(searchedItems) =>
                  this.setState({
                    searchedQuestionnaires: searchedItems,
                  })
                }
                triggerPagination
                setTriggerPagination={() =>
                  this.setState({
                    triggerPagination: false,
                  })
                }
                displayedItems={displayedQuestionnaires}
                setDisplayedItems={(displayedItems) => {
                  this.setState({
                    displayedQuestionnaires: displayedItems,
                  });
                }}
                placeholderText={`${getLabelByName(SEARCH)} ${getLabelByName(
                  QUESTIONNAIRE
                ).toLowerCase()}`}
              />
            </div>
            <div className="questionnaires-count small-screens">
              {(!triggerPagination && searchedQuestionnaires && (
                <p>{`${searchedQuestionnaires.length} ${
                  (searchedQuestionnaires.length > 1 &&
                    getLabelByName(QUESTIONNAIRES)) ||
                  getLabelByName(QUESTIONNAIRE)
                }`}</p>
              )) ||
                (displayedQuestionnaires && (
                  <p>{`${displayedQuestionnaires.length} ${
                    (displayedQuestionnaires.length > 1 &&
                      getLabelByName(QUESTIONNAIRES)) ||
                    getLabelByName(QUESTIONNAIRE)
                  }`}</p>
                ))}
              <div className="edit-button">
                <button
                  type="button"
                  onClick={() => {
                    history.push("/manage-questionnaires/new", {
                      clubId,
                      handle,
                    });
                  }}
                  className="btn btn-lg submit-btn"
                  data-toggle="modal"
                  data-target=".create-club-modal"
                >
                  {isAdding && (
                    <>
                      <span>
                        {`${getLabelByName(ADD)} ${getLabelByName(
                          NEW
                        ).toLowerCase()}`}
                      </span>
                      <Loader />
                    </>
                  )}
                  {!isAdding &&
                    `${getLabelByName(ADD)} ${getLabelByName(
                      NEW
                    ).toLowerCase()}`}
                </button>
              </div>
            </div>
          </div>
          <div className="admin-club-questionnaires-section">
            {(!triggerPagination &&
              searchedQuestionnaires &&
              searchedQuestionnaires.map((questionnaire) => (
                <div className="admin-member-card">
                  <div className="member-card-inside-content">
                    <div
                      className="admin-member-details"
                      aria-hidden
                      onClick={() => {
                        this.setState({
                          viewQuestionnaireModal: true,
                          viewQuestionnaire: questionnaire,
                        });
                      }}
                    >
                      <h1>{questionnaire.name}</h1>
                      <p>
                        {`${getLabelByName(CREATED)}: ${moment(
                          questionnaire?.dateCreated * 1000
                        ).format("DD MMM YYYY hh:mm")}`}
                      </p>
                      <p>
                        {getLabelByName(LAST_UPDATED)}:{" "}
                        {(questionnaire?.lastUpdated &&
                          moment(questionnaire?.lastUpdated * 1000).format(
                            "DD MMM YYYY hh:mm"
                          )) ||
                          "N/A"}
                      </p>
                    </div>
                    <div className="edit-questionnaire">
                      <button
                        type="button"
                        onClick={() => {
                          history.push(
                            `/manage/questionnaires/club/${questionnaire.parentId}/questionnaire/${questionnaire.id}/edit`,
                            {
                              clubId,
                              handle,
                              isClubFromQuestionnaire: true,
                            }
                          );
                        }}
                      >
                        <EditOutlineIcon />
                      </button>
                    </div>
                  </div>
                </div>
              ))) ||
              (displayedQuestionnaires &&
                displayedQuestionnaires.map((questionnaire) => (
                  <div className="admin-member-card">
                    <div className="member-card-inside-content">
                      <div
                        className="admin-member-details"
                        aria-hidden
                        onClick={() => {
                          this.setState({
                            viewQuestionnaireModal: true,
                            viewQuestionnaire: questionnaire,
                          });
                        }}
                      >
                        <h1>{questionnaire.name}</h1>
                        <p>
                          {`${getLabelByName(CREATED)}: ${moment(
                            questionnaire?.dateCreated * 1000
                          ).format("DD MMM YYYY hh:mm")}`}
                        </p>
                        <p>
                          {getLabelByName(LAST_UPDATED)}:{" "}
                          {(questionnaire?.lastUpdated &&
                            moment(questionnaire?.lastUpdated * 1000).format(
                              "DD MMM YYYY hh:mm"
                            )) ||
                            "N/A"}
                        </p>
                      </div>
                      <div className="edit-questionnaire">
                        <button
                          type="button"
                          onClick={() => {
                            history.push(
                              `/manage/questionnaires/club/${questionnaire.parentId}/questionnaire/${questionnaire.id}/edit`,
                              {
                                clubId,
                                handle,
                                isClubFromQuestionnaire: true,
                              }
                            );
                          }}
                        >
                          <EditOutlineIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                )))}
          </div>
        </div>
        {viewQuestionnaireModal && (
          <ViewContributionModal
            isQuestionnaire
            questionnaire={viewQuestionnaire}
            clubId={clubId}
            isModalOpen={viewQuestionnaireModal}
            closeModal={() =>
              this.setState({
                viewQuestionnaireModal: false,
              })
            }
          />
        )}
      </>
    );
  }
}

ClubQuestionnaires.propTypes = {
  getClubQuestionnaires: PropTypes.func,
  getQuestionnairesData: PropTypes.array,
};

const mapStateToProps = (state) => ({
  getQuestionnairesData: state.getQuestionnairesReducer.getQuestionnairesData,
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
});

const mapDispatchToProps = {
  getClubQuestionnaires,
  getSessionAction,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ClubQuestionnaires);
