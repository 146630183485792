import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import DedicatedPage from "../../generics/DedicatedPage/DedicatedPage";
import { getOutputs } from "../../../redux/actions/contributionsActions";
import { getInstitute } from "../../../redux/actions/institutesActions";
import OutputCard from "../../OutputCard";
import { CLUB_OUTPUTS, OUTPUTS } from "../../../helpers/constants";
import { checkSession, getLabelByName } from "../../../helpers/helperFunctions";
import { getSessionAction } from "../../../redux/actions/authActions";

class ClubOutputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: this.props.location.pathname,
    };
  }

  UNSAFE_componentWillMount = async () => {
    const { props } = this;
    const { id: clubId, handle } = props.match.params;
    await props.getSessionAction();
    await props.getInstitute(handle);
    await props.getOutputs(`?q={"clubId":${clubId}}`);
  };

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, status } = nextProps;
    checkSession(data, status, this.state.pathname, nextProps);
  }

  getOutputsCards = () => {
    let outputsCards = [];

    if (this.props.getOutputsData) {
      outputsCards = this.props.getOutputsData?.data?.map((output, index) => (
        <OutputCard key={index} output={output} />
      ));
    }

    return outputsCards;
  };

  getPageTitle = () => {
    let title = getLabelByName(CLUB_OUTPUTS);
    if (this.props.instituteData?.data?.name) {
      title = `${this.props.instituteData?.data?.name} - ${getLabelByName(
        OUTPUTS
      )}`;
    }

    return title;
  };

  render() {
    return (
      <DedicatedPage
        pageTitle={this.getPageTitle()}
        resource={getLabelByName(OUTPUTS)}
        resourceData={this.getOutputsCards()}
        match={this.props.match}
      />
    );
  }
}

ClubOutputs.propTypes = {
  getOutputsData: PropTypes.any,
  getOutputs: PropTypes.func,
  getInstitute: PropTypes.func,
  instituteData: PropTypes.any,
};

const mapStateToProps = (state) => ({
  getOutputsData: state.getOutputsReducer.getOutputsData,
  instituteData: state.instituteReducer.instituteData,
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { getOutputs, getInstitute, getSessionAction })
)(ClubOutputs);
