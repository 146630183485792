import React from "react";
import { ReactComponent as ExportMailIcon } from "../../../../../assets/export-mail.svg";

const SuccessMessage = () => {
  return (
    <div className="success-message">
      <div className="max-width-wrapper">
        <div className="mb-3">
          <ExportMailIcon />
        </div>

        <p>
          Your export request is successful! You will
          receive an email with the exported activity data
          once the export is completed.
        </p>
      </div>
    </div>
  );
};

export default SuccessMessage;
