/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { toast } from "react-toastify";
import {
  ArrowBackIos,
  VisibilityOutlined,
} from "@material-ui/icons";

import { FormGroup } from "react-bootstrap";
import {
  loginAction,
  forgotPasswordAction,
} from "../redux/actions/authActions";
import { getUserClubs } from "../redux/actions/institutesActions";
import { getActivities } from "../redux/actions/campaignsActions";
import {
  getLabelByName,
  getProfileDataFromLocalStorage,
  userIsLoggedIn,
} from "../helpers/helperFunctions";
import logo from "../assets/logo-opac.svg";
import {
  colors,
  EMAIL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_DESCRIPTION,
  LOGGING_IN,
  LOGIN,
  PASSWORD,
  REQUEST_LINK,
  REQUEST_LINK_SENT,
  SENDING,
} from "../helpers/constants";

export class Login extends Component {
  state = {
    username: "",
    userEmail: "",
    password: "",
    isLoading: false,
    isSending: false,
    isSent: false,
    profileData: getProfileDataFromLocalStorage(),
    redirectTo: null,
    showPassword: true,
  };

  async UNSAFE_componentWillMount() {
    const { props } = this;
    const query = new URLSearchParams(
      props.location.search,
    );
    const redirectTo =
      props.location?.state?.from ||
      query.get("redirect_to");

    if (userIsLoggedIn(this.state.profileData)) {
      this.props.history.push("/explore");
    }

    this.setState({ redirectTo });
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      dataError,
      status,
      data,
      history,
      forgotData,
      forgotDataError,
      forgotStatus,
    } = nextProps;

    const { redirectTo } = this.state;

    switch (status) {
      case "success":
        this.setState((state) => ({
          ...state,
          isLoading: false,
          isSending: false,
        }));
        switch (data.status) {
          case 200:
            await this.props.getUserClubs();
            await this.props.getActivities();
            history.push(redirectTo || "/home");
            break;
          default:
            toast.error(data.error.message);
            break;
        }
        break;
      case "error":
        this.setState((state) => ({
          ...state,
          isLoading: false,
          isSending: false,
        }));
        toast.error(dataError.message);
        break;
      default:
        break;
    }

    switch (forgotStatus) {
      case "success":
        this.setState((state) => ({
          ...state,
          isLoading: false,
          isSending: false,
        }));
        switch (forgotData.status) {
          case 500:
            toast.error(forgotData.error.message);
            break;
          default:
            this.setState((state) => ({
              ...state,
              isSent: true,
            }));
            break;
        }
        break;
      case "error":
        this.setState((state) => ({
          ...state,
          isLoading: false,
          isSending: false,
        }));
        toast.error(forgotDataError.message);
        break;
      default:
        break;
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleRadio = (e) => {
    this.setState({ gender: e.target.value });
  };

  handleSubmit = async (e) => {
    const { props } = this;
    e.preventDefault();

    const { username, password } = this.state;
    const userData = {
      username,
      password,
    };

    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    await props.loginAction(userData);
  };

  sendForgotEmail = async (e) => {
    e.preventDefault();
    const { props } = this;
    const { userEmail } = this.state;
    this.setState((state) => ({
      ...state,
      isSending: true,
    }));
    await props.forgotPasswordAction({
      username: userEmail,
    });
  };

  render() {
    const { REACT_APP_APPLICATION_NAME } = process.env;
    document.title = `${
      REACT_APP_APPLICATION_NAME || "SOSO"
    } - ${getLabelByName(LOGIN)}`;
    const {
      username,
      password,
      userEmail,
      isLoading,
      isSending,
      isSent,
    } = this.state;
    const { landingData } = this.props;
    let landingInfo = null;
    if (landingData && landingData.data) {
      landingInfo = landingData.data.filter(
        (x) => x.name === "landing-page",
      )[0];
    }

    const loginText =
      landingInfo &&
      (landingInfo.data["login_text"] ||
        "Log In to engage with your community");

    return (
      <>
        <div className="back-homepage">
          <div>
            <Link
              to="/"
              style={{
                color: colors.textPrimary,
                opacity: "0.5",
              }}>
              <ArrowBackIos />
              {"  "}
              Back
            </Link>
          </div>
        </div>
        <div className="login-form">
          <h5 className="text-center">{loginText}</h5>
          <form
            onSubmit={this.handleSubmit}
            className="auth-form mt-3">
            <FormGroup>
              <input
                type="email"
                id="emailAddress"
                name="username"
                onChange={this.handleChange}
                className="form-control"
                required
              />
              <label
                className="form-control-placeholder"
                htmlFor="emailAddress">
                {getLabelByName(EMAIL)}
              </label>
            </FormGroup>
            <FormGroup>
              <input
                type={
                  this.state.showPassword
                    ? "password"
                    : "text"
                }
                id="password"
                name="password"
                className="form-control"
                onChange={this.handleChange}
                required
              />
              <label
                className="form-control-placeholder"
                htmlFor="password">
                {getLabelByName(PASSWORD)}
              </label>
              <VisibilityOutlined
                onClick={() =>
                  this.setState((prevState) => ({
                    showPassword: !prevState.showPassword,
                  }))
                }
                style={{
                  position: "absolute",
                  top: "25%",
                  left: "90%",
                  color: "#ADADAD",
                  cursor: "pointer",
                }}
              />
            </FormGroup>
            <div className="d-flex justify-content-end">
              <small>
                <p
                  className="forgot-link"
                  data-toggle="modal"
                  data-target=".forgot-password-modal">
                  {getLabelByName(FORGOT_PASSWORD)}
                </p>
              </small>
            </div>
            <br />

            <div className="text-center">
              <button
                type="submit"
                className="btn btn-lg submit-btn">
                {isLoading ? (
                  <>
                    <span>
                      {getLabelByName(LOGGING_IN)}
                      {"  "}
                    </span>
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status">
                      <span className="sr-only">
                        Loading...
                      </span>
                    </div>
                  </>
                ) : (
                  "Sign In"
                )}
              </button>
            </div>
          </form>

          {/* Forgot Modal */}
          <div
            className="modal fade forgot-password-modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="forgotPasswordModal"
            aria-hidden="true">
            <div
              className="modal-dialog modal-lg modal-dialog-centered"
              role="document">
              <div className="modal-content modal-card p-4">
                <div className="d-flex justify-content-center">
                  <img
                    className="logo-modal"
                    src={
                      landingInfo &&
                      landingInfo.data &&
                      landingInfo.data.logo
                        ? landingInfo.data.logo
                        : logo
                    }
                    alt="logo"
                  />
                </div>
                <p className="forgot-modal-title text-center">
                  {getLabelByName(FORGOT_PASSWORD)}
                </p>
                {!isSent ? (
                  <>
                    <p className="forgot-modal-text">
                      {getLabelByName(
                        FORGOT_PASSWORD_DESCRIPTION,
                      )}
                    </p>
                    <form onSubmit={this.sendForgotEmail}>
                      <div className="row d-flex justify-content-center">
                        <FormGroup className="form-input-container">
                          <input
                            type="email"
                            name="userEmail"
                            className="form-control"
                            id="input-email-user"
                            onChange={this.handleChange}
                            value={userEmail}
                            required
                          />
                          <label
                            className="form-control-placeholder"
                            htmlFor="emailAddress">
                            {getLabelByName(EMAIL)}
                          </label>
                        </FormGroup>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-lg submit-btn">
                          {isSending ? (
                            <>
                              <span>
                                {getLabelByName(SENDING)}
                                {"  "}
                              </span>
                              <div
                                className="spinner-border spinner-border-sm"
                                role="status">
                                <span className="sr-only">
                                  Loading...
                                </span>
                              </div>
                            </>
                          ) : (
                            getLabelByName(REQUEST_LINK)
                          )}
                        </button>
                      </div>
                    </form>
                  </>
                ) : (
                  <p className="forgot-modal-text text-center">
                    {getLabelByName(REQUEST_LINK_SENT)}
                  </p>
                )}

                <br />
                <br />
                <div className="row d-flex justify-content-center">
                  <small
                    onClick={() =>
                      this.setState((state) => ({
                        ...state,
                        isSent: false,
                      }))
                    }>
                    <p
                      className="back-to-sign"
                      data-dismiss="modal"
                      aria-label="Close"
                      style={{ color: "#E69355" }}>
                      I remember my password
                    </p>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Login.propTypes = {
  loginAction: PropTypes.func.isRequired,
  forgotPasswordAction: PropTypes.func.isRequired,
  getUserClubs: PropTypes.func.isRequired,
  getActivities: PropTypes.func.isRequired,
  dataError: PropTypes.object,
  data: PropTypes.object,
  status: PropTypes.string,
  forgotData: PropTypes.any,
  forgotDataError: PropTypes.object,
  forgotStatus: PropTypes.string,
  landingData: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
  forgotDataError:
    state.forgotPasswordReducer.forgotDataError,
  forgotData: state.forgotPasswordReducer.forgotData,
  forgotStatus: state.forgotPasswordReducer.forgotStatus,

  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    loginAction,
    forgotPasswordAction,
    getUserClubs,
    getActivities,
  }),
)(Login);
