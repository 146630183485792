import {
  DELETE_CLUB_MEMBERS_SUCCESS,
  DELETE_CLUB_MEMBERS_ERROR,
  DELETE_CLUB_MEMBERS_LOADING,
} from '../actions/types';

const initialState = {
  deleteClubMembersData: null,
  deleteClubMembersDataError: null,
  deleteClubMembersLoading: false,
  deleteClubMembersStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DELETE_CLUB_MEMBERS_SUCCESS:
      return {
        ...state,
        deleteClubMembersData: action.payload,
        deleteClubMembersDataError: null,
        deleteClubMembersStatus: 'success',
        deleteClubMembersLoading: false,
      };
    case DELETE_CLUB_MEMBERS_ERROR:
      return {
        ...state,
        deleteClubMembersDataError: action.payload,
        deleteClubMembersData: null,
        deleteClubMembersStatus: 'error',
        deleteClubMembersLoading: false,
      };
    case DELETE_CLUB_MEMBERS_LOADING:
      return {
        ...state,
        deleteClubMembersDataError: null,
        deleteClubMembersData: null,
        deleteClubMembersStatus: 'loading',
        deleteClubMembersLoading: true,
      };
    default:
      return {
        ...state,
        deleteClubMembersStatus: null,
      };
  }
};
