import React from 'react';
import {
  CheckBoxOutlined, EventOutlined, ImageOutlined, LinkOutlined, RadioButtonCheckedOutlined, AttachFileRounded, ShareOutlined,
  ShortTextRounded, StarBorderRounded, SubjectRounded, UnfoldMoreRounded, ViewListRounded, ToggleOff, ReorderRounded,
} from '@material-ui/icons';
import Option from './Option';
import UploadImage from './UploadImage';
import TextArea from './TextArea';
import ShortInput from './ShortInput';
import { colors } from '../../../../helpers/constants';
import Checkboxes from '../preview/Checkboxes';
import Dropdown from '../preview/Dropdown';
import Radios from '../preview/Radios';
import Stars from '../preview/Stars';
import UploadDoc from './UploadDoc';
import DateTimePreview from './DateTimePreview';
import DateTimeRangePreview from './DateTimeRangePreview';
import BooleanInput from './BooleanInput';
import SortableQuestion from '../preview/SortableQuestion';
import BooleanRender from './BooleanRender';

export const questionsTypesMapping = {
  SIMPLE_TEXT: 'Short Answer',
  FREE_TEXT: 'Paragraph',
  FILE_UPLOAD: 'Upload Image',
  CHECKBOXES: 'Checkboxes',
  DROPDOWN: 'Dropdown',
  MULTIPLE_CHOICE: 'Multiple Choice',
  RADIOS: 'Radio Buttons',
  RATING: 'Rating',
  DATE_TIME: 'Date / Time',
  DATE_TIME_RANGE: 'Date / Time Range',
  SHARE_LINK: 'Share Link',
  SOCIAL_MEDIA: 'Social Media Profile',
  DOC_UPLOAD: 'Upload Document',
  BOOLEAN_QUESTION: 'Boolean Question',
  SORTABLE: 'Sortable',
};

export const questionsTypesIcons = {
  SIMPLE_TEXT: <ShortTextRounded style={{ color: colors.primaryColor, marginRight: '0.5rem' }} />,
  FREE_TEXT: <SubjectRounded style={{ color: colors.primaryColor, marginRight: '0.5rem' }} />,
  FILE_UPLOAD: <ImageOutlined style={{ color: colors.primaryColor, marginRight: '0.5rem' }} />,
  CHECKBOXES: <CheckBoxOutlined style={{ color: colors.primaryColor, marginRight: '0.5rem' }} />,
  DROPDOWN: <UnfoldMoreRounded style={{ color: colors.primaryColor, marginRight: '0.5rem' }} />,
  MULTIPLE_CHOICE: <ViewListRounded style={{ color: colors.primaryColor, marginRight: '0.5rem' }} />,
  RADIOS: <RadioButtonCheckedOutlined style={{ color: colors.primaryColor, marginRight: '0.5rem' }} />,
  RATING: <StarBorderRounded style={{ color: colors.primaryColor, marginRight: '0.5rem' }} />,
  DATE_TIME: <EventOutlined style={{ color: colors.primaryColor, marginRight: '0.5rem' }} />,
  DATE_TIME_RANGE: <EventOutlined style={{ color: colors.primaryColor, marginRight: '0.5rem' }} />,
  SHARE_LINK: <LinkOutlined style={{ color: colors.primaryColor, marginRight: '0.5rem' }} />,
  SOCIAL_MEDIA: <ShareOutlined style={{ color: colors.primaryColor, marginRight: '0.5rem' }} />,
  DOC_UPLOAD: <AttachFileRounded style={{ color: colors.primaryColor, marginRight: '0.5rem', transform: 'rotate(45deg)' }} />,
  BOOLEAN_QUESTION: <ToggleOff style={{ color: colors.primaryColor, marginRight: '0.5rem' }} />,
  SORTABLE: <ReorderRounded style={{ color: colors.primaryColor, marginRight: '0.5rem' }} />,
};

export const questionComponentMappping = (handleCustomMeta, index, question, previewMode = false) => ({
  SIMPLE_TEXT: <ShortInput />,
  FREE_TEXT: <TextArea />,
  FILE_UPLOAD: <UploadImage />,
  CHECKBOXES: <Option />,
  DROPDOWN: <Option />,
  MULTIPLE_CHOICE: <Option />,
  RADIOS: <Option />,
  RATING: <Option />,
  DATE_TIME: <DateTimePreview question={question} handleCustomMeta={handleCustomMeta} index={index} previewMode={previewMode} />,
  DATE_TIME_RANGE: <DateTimeRangePreview question={question} handleCustomMeta={handleCustomMeta} index={index} previewMode={previewMode} />,
  SHARE_LINK: <ShortInput />,
  SOCIAL_MEDIA: <ShortInput />,
  DOC_UPLOAD: <UploadDoc />,
  BOOLEAN_QUESTION: <BooleanRender resource={question} review />,
});

export const optionsPreviewMappping = (question) => {
  const settings = question?.meta?.choicesSettings && JSON.parse(question.meta.choicesSettings);
  const maxChoices = settings && Number(settings.maxChoices);
  const defaults = question?.meta?.choicesSettings && JSON.parse(question.meta.choicesSettings)?.defaults;

  return ({
    CHECKBOXES: <Checkboxes options={question.options} />,
    DROPDOWN: <Dropdown options={question.options} />,
    MULTIPLE_CHOICE: maxChoices <= 1
      ? <Dropdown options={question.options} defaults={defaults} />
      : <Checkboxes options={question.options} defaults={defaults} />,
    RADIOS: <Radios options={question.options} />,
    RATING: <Stars options={question.options} />,
    SORTABLE: <SortableQuestion options={question.options} />,
  });
};

export const optionTypes = ['CHECKBOXES', 'DROPDOWN', 'MULTIPLE_CHOICE', 'RADIOS', 'RATING', 'SORTABLE'];

export const ratingRange = {
  minRating: 3,
  maxRating: 10,
};
