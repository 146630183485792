import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { ArrowForwardIos } from "@material-ui/icons";
import { connect } from "react-redux";
import { compose } from "redux";
import "./activityLinks.scss";

export const ActivityLinks = ({
  title,
  count,
  info,
  link,
  activityData,
}) => {
  return (
    <NavLink
      to={{ pathname: link, activityData }}
      className="activityLinks">
      <div className="text">
        <h3>
          {title} {count && <span>{`(${count})`}</span>}{" "}
        </h3>
        <p>{info}</p>
      </div>
      <ArrowForwardIos />
    </NavLink>
  );
};

export class ActivityLinksForPrticipant extends Component {
  UNSAFE_componentWillMount() {
    const {
      activityData,
      getParticipants: getParticipantsAction,
    } = this.props;

    if (activityData?.id) {
      getParticipantsAction(activityData.id);
    }
  }

  render() {
    const {
      participantsData,
      title,
      info,
      link,
      activityData,
    } = this.props;
    return (
      <ActivityLinks
        title={title}
        count={participantsData?.data?.length}
        info={info}
        link={link}
        activityData={activityData}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  participantsData:
    state.getParticipantsReducer.getParticipantsData,
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ActivityLinksForPrticipant);
