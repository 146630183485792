import React, { useEffect } from 'react'
import { withRouter, } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { connect } from 'react-redux';
import { compose } from 'redux';

import { retrieveActivity } from "../../../redux/actions/activities/activityActions.js";
import { retrieveParticipant } from "../../../redux/actions/activities/participantActions.js";
import { searchParticipantActions } from "../../../redux/actions/activities/participantActionActions.js";

import ActionRenderer from './ActionRenderer.js';
import './participantAction.scss'

const ParticipantAction = (props) => {
    const { activityId, participantId, actionId, pageAction, participantActionId } = props.match.params // parent props
    const { history, location, activity, participant, participantActions } = props // redux props


    useEffect(() => {
        props.retrieveActivity(activityId)
        props.retrieveParticipant(activityId, participantId)
        props.searchParticipantActions(activityId, participantId)
    }, [activityId, participantId, actionId, pageAction, participantActionId])

    if (!activity?.actions) {
        return <>Loading...</>
    }

    const actionsIndex = {};
    let action = null;
    activity.actions.forEach(activityAction => {
        actionsIndex[activityAction.id] = activityAction;
        if (activityAction.id == actionId) {
            action = activityAction;
        }
    });

    if (!action) {
        console.log("---Action not found, redirect to console");
    }

    return <section className='participant-action'>
        <nav className="main-nav">
            <div className="link-wrapper">
                <button
                    type="button"
                    onClick={() =>
                        history.push(`/activity/${activityId}/participant/${participantId}/console`)
                    }>
                    <ArrowBackIosIcon />
                    <span className="d-none d-md-block">
                        Back to Dashboard
                    </span>
                </button>
            </div>

            <div className="wrapper">
                <p>{action?.name}</p>
            </div>
        </nav>
        <ActionRenderer
            activity={activity}
            action={action}
            participant={participant}
            participantActions={participantActions}
            pageAction={pageAction}
            participantActionId={participantActionId} />
    </section>
}

const mapStateToProps = (state) => ({
    activity: state.activityCRUDReducer,
    participant: state.participantCRUDReducer,
    participantActions: state.participantActionsSearchReducer,
    participantContributions: state.participantContributionsSearchReducer,
});

export default compose(
    withRouter,
    connect(mapStateToProps, {
        retrieveActivity,
        retrieveParticipant,
        searchParticipantActions,
    }),
)(ParticipantAction);
