import React from "react";
import { ReactComponent as ErrorCircle } from "../../../../../assets/error-circle.svg";

const ErrorMessage = () => {
  return (
    <div className="error-message m-4">
      <div className="max-width-wrapper">
        <div className="mb-3">
          <ErrorCircle className="error-circle" />
        </div>

        <p className="mb-4">
          An errror occured while trying to export your data
        </p>
        <button type="button" className="btn-action">
          Try Again
        </button>
      </div>
    </div>
  );
};

export default ErrorMessage;
