/* eslint-disable react/no-danger */
import React from "react";
import { ReactComponent as HamburgerIcon } from "../../../../assets/icons/hamburger.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../../assets/icons/arrow-left.svg";
import ImageGridGallery from "../../../ImageGridGallery";
import VideoPlayer from "../../../generics/VideoPlayer";
import FileDownload from "../../../generics/FileDownload";

const MainContent = ({
  isOpen,
  toggleSidebar,
  currLesson,
  lessonProgress,
  handleLessonCompletion,
  action,
}) => {
  const { headerImage, ...articleImages } =
    currLesson?.images ?? {};

  return (
    <main className={isOpen ? "" : "is-closed"}>
      <header>
        <div className="title-wrapper">
          <button
            type="button"
            className="toggle-btn"
            onClick={() => toggleSidebar((open) => !open)}>
            {isOpen ? <CloseIcon /> : <HamburgerIcon />}
          </button>

          <span className={isOpen ? "hide" : ""}>{currLesson?.content?.title}</span>
        </div>

        <div className="progress">
          <span>{`${lessonProgress}%`} complete</span>
        </div>
      </header>

      <div className="content">
        <div className="wrapper">
          {headerImage && (
            <div className="article-image">
              <img
                src={headerImage}
                alt={currLesson?.content?.title}
              />
            </div>
          )}

          {/* ================ ARTICLE BODY ================ */}
          <article
            className="mb-3"
            dangerouslySetInnerHTML={{
              __html: currLesson?.content?.body,
            }}
          />

          {/* ================ IMAGE GRID GALLERY ================ */}
          <div className="activity-content-images mb-3 pb-3">
            {Object.values(articleImages).length > 0 && (
              <ImageGridGallery
                images={Object.values(articleImages)}
              />
            )}
          </div>

          {/* ================ VIDEO PLAYER ================ */}
          {currLesson?.videos && (
            <div className="activity-content-videos mb-3 pb-3">
              {Object.values(currLesson?.videos).length >
                0 &&
                Object.values(currLesson.videos).map(
                  (video) => <VideoPlayer video={video} />,
                )}
            </div>
          )}

          {/* ================ FILE DOWNLOAD ================ */}
          {currLesson?.files && (
            <div className="activity-content-files pb-3">
              {Object.values(currLesson.files).length > 0 &&
                Object.values(currLesson.files).map(
                  (file) => <FileDownload file={file} />,
                )}
            </div>
          )}
        </div>
      </div>

      <footer>
        <a
          className="cta-button p-4"
          onClick={() => handleLessonCompletion()}>
          {currLesson?.isComplete
            ? action?.profile?.cta_mark_incomplete
            : action?.profile?.cta_mark_complete}
          <ArrowLeftIcon className="arrow-left-icon" />
        </a>
      </footer>
    </main>
  );
};

export default MainContent;
