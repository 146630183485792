import React, {useEffect} from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getLanding } from '../redux/actions/landingActions';
import {TermPolicyPageComponent} from './term-privacy-component'

const Privacy = (props) => {
// eslint-disable-next-line react/destructuring-assignment
const privacyObject = props?.landingData?.data.find(datum => datum.name === 'privacy')
const privacyData = privacyObject?.data || {}

  useEffect(() => {
    props.getLanding()
  }, [])
  useEffect(() => {}, [privacyData])
  
  return (
    <TermPolicyPageComponent pageTitle="Privacy Policy" pageData={privacyData}/>
  )
}

const mapStateToProps = (state) => ({
    landingDataError: state.landingReducer.landingDataError,
    landingData: state.landingReducer.landingData,
    landingStatus: state.landingReducer.landingStatus,
});
  
export default compose(
    withRouter,
    connect(mapStateToProps, { getLanding }),
  )(Privacy);