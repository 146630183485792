import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { createSession, verifySession, deleteSession } from '../../redux/actions/users/sessionActions';

const SessionUser = (props) => {
    const { sessionUser } = props;
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        props.verifySession();
    }, []);

    useEffect(() => {
        if (sessionUser.id !== 0) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [sessionUser]);

    const createSession = (e) => {
        props.createSession({
            username: "su.b@almamaters.club",
            password: "p4$$word!"
        });
    }

    const verifySession = (e) => {
        props.verifySession();
    }

    const deleteSession = (e) => {
        props.deleteSession();
    }

    return <>
        <h1>Let's have a play session</h1>
        {!isAuthenticated && <>
            <p><button onClick={createSession}>Create Session</button></p>
        </>}
        {isAuthenticated && <>
            <p>You are logged in as {sessionUser.profile.firstName} {sessionUser.profile.lastName}</p>
            <p><button onClick={verifySession}>Verify Session</button></p>
            <p><button onClick={deleteSession}>Delete Session</button></p>
        </>}
    </>

}

const mapStateToProps = (state) => ({
    sessionUser: state.sessionUserReducer
})

export default compose(
    withRouter,
    connect(mapStateToProps, {
        createSession,
        verifySession,
        deleteSession,
    }),
)(SessionUser);