import {
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  REQUEST_PASSWORD_SUCCESS,
  REQUEST_PASSWORD_ERROR,
  IMPORT_USERS_SUCCESS,
  IMPORT_USERS_ERROR,
} from "./types";

import apiCall from "../../helpers/apiCall";
import { serviceProvider } from "../../helpers/constants";
import { getHeaders } from "../../helpers/getHeaders";
import { toast } from "react-toastify";

const userType = (type, payload) => ({
  type,
  payload,
});

export const getUsers =
  (headers = getHeaders(null, "/users")) =>
  (dispatch) => {
    apiCall
      .get("/users", { headers })
      .then((response) => {
        dispatch(
          userType(GET_USERS_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(userType(GET_USERS_ERROR, err));
      });
  };

export const importUsers =
  (payload, headers = getHeaders(null, "/users/import")) =>
  async (dispatch) => {
    try {
      const response = await apiCall.post(
        "/users/import",
        payload,
        { headers },
      );

      dispatch(
        userType(IMPORT_USERS_SUCCESS, response.data),
      );

      return response.data;
    } catch (err) {
      dispatch(userType(IMPORT_USERS_ERROR, err));
    }
  };

export const requestPasswordReset =
  (
    payload,
    headers = getHeaders(null, "/user/password/request"),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.post(
        "/user/password/request",
        payload,
        { headers },
      );

      dispatch(
        userType(REQUEST_PASSWORD_SUCCESS, response.data),
      );

      return response.data;
    } catch (err) {
      dispatch(userType(REQUEST_PASSWORD_ERROR, err));
    }
  };

export const requestVerification =
  (
    payload,
    headers = getHeaders(payload, "/user/verification/request"),
  ) =>
  async () => {
    try {
      const response = await apiCall.post(
        "/user/verification/request",
        payload,
        { headers },
      );

      toast.info("verification email as been sent")

      return response.data;
    } catch (err) {
      return toast.error("Oops! Something went wrong")
    }
  };

  export const confirmVerification =
  (
    payload,
    headers = {
      "Content-Type": "application/json",
      "X-SERVICE-PROVIDER": serviceProvider,
    },
  ) =>
  async () => {
    try {
      const response = await apiCall.post(
        "/user/verification/confirm",
        payload,
        { headers },
      );
      console.log('confirmResponse', response?.data) // Invalid token data: {message: 'Account verified successfully'}
      // console.log('confirmResponse', response?.data?.error?.message) // Invalid token // "User has already been verified"

      return response.data;
    } catch (err) {
      console.log('confirmError', err)
      return toast.error("Oops! Something went wrong")
    }
  };