import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { Add, ArrowForwardIos } from "@material-ui/icons";
import {
  ACTIVITIES,
  ADD_NEW_PARTICIPANT,
  NO_ACTIVITIES,
  VIEW_ALL,
} from "../../../../helpers/constants";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import "./club-activities-section.css";
import ItemCard from "../../../explore/ItemCard";
import activityIcon from "../../../../assets/manage-campaigns.svg";

export class ClubActivitiesSection extends Component {
  render() {
    const { match, clubCampaignsData: clubActivities } = this.props;

    return (
      <div className="club-activities">
        <div className="header-title">
          <h1>
            {getLabelByName(ACTIVITIES)}
            <span style={{ color: "#A3A3A3", paddingLeft: "3px" }}>
              ({clubActivities?.data?.length})
            </span>
          </h1>
          <span>
            <Link
              to={`${window.location.pathname}/activities`}
              style={{ color: "#C6A177" }}
            >
              {getLabelByName(VIEW_ALL)}
              <ArrowForwardIos />
            </Link>
          </span>
        </div>
        <div className="body">
          <div className="activities-details">
            {clubActivities?.data?.length > 0 ? (
              clubActivities?.data
                ?.slice(0, 4)
                .map((activity, index) => (
                  <ItemCard
                    item={activity}
                    type="manage/activities"
                    index={index}
                    defaultIcon={activityIcon}
                  />
                ))
            ) : (
              <p>{getLabelByName(NO_ACTIVITIES)}</p>
            )}
          </div>
        </div>
        <div className="footer">
          {match.path === "/manage/clubs/:id/:handle" && (
            <Button
              style={{ textTransform: "none" }}
              color="primary"
              onClick={() => this.props.SetIsModalOpen()}
            >
              <Add />
              {getLabelByName(ADD_NEW_PARTICIPANT)}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

ClubActivitiesSection.propTypes = {
  getParticipants: PropTypes.func,
  getInstitute: PropTypes.func,
  activityData: PropTypes.object,
  clubData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  clubCampaignsData: state.clubCampaignsReducer.clubCampaignsData,
});

const mapDispatchToProps = {};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ClubActivitiesSection);
