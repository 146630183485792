import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CreateOutlined } from "@material-ui/icons";
import moment from "moment";
import "./DataCollectionManager.scss"


const DataCollectionManager = ({ activity, action, participant, participantActions }) => {

    const [formStatusToShow, setFormStatusToShow] = useState('INCOMPLETE')

    return (
        <div className="data-collection-manager">
            <div className="top-section">
                <div className="text-section">
                    <h5>Step {action.displayOrder} : {action.name}</h5>
                    <p>{action.profile.description}</p>
                </div>
                <Link to={`/activity/${activity.id}/participant/${participant.id}/action/${action.id}/new`}>
                    Add an entry
                </Link>
            </div>
            <div className="switch-section">
                <button
                    className={formStatusToShow === 'INCOMPLETE' ? 'active' : ''}
                    onClick={() => setFormStatusToShow('INCOMPLETE')}>
                    Draft
                </button>
                <button
                    className={formStatusToShow === 'COMPLETE' ? 'active' : ''}
                    onClick={() => setFormStatusToShow('COMPLETE')}>
                    Ready for Submission
                </button>
                <button
                    className={formStatusToShow === 'completed' ? 'active' : ''}
                    onClick={() => setFormStatusToShow('completed')}>
                    Submitted
                </button>
            </div>
            <div className="card-section">
                {participantActions?.participantActions?.map((participantAction) => {
                    if (participantAction.actionId == action.id && participantAction.status == formStatusToShow) {

                        // Also check if 
                        // - formStatusToShow === 'draft' || 
                        // - formStatusToShow ==='readyToSubmit' ||
                        // - formStatusToShow === 'completed'
                        // And render appropriately
                        return (<div key={participantAction.id}>
                            <div className="contribution-card-area">
                                <div className="listing-card-ItemCard">
                                    <h1>{action.name} | {participantAction.status}</h1>
                                    <p>Created At :
                                        {moment(participantAction?.dateCreated * 1000).format("DD MMM YYYY hh:mm",) || "DD MMM YYYY"}
                                    </p>
                                    <p>Last Updated :{" "}
                                        {(participantAction?.lastUpdated &&
                                            moment(participantAction?.lastUpdated * 1000,).format("DD MMM YYYY hh:mm")) || "N/A"}
                                    </p>
                                    <Link to={`/activity/${activity.id}/participant/${participant.id}/action/${action.id}/edit/${participantAction.id}`}>
                                        <CreateOutlined className="mr-2" />
                                        Edit Entry
                                    </Link>
                                </div>
                            </div>
                        </div>);
                    }
                })
                }
            </div>
        </div>)


}

export default DataCollectionManager;