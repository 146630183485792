import {
  UPDATE_CONTRIBUTION_ERROR, UPDATE_CONTRIBUTION_SUCCESS,
} from '../actions/types';

const initialState = {
  updateContributionData: null,
  updateContributionDataError: null,
  updateContributionStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONTRIBUTION_SUCCESS:
      return {
        ...state,
        updateContributionData: action.payload,
        updateContributionDataError: null,
        updateContributionStatus: 'success',
      };
    case UPDATE_CONTRIBUTION_ERROR:
      return {
        ...state,
        updateContributionDataError: action.payload,
        updateContributionData: null,
        updateContributionStatus: 'error',
      };
    default:
      return {
        ...state,
        updateContributionStatus: null,
      };
  }
};
