import {
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  GUEST_LOGIN_SUCCESS,
  GUEST_LOGIN_ERROR,
  GET_SESSION_SUCCESS,
  GET_SESSION_ERROR,
} from "../actions/types";

const initialState = {
  data: null,
  dataError: null,
  status: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_SUCCESS:
      return {
        ...state,
        data: action.payload,
        dataError: null,
        status: "success",
      };
    case SIGNUP_ERROR:
      return {
        ...state,
        dataError: action.payload,
        data: null,
        status: "error",
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        dataError: null,
        status: "success",
      };
    case LOGIN_ERROR:
      return {
        ...state,
        dataError: action.payload,
        data: null,
        status: "error",
      };
    case GUEST_LOGIN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        dataError: null,
        status: "success",
      };
    case GUEST_LOGIN_ERROR:
      return {
        ...state,
        dataError: action.payload,
        data: null,
        status: "error",
      };
    case GET_SESSION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        dataError: null,
        status: "success",
      };
    case GET_SESSION_ERROR:
      return {
        ...state,
        dataError: action.payload,
        data: null,
        status: "error",
      };
    default:
      return {
        ...state,
        status: null,
      };
  }
};
