import {
  GET_USER_CLUBS_ERROR, GET_USER_CLUBS_SUCCESS,
} from '../actions/types';

const initialState = {
  getUserClubsData: null,
  getUserClubsDataError: null,
  getUserClubsStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_CLUBS_SUCCESS:
      return {
        ...state,
        getUserClubsData: action.payload,
        getUserClubsDataError: null,
        getUserClubsStatus: 'success',
      };
    case GET_USER_CLUBS_ERROR:
      return {
        ...state,
        getUserClubsDataError: action.payload,
        getUserClubsData: null,
        getUserClubsStatus: 'error',
      };
    default:
      return {
        ...state,
        // institutesStatus: null,
      };
  }
};
