import "./activityDetails.scss";
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  CalendarToday as CalendarTodayIcon,
  Extension as ExtensionIcon,
  People as PeopleIcon,
} from "@material-ui/icons";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import activityDefaultImage from "../../../../assets/manage-campaigns.svg";
import {
  ACTIVITIES,
  CONTRIBUTIONS,
  DESCRIPTION,
  END_DATE,
  LAST_ACTIVITY,
  MEMBERS,
  NO_DESCRIPTION_FOUND,
  PARTICIPANT,
  START_DATE,
} from "../../../../helpers/constants";
import ActivityStatus from "./ActivityStatus";

const ActivityDescription = ({ activityData }) => (
  <div className="activityDescription">
    <h1>{getLabelByName(DESCRIPTION)}</h1>
    <p className="white-space-pre-line">
      {activityData?.profile?.description ||
        getLabelByName(NO_DESCRIPTION_FOUND)}
    </p>
  </div>
);
export class ActivityDetails extends Component {
  UNSAFE_componentWillMount() {
    const {
      activityData,
      getParticipants: getParticipantsAction,
      getInstitute: getInstituteAction,
    } = this.props;
    if (activityData?.parentId) {
      getInstituteAction(activityData.parentId);
    }

    if (activityData?.id) {
      getParticipantsAction(activityData.id);
    }
  }

  render() {
    const {
      activityData,
      clubData,
      participantsData,
      getContributionsData,
      isClubLayout,
      isFromActivityDetailsPage,
      match: { path },
    } = this.props;

    return (
      <>
        <div className="Activity_">
          <div className="mobileLayout">
            <div className="mobilegroup">
              <img
                className="activityPicture"
                src={
                  activityData?.profile?.picture ||
                  activityDefaultImage
                }
                alt="activity"
              />
              <ActivityStatus
                activityData={activityData}
                clubData={clubData}
                path={path}
                isMobile
              />
            </div>
            <ActivityDescription
              activityData={activityData}
            />
          </div>

          <div className="desktopLayout">
            <img
              className="activityPicture"
              src={
                activityData?.profile?.picture ||
                activityDefaultImage
              }
              alt="activity"
            />
            <div className="group">
              <ActivityStatus
                activityData={activityData}
                clubData={clubData}
                path={path}
              />
              <ActivityDescription
                activityData={activityData}
              />
            </div>
          </div>
        </div>
        <div className="ActivityPlus">
          <div className="unit">
            <div className="group">
              <CalendarTodayIcon />
              <p>{getLabelByName(START_DATE)}</p>
            </div>
            <p>
              {isClubLayout
                ? moment(
                    activityData?.dateCreated * 1000,
                  ).format("DD MMM YYYY")
                : moment(
                    activityData?.startDate * 1000,
                  ).format("DD MMM YYYY HH:MM")}
            </p>
            <div className="line" />
          </div>
          <div className="unit">
            <div className="group">
              <CalendarTodayIcon />
              <p>
                {isClubLayout
                  ? getLabelByName(LAST_ACTIVITY)
                  : getLabelByName(END_DATE)}
              </p>
            </div>
            {isClubLayout ? (
              <p>
                {activityData?.lastUpdated
                  ? moment(
                      activityData?.lastUpdated * 1000,
                    ).format("DD MMM YYYY HH:MM")
                  : "N/A"}{" "}
              </p>
            ) : (
              <p>
                {activityData?.endDate
                  ? moment(
                      activityData?.endDate * 1000,
                    ).format("DD MMM YYYY HH:MM")
                  : "N/A"}
              </p>
            )}
            <div className="line" />
          </div>
          {(activityData?.campaigns ||
            activityData?.meta?.displayContributions ===
              "1" ||
            isFromActivityDetailsPage) && (
            <div className="unit">
              <div className="group">
                <ExtensionIcon />
                <p>
                  {isClubLayout
                    ? getLabelByName(ACTIVITIES)
                    : getLabelByName(CONTRIBUTIONS)}
                </p>
              </div>
              <p>
                {isClubLayout
                  ? activityData?.campaigns
                  : getContributionsData?.data?.length}
              </p>
              <div className="line" />
            </div>
          )}
          {(activityData?.members ||
            activityData?.meta?.displayParticipants ===
              "1" ||
            isFromActivityDetailsPage) && (
            <div className="unit">
              <div className="group">
                <PeopleIcon />
                <p>
                  {isClubLayout
                    ? getLabelByName(MEMBERS)
                    : getLabelByName(PARTICIPANT)}
                </p>
              </div>
              <p>
                {isClubLayout
                  ? activityData?.members
                  : participantsData?.data.length}
              </p>
            </div>
          )}
        </div>
      </>
    );
  }
}

ActivityDetails.propTypes = {
  getParticipants: PropTypes.func,
  participantsData: PropTypes.object,
  activityData: PropTypes.object,
  getInstitute: PropTypes.func,
  clubData: PropTypes.object,
  getContributionsData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  clubData: state.instituteReducer.instituteData,
  participantsData:
    state.getParticipantsReducer.getParticipantsData,
});

const mapDispatchToProps = {};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ActivityDetails);
