/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import Switch from '../../generics/Switch';
import { CHARACTERS_REMAINING, colors } from '../../../helpers/constants';
import { getLabelByName } from '../../../helpers/helperFunctions';

class InputCardSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      title, showStatus, toggleShowStatus, statusName,
    } = this.props;

    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className="institute-card article-card">
          <div className="create-card-header">
            <div className="card-header-title w-100 d-flex justify-content-between">
              <h5>
                {title}
                {' '}
                <span className="asterisk">*</span>
              </h5>
              <div className="font-italic character-counter">
                {this.getWordCount()}
                {' '}
                {getLabelByName(CHARACTERS_REMAINING)}
              </div>
            </div>
          </div>
          {this.selectInputType()}
          {this.props.children}

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={showStatus} onChange={() => toggleShowStatus(statusName, showStatus)} />}
              label={<span style={{ color: colors.textSecondary }}>Hide section</span>}
              labelPlacement="start"
            />
          </FormGroup>
        </div>
      </div>
    );
  }

  selectInputType = () => {
    const {
      inputType, inputName, placeholder, handleChange, input, maxLength,
    } = this.props;

    if (inputType === 'textarea') {
      return (
        <div className="post-textarea description-textarea">
          <textarea
            name={inputName}
            rows="4"
            maxLength={maxLength}
            placeholder={placeholder}
            value={input}
            onChange={(e) => handleChange(e)}
            required
            style={{ resize: 'none' }}
          />
        </div>
      );
    }

    return (
      <div className="col-md-12">
        <input
          type="text"
          name={inputName}
          className="form-control"
          id="input-name"
          maxLength={maxLength}
          placeholder={placeholder}
          value={input}
          onChange={(e) => handleChange(e)}
          style={{ backgroundColor: '#FFFFFF' }}
          required
        />
      </div>
    );
  };

  getWordCount = () => {
    const { maxLength, input } = this.props;
    return maxLength - input.length;
  };
}

InputCardSection.propTypes = {
  inputType: PropTypes.any,
  inputName: PropTypes.any,
  title: PropTypes.any,
  placeholder: PropTypes.any,
  handleChange: PropTypes.any,
  input: PropTypes.any,
  showStatus: PropTypes.any,
  toggleShowStatus: PropTypes.any,
  statusName: PropTypes.any,
  maxLength: PropTypes.any,
};

export default InputCardSection;
