/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable dot-notation */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { ArrowBackIos } from "@material-ui/icons";

import { toast } from "react-toastify";
import { getSessionAction } from "../../../../redux/actions/authActions";
import Footer from "../../../Footer";
import Navbar from "../../../Navbar";
import {
  checkSession,
  getLabelByName,
  getProfileDataFromLocalStorage,
  isActionError,
  isActionSuccess,
} from "../../../../helpers/helperFunctions";
import {
  BACK,
  UPDATED,
  QUESTIONNAIRE,
} from "../../../../helpers/constants";
import { getQuestionnaire } from "../../../../redux/actions/campaignsActions";
import { ratingRange } from "../components/questionsHelpers";
import {
  createQuestion,
  deleteQuestion,
  updateQuestion,
  updateQuestionnaire,
} from "../../../../redux/actions/adminActions";
import QuestionnairePageHeader from "./questionnairePageHeader";
import PreviewedQuestionnaire from "./previewedQuestionnaire";
import QuestionnaireFooter from "./questionnaireFooter";
import UnpreviewedQuestionnaire from "./unpreviewedQuestionnaire";

import "./questionnairePage.scss";
import { handleImageUpload } from "../../../../helpers/functions";
import Checkbox from "../../../common/checkbox/checkbox";

export class QuestionnairePage extends Component {
  state = {
    profileData: getProfileDataFromLocalStorage(),
    preveiewQuestionnaire: false,
    isLoading: false,
    questionsList: [],
    name: "",
    description: "",
    questionsToRemove: [],
    displayAdvancedSettings: false,
    displayMultipleOption: false,
  };

  async UNSAFE_componentWillMount() {
    const { props, state } = this;
    const { profileData, pathname } = state;
    if (!profileData || profileData.status !== 200) {
      window.location.href = `/login?redirect_to=${pathname}`;
    }
    await props.getSessionAction();
    const { clubId, questionnaireId } = props.match.params;
    await props.getQuestionnaire(clubId, questionnaireId);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { status, data } = nextProps;

    const { props, state } = this;
    const { pathname } = state;

    checkSession(data, status, pathname, props);

    if (
      isActionSuccess(
        nextProps,
        "getQuestionnaireStatus",
        "getQuestionnaireData",
      )
    ) {
      this.setState({
        questionsList: [
          ...state.questionsList,
          ...nextProps.getQuestionnaireData.data.questions,
        ],
        name: nextProps.getQuestionnaireData.data.name,
        description:
          nextProps.getQuestionnaireData.data.description,
      });
    }

    if (
      isActionSuccess(
        nextProps,
        "updateQuestionnaireStatus",
        "updateQuestionnaireData",
      )
    ) {
      this.setState({ isLoading: false });
      toast.success(
        `${getLabelByName(QUESTIONNAIRE)} ${getLabelByName(
          UPDATED,
        )}`,
      );
      window.location.reload();
    }

    if (
      isActionError(
        nextProps,
        "updateQuestionnaireStatus",
        "updateQuestionnaireData",
      )
    ) {
      this.setState({ isLoading: false });
    }
  }

  handleDisplayMultipleOption = (value) => {
    this.setState({ displayMultipleOption: value });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  dataHasLoaded = (state) => !!(state && state.data);

  getQuestionnaire = () =>
    this.dataHasLoaded(this.props.getQuestionnaireData) &&
    this.props.getQuestionnaireData.data;

  switchPreviewQuestionnaire = () => {
    this.setState({
      preveiewQuestionnaire:
        !this.state.preveiewQuestionnaire,
    });
  };

  addQuestion = () => {
    const { questionnaireId } = this.props.match.params;

    const newQuestion = {
      questionnaireId,
      content: "",
      type: null,
      options: [],
      meta: {
        input_key: "",
      },
      images: {},
      settings: {
        responses: [
          {
            type: null,
            label: "",
            options: [],
            required: false,
            input_key: "",
          },
        ],
      },
      required: false,
    };

    this.setState((prevState) => ({
      questionsList: [
        ...prevState.questionsList,
        newQuestion,
      ],
    }));
  };

  addResponse = (questionIndex) => {
    const questionsList = [...this.state.questionsList];
    let newQuestion;

    if (questionsList[questionIndex]?.settings?.responses) {
      newQuestion = {
        ...questionsList[questionIndex],
        settings: {
          responses: [
            ...questionsList[questionIndex].settings
              .responses,
            {
              type: null,
              label: "",
              options: [],
              required: false,
              input_key: "",
            },
          ],
        },
      };
    } else {
      newQuestion = {
        ...questionsList[questionIndex],
        settings: {
          responses: [
            {
              type: null,
              label: "",
              options: [],
              required: false,
              input_key: "",
            },
          ],
        },
      };
    }

    const newQuestions = questionsList.filter(
      (question, index) => index !== questionIndex,
    );
    newQuestions.splice(questionIndex, 0, newQuestion);

    this.setState(() => ({ questionsList: newQuestions }));
  };

  renderCheckbox = (questionIndex, optionIndex) => (
    <Checkbox
      id={`gridCheck-${questionIndex}-${optionIndex}`}
      children=""
    />
  );

  generateOptionTypes = ({
    optionValue,
    questionIndex,
    responseIndex,
    responseType,
  }) => {
    optionValue.split(new RegExp("\n")).map((value) => {
      const { questionsList } = this.state;
      const questionToUpdate = questionsList[questionIndex];

      const newOptions = [
        ...questionToUpdate.settings.responses[
          responseIndex
        ].options,
        value,
      ];

      questionToUpdate.hasUpdated = true;
      questionToUpdate.settings.responses[
        responseIndex
      ].options = newOptions;

      const newQuestions = questionsList.filter(
        (question, index) => index !== questionIndex,
      );
      newQuestions.splice(
        questionIndex,
        0,
        questionToUpdate,
      );
      this.setState({ questionsList: newQuestions });
    });
  };

  addOption = (
    questionIndex,
    responseIndex,
    questionType,
    value,
  ) => {
    const { questionsList } = this.state;
    const questionToUpdate = questionsList[questionIndex];

    if (questionType === "BOOLEAN_QUESTION") {
      const newOptions = [
        ...questionToUpdate.settings.responses[
          responseIndex
        ].options,
        value,
      ];

      questionToUpdate.hasUpdated = true;
      questionToUpdate.settings.responses[
        responseIndex
      ].options = newOptions;

      const updatedResponse = {};
      const newQuestions = questionsList.filter(
        (question, index) => index !== questionIndex,
      );

      newQuestions.splice(
        questionIndex,
        0,
        questionToUpdate,
      );
      return this.setState({ questionsList: newQuestions });
    }

    if (
      questionType !== "RATING" ||
      (questionType === "RATING" &&
        questionToUpdate.options.length < 10) ||
      questionType !== "BOOLEAN_QUESTION"
    ) {
      const newOptions = [
        ...questionToUpdate.settings.responses[
          responseIndex
        ].options,
        "",
      ];
      questionToUpdate.hasUpdated = true;
      questionToUpdate.settings.responses[
        responseIndex
      ].options = newOptions;

      const newQuestions = questionsList.filter(
        (question, index) => index !== questionIndex,
      );
      newQuestions.splice(
        questionIndex,
        0,
        questionToUpdate,
      );
      this.setState({ questionsList: newQuestions });
    }

    if (
      questionType === "RATING" &&
      questionToUpdate.options.length >=
        ratingRange.maxRating
    ) {
      toast.info(
        "You have reached the maximum options for ratings!",
      );
    }
  };

  addAdvancedSettings = () => {
    this.setState({ displayAdvancedSettings: true });
  };

  handleOptionChange = (
    option,
    optionIndex,
    questionIndex,
    responseIndex,
  ) => {
    const { questionsList } = this.state;
    const questionToUpdate = questionsList[questionIndex];
    const newOptions = questionToUpdate.settings.responses[
      responseIndex
    ].options.filter(
      (option, index) => index !== optionIndex,
    );
    newOptions.splice(optionIndex, 0, option);
    questionToUpdate.hasUpdated = true;

    questionToUpdate.settings.responses[
      responseIndex
    ].options = newOptions;
    const newQuestions = questionsList.filter(
      (question, index) => index !== questionIndex,
    );
    newQuestions.splice(questionIndex, 0, questionToUpdate);
    this.setState({ questionsList: newQuestions });
  };

  handleQuestionChange = (value, prop, questionIndex) => {
    const { questionsList } = this.state;
    const questionToUpdate = questionsList[questionIndex];
    questionToUpdate[prop] = value;
    questionToUpdate.hasUpdated = true;

    const newQuestions = questionsList.filter(
      (question, index) => index !== questionIndex,
    );
    newQuestions.splice(questionIndex, 0, questionToUpdate);
    this.setState({ questionsList: newQuestions });
  };

  handleImageChange = (value, prop, questionIndex) => {
    const { questionsList } = this.state;
    const questionToUpdate = questionsList[questionIndex];
    questionToUpdate["images"] = {
      [prop]: value,
    };
    questionToUpdate.hasUpdated = true;

    const newQuestions = questionsList.filter(
      (question, index) => index !== questionIndex,
    );

    newQuestions.splice(questionIndex, 0, questionToUpdate);
    this.setState({ questionsList: newQuestions });
  };

  handleDeleteLabelImage = (value, prop, questionIndex) => {
    const { questionsList } = this.state;
    const questionToUpdate = questionsList[questionIndex];
    questionToUpdate["meta"] = {
      ...questionToUpdate["meta"],
      [prop]: value,
    };
    questionToUpdate.hasUpdated = true;

    const newQuestions = questionsList.filter(
      (question, index) => index !== questionIndex,
    );

    newQuestions.splice(questionIndex, 0, questionToUpdate);
    this.setState({ questionsList: newQuestions });
  };

  handleResponseChange = (
    value,
    prop,
    questionIndex,
    responseIndex,
  ) => {
    const { questionsList } = this.state;
    const questionToUpdate = questionsList[questionIndex];
    const responseToUpdate =
      questionToUpdate.settings.responses[responseIndex];
    responseToUpdate[prop] = value;
    questionToUpdate.hasUpdated = true;

    const newResponses =
      questionToUpdate.settings.responses.filter(
        (response, index) => index !== responseIndex,
      );
    newResponses.splice(responseIndex, 0, responseToUpdate);

    questionToUpdate.settings.responses = newResponses;

    const newQuestions = questionsList.filter(
      (question, index) => index !== questionIndex,
    );
    newQuestions.splice(questionIndex, 0, questionToUpdate);
    this.setState({ questionsList: newQuestions });
  };

  handleChoicesSettings = (
    settings,
    questionIndex,
    responseIndex,
  ) => {
    const { questionsList } = this.state;
    const questionToUpdate = questionsList[questionIndex];
    questionToUpdate.settings.responses[
      responseIndex
    ].choicesSettings = settings;
    questionToUpdate.hasUpdated = true;

    const newQuestions = questionsList.filter(
      (question, index) => index !== questionIndex,
    );
    newQuestions.splice(questionIndex, 0, questionToUpdate);
    this.setState({ questionsList: newQuestions });
  };

  cloneQuestion = (question, questionIndex) => {
    const { questionsList } = this.state;

    const newQuestion = JSON.parse(
      JSON.stringify(questionsList[questionIndex]),
    );

    delete newQuestion.id;

    this.setState({
      questionsList: [...questionsList, newQuestion],
    });
  };

  removeQuestion = (question, questionIndex) => {
    if (question.id) {
      const questionsToRemove = [
        ...this.state.questionsToRemove,
        question,
      ];
      this.setState({ questionsToRemove });
    }
    const newQuestions = this.state.questionsList.filter(
      (question, index) => index !== questionIndex,
    );
    this.setState({ questionsList: newQuestions });
  };

  removeResponse = (questionIndex, responseIndex) => {
    const questionToUpdate =
      this.state.questionsList[questionIndex];
    questionToUpdate.settings.responses =
      questionToUpdate.settings.responses.filter(
        (response, index) => index !== responseIndex,
      );

    const newQuestions = this.state.questionsList.filter(
      (question, index) => index !== questionIndex,
    );
    newQuestions.splice(questionIndex, 0, questionToUpdate);
    this.setState({ questionsList: newQuestions });
  };

  removeOption = (
    questionIndex,
    responseIndex,
    optionIndex,
  ) => {
    const { questionsList } = this.state;
    const questionToUpdate = questionsList[questionIndex];
    const newOptions = questionToUpdate.settings.responses[
      responseIndex
    ].options.filter(
      (option, index) => index !== optionIndex,
    );
    questionToUpdate.hasUpdated = true;

    questionToUpdate.settings.responses[
      responseIndex
    ].options = newOptions;
    const newQuestions = questionsList.filter(
      (question, index) => index !== questionIndex,
    );
    newQuestions.splice(questionIndex, 0, questionToUpdate);
    this.setState({ questionsList: newQuestions });
  };

  addSource = (questionIndex, responseIndex) => {
    const { questionsList } = this.state;
    const questionToUpdate = questionsList[questionIndex];

    if (
      !questionToUpdate.settings.responses[responseIndex]
        .sources
    ) {
      questionToUpdate.settings.responses[
        responseIndex
      ].sources = [];
    }

    questionToUpdate.settings.responses[
      responseIndex
    ].sources.push({
      actionId: "",
      input_key: "",
    });
    questionToUpdate.hasUpdated = true;

    const newQuestions = questionsList.filter(
      (question, index) => index !== questionIndex,
    );
    newQuestions.splice(questionIndex, 0, questionToUpdate);
    this.setState({ questionsList: newQuestions });
  };

  handleSourcesChange = (
    questionIndex,
    responseIndex,
    sourceIndex,
    prop,
    value,
  ) => {
    const { questionsList } = this.state;
    const questionToUpdate = questionsList[questionIndex];
    questionToUpdate.settings.responses[
      responseIndex
    ].sources[sourceIndex][prop] = value;
    questionToUpdate.hasUpdated = true;

    const newQuestions = questionsList.filter(
      (question, index) => index !== questionIndex,
    );
    newQuestions.splice(questionIndex, 0, questionToUpdate);
    this.setState({ questionsList: newQuestions });
  };

  removeSource = (
    questionIndex,
    responseIndex,
    sourceIndex,
  ) => {
    const { questionsList } = this.state;
    const questionToUpdate = questionsList[questionIndex];
    questionToUpdate.settings.responses[
      responseIndex
    ].sources.splice(sourceIndex, 1);
    questionToUpdate.hasUpdated = true;

    const newQuestions = questionsList.filter(
      (question, index) => index !== questionIndex,
    );
    newQuestions.splice(questionIndex, 0, questionToUpdate);
    this.setState({ questionsList: newQuestions });
  };

  submitQuestions = async (e) => {
    e.preventDefault();
    const {
      questionsList,
      name,
      description,
      questionsToRemove,
    } = this.state;

    const { clubId, questionnaireId } =
      this.props.match.params;
    this.setState({ isLoading: true });

    await Promise.all(
      questionsList.map(async (question, index) => {
        let payload = { ...question };
        payload.displayOrder = index + 1;

        if (question.id && question.hasUpdated) {
          if (
            question.images &&
            question.images.imageFiles?.length > 0
          ) {
            const resp = await handleImageUpload(
              question.images.imageFiles[0].fileItem,
            );

            if (!resp.error) {
              payload.meta.labelImage = resp.secure_url;

              console.log(payload);
            }
          }

          await this.props.updateQuestion(
            clubId,
            questionnaireId,
            question.id,
            payload,
          );
        }

        if (!question.id && question.hasUpdated) {
          await this.props.createQuestion(
            clubId,
            questionnaireId,
            payload,
          );
        }
      }),
    );

    await Promise.all(
      questionsToRemove.map(async (question) => {
        await this.props.deleteQuestion(
          clubId,
          questionnaireId,
          question.id,
          question,
        );
      }),
    );

    const payload = {
      ...this.getQuestionnaire(),
      name,
      description,
    };

    await this.props.updateQuestionnaire(
      clubId,
      questionnaireId,
      payload,
    );
  };

  backHandler = () => {
    const { location, history } = this.props;

    const { clubId } = this.props.match.params;

    if (location.state?.isClubFromQuestionnaire) {
      return history.push(
        `/manage/clubs/${clubId}/${location.state?.clubHandle}/questionnaires`,
      );
    }
    return history.push("/manage/questionnaires");
  };

  render() {
    const { REACT_APP_APPLICATION_NAME } = process.env;
    document.title = `${
      REACT_APP_APPLICATION_NAME || "The Alma Maters Club"
    } - ${getLabelByName(QUESTIONNAIRE)}`;

    const {
      preveiewQuestionnaire,
      isLoading,
      questionsList,
      description,
      displayAdvancedSettings,
      displayMultipleOption,
    } = this.state;

    return (
      <>
        <Navbar pathname="profile" />
        <div className="club-page">
          <div className="container">
            <div className="buttons-row">
              <div id="back-link">
                <div
                  className="d-flex justify-content-center align-items-center"
                  onClick={this.backHandler}
                  style={{ cursor: "pointer" }}>
                  <ArrowBackIos className="arrow-back-icon" />
                  {"  "}
                  {getLabelByName(BACK)}
                </div>
              </div>
            </div>

            <form
              className="questionnairePage"
              onSubmit={(e) => this.submitQuestions(e)}>
              <QuestionnairePageHeader
                isLoading={isLoading}
                preveiewQuestionnaire={
                  preveiewQuestionnaire
                }
                switchPreviewQuestionnaire={
                  this.switchPreviewQuestionnaire
                }
              />

              {!preveiewQuestionnaire && (
                <UnpreviewedQuestionnaire
                  name={this.state.name}
                  description={description}
                  hasDataLoaded={() =>
                    this.dataHasLoaded(
                      this.props.getQuestionnaireData,
                    )
                  }
                  addOption={this.addOption}
                  addQuestion={this.addQuestion}
                  addResponse={this.addResponse}
                  addSource={this.addSource}
                  removeSource={this.removeSource}
                  cloneQuestion={this.cloneQuestion}
                  removeQuestion={this.removeQuestion}
                  removeOption={this.removeOption}
                  renderCheckbox={this.renderCheckbox}
                  removeResponse={this.removeResponse}
                  displayMultipleOption={
                    displayMultipleOption
                  }
                  handleDisplayMultipleOption={
                    this.handleDisplayMultipleOption
                  }
                  generateOptionTypes={
                    this.generateOptionTypes
                  }
                  displayAdvancedSettings={
                    displayAdvancedSettings
                  }
                  addAdvancedSettings={
                    this.addAdvancedSettings
                  }
                  handleChoicesSettings={
                    this.handleChoicesSettings
                  }
                  handleChange={this.handleChange}
                  handleQuestionChange={
                    this.handleQuestionChange
                  }
                  handleResponseChange={
                    this.handleResponseChange
                  }
                  handleOptionChange={
                    this.handleOptionChange
                  }
                  handleSourcesChange={
                    this.handleSourcesChange
                  }
                  handleDeleteLabelImage={
                    this.handleDeleteLabelImage
                  }
                  handleImageChange={this.handleImageChange}
                  questionsList={questionsList}
                />
              )}

              {preveiewQuestionnaire && (
                <PreviewedQuestionnaire
                  questionnaireName={
                    this.getQuestionnaire() &&
                    this.getQuestionnaire().name
                  }
                  questionsList={questionsList}
                  handleCustomMeta={this.handleCustomMeta}
                />
              )}

              <QuestionnaireFooter
                isLoading={isLoading}
                preveiewQuestionnaire={
                  preveiewQuestionnaire
                }
                switchPreviewQuestionnaire={
                  this.switchPreviewQuestionnaire
                }
              />
            </form>
          </div>
          <div className="footer-block">
            <div className="footer-component">
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  }
}

QuestionnairePage.propTypes = {
  getSessionAction: PropTypes.func,
  getQuestionnaire: PropTypes.func,
  createQuestion: PropTypes.func,
  updateQuestion: PropTypes.func,
  deleteQuestion: PropTypes.func,
  updateQuestionnaire: PropTypes.func,
  match: PropTypes.any,
  data: PropTypes.object,
  status: PropTypes.string,
  history: PropTypes.any,
  getQuestionnaireData: PropTypes.object,
};

export const mapStateToProps = (state) => ({
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,

  getQuestionnaireDataError:
    state.getQuestionnaireReducer.getQuestionnaireDataError,
  getQuestionnaireData:
    state.getQuestionnaireReducer.getQuestionnaireData,
  getQuestionnaireStatus:
    state.getQuestionnaireReducer.getQuestionnaireStatus,

  updateQuestionnaireDataError:
    state.updateQuestionnaireReducer
      .updateQuestionnaireDataError,
  updateQuestionnaireData:
    state.updateQuestionnaireReducer
      .updateQuestionnaireData,
  updateQuestionnaireStatus:
    state.updateQuestionnaireReducer
      .updateQuestionnaireStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getSessionAction,
    getQuestionnaire,
    createQuestion,
    updateQuestion,
    updateQuestionnaire,
    deleteQuestion,
  }),
)(QuestionnairePage);
