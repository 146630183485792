import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getLabelByName } from "../../helpers/helperFunctions";
import {
  REMOVE,
  REMOVE_CONFIRMATION_TEXT,
  CONFIRMATION,
  CLOSE,
  REMOVING,
} from "../../helpers/constants";

export default function ConfirmationDialog(props) {
  const {
    onClose,
    selectedValue,
    open,
    handleDeleteParticipant,
    actionToRemove,
    setLoading,
    buttonLoading,
  } = props;

  const handleClose = () => {
    onClose();
  };

  const handleDelete = async () => {
    await handleDeleteParticipant(selectedValue);

    if (setLoading) {
      setLoading(true);
    }
    // onClose();
  };

  const Loader = () => (
    <div
      className="spinner-border spinner-border-sm"
      role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirmation-title">
        <DialogTitle id="confirmation-title">
          {getLabelByName(CONFIRMATION)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`${getLabelByName(REMOVE_CONFIRMATION_TEXT)} ${
              actionToRemove || "member"
            }`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {buttonLoading && (
            <>
              <Button
                onClick={handleClose}
                variant="contained"
                disabled
                style={{
                  outline: "none",
                  background: "transparent",
                  boxShadow: "none",
                  border: "none",
                  color: "#F38F46",
                }}>
                {getLabelByName(CLOSE)}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled
                style={{
                  marginRight: "10px",
                  outline: "none",
                }}>
                {getLabelByName(REMOVING)}
                <Loader />
              </Button>
            </>
          )}
          {!buttonLoading && (
            <>
              <Button
                onClick={handleClose}
                variant="contained"
                style={{
                  outline: "none",
                  background: "transparent",
                  boxShadow: "none",
                  border: "none",
                  color: "#F38F46",
                }}>
                {getLabelByName(CLOSE)}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDelete}
                style={{
                  marginRight: "10px",
                  outline: "none",
                }}>
                {getLabelByName(REMOVE)}
              </Button>
            </>
          )}
          {/* <Button
            onClick={() => handleDelete()}
            style={{ outline: "none" }}
            color="primary">
            {getLabelByName(REMOVE)}
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
