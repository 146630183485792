/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import moment from 'moment';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import { getLabelByName } from '../../../../helpers/helperFunctions';
import { END_DATE, START_DATE, colors } from '../../../../helpers/constants';
import Switch from '../../../generics/Switch';

class DateTimeRangePreview extends Component {
  state = {
    allow_time: false,
  };

  minDate = () => moment(moment.now()).format('YYYY-MM-DDThh:mm');

  getValues = (data, index) => {
    const { handleCustomMeta } = this.props;
    handleCustomMeta({ allow: data.allow }, index);
  };

  componentDidMount = () => {
    const { question } = this.props;

    this.setState({
      allow_time: question.options[0] ? question.options[0].allow_time : false,

    });
  };

  render() {
    const { allow_time } = this.state;
    return (
      <div className="row ml-0">
        {!this.props.previewMode && (
          <FormGroup className="col-md-12">
            <FormControlLabel
              className="p-0 m-0"
              control={(
                <Switch
                  checked={allow_time}
                  onChange={() => this.setState({ allow_time: !allow_time })}
                />
              )}
              label={<span style={{ color: colors.textSecondary }}>Include Time</span>}
              labelPlacement="end"
            />
          </FormGroup>
        )}
        <div className="col-md-6">
          <div className="help-text mb-3">{getLabelByName(START_DATE)}</div>
          <div className="">
            <input
              type={allow_time ? 'datetime-local' : 'date'}
              name="startDate"
              className="form-control"
              id="input-startDate"
              min={this.minDate()}
              style={{ backgroundColor: '#FFFFFF' }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="help-text mb-3">{getLabelByName(END_DATE)}</div>
          <div className="">
            <input
              type={allow_time ? 'datetime-local' : 'date'}
              name="endDate"
              className="form-control"
              id="input-endDate"
              min={this.minDate()}
              style={{ backgroundColor: '#FFFFFF' }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DateTimeRangePreview;
