import {
  GET_INSTITUTES_ERROR, GET_INSTITUTES_SUCCESS,
} from '../actions/types';

const initialState = {
  institutesData: null,
  institutesDataError: null,
  institutesStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INSTITUTES_SUCCESS:
      return {
        ...state,
        institutesData: action.payload,
        institutesDataError: null,
        institutesStatus: 'success',
      };
    case GET_INSTITUTES_ERROR:
      return {
        ...state,
        institutesDataError: action.payload,
        institutesData: null,
        institutesStatus: 'error',
      };
    default:
      return {
        ...state,
        // institutesStatus: null,
      };
  }
};
