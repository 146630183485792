import "./createClubOutput.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import { toast } from "react-toastify";
import imagePlaceholder from "../../../../../assets/icons/image-placeholder.svg";
import {
  getLabelByName,
  isActionError,
  isActionSuccess,
} from "../../../../../helpers/helperFunctions";
import {
  CREATE,
  OUTPUT,
  outputSteps,
  visibilityOptions,
  statuses,
  TITLE,
  DESCRIPTION,
  COMPLETE,
  SAVING,
  EDIT,
  outputParams,
  CONFIGURE_YOUR,
  outputTypes,
} from "../../../../../helpers/constants";
import {
  createOutput,
  updateOutput,
  cloneOutput,
} from "../../../../../redux/actions/contributionsActions";
import { getClubActivities } from "../../../../../redux/actions/campaignsActions";

import BasicInformation from "./BasicInformation/BasicInformation";
import OutputSources from "./OutputSources/OutputSources";
import OutputSettings from "./OutputSettings/OutputSettings";

export class CreateClubOutput extends Component {
  state = {
    selectedFile: null,
    imagePreview: null,
    name: "",
    type: "Default",
    visibility: "PUBLIC",
    cover_image: "",
    description: "",
    title: "",
    status: "ACTIVE",
    settingOptionInputKeys: [],
    settings: [],
    sources: [],
    isSending: false,
    globalSettings: {},
    meta: {},
    currentOutputStep: outputSteps.basicInformation,
  };

  async UNSAFE_componentWillMount() {
    const {
      getClubActivities: getClubActivitiesAction,
      setClearModal,
    } = this.props;
    await getClubActivitiesAction;

    if (setClearModal) {
      setClearModal();
    }

    const addedSettings = [
      { text: getLabelByName(TITLE), value: "title" },
      {
        text: getLabelByName(DESCRIPTION),
        value: "description",
      },
    ];

    addedSettings.forEach((setting) => {
      this.addSetting(setting.value);
    });
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      editOutput,
      cloneOutput,
      handleCloseOutputModal,
      setClearModal,
      clearModal,
    } = nextProps;

    let formattedSettings = [];
    let formattedSources = [
      {
        name: null,
        activityId: null,
        actionId: null,
        settings: {},
      },
    ];

    if (editOutput) {
      formattedSettings = this.formatSettings(
        editOutput?.settings,
      );
      formattedSources = this.formatSources(
        editOutput?.sources,
      );
    }

    if (cloneOutput) {
      formattedSettings = this.formatSettings(
        cloneOutput?.settings,
      );
      formattedSources = this.formatSources(
        cloneOutput?.sources,
      );
    }

    const {
      editOutput: editOutputFromProps,
      cloneOutput: cloneOutputFromProps,
    } = this.props;

    const { globalSettings } = this.state;

    if (editOutput) {
      if (globalSettings[editOutput?.type || "Default"]) {
        Object.keys(
          globalSettings[editOutput?.type || "Default"],
        ).forEach((globalSetting) => {
          formattedSettings.push({
            key: globalSetting,
            value:
              globalSettings[editOutput?.type || "Default"][
                globalSetting
              ],
          });
        });
      }

      if (editOutputFromProps !== nextProps.editOutput) {
        this.setState({
          name: editOutput?.name || "",
          type: editOutput?.type || "Default",
          visibility: editOutput?.visibility || "PUBLIC",
          description:
            editOutput?.settings?.description || "",
          cover_image: editOutput?.cover_image || "",
          globalSettings,
          title: editOutput?.name || "",
          status: editOutput?.status || "ACTIVE",
          settings: editOutput
            ? [...formattedSettings]
            : [],
          sources: editOutput
            ? [...formattedSources]
            : [
                {
                  name: null,
                  activityId: null,
                  actionId: null,
                },
              ],
          isSending: false,
          currentOutputStep: outputSteps.basicInformation,
        });
      }
    } else {
      if (globalSettings[cloneOutput?.type || "Default"]) {
        Object.keys(
          globalSettings[cloneOutput?.type || "Default"],
        ).forEach((globalSetting) => {
          formattedSettings.push({
            key: globalSetting,
            value:
              globalSettings[
                cloneOutput?.type || "Default"
              ][globalSetting],
          });
        });
      }

      if (cloneOutputFromProps !== nextProps.cloneOutput) {
        this.setState({
          name: `${cloneOutput?.name} Copy` || "",
          type: cloneOutput?.type || "Default",
          visibility: cloneOutput?.visibility || "PUBLIC",
          description:
            cloneOutput?.settings?.description || "",
          cover_image: cloneOutput?.cover_image || "",
          globalSettings,
          title: `${cloneOutput?.name} Copy` || "",
          status: cloneOutput?.status || "ACTIVE",
          settings: cloneOutput
            ? [...formattedSettings]
            : [],
          sources: cloneOutput
            ? [...formattedSources]
            : [
                {
                  name: null,
                  activityId: null,
                  actionId: null,
                },
              ],
          isSending: false,
          currentOutputStep: outputSteps.basicInformation,
        });
      }
    }

    if (
      cloneOutput &&
      isActionSuccess(
        nextProps,
        "cloneOutputStatus",
        "cloneOutputData",
      )
    ) {
      // setIsLoading(false);

      toast.success(
        `${cloneOutput?.name} cloned successfully.`,
      );

      this.setState({ isSending: true });

      handleCloseOutputModal();
    }

    if (
      cloneOutput &&
      isActionError(
        nextProps,
        "cloneOutputStatus",
        "cloneOutputData",
      )
    ) {
      toast.error(
        "Oooops! Something went wrong.\nPlease try again!",
      );
      this.setState({ isSending: true });

      handleCloseOutputModal();
    }

    if (editOutputFromProps !== nextProps.editOutput) {
      this.setState({
        name: editOutput?.name || "",
        type: editOutput?.type || "Default",
        visibility: editOutput?.visibility || "PUBLIC",
        description:
          editOutput?.settings?.description || "",
        cover_image: editOutput?.cover_image || "",
        globalSettings,
        title: editOutput?.name || "",
        status: editOutput?.status || "ACTIVE",
        settings: editOutput ? [...formattedSettings] : [],
        sources: editOutput
          ? [...formattedSources]
          : [
              {
                name: null,
                activityId: null,
                actionId: null,
                settings: {},
              },
            ],
        isSending: false,
        currentOutputStep: outputSteps.basicInformation,
      });
    }

    if (clearModal && !editOutput) {
      this.setState({
        selectedFile: null,
        imagePreview: null,
        name: "",
        type: "Default",
        visibility: "PUBLIC",
        cover_image: "",
        description: "",
        title: "",
        status: "ACTIVE",
        settingOptionInputKeys: [],
        settings: [],
        sources: [],
        isSending: false,
        globalSettings: {},
        meta: {},
        currentOutputStep: outputSteps.basicInformation,
      });

      setClearModal();
    }
  }

  setInitialGlobalSettings = (type, settings) => {
    this.setState({
      globalSettings: {
        [type]: settings,
      },
    });
  };

  initializeSources = (type) => {
    const initialSources = [];
    outputParams[type].sources.forEach((source) =>
      initialSources.push({
        name: source,
        activityId: null,
        actionId: null,
        settings: {},
      }),
    );
    this.setState({ sources: initialSources });
  };

  formatSettings = (settings) => {
    const cleanSettings = { ...settings };
    const keys = Object.keys(cleanSettings);
    const values = Object.values(cleanSettings);

    const formattedSettings = values.map(
      (value, index) => ({
        key: keys[index],
        value,
      }),
    );

    return formattedSettings;
  };

  formatSources = (sources) => {
    return Object.values(sources);
  };

  addGlobalSettingsValues = (key, value) => {
    const { settings } = this.state;

    if (settings.length > 0) {
      const globalSetting = settings.find(
        (setting) => setting.key === key,
      ) || {
        key,
      };

      globalSetting.value = value;

      const globalSettingIndex =
        settings.indexOf(globalSetting);

      const newGlobalSettings = settings.filter(
        (setting, index) => {
          return index !== globalSettingIndex;
        },
      );

      newGlobalSettings.splice(
        globalSettingIndex,
        0,
        globalSetting,
      );

      this.setState({ settings: newGlobalSettings });
    }
  };

  addSettingTitle = () => {
    const { settings, name } = this.state;

    if (settings.length > 0) {
      const settingTitle = settings.find(
        (setting) => setting.key === "title",
      );

      settingTitle.value = name;

      const titleIndex = settings.indexOf(settingTitle);

      const newSettings = settings.filter(
        (setting, index) => {
          return index !== titleIndex;
        },
      );

      newSettings.splice(titleIndex, 0, settingTitle);

      this.setState({ settings: newSettings });
    }
  };

  addSettingDescription = () => {
    const { settings, description } = this.state;

    if (settings.length > 0) {
      const settingDescription = settings.find(
        (setting) => setting.key === "description",
      );

      settingDescription.value = description;

      const descriptionIndex = settings.indexOf(
        settingDescription,
      );

      const newSettings = settings.filter(
        (setting, index) => {
          return index !== descriptionIndex;
        },
      );

      newSettings.splice(
        descriptionIndex,
        0,
        settingDescription,
      );

      this.setState({ settings: newSettings });
    }
  };

  submitForm = async (e, editOutput, cloneOutput) => {
    e.preventDefault();

    this.setState({ isSending: true });
    const { props, state } = this;
    const { id: clubId } = props.match.params;
    const {
      name,
      type,
      visibility,
      cover_image,
      description,
      status,
      settings,
      sources,
      selectedFile,
      globalSettings,
    } = state;

    const settingsObject = {};

    if (settings?.length > 0) {
      settings.forEach((setting) => {
        settingsObject[setting.key] = setting.value;
      });
    }

    const sourcesObject = {};

    if (sources?.length > 0) {
      sources.forEach((source) => {
        sourcesObject[source.name] = source;
      });
    }

    if (!selectedFile) {
      const payload = {
        name: name || editOutput?.name || cloneOutput?.name,
        type: type || editOutput?.type || cloneOutput?.type,
        visibility:
          visibility ||
          editOutput?.visibility ||
          cloneOutput?.visibility,
        status:
          status ||
          editOutput?.status ||
          cloneOutput?.status,
        globalSettings:
          globalSettings[
            type || editOutput?.type || cloneOutput?.type
          ] ||
          editOutput?.globalSettings ||
          cloneOutput?.globalSettings,
        settings: {
          ...settingsObject,
          cover_image:
            cover_image ||
            editOutput?.settings.cover_image ||
            cloneOutput?.settings.cover_image,
          description:
            description ||
            editOutput?.settings.description ||
            cloneOutput?.settings.description,
          title:
            name ||
            editOutput?.settings.title ||
            cloneOutput?.settings.title,
        },
        sources: {
          ...sourcesObject,
        },
        meta: editOutput?.meta || cloneOutput?.meta || {},
      };

      if (!editOutput && !cloneOutput) {
        await props.createOutput(clubId, payload);
        props.handleCloseOutputModal();
      }

      if (cloneOutput) {
        await props.cloneOutput(
          cloneOutput?.club.id,
          cloneOutput.id,
          {
            ...payload,
            clubId,
            description,
          },
        );
      } else {
        const updatedPayload = {
          ...editOutput,
          ...cloneOutput,
          ...payload,
        };

        await props.updateOutput(
          clubId,
          updatedPayload.id,
          updatedPayload,
        );
        props.handleCloseOutputModal();
      }
    }

    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("upload_preset", "qtt2g8ao");
      const options = {
        method: "POST",
        body: formData,
      };

      await fetch(
        "https://api.Cloudinary.com/v1_1/ayudigital/auto/upload",
        options,
      )
        .then((res) => res.json())
        .then(async (res) => {
          const { secure_url } = res;

          const payload = {
            name:
              name || editOutput?.name || cloneOutput?.name,
            type:
              type || editOutput?.type || cloneOutput?.type,
            visibility:
              visibility ||
              editOutput?.visibility ||
              cloneOutput?.visibility,
            status:
              status ||
              editOutput?.status ||
              cloneOutput?.status,
            globalSettings:
              globalSettings[
                type ||
                  editOutput?.type ||
                  cloneOutput?.type
              ] ||
              editOutput?.globalSettings ||
              cloneOutput?.globalSettings,
            settings: {
              ...settingsObject,
              cover_image: secure_url,
              description:
                description ||
                editOutput?.settings.description ||
                cloneOutput?.settings.description,
              title:
                name ||
                editOutput?.settings.title ||
                cloneOutput?.settings.title,
            },
            sources: {
              ...sourcesObject,
            },
            meta:
              editOutput?.meta || cloneOutput?.meta || {},
          };

          if (!editOutput && !cloneOutput) {
            await props.createOutput(clubId, payload);
            props.handleCloseOutputModal();
          }

          if (cloneOutput) {
            await props.cloneOutput(
              cloneOutput?.club.id,
              cloneOutput.id,
              { ...payload, clubId, description },
            );
          } else {
            const updatedPayload = {
              ...editOutput,
              ...payload,
            };
            await props.updateOutput(
              clubId,
              updatedPayload.id,
              updatedPayload,
            );
            props.handleCloseOutputModal();
          }
        })
        .catch(() =>
          toast.error(
            "Error submitting club information!\nPlease try again.",
          ),
        );
    }
  };

  addSettingOptionInputKeys = (inputKeys) => {
    this.setState({ settingOptionInputKeys: inputKeys });
  };

  handleRadio = (event) => {
    this.setState({
      status: event.target.value,
    });
  };

  fileChange = (e) => {
    this.setState({ selectedFile: e.target.files[0] });
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.setState({
        cover_image: fileReader.result,
        imagePreview: fileReader.result,
      });
    };
    fileReader.readAsDataURL(e.target.files[0]);
  };

  getImagePreview = () => {
    const { resource, editAction } = this.props;

    const { imagePreview } = this.state;

    let image = imagePlaceholder;

    if (editAction && editAction[resource.input_key]) {
      image = editAction[resource.input_key];
    }

    if (imagePreview) {
      image = imagePreview;
    }
    return image;
  };

  updateSource = (key, value, indexToFind) => {
    const { sources } = this.state;
    const sourceToUpdate = sources.find(
      (source, index) => index === indexToFind,
    );

    sourceToUpdate[key] = value;

    const newSources = sources.filter(
      (source, index) => index !== indexToFind,
    );
    newSources.splice(indexToFind, 0, sourceToUpdate);
    this.setState({ sources: newSources });
  };

  addAnotherSourceHandler = () => {
    const { sources } = this.state;

    const newSourceObj = {
      name: null,
      activityId: null,
      actionId: null,
      settings: {},
    };

    this.setState({ sources: [...sources, newSourceObj] });
  };

  removeItemHandler = (index) => {
    const { sources } = this.state;

    const newSourcesUpdated = [...sources].filter(
      (source, sourceIndex) => sourceIndex !== index,
    );

    this.setState({ sources: newSourcesUpdated });
  };

  addGlobalSetting = (key) => {
    const { settings } = this.state;
    const newSetting = {
      key,
      value: false,
    };

    settings.push(newSetting);

    this.setState({ settings });
  };

  addSetting = (key) => {
    const { settings } = this.state;
    const newSetting = {
      key,
      value: "",
    };

    const newSettings = [...settings, newSetting];
    this.setState({ settings: newSettings });
  };

  updateSetting = (key, value, indexToFind, inputKey) => {
    const { settings } = this.state;

    let settingToUpdate;
    if (inputKey !== "cover_image") {
      settingToUpdate = settings.find(
        (setting) => setting.key === inputKey,
      ) || { key: inputKey };
    }

    if (settingToUpdate) {
      if (value) {
        settingToUpdate[key] = value;
      } else {
        settingToUpdate[key] = "";
      }
      const newSettings = settings.filter(
        (setting) => setting.key !== inputKey,
      );

      newSettings.splice(indexToFind, 0, settingToUpdate);

      const { sources } = this.state;

      const sourcesCopy = [...sources];

      if (
        inputKey === "listing_title" ||
        inputKey === "listing_subtitle" ||
        inputKey === "listing_image"
      ) {
        const listingsToUpdate = sourcesCopy.find(
          (source) => {
            this.setState({ sourceType: source.name });
            return source.name === "listings";
          },
        );

        if (listingsToUpdate) {
          listingsToUpdate.settings = {
            ...listingsToUpdate.settings,
            [inputKey]: value,
          };
        } else {
          listingsToUpdate.settings = "";
        }

        this.setState({ sources: sourcesCopy });
      } else if (
        inputKey === "profile_title" ||
        inputKey === "profile_subtitle" ||
        inputKey === "profile_picture"
      ) {
        const profileSettingsToUpdate = sourcesCopy.find(
          (source) => {
            this.setState({ sourceType: source.name });
            return source.name === "profile";
          },
        );

        if (profileSettingsToUpdate) {
          profileSettingsToUpdate.settings = {
            ...profileSettingsToUpdate.settings,
            [inputKey]: value,
          };
        } else {
          profileSettingsToUpdate.settings = "";
        }

        this.setState({ sources: sourcesCopy });
      } else {
        this.setState({ settings: newSettings });
      }
    }
  };

  handleGlobalSettingsOnChange = (event) => {
    const { globalSettings, type } = this.state;

    this.setState({
      globalSettings: {
        [type]: {
          ...globalSettings[type],
          [event.target.name]: event.target.checked,
        },
      },
    });

    this.addGlobalSettingsValues(
      event.target.name,
      event.target.checked,
    );
  };

  render() {
    const {
      openOutputModal: open,
      handleCloseOutputModal: handleClose,
      clubActivitiesData,
      cloneOutput,
      editOutput,
    } = this.props;

    const {
      selectedFile,
      name,
      type,
      visibility,
      cover_image,
      description,
      title,
      status,
      settings,
      sources,
      currentOutputStep,
      settingOptionInputKeys,
      isSending,
      globalSettings,
      sourceType,
    } = this.state;

    const payload = {
      name,
      type,
      visibility,
      cover_image,
      description,
      title,
      status,
      settings,
      sources,
      globalSettings,
    };

    // eslint-disable-next-line no-nested-ternary
    const modalTitle = editOutput
      ? `${getLabelByName(EDIT)} ${name}`
      : cloneOutput
      ? `Copy of "${cloneOutput?.name}"`
      : `${getLabelByName(CREATE)} ${
          payload.name
            ? payload.name
            : getLabelByName(OUTPUT)
        }`;

    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="output-modal"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className="output-modal-content">
            <div className="output-modal-title p-x-50">
              <h1>{modalTitle}</h1>

              <p>{`${getLabelByName(CONFIGURE_YOUR)} ${
                outputTypes[type]
              }`}</p>
            </div>

            <div className="output-step-indicator">
              <hr className="first-line" />

              <div
                aria-hidden
                onClick={() =>
                  this.setState({
                    currentOutputStep:
                      outputSteps.basicInformation,
                  })
                }
                className={`output-basic-information-indicator ${
                  currentOutputStep ===
                    outputSteps.basicInformation &&
                  "active-step"
                }`}>
                <span>1</span>
                <p>{outputSteps.basicInformation}</p>
              </div>

              <hr className="second-line" />

              <div
                aria-hidden
                onClick={() =>
                  payload.name && payload.description
                    ? this.setState({
                        currentOutputStep:
                          outputSteps.sources,
                      })
                    : undefined
                }
                data-disabled={!payload.name}
                className={`output-sources-indicator ${
                  currentOutputStep ===
                    outputSteps.sources && "active-step"
                }`}>
                <span>2</span>
                <p>{outputSteps.sources}</p>
              </div>
              <hr className="third-line" />
              <div
                aria-hidden
                onClick={() =>
                  sources.length > 0 &&
                  sources[0].activityId &&
                  sources[0].actionId &&
                  sources[0].name
                    ? this.setState({
                        currentOutputStep:
                          outputSteps.settings,
                      })
                    : undefined
                }
                className={`output-settings-indicator ${
                  currentOutputStep ===
                    outputSteps.settings && "active-step"
                }`}>
                <span>3</span>
                <p>{outputSteps.settings}</p>
              </div>
              <hr className="fourth-line" />
            </div>

            <form
              action=""
              style={{ paddingBottom: "68px" }}>
              {currentOutputStep ===
                outputSteps.basicInformation && (
                <>
                  <BasicInformation
                    selectedFile={selectedFile}
                    payload={payload}
                    editOutput={editOutput}
                    cloneOutput={cloneOutput}
                    statuses={statuses}
                    updateSetting={this.updateSetting}
                    visibilityOptions={visibilityOptions}
                    handleRadio={(event) =>
                      this.setState({
                        status: event.target.value,
                      })
                    }
                    handleChange={(event) => {
                      this.setState({
                        [event.target.name]:
                          event.target.value,
                      });
                    }}
                    fileChange={this.fileChange}
                    getImagePreview={this.getImagePreview()}
                  />

                  <hr className="border-separator" />
                  <div className="create-outputs-buttons">
                    <input
                      type="button"
                      value="Cancel"
                      className="cancel-button"
                      onClick={handleClose}
                    />
                    <input
                      type="button"
                      className={`${
                        !payload.name ||
                        !payload.description
                          ? "next-button disabled"
                          : "next-button"
                      }`}
                      value="Next"
                      disabled={
                        !payload.name ||
                        !payload.description
                      }
                      onClick={() =>
                        this.setState({
                          currentOutputStep:
                            outputSteps.sources,
                        })
                      }
                    />
                  </div>
                </>
              )}

              {currentOutputStep ===
                outputSteps.sources && (
                <>
                  <div className="create-output-sources p-x-50 min-height-500">
                    <OutputSources
                      payload={payload}
                      sources={sources}
                      addSettingOptionInputKeys={
                        this.addSettingOptionInputKeys
                      }
                      addGlobalSettingsValues={
                        this.addGlobalSettingsValues
                      }
                      addGlobalSetting={
                        this.addGlobalSetting
                      }
                      removeItemHandler={
                        this.removeItemHandler
                      }
                      addAnotherSourceHandler={
                        this.addAnotherSourceHandler
                      }
                      globalSettings={globalSettings}
                      handleGlobalSettingsOnChange={
                        this.handleGlobalSettingsOnChange
                      }
                      updateSource={this.updateSource}
                      clubActivitiesData={
                        clubActivitiesData
                      }
                      editOutput={this.props.editOutput}
                      cloneOutput={this.props.cloneOutput}
                      setInitialGlobalSettings={
                        this.setInitialGlobalSettings
                      }
                      initializeSources={
                        this.initializeSources
                      }
                    />
                  </div>
                  <hr className="border-separator" />
                  <div className="create-outputs-buttons">
                    <input
                      type="button"
                      value="Cancel"
                      className="cancel-button"
                      onClick={handleClose}
                    />
                    <input
                      type="button"
                      className={`${
                        sources.length > 0 &&
                        sources[0].activityId &&
                        sources[0].actionId &&
                        sources[0].name
                          ? "next-button"
                          : "next-button disabled"
                      }`}
                      value="Next"
                      onClick={() => {
                        return sources.length > 0 &&
                          sources[0].activityId &&
                          sources[0].actionId &&
                          sources[0].name
                          ? this.setState({
                              currentOutputStep:
                                outputSteps.settings,
                            })
                          : undefined;
                      }}
                    />
                  </div>
                </>
              )}

              {currentOutputStep ===
                outputSteps.settings && (
                <>
                  <div className="create-output-settings p-x-50 min-height-500">
                    <OutputSettings
                      addSettingTitle={this.addSettingTitle}
                      addSettingDescription={
                        this.addSettingDescription
                      }
                      settingOptionInputKeys={
                        settingOptionInputKeys
                      }
                      payload={payload}
                      sources={sources}
                      sourceType={sourceType}
                      settings={settings}
                      updateSetting={this.updateSetting}
                      clubActivitiesData={
                        clubActivitiesData
                      }
                    />
                  </div>

                  <hr className="border-separator" />

                  <div className="create-outputs-buttons">
                    <input
                      type="button"
                      value="Cancel"
                      className="cancel-button"
                      onClick={handleClose}
                    />

                    <button
                      type="button"
                      onClick={(e) =>
                        this.submitForm(
                          e,
                          editOutput,
                          cloneOutput,
                        )
                      }
                      className="btn btn-lg submit-btn">
                      {isSending ? (
                        <>
                          <span>
                            {getLabelByName(SAVING)}
                            {"  "}
                          </span>
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status">
                            <span className="sr-only">
                              Loading...
                            </span>
                          </div>
                        </>
                      ) : (
                        getLabelByName(COMPLETE)
                      )}
                    </button>
                  </div>
                </>
              )}
            </form>
          </div>
        </Fade>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  clubActivitiesData:
    state.clubCampaignsReducer.clubCampaignsData,
  getQuestionnairesData:
    state.getQuestionnairesReducer.getQuestionnairesData,
  cloneOutputStatus:
    state.cloneOutputReducer.cloneOutputStatus,
  cloneOutputData: state.cloneOutputReducer.cloneOutputData,
  cloneOutputDataError:
    state.cloneOutputReducer.cloneOutputDataError,
});

const mapDispatchToProps = {
  createOutput,
  updateOutput,
  cloneOutput,
  getClubActivities,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CreateClubOutput);
