import {
  GET_INSTITUTES_ERROR,
  GET_INSTITUTES_SUCCESS,
  GET_INSTITUTE_ERROR,
  GET_INSTITUTE_SUCCESS,
  GET_CLUB_MEMBERS_ERROR,
  GET_CLUB_MEMBERS_SUCCESS,
  JOIN_CLUB_SUCCESS,
  JOIN_CLUB_ERROR,
  SEARCH_CLUBS_NAME_ERROR,
  SEARCH_CLUBS_NAME_SUCCESS,
  SEARCH_CLUBS_DESCRIPTION_ERROR,
  SEARCH_CLUBS_DESCRIPTION_SUCCESS,
  CREATE_CLUB_SUCCESS,
  CREATE_CLUB_ERROR,
  UPDATE_CLUB_SUCCESS,
  UPDATE_CLUB_ERROR,
  GET_MEMBER_SUCCESS,
  GET_MEMBER_ERROR,
  GET_USER_CLUBS_ERROR,
  GET_USER_CLUBS_SUCCESS,
  GET_INSTITUTE_LOADING,
  EDIT_CLUB_MEMBERS_SUCCESS,
  EDIT_CLUB_MEMBERS_ERROR,
  EDIT_CLUB_MEMBERS_LOADING,
  DELETE_CLUB_MEMBERS_SUCCESS,
  DELETE_CLUB_MEMBERS_LOADING,
  DELETE_CLUB_MEMBERS_ERROR,
} from "./types";
import apiCall from "../../helpers/apiCall";
import { getHeaders } from "../../helpers/getHeaders";

const instituteType = (type, payload) => ({
  type,
  payload,
});

export const getAdminClubs =
  (headers = getHeaders(null, "/admin/clubs")) =>
  async (dispatch) => {
    try {
      const response = await apiCall.get("/admin/clubs", {
        headers,
      });

      localStorage.setItem(
        "clubsData",
        JSON.stringify(response.data.data),
      );

      dispatch(
        instituteType(
          GET_INSTITUTES_SUCCESS,
          response.data,
        ),
      );

      return response.data;
    } catch (err) {
      dispatch(instituteType(GET_INSTITUTES_ERROR, err));
    }
  };

export const getUserClubs =
  (headers = getHeaders(null, "/user/clubs")) =>
  (dispatch) => {
    apiCall
      .get("/user/clubs", { headers })
      .then((response) => {
        localStorage.setItem(
          "userClubsData",
          JSON.stringify(response.data.data),
        );
        dispatch(
          instituteType(
            GET_USER_CLUBS_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(instituteType(GET_USER_CLUBS_ERROR, err));
      });
  };

export const getInstitute =
  (handle, headers = getHeaders(null, `/club/${handle}`)) =>
  (dispatch) => {
    dispatch(instituteType(GET_INSTITUTE_LOADING, true));
    apiCall
      .get(`/club/${handle}`, { headers })
      .then((response) => {
        dispatch(
          instituteType(
            GET_INSTITUTE_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(instituteType(GET_INSTITUTE_ERROR, err));
      });
  };

export const getInstituteMembers =
  (
    id,
    headers = getHeaders(
      null,
      `/members?q={"clubId":${id}}`,
    ),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.get(
        `/members?q={"clubId":${id}}`,
        { headers },
      );

      dispatch(
        instituteType(
          GET_CLUB_MEMBERS_SUCCESS,
          response.data,
        ),
      );

      return response.data;
    } catch (err) {
      dispatch(instituteType(GET_CLUB_MEMBERS_ERROR, err));
    }
  };

export const editClubMembers =
  (
    id,
    payload,
    headers = getHeaders(payload, `/member/${id}`),
  ) =>
  (dispatch) => {
    dispatch(
      instituteType(EDIT_CLUB_MEMBERS_LOADING, true),
    );

    apiCall
      .put(`/member/${id}`, payload, { headers })
      .then((response) => {
        dispatch(
          instituteType(
            EDIT_CLUB_MEMBERS_SUCCESS,
            response.data,
          ),
        );
        dispatch(
          instituteType(EDIT_CLUB_MEMBERS_LOADING, false),
        );
      })
      .catch((err) => {
        dispatch(
          instituteType(EDIT_CLUB_MEMBERS_ERROR, err),
        );
        dispatch(
          instituteType(EDIT_CLUB_MEMBERS_LOADING, false),
        );
      });
  };

export const deleteClubMember =
  (id, headers = getHeaders(null, `/member/${id}`)) =>
  (dispatch) => {
    dispatch(
      instituteType(DELETE_CLUB_MEMBERS_LOADING, true),
    );

    apiCall
      .delete(`/member/${id}`, { headers })
      .then((response) => {
        dispatch(
          instituteType(
            DELETE_CLUB_MEMBERS_SUCCESS,
            response.data,
          ),
        );
        dispatch(
          instituteType(DELETE_CLUB_MEMBERS_LOADING, false),
        );
      })
      .catch((err) => {
        dispatch(
          instituteType(DELETE_CLUB_MEMBERS_ERROR, err),
        );
        dispatch(
          instituteType(DELETE_CLUB_MEMBERS_LOADING, false),
        );
      });
  };

export const joinClub =
  (
    id,
    payload,
    multiple = false,
    headers = getHeaders(
      payload,
      `/club/${id}/membership${multiple ? "s" : ""}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .post(
        `/club/${id}/membership${multiple ? "s" : ""}`,
        payload,
        {
          headers,
        },
      )
      .then((response) => {
        dispatch(
          instituteType(JOIN_CLUB_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(instituteType(JOIN_CLUB_ERROR, err));
      });
  };

export const searchClubsByName =
  (
    searchParams,
    headers = getHeaders(
      null,
      `/user/clubs?q=${searchParams}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/user/clubs?q=${searchParams}`, { headers })
      .then((response) => {
        dispatch(
          instituteType(
            SEARCH_CLUBS_NAME_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          instituteType(SEARCH_CLUBS_NAME_ERROR, err),
        );
      });
  };

export const searchAdminClubsByName =
  (
    searchParams,
    headers = getHeaders(
      null,
      `/admin/clubs?q=${searchParams}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/admin/clubs?q=${searchParams}`, { headers })
      .then((response) => {
        dispatch(
          instituteType(
            SEARCH_CLUBS_NAME_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          instituteType(SEARCH_CLUBS_NAME_ERROR, err),
        );
      });
  };

export const searchClubDescription =
  (
    searchParams,
    headers = getHeaders(
      null,
      `/user/clubs?q=${searchParams}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/user/clubs?q=${searchParams}`, { headers })
      .then((response) => {
        dispatch(
          instituteType(
            SEARCH_CLUBS_DESCRIPTION_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          instituteType(
            SEARCH_CLUBS_DESCRIPTION_ERROR,
            err,
          ),
        );
      });
  };

export const createClub =
  (
    payload,
    headers = getHeaders(payload, "/manage/club"),
  ) =>
  (dispatch) => {
    apiCall
      .post("/manage/club", payload, { headers })
      .then((response) => {
        dispatch(
          instituteType(CREATE_CLUB_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(instituteType(CREATE_CLUB_ERROR, err));
      });
  };

export const updateClub =
  (
    id,
    payload,
    headers = getHeaders(payload, `/manage/club/${id}`),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.put(
        `/manage/club/${id}`,
        payload,
        { headers },
      );

      dispatch(
        instituteType(UPDATE_CLUB_SUCCESS, response.data),
      );

      return response.data;
    } catch (err) {
      dispatch(instituteType(UPDATE_CLUB_ERROR, err));
    }
  };

export const getMember =
  (
    id,
    headers = getHeaders(null, `/members?q={"id":${id}}`),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/members?q={"id":${id}}`, { headers })
      .then((response) => {
        dispatch(
          instituteType(GET_MEMBER_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(instituteType(GET_MEMBER_ERROR, err));
      });
  };
