import {
    ACTIVITIES_CONTRIBUTIONS_COMMENT_SEARCH,
    ACTIVITIES_CONTRIBUTIONS_SEARCH,
    ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_CRUD,
    ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_SEARCH,
} from "../../actions/types"

const initialSearchState = {
    contributions: []
};

const initialState = {
    id: 0,
};

export const participantContributionCRUDReducer = (state = initialState, action) => {
    if (action.type !== ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_CRUD) {
        return state;
    }

    const apiResponse = action.payload;
    if (apiResponse.status === 200) {
        return {
            ...state,
            ...apiResponse.data
        }
    } else {
        return {
            ...state,
            error: apiResponse.error.message
        }
    }
}

export const participantContributionsSearchReducer = (state = initialSearchState, action) => {
    if (action.type !== ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_SEARCH) {
        return state;
    }

    const apiResponse = action.payload;
    if (apiResponse.status === 200) {
        return {
            ...state,
            contributions: apiResponse.data
        }
    } else {
        return {
            ...state,
            error: apiResponse.error.message
        }
    }
}

export const activityContributionsSearchReducer = (state = initialSearchState, action) => {
    if (action.type !== ACTIVITIES_CONTRIBUTIONS_SEARCH) {
        return state;
    }

    const apiResponse = action.payload;
    if (apiResponse.status === 200) {
        return {
            ...state,
            contributions: apiResponse.data
        }
    } else {
        return {
            ...state,
            error: apiResponse.error.message
        }
    }
}

export const activityContributionCommentsSearchReducer = (state = [], action) => {
    if (action.type !== ACTIVITIES_CONTRIBUTIONS_COMMENT_SEARCH) {
        return state;
    }

    const apiResponse = action.payload;
    if (apiResponse.status === 200) {
        return apiResponse.data;
    } else {
        return {
            error: apiResponse.error.message,
        }
    }
}