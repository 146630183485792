import {
  CREATE_POST_SUCCESS, CREATE_POST_ERROR,
} from '../actions/types';

const initialState = {
  createPostData: null,
  createPostDataError: null,
  createPostStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_POST_SUCCESS:
      return {
        ...state,
        createPostData: action.payload,
        createPostDataError: null,
        createPostStatus: 'success',
      };
    case CREATE_POST_ERROR:
      return {
        ...state,
        createPostDataError: action.payload,
        createPostData: null,
        createPostStatus: 'error',
      };
    default:
      return {
        ...state,
        createPostStatus: null,
      };
  }
};
