import { Co2Sharp } from "@mui/icons-material";
import apiCall from "../../../helpers/apiCall";
import { getHeaders } from "../../../helpers/getHeaders";

import {
    ACTIVITIES_PARTICIPANT_ACTION_CRUD,
    ACTIVITIES_PARTICIPANT_ACTION_SEARCH,
} from "../types";

export const createParticipantAction = (
    activityId,
    participantId,
    actionId,
    participantAction
) => async (dispatch) => {
    const apiEndpoint = `/activity/${activityId}/participant/${participantId}/action/${actionId}`;

    try {
        const response = await apiCall.post(apiEndpoint, participantAction,{
            headers: getHeaders(
                participantAction,
                apiEndpoint,
            )
        })
        dispatch({
            type: ACTIVITIES_PARTICIPANT_ACTION_CRUD,
            payload: response.data,
        });
        
    } catch (error) {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_ACTION_CRUD,
            payload: error,
        });
    }
}

export const retrieveParticipantAction = (
    activityId,
    participantId,
    participantActionId
) => (dispatch) => {
    const apiEndpoint = `/activity/${activityId}/participant/${participantId}/action/${participantActionId}`;
    apiCall.get(
        apiEndpoint,
        {
            headers: getHeaders(
                null,
                apiEndpoint,
            )
        }
    ).then((response) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_ACTION_CRUD,
            payload: response.data,
        });
    }).catch((err) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_ACTION_CRUD,
            payload: err,
        });
    });
}

export const updateParticipantAction = (
    activityId,
    participantId,
    actionId, 
    participantAction
) => async (dispatch) => {
    const apiEndpoint = `/activity/${activityId}/participant/${participantId}/action/${actionId}`;
    try {
        const response = await apiCall.put(apiEndpoint, participantAction,{
            headers: getHeaders(
                participantAction,
                apiEndpoint,
            )
        })
        dispatch({
            type: ACTIVITIES_PARTICIPANT_ACTION_CRUD,
            payload: response.data,
        });
        
    } catch (error) {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_ACTION_CRUD,
            payload: error,
        });
    }
}

export const deleteParticipantAction = (
    activityId,
    participantId,
    participantActionId
) => (dispatch) => {
    const apiEndpoint = `/activity/${activityId}/participant/${participantId}/action/${participantActionId}`;
    apiCall.delete(
        apiEndpoint,
        {
            headers: getHeaders(
                null,
                apiEndpoint,
            )
        }
    ).then((response) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_ACTION_CRUD,
            payload: response.data,
        })
    }).catch((err) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_ACTION_CRUD,
            payload: err,
        })
    })
}

export const searchParticipantActions = (
    activityId,
    participantId,
) => (dispatch) => {
    const apiEndpoint = `/activity/${activityId}/participant/${participantId}/actions`;
    apiCall.get(
        apiEndpoint,
        {
            headers: getHeaders(
                null,
                apiEndpoint
            ),
        }
    ).then((response) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_ACTION_SEARCH,
            payload: response.data
        });
    }).catch((err) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_ACTION_SEARCH,
            payload: err
        })
    })
}