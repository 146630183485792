/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "typeface-roboto";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import store from "./redux/store";
import "./fonts/Digital-Numbers.ttf";
import "./styles/floating-labels.css";
import "./styles/styles.css";
import "./styles/notfound.css";
import "./styles/landing.css";
import "./styles/navbar.css";
import "./styles/profile.css";
import "./styles/search.css";
import "./styles/club.css";
import "./styles/article.css";
import "./styles/manage.css";
import "./styles/create-club.css";
import "./styles/club-details.css";
import "./styles/feed.css";
import "./styles/create-article.css";
import "./styles/campaign.css";
import "./styles/questionnaire.css";
import "./styles/contributions.css";
import "./styles/calendar.css";
import "./styles/card-output.css";
import "./styles/newLanding.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const theme = createTheme({
  palette: {
    primary: {
      light: "#F38F46",
      main: "#F38F46",
      dark: "#F38F46",
      contrastText: "#fff",
    },
  },
});

const Root = () => (
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
