import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { Add, ArrowForwardIos } from "@material-ui/icons";
import {
  ADD_NEW_PARTICIPANT,
  NO_OUTPUTS,
  OUTPUTS,
  VIEW_ALL,
} from "../../../../helpers/constants";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import "./club-outputs-section.css";
import OutputCard from "../../../OutputCard";

export class ClubOutputsSection extends Component {
  render() {
    const { match, getOutputsData: clubOutputs } = this.props;

    return (
      <div className="club-activities">
        <div className="header-title">
          <h1>
            {getLabelByName(OUTPUTS)}
            <span style={{ color: "#A3A3A3", paddingLeft: "3px" }}>
              ({clubOutputs?.data?.length})
            </span>
          </h1>
          <span>
            <Link
              to={`${window.location.pathname}/outputs`}
              style={{ color: "#C6A177" }}
            >
              {getLabelByName(VIEW_ALL)}
              <ArrowForwardIos />
            </Link>
          </span>
        </div>
        <div className="body">
          <div className="activities-details">
            {clubOutputs?.data?.length > 0 ? (
              clubOutputs?.data
                ?.slice(0, 5)
                .map((output, index) => (
                  <OutputCard key={index} output={output} />
                ))
            ) : (
              <p>{getLabelByName(NO_OUTPUTS)}</p>
            )}
          </div>
        </div>
        <div className="footer">
          {match.path === "/manage/clubs/:id/:handle" && (
            <Button
              style={{ textTransform: "none" }}
              color="primary"
              onClick={() => this.props.SetIsModalOpen()}
            >
              <Add />
              {getLabelByName(ADD_NEW_PARTICIPANT)}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

ClubOutputsSection.propTypes = {
  getParticipants: PropTypes.func,
  getInstitute: PropTypes.func,
  activityData: PropTypes.object,
  clubData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  getOutputsData: state.getOutputsReducer.getOutputsData,
});

const mapDispatchToProps = {};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ClubOutputsSection);
