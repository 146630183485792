/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import { LaunchRounded } from "@material-ui/icons";
import slugify from "slugify";
import Loader from "../Loader";
import {
  getAllClubsFromLocalStorage,
  getLabelByName,
} from "../../helpers/helperFunctions";
import campaignIconOrange from "../../assets/icons/campaign-dark-orange.svg";
import promotionIcon from "../../assets/promotionIcon.png";
import { getSessionAction } from "../../redux/actions/authActions";
import { participateCampaign } from "../../redux/actions/campaignsActions";
import userIcon from "../../assets/icons/user.svg";
import {
  colors,
  JOINING,
  VISIT,
  COMING_SOON,
} from "../../helpers/constants";
import "../../styles/templates.css";
import {
  joinClub,
  getInstituteMembers,
} from "../../redux/actions/institutesActions";
import {
  createPost,
  postReaction,
  getReactions,
  deleteReaction,
} from "../../redux/actions/postActions";
import campaignIcon from "../../assets/manage-campaigns.svg";
import clubsIcon from "../../assets/manage-clubs.svg";
import timeIcon from "../../assets/icons/time.svg";
import likingIcon from "../../assets/reactions/like.svg";
import commentIcon from "../../assets/icons/comment.svg";
import commentIconOrange from "../../assets/icons/comment-orange.svg";
import CommentForm from "./CommentForm";
import ReactionsMenu from "./ReactionsMenu";
import SharePost from "./ShareResource";
import PromoTemplatePost from "./PromoTemplatePost";

function PromotionTemplate({
  postItem,
  joinClubAction,
  profileData,
  handleLoading,
  joinClubFeedback,
  getSessionAction,
  getReactions,
  updateSelectedReactions,
  deleteReaction,
  postReaction,
  openComments,
  createPost,
  getReactionsData,
  postReactionStatus,
  postReactionData,
  participateCampaign,
  participateCampaignData,
  handleLinkItemCountUpdate,
  getInstituteMembers: getInstituteMembersAction,
  clubMembersData,
  handleIfVerified
}) {
  const [clubInfo, setClubInfo] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [clubsData, setClubsData] = useState(
    getAllClubsFromLocalStorage(),
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [reactions, setReactions] = useState({});
  const [clickedPostId, setClickedPostId] = useState(null);
  const [displayComments, setDisplayComments] = useState(
    [],
  );
  const commentHash = window.location.hash.slice(1);

  const joinHandler = async () => {
    setIsLoading(true);
    const { typeId } = postItem.content;
    const payload = { userId: profileData?.data?.id };
    switch (postItem.meta.promoType) {
      case "club":
        await joinClubAction(postItem.clubId, {
          userId: profileData?.data?.id,
        });
        break;
      case "activity":
        await participateCampaign(typeId, payload);
        break;
      case "contribution":
        toast.info("coming Soon");
        break;

      default:
        break;
    }
  };

  const isMemberHandler = () => {
    const ClubStatus = Object.keys(
      profileData?.data?.clubs,
    ).includes(postItem?.clubId?.toString());
    const ActivityStatus = Object.keys(
      profileData?.data?.activities,
    ).includes(postItem?.content?.typeId?.toString());
    if (postItem.meta.promoType === "club") {
      setIsMember(ClubStatus);
    }
    if (postItem.meta.promoType === "activity") {
      setIsMember(ActivityStatus);
    }
  };

  const handleClubInfo = () => {
    if (clubsData.length !== 0) {
      const data = clubsData.filter(
        (values) =>
          Number(values.id) === Number(postItem.clubId),
      );
      setClubInfo(data[0]);
    }
  };

  const handleFeedback = async () => {
    if (
      (joinClubFeedback &&
        joinClubFeedback.status === 200) ||
      (participateCampaignData &&
        participateCampaignData.status === 200)
    ) {
      toast.success("Successfully joined!");
      setIsLoading(false);
      setIsMember(true);
      await getSessionAction();
      handleLinkItemCountUpdate();
    }
    if (
      (joinClubFeedback &&
        joinClubFeedback.status === 500) ||
      (participateCampaignData &&
        participateCampaignData.status === 500)
    ) {
      setIsLoading(false);
      toast.error(
        `Error joining the ${postItem.meta.promoType}! \nPlease try again!`,
      );
    }
  };

  const getCallToActionLabel = () => {
    if (
      (postItem.content.actionType === "Join" &&
        !isMember) ||
      postItem.content.actionType !== "Join"
    ) {
      return postItem.content.callToAction;
    }
    if (
      postItem.content.actionType === "Join" &&
      isMember
    ) {
      return `${getLabelByName(VISIT)} ${
        postItem.meta.promoType
      }`;
    }
    return "";
  };

  const fetchReactions = async (postId) => {
    setClickedPostId(postId);
    if (!reactions[postId]) {
      await getReactions(postId);
    }
  };

  const createPostReaction = async (
    postId,
    reaction,
    myReaction,
  ) => {
    if (myReaction && myReaction === reaction) {
      await deleteReaction(postId, reaction);
      delete reactions[postId][profileData.data.id.toString()];
    }
    if (!myReaction) {
      await postReaction({}, postId, reaction);
    }
  };

  const formatReactionsHandler = () => {
    if (
      clickedPostId !== null &&
      getReactionsData &&
      getReactionsData.data &&
      Object.keys(getReactionsData.data).length > 0 &&
      !reactions[clickedPostId]
    ) {
      const newReactions = {
        ...reactions,
        [clickedPostId]: getReactionsData.data,
      };
      setReactions(newReactions);
    }

    if (
      postReactionStatus &&
      postReactionData &&
      postReactionData.data &&
      clickedPostId !== null
    ) {
      if (!reactions[clickedPostId]) {
        const newReactions = {
          ...reactions,
          [clickedPostId]: {},
        };
        setReactions(newReactions);
      }
      if (!reactions[clickedPostId][profileData.data.id.toString()]) {
        const newReactions = {
          ...reactions,
          [clickedPostId]: {
            [profileData.data.id.toString()]: postReactionData.data,
          },
        };
        setReactions(newReactions);
      }
    }
  };

  const onToggleComments = (postId) => {
    if (!displayComments.includes(postId)) {
      const newDisplayComments = [
        ...displayComments,
        postId,
      ];
      setDisplayComments(newDisplayComments);
    } else {
      const newDisplayComments = [
        ...displayComments,
      ].filter((item) => item !== postId);
      setDisplayComments(newDisplayComments);
    }
  };

  const updateReactionsModal = (postId) => {
    updateSelectedReactions(reactions[postId]);
  };

  const getPostClub = () => {
    const postClub =
      clubsData !== null &&
      clubsData.filter(
        (club) => club.id === postItem?.clubId?.toString(),
      )[0];

    return postClub;
  };

  const getPostLink = () => {
    if (getPostClub() === undefined) return undefined;
    const { handle } = getPostClub();
    const link = `/club/${postItem.clubId}/${handle}/post/${postItem.id}`;
    return link;
  };

  const scrollToComment = () => {
    // const commentHash = window.location.hash.slice(1);
    const element = document.getElementById(commentHash);
    if (element)
      element.scrollIntoView({ behavior: "smooth" });
  };
  const getCallToLink = () => {
    let url;
    switch (postItem.meta.promoType) {
      case "club":
        url = `/club/${postItem.clubId}/${clubInfo.handle}`;
        break;

      case "activity":
        url = `/campaign/${postItem.content.typeId}/${clubInfo.handle}/`;
        break;

      case "output":
        url = `/club/${postItem.clubId}/${
          clubInfo.handle
        }/output/${postItem.content.typeId}/${slugify(
          postItem.content.name,
          { lower: true },
        )}`;
        break;

      case "contribution":
        url = `/activity/${postItem.content.typeId}/participant/${profileData.data.activities[postItem.content.typeId].participantId}/console`;
        break;

      case "article":
        url = `/club/${postItem.content.clubId}/${clubInfo.handle}/post/${postItem.content.typeId}`;
        break;

      default:
        url = "/home";
        break;
    }
    return url;
  };

  const getClubMembersAction = async () => {
    await getInstituteMembersAction(postItem.clubId);
  };

  useEffect(() => {
    if (
      openComments &&
      !displayComments.includes(postItem.id)
    ) {
      displayComments.push(parseInt(openComments, 10));
      setDisplayComments(displayComments);
    }
    scrollToComment();

    getClubMembersAction();
  }, []);

  useEffect(() => {
    isMemberHandler();
    handleClubInfo();
    if (isLoading) {
      handleFeedback();
    }
    formatReactionsHandler();
  }, [
    joinClubFeedback,
    getReactionsData,
    postReactionStatus,
    postReactionData,
    participateCampaignData,
  ]);

  return (
    <Card className="custom-promotion-card">
      <Card.Body className="custom-promotion-card__content">
        <div className="custom-promotion-card__container">
          <div className="custom-promotion-card__image-container">
            {postItem.content.coverImage !== "" ? (
              <Card.Img
                className="custom-promotion-card__image"
                src={postItem.content.coverImage}
                style={{
                  overflow: "hidden",
                  height: "100%",
                }}
              />
            ) : (
              <div className="card-img">
                <img
                  src={
                    postItem.meta.promoType === "club"
                      ? clubsIcon
                      : campaignIcon
                  }
                  alt="club-avatar"
                />
              </div>
            )}
          </div>
          <div className="custom-promotion-card__content">
            <Card.Title className="custom-promotion-card__content__title">
              {postItem.content.caption}
              {getPostLink() === undefined
                ? ""
                : !openComments && (
                    <Link to={getPostLink()}>
                      <LaunchRounded
                        titleAccess="Expand promo"
                        style={{
                          color: colors.textSecondary,
                        }}
                      />
                    </Link>
                  )}
            </Card.Title>
            <Card.Text className="custom-promotion-card__content__body">
              {postItem.content.summary}
            </Card.Text>
          </div>
        </div>

        <div className="custom-promotion-card__bottom">
          {postItem && (
            <PromoTemplatePost
              isMember={isMember}
              joinHandler={joinHandler}
              getCallToLink={getCallToLink}
              postItem={postItem}
              clubInfo={clubInfo}
            />
          )}
          <div className="activity-stats justify-content-between flex-column-reverse flex-xl-row">
            <div className="d-flex align-items-center mt-2">
              <div
                className="activity-stat-item dropup"
                id="myDropdown">
                <div
                  className="dropdown-toggle activity-stat-icon handicon"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => handleIfVerified(()=> fetchReactions(postItem.id))}>
                  <img
                    src={likingIcon}
                    alt="like-icon"
                    width="25px"
                  />
                </div>
                {Object.values(postItem.reactions)
                  .map((reaction) =>
                    parseInt(reaction.total, 10),
                  )
                  .reduce((a, b) => a + b, 0)}
                <ReactionsMenu
                  reactions={reactions}
                  resourceItem={postItem}
                  createPostReaction={createPostReaction}
                  updateSelectedReactions={
                    updateReactionsModal
                  }
                />
              </div>
              <div
                className="activity-stat-item"
                style={{
                  color:
                    displayComments.includes(postItem.id) &&
                    colors.primaryColor,
                }}
                onClick={() => handleIfVerified(()=> onToggleComments(postItem.id))}>
                <div className="activity-stat-icon">
                  <img
                    src={
                      displayComments.includes(postItem.id)
                        ? commentIconOrange
                        : commentIcon
                    }
                    alt="comment-icon"
                    width="25px"
                  />
                </div>
                {postItem.totalResponses}
              </div>
              <div className="activity-stat-item">
                <div className="activity-stat-icon">
                  <img
                    src={timeIcon}
                    alt="time-icon"
                    width="25px"
                  />
                </div>
                {moment(postItem.dateCreated * 1000).format(
                  "hh:mm, DD/MM/YY",
                ) || "hh:mm, DD/MM/YY"}
              </div>
              {getPostLink() === undefined ? (
                ""
              ) : (
                <SharePost
                  postId={postItem.id}
                  postLink={getPostLink()}
                />
              )}
            </div>
            <div className="custom-promotion-card__content__calltoaction">
              <button
                type="button"
                className="btn btn-sm submit-btn"
                disabled={
                  isLoading ||
                  postItem.meta.promoType === "contribution"
                }
                onClick={
                  postItem.content.actionType === "Join" &&
                  !isMember
                    ? () => joinHandler()
                    : () => {
                        window.location.href =
                          getCallToLink();
                      }
                }>
                {isLoading && (
                  <>
                    <span>
                      {getLabelByName(JOINING)}
                      {"  "}
                    </span>
                    <Loader />
                  </>
                )}
                {postItem.meta.promoType === "contribution"
                  ? getLabelByName(COMING_SOON)
                  : !isLoading && getCallToActionLabel()}
              </button>
            </div>
          </div>
        </div>
      </Card.Body>
      {(openComments ||
        displayComments.includes(postItem.id)) && (
        <div
          className="comments-sections m-3"
          style={{
            borderTop:
              postItem.totalResponses <= 0 && "unset",
            paddingTop:
              postItem.totalResponses <= 0 && "unset",
          }}>
          <div className="comments-list">
            {postItem.responses.length > 0 &&
              postItem.responses.map((comment) => (
                <>
                  <div
                    id={`commentId-${comment.id}`}
                    className="post-comment-block">
                    <div className="post-comment-item-author d-none d-lg-block d-xl-block">
                      <div className="post-avatar">
                        {comment.author.profile.picture && (
                          <img
                            className="img-fluid"
                            src={
                              comment.author.profile.picture
                            }
                            alt="club-avatar"
                          />
                        )}
                        {!comment.author.profile
                          .picture && (
                          <div className="member-initials">
                            {comment.author.profile
                              .firstName[0] +
                              comment.author.profile
                                .lastName[0]}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      id={
                        `commentId-${comment.id}` ===
                        commentHash
                          ? "highlighted-comment"
                          : ""
                      }
                      className="post-comment-item">
                      <div className="activity-content">
                        <div className="post-item-author">
                          <div className="post-avatar d-lg-none d-sm-block">
                            {comment.author.profile
                              .picture && (
                              <img
                                className="img-fluid"
                                src={
                                  comment.author.profile
                                    .picture
                                }
                                alt="club-avatar"
                              />
                            )}
                            {!comment.author.profile
                              .picture && (
                              <div className="member-initials">
                                {comment.author.profile
                                  .firstName[0] +
                                  comment.author.profile
                                    .lastName[0]}
                              </div>
                            )}
                          </div>
                          <div className="post-author-name">
                            {`${comment.author.profile.firstName} ${comment.author.profile.lastName}`}
                            <br />
                            <span className="post-item-member">
                              {`${comment.author.roles[0]
                                .slice(0, 1)
                                .toUpperCase()}${comment.author.roles[0].slice(
                                comment.author.roles[0]
                                  .length -
                                  (comment.author.roles[0]
                                    .length -
                                    1),
                              )}`}
                            </span>
                          </div>
                        </div>
                        <p className="activity-content-text pb-3">
                          {comment.content.body}
                        </p>
                      </div>
                      <div className="activity-stats">
                        <div className="activity-stat-item dropup">
                          <div
                            className="dropdown-toggle activity-stat-icon handicon"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            onClick={() =>
                              fetchReactions(comment.id)
                            }>
                            <img
                              src={likingIcon}
                              alt="like-icon"
                              width="25px"
                            />
                          </div>
                          {Object.values(comment.reactions)
                            .map((reaction) =>
                              parseInt(reaction.total, 10),
                            )
                            .reduce((a, b) => a + b, 0)}
                          <ReactionsMenu
                            reactions={reactions}
                            resourceItem={comment}
                            createPostReaction={
                              createPostReaction
                            }
                            updateSelectedReactions={
                              updateReactionsModal
                            }
                          />
                        </div>
                        <div
                          className="activity-stat-item"
                          style={{
                            color:
                              displayComments.includes(
                                comment.id,
                              ) && colors.primaryColor,
                          }}
                          onClick={() =>
                            onToggleComments(comment.id)
                          }>
                          <div className="activity-stat-icon">
                            <img
                              src={
                                displayComments.includes(
                                  comment.id,
                                )
                                  ? commentIconOrange
                                  : commentIcon
                              }
                              alt="comment-icon"
                              width="25px"
                            />
                          </div>
                          {comment.totalResponses}
                        </div>
                        <div className="activity-stat-item">
                          <div className="activity-stat-icon">
                            <img
                              src={timeIcon}
                              alt="time-icon"
                              width="25px"
                            />
                          </div>
                          {moment(
                            comment.dateCreated * 1000,
                          ).format("hh:mm, DD MMM YYYY") ||
                            "hh:mm, DD MMM YYYY"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="comment-replies-block">
                    {(openComments ||
                      displayComments.includes(
                        comment.id,
                      )) && (
                      <div>
                        <div className="comments-list">
                          {comment.responses.length > 0 &&
                            comment.responses.map(
                              (reply) => (
                                <div
                                  id={`commentId-${reply.id}`}
                                  className="post-comment-block">
                                  <div className="post-comment-item-author d-none d-lg-block d-xl-block">
                                    <div className="post-avatar">
                                      {reply.author.profile
                                        .picture && (
                                        <img
                                          className="img-fluid"
                                          src={
                                            reply.author
                                              .profile
                                              .picture
                                          }
                                          alt="club-avatar"
                                        />
                                      )}
                                      {!reply.author.profile
                                        .picture && (
                                        <div className="member-initials">
                                          {reply.author
                                            .profile
                                            .firstName[0] +
                                            reply.author
                                              .profile
                                              .lastName[0]}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    id={
                                      `commentId-${reply.id}` ===
                                      commentHash
                                        ? "highlighted-comment"
                                        : ""
                                    }
                                    className="post-comment-item">
                                    <div className="activity-content">
                                      <div className="post-item-author">
                                        <div className="post-avatar d-lg-none d-sm-block">
                                          {reply.author
                                            .profile
                                            .picture && (
                                            <img
                                              className="img-fluid"
                                              src={
                                                reply.author
                                                  .profile
                                                  .picture
                                              }
                                              alt="club-avatar"
                                            />
                                          )}
                                          {!reply.author
                                            .profile
                                            .picture && (
                                            <div className="member-initials">
                                              {reply.author
                                                .profile
                                                .firstName[0] +
                                                reply.author
                                                  .profile
                                                  .lastName[0]}
                                            </div>
                                          )}
                                        </div>
                                        <div className="post-author-name">
                                          {`${reply.author.profile.firstName} ${reply.author.profile.lastName}`}
                                          <br />
                                          <span className="post-item-member">
                                            {`${reply.author.roles[0]
                                              .slice(0, 1)
                                              .toUpperCase()}${reply.author.roles[0].slice(
                                              reply.author
                                                .roles[0]
                                                .length -
                                                (reply
                                                  .author
                                                  .roles[0]
                                                  .length -
                                                  1),
                                            )}`}
                                          </span>
                                        </div>
                                      </div>
                                      <p className="activity-content-text pb-3">
                                        {reply.content.body}
                                      </p>
                                    </div>
                                    <div className="activity-stats">
                                      <div className="activity-stat-item dropup">
                                        <div
                                          className="dropdown-toggle activity-stat-icon handicon"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                          onClick={() =>
                                            fetchReactions(
                                              reply.id,
                                            )
                                          }>
                                          <img
                                            src={likingIcon}
                                            alt="like-icon"
                                            width="25px"
                                          />
                                        </div>
                                        {Object.values(
                                          reply.reactions,
                                        )
                                          .map((reaction) =>
                                            parseInt(
                                              reaction.total,
                                              10,
                                            ),
                                          )
                                          .reduce(
                                            (a, b) => a + b,
                                            0,
                                          )}
                                        <ReactionsMenu
                                          reactions={
                                            reactions
                                          }
                                          resourceItem={
                                            reply
                                          }
                                          createPostReaction={
                                            createPostReaction
                                          }
                                          updateSelectedReactions={
                                            updateReactionsModal
                                          }
                                        />
                                      </div>
                                      <div className="activity-stat-item">
                                        <div className="activity-stat-icon">
                                          <img
                                            src={timeIcon}
                                            alt="time-icon"
                                            width="25px"
                                          />
                                        </div>
                                        {moment(
                                          reply.dateCreated *
                                            1000,
                                        ).format(
                                          "hh:mm, DD MMM YYYY",
                                        ) ||
                                          "hh:mm, DD MMM YYYY"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ),
                            )}
                        </div>

                        <div className="post-comment post-comment-reply">
                          <CommentForm
                            resource={comment}
                            createPost={createPost}
                            commentType="Reply"
                            clubMembersData={
                              clubMembersData
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ))}
          </div>
          <div className="post-comment">
            <CommentForm
              resource={postItem}
              createPost={createPost}
              commentType="Comment"
              clubMembersData={clubMembersData}
            />
          </div>
        </div>
      )}
    </Card>
  );
}

PromotionTemplate.propTypes = {
  postItem: PropTypes.shape({
    id: PropTypes.number,
    clubId: PropTypes.number,
    images: PropTypes.shape({
      image_1: PropTypes.string,
    }),
    meta: PropTypes.shape({
      resourceId: PropTypes.string,
      resourceType: PropTypes.string,
      promoType: PropTypes.string,
    }),
    content: PropTypes.shape({
      summary: PropTypes.string,
      body: PropTypes.string,
      caption: PropTypes.string,
      callToAction: PropTypes.string,
      actionType: PropTypes.string,
      typeId: PropTypes.string,
      name: PropTypes.string,
      clubId: PropTypes.string,
    }),
    reactions: PropTypes.object,
    totalResponses: PropTypes.number,
    responses: PropTypes.array,
    dateCreated: PropTypes.any,
  }).isRequired,
  joinClubAction: PropTypes.func,
  handleLoading: PropTypes.func,

  profileData: PropTypes.shape({
    id: PropTypes.string,
    userId: PropTypes.string,
    clubs: PropTypes.shape({}),
    activities: PropTypes.shape({}),
    data: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  joinClubFeedback: PropTypes.shape({
    status: PropTypes.string,
  }),
  openComments: PropTypes.number,
  getReactionsData: PropTypes.object,
  postReactionStatus: PropTypes.object,
  postReactionData: PropTypes.object,
  getReactions: PropTypes.func,
  updateSelectedReactions: PropTypes.any,
  deleteReaction: PropTypes.func,
  postReaction: PropTypes.func,
  createPost: PropTypes.func,
  participateCampaignData: PropTypes.any,
  handleLinkItemCountUpdate: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  joinClubFeedback: state.joinClubReducer.joinClubData,
  getReactionsData:
    state.getReactionsReducer.getReactionsData,
  postReactionStatus:
    state.postReactionReducer.postReactionStatus,
  postReactionData: state.joinClubReducer.postReactionData,
  participateCampaignData:
    state.participateCampaignReducer
      .participateCampaignData,
  clubMembersData: state.clubMembersReducer.clubMembersData,
});

export default connect(mapStateToProps, {
  joinClubAction: joinClub,
  getSessionAction,
  getReactions,
  deleteReaction,
  postReaction,
  createPost,
  participateCampaign,
  getInstituteMembers,
})(PromotionTemplate);
