/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment";
import { LaunchRounded } from "@material-ui/icons";

import {
  createPost,
  postReaction,
  getReactions,
  deleteReaction,
} from "../../redux/actions/postActions";
import ImageGridGallery from "../ImageGridGallery";
import timeIcon from "../../assets/icons/time.svg";
import arrowCircleIcon from "../../assets/icons/arrow-circled-orange-light.svg";
import likingIcon from "../../assets/reactions/like.svg";
import commentIcon from "../../assets/icons/comment.svg";
import commentIconOrange from "../../assets/icons/comment-orange.svg";
import VideoPlayer from "../generics/VideoPlayer";
import FileDownload from "../generics/FileDownload";
import ShareResource from "../generics/ShareResource";
import {
  getAllClubsFromLocalStorage,
  getLabelByName,
  getProfileDataFromLocalStorage,
} from "../../helpers/helperFunctions";
import {
  colors,
  IN_WORD,
  POSTED,
  SEE_SIMILAR_POSTS,
} from "../../helpers/constants";
import { getInstituteMembers } from "../../redux/actions/institutesActions";
import Comments from "./Comments";
import ReactionsMenu from "../generics/ReactionsMenu";

const FeedPost = (props) => {
  const [state, setState] = useState({
    listReactions: (postId) => fetchReactions(postId),
    displayComments: [],
    clickedPostId: null,
    profileData: getProfileDataFromLocalStorage(),
    clubsData: getAllClubsFromLocalStorage(),
    commentHash: null,
  });

  const reactions = {};

  const setNewState = (newState) => {
    setState((prevState) => ({
      ...prevState,
      newState,
    }));
  };

  async function fetchReactions(postId) {
    setState((prevState) => ({
      ...prevState,
      clickedPostId: postId,
    }));

    if (!reactions[postId]) {
      await props.getReactions(postId);
    }
  }

  useEffect(() => {
    const { displayComments } = state;
    if (
      props.openComments &&
      !displayComments.includes(props.postItem.id)
    ) {
      displayComments.push(
        parseInt(props.openComments, 10),
      );

      setNewState(displayComments);
    }

    const {
      getInstituteMembers: getInstituteMembersAction,
      match: {
        params: { clubId, id },
      },
    } = props;

    getInstituteMembersAction(clubId || id);

    const commentHash = window.location.hash.slice(1);
    const element = document.getElementById(commentHash);
    if (element)
      element.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    const { profileData, clickedPostId } = state;

    const {
      getReactionsData,
      postReactionData,
      postReactionStatus,
    } = props;

    if (
      clickedPostId !== null &&
      getReactionsData &&
      getReactionsData.data &&
      Object.keys(getReactionsData.data).length > 0 &&
      !reactions[clickedPostId]
    ) {
      reactions[clickedPostId] = getReactionsData.data;
    }

    if (
      postReactionStatus &&
      postReactionData &&
      postReactionData.data &&
      clickedPostId !== null
    ) {
      if (!reactions[clickedPostId]) {
        reactions[clickedPostId] = {};
      }

      if (!reactions[clickedPostId][profileData.data.id]) {
        reactions[clickedPostId][profileData.data.id] =
          postReactionData.data;
      }
    }
  }, [
    props.getReactionsData,
    props.postReactionData,
    props.postReactionStatus,
  ]);

  const createPostReaction = async (
    postId,
    reaction,
    myReaction,
  ) => {
    const { profileData } = state;

    if (myReaction && myReaction === reaction) {
      await props.deleteReaction(postId, reaction);
      delete reactions[postId][profileData.data.id];
    }
    if (!myReaction) {
      await props.postReaction({}, postId, reaction);
    }
  };

  const onToggleComments = (postId) => {
    const { displayComments } = state;

    if (!displayComments.includes(postId)) {
      displayComments.push(postId);
      setNewState(displayComments);
    } else {
      displayComments.splice(
        displayComments.indexOf(postId),
      );
      setNewState(displayComments);
    }
  };

  const updateSelectedReactions = (postId) => {
    props.updateSelectedReactions(reactions[postId]);
  };

  const getPostClub = () => {
    const { postItem } = props;
    const { clubsData } = state;
    const postClub =
      clubsData !== null &&
      clubsData.filter(
        (club) => club.id === postItem.clubId.toString(),
      )[0];

    return postClub;
  };

  const getPostLink = () => {
    const { postItem } = props;
    if (getPostClub() === undefined) return undefined;
    const { handle } = getPostClub();
    if (getPostClub() === undefined) return undefined;
    const link = `/club/${postItem.clubId}/${handle}/post/${postItem.id}`;
    return link;
  };

  const { postItem, openComments, clubMembersData } = props;
  const { displayComments } = state;
  const postClub = getPostClub();
  const commentHash = window.location.hash.slice(1);
  const firstName =
    postItem.author.profile?.firstName || "Unknown";
  const lastName =
    postItem.author.profile?.lastName || "User";

  return (
    <div className="card activity-card">
      <div className="card-body">
        <div className="activity-content-row">
          <div className="activity-content">
            <div className="d-flex justify-content-between">
              <div className="post-item-author">
                <div className="post-avatar">
                  {postItem.author.profile.picture && (
                    <img
                      className="img-fluid"
                      src={postItem.author.profile.picture}
                      alt="club-avatar"
                    />
                  )}
                  {!postItem?.author?.profile?.picture && (
                    <div className="member-initials">
                      {`${firstName[0]} ${lastName[0]}`}
                    </div>
                  )}
                </div>
                <div className="post-author-name">
                  {`${firstName} ${lastName}`}
                  <br />
                  {postClub === undefined ? (
                    ""
                  ) : (
                    <Link
                      to={{
                        pathname: `/club/${postItem.clubId}/${postClub.handle}`,
                        hash: `#postid${postItem.id}`,
                      }}
                      style={{ textDecoration: "none" }}>
                      <span className="post-item-member">
                        {getLabelByName(POSTED)}{" "}
                        {getLabelByName(IN_WORD)}{" "}
                        {postClub.name?.length > 50
                          ? `${postClub.name.slice(
                              0,
                              50,
                            )}...`
                          : postClub.name}
                      </span>
                    </Link>
                  )}
                </div>
              </div>

              {getPostLink() === undefined
                ? ""
                : !props.openComments && (
                    <Link to={getPostLink()}>
                      <LaunchRounded
                        titleAccess="Expand post"
                        style={{
                          color: colors.textSecondary,
                        }}
                      />
                    </Link>
                  )}
            </div>

            <p className="activity-content-text pb-3">
              {postItem.content.body}
            </p>
            <div className="activity-content-images pb-3">
              {Object.values(postItem.images).length >
                0 && (
                <ImageGridGallery
                  images={Object.values(postItem.images)}
                />
              )}
            </div>
            <div className="activity-content-videos pb-3">
              {Object.values(postItem.videos).length > 0 &&
                Object.values(postItem.videos).map(
                  (video) => <VideoPlayer video={video} />,
                )}
            </div>
            <div className="activity-content-files pb-3">
              {Object.values(postItem.files).length > 0 &&
                Object.values(postItem.files).map(
                  (file) => <FileDownload file={file} />,
                )}
            </div>
          </div>

          <div className="activity-stats justify-content-between">
            <div className="d-flex">
              <div
                className="activity-stat-item dropup"
                id="myDropdown">
                <div
                  className="dropdown-toggle activity-stat-icon handicon"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => props.handleIfVerified(()=> state.listReactions(postItem.id))}>
                  <img
                    src={likingIcon}
                    alt="like-icon"
                    width="25px"
                  />
                </div>
                {Object.values(postItem.reactions)
                  .map((reaction) =>
                    parseInt(reaction.total, 10),
                  )
                  .reduce((a, b) => a + b, 0)}
                <ReactionsMenu
                  reactions={reactions}
                  resourceItem={postItem}
                  createPostReaction={createPostReaction}
                  updateSelectedReactions={
                    updateSelectedReactions
                  }
                />
              </div>
              <div
                className="activity-stat-item"
                style={{
                  color:
                    displayComments.includes(postItem.id) &&
                    colors.primaryColor,
                }}
                onClick={() => props.handleIfVerified(()=> onToggleComments(postItem.id))
                }>
                <div className="activity-stat-icon">
                  <img
                    src={
                      displayComments.includes(postItem.id)
                        ? commentIconOrange
                        : commentIcon
                    }
                    alt="comment-icon"
                    width="25px"
                  />
                </div>
                {postItem.totalResponses}
              </div>
              <div className="activity-stat-item">
                <div className="activity-stat-icon">
                  <img
                    src={timeIcon}
                    alt="time-icon"
                    width="25px"
                  />
                </div>
                {moment
                  .unix(postItem.dateCreated)
                  .format("hh:mm, DD/MM/YY") ||
                  "hh:mm, DD/MM/YY"}
              </div>
              {getPostLink() === undefined ? (
                ""
              ) : (
                <ShareResource
                  resourceType="Post"
                  postId={postItem.id}
                  postLink={getPostLink()}
                />
              )}
            </div>
            {postClub === undefined ? (
              ""
            ) : (
              <Link
                to={`/club/${postItem.clubId}/${postClub.handle}/feed`}
                style={{ textDecoration: "none" }}>
                <div
                  className="activity-stat-item mr-0"
                  style={{ color: colors.primaryColor }}>
                  {getLabelByName(SEE_SIMILAR_POSTS)}
                  <img
                    className="ml-2"
                    src={arrowCircleIcon}
                    alt="arrow-icon"
                    width="16px"
                  />
                </div>
              </Link>
            )}
          </div>

          {(openComments ||
            displayComments.includes(postItem.id)) && (
            <Comments
              postItem={postItem}
              reactions={reactions}
              createPostReaction={createPostReaction}
              updateSelectedReactions={
                updateSelectedReactions
              }
              displayComments={displayComments}
              commentHash={commentHash}
              createPost={props.createPost}
              clubMembersData={clubMembersData}
              openComments={openComments}
              listReactions={state.listReactions}
              onToggleComments={onToggleComments}
            />
          )}
        </div>
      </div>
    </div>
  );
};

FeedPost.propTypes = {
  postItem: PropTypes.any,
  postReaction: PropTypes.func,
  deleteReaction: PropTypes.func,
  getReactions: PropTypes.func,
  createPost: PropTypes.func,
  updateSelectedReactions: PropTypes.func,
  openComments: PropTypes.any,

  postReactionData: PropTypes.object,
  postReactionStatus: PropTypes.string,

  getReactionsData: PropTypes.any,
  getInstituteMembers: PropTypes.func,
};

export const mapStateToProps = (state) => ({
  postReactionDataError:
    state.postReactionReducer.postReactionDataError,
  postReactionData:
    state.postReactionReducer.postReactionData,
  postReactionStatus:
    state.postReactionReducer.postReactionStatus,

  deleteReactionDataError:
    state.deleteReactionReducer.deleteReactionDataError,
  deleteReactionData:
    state.deleteReactionReducer.deleteReactionData,
  deleteReactionStatus:
    state.deleteReactionReducer.deleteReactionStatus,

  getReactionsData:
    state.getReactionsReducer.getReactionsData,
  getReactionsDataError:
    state.getReactionsReducer.getReactionsDataError,
  getReactionsStatus:
    state.getReactionsReducer.getReactionsStatus,

  createPostData: state.createPostReducer.createPostData,
  createPostDataError:
    state.createPostReducer.createPostDataError,
  createPostStatus:
    state.createPostReducer.createPostStatus,

  clubMembersData: state.clubMembersReducer.clubMembersData,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    postReaction,
    deleteReaction,
    getReactions,
    createPost,
    getInstituteMembers,
  }),
)(FeedPost);
