import { UPDATE_PARTICIPANT_ACTION_SUCCESS, UPDATE_PARTICIPANT_ACTION_ERROR } from '../actions/types';

const initialState = {
  updateParticipantActionData: null,
  updateParticipantActionDataError: null,
  updateParticipantActionStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PARTICIPANT_ACTION_SUCCESS:
      return {
        ...state,
        updateParticipantActionData: action.payload,
        updateParticipantActionDataError: null,
        updateParticipantActionStatus: 'success',
      };
    case UPDATE_PARTICIPANT_ACTION_ERROR:
      return {
        ...state,
        updateParticipantActionDataError: action.payload,
        updateParticipantActionData: null,
        updateParticipantActionStatus: 'error',
      };
    default:
      return {
        ...state,
        updateParticipantActionStatus: null,
      };
  }
};
