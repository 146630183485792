import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MainContent from "./MainContent";
import LessonsSidebar from "./LessonsSidebar";
import Loading from "../../../generics/Loading/Loading";
import {
  getLessons,
  getPostLessons,
  postParticipantAction,
  updateParticipantAction,
  getParticipantActions,
  getParticipantAllActions,
} from "../../../../redux/actions/campaignsActions";
import { submitContribution } from "../../../../redux/actions/contributionsActions";
import { isLastItemInArray } from "./helpers";

import "./contributeLessons.scss";
import LessonsOverlay from "./LessonsOverlay";

const ContributeToLessons = ({
  history,
  location,
  profileData,
  match: { params },
  getLessons: getLessonsReq,
  getPostLessons: getPostLessonsReq,
  getPostLessonsData,
  submitContribution: submitContributionReq,
  postParticipantAction: postParticipantActionReq,
  updateParticipantAction: updateParticipantActionReq,
  getParticipantActions: getParticipantActionsReq,
}) => {
  // =================== STATE ===================
  const [isOpen, toggleSidebar] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isOverlayOpen, toggleLessonsOverlay] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [lessons, setLessons] = useState([]);
  const [lessonsData, setLessonsData] = useState({
    article: [],
    progress: {},
  });
  const [currLesson, setCurrLesson] = useState({});
  const [participantActionData, setParticipantActionData] =
    useState([]);
  // =================== STATE ===================

  const { id: activityId, actionId } = params;

  const getParticipantId = () => {
    const activityData =
      location?.state?.from?.activityData;

    const isAnonymousActivity =
      activityData?.settings?.anonymous;

    if (isAnonymousActivity && location.state) {
      return location.state?.from?.participantId;
    }

    const activities = profileData?.data?.activities;

    const participantId = Object.values(activities).find(
      (activity) => activity.activityId === activityId,
    )?.participantId;

    return participantId;
  };

  const getLessonProgess = () => {
    const noOfCompletedLessons =
      lessonsData.article?.filter(
        (article) => article.isComplete,
      )?.length;

    const totalNumberOfLessons =
      lessonsData.article?.length;

    const result =
      (noOfCompletedLessons / totalNumberOfLessons) * 100;

    return result ? result.toFixed() : 0;
  };

  const onSelectLesson = (articleId) => {
    const result = lessonsData.article.find(
      (article) => article?.id === articleId,
    );
    window.scrollTo(0, 0);
    setCurrLesson(result);
  };

  const routeToNextLesson = (currLesson) => {
    const lastIndex = lessons.length - 1;
    // If Lesson is not last item in array, move to next lesson
    if (lessons[lastIndex].id !== currLesson.lessonId) {
      const index = lessons.findIndex(
        (lesson) =>
          parseInt(lesson.id) ===
          parseInt(currLesson.lessonId),
      );

      const result = lessonsData.article.find(
        (article) =>
          parseInt(article?.id) ===
          parseInt(lessons[index + 1].articleId),
      );

      setCurrLesson(result);

      toggleLessonsOverlay(false);
    }
  };

  const participantId = getParticipantId();

  const createParticipantAction = async () => {
    const payload = {
      data: {},
      meta: {},
      status: "ACTIVE",
      visibility: "PUBLIC",
    };

    const activityData =
      location?.state?.from?.activityData;

    const isAnonymousActivity =
      activityData?.settings?.anonymous;

    const response = await postParticipantActionReq(
      activityId,
      participantId,
      actionId,
      payload,
      isAnonymousActivity,
    );

    setLoading(false);

    if (response.status === 500) {
      toast.error(response.error.message);

      // history.push(location?.state?.from?.where);
    }

    setParticipantActionData([response.data]);
  };

  const updateLessonsProgress = (data) => {
    const lessonsProgress = JSON.parse(
      data?.data?.progress ?? "{}",
    );

    setLessonsData((prevState) => ({
      article: prevState.article.map((article) => ({
        ...article,
        isComplete:
          lessonsProgress[article?.lessonId]?.status ===
          "complete"
            ? true
            : false,
      })),
      progress: lessonsProgress,
    }));

    setCurrLesson((prevState) => ({
      ...prevState,
      isComplete:
        lessonsProgress[prevState?.lessonId]?.status ===
        "complete"
          ? true
          : false,
    }));
  };

  const getActionData = async () => {
    const query2 = `?q={"actionId":${actionId}}`;

    const activityData =
      location?.state?.from?.activityData;

    const isAnonymousActivity =
      activityData?.settings?.anonymous;

    const response = await getParticipantActionsReq(
      activityId,
      participantId,
      query2,
      isAnonymousActivity,
    );

    setParticipantActionData(response.data);

    if (response.data && response.data?.length > 0) {
      updateLessonsProgress(response.data[0]);
    }

    if (
      response.status === 200 &&
      response?.data?.length === 0
    ) {
      createParticipantAction();
    } else {
      setLoading(false);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    if (!searchValue) return;

    const regex = new RegExp(
      searchValue.toLowerCase(),
      "g",
    );

    const filteredData = lessonsData.article.find(
      (article) =>
        article?.content?.title?.toLowerCase().match(regex),
    );

    onSelectLesson(filteredData?.id);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;

    setSearchValue(value);
  };

  useEffect(() => {
    const fetchLessons = async () => {
      // setLoading(true);

      const response = await getLessonsReq(
        activityId,
        actionId,
      );

      setLessons(
        response?.data?.sort(
          (a, b) =>
            parseInt(a.displayOrder) -
            parseInt(b.displayOrder),
        ),
      );
    };

    fetchLessons();
    getPostLessonsReq(location.state.from.clubId);
  }, []);

  useEffect(() => {
    if (getPostLessonsData && getPostLessonsData.data) {
      const articles = getPostLessonsData.data;

      const result = lessons.map((lesson) => {
        return {
          ...articles.find(
            (article) =>
              article?.id === parseInt(lesson.articleId),
          ),
          lessonId: lesson.id,
        };
      });

      setLessonsData((prevState) => ({
        progress: prevState.progress,
        article: result,
      }));

      setCurrLesson(result[0]);

      getActionData();
    }
  }, [getPostLessonsData]);

  const handleSubmitContribution = async () => {
    window.scrollTo(0, 0);
    setIsSubmitting(true);
    const currentParticipantAction =
      participantActionData.find(
        (participantAction) =>
          participantAction.actionId === actionId,
      );

    const payload = {
      participantActionId: currentParticipantAction?.id,
    };

    const activityData =
      location?.state?.from?.activityData;

    const isAnonymousActivity =
      activityData?.settings?.anonymous;

    const response = await submitContributionReq(
      activityId,
      participantId,
      payload,
      isAnonymousActivity,
    );

    setIsSubmitting(false);

    if (response.status === 200) {
      toast.success("Successfully Submitted");

      history.push(location?.state?.from?.where);

      toggleLessonsOverlay(false);
    } else {
      toast.error(response?.error?.message);
    }
  };

  const handleLessonCompletion = async () => {
    window.scrollTo(0, 0);
    try {
      const currentParticipantAction =
        participantActionData.find(
          (participantAction) =>
            participantAction.actionId === actionId,
        );

      const progressPayload = JSON.parse(
        currentParticipantAction?.data?.progress ?? "{}",
      );

      if (currLesson.isComplete) {
        progressPayload[currLesson.lessonId] = {
          ...progressPayload[currLesson.lessonId],
          lessonId: currLesson.lessonId,
          status: "incomplete",
        };
      } else {
        progressPayload[currLesson.lessonId] = {
          ...progressPayload[currLesson.lessonId],
          lessonId: currLesson.lessonId,
          status: "complete",
        };
      }

      const payload = {
        ...currentParticipantAction,
        data: {
          progress: progressPayload,
        },
      };

      const activityData =
        location?.state?.from?.activityData;

      const isAnonymousActivity =
        activityData?.settings?.anonymous;

      const response = await updateParticipantActionReq(
        activityId,
        getParticipantId(),
        payload,
        actionId,
        isAnonymousActivity,
      );

      if (response.status === 200) {
        setParticipantActionData([response.data]);

        updateLessonsProgress(response.data);

        const isLastLesson = isLastItemInArray(
          lessons,
          currLesson.lessonId,
        );

        if (
          !currLesson.isComplete &&
          response.data.status === "INCOMPLETE"
        ) {
          toggleLessonsOverlay(true);
        } else if (
          !currLesson.isComplete &&
          response.data.status === "COMPLETE" &&
          isLastLesson
        ) {
          toggleLessonsOverlay(true);
        } else {
          if (isLastLesson) {
            return;
          } else {
            if (!currLesson.isComplete) {
              routeToNextLesson(currLesson);
            }
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const action = location?.state?.from?.action;
  const activityData = location?.state?.from?.activityData;

  if (loading) {
    return <Loading />;
  }

  return (
    <section className="contribute-to-lessons">
      {/* ========= MAIN NAV =========  */}
      <nav className="main-nav">
        <div className="link-wrapper">
          <button
            type="button"
            onClick={() =>
              history.push(location?.state?.from?.where)
            }>
            <ArrowBackIosIcon />
            <span className="d-none d-md-block">
              Back to Contributions
            </span>
          </button>
        </div>

        <div className="wrapper">
          <p>{activityData?.name}</p>
        </div>
      </nav>

      <section className="section-wrapper">
        {/* ========= SIDEBAR =========  */}
        <LessonsSidebar
          isOpen={isOpen}
          action={action}
          currLesson={currLesson}
          lessonsData={lessonsData}
          onSelectLesson={onSelectLesson}
          handleSearchChange={handleSearchChange}
          handleSearchSubmit={handleSearchSubmit}
        />

        {/* ========= MAIN CONTENT =========  */}
        <MainContent
          isOpen={isOpen}
          toggleSidebar={toggleSidebar}
          currLesson={currLesson}
          lessonProgress={getLessonProgess()}
          handleLessonCompletion={handleLessonCompletion}
          action={action}
        />
      </section>

      {isOverlayOpen && (
        <LessonsOverlay
          toggleOverlay={toggleLessonsOverlay}
          currLesson={currLesson}
          isSubmitting={isSubmitting}
          percentage={getLessonProgess()}
          isLastLesson={isLastItemInArray(
            lessons,
            currLesson.lessonId,
          )}
          handleSubmitContribution={
            handleSubmitContribution
          }
          routeToNextLesson={routeToNextLesson}
        />
      )}
    </section>
  );
};

const mapStateToProps = (state) => ({
  profileData: state.authReducer.data,

  getPostLessonsData:
    state.getPostLessonsReducer.getPostLessonsData,

  getParticipantActionsDataError:
    state.getParticipantActionsReducer
      .getParticipantActionsDataError,
  getParticipantActionsData:
    state.getParticipantActionsReducer
      .getParticipantActionsData,
  getParticipantActionsStatus:
    state.getParticipantActionsReducer
      .getParticipantActionsStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getLessons,
    getPostLessons,
    submitContribution,
    getParticipantActions,
    updateParticipantAction,
    postParticipantAction,
    getParticipantAllActions,
  }),
)(ContributeToLessons);
