import React from "react";
import styles from "./checkbox.module.scss";

const Checkbox = ({ children, className, ...rest }) => {
  const { amc__checkbox, checkbox_input, checkbox_label } =
    styles;

  return (
    <div className={`${amc__checkbox} ${className}`}>
      <input
        className={`${checkbox_input}`}
        type="checkbox"
        id={rest.id}
        checked={rest.checked}
        {...rest}
      />
      <label className={checkbox_label} htmlFor={rest.id}>
        {children}
      </label>
    </div>
  );
};

export default Checkbox;
