import {
  SUBMIT_CONTRIBUTION_ERROR, SUBMIT_CONTRIBUTION_SUCCESS,
} from '../actions/types';

const initialState = {
  submitContributionData: null,
  submitContributionDataError: null,
  submitContributionStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_CONTRIBUTION_SUCCESS:
      return {
        ...state,
        submitContributionData: action.payload,
        submitContributionDataError: null,
        submitContributionStatus: 'success',
      };
    case SUBMIT_CONTRIBUTION_ERROR:
      return {
        ...state,
        submitContributionDataError: action.payload,
        submitContributionData: null,
        submitContributionStatus: 'error',
      };
    default:
      return {
        ...state,
        submitContributionStatus: null,
      };
  }
};
