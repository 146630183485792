import {
  DELETE_REACTION_ERROR, DELETE_REACTION_SUCCESS,
} from '../actions/types';

const initialState = {
  deleteReactionData: null,
  deleteReactionDataError: null,
  deleteReactionStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DELETE_REACTION_SUCCESS:
      return {
        ...state,
        deleteReactionData: action.payload,
        deleteReactionDataError: null,
        deleteReactionStatus: 'success',
      };
    case DELETE_REACTION_ERROR:
      return {
        ...state,
        deleteReactionDataError: action.payload,
        deleteReactionData: null,
        deleteReactionStatus: 'error',
      };
    default:
      return {
        ...state,
        deleteReactionStatus: null,
      };
  }
};
