import React from "react";

const CustomCheckbox = ({
  children,
  actionId,
  onChange,
  checked,
  name,
}) => {
  return (
    <div className="ml-3 mb-2 font-small d-flex">
      <div className="checkbox-container">
        <input
          name={name}
          checked={checked}
          type="checkbox"
          className="input-checkbox-option"
          onChange={(e) => onChange(e, actionId)}
        />
        <span className="check"></span>
      </div>
      {children}
    </div>
  );
};

export default CustomCheckbox;
