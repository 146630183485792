/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import PropTypes from "prop-types";

import "./ResourceTable.scss";

class ResourceTable extends Component {
  render() {
    const { tableActions, headers, tableData } = this.props;

    const getClassName = (index) => {
      return tableActions?.title.includes(
        "Questionnaires",
      ) &&
        index > 1 &&
        index < 4
        ? { className: "w-10" }
        : {};
    };

    return (
      <div className="table-block pt-1 resource-table">
        {tableActions && (
          <div className="m-3 d-flex justify-content-between table-actions">
            <div className="table-actions__title">
              {tableActions.title}
            </div>
            <div className="table-actions__actions">
              {tableActions.actions}
            </div>
          </div>
        )}

        <table className="table table-hover">
          <thead>
            <tr>
              {headers &&
                Object.values(headers).map(
                  (header, index) => (
                    <th
                      scope="col"
                      key={index}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...getClassName(index)}>
                      <span
                        className={
                          header === "Email" && "email-cell"
                        }>
                        {header}
                      </span>
                    </th>
                  ),
                )}
            </tr>
          </thead>

          <tbody>
            {tableData?.length > 0 &&
              tableData.map((row) => (
                <tr key={row.id}>{this.getRowData(row)}</tr>
              ))}
          </tbody>
        </table>
        {tableData?.length === 0 && (
          <div className="w-100 text-center">
            No results to display!
          </div>
        )}
      </div>
    );
  }

  getRowData(row) {
    return Object.keys(this.props.headers).map((header) => (
      <td
        className={
          header === "name"
            ? "name-cell"
            : header === "description"
            ? "description-cell"
            : header === "id"
            ? "id-cell"
            : ""
        }>
        {row[header]}
      </td>
    ));
  }
}

ResourceTable.propTypes = {
  tableData: PropTypes.array,
  headers: PropTypes.any,
  updated: PropTypes.shape({}),
};

export default ResourceTable;
