import {
  GET_CONTRIBUTIONS_COMMENTS_ERROR,
  GET_CONTRIBUTIONS_COMMENTS_SUCCESS,
} from "../actions/types";

const initialState = {
  getContributionsCommentsData: null,
  getContributionsCommentsDataError: null,
  getContributionsCommentsStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTRIBUTIONS_COMMENTS_SUCCESS:
      return {
        ...state,
        getContributionsCommentsData: action.payload,
        getContributionsCommentsDataError: null,
        getContributionsCommentsStatus: "success",
      };
    case GET_CONTRIBUTIONS_COMMENTS_ERROR:
      return {
        ...state,
        getContributionsCommentsDataError: action.payload,
        getContributionsCommentsData: null,
        getContributionsCommentsStatus: "error",
      };
    default:
      return {
        ...state,
        getContributionsCommentsStatus: null,
      };
  }
};
