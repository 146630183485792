/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import {
  TextField,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  MenuItem,
  RadioGroup,
  FormLabel,
  Radio,
} from "@material-ui/core";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import {
  ALL_USERS,
  CLUB,
  CLUB_DETAILS_VISIBILITY,
  CTA_TEXT,
  CUSTOMIZE_URL_HANDLE,
  DISPLAY_MEMBERS_PANEL,
  DISPLAY_MEMBERS_PANEL_DESCRIPTION,
  ENABLE_ACTIVITY_FEED,
  ENABLE_ACTIVITY_FEED_DESCRIPTION,
  INFORMATION,
  INVITATION_ONLY,
  JOIN_CALL_TO_ACTION,
  JOIN_CALL_TO_ACTION_DESCRIPTION,
  MEMBERS_CREATE_POSTS,
  MEMBERS_CREATE_POSTS_DESCRIPTION,
  MEMBERS_ONLY,
  SEND_MEMBER_wELCOME_EMAIL,
  wELCOME_EMAIL_CONTENT,
  wELCOME_EMAIL_SENDER,
  wELCOME_EMAIL_SUBJECT,
  WHO_CAN_CONTRIBUTE,
} from "../../../../helpers/constants";
import Switch from "../../../generics/Switch";

const visibilityOptions = [
  { value: "PUBLIC", name: getLabelByName(ALL_USERS) },
  { value: "PRIVATE", name: getLabelByName(MEMBERS_ONLY) },
  {
    value: "RESTRICTED",
    name: getLabelByName(INVITATION_ONLY),
  },
];

const statuses = [
  { index: 0, text: "Active", value: "ACTIVE" },
  { index: 1, text: "Inactive", value: "INACTIVE" },
];

export class SettingsContent extends Component {
  render() {
    const {
      category,
      clubData,
      settingsChange,
      switchChange,
      notificationsChange,
      notifSwitchChange,
      handleRadio,
      handleChange,
    } = this.props;

    return (
      <div>
        {category === "general" && (
          <>
            <div className="activity-card other-activity-information">
              <div className="activity-card__heading">
                <h5>
                  {" "}
                  {`${getLabelByName(
                    CLUB,
                  )} ${getLabelByName(INFORMATION)}`}
                </h5>
              </div>
              <div className="activity-card__main">
                <form id="form" onSubmit={() => {}}>
                  <FormControl
                    className="inputLeft50"
                    variant="outlined">
                    <TextField
                      id="handle"
                      label={getLabelByName(
                        CUSTOMIZE_URL_HANDLE,
                      )}
                      name="handle"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">{`${window.location.hostname}/club/${clubData?.id}/`}</InputAdornment>
                        ),
                      }}
                      value={clubData?.handle}
                      onChange={handleChange}
                      required
                    />
                  </FormControl>
                  {clubData && (
                    <FormControl
                      className="inputRight50"
                      variant="outlined">
                      <InputLabel id="visibility">
                        {getLabelByName(WHO_CAN_CONTRIBUTE)}
                        ?
                      </InputLabel>
                      <Select
                        labelId="visibility"
                        id="visibility"
                        name="visibility"
                        required
                        value={
                          clubData && clubData.visibility
                        }
                        onChange={handleChange}
                        label={getLabelByName(
                          WHO_CAN_CONTRIBUTE,
                        )}>
                        {visibilityOptions.map(
                          (visibility) => {
                            return (
                              <MenuItem
                                selected={
                                  clubData &&
                                  clubData.visibility ===
                                    visibility.value
                                }
                                value={visibility.value}>
                                {visibility.name}
                              </MenuItem>
                            );
                          },
                        )}
                      </Select>
                    </FormControl>
                  )}
                  <FormControl
                    className="input100"
                    margin="normal"
                    component="fieldset">
                    <FormLabel component="legend">
                      Select the status of your entity, you
                      can change this at a later time
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="Activity-status"
                      name="Status"
                      onChange={handleRadio}>
                      {statuses.map((statusItem) => (
                        <FormControlLabel
                          value={statusItem.value}
                          id={`check-${statusItem.value}`}
                          labelPlacement="end"
                          checked={
                            clubData &&
                            clubData.status ===
                              statusItem.value
                          }
                          control={
                            <Radio color="primary" />
                          }
                          label={statusItem.text}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </form>
              </div>
            </div>

            <div className="activity-card other-club-information">
              <div className="activity-card__heading">
                <h5>
                  {getLabelByName(JOIN_CALL_TO_ACTION)}
                </h5>
              </div>
              <div className="activity-card__main">
                <form
                  id="form_join_cta"
                  onSubmit={() => {}}>
                  <div className="row">
                    <div className="col-md">
                      <FormControl
                        className="w-100"
                        variant="outlined">
                        <TextField
                          id="join_call_to_action"
                          label={getLabelByName(CTA_TEXT)}
                          name="join_call_to_action"
                          variant="outlined"
                          value={
                            clubData?.settings
                              ?.join_call_to_action || ""
                          }
                          onChange={settingsChange}
                        />
                      </FormControl>
                    </div>
                    <div className="col-md">
                      {getLabelByName(
                        JOIN_CALL_TO_ACTION_DESCRIPTION,
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="activity-card other-club-information">
              <div className="activity-card__heading">
                <h5>
                  {getLabelByName(CLUB_DETAILS_VISIBILITY)}
                </h5>
              </div>
              <div className="activity-card__main">
                <form id="form" onSubmit={() => {}}>
                  <FormControl className="input100">
                    <FormControlLabel
                      className="p-0 m-0"
                      name="enable_activity_feed"
                      control={
                        <Switch
                          name="enable_activity_feed"
                          checked={
                            !!clubData?.settings
                              ?.enable_activity_feed
                          }
                          onChange={() =>
                            switchChange(
                              "enable_activity_feed",
                            )
                          }
                        />
                      }
                      label={getLabelByName(
                        ENABLE_ACTIVITY_FEED,
                      )}
                      labelPlacement="end"
                    />
                  </FormControl>
                  <div>
                    {getLabelByName(
                      ENABLE_ACTIVITY_FEED_DESCRIPTION,
                    )}
                  </div>
                  <FormControl className="mt-5 input100">
                    <FormControlLabel
                      className="p-0 m-0"
                      name="members_create_posts"
                      control={
                        <Switch
                          name="members_create_posts"
                          checked={
                            !!clubData?.settings
                              ?.members_create_posts
                          }
                          onChange={() =>
                            switchChange(
                              "members_create_posts",
                            )
                          }
                        />
                      }
                      label={getLabelByName(
                        MEMBERS_CREATE_POSTS,
                      )}
                      labelPlacement="end"
                    />
                  </FormControl>
                  <div>
                    {getLabelByName(
                      MEMBERS_CREATE_POSTS_DESCRIPTION,
                    )}
                  </div>
                  <FormControl className="mt-5 input100">
                    <FormControlLabel
                      className="p-0 m-0"
                      name="display_members_panel"
                      control={
                        <Switch
                          name="display_members_panel"
                          checked={
                            !!clubData?.settings
                              ?.display_members_panel
                          }
                          onChange={() =>
                            switchChange(
                              "display_members_panel",
                            )
                          }
                        />
                      }
                      label={getLabelByName(
                        DISPLAY_MEMBERS_PANEL,
                      )}
                      labelPlacement="end"
                    />
                  </FormControl>
                  <div>
                    {getLabelByName(
                      DISPLAY_MEMBERS_PANEL_DESCRIPTION,
                    )}
                  </div>
                </form>
              </div>
            </div>
          </>
        )}

        {category === "notifications" && (
          <>
            <div className="activity-card other-activity-information">
              <div className="d-flex justify-content-between align-items-center activity-card__heading">
                <div>
                  <h5>
                    {getLabelByName(
                      SEND_MEMBER_wELCOME_EMAIL,
                    )}
                  </h5>
                </div>
                <div>
                  <FormControl className="input100">
                    <FormControlLabel
                      className="p-0 m-0"
                      name="send_welcome_email"
                      control={
                        <Switch
                          name="send_welcome_email"
                          checked={
                            !!clubData?.settings
                              ?.notifications?.welcome_email
                              ?.send_welcome_email
                          }
                          onChange={(e) =>
                            notifSwitchChange(
                              e,
                              "welcome_email",
                              "send_welcome_email",
                            )
                          }
                        />
                      }
                      label=""
                      labelPlacement="end"
                    />
                  </FormControl>
                </div>
              </div>
              <div className="activity-card__main">
                <form id="form" onSubmit={() => {}}>
                  <FormControl
                    className="inputLeft50"
                    variant="outlined">
                    <TextField
                      id="welcome_email_sender"
                      label={getLabelByName(
                        wELCOME_EMAIL_SENDER,
                      )}
                      name="welcome_email_sender"
                      variant="outlined"
                      value={
                        clubData?.settings?.notifications
                          ?.welcome_email
                          ?.welcome_email_sender || ""
                      }
                      onChange={(e) =>
                        notificationsChange(
                          e.target.value,
                          "welcome_email",
                          "welcome_email_sender",
                        )
                      }
                    />
                  </FormControl>
                  <FormControl
                    className="inputRight50"
                    variant="outlined">
                    <TextField
                      id="welcome_email_subject"
                      label={getLabelByName(
                        wELCOME_EMAIL_SUBJECT,
                      )}
                      name="welcome_email_subject"
                      variant="outlined"
                      value={
                        clubData?.settings?.notifications
                          ?.welcome_email
                          ?.welcome_email_subject || ""
                      }
                      onChange={(e) =>
                        notificationsChange(
                          e.target.value,
                          "welcome_email",
                          "welcome_email_subject",
                        )
                      }
                    />
                  </FormControl>
                  <FormControl
                    className="mt-3 input100"
                    variant="outlined">
                    <TextField
                      multiline
                      rows={10}
                      id="welcome_email_content"
                      label={getLabelByName(
                        wELCOME_EMAIL_CONTENT,
                      )}
                      name="welcome_email_content"
                      variant="outlined"
                      value={
                        clubData?.settings?.notifications
                          ?.welcome_email
                          ?.welcome_email_content || ""
                      }
                      onChange={(e) =>
                        notificationsChange(
                          e.target.value,
                          "welcome_email",
                          "welcome_email_content",
                        )
                      }
                    />
                  </FormControl>
                </form>
              </div>
            </div>
          </>
        )}

        {category === "interactions" && (
          <>
            <div className="activity-card other-activity-information">
              <div className="d-flex justify-content-between align-items-center activity-card__heading">
                <div>
                  <h5>Members can create posts</h5>
                </div>
                <div>
                  <FormControl className="input100">
                    <FormControlLabel
                      className="p-0 m-0"
                      name="members_can_create_posts"
                      control={
                        <Switch
                          name="members_can_create_posts"
                          checked={
                            !!clubData?.settings
                              ?.members_can_create_posts
                          }
                          onChange={(e) =>
                            switchChange(
                              "members_can_create_posts",
                            )
                          }
                        />
                      }
                      label=""
                      labelPlacement="end"
                    />
                  </FormControl>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default SettingsContent;
