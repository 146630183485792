import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ClubPosts from "../../ClubPosts/ClubPosts";
import ClubArticles from "../../ClubArticles/ClubArticles";
import ClubLessons from "../../ClubLessons/ClubLessons";
import ClubPromotions from "../../ClubPromotions/ClubPromotions";

import "./clubContentBody.scss";

export class ClubContentBody extends Component {
  state = {};

  renderClubContentBody = (category) => {
    switch (category) {
      case "posts":
        return <ClubPosts />;
      case "articles":
        return <ClubArticles />;
      case "lessons":
        return <ClubLessons />;
      case "promotions":
        return <ClubPromotions />;

      default:
        return "";
    }
  };

  render() {
    const { category } = this.props;

    return <>{this.renderClubContentBody(category)}</>;
  }
}

const mapStateToProps = (state) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(ClubContentBody);
