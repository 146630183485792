import { toast } from 'react-toastify';
import {
  CREATE_ACTIVITY_ERROR, CREATE_ACTIVITY_SUCCESS,
} from '../actions/types';

const initialState = {
  createActivityData: null,
  createActivityDataError: null,
  createActivityStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        createActivityData: action.payload,
        createActivityDataError: null,
        createActivityStatus: 'success',
      };
    case CREATE_ACTIVITY_ERROR:
      return {
        ...state,
        createActivityDataError: action.payload,
        createActivityData: null,
        createActivityStatus: 'error',
      };
    default:
      return {
        ...state,
        createActivityStatus: null,
      };
  }
};
