/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable global-require */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { Modal } from "react-bootstrap";
import { getLabelByName } from "../../helpers/helperFunctions";
import { requestVerification } from "../../redux/actions/usersActions";
import { CANCEL } from "../../helpers/constants";

class UserVerificationModal extends Component {
  render() {
    const { isModalOpen, toggleModal, profileData, } = this.props;

    const requestUserVerification = () => {
      toggleModal()
      this.props.requestVerification({username: profileData?.data?.username})
    }

    return (
      <Modal
        size="md"
        show={isModalOpen}
        onHide={toggleModal}
        className="behalf-modal">
        <div className="create-club-header">
          <div className="header-left">
            <p className="header-modal-title">
            Verify your account to have access to more features
            </p>
          </div>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            style={{ marginTop: "-5rem", outline: "none" }}
            onClick={() => toggleModal()}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="row d-flex justify-content-around create-club-buttons">
          <>
            <button
                type="button"
                className="btn btn-lg submit-btn"
                onClick={requestUserVerification}>
                Verify account now
              </button>
            <button
              type="button"
              className="btn btn-lg submit-btn btn-reverse"
              onClick={toggleModal}>
              {getLabelByName(CANCEL)}
            </button>
          </>
        </div>
      </Modal>
    );
  }
}

export const mapStateToProps = (state) => ({
  profileData: state.authReducer.data,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    requestVerification,
  }),
)(UserVerificationModal);
// export default UserVerificationModal;
