/* eslint-disable prefer-destructuring */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";

const useStyles = makeStyles(() => ({
  formControl: {
    width: 100,
  },
}));

const StatusDropdown = ({
  dropDownValue,
  handleChange,
}) => {
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}>
      <NativeSelect
        value={dropDownValue}
        name="age"
        onChange={handleChange}
        inputProps={{ "aria-label": "status" }}>
        <option value="all">All</option>
        <option value="promoted">Promoted</option>
      </NativeSelect>
    </FormControl>
  );
};

export default StatusDropdown;
