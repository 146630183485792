/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import slugify from "slugify";
import campaigns from "../assets/manage-campaigns.svg";
import MembersOnlyModal from "./MembersOnlyModal";

class OutputCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMembersOnlyModal: false,
    };
  }

  isMember = () => {
    const { profileData, output } = this.props;
    let isMember = false;

    const clubId = output?.club?.id;

    if (profileData?.data) {
      isMember = Object.keys(
        profileData?.data?.clubs,
      ).includes(clubId.toString());
    }

    return isMember;
  };

  handleOutputClick = (e) => {
    e.preventDefault();

    const { output, history } = this.props;
    const link = this.getOutputLink();

    if (output.visibility === "PUBLIC") {
      history.push(link);
    }

    if (output.visibility === "PRIVATE") {
      const isUserMember = this.isMember();

      if (isUserMember) {
        history.push(link);
      } else {
        this.setState({ isMembersOnlyModal: true });
      }
    }
  };

  closeMembersOnlyModal = () => {
    return this.setState({ isMembersOnlyModal: false });
  };

  render() {
    const { output, category } = this.props;

    const { isMembersOnlyModal } = this.state;

    if (category === "clubs") {
      return (
        <Link
          className="output-card-link"
          to={this.getOutputLink()}>
          <div className="output-card">
            <div
              className="card-image"
              style={{ backgroundColor: "#e8daca" }}>
              {output.profile.picture ? (
                <img
                  src={output.profile.picture}
                  alt="cover_image"
                />
              ) : (
                <img src={campaigns} alt="output-card" />
              )}
            </div>
            <div className="output-name">
              {output?.name}
            </div>
            {output?.club?.name && (
              <div className="output-club-name">
                {output.club.name}
              </div>
            )}
          </div>
        </Link>
      );
    }

    if (category === "posts") {
      return (
        <Link
          className="output-card-link"
          to={this.getOutputLink()}>
          <div className="output-card">
            <div
              className="card-image"
              style={{ backgroundColor: "#e8daca" }}>
              {output.settings.cover_image ? (
                <img
                  src={output.settings.cover_image}
                  alt="cover_image"
                />
              ) : (
                <img src={campaigns} alt="output-card" />
              )}
            </div>
            <div className="output-name">{output.name}</div>
            {output?.club?.name && (
              <div className="output-club-name">
                {output.club.name}
              </div>
            )}
          </div>
        </Link>
      );
    }

    if (category === "activities") {
      return (
        <Link
          className="output-card-link"
          to={this.getOutputLink()}>
          <div className="output-card">
            <div
              className="card-image"
              style={{ backgroundColor: "#e8daca" }}>
              {output.profile.picture ? (
                <img
                  src={output.profile.picture}
                  alt="cover_image"
                />
              ) : (
                <img src={campaigns} alt="output-card" />
              )}
            </div>
            <div className="output-name">{output.name}</div>
            {output?.club?.name && (
              <div className="output-club-name">
                {output.club.name}
              </div>
            )}
          </div>
        </Link>
      );
    }

    return (
      <>
        <Link
          className="output-card-link"
          to="#"
          onClick={this.handleOutputClick}>
          <div className="output-card">
            <div
              className="card-image"
              style={{ backgroundColor: "#e8daca" }}>
              {output.settings.cover_image ? (
                <img
                  src={output.settings.cover_image}
                  alt="cover_image"
                />
              ) : (
                <img src={campaigns} alt="output-card" />
              )}
            </div>
            <div className="output-name">{output.name}</div>
            <div className="output-club-name">
              {output.club.name}
            </div>
          </div>
        </Link>

        {isMembersOnlyModal && (
          <MembersOnlyModal
            isModalOpen={isMembersOnlyModal}
            toggleModal={this.closeMembersOnlyModal}
          />
        )}
      </>
    );
  }

  getOutputLink = () => {
    const { output, category } = this.props;

    if (category === "outputs") {
      const link = `/club/${output.club.id}/${
        output.club.handle
      }/output/${output?.id}/${slugify(output.name, {
        lower: true,
      })}`;
      return link;
    }
    return "#";
  };
}

const mapStateToProps = (state) => ({
  profileData: state.authReducer.data,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {}),
)(OutputCard);
