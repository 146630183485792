/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import PropTypes from "prop-types";
import firstPage from "../../assets/first-page.svg";
import lastPage from "../../assets/last-page.svg";
import prevPage from "../../assets/prev-page.svg";
import nextPage from "../../assets/next-page.svg";
import { getLabelByName } from "../../helpers/helperFunctions";
import {
  OF_WORD,
  ROWS_PER_PAGE,
} from "../../helpers/constants";

export class PaginationRow extends Component {
  getPageRange() {
    const { currentPage, pageDivider, dataLength } =
      this.props;
    const first = (currentPage - 1) * pageDivider + 1;
    let last = first + (pageDivider - 1);
    if (last > dataLength) {
      last = dataLength;
    }

    return { first, last };
  }

  render() {
    const {
      incrementPage,
      decrementPage,
      nbPages,
      currentPage,
      dataLength,
      setCurrentPage,
      pageDivider,
    } = this.props;

    return (
      <div
        className={`pagination-bar ${
          this.props.className && this.props.className
        }`}>
        <div className="rows-per-page">
          {getLabelByName(ROWS_PER_PAGE)}{" "}
          <select className="ml-2">
            <option>{pageDivider}</option>
          </select>
        </div>
        <div className="current-items">
          {this.getPageRange().first} -{" "}
          {this.getPageRange().last}{" "}
          {getLabelByName(OF_WORD)} {dataLength}
        </div>
        <div className="pages-nav">
          <img
            src={firstPage}
            alt="first-page"
            onClick={() => setCurrentPage(1)}
          />
          <img
            src={prevPage}
            alt="previous-page"
            onClick={() => decrementPage()}
          />
          <select
            onChange={(e) =>
              setCurrentPage(Number(e.target.value))
            }>
            {Array.from(
              { length: nbPages },
              (_, index) => index + 1,
            ).map((number) => (
              <option
                value={number}
                selected={
                  Number(currentPage) === Number(number)
                }>
                {number}
              </option>
            ))}
          </select>
          <img
            src={nextPage}
            alt="next-page"
            onClick={() => incrementPage()}
          />
          <img
            src={lastPage}
            alt="last-page"
            onClick={() => setCurrentPage(nbPages)}
          />
        </div>
      </div>
    );
  }
}

export default PaginationRow;
