/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import CampaignIcon from "../../../assets/manage-campaigns.svg";
import OutputDropdown from "./OutputDropdown";
import { ReactComponent as LoudIcon } from "../../../assets/icons/loud.svg";
import { timestampToDate } from "../../../helpers/admin";
import {
  updateOutputReq,
  getOutputs,
} from "../../../redux/actions/contributionsActions";
import {
  getAdminClubs,
  updateClub,
} from "../../../redux/actions/institutesActions";
import {
  updateActivity,
  getAdminActivities,
} from "../../../redux/actions/campaignsActions";

const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#10A728",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const OutputItem = ({
  // ------------ NB: Output Prop acting as placeholder for the 'category' object type being passed --------------- //
  output,

  // ------------ NB: Category Type can be one of: clubs, posts, outputs, activities --------------- //
  category,
  getOutputsReq,
  updateOutputReq,
  numOfPromotedOutputs,
  getAdminClubsReq,
  getAdminActivitiesReq,
  updateClubReq,
  updateActivityReq,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (output?.meta?.promoted) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [output]);

  const handlePromoteOutput = async (isChecked) => {
    setChecked(isChecked);

    if (isChecked) {
      if (numOfPromotedOutputs >= 7) {
        setChecked(false);
        return toast.warn(
          "Maximum number of outputs promoted exceeded.",
        );
      }

      const response = await updateOutputReq(
        output?.club?.id,
        output?.id,
        {
          ...output,
          meta: {
            promoted: true,
          },
        },
      );

      if (response.status === 200) {
        getOutputsReq();

        toast.success("Output Promoted");
      }

      if (response.status === 500) {
        setChecked(output?.meta?.promoted ? true : false);
        toast.error("Error promoting output");
      }
    } else {
      const response = await updateOutputReq(
        output?.club?.id,
        output?.id,
        {
          ...output,
          meta: {
            promoted: false,
          },
        },
      );

      if (response.status === 200) {
        getOutputsReq();

        toast.success("Output is no longer promoted");
      }

      if (response.status === 500) {
        setChecked(output?.meta?.promoted ? true : false);
        toast.error("Error promoting output");
      }
    }
  };

  const handlePromotePost = async (isChecked) => {
    console.log("HANDLE PROMOTE POST");
  };

  const handlePromoteActivity = async (isChecked) => {
    setChecked(isChecked);

    if (isChecked) {
      if (numOfPromotedOutputs >= 7) {
        setChecked(false);
        return toast.warn(
          "Maximum number of outputs promoted exceeded.",
        );
      }

      const payload = {
        ...output,
        meta: {
          promoted: true,
        },
      };

      const response = await updateActivityReq(
        output?.id,
        payload,
      );

      if (response.status === 200) {
        getAdminActivitiesReq();

        toast.success("Activity Promoted");
      }

      if (response.status === 500) {
        setChecked(output?.meta?.promoted ? true : false);
        toast.error("Error promoting activity");
      }
    } else {
      const payload = {
        ...output,
        meta: {
          promoted: false,
        },
      };

      const response = await updateActivityReq(
        output?.id,
        payload,
      );

      if (response.status === 200) {
        getAdminActivitiesReq();

        toast.success("Activity is no longer promoted");
      }

      if (response.status === 500) {
        setChecked(output?.meta?.promoted ? true : false);
        toast.error("Error promoting activity");
      }
    }
  };

  const handlePromoteClub = async (isChecked) => {
    setChecked(isChecked);

    if (isChecked) {
      if (numOfPromotedOutputs >= 7) {
        setChecked(false);
        return toast.warn(
          "Maximum number of outputs promoted exceeded.",
        );
      }

      const payload = {
        ...output,
        meta: {
          promoted: true,
        },
      };

      const response = await updateClubReq(
        output?.id,
        payload,
      );

      if (response.status === 200) {
        getAdminClubsReq();

        toast.success("Club Promoted");
      }

      if (response.status === 500) {
        setChecked(output?.meta?.promoted ? true : false);
        toast.error("Error promoting club");
      }
    } else {
      const payload = {
        ...output,
        meta: {
          promoted: false,
        },
      };

      const response = await updateClubReq(
        output?.id,
        payload,
      );

      if (response.status === 200) {
        getAdminClubsReq();

        toast.success("Club is no longer promoted");
      }

      if (response.status === 500) {
        setChecked(output?.meta?.promoted ? true : false);
        toast.error("Error promoting club");
      }
    }
  };

  const handleChange = (event) => {
    const { checked: isChecked } = event.target;

    switch (category) {
      case "outputs":
        handlePromoteOutput(isChecked);
        break;

      case "posts":
        handlePromotePost(isChecked);
        break;

      case "activities":
        handlePromoteActivity(isChecked);
        break;

      case "clubs":
        handlePromoteClub(isChecked);
        break;

      default:
    }
  };

  const imageUrls = {
    outputs: output?.settings?.cover_image,
    activities: output?.profile?.picture,
    clubs: output?.profile?.picture,
    posts: output?.image?.url,
  };

  return (
    <div className="output-item d-flex justify-content-between align-items-center mb-4">
      <div className="d-flex align-items-center">
        <img
          className="cover-image"
          src={imageUrls[category] || CampaignIcon}
          alt="cover_image"
        />

        <div className="ml-3">
          <p className="mb-1">{output?.name}</p>
          <span className="club-name">
            {output?.club?.name}
          </span>
        </div>
      </div>

      <div className="d-flex align-items-center gap-15">
        <OutputDropdown />

        <span className="d-none d-md-block">
          {timestampToDate(output?.dateCreated)}
        </span>

        <p className="mb-0">
          <LoudIcon className="mr-1 mb-2" />
          Promote
        </p>

        <GreenCheckbox
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "promote-output" }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  updateOutputData:
    state.updateOutputReducer.updateOutputData,
  updateOutputDataError:
    state.updateOutputReducer.updateOutputDataError,
  updateOutputStatus:
    state.updateOutputReducer.updateOutputStatus,
});

const mapDispatchToProps = {
  updateOutputReq,
  getOutputsReq: getOutputs,
  updateClubReq: updateClub,
  getAdminClubsReq: getAdminClubs,
  updateActivityReq: updateActivity,
  getAdminActivitiesReq: getAdminActivities,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OutputItem);
