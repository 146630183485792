import React, { useState } from "react";
import { connect } from "react-redux";
import {
  useHistory,
  useLocation,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  CANCEL,
  PAY,
  PAYING,
} from "../../../../helpers/constants";
import PaymentModal from "./paymentModal";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import { makePayment } from "../../../../redux/actions/contributionsActions";

import "./paymentView.scss";
import PaymentStatusOverlay from "./paymentStatusOverlay";
import { compose } from "redux";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_API_KEY,
);

const PaymentView = ({
  action,
  participantId,
  makePayment: makePaymentReq,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] =
    useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] =
    useState(false);
  const [
    isPaymentStatusOverlayOpen,
    setIsPaymentStatusOverlayOpen,
  ] = useState(false);

  const [clientSecret, setClientSecret] = useState("");

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const { id: activityId, handle: activityName } =
    match.params;

  const options = {
    // passing the client secret obtained from the server
    clientSecret,
  };

  const setPaymentStatus = (value) => {
    if (value === "SUCCESS") {
      setIsPaymentSuccess(true);
    }

    if (value === "ERROR") {
      setIsPaymentSuccess(false);
    }
  };

  const toggleIsPaymentModalOpen = () =>
    setIsPaymentModalOpen((open) => !open);

  const togglePaymentOverlay = () =>
    setIsPaymentStatusOverlayOpen((open) => !open);

  const fetchClientSecret = async () => {
    try {
      const payload = { amount: action?.profile?.amount };

      setIsLoading(true);

      const response = await makePaymentReq(
        action.id,
        participantId,
        payload,
      );

      setIsLoading(false);

      setClientSecret(response.client_secret);
      setIsPaymentModalOpen(true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handlePaymentRetry = () => {
    // Close Payment Status Overlay
    setIsPaymentStatusOverlayOpen(false);

    // Initiate Payment Request Again
    fetchClientSecret();
  };

  const handleNextActionAfterPayment = () => {
    // Close Payment Status Overlay
    setIsPaymentStatusOverlayOpen(false);

    //  Redirect to Activity Contribution Page
    history.push(location?.state?.from);
  };

  return (
    <>
      <div className="payment-view m-2">
        <h2 className="confirm-header">Confirm Payment</h2>

        <div className="wrapper">
          <div className="description">
            <header>Description</header>
            <p>{action?.profile?.description}</p>
          </div>

          <div className="frequency">
            <header>Frequency</header>
            <p>{action?.profile?.paymentFrequency}</p>
          </div>

          {/* <div className="ref_number">
        <header>Ref Number</header>
        <p>AMCEIBS934984</p>
      </div> */}
        </div>

        <div className="total">
          <header>Total to pay</header>
          <p className="amount">
            {`${action?.profile?.amount}${action?.profile?.currency}`}{" "}
          </p>
        </div>
      </div>

      <div className="action-btns d-flex justify-content-end align-items-center mx-2">
        <div>
          <button
            type="submit"
            className="btn btn-lg cancel-btn"
            data-dismiss="modal"
            aria-label="Close">
            {getLabelByName(CANCEL)}
          </button>

          <button
            type="submit"
            onClick={() => fetchClientSecret()}
            className="ml-3 btn btn-lg submit-btn">
            {isLoading ? (
              <>
                <span>
                  {getLabelByName(PAYING)}
                  {"  "}
                </span>
                <div
                  className="spinner-border spinner-border-sm"
                  role="status">
                  <span className="sr-only">
                    Loading...
                  </span>
                </div>
              </>
            ) : (
              getLabelByName(PAY)
            )}
          </button>
        </div>
      </div>

      {clientSecret && isPaymentModalOpen ? (
        <Elements stripe={stripePromise} options={options}>
          <PaymentModal
            closeModal={toggleIsPaymentModalOpen}
            isPaymentModalOpen={isPaymentModalOpen}
            togglePaymentOverlay={togglePaymentOverlay}
            setPaymentStatus={setPaymentStatus}
            location={location}
            activityId={activityId}
            activityName={activityName}
          />
        </Elements>
      ) : null}

      {isPaymentStatusOverlayOpen && (
        <PaymentStatusOverlay
          amount={action?.profile?.amount}
          currency={action?.profile?.currency}
          status={isPaymentSuccess ? "SUCCESS" : "ERROR"}
          closeOverlay={togglePaymentOverlay}
          handlePaymentRetry={handlePaymentRetry}
          handleNextActionAfterPayment={
            handleNextActionAfterPayment
          }
        />
      )}
    </>
  );
};

export default compose(
  withRouter,
  connect(null, { makePayment }),
)(PaymentView);
