/* eslint-disable global-require */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { colors } from '../../helpers/constants';
import { markNotification } from '../../redux/actions/notificationsActions';
import { getNotificationTime, getResource, getViewLink } from '../../helpers/notificationsHelper';
import { getProfileDataFromLocalStorage } from '../../helpers/helperFunctions';

export class NotificationPanelItem extends Component {
  state = {
    profileData: getProfileDataFromLocalStorage(),
  };

  markAsRead = async () => {
    const userId = this.state.profileData.data.id;
    const { id: notificationId } = this.props.notification;

    const payload = {
      ...this.props.notification,
      status: 'READ',
    };

    await this.props.markNotification(payload, userId, notificationId);
  };

  render() {
    const { notification } = this.props;
    const resource = getResource(notification);
    return (
      <a
        className="dropdown-notification-link"
        href={getViewLink(notification)}
        onClick={() => this.markAsRead()}
      >
        <div className="post-item-author pb-0">
          <div className="post-avatar">
            <img
              className="img-fluid"
              src={resource?.profile?.picture || require('../../assets/icons/club-dark.svg')}
              alt="notification-avatar"
              style={{
                width: '40px',
                height: '40px',
                objectFit: 'cover',
                padding: !resource?.profile?.picture && '0.5rem',
              }}
            />
          </div>
          <div>
            {notification.data.description}
            <br />
            <div style={{ color: colors.textSecondary }}>
              {getNotificationTime(notification.dateCreated)}
            </div>
          </div>
        </div>
      </a>
    );
  }
}

NotificationPanelItem.propTypes = {
  notification: PropTypes.any,
  markNotification: PropTypes.func,
};

export const mapStateToProps = (state) => ({
  markNotificationData: state.markNotificationReducer.markNotificationData,
  markNotificationDataError: state.markNotificationReducer.markNotificationDataError,
  markNotificationStatus: state.markNotificationReducer.markNotificationStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { markNotification }),
)(NotificationPanelItem);
