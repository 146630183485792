import apiCall from "../../../helpers/apiCall";
import { getHeaders } from "../../../helpers/getHeaders";

import {
    ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_CRUD,
    ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_SEARCH,
    ACTIVITIES_CONTRIBUTIONS_SEARCH,
    ACTIVITIES_CONTRIBUTIONS_COMMENT_SEARCH,
} from "./types";
import { getUserAuthHeaders } from "../users/functions/session";

export const createParticipantContribution = (
    activityId,
    participantId,
    participantActionId,
) => (dispatch) => {
    const apiEndpoint = `/activity/${activityId}/participant/${participantId}/contribute`;
    const payload = {
        participantActionId: participantActionId,
    }

    apiCall.post(
        apiEndpoint,
        payload,
        {
            headers: getHeaders(
                payload,
                apiEndpoint,
            )
        }
    ).then((response) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_CRUD,
            payload: response.data,
        });
    }).catch((err) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_CRUD,
            payload: err,
        });
    });
}

export const retrieveParticipantContribution = (
    activityId,
    participantId,
    contributionId
) => (dispatch) => {
    const apiEndpoint = `/activity/${activityId}/participant/${participantId}/contribution/${contributionId}`;
    apiCall.get(
        apiEndpoint,
        {
            headers: getHeaders(
                null,
                apiEndpoint,
            )
        }
    ).then((response) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_CRUD,
            payload: response.data,
        });
    }).catch((err) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_CRUD,
            payload: err,
        });
    });
}

export const updateParticipantContribution = (
    activityId,
    contributionId,
    contribution
) => (dispatch) => {
    const apiEndpoint = `/activity/${activityId}/contribution/${contributionId}`;
    const payload = contribution;

    apiCall.put(
        apiEndpoint,
        payload,
        {
            headers: getHeaders(
                payload,
                apiEndpoint,
            )
        }
    ).then((response) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_CRUD,
            payload: response.data,
        });
    }).catch((err) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_CRUD,
            payload: err,
        });
    });
}

export const deleteParticipantContribution = (
    activityId,
    participantId,
    contributionId
) => (dispatch) => {
    const apiEndpoint = `/activity/${activityId}/participant/${participantId}/contribution/${contributionId}`;
    apiCall.delete(
        apiEndpoint,
    ).then((response) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_CRUD,
            payload: response.data,
        });
    }).catch((err) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_CRUD,
            payload: err,
        });
    });
}

export const searchParticipantContributions = (
    activityId,
    participantId
) => (dispatch) => {
    const apiEndpoint = `/activity/${activityId}/participant/${participantId}/contributions`;
    apiCall.get(
        apiEndpoint,
        {
            headers: getHeaders(
                null,
                apiEndpoint,
            )
        },
    ).then((response) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_SEARCH,
            payload: response.data
        });
    }).catch((err) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_SEARCH,
            payload: err
        });
    });
}

export const searchActivityContributions = (activityId, query = null) => (dispatch) => {
    let apiEndpoint = `/activity/${activityId}/contributions`;
    if (query !== null) {
        apiEndpoint += `?q=${JSON.stringify(query)}`;
    }

    apiCall.get(
        apiEndpoint,
        {
            headers: getHeaders(
                null,
                apiEndpoint,
            )
        },
    ).then((response) => {
        dispatch({
            type: ACTIVITIES_CONTRIBUTIONS_SEARCH,
            payload: response.data,
        });
    }).catch((err) => {
        dispatch({
            type: ACTIVITIES_CONTRIBUTIONS_SEARCH,
            payload: err,
        });
    });
}

export const getContributionComments = (contributionId) => (dispatch) => {
    const apiEndpoint = `/contribution/${contributionId}/comments`;

    apiCall.get(
        apiEndpoint,
        {
            headers: getUserAuthHeaders(
                apiEndpoint,
            ),
        },
    ).then((response) => {
        dispatch({
            type: ACTIVITIES_CONTRIBUTIONS_COMMENT_SEARCH,
            payload: response.data,
        });
    }).catch((err) => {
        dispatch({
            type: ACTIVITIES_CONTRIBUTIONS_COMMENT_SEARCH,
            payload: err,
        })
    });
}