import {
  GET_PARTICIPANTS_SUCCESS, GET_PARTICIPANTS_ERROR,
} from '../actions/types';

const initialState = {
  getParticipantsData: null,
  getParticipantsDataError: null,
  getParticipantsStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        getParticipantsData: action.payload,
        getParticipantsDataError: null,
        getParticipantsStatus: 'success',
      };
    case GET_PARTICIPANTS_ERROR:
      return {
        ...state,
        getParticipantsDataError: action.payload,
        getParticipantsData: null,
        getParticipantsStatus: 'error',
      };
    default:
      return {
        ...state,
        getParticipantsStatus: null,
      };
  }
};
