import React, { useEffect, useState } from "react";
import QuestionnaireRenderer from '../../Questionnaire/QuestionnaireRenderer';
import DataCollectionManager from './DataCollectionManager'


const DataCollectionConsole = (props) => {

    const { activity, action, participant, participantActions, pageAction, participantActionId, isPreviewMode } = props;

    const maxSubmissions = parseInt(action.meta.maxSubmissions) || 1;

    const [currentParticipantAction, setCurrentParticipantAction] = useState({
        id: 0,
        data: {}
    })

    useEffect(() => {
        if(!participantActions?.participantActions){
            return
        }
        if (!participantActionId) {
            const currentAction = participantActions.participantActions.find(pAction => pAction.id == action.id)
            setCurrentParticipantAction({
                ...currentParticipantAction,
                ...currentAction,
            });  
        } 
        else {
            const currentAction = participantActions.participantActions.find(pAction => pAction.id == participantActionId)
            setCurrentParticipantAction({
                ...currentParticipantAction,
                ...currentAction,
            });
        }
    }, [participantActions?.participantActions, participantActionId]);

    if (maxSubmissions === 1) {
        return <QuestionnaireRenderer
            activity={activity}
            action={action}
            participant={participant}
            participantActions={participantActions}
            isPreviewMode={isPreviewMode}
            participantAction={currentParticipantAction}
        />
    }

    if (!pageAction) {
        return <DataCollectionManager
            activity={activity}
            action={action}
            participant={participant}
            participantActions={participantActions}
        />
    }

    if (pageAction === "new") {
        return <QuestionnaireRenderer
            activity={activity}
            action={action}
            participant={participant}
            participantActions={participantActions}
            isPreviewMode={isPreviewMode}
            participantAction={{
                id: 0,
                data: {}
            }}
        />
    }

    if (pageAction === "edit" && participantActionId) {
        return <QuestionnaireRenderer
            activity={activity}
            action={action}
            participant={participant}
            participantActions={participantActions}
            isPreviewMode={isPreviewMode}
            participantAction={currentParticipantAction} />
    }

    if (pageAction === "review") {
        return <QuestionnaireRenderer
            activity={activity}
            action={action}
            participant={participant}
            participantActions={participantActions}
            isPreviewMode={isPreviewMode}
            participantAction={currentParticipantAction} />
    }

}

export default DataCollectionConsole;