import {
    ACTIVITIES_ACTIVITY_CRUD
} from "../../actions/types";

const initialState = (defaultMessage) => {
    return {
        id: 0,
        parentResource: "CLUB",
        parentId: 0,
        name: { defaultMessage },
        handle: "activity/loading",
        type: "Default",
        startDate: 0,
        endDate: null,
        status: "ACTIVE",
        visibility: "PUBLIC",
        settings: {
            anonymous: false,
            data_export: false,
            notifications: {
                welcome_email: [],
                review_notification_email: [],
                contribution_confirmation_email: []
            },
            campaignNotEnd: true,
            displayParticipants: true,
            displayContributions: true,
            contribute_call_to_action: { defaultMessage },
            participate_call_to_action: { defaultMessage }
        },
        dateCreated: 0,
        lastUpdated: 0,
        actions: [],
        profile: {
            description: { defaultMessage },
            picture: ""
        },
        meta: {}

    }
}

export const activityCRUDReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIVITIES_ACTIVITY_CRUD:
            const apiResponse = action.payload;
            if (apiResponse.status === 200) {
                return {
                    ...state,
                    ...apiResponse.data
                };
            } else {
                return {
                    ...state,
                    ...initialState("Activity not found..."),
                    error: apiResponse.error.message
                }
            }
        default:
            return { ...state };
    }
}
