/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import "./createActivityModal.css";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PropTypes from "prop-types";
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { Modal } from "react-bootstrap";
import moment from "moment";
import slugify from "slugify";
import { toast } from "react-toastify";
import {
  TextField,
  FormControl,
  InputLabel,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Button,
  IconButton,
  Switch,
  Checkbox,
} from "@material-ui/core";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import campaignsIcon from "../../assets/manage-campaigns.svg";

import {
  createActivity,
  updateActivity,
  cloneActivityReq,
} from "../../redux/actions/campaignsActions";
import {
  ACTIVITY,
  ACTIVITY_STATUS_DESCRIPTION,
  ADD_ACTIVITY_INFORMATION_DESCRIPTION,
  ADVANCED_SETTINGS,
  ALL_USERS,
  CANCEL,
  CLUB,
  CREATED_SUCCESSFULLY,
  CLONED_SUCCESSFULLY,
  INVITATION_ONLY,
  MEMBERS_ONLY,
  SAVE,
  SAVING,
  SELECT_FROM_COMPUTER,
  VISIBILITY,
} from "../../helpers/constants";
import { getAllClubsFromLocalStorage, getLabelByName } from "../../helpers/helperFunctions";
import FormStyles from "./main.styles";
import cameraOutline from "../../assets/icons/cameraOutline.svg";

const initialState = {
  name: "",
  type: "Default",
  visibility: "PUBLIC",
  status: "ACTIVE",
  handle: "",
  profile: {},
  settings: {
    participate_call_to_action: "",
    contribute_call_to_action: "",
    displayParticipants: true,
    displayContributions: true,
    campaignNotEnd: false,
    anonymous: false,
    data_export: false,
    notifications: {
      welcome_email: {},
      contribution_confirmation_email: {},
      review_notification_email: {},
    },
  },
  meta: {},
  verified: true,
  startDate: null,
  endDate: null,
  selectedResource: 1,
  statusClicked: null,
  selectedFile: null,
  imagePreview: null,
  clubsData: [],
  visibilityOptions: [
    { value: "PUBLIC", name: getLabelByName(ALL_USERS) },
    {
      value: "PRIVATE",
      name: getLabelByName(MEMBERS_ONLY),
    },
    {
      value: "RESTRICTED",
      name: getLabelByName(INVITATION_ONLY),
    },
  ],
  typeOptions: [{ value: "DEFAULT", name: "Default" }],
};

const CreateActivityModal = ({
  isCreateModalVisible,
  changeIsCreateModal,
  createActivity: createActivityAction,
  updateActivity: updateActivityAction,
  editActivity,
  getActivity,
  createActivityStatus,
  createActivityData,
  updateActivityStatus,
  propsClubData,
  isFromActivityDetailsPage,
  cloneActivity,
  cloneActivityReq,
  cloneActivityData,
  cloneActivityDataError,
  cloneActivityStatus,
}) => {
  const classes = FormStyles();
  const [data, setdata] = useState({
    ...initialState,
    clubsData: getAllClubsFromLocalStorage(),
  });
  const [clubsData, setClubsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const mobile = useMediaQuery("(max-width:768px)");

  const history = useHistory();

  const handleRadio = (e) => {
    setdata({
      ...data,
      status: e.target.value,
      statusClicked: "clicked",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "participate_call_to_action":
        setdata({
          ...data,
          settings: {
            ...data.settings,
            [name]: value,
          },
        });
        break;

      case "contribute_call_to_action":
        setdata({
          ...data,
          settings: {
            ...data.settings,
            [name]: value,
          },
        });
        break;

      case "displayParticipants":
        setdata({
          ...data,
          settings: {
            ...data.settings,
            [name]: value,
          },
        });
        break;

      case "displayContributions":
        setdata({
          ...data,
          settings: {
            ...data.settings,
            [name]: value,
          },
        });
        break;

      case "description":
        setdata({
          ...data,
          profile: {
            ...data.profile,
            [name]: value,
          },
        });
        break;

      case "startDate":
      case "endDate":
        setdata({
          ...data,
          [name]: moment(value).unix(),
        });
        break;

      default:
        setdata({
          ...data,
          [name]: value,
        });
        break;
    }
  };

  const fileChange = (e) => {
    const blob = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setdata({
        ...data,
        selectedFile: blob,
        imagePreview: fileReader.result,
      });
    };
    fileReader.readAsDataURL(e.target.files[0]);
  };

  const {
    name,
    visibility,
    visibilityOptions,
    description,
    status,
    startDate,
    endDate,
    parentId,
    imagePreview,
    selectedResource,
    profile,
    settings,
  } = data;

  const statuses = [
    { index: 0, text: "Active", value: "ACTIVE" },
    { index: 1, text: "Inactive", value: "INACTIVE" },
  ];

  let previewToDisplay = campaignsIcon;
  if (!imagePreview && profile && profile.picture) {
    previewToDisplay = profile.picture;
  }
  if (imagePreview) {
    previewToDisplay = imagePreview;
  }

  const clubsOptions = clubsData
    ? clubsData.map((club) => ({
        value: club.id,
        text: club.name,
      }))
    : [];

  const handleEndlessChange = () => {
    setdata({
      ...data,
      settings: {
        ...data.settings,
        campaignNotEnd: !data.settings.campaignNotEnd,
      },
    });
  };

  const handleSaveOnClick = async () => {
    setIsLoading(true);
    const { selectedFile, handle, campaignNotEnd } = data;

    if (!selectedFile) {
      let payload;

      if (cloneActivity) {
        payload = {
          parentId: cloneActivity?.id,
          name: data?.name || cloneActivity?.name,
          description:
            data?.profile?.description ||
            cloneActivity?.profile?.description,
        };

        await cloneActivityReq(cloneActivity.id, payload);
      } else {
        payload = {
          ...data,
          parentResource: "CLUB",
          parentId:
            propsClubData?.data.id ||
            selectedResource?.toString(),
          handle: handle || slugify(name, { lower: true }),
          profile: {
            ...profile,
            picture: (profile && profile.picture) || "",
          },
          settings: {
            ...settings,
            participate_call_to_action:
              settings.participate_call_to_action,
            contribute_call_to_action:
              settings.contribute_call_to_action,
            displayParticipants:
              settings.displayParticipants,
            displayContributions:
              settings.displayContributions,
            campaignNotEnd:
              endDate === null || campaignNotEnd,
          },
          meta: {},
        };

        delete payload.visibilityOptions;
        delete payload.selectedResource;
        delete payload.imagePreview;
        delete payload.statusClicked;
        delete payload.typeOptions;
        delete payload.selectedFile;
        delete payload.clubsData;

        if (!editActivity) {
          await createActivityAction(payload);
        } else {
          const updatedPayload = {
            ...payload,
          };
          await updateActivityAction(
            updatedPayload.id,
            updatedPayload,
          );
        }
      }
    }

    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("upload_preset", "qtt2g8ao");

      const options = {
        method: "POST",
        body: formData,
      };

      fetch(
        "https://api.Cloudinary.com/v1_1/ayudigital/image/upload",
        options,
      )
        .then((res) => res.json())
        .then(async (res) => {
          const { secure_url } = res;

          let payload;

          if (cloneActivity) {
            payload = {
              parentId: cloneActivity?.id,
              name: data?.name || cloneActivity?.name,
              description:
                data?.profile?.description ||
                cloneActivity?.profile?.description,
              profile: {
                ...profile,
                picture: secure_url,
              },
            };

            await cloneActivityReq(
              cloneActivity.id,
              payload,
            );
          } else {
            payload = {
              ...data,
              parentResource: "CLUB",
              parentId:
                propsClubData?.data.id ||
                selectedResource.toString(),
              handle:
                handle || slugify(name, { lower: true }),
              profile: {
                ...profile,
                picture: secure_url,
              },
              settings: {
                ...data.settings,
                participate_call_to_action:
                  settings.participate_call_to_action,
                contribute_call_to_action:
                  settings.contribute_call_to_action,
                displayParticipants:
                  settings.displayParticipants,
                displayContributions:
                  settings.displayContributions,
              },
              meta: {},
            };

            delete payload.visibilityOptions;
            delete payload.selectedResource;
            delete payload.imagePreview;
            delete payload.statusClicked;
            delete payload.typeOptions;
            delete payload.selectedFile;
            delete payload.clubsData;

            if (!editActivity) {
              await createActivityAction(payload);
            } else {
              const updatedPayload = {
                ...payload,
              };
              await updateActivityAction(
                updatedPayload.id,
                updatedPayload,
              );
            }
          }
        })
        .catch((err) => {
          toast.error(
            "Error uploading the picture!\nPlease try again.",
          );
          setdata({ ...data, isSending: false });
        });
    }
  };

  useEffect(() => {
    if (cloneActivity !== null) {
      setdata({
        ...data,
        ...cloneActivity,
        name: `${cloneActivity?.name} copy`,
        selectedResource: cloneActivity?.parentId,
        settings: {
          ...data.settings,
          ...cloneActivity?.settings,
          campaignNotEnd: !!(
            cloneActivity?.settings?.campaignNotEnd &&
            cloneActivity?.settings?.campaignNotEnd === "1"
          ),
        },
        meta: {
          ...data.meta,
          ...cloneActivity?.meta,
        },
      });
    }
  }, [cloneActivity]);

  useEffect(() => {
    if (editActivity !== null) {
      setdata({
        ...data,
        ...editActivity,
        selectedResource: editActivity.parentId,
        settings: {
          ...data.settings,
          ...editActivity.settings,
          campaignNotEnd: !!(
            editActivity?.settings?.campaignNotEnd &&
            editActivity?.settings?.campaignNotEnd === "1"
          ),
        },
        meta: {
          ...data.meta,
          ...editActivity.meta,
        },
      });
    }
  }, [editActivity]);

  useEffect(() => {
    setClubsData(
      getAllClubsFromLocalStorage(),
    );
  }, []);

  useEffect(() => {
    if (
      isCreateModalVisible === false &&
      editActivity === null
    ) {
      setdata(initialState);
    }
  }, [isCreateModalVisible]);

  useEffect(() => {
    if (
      !isFromActivityDetailsPage &&
      updateActivityStatus === "success"
    ) {
      getActivity();
    }
    if (updateActivityStatus === "success") {
      setIsLoading(false);
      toast.success("Activity updated successfully");
      changeIsCreateModal();
    }
    if (updateActivityStatus === "error") {
      toast.error("Activity was not updated successfully");
    }

    if (createActivityStatus === "success") {
      setIsLoading(false);
      getActivity();

      toast.success(
        `${getLabelByName(ACTIVITY)} ${getLabelByName(
          CREATED_SUCCESSFULLY,
        )}`,
      );

      changeIsCreateModal();

      const { id: activityId, name: activityName } =
        createActivityData?.data;

      history.push(
        `/manage/activities/${activityId}/${activityName}`,
      );
    }

    if (createActivityStatus === "error") {
      toast.error("Activity was not created successfully");
    }

    if (cloneActivityStatus === "success") {
      setIsLoading(false);
      getActivity();

      toast.success(
        `${cloneActivity.name} ${getLabelByName(
          CLONED_SUCCESSFULLY,
        )}`,
      );

      changeIsCreateModal();

      const { id, handle } = cloneActivityData?.data;

      history.push(`/manage/activities/${id}/${handle}`);
    }

    if (cloneActivityStatus === "error") {
      toast.error(`Activity was not cloned successfully`);
    }
  }, [
    createActivityStatus,
    updateActivityStatus,
    cloneActivityStatus,
  ]);

  // eslint-disable-next-line no-nested-ternary
  const modalTitle = editActivity
    ? `Edit "${getLabelByName(ACTIVITY)}"`
    : cloneActivity
    ? `Copy of "${cloneActivity?.name} ${getLabelByName(
        ACTIVITY,
      )}"`
    : `Create "${getLabelByName(ACTIVITY)}"`;

  return (
    <Modal
      size="lg"
      show={isCreateModalVisible}
      onHide={changeIsCreateModal}>
      <div>
        <div
          style={{
            padding: mobile
              ? "1rem 1rem 0 1rem"
              : "1rem 2rem 0 2rem",
            font: ".8rem",
          }}>
          <div>
            <h2>{modalTitle}</h2>
            <p>
              {`${getLabelByName(
                ADD_ACTIVITY_INFORMATION_DESCRIPTION,
              )} ${editActivity ? "edit" : "create"}.`}
            </p>
          </div>
        </div>
        <div
          style={{
            padding: mobile
              ? "0 1rem 1rem 1rem"
              : "0 2rem 1rem 2rem",
          }}>
          <form id="form" className={classes.form}>
            <FormControl
              className={classes.input}
              variant="outlined">
              <TextField
                id="name"
                label="Name"
                name="name"
                variant="outlined"
                onChange={handleChange}
                value={name}
                required
              />
            </FormControl>
            <FormControl
              className={
                mobile ? classes.input : classes.inputLeft
              }
              margin="normal"
              fullWidth
              variant="outlined">
              <InputLabel id="club">
                {getLabelByName(CLUB)}
              </InputLabel>
              <Select
                labelId="club"
                id="type"
                disabled={!!propsClubData}
                value={
                  parseInt(propsClubData?.data.id, 10) ||
                  parseInt(selectedResource, 10)
                }
                required
                onChange={(e) =>
                  setdata({
                    ...data,
                    selectedResource: e.target.value,
                  })
                }
                label="Club">
                {clubsOptions.map((club) => {
                  return (
                    <MenuItem
                      value={
                        propsClubData?.data.id || club.value
                      }
                      selected={
                        parseInt(selectedResource, 10) ===
                        (parseInt(
                          propsClubData?.data.id,
                          10,
                        ) || parseInt(club.value, 10))
                      }>
                      {propsClubData?.data.name ||
                        club.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              className={
                mobile ? classes.input : classes.inputRight
              }
              margin="normal"
              variant="outlined">
              <InputLabel id="club">
                {getLabelByName(VISIBILITY)}
              </InputLabel>
              <Select
                labelId="visibility"
                id="visibility"
                required
                value={visibility}
                onChange={(e) =>
                  setdata({
                    ...data,
                    visibility: e.target.value,
                  })
                }
                label="Visibility">
                {visibilityOptions.map((option) => (
                  <MenuItem
                    value={option.value}
                    selected={
                      editActivity?.visibility ===
                      option.value
                    }>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className={classes.input}
              variant="outlined">
              <TextField
                id="description"
                label="Description"
                name="description"
                multiline
                margin="normal"
                rows={10}
                variant="outlined"
                onChange={handleChange}
                required
                value={data?.profile?.description}
              />
            </FormControl>
            <FormControl
              className={
                mobile ? classes.input : classes.inputLeft50
              }
              margin="normal"
              variant="outlined">
              <TextField
                id="startDate"
                name="startDate"
                label="Start Date"
                type="datetime-local"
                variant="outlined"
                className={classes.textField}
                required
                InputLabelProps={{
                  shrink: true,
                }}
                value={
                  startDate &&
                  moment(startDate * 1000).format(
                    "YYYY-MM-DDThh:mm",
                  )
                }
                onChange={handleChange}
              />
            </FormControl>
            <FormControl
              className={
                mobile
                  ? classes.input
                  : classes.inputRight50
              }
              margin="normal"
              variant="outlined">
              <TextField
                id="endDate"
                name="endDate"
                label="End Date"
                type="datetime-local"
                variant="outlined"
                disabled={settings.campaignNotEnd}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
                value={
                  endDate &&
                  moment(endDate * 1000).format(
                    "YYYY-MM-DDThh:mm",
                  )
                }
              />
            </FormControl>
            <FormControl fullWidth component="fieldset">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={settings.campaignNotEnd}
                    onChange={() => handleEndlessChange()}
                    name="campaignNotEnd"
                    value={!!settings.campaignNotEnd}
                    color="primary"
                  />
                }
                label="Endless"
              />
            </FormControl>
            <FormControl fullWidth component="fieldset">
              <FormLabel component="legend">
                {getLabelByName(
                  ACTIVITY_STATUS_DESCRIPTION,
                )}
              </FormLabel>
              <RadioGroup
                row
                aria-label="Activity-status"
                name="Status"
                onChange={handleRadio}>
                {statuses.map((statusItem) => (
                  <FormControlLabel
                    value={statusItem.value}
                    id={`check-${statusItem.value}`}
                    labelPlacement="end"
                    checked={status === statusItem.value}
                    control={<Radio color="primary" />}
                    label={statusItem.text}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            <input
              accept="image/*"
              className={classes.uploadInput}
              id="icon-button-file"
              onChange={(e) => fileChange(e)}
              type="file"
            />
            <label
              htmlFor="icon-button-file"
              style={{
                border: "dashed 1px #F38F46",
                backgroundColor: "#F7F4F2",
                padding: "10px",
                borderRadius: "10px",
              }}>
              <>
                {imagePreview || profile?.picture ? (
                  <img
                    src={previewToDisplay}
                    alt="header-logo"
                    style={{
                      margin: ".8rem",
                      width:
                        (imagePreview ||
                          profile?.picture) &&
                        "25px",
                      height:
                        (imagePreview ||
                          profile?.picture) &&
                        "25px",
                      objectFit:
                        (imagePreview ||
                          profile?.picture) &&
                        "cover",
                      borderRadius:
                        (imagePreview ||
                          profile?.picture) &&
                        "50%",
                    }}
                  />
                ) : (
                  <IconButton
                    aria-label="upload picture"
                    component="span">
                    <img
                      src={cameraOutline}
                      style={{ width: "20px" }}
                      alt="nav-icon"
                    />
                  </IconButton>
                )}
              </>
              {getLabelByName(SELECT_FROM_COMPUTER)}
            </label>
            <hr
              style={{
                width: "unset",
                marginTop: "1rem",
              }}
            />
            <h5>{getLabelByName(ADVANCED_SETTINGS)}</h5>
            <FormControl
              className={`${classes.input} call-to-action-input`}
              variant="outlined">
              <TextField
                id="call_to_action"
                label="Participate call to action"
                variant="outlined"
                name="participate_call_to_action"
                value={
                  settings &&
                  settings.participate_call_to_action
                }
                onChange={handleChange}
              />
            </FormControl>
            <br />
            <FormControl
              className={`${classes.input} call-to-action-input`}
              variant="outlined">
              <TextField
                id="call_to_action"
                label="Contribute call to action"
                variant="outlined"
                name="contribute_call_to_action"
                value={
                  settings &&
                  settings.contribute_call_to_action
                }
                onChange={handleChange}
              />
            </FormControl>
            <br />
            <FormControl
              className={
                mobile ? classes.input : classes.inputLeft
              }>
              <FormControlLabel
                control={
                  <Switch
                    value={
                      settings &&
                      settings.displayParticipants
                    }
                    color="primary"
                    defaultChecked={false}
                    checked={settings.displayParticipants}
                    onChange={() => {
                      setdata({
                        ...data,
                        settings: {
                          ...data.settings,
                          displayParticipants:
                            !data.settings
                              .displayParticipants,
                        },
                      });
                    }}
                    name="display-participants"
                  />
                }
                label="Display Participants on Activity page"
              />
            </FormControl>
            <br />
            <FormControl
              className={
                mobile ? classes.input : classes.inputLeft
              }>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    value={
                      settings &&
                      settings.displayContributions
                    }
                    defaultChecked={false}
                    checked={settings.displayContributions}
                    onChange={() => {
                      setdata({
                        ...data,
                        settings: {
                          ...data.settings,
                          displayContributions:
                            !data.settings
                              .displayContributions,
                        },
                      });
                    }}
                    name="display-contributions"
                  />
                }
                label="Display Contributions on Activity page"
              />
            </FormControl>
          </form>
        </div>
        <Modal.Footer className={classes.input}>
          <Button
            className={classes.button}
            color="primary"
            onClick={changeIsCreateModal}>
            {getLabelByName(CANCEL)}
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            form="form"
            type="button"
            onClick={handleSaveOnClick}
            disabled={isLoading}>
            {isLoading
              ? `${getLabelByName(SAVING)}...`
              : getLabelByName(SAVE)}
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

CreateActivityModal.propTypes = {
  isCreateModalVisible: PropTypes.bool.isRequired,
  changeIsCreateModal: PropTypes.func.isRequired,
  createActivity: PropTypes.func,
  updateActivity: PropTypes.func,
  editActivity: PropTypes.any,
  cloneActivityReq: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  createActivityData:
    state.createActivityReducer.createActivityData,
  createActivityDataError:
    state.createActivityReducer.createActivityDataError,
  createActivityStatus:
    state.createActivityReducer.createActivityStatus,
  updateActivityData:
    state.updateActivityReducer.updateActivityData,
  updateActivityDataError:
    state.updateActivityReducer.updateActivityDataError,
  updateActivityStatus:
    state.updateActivityReducer.updateActivityStatus,
  cloneActivityData:
    state.cloneActivityReducer.cloneActivityData,
  cloneActivityDataError:
    state.cloneActivityReducer.cloneActivityDataError,
  cloneActivityStatus:
    state.cloneActivityReducer.cloneActivityStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    createActivity,
    updateActivity,
    cloneActivityReq,
  }),
)(CreateActivityModal);
