import { SITE_CONTENT_SUCCESS, SITE_CONTENT_ERROR } from '../actions/types';

const initialState = {
  adminData: null,
  adminDataError: null,
  adminStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SITE_CONTENT_SUCCESS:
      return {
        ...state,
        adminData: action.payload,
        adminDataError: null,
        adminStatus: 'success',
      };
    case SITE_CONTENT_ERROR:
      return {
        ...state,
        adminDataError: action.payload,
        adminData: null,
        adminStatus: 'error',
      };
    default:
      return {
        ...state,
        adminStatus: null,
      };
  }
};
