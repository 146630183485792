/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getLabelByName } from '../../../helpers/helperFunctions';
import { CLUB, colors, INFORMATION } from '../../../helpers/constants';
import ResourceItemCard from '../FindResource/ResourceItemCard';

class ClubInformationSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { selectedResource, setPageTracking, resourceType, } = this.props;

    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className="institute-card article-card">
          <div className="create-card-header">
            <div className="card-header-title">
              <h5>
              {`${getLabelByName(CLUB)} ${getLabelByName(INFORMATION)}`}
                {' '}
                <span className="asterisk">*</span>
              </h5>
            </div>
          </div>
          <div className="col-md-12 d-flex justify-content-between align-items-center">
            <div className="w-100">
              <ResourceItemCard resourceType={resourceType} resource={selectedResource} selectedResource={selectedResource} selectResource={() => {}} />
            </div>
            <div
              className="mb-3 ml-3"
              style={{
                textDecoration: 'underline',
                color: colors.primaryColor,
                cursor: 'pointer',
              }}
              onClick={() => setPageTracking('find-resource')}
            >
              Change
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ClubInformationSection.propTypes = {
  selectedResource: PropTypes.any,
  setPageTracking: PropTypes.any,
};

export default ClubInformationSection;
