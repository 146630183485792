import React from "react";
import apiCall from "../../../helpers/apiCall";
import { authHeaders, getUserAuthHeaders } from "./functions/session";

import {
    USERS_SESSION_USER,
} from "../types";

const apiEndpoint = "/session";

export const createSession = (credentials) => (dispatch) => {
    apiCall.post(
        apiEndpoint,
        credentials,
        {
            headers: getUserAuthHeaders(
                apiEndpoint,
                credentials,
            )
        }
    ).then((response) => {
        Object.keys(authHeaders).forEach((authHeader) => {
            let serviceHeader = authHeaders[authHeader].toLowerCase();
            if (response.headers.hasOwnProperty(serviceHeader)) {
                localStorage.setItem(`sessionUser.${authHeader}`, response.headers[serviceHeader]);
            }
        });

        dispatch({
            type: USERS_SESSION_USER,
            payload: response.data,
        });
    }).catch((err) => {
        dispatch({
            type: USERS_SESSION_USER,
            payload: err,
        });
    })
}

export const verifySession = () => (dispatch) => {
    apiCall.get(
        apiEndpoint,
        {
            headers: getUserAuthHeaders(
                apiEndpoint
            )
        }
    ).then((response) => {
        dispatch({
            type: USERS_SESSION_USER,
            payload: response.data,
        });
    }).catch((err) => {
        dispatch({
            type: USERS_SESSION_USER,
            payload: err,
        });
    })
}

export const deleteSession = () => (dispatch) => {
    apiCall.delete(
        apiEndpoint,
        {
            headers: getUserAuthHeaders(
                apiEndpoint
            )
        }
    ).then((response) => {
        Object.keys(authHeaders).forEach((authHeader) => {
            localStorage.removeItem(`sessionUser.${authHeader}`);
        });

        dispatch({
            type: USERS_SESSION_USER,
            payload: response.data,
        });
    }).catch((err) => {
        dispatch({
            type: USERS_SESSION_USER,
            payload: err,
        });
    })
}
