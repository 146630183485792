import {
  POST_REACTION_ERROR, POST_REACTION_SUCCESS,
} from '../actions/types';

const initialState = {
  postReactionData: null,
  postReactionDataError: null,
  postReactionStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_REACTION_SUCCESS:
      return {
        ...state,
        postReactionData: action.payload,
        postReactionDataError: null,
        postReactionStatus: 'success',
      };
    case POST_REACTION_ERROR:
      return {
        ...state,
        postReactionDataError: action.payload,
        postReactionData: null,
        postReactionStatus: 'error',
      };
    default:
      return {
        ...state,
        postReactionStatus: null,
      };
  }
};
