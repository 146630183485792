/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  ArrowBackIos,
  Email,
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
} from "@material-ui/icons";
import { toast } from "react-toastify";

import { getSessionAction } from "../redux/actions/authActions";
import { postContent } from "../redux/actions/adminActions";
import { getLanding } from "../redux/actions/landingActions";
import Footer from "./Footer";
import Navbar from "./Navbar";
import NotFound from "./NotFound";
import logo from "../assets/logo-opac.svg";
import aboutImage from "../assets/what-we-do.png";
import servicesImage from "../assets/how-we-do.png";
import backgroundImage from "../assets/bg-wave.svg";
import Fallback from "./Fallback";
import { checkSession, getProfileDataFromLocalStorage } from "../helpers/helperFunctions";
import { colors } from "../helpers/constants";

export class SiteManagement extends Component {
  state = {
    isLoading: false,
    isJoining: false,
    currentPage: 1,
    "welcome-header": null,
    "welcome-text": null,
    "site-slogan": null,
    "about-header": null,
    "about-text": null,
    "services-header": null,
    "services-text": null,
    "copyright-text": null,
    "login-text": null,
    "signup-text": null,
    "footer-text": null,
    "footer-link": null,
    "footer-button": null,

    email: null,
    instagram: null,
    linkedin: null,
    facebook: null,
    twitter: null,

    logo: null,
    "background-image": null,
    "about-image": null,
    "services-image": null,

    imagePreview: {},

    profileData: getProfileDataFromLocalStorage(),
    pathname: this.props.location.pathname,
  };

  async UNSAFE_componentWillMount() {
    const { props, state } = this;
    const { profileData, pathname } = state;
    if (!profileData || profileData.status !== 200) {
      window.location.href = `/login?redirect_to=${pathname}`;
    }
    await props.getSessionAction();
    await props.getLanding();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { props, state } = this;
    const { pathname } = state;
    const { dataError, status, data, adminDataError, adminData, adminStatus } =
      nextProps;

    checkSession(data, status, pathname, props);

    switch (adminStatus) {
      case "success":
        switch (adminData.status) {
          case 200:
            await this.props.getLanding();
            break;
          default:
            toast.error("There was an unexpected error!");
            break;
        }
        break;
      case "error":
        toast.error("There was an unexpected error!");
        break;
      default:
        break;
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  submitContent = async (e, valueObject, prop) => {
    const { props } = this;
    const property = this.state[prop] || valueObject.data[prop];
    e.preventDefault();
    const id = valueObject.id;
    const name = valueObject.name;
    const payload = {
      id,
      name,
      data: { ...valueObject.data, [prop]: property },
    };
    await props.postContent(payload, id);
  };

  submitSociaLinks = async (e, socialInfo) => {
    e.preventDefault();
    const { props } = this;
    const id = socialInfo && socialInfo.id;
    const name = socialInfo && socialInfo.name;
    e.preventDefault();
    const payload = {
      id,
      name,
      data: {
        email: this.state.email || (socialInfo ? socialInfo.data.email : ""),
        instagram:
          this.state.instagram || (socialInfo ? socialInfo.data.instagram : ""),
        linkedin:
          this.state.linkedin || (socialInfo ? socialInfo.data.linkedin : ""),
        facebook:
          this.state.facebook || (socialInfo ? socialInfo.data.facebook : ""),
        twitter:
          this.state.twitter || (socialInfo ? socialInfo.data.twitter : ""),
      },
    };
    await props.postContent(payload, id);
  };

  fileChange = (e, prop) => {
    this.setState({ [prop]: e.target.files[0] });
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.setState({
        imagePreview: {
          ...this.state.imagePreview,
          [prop]: fileReader.result,
        },
      });
    };
    fileReader.readAsDataURL(e.target.files[0]);
  };

  submitImage = (e, prop, landingInfo) => {
    const { props } = this;
    const id = landingInfo.id;
    const name = landingInfo.name;
    const formData = new FormData();
    formData.append("file", this.state[prop]);
    formData.append("upload_preset", "qtt2g8ao");
    const options = {
      method: "POST",
      body: formData,
    };
    fetch("https://api.Cloudinary.com/v1_1/ayudigital/image/upload", options)
      .then((res) => res.json())
      .then(async (res) => {
        const { secure_url } = res;
        const payload = {
          id,
          name,
          data: { ...landingInfo.data, [prop]: secure_url },
        };
        await props.postContent(payload, id);
      })
      .catch((err) =>
        toast.error("Error uploading the picture!\nPlease try again.")
      );
  };

  render() {
    const { REACT_APP_APPLICATION_NAME } = process.env;
    document.title = `${
      REACT_APP_APPLICATION_NAME || "SOSO"
    } - Site Management`;
    const sectionsList = [
      { ref: "logo", name: "Logo" },
      { ref: "background-image", name: "Background Image" },
      { ref: "welcome-header", name: "Welcome Header" },
      { ref: "welcome-text", name: "Welcome Text" },
      { ref: "site-slogan", name: "Slogan" },
      { ref: "about-header", name: "About Header" },
      { ref: "about-text", name: "About Text" },
      { ref: "about-image", name: "About Image" },
      { ref: "services-header", name: "Services Header" },
      { ref: "services-text", name: "Services Text" },
      { ref: "services-image", name: "Services Image" },
      {
        ref: "social-media-links",
        name: "Social Media Links",
      },
      { ref: "copyright-text", name: "Copyright Text" },
    ];
    const { imagePreview } = this.state;
    const { landingData, landingDataError, landingStatus } = this.props;

    if (landingDataError || (landingData && landingData.status === "500")) {
      return <Fallback route="/manage-site" />;
    }

    let landingInfo = null;
    let socialInfo = null;
    if (landingData && landingData.data) {
      landingInfo = landingData.data.filter(
        (x) => x.name === "landing-page"
      )[0];
    }
    if (landingData && landingData.data) {
      socialInfo = landingData.data.filter((x) => x.name === "links")[0];
    }

    const getImagePreview = (prop, defaultImage) => {
      if (imagePreview[prop]) {
        return imagePreview[prop];
      }
      if (landingInfo && landingInfo.data && landingInfo.data[prop]) {
        return landingInfo.data[prop];
      }
      return defaultImage;
    };

    return (
      <>
        <Navbar pathname="profile" />
        <div className="manage-site-page container">
          <div className="buttons-row">
            <div id="back-link">
              <div className="d-flex justify-content-center align-items-center">
                <Link
                  to="/manage/posts"
                  style={{
                    color: colors.textPrimary,
                    font: "Book 20px/17px Avenir",
                  }}
                >
                  <ArrowBackIos className="arrow-back-icon" />
                  {"  "}
                  Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="manage-sections">
                <div className="sections-title">Sections</div>
                <div className="sections-list">
                  {sectionsList.map((section) => (
                    <a href={`#${section.ref}`}>{section.name}</a>
                  ))}
                </div>
              </div>
            </div>
            <div className="content-main col-md-8">
              <div className="container">
                <div className="content-title">Site Content</div>
                <div className="content-sections">
                  <div id="logo" className="card section-card image-card">
                    <div className="card-body">
                      <div className="card-details">
                        <h5 className="card-title">Logo</h5>
                        <p className="helper-text">
                          Upload your brand logo, this would be used on multiple
                          pages across the app.
                        </p>
                        <br />
                        <p className="helper-text font-italic">
                          Can upload png, jpeg, jpg and svg format
                        </p>
                        <div>
                          <input
                            type="file"
                            className="custom-file-inputz mb-5"
                            id="customFile"
                            onChange={(e) => this.fileChange(e, "logo")}
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-sm submit-btn"
                          disabled={this.state.logo === null}
                          onClick={(e) =>
                            this.submitImage(e, "logo", landingInfo)
                          }
                        >
                          Upload image
                        </button>
                      </div>
                      <div className="card-image-preview">
                        <img
                          src={getImagePreview("logo", logo)}
                          alt="card-preview"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="background-image"
                    className="card section-card image-card"
                  >
                    <div className="card-body">
                      <div className="card-details">
                        <h5 className="card-title">Homepage Background</h5>
                        <p className="helper-text">
                          Upload your preferred background image, this would
                          appear only on the homepage.
                        </p>
                        <br />
                        <p className="helper-text font-italic">
                          Can upload png, jpeg, jpg and svg format
                        </p>
                        <div>
                          <input
                            type="file"
                            className="custom-file-inputz mb-5"
                            id="customFile"
                            onChange={(e) =>
                              this.fileChange(e, "background-image")
                            }
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-sm submit-btn"
                          disabled={this.state["background-image"] === null}
                          onClick={(e) =>
                            this.submitImage(e, "background-image", landingInfo)
                          }
                        >
                          Upload image
                        </button>
                      </div>
                      <div className="card-image-preview">
                        <img
                          src={getImagePreview(
                            "background-image",
                            backgroundImage
                          )}
                          alt="card-preview"
                        />
                      </div>
                    </div>
                  </div>
                  <div id="welcome-header" className="card section-card">
                    <div className="card-body">
                      <div className="card-details">
                        <h5 className="card-title">Welcome Header</h5>
                        <form
                          onSubmit={(e) =>
                            this.submitContent(e, landingInfo, "welcome-header")
                          }
                          className="mt-3"
                        >
                          <div className="form-group">
                            <input
                              type="text"
                              name="welcome-header"
                              className="form-control"
                              id="input-welcomeHeader"
                              placeholder="Welcome..."
                              value={
                                this.state["welcome-header"] === null
                                  ? landingInfo &&
                                    landingInfo.data["welcome-header"]
                                  : this.state["welcome-header"]
                              }
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="post-buttons">
                            <div className="cancel-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn btn-reverse"
                                disabled={false}
                                onClick={() => {}}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="post-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn"
                                disabled={false}
                                // onClick={() => {}}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div id="welcome-text" className="card section-card">
                    <div className="card-body">
                      <div className="card-details">
                        <h5 className="card-title">Welcome Text</h5>
                        <form
                          onSubmit={(e) =>
                            this.submitContent(e, landingInfo, "welcome-text")
                          }
                          className="mt-3"
                        >
                          <div className="section-textarea">
                            <textarea
                              name="welcome-text"
                              rows="3"
                              placeholder="Welcome..."
                              value={
                                this.state["welcome-text"] === null
                                  ? landingInfo &&
                                    landingInfo.data["welcome-text"]
                                  : this.state["welcome-text"]
                              }
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="post-buttons">
                            <div className="cancel-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn btn-reverse"
                                disabled={false}
                                onClick={() => {}}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="post-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn"
                                disabled={false}
                                // onClick={() => {}}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div id="site-slogan" className="card section-card">
                    <div className="card-body">
                      <div className="card-details">
                        <h5 className="card-title">Slogan</h5>
                        <form
                          onSubmit={(e) =>
                            this.submitContent(e, landingInfo, "site-slogan")
                          }
                          className="mt-3"
                        >
                          <div className="section-textarea">
                            <textarea
                              name="site-slogan"
                              rows="2"
                              placeholder="Hooray..."
                              value={
                                this.state["site-slogan"] === null
                                  ? landingInfo &&
                                    landingInfo.data["site-slogan"]
                                  : this.state["site-slogan"]
                              }
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="post-buttons">
                            <div className="cancel-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn btn-reverse"
                                disabled={false}
                                onClick={() => {}}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="post-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn"
                                disabled={false}
                                // onClick={() => {}}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div id="about-header" className="card section-card">
                    <div className="card-body">
                      <div className="card-details">
                        <h5 className="card-title">About Header</h5>
                        <form
                          onSubmit={(e) =>
                            this.submitContent(e, landingInfo, "about-header")
                          }
                          className="mt-3"
                        >
                          <div className="form-group">
                            <input
                              type="text"
                              name="about-header"
                              className="form-control"
                              id="input-aboutHeader"
                              placeholder="What We Do"
                              value={
                                this.state["about-header"] === null
                                  ? landingInfo &&
                                    landingInfo.data["about-header"]
                                  : this.state["about-header"]
                              }
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="post-buttons">
                            <div className="cancel-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn btn-reverse"
                                disabled={false}
                                onClick={() => {}}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="post-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn"
                                disabled={false}
                                // onClick={() => {}}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div id="about-text" className="card section-card">
                    <div className="card-body">
                      <div className="card-details">
                        <h5 className="card-title">About Text</h5>
                        <form
                          onSubmit={(e) =>
                            this.submitContent(e, landingInfo, "about-text")
                          }
                          className="mt-3"
                        >
                          <div className="section-textarea">
                            <textarea
                              name="about-text"
                              rows="3"
                              placeholder="We are..."
                              value={
                                this.state["about-text"] === null
                                  ? landingInfo &&
                                    landingInfo.data["about-text"]
                                  : this.state["about-text"]
                              }
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="post-buttons">
                            <div className="cancel-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn btn-reverse"
                                disabled={false}
                                onClick={() => {}}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="post-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn"
                                disabled={false}
                                // onClick={() => {}}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    id="about-image"
                    className="card section-card image-card"
                  >
                    <div className="card-body">
                      <div className="card-details">
                        <h5 className="card-title">About Image</h5>
                        <p className="helper-text">
                          Upload your preferred image for this about section.
                        </p>
                        <br />
                        <p className="helper-text font-italic">
                          Can upload png, jpeg, jpg and svg format
                        </p>
                        <div>
                          <input
                            type="file"
                            className="custom-file-inputz mb-5"
                            id="customFile"
                            onChange={(e) => this.fileChange(e, "about-image")}
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-sm submit-btn"
                          // disabled={this.state['about-image'] === null}
                          onClick={(e) =>
                            this.submitImage(e, "about-image", landingInfo)
                          }
                        >
                          Upload image
                        </button>
                      </div>
                      <div className="card-image-preview">
                        <img
                          src={getImagePreview("about-image", aboutImage)}
                          alt="card-preview"
                        />
                      </div>
                    </div>
                  </div>
                  <div id="services-header" className="card section-card">
                    <div className="card-body">
                      <div className="card-details">
                        <h5 className="card-title">Services Header</h5>
                        <form
                          onSubmit={(e) =>
                            this.submitContent(
                              e,
                              landingInfo,
                              "services-header"
                            )
                          }
                          className="mt-3"
                        >
                          <div className="form-group">
                            <input
                              type="text"
                              name="services-header"
                              className="form-control"
                              id="input-servicesHeader"
                              placeholder="How We Do It"
                              value={
                                this.state["services-header"] === null
                                  ? landingInfo &&
                                    landingInfo.data["services-header"]
                                  : this.state["services-header"]
                              }
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="post-buttons">
                            <div className="cancel-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn btn-reverse"
                                disabled={false}
                                onClick={() => {}}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="post-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn"
                                disabled={false}
                                onClick={() => {}}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div id="services-text" className="card section-card">
                    <div className="card-body">
                      <div className="card-details">
                        <h5 className="card-title">Services Text</h5>
                        <form
                          onSubmit={(e) =>
                            this.submitContent(e, landingInfo, "services-text")
                          }
                          className="mt-3"
                        >
                          <div className="section-textarea">
                            <textarea
                              name="services-text"
                              rows="3"
                              placeholder="We are..."
                              value={
                                this.state["services-text"] === null
                                  ? landingInfo &&
                                    landingInfo.data["services-text"]
                                  : this.state["services-text"]
                              }
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="post-buttons">
                            <div className="cancel-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn btn-reverse"
                                disabled={false}
                                onClick={() => {}}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="post-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn"
                                disabled={false}
                                onClick={() => {}}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    id="services-image"
                    className="card section-card image-card"
                  >
                    <div className="card-body">
                      <div className="card-details">
                        <h5 className="card-title">Services Image</h5>
                        <p className="helper-text">
                          Upload your preferred image for this services section.
                        </p>
                        <br />
                        <p className="helper-text font-italic">
                          Can upload png, jpeg, jpg and svg format
                        </p>
                        <div>
                          <input
                            type="file"
                            className="custom-file-inputz mb-5"
                            id="customFile"
                            onChange={(e) =>
                              this.fileChange(e, "services-image")
                            }
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-sm submit-btn"
                          disabled={this.state["services-image"] === null}
                          onClick={(e) =>
                            this.submitImage(e, "services-image", landingInfo)
                          }
                        >
                          Upload image
                        </button>
                      </div>
                      <div className="card-image-preview">
                        <img
                          src={getImagePreview("services-image", servicesImage)}
                          alt="card-preview"
                        />
                      </div>
                    </div>
                  </div>
                  <div id="copyright-text" className="card section-card">
                    <div className="card-body">
                      <div className="card-details">
                        <h5 className="card-title">Social Media Links</h5>
                        <form
                          onSubmit={(e) => this.submitSociaLinks(e, socialInfo)}
                          className="mt-3"
                        >
                          <div className="form-group">
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <span className="pr-3">
                                    <Email />
                                  </span>
                                  Email
                                </span>
                              </div>
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                id="input-social-email"
                                placeholder="contact@sosocameroon.com"
                                value={
                                  this.state.email === null
                                    ? socialInfo && socialInfo.data.email
                                    : this.state.email
                                }
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <span className="pr-3">
                                    <Instagram />
                                  </span>
                                  Instagram
                                </span>
                              </div>
                              <input
                                type="text"
                                name="instagram"
                                className="form-control"
                                id="input-social-instagram"
                                placeholder="sosocameroon"
                                value={
                                  this.state.instagram === null
                                    ? socialInfo && socialInfo.data.instagram
                                    : this.state.instagram
                                }
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <span className="pr-3">
                                    <LinkedIn />
                                  </span>
                                  LinkedIn
                                </span>
                              </div>
                              <input
                                type="text"
                                name="linkedin"
                                className="form-control"
                                id="input-social-linkedin"
                                placeholder="sosocameroon"
                                value={
                                  this.state.linkedin === null
                                    ? socialInfo && socialInfo.data.linkedin
                                    : this.state.linkedin
                                }
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <span className="pr-3">
                                    <Facebook />
                                  </span>
                                  Facebook
                                </span>
                              </div>
                              <input
                                type="text"
                                name="facebook"
                                className="form-control"
                                id="input-social-facebook"
                                placeholder="sosocameroon"
                                value={
                                  this.state.facebook === null
                                    ? socialInfo && socialInfo.data.facebook
                                    : this.state.facebook
                                }
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <span className="pr-3">
                                    <Twitter />
                                  </span>
                                  Twitter
                                </span>
                              </div>
                              <input
                                type="text"
                                name="twitter"
                                className="form-control"
                                id="input-social-twitter"
                                placeholder="sosocameroon"
                                value={
                                  this.state.twitter === null
                                    ? socialInfo && socialInfo.data.twitter
                                    : this.state.twitter
                                }
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="post-buttons">
                            <div className="cancel-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn btn-reverse"
                                disabled={false}
                                onClick={() => {}}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="post-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn"
                                disabled={false}
                                // onClick={() => {}}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div id="copyright-text" className="card section-card">
                    <div className="card-body">
                      <div className="card-details">
                        <h5 className="card-title">Copyright Text</h5>
                        <form
                          onSubmit={(e) =>
                            this.submitContent(e, landingInfo, "copyright-text")
                          }
                          className="mt-3"
                        >
                          <div className="form-group">
                            <input
                              type="text"
                              name="copyright-text"
                              className="form-control"
                              id="input-copyright-text"
                              placeholder="Copyright 2020 SOSO Cameroon. All Rights Reserved."
                              value={
                                this.state["copyright-text"] === null
                                  ? landingInfo &&
                                    landingInfo.data["copyright-text"]
                                  : this.state["copyright-text"]
                              }
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="post-buttons">
                            <div className="cancel-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn btn-reverse"
                                disabled={false}
                                onClick={() => {}}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="post-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn"
                                disabled={false}
                                // onClick={() => {}}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div id="login-text" className="card section-card">
                    <div className="card-body">
                      <div className="card-details">
                        <h5 className="card-title">Login Text</h5>
                        <form
                          onSubmit={(e) =>
                            this.submitContent(e, landingInfo, "login-text")
                          }
                          className="mt-3"
                        >
                          <div className="form-group">
                            <input
                              type="text"
                              name="login-text"
                              className="form-control"
                              id="input-login-text"
                              placeholder="Login text..."
                              value={
                                this.state["login-text"] === null
                                  ? landingInfo &&
                                    landingInfo.data["login-text"]
                                  : this.state["login-text"]
                              }
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="post-buttons">
                            <div className="cancel-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn btn-reverse"
                                disabled={false}
                                onClick={() => {}}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="post-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn"
                                disabled={false}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div id="signup-text" className="card section-card">
                    <div className="card-body">
                      <div className="card-details">
                        <h5 className="card-title">Signup Text</h5>
                        <form
                          onSubmit={(e) =>
                            this.submitContent(e, landingInfo, "signup-text")
                          }
                          className="mt-3"
                        >
                          <div className="form-group">
                            <input
                              type="text"
                              name="signup-text"
                              className="form-control"
                              id="input-signup-text"
                              placeholder="Signup text..."
                              value={
                                this.state["signup-text"] === null
                                  ? landingInfo &&
                                    landingInfo.data["signup-text"]
                                  : this.state["signup-text"]
                              }
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="post-buttons">
                            <div className="cancel-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn btn-reverse"
                                disabled={false}
                                onClick={() => {}}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="post-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn"
                                disabled={false}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div id="signup-text" className="card section-card">
                    <div className="card-body">
                      <div className="card-details">
                        <h5 className="card-title">Footer</h5>
                        <form
                          onSubmit={(e) =>
                            this.submitContent(e, landingInfo, "footer-text")
                          }
                          className="mt-3"
                        >
                          <div className="form-group">
                            <label
                              className="helper-text"
                              htmlFor="input-footer-text"
                            >
                              Footer Text
                            </label>
                            <input
                              type="text"
                              name="footer-text"
                              className="form-control"
                              id="input-footer-text"
                              placeholder="Footer text..."
                              value={
                                this.state["footer-text"] === null
                                  ? landingInfo &&
                                    landingInfo.data["footer-text"]
                                  : this.state["footer-text"]
                              }
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="post-buttons">
                            <div className="cancel-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn btn-reverse"
                                disabled={false}
                                onClick={() => {}}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="post-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn"
                                disabled={false}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                        <form
                          onSubmit={(e) =>
                            this.submitContent(e, landingInfo, "footer-link")
                          }
                          className="mt-3"
                        >
                          <div className="form-group">
                            <label
                              className="helper-text"
                              htmlFor="input-footer-link"
                            >
                              Footer Link
                            </label>
                            <input
                              type="text"
                              name="footer-link"
                              className="form-control"
                              id="input-footer-link"
                              placeholder="/signup"
                              value={
                                this.state["footer-link"] === null
                                  ? landingInfo &&
                                    landingInfo.data["footer-link"]
                                  : this.state["footer-link"]
                              }
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="post-buttons">
                            <div className="cancel-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn btn-reverse"
                                disabled={false}
                                onClick={() => {}}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="post-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn"
                                disabled={false}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                        <form
                          onSubmit={(e) =>
                            this.submitContent(e, landingInfo, "footer-link")
                          }
                          className="mt-3"
                        >
                          <div className="form-group">
                            <label
                              className="helper-text"
                              htmlFor="input-footer-button"
                            >
                              Footer Button
                            </label>
                            <input
                              type="text"
                              name="footer-button"
                              className="form-control"
                              id="input-footer-button"
                              placeholder="Footer button title"
                              value={
                                this.state["footer-button"] === null
                                  ? landingInfo &&
                                    landingInfo.data["footer-button"]
                                  : this.state["footer-button"]
                              }
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="post-buttons">
                            <div className="cancel-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn btn-reverse"
                                disabled={false}
                                onClick={() => {}}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="post-button">
                              <button
                                type="submit"
                                className="btn btn-sm submit-btn"
                                disabled={false}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-block">
          <div className="footer-component">
            <Footer />
          </div>
        </div>
      </>
    );
  }
}

SiteManagement.propTypes = {
  getLanding: PropTypes.func.isRequired,
  landingDataError: PropTypes.object,
  landingData: PropTypes.object,
  landingStatus: PropTypes.string,

  postContent: PropTypes.func.isRequired,
  adminDataError: PropTypes.object,
  adminData: PropTypes.object,
  adminStatus: PropTypes.string,

  dataError: PropTypes.object,
  data: PropTypes.object,
  status: PropTypes.string,

  getSessionAction: PropTypes.func.isRequired,
  history: PropTypes.any,
  location: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  adminDataError: state.adminReducer.adminDataError,
  adminData: state.adminReducer.adminData,
  adminStatus: state.adminReducer.adminStatus,

  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    postContent,
    getLanding,
    getSessionAction,
  })
)(SiteManagement);
