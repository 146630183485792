import {
  GET_OUTPUTS_SUCCESS, GET_OUTPUTS_ERROR,
} from '../actions/types';

const initialState = {
  getOutputsData: null,
  getOutputsDataError: null,
  getOutputsStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OUTPUTS_SUCCESS:
      return {
        ...state,
        getOutputsData: action.payload,
        getOutputsDataError: null,
        getOutputsStatus: 'success',
      };
    case GET_OUTPUTS_ERROR:
      return {
        ...state,
        getOutputsDataError: action.payload,
        getOutputsData: null,
        getOutputsStatus: 'error',
      };
    default:
      return {
        ...state,
        getOutputsStatus: null,
      };
  }
};
