/* eslint-disable dot-notation */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ArrowBackIos } from '@material-ui/icons';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import '../styles/SignUp.css';
import {
  getLabelsByLanguage,
  getLanding,
} from '../redux/actions/landingActions';
import logo from '../assets/logo-opac.svg';
import bgImage from '../assets/bg-wave.svg';
import Fallback from './Fallback';
import {
  languageApiMapping,
  colors,
  welcome_back,
  welcome_back_description,
  SIGN_IN,
  hello,
  HELLO,
  HELLO_DESCRIPTION,
} from '../helpers/constants';
import {
  getLabelByName,
  getUserLanguage,
} from '../helpers/helperFunctions';
import Footer from './Footer';

class AuthContainer extends Component {
  async UNSAFE_componentWillMount() {
    this.props.getLanding();
    this.props.getLabelsByLanguage(
      languageApiMapping[getUserLanguage()],
    );
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { props } = this;

    const { landingData, landingDataError } = this.props;
    let landingInfo = null;
    if (landingData && landingData.data) {
      landingInfo = landingData.data.filter(
        (x) => x.name === 'landing-page',
      )[0];
    }

    if (
      landingDataError ||
      (landingData && landingData.status === '500')
    ) {
      return <Fallback route="/" />;
    }

    return (
      <div className="first-container auth-container">
        <div className="row m-0">
          <div id="mobile-bar" className="fixed-top">
            <div className="d-flex">
              <div className="back-button">
                <Link to="/">
                  <ArrowBackIos
                    style={{ color: colors.primaryColor }}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div
            id="form-block"
            className="col-lg-6 col-md-12">
            <div className="padding-auth">
              <div className="auth-comp">
                {props.children}
              </div>
            </div>
          </div>
          <div
            id="intro"
            className="col-lg-6 col-md-12"
            style={{
              boxShadow:
                'inset 0 0 0 2000px rgba(0, 0, 0, 0.3)',
              backgroundImage: `url(${
                landingInfo &&
                landingInfo.data &&
                landingInfo.data['background-image']
                  ? landingInfo.data['background-image']
                  : bgImage
              })`,
            }}>
            <div id="logo">
              <img
                src={
                  landingInfo &&
                  landingInfo.data &&
                  landingInfo.data['logo']
                    ? landingInfo.data['logo']
                    : logo
                }
                alt="logo"
              />
            </div>
            <div className="intro-text">
              <div className="intro text-center d-flex justify-content-center align-items-center">
                <div>
                  <h2>{getLabelByName(welcome_back)}!</h2>
                  <p>
                    {getLabelByName(
                      welcome_back_description,
                    )}
                  </p>
                  {this.props.history.location.pathname !==
                  '/login' ? (
                    <Link to="/login">
                      <button
                        type="submit"
                        className="btn btn-lg submit-btn">
                        {getLabelByName(SIGN_IN)}
                      </button>
                    </Link>
                  ) : (
                    <Link to="/signup">
                      <button
                        type="submit"
                        className="btn btn-lg submit-btn">
                        Sign up
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

AuthContainer.propTypes = {
  children: PropTypes.any,
  getLanding: PropTypes.func.isRequired,
  getLabelsByLanguage: PropTypes.func.isRequired,
  landingDataError: PropTypes.object,
  landingData: PropTypes.object,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

export const mapStateToProps = (state) => ({
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getLanding,
    getLabelsByLanguage,
  }),
)(AuthContainer);
