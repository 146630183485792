import {
  UPDATE_QUESTIONNAIRE_SUCCESS, UPDATE_QUESTIONNAIRE_ERROR,
} from '../actions/types';

const initialState = {
  updateQuestionnaireData: null,
  updateQuestionnaireDataError: null,
  updateQuestionnaireStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        updateQuestionnaireData: action.payload,
        updateQuestionnaireDataError: null,
        updateQuestionnaireStatus: 'success',
      };
    case UPDATE_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        updateQuestionnaireDataError: action.payload,
        updateQuestionnaireData: null,
        updateQuestionnaireStatus: 'error',
      };
    default:
      return {
        ...state,
        updateQuestionnaireStatus: null,
      };
  }
};
