/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { video, width, className } = this.props;

    return (
      <video
        className={className}
        width={width || '100%'}
        controls
        style={{ borderRadius: '5px', outline: 'none' }}
      >
        <source src={video} />
        Your browser does not support this video.
      </video>
    );
  }
}

VideoPlayer.propTypes = {
  video: PropTypes.any,
  width: PropTypes.any,
  className: PropTypes.string,
};

export default VideoPlayer;
