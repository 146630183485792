import apiCall from "../../../helpers/apiCall";
import { getHeaders } from "../../../helpers/getHeaders";

import {
    QUESTIONNAIRES_CREATE,
    QUESTIONNAIRES_RETRIEVE,
    QUESTIONNAIRES_UPDATE,
    QUESTIONNAIRES_DELETE,
    QUESTIONNAIRES_SEARCH,
} from "../types";

export const retrieveQuestionnaire = (id) => (dispatch) => {
    const apiEndpoint = `/questionnaire/${id}`;
    apiCall.get(
        apiEndpoint,
        {
            headers: getHeaders(
                null, 
                apiEndpoint,
            )
        }
    ).then((response) => {
        dispatch({
            type: QUESTIONNAIRES_RETRIEVE,
            payload: response.data,
        });
    }).catch((err) => {
        dispatch({
            type: QUESTIONNAIRES_RETRIEVE,
            payload: err,
        });
    }) 
}