import {
  GET_CLUB_POSTS_SUCCESS,
  GET_CLUB_POSTS_ERROR,
} from "../actions/types";

const initialState = {
  getClubPostsData: null,
  getClubPostsDataError: null,
  getClubPostsStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CLUB_POSTS_SUCCESS:
      return {
        ...state,
        getClubPostsData: action.payload,
        getClubPostsDataError: null,
        getClubPostsStatus: "success",
      };
    case GET_CLUB_POSTS_ERROR:
      return {
        ...state,
        getClubPostsDataError: action.payload,
        getClubPostsData: null,
        getClubPostsStatus: "error",
      };
    default:
      return {
        ...state,
        getClubPostsStatus: null,
      };
  }
};
