/* eslint-disable no-multi-str */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { labelsDefaults } from "./labelsDefaults";
import { DAYS } from "./constants";

export const clearLocalStorage = () => {
  localStorage.removeItem("profileData");
  localStorage.removeItem("clubsData");
  localStorage.removeItem("headers");
};

export const userIsLoggedIn = (profileData) =>
  profileData && profileData.status === 200;

export const redirectToLogin = (route) => {
  const redirectionRoute = route
    ? `/login?redirect_to=${route}`
    : "/login";
  toast.error(
    "Your session has expired.\nPlease Login again!",
  );
  setTimeout(() => {
    window.location.href = redirectionRoute;
  }, 1500);
};

export const checkSession = (data, status, route) => {
  switch (status) {
    case "success":
      switch (data && data.status) {
        case 200:
          localStorage.setItem(
            "profileData",
            JSON.stringify(data),
          );
          break;
        default:
          redirectToLogin(route);
          clearLocalStorage();
          break;
      }
      break;
    case "error":
      redirectToLogin(route);
      clearLocalStorage();
      break;
    default:
      break;
  }
};

export const calculateTime = (secs) => {
  const days = Math.floor(secs / (60 * 60 * 24));

  const divisor_for_hours = secs % (60 * 60 * 24);
  const hours = Math.floor(divisor_for_hours / (60 * 60));

  const divisor_for_minutes = divisor_for_hours % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  const timeArray = [
    {
      title: getLabelByName(DAYS),
      number: String(days).padStart(2, "0"),
    },
    {
      title: "HRS",
      number: String(hours).padStart(2, "0"),
    },
    {
      title: "MIN",
      number: String(minutes).padStart(2, "0"),
    },
    {
      title: "SEC",
      number: String(seconds).padStart(2, "0"),
    },
  ];

  return timeArray;
};

export const renderOGMetaTags = ({
  title,
  description,
  image,
}) => {
  return (
    <Helmet>
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content={description}
      />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={image} />
    </Helmet>
  );
};

export const getOGMetaTagsClub = (club, landing) => {
  const landingLogo = landing.data.logo;
  const tags = {
    title: club.name,
    description: club.profile.objectives,
    image: club.profile?.picture || landingLogo,
  };

  return tags;
};

export const getOGMetaTags = (post, landing) => {
  const authorName = `${post.author.profile.firstName} ${post.author.profile.lastName}`;
  const landingLogo = landing.data.logo;
  const tags = {
    title: document.title,
    description: `posted by ${authorName}`,
    image: landingLogo,
  };

  return tags;
};

export const getOGMetaTagsPosts = (post, club) => {
  const authorName = `${post.author.profile.firstName} ${post.author.profile.lastName}`;
  let tags;
  switch (post.type) {
    case "POST":
      tags = {
        title: document.title,
        description: `${authorName} shared a post in ${club.name}`,
        image: post.images.image_1,
      };
      break;

    case "ARTICLE":
      tags = {
        title: `${authorName} posted ${post.content.title}`,
        description: `${post.content.description}`,
        image: post.images?.image_1,
      };
      break;

    case "PROMO":
      tags = {
        title: post.content.caption,
        description: `${post.content.summary}`,
        image:
          post.images?.image_1 ||
          post.promoResource?.profile?.picture ||
          post.promoResource?.images?.image_1,
      };
      break;

    default:
      tags = {
        description: `${authorName} posted`,
        title: document.title,
        image: post.images.image_1,
      };
      break;
  }

  return tags;
};

export const isSessionActive = (data) =>
  data && data.status === 200;

export const redirectTo = (authPage, route) => {
  clearLocalStorage();
  const redirectionRoute = route
    ? `/${authPage}?redirect_to=${route}`
    : `/${authPage}`;
  window.location.href = redirectionRoute;
};

export const getAuthors = () => [
  {
    id: 1,
    firstName: "Suhmayah",
    lastName: "Banda",
    status: "ACTIVE",
  },
  {
    id: 2,
    firstName: "Thomas",
    lastName: "Nignan",
    status: "ACTIVE",
  },
  {
    id: 3,
    firstName: "Robert",
    lastName: "C. Martin",
    status: "ACTIVE",
  },
  {
    id: 4,
    firstName: "Suhmayah",
    lastName: "Banda",
    status: "ACTIVE",
  },
  {
    id: 5,
    firstName: "Thomas",
    lastName: "Nignan",
    status: "ACTIVE",
  },
  {
    id: 6,
    firstName: "Robert",
    lastName: "C. Martin",
    status: "ACTIVE",
  },
];

export const getAuthorsBio = () => [
  {
    id: 1,
    firstName: "Suh",
    lastName: "Banda",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.\
      Suspendisse sagittis tincidunt massa eu egestas. \
      Etiam blandit consequat lorem, nec sagittis mauris vehicula sit amet. \
      Ut sit amet dui id enim varius fermentum. Aliquam erat volutpat. \
      Nam sagittis ultricies nunc, quis sagittis purus luctus eu. \
      Duis orci velit, iaculis sit amet libero dapibus, bibendum blandit orci.",
    occupation: "Lawyer",
    status: "ACTIVE",
    social_links: {
      twitter: "https://www.twitter.com",
      email: "https://www.email.com",
      instagram: "https://www.instagram.com",
      facebook: "https://www.facebook.com",
      linkedin: "https://www.linkedin.com",
    },
  },
];

export const getDummyListings = () => {
  const listings = [];
  Array.from(
    { length: 12 },
    (_, index) => index + 1,
  ).forEach((index) => {
    listings.push({
      data: {
        id: index,
        publication_cover:
          "https://images-na.ssl-images-amazon.com/images/I/414JfiBCutL._SX327_BO1,204,203,200_.jpg",
        publication_title: "Becoming",
        publication_author: "Michelle Obama",
        publication_category: "Memoir",
        publication_description: "Lorem Ipsum",
      },
    });
  });

  return listings;
};

export const getUserLanguage = () =>
  localStorage.getItem("userLanguage");

export const getLabels = () => {
  try {
    const labels = JSON.parse(
      localStorage.getItem("labels"),
    );
    return labels.data.data;
  } catch (e) {
    return null;
  }
};

export const getDefaultLabels = () =>
  labelsDefaults[getUserLanguage()];

export const getLabelByName = (prop) =>
  getLabels()?.[prop]
    ? getLabels()[prop]
    : getDefaultLabels()?.[prop];

export const isActionSuccess = (
  nextProps,
  actionStatus,
  actionData,
) =>
  nextProps[actionStatus] === "success" &&
  nextProps[actionData].status === 200;

export const isActionError = (
  nextProps,
  actionStatus,
  actionData,
) =>
  nextProps[actionStatus] === "error" ||
  (nextProps[actionStatus] === "success" &&
    nextProps[actionData].status !== 200);

export const getListingBySetting = (
  setting,
  listingItem,
) => {
  const words = setting.split(" ");
  const variables = words.filter((word) => word[0] === ":");
  const formattedVariables = variables.map((variable) =>
    variable.slice(1),
  );
  const values = formattedVariables.map(
    (variable) => listingItem.data[variable],
  );
  let variableTracker = 0;

  const newWords = words.map((word) => {
    let newWord = word;
    if (word[0] === ":") {
      newWord = values[variableTracker];
      variableTracker += 1;
    }
    return newWord;
  });
  const listingSubtitle = newWords.join(" ");
  return listingSubtitle;
};

export const isAdmin = () => {
  const profileData = getProfileDataFromLocalStorage();

  return (
    profileData &&
    profileData.data &&
    profileData.data.roles.includes("admin")
  );
};

export const isSectionAdmin = (sectionList = []) => {
  const profileData = JSON.parse(
    localStorage.getItem("profileData"),
  );

  return sectionList.some((section) =>
    profileData?.data?.roles.includes(section),
  );
};

export const getFormattedContributionSetting = (
  setting,
  source,
) => {
  if (!setting || !setting.trim()) {
    return "";
  }

  let contributionVariables =
    parseContributionVariables(source);
  let parsedVariables = {};

  const words = setting.trim().split(" ");
  const variables = words.filter((word) => word[0] == ":");

  if (variables.length) {
    variables.map((variable) => {
      variable = variable.slice(1);
      parsedVariables[variable] =
        contributionVariables[variable];
    });
  }

  let newWords = [];
  newWords = words.map(
    (word) => parsedVariables[word.slice(1)] ?? word,
  );
  return newWords.join(" ");
};

const parseContributionVariables = (contribution) => {
  let contributionVariables = {};

  Object.keys(contribution.data).map(
    (key) =>
      (contributionVariables[key] = contribution.data[key]),
  );

  Object.keys(contribution.participant).map(
    (prop) =>
      (contributionVariables["participant_" + prop] =
        contribution.participant[prop]),
  );

  return contributionVariables;
};

export const getFormattedSetting = (setting, prop) => {
  const words = setting?.split(" ");
  const variables = words?.filter(
    (word) => word[0] === ":",
  );
  const formattedVariables = variables?.map((variable) =>
    variable.slice(1),
  );
  const values = formattedVariables?.map(
    (variable) => prop[variable],
  );

  let variableTracker = 0;

  const newWords = words?.map((word) => {
    let newWord = word;
    if (word[0] === ":") {
      newWord = values[variableTracker];
      variableTracker++;
    }
    return newWord;
  });

  const formatted = newWords?.join(" ");
  return formatted;
};

export const getProfileDataFromLocalStorage = () => {
  const profileData = localStorage.getItem("profileData");

  if (profileData === undefined || profileData === null) {
    return JSON.parse("{}");
  } else {
    return JSON.parse(profileData);
  }
};

export const getGuestDataFromLocalStorage = () => {
  const guestData = localStorage.getItem("guestData");

  if (guestData === undefined || guestData === null) {
    return JSON.parse("{}");
  } else {
    return JSON.parse(guestData);
  }
};

export const getAllClubsFromLocalStorage = () => {
  const clubsData = localStorage.getItem("userClubsData");

  if (clubsData === "undefined" || clubsData === null) {
    return [];
  } else {
    return JSON.parse(clubsData);
  }
};

export const getAllActivitiesFromLocalStorage = () => {
  const activitiesData = localStorage.getItem(
    "activitiesData",
  );

  if (
    activitiesData === "undefined" ||
    activitiesData === null
  ) {
    return [];
  } else {
    return JSON.parse(activitiesData);
  }
};
