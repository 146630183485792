/* eslint-disable no-case-declarations */
import React, {
  createContext,
  useContext,
  useReducer,
} from "react";
import { outputParams } from "../../../../../helpers/constants";

const WizardContext = createContext(undefined);

export const WIZARD_CONSTANTS = {
  HANDLE_FIELD_CHANGE: "HANDLE_FIELD_CHANGE",
  FILE_CHANGE: "FILE_CHANGE",
  SET_FILE_PREVIEW: "SET_FILE_PREVIEW",
  GLOBAL_SETTINGS_CHANGE: "GLOBAL_SETTINGS_CHANGE",
  HANDLE_SOURCE_CHANGE: "HANDLE_SOURCE_CHANGE",
  ADD_NEW_OUTPUT_SOURCE: "ADD_NEW_OUTPUT_SOURCE",
  REMOVE_OUTPUT_SOURCE: "REMOVE_OUTPUT_SOURCE",
  LOADING: "LOADING",
  RESET: "RESET",
};

const newOutputSource = {
  name: "",
  activityId: "",
  actionId: "",
  settings: {},
};

const initialState = {
  isLoading: false,
  name: "",
  type: "Default",
  visibility: "PUBLIC",
  meta: {},
  description: "",
  status: "ACTIVE",
  selectedFile: null,
  imagePreview: null,
  cover_image: "",
  settings: {},
  outputSources: [
    {
      name: "",
      activityId: "",
      actionId: "",
      settings: {},
    },
  ],
  globalSettings: {},
  isEdit: false,
  isClone: false,
  outputData: {},
};

function reducer(state, action) {
  switch (action.type) {
    case WIZARD_CONSTANTS.LOADING:
      return { ...state, isLoading: action.payload };

    case WIZARD_CONSTANTS.HANDLE_FIELD_CHANGE:
      const { key, value } = action.payload;
      return { ...state, [key]: value };

    case WIZARD_CONSTANTS.FILE_CHANGE:
      return { ...state, ...action.payload };

    case WIZARD_CONSTANTS.SET_FILE_PREVIEW:
      return { ...state, ...action.payload };

    case WIZARD_CONSTANTS.GLOBAL_SETTINGS_CHANGE:
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          ...action.payload,
        },
      };

    case WIZARD_CONSTANTS.HANDLE_SOURCE_CHANGE:
      const {
        name,
        value: sourceValue,
        index,
        isFromOutputSettings,
      } = action.payload;

      let sourceToUpdate = [...state.outputSources][index];

      if (isFromOutputSettings) {
        sourceToUpdate.settings[name] = sourceValue;
      } else {
        sourceToUpdate[name] = sourceValue;
      }

      console.log("SourceToUpdate", sourceToUpdate);

      state.outputSources[index] = sourceToUpdate;

      return {
        ...state,
        outputSources: state.outputSources,
      };

    case WIZARD_CONSTANTS.ADD_NEW_OUTPUT_SOURCE:
      return {
        ...state,
        outputSources: [
          ...state.outputSources,
          newOutputSource,
        ],
      };

    case WIZARD_CONSTANTS.REMOVE_OUTPUT_SOURCE:
      return {
        ...state,
        outputSources: state.outputSources.filter(
          (_, srcIndex) =>
            srcIndex !== action.payload.indexToRemove,
        ),
      };

    case WIZARD_CONSTANTS.RESET:
      return initialState;
    default:
      return initialState;
  }
}

const editOutputData = {
  id: "2",
  name: "Debug Media Library",
  type: "MEDIA_LIBRARY",
  status: "ACTIVE",
  visibility: "PUBLIC",
  dateCreated: "1650496632",
  lastUpdated: "1650496658",
  meta: { promoted: "1" },
  settings: {
    description: "Debugging the media library output",
    enable_date_filters: "",
    enable_filters: "",
    enable_type_filters: "",
    title: "Debug Media Library",
  },
  sources: {
    listings: {
      name: "listings",
      activityId: "5",
      actionId: "14",
      settings: {
        listing_image: ":media_file ",
        listing_title: ":media_title ",
        listing_subtitle: ":media_description ",
      },
    },
  },
  club: {
    id: "10",
    name: "Debug Club",
    handle: "debug-club",
    visibility: "RESTRICTED",
    status: "ACTIVE",
  },
};

function initState(outputData) {
  if (!outputData) return initialState;

  const {
    name,
    type,
    visibility,
    meta,
    status,
    settings,
    sources,
  } = outputData;

  let globalSettings = {};

  const settingKeys = outputParams[
    type
  ]?.general_settings?.map((setting) => setting.key);

  const settingsResult = settingKeys.map((key) =>
    settings[key]
      ? { [key]: settings[key] }
      : { [key]: false },
  );

  //   Much Explanatory
  //   for (let i = 0; i < settingsResult.length; i++) {
  //     if (settingsResult[i]) {
  //       for (const key in settingsResult[i]) {
  //         globalSettings[key] = settingsResult[i][key];
  //       }
  //     }
  //   }

  //   More Concise
  Object.entries(settingsResult).map((entry) => {
    globalSettings[Object.keys(entry[1])[0]] =
      Object.values(entry[1])[0];
  });

  const result = {
    ...initialState,
    name,
    description: settings.description,
    type,
    visibility,
    meta,
    status,
    settings,
    globalSettings,
    outputSources: Object.keys(sources).map((key) => {
      return sources[key];
    }),
    isEdit: outputData.isEdit,
    isClone: outputData.isClone,
    outputData,
  };

  return result;
}

const WizardContextProvider = ({
  outputData,
  children,
}) => {
  const [state, dispatch] = useReducer(
    reducer,
    outputData,
    initState,
  );

  return (
    <WizardContext.Provider value={[state, dispatch]}>
      {children}
    </WizardContext.Provider>
  );
};

export function useWizardContext() {
  const context = useContext(WizardContext);

  if (context === undefined) {
    throw new Error(
      "useWizardContext must be used within a WizardContextProvider",
    );
  }

  return context;
}

export default WizardContextProvider;
