import {
  GET_LABELS_ERROR, GET_LABELS_SUCCESS,
} from '../actions/types';

const initialState = {
  getLabelsData: null,
  getLabelsDataError: null,
  getLabelsStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LABELS_SUCCESS:
      return {
        ...state,
        getLabelsData: action.payload,
        getLabelsDataError: null,
        getLabelsStatus: 'success',
      };
    case GET_LABELS_ERROR:
      return {
        ...state,
        getLabelsDataError: action.payload,
        getLabelsData: null,
        getLabelsStatus: 'error',
      };
    default:
      return {
        ...state,
        getLabelsStatus: null,
      };
  }
};
