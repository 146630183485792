import React, { useState } from "react";
import moment from "moment";
import { MoreHoriz } from "@material-ui/icons";
import "./ResourceCard.scss";
import { ChatBubbleOutlineOutlined as ChatBubbleOutlineOutlinedIcon } from "@mui/icons-material";
import commentIcon from "../../assets/icons/comment.svg";
import { getLabelByName } from "../../helpers/helperFunctions";
import {
  ANONYMOUS,
  STATUS_APPROVED,
  STATUS_REJECTED,
  STATUS_PENDING,
  CONTRIBUTION_INTRO,
  NO_CONTRIBUTIONS,
} from "../../helpers/constants";
import ContributionComment from "./Contribution/ContribuitonComment/ContributionComment";

const ResourceCard = ({
  contributions,
  setIsAddedDiv,
  isAddedDiv,
}) => {
  const [showComment, setShowComment] = useState(false);
  const [currentModalIndex, setCurrentModalIndex] =
    useState(0);

  const sortedContributions = contributions.sort(
    (a, b) => b.dateCreated - a.dateCreated,
  );

  const showCommentModal = (index) => {
    setShowComment(!showComment);
    setCurrentModalIndex(index);
    setIsAddedDiv(!isAddedDiv);
  };

  return (
    <>
      <h3 className="ResourceCardIntro">
        {getLabelByName(CONTRIBUTION_INTRO)}
      </h3>
      {!contributions || contributions.length === 0 ? (
        <p>{getLabelByName(NO_CONTRIBUTIONS)}</p>
      ) : (
        <div className="allContributions">
          {sortedContributions.map(
            (contribution, index) => {
              const { status } = contribution;
              const statusText =
                // eslint-disable-next-line no-nested-ternary
                status === "APPROVED"
                  ? STATUS_APPROVED
                  : status === "REJECTED"
                  ? STATUS_REJECTED
                  : STATUS_PENDING;

              return (
                <div
                  key={contribution.id}
                  className="contribution cursor-pointer"
                  onClick={contribution.modalCall}>
                  <div className="texts">
                    <h5>
                      {contribution.isAnonymous
                        ? getLabelByName(ANONYMOUS)
                        : `${contribution.participant.firstName} ${contribution.participant.lastName}`}
                    </h5>
                    <p className="date">
                      {moment(
                        contribution.dateCreated * 1000,
                      ).format("DD MMM YYYY, hh:mm")}
                    </p>
                    <p className={`status ${status}`}>
                      {getLabelByName(statusText)}
                    </p>
                  </div>
                  <MoreHoriz />
                  <div className="comment-toggle-icon">
                    <div
                      className="comment-icon"
                      aria-hidden
                      onClick={() =>
                        showCommentModal(index)
                      }>
                      <img
                        src={commentIcon}
                        alt="comment-icon"
                        width="25px"
                      />
                      <span className="comment-number">
                        ({contribution?.comments})
                      </span>
                    </div>
                    {showComment &&
                      index === currentModalIndex && (
                        <ContributionComment
                          closeModal={() => {
                            setShowComment(false);
                            setIsAddedDiv(false);
                          }}
                          contribution={contribution}
                        />
                      )}
                  </div>
                </div>
              );
            },
          )}
        </div>
      )}
    </>
  );
};

export default ResourceCard;
