import {
    GET_USERS_SUCCESS, GET_USERS_ERROR,
} from '../actions/types';

const initialState = {
    getUsersData: null,
    getUsersDataError: null,
    getUsersStatus: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS_SUCCESS:
            return {
                ...state,
                getUsersData: action.payload,
                getUsersDataError: null,
                getUsersStatus: 'success',
            }
        case GET_USERS_ERROR:
            return {
                ...state,
                getUsersData: null,
                getUsersDataError: action.payload,
                getUsersStatus: 'error',
            }
        default:
            return {
                ...state,
                getUsersStatus: null,
            }
    }
}