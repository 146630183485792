/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { connect } from "react-redux";
import {
  Link,
  Redirect,
  withRouter,
} from "react-router-dom";
import { toast } from "react-toastify";
import ActivityDetails from "../../generics/Activity/ActivityDetailsSection/ActivityDetails";
import ActivityMoreInformation from "../../generics/Activity/ActivityMoreInformationSection/ActivityMoreInformation";
import ActivityParticipants from "../../generics/Activity/ActivityParticipantsSection/ActivityParticipants";
import Loading from "../../generics/Loading/Loading";
import { getInstitute } from "../../../redux/actions/institutesActions";
import { getLanding } from "../../../redux/actions/landingActions";
import { getContributions } from "../../../redux/actions/contributionsActions";
import {
  getActivity,
  getParticipants,
  participateCampaign,
} from "../../../redux/actions/campaignsActions";
import Navbar from "../../Navbar";
import {
  getLabelByName,
  isActionSuccess,
} from "../../../helpers/helperFunctions";
import {
  ACTIVITY,
  CONTRIBUTE,
  IN_WORD,
  JOINING,
  PARTICIPATE,
  THIS_WORD,
} from "../../../helpers/constants";
import {
  createGuestUser,
  getSessionAction,
} from "../../../redux/actions/authActions";
import Loader from "../../Loader";
import "./activity.css";
import LoginModal from "../../generics/LoginModal";
import UserVerificationModal from "../../generics/UserVerificationModal";

export class Activity extends Component {
  state = {
    activityData: null,
    isJoining: false,
    openLoginModal: false,
    openUserVerificationModal: false,
  };

  async UNSAFE_componentWillMount() {
    const { id: activityId } = this.props.match.params;

    const query = `?q={"activityId":${activityId}}`;

    await this.props.getActivity(activityId);

    await this.props.getParticipants(activityId);

    await this.props.getLanding();

    await this.props.getContributions(query);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    this.insertDataIntoState(
      nextProps.getActivityData?.data,
    );

    if (
      isActionSuccess(
        nextProps,
        "participateCampaignStatus",
        "participateCampaignData",
      )
    ) {
      const { profileData } = nextProps;

      if (
        profileData &&
        profileData?.data?.status !== "GUEST"
      ) {
        console.log(
          "DATA_SESSION_IN_ACTIVITY",
          profileData,
        );
        await this.props.getSessionAction();
      }

      const { location } = this.props;
      const { id: campaignId, handle } =
        this.props.match.params;
      const { id: clubId } = this.props.clubData?.data;

      let participantId = nextProps?.participateCampaignData?.data?.id;
      this.props.history.push(
        `/activity/${campaignId}/participant/${participantId}/console`,
        {
          participantId:
            nextProps?.participateCampaignData?.data?.id,
          clubId,
          from: location.pathname,
        },
      );
    }
  }

  async componentDidUpdate(prevState) {
    const { id: activityId } = this.props.match.params;

    const query = `?q={"activityId":${activityId}}`;

    if (
      prevState.participateCampaignData !==
      this.props.participateCampaignData
    ) {
      this.setIsButtonLoadingFalse();

      await this.props.getParticipants(activityId);

      await this.props.getContributions(query);
    }
  }

  setIsButtonLoadingFalse = () => {
    this.setState({ isJoining: false });
  };

  insertDataIntoState = (data) => {
    this.setState({ activityData: data });
  };

  gotoContribute = async () => {
    const { location } = this.props;
    const { id: campaignId, handle } =
      this.props.match.params;
      console.log("Props", this.props);

    let participantId = this.props.data.data.activities[campaignId].participantId;

    this.props.history.push(
      `/activity/${campaignId}/participant/${participantId}/console`,
      {
        from: location.pathname,
      },
    );
  };

  participate = async () => {
    const { profileData } = this.props;
    const userIsVerified =
      profileData?.data?.meta.hasOwnProperty("verifiedAt");

    if (!userIsVerified) {
      return this.setState({
        openUserVerificationModal: true,
      });
    }

    let payload = {};

    const isAnonymousActivity =
      this.state.activityData?.settings?.anonymous;

    if (isAnonymousActivity) {
      const {
        headers: guestHeaders,
        data: { status, data: userData },
      } = await this.props.createGuestUser();

      if (status !== 200) {
        return toast.error(
          userData?.error?.message || "An error occured.",
        );
      }

      payload.userId = userData?.id;
      payload.guestHeaders = guestHeaders;
    } else {
      if (!profileData || !profileData?.data) {
        this.setState({ openLoginModal: true });
        return;
      }

      payload.userId = profileData.data.id;
    }

    this.setState({ isJoining: true });

    if (
      !isAnonymousActivity &&
      this.state.activityData.visibility === "PRIVATE" &&
      !this.userIsParticipant()
    ) {
      this.setState({ isJoining: false });

      toast.error(
        "Request an invite from the admin to participate this activity.",
      );
      return;
    }

    const { props } = this;
    const { id: campaignId } = props.match.params;

    const response = await this.props.participateCampaign(
      campaignId,
      payload,
    );

    if (response.status === 500) {
      toast.error(
        response?.error?.message || "An error occured.",
      );
    }
  };

  userIsParticipant = () => {
    const { id: campaignId } = this.props.match.params;

    const { profileData } = this.props;

    if (!profileData) return false;

    const userActivities = Object.keys(
      profileData.data.activities,
    );

    return userActivities.includes(campaignId.toString());
  };

  isUserParticipant = () => {
    const { profileData } = this.props;

    if (!profileData) return false;

    const userPart =
      this.props.participantsData &&
      this.props.participantsData.data.filter(
        (data) =>
          data.user.id === this.props.profileData?.data?.id,
      );

    if (userPart?.length > 0) {
      return true;
    }
    return false;
  };

  handleButtonAction = () => {
    const isAnonymousActivity =
      this.state.activityData?.settings?.anonymous;

    if (isAnonymousActivity) return this.participate();

    const userParticipated = this.isUserParticipant();

    return !userParticipated
      ? this.participate()
      : this.gotoContribute();
  };

  render() {
    const {
      getActivityLoading,
      getInstitute: getInstituteAction,
      getParticipants: getParticipantsAction,
      getContributionsData,
    } = this.props;

    const {
      activityData,
      isJoining,
      openLoginModal,
      openUserVerificationModal,
    } = this.state;

    const { profileData } = this.props;

    const userParticipated = this.isUserParticipant();

    if (
      this.state.activityData?.visibility ===
        "RESTRICTED" &&
      !this.userIsParticipant()
    ) {
      return <Redirect push to="/404" />;
    }

    if (!this.state.activityData && getActivityLoading) {
      return <Loading />;
    }

    const isAnonymousActivity =
      this.state.activityData?.settings?.anonymous;

    const PARTICIPATE_IN_THIS_ACTIVITY = `${getLabelByName(
      PARTICIPATE,
    )} ${getLabelByName(IN_WORD)} ${getLabelByName(
      THIS_WORD,
    ).toLowerCase()} ${getLabelByName(
      ACTIVITY,
    ).toLowerCase()}`;

    const CTA_TEXT = isAnonymousActivity
      ? PARTICIPATE_IN_THIS_ACTIVITY
      : (!userParticipated && !isJoining ? (
          activityData?.meta.participate_call_to_action ||
          PARTICIPATE_IN_THIS_ACTIVITY
        ) : isJoining && !userParticipated ? (
          <>
            <span>{getLabelByName(JOINING)}</span>
            <Loader />
          </>
        ) : (
          activityData?.meta.contribute_call_to_action
        )) || getLabelByName(CONTRIBUTE);

    return (
      <>
        <Navbar pathname="club" />
        <div className="viewSingleActivity">
          <div className="title">
            <button
              type="button"
              style={{
                background: "none",
                border: "none",
                outline: "none",
                marginTop: "-8px",
                marginLeft: "-5px",
              }}>
              <Link
                to="/home"
                style={{
                  outline: "none",
                  color: "#424242",
                }}>
                <ArrowBackIosIcon />
              </Link>
            </button>
            <h1 style={{ paddingLeft: "10px" }}>
              {this.state.activityData?.name}
            </h1>
          </div>

          <div className="activityDetailsSection">
            <div className="activity">
              <ActivityDetails
                activityData={activityData}
                getInstitute={getInstituteAction}
                getParticipants={getParticipantsAction}
                getContributionsData={getContributionsData}
              />
            </div>
          </div>

          <div className="participateButtonSection">
            <button
              type="button"
              onClick={this.handleButtonAction}>
              {CTA_TEXT}
            </button>
          </div>

          <div className="activityMoreInformationSection">
            <ActivityMoreInformation
              getInstitute={getInstituteAction}
              activityData={activityData}
              getParticipants={getParticipantsAction}
            />
          </div>
          {profileData?.data &&
            activityData?.meta.displayParticipants ===
              "1" && (
              <div className="activityParticipantsSection">
                <ActivityParticipants
                  getParticipants={getParticipantsAction}
                />
              </div>
            )}
        </div>

        <LoginModal
          isModalOpen={openLoginModal}
          toggleModal={() =>
            this.setState((prevState) => ({
              openLoginModal: !prevState.openLoginModal,
            }))
          }
        />
        <UserVerificationModal
          isModalOpen={openUserVerificationModal}
          toggleModal={() =>
            this.setState((prevState) => ({
              openUserVerificationModal:
                !prevState.openUserVerificationModal,
            }))
          }
        />
      </>
    );
  }
}

Activity.propTypes = {
  match: PropTypes.any,
  getActivity: PropTypes.func,
  getSessionAction: PropTypes.func,
  getParticipants: PropTypes.func,
  getLanding: PropTypes.func,
  getActivityData: PropTypes.object,
  participateCampaign: PropTypes.func,
  participantsData: PropTypes.object,
  getActivityLoading: PropTypes.bool,
  getInstitute: PropTypes.func,
  participateCampaignData: PropTypes.object,
  getContributionsData: PropTypes.object,
  getContributions: PropTypes.object,
};

const mapStateToProps = (state) => ({
  getActivityData: state.getActivityReducer.getActivityData,
  getActivityLoading:
    state.getActivityReducer.getActivityLoading,
  profileData: state.authReducer.data,
  clubData: state.instituteReducer.instituteData,
  participantsData:
    state.getParticipantsReducer.getParticipantsData,
  participateCampaignData:
    state.participateCampaignReducer
      .participateCampaignData,
  participateCampaignStatus:
    state.participateCampaignReducer
      .participateCampaignStatus,
  getContributionsData:
    state.getContributionsReducer.getContributionsData,
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
});
const mapDispatchToProps = {
  getActivity,
  getInstitute,
  getParticipants,
  participateCampaign,
  getSessionAction,
  getLanding,
  getContributions,
  createGuestUser,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Activity);
