import "./webContentSettings.scss";
import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { webContentCategory } from "../../../../helpers/constants";
import Logo from "../Logo/Logo";
import ContentLandingPage from "../ContentLandingPage/ContentLandingPage";
import Footer from "../Footer/Footer";
import SocialMedia from "../SocialMedia/SocialMedia";
import { PrivacyPolicy } from "../PrivacyPolicy/PrivacyPolicy";
import { TermsConditions } from "../TermsConditions/TermsConditions";
import Labels from "../Labels/Labels";

export class WebContentSettings extends Component {
  state = {};

  renderWebContentSettings = (category) => {
    const {
      setSection,
      landingInfo,
      socialInfo,
      policyInfo,
      fileChange,
      imagePreview,
      handleChange,
      section,
      value,
      stateValues,
      triggerCheckbox,
      termsInfo,
      submitImage,
      labelsObject,
      handleLabelsChange,
      addNewLabel,
      removeLabel,
    } = this.props;

    switch (category) {
      case webContentCategory.logo:
        return (
          <Logo
            setSection={setSection}
            landingInfo={landingInfo}
            fileChange={fileChange}
            imagePreview={imagePreview}
            submitImage={submitImage}
            stateValues={stateValues}
          />
        );
      case webContentCategory.landingPage:
        return (
          <ContentLandingPage
            landingInfo={landingInfo}
            fileChange={fileChange}
            imagePreview={imagePreview}
            handleChange={handleChange}
            section={section}
            value={value}
            setSection={setSection}
            stateValues={stateValues}
            submitImage={submitImage}
          />
        );
      case webContentCategory.footer:
        return (
          <Footer
            landingInfo={landingInfo}
            handleChange={handleChange}
            section={section}
            setSection={setSection}
            value={value}
            stateValues={stateValues}
            socialInfo={socialInfo}
            triggerCheckbox={triggerCheckbox}
          />
        );
      case webContentCategory.socialMedia:
        return (
          <SocialMedia
            stateValues={stateValues}
            socialInfo={socialInfo}
            handleChange={handleChange}
          />
        );
      case webContentCategory.privacyPolicy:
        return (
          <PrivacyPolicy
            stateValues={stateValues}
            handleChange={handleChange}
            policyInfo={policyInfo}
            value={value}
          />
        );
      case webContentCategory.termsConditions:
        return (
          <TermsConditions
            stateValues={stateValues}
            handleChange={handleChange}
            termsInfo={termsInfo}
            value={value}
          />
        );
      case webContentCategory.en_labels:
        return (
          <Labels
            labelLang={webContentCategory.en_labels}
            labelsObject={labelsObject}
            handleLabelsChange={handleLabelsChange}
            addNewLabel={addNewLabel}
            removeLabel={removeLabel}
          />
        );
      case webContentCategory.fr_labels:
        return (
          <Labels
            labelLang={webContentCategory.fr_labels}
            labelsObject={labelsObject}
            handleLabelsChange={handleLabelsChange}
            addNewLabel={addNewLabel}
            removeLabel={removeLabel}
          />
        );
      default:
        return "";
    }
  };

  render() {
    const { category } = this.props;

    return <>{this.renderWebContentSettings(category)}</>;
  }
}

const mapStateToProps = (state) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(WebContentSettings);
