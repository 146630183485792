import "./socialMedia.scss";
import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ContentCard from "../ContentCard/ContentCard";
import { webContentSection } from "../../../../helpers/constants";

export class SocialMedia extends Component {
  state = {};

  setValue = (section, socialInfo) => {
    const { stateValues } = this.props;

    if (section === webContentSection.emailLink) {
      return !stateValues[webContentSection.emailLink] &&
        stateValues[webContentSection.emailLink] !== null
        ? socialInfo &&
            socialInfo.data[webContentSection.emailLink]
        : stateValues[webContentSection.emailLink];
    }
    if (section === webContentSection.instagramLink) {
      return !stateValues[
        webContentSection.instagramLink
      ] &&
        stateValues[webContentSection.instagramLink] !==
          null
        ? socialInfo &&
            socialInfo.data[webContentSection.instagramLink]
        : stateValues[webContentSection.instagramLink];
    }
    if (section === webContentSection.linkedInLink) {
      return !stateValues[webContentSection.linkedInLink] &&
        stateValues[webContentSection.linkedInLink] !== null
        ? socialInfo &&
            socialInfo.data[webContentSection.linkedInLink]
        : stateValues[webContentSection.linkedInLink];
    }
    if (section === webContentSection.facebookLink) {
      return !stateValues[webContentSection.facebookLink] &&
        stateValues[webContentSection.facebookLink] !== null
        ? socialInfo &&
            socialInfo.data[webContentSection.facebookLink]
        : stateValues[webContentSection.facebookLink];
    }
    if (section === webContentSection.twitterLink) {
      return !stateValues[webContentSection.twitterLink] &&
        stateValues[webContentSection.twitterLink] !== null
        ? socialInfo &&
            socialInfo.data[webContentSection.twitterLink]
        : stateValues[webContentSection.twitterLink];
    }

    return "";
  };

  render() {
    const { handleChange, socialInfo } = this.props;

    return (
      <div>
        <ContentCard
          header="Social Media Links"
          socialMedia
          title="Enter the link to your social media platform"
          handleChange={handleChange}
          contentSection={webContentSection.emailLink}
          contentSection2={webContentSection.instagramLink}
          contentSection3={webContentSection.linkedInLink}
          contentSection4={webContentSection.facebookLink}
          contentSection5={webContentSection.twitterLink}
          socialInfo={socialInfo}
          emailValue={this.setValue(
            webContentSection.emailLink,
            socialInfo,
          )}
          instagramValue={this.setValue(
            webContentSection.instagramLink,
            socialInfo,
          )}
          facebookValue={this.setValue(
            webContentSection.facebookLink,
            socialInfo,
          )}
          twitterValue={this.setValue(
            webContentSection.twitterLink,
            socialInfo,
          )}
          linkedInValue={this.setValue(
            webContentSection.linkedInLink,
            socialInfo,
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(SocialMedia);
