import React, { Component } from "react";
import PropTypes from "prop-types";
import "./media-library.css";
import Navbar from "../../Navbar";
import LibrarySidebar from "./LibrarySidebar/LibrarySidebar";
import LibraryContent from "./LibraryContent/LibraryContent";

class MediaLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contributions: this.props.contributions || [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      contributions: nextProps.contributions || [],
    });
  }

  settingsHasFilters = () => {
    return (
      this.props?.settings?.enable_filters === "true" ||
      this.props?.settings?.enable_date_filter === "true" ||
      this.props?.settings?.enable_type_filter === "true"
    );
  };

  filterContributionsbyType = (types) => {
    const { contributions } = this.props;
    let filtered = [...contributions];
    if (types.length > 0) {
      filtered = filtered.filter((contribution) => {
        if (types.includes(contribution.data.file_type)) {
          return contribution;
        }
      });
    }
    this.setState({ contributions: filtered });
  };

  render() {
    const { settings } = this.props;
    const { contributions } = this.state;

    console.log("Sources", this.props.sources);

    return (
      <>
        <Navbar pathname="club" />
        <div className="media-library">
          {this.settingsHasFilters() && (
            <div className="media-library__library-sidebar">
              <LibrarySidebar
                settings={settings}
                filterContributionsbyType={
                  this.filterContributionsbyType
                }
              />
            </div>
          )}
          <div
            className="media-library__library-content"
            style={{
              width: !this.settingsHasFilters() && "100%",
            }}>
            <LibraryContent
              contributions={contributions}
              settings={settings}
              sources={this.props.sources}
            />
          </div>
        </div>
      </>
    );
  }
}

MediaLibrary.propTypes = {};

export default MediaLibrary;
