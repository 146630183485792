/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Cancel, CloudUploadRounded, Description } from '@material-ui/icons';

class UploadDocumentPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  cancelAction = (file) => {
    const { removeFile } = this.props;
    removeFile(file);
  };

  cancelExistingUrl = (url) => {
    const { removeExistingFile } = this.props;
    removeExistingFile(url);
  };

  render() {
    const { existingFiles, files, isUploading } = this.props;

    const existingFilesToMap = existingFiles || []
    const filesToMap = files || []
    
    return (
      <div className="document-preview-row mt-2">
        {existingFilesToMap.map((url) => (
          <div className="document-preview-item" key={url}>
            {this.previewExistingFile(url)}
            {!isUploading ? (
              <Cancel
                className="document-cancel-button"
                onClick={() => this.cancelExistingUrl(url)}
                titleAccess="Remove File"
              />
            ) : (
                <CloudUploadRounded
                  className="document-cancel-button"
                  titleAccess="Uploading File"
                  style={{ cursor: 'not-allowed' }}
                />
            )}
          </div>
        ))}
        {filesToMap.map((file, index) => (
          <div className="document-preview-item" key={file}>
            {this.getPreviewContent(file, index)}
            {!isUploading ? (
              <Cancel
                className="document-cancel-button"
                onClick={() => this.cancelAction(file)}
                titleAccess="Remove File"
              />
            ) : (
                <CloudUploadRounded
                  className="document-cancel-button"
                  titleAccess="Uploading File"
                  style={{ cursor: 'not-allowed' }}
                />
            )}
          </div>
        ))}
      </div>
    );
  }

  getPreviewContent = (file, index) => (
    <div className="document-upload-preview">
      <Description style={{ fontSize: '50px' }} />
      <p className="document-preview-name">
        {file?.fileItem?.name.length > 15
          ? `${file?.fileItem?.name.slice(0, 12)}...`
          : file?.fileItem?.name}
      </p>
    </div>
  );

  previewExistingFile = (url) => {
    const filename = url ? url.split('/')[url.split('/').length - 1] : "";
    return (
      <div className="document-upload-preview">
        <Description style={{ fontSize: '50px' }} />
        <p className="document-preview-name">
          {filename.length > 15
            ? `${filename.slice(0, 12)}...`
            : filename}
        </p>
      </div>
    );
  };
}

UploadDocumentPreview.propTypes = {
  files: PropTypes.array,
  existingFiles: PropTypes.array,
  removeFile: PropTypes.func,
  removeExistingFile: PropTypes.func,
  isUploading: PropTypes.bool,
};

export default UploadDocumentPreview;
