import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";

const ContributionConfirmationModal = (props) => {
  const { isModalOpen, activityData, actionId, isSubmit, updateActionId, submitAction, closeModal } = props; 
  const history = useHistory();
  
    
    const action = activityData?.actions.find(action_ => action_.id == actionId)
    // const actionIndex = activityData?.actions.map(action => `${action.id  }`).indexOf(actionId)

    // const actionIsLast = actionIndex === activityData?.actions.length -1;
    
    // console.log('ContributionConfirmationModal -- thisAction', action)
    
  // const goToNextAction = () => {
  //   const nextActionIndex = actionIndex + 1;
  //   const nextActionId = activityData?.actions[nextActionIndex].id
  //   updateActionId(nextActionId)
  //   closeModal()
  // }

  const returnToAction = () => {
    updateActionId(actionId)
    closeModal()
  }
  const submitContribution = () => {
    closeModal()
    submitAction()
  }
  return (
    <Modal
        size="md"
        show={isModalOpen}
        onHide={closeModal}
        className="behalf-modal">
        <div className="create-club-header">
          <div className="header-left">
            <p className="header-modal-title">
            Submission Saved
            </p>
            <p className="header-modal-subtitle">
              Your changes have been saved. {isSubmit ? 'Your submission has been received.' : null}
            </p>
          </div>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            style={{ marginTop: "-5rem", outline: "none" }}
            onClick={closeModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="row d-flex justify-content-around create-club-buttons">
          <>
            {/* {isSubmit && !actionIsLast ? (
              <Link
                to='#'>
                <button
                  type="button"
                  onClick={goToNextAction}
                  className="btn btn-lg submit-btn">
                  Go to the next step
                </button>
              </Link>
            ): null} */}
            {isSubmit && (action?.meta?.maxSubmissions != 1) ? (
              <Link
                to='#'>
                <button
                  type="button"
                  onClick={returnToAction}
                  className="btn btn-lg submit-btn">
                  Participate again
                </button>
              </Link>
            ): null}
            {!isSubmit && (
              <Link
                to='#'>
                <button
                  type="button"
                  onClick={submitContribution}
                  className="btn btn-lg submit-btn">
                  Submit contribution
                </button>
              </Link>
            )}
            <button
              type="button"
              className="btn btn-lg submit-btn btn-reverse"
              onClick={() => history.push(`/campaign/${activityData?.id}/${activityData?.handle}`)}>
              Return to dashboard
            </button>
          </>
        </div>
      </Modal>
  )
}

export default ContributionConfirmationModal