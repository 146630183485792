import React from 'react'
import { Link, useHistory  } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import moment from "moment";
import activityDefaultImage from "../../../assets/manage-campaigns.svg";
import { getLabelByName } from "../../../helpers/helperFunctions";
import {
    ACTIVITY,
    CONTRIBUTION,
    ENDED,
    END_ON,
    EXIT,
    HAS
} from "../../../helpers/constants";


const ConsoleSidebar = ({activity, clubData, participant, isDropDownOpen, toggleIsDropDownOpen}) => {
    // const { activityId, participantId } = props.match.params
    // const { activity, participantContributions, participant } = props
    // const clubData = {}
    // const isDropDownOpen = true

    const history = useHistory()

    return (
        <div className="left-div">
            <div className="left-title">
                <div className="publications">
                    <img
                        src={
                            activity?.profile?.picture ||
                            activityDefaultImage
                        }
                        alt=""
                        style={{ backgroundColor: "#e8daca" }}
                    />
                    <div className="profile">
                        <button
                            type="button"
                            style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                                textAlign: "start",
                            }}
                            onClick={async () => { }}>
                            <h1>{activity?.name}</h1>
                        </button>
                        <div className="profile-info">
                            <img
                                src={clubData?.data?.profile?.picture}
                                alt=""
                            />

                            <h1>{clubData?.data?.name}</h1>
                        </div>
                    </div>
                </div>
                <div className="contribution-timer">
                    {(activity?.endDate !== null &&
                        activity?.endDate * 1000 <
                        new Date() && (
                            <p>
                                {`${getLabelByName(
                                    ACTIVITY,
                                )} ${getLabelByName(
                                    HAS,
                                )} ${getLabelByName(ENDED)}`}
                            </p>
                        )) ||
                        (activity?.endDate !== null && (
                            <>
                                <p>
                                    {`${getLabelByName(
                                        ACTIVITY,
                                    )} ${getLabelByName(END_ON)} `}
                                    {moment(
                                        activity?.endDate * 1000,
                                    ).format("DD MMM YYYY hh:mm ")}
                                </p>
                            </>
                        ))}
                </div>
            </div>

            <div className="responsiveness-only">
                <div className="arrow-down-dropdown">
                    <button
                        type="button"
                        onClick={toggleIsDropDownOpen}>
                        <KeyboardArrowDownIcon />
                    </button>
                </div>
            </div>

            <div
                className={`${isDropDownOpen
                    ? "left-body show-steps"
                    : "left-body not-show-step"
                    }`}>
                {activity?.actions &&
                    activity.actions.map(
                        (action, index) =>
                            action.status === "ACTIVE" && (
                                <div className="contribute-step" key={action.id}>
                                    <button
                                        type="button"
                                        onClick={() => history.push(`/activity/${activity.id}/participant/${participant.id}/action/${action.id}`)}>
                                        <p>Step {index + 1}</p>
                                        <h1>{action.name}</h1>
                                    </button>
                                </div>
                            ),
                    )}

                <div className="exit-button">
                    <Link
                        to={`/campaign/${activity?.id}/${activity?.handle}`}
                        style={{ color: "#f38f46" }}>
                        {`${getLabelByName(
                            EXIT,
                        )} ${getLabelByName(
                            CONTRIBUTION,
                        ).toLowerCase()}`}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ConsoleSidebar