/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Link } from "react-router-dom";
//
const CreateUserDropdown = ({ toggleModal }) => {
  return (
    <>
      <div
        className="dropdown-menu dropdown-menu-right create-dropdown"
        aria-labelledby="createUserDropdown"
        style={{
          boxShadow: "0px 2px 5px #cccccc80",
          borderRadius: "10px",
          border: "none",
        }}>
        <Link
          className="dropdown-item nav-link"
          onClick={(e) => {
            e.preventDefault();
            toggleModal();
          }}>
          <div className="side-nav-item">
            Add Individual Member
          </div>
        </Link>

        <Link
          to="/manage/users/import-csv"
          className="dropdown-item nav-link">
          <div className="side-nav-item">
            Import members using CSV file
          </div>
        </Link>
      </div>
    </>
  );
};

export default CreateUserDropdown;
