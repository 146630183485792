import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom";
import activityIcon from "../../assets/manage-campaigns.svg";
import { createGuestUser } from "../../redux/actions/authActions";
import { participateCampaign } from "../../redux/actions/campaignsActions";
import ShareResource from "../generics/ShareResource";

const ActivityCard = ({
  item,
  profileData,
  createGuestUserReq,
  participateCampaignReq,
  toggleUserVerificationModal,
  userActivities,
}) => {
  const history = useHistory();
  const location = useLocation();

  const userIsParticipant = () => {
    return Object.keys(userActivities).includes(item.id.toString())
  };

  const participate = async () => {
    const userIsVerified =
      profileData?.data?.meta.hasOwnProperty("verifiedAt");

    const campaignId = item.id;

    if (!userIsVerified) {
      return toggleUserVerificationModal();
    }

    let payload = {};

    const isAnonymousActivity = item?.settings?.anonymous;

    if (isAnonymousActivity) {
      const {
        headers: guestHeaders,
        data: { status, data: userData },
      } = await createGuestUserReq();

      if (status !== 200) {
        return toast.error(
          userData?.error?.message || "An error occured.",
        );
      }

      payload.userId = userData?.id;
      payload.guestHeaders = guestHeaders;
    } else {
      if (!profileData || !profileData?.data) {
        // this.setState({ openLoginModal: true });
        return;
      }

      payload.userId = profileData.data.id;
    }

    // this.setState({ isJoining: true });

    if (
      !isAnonymousActivity &&
      item.activityData.visibility === "PRIVATE" &&
      !userIsParticipant()
    ) {
      // this.setState({ isJoining: false });

      return toast.error(
        "Request an invite from the admin to participate this activity.",
      );
    }

    const response = await participateCampaignReq(
      campaignId,
      payload,
    );

    if (response.status === 200) {
      const { handle } = item;

      history.push(
        `/campaign/${campaignId}/${handle}/participate`,
        {
          participantId: response.data?.id,
          clubId: item.parentId,
          from: location.pathname,
        },
      );
    }

    if (response.status === 500) {
      toast.error(
        response?.error?.message || "An error occured.",
      );
    }
  };

  const gotoContribute = () => {
    history.push(
      `campaign/${item.id}/${item.handle}/participate`,
      {
        clubId: item.parentId,
        from: location.pathname,
      },
    );
  };

  const handleOnClick = (e) => {
    e.preventDefault();

    const isAnonymousActivity = item?.settings?.anonymous;

    if (isAnonymousActivity) return participate();

    const userParticipated = userIsParticipant();

    return !userParticipated
      ? participate()
      : gotoContribute();
  };

  return (
    <div className="card item-card text-center pb-2 d-flex align-items-center justify-content-center">
      <Link
        to={`/activity/${item.id}/participant/${userActivities[item.id].participantId}/console`}
        className="w-100"
        style={{
          color: "unset",
          textDecoration: "none",
        }}>
        <div
          className="card-img"
          style={{
            backgroundColor: "#E8DACA",
            borderRadius: "5px 5px 0 0",
            display: "grid",
            height: "227px",
            placeItems: "center",
          }}>
          <img
            className="img-fluid"
            src={item?.profile?.picture || activityIcon}
            alt="club-avatar"
            style={{
              objectFit: "cover",
              height: item?.profile?.picture
                ? "100%"
                : "auto",
              maxHeight: "227px",
              borderRadius: "5px 5px 0 0",
              width: !item?.profile?.picture
                ? "100px"
                : "100%",
            }}
          />
        </div>
        <div className="card-body pb-0 mb-2">
          <p className="card-text d-flex justify-content-center align-items-center">
            {item.name}
          </p>
        </div>
      </Link>

      <ShareResource
        resourceType="Activity"
        postId={item?.id}
        postLink={`/campaign/${item?.id}/${item?.handle}
        `}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  profileData: state.authReducer.data,
});

export default connect(mapStateToProps, {
  createGuestUserReq: createGuestUser,
  participateCampaignReq: participateCampaign,
})(ActivityCard);
