import "./club-details.css";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import {
  AccessTime as AccessTimeIcon,
  CalendarToday as CalendarTodayIcon,
  Extension as ExtensionIcon,
  People as PeopleIcon,
} from "@material-ui/icons";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import { getLabelByName } from "../../../../helpers/helperFunctions";
import clubDefaultImage from "../../../../assets/manage-clubs.svg";
import {
  ACTIVITIES,
  DESCRIPTION,
  LAST_ACTIVITY,
  MEMBERS,
  NO_DESCRIPTION_FOUND,
  START_DATE,
} from "../../../../helpers/constants";
import ShareResource from "../../../generics/ShareResource";

export class ClubDetailsSection extends Component {
  getClubData = () => this.props.instituteData?.data;

  render() {
    const clubData = this.getClubData();

    const getClubLink = () => {
      const { pathname } = this.props.location;

      return pathname;
    };

    return (
      <div className="activityDetails">
        <div className="activityDetailsDescription">
          <div className="profilePicture biggerScreens">
            <div className="avatar">
              {clubData?.profile?.picture ? (
                <img
                  src={clubData?.profile?.picture}
                  alt=""
                />
              ) : (
                <img src={clubDefaultImage} alt="" />
              )}
            </div>
          </div>
          <div className="descriptionDetails">
            <div className="descriptionDetailsHeader">
              <div className="profilePicture responsiveness">
                <div className="avatar">
                  {clubData?.profile?.picture ? (
                    <img
                      src={clubData?.profile?.picture}
                      alt=""
                    />
                  ) : (
                    <img src={clubDefaultImage} alt="" />
                  )}
                </div>
              </div>

              <Grid
                container
                spacing={3}
                className="statusDiv">
                <Grid item xs className="activeDiv">
                  <div
                    className={
                      clubData?.status === "ACTIVE"
                        ? "activeIndicator"
                        : "inActiveIndicator"
                    }
                  />
                  <h4>{clubData?.status}</h4>
                </Grid>
                <Grid
                  item
                  xs
                  style={{ maxWidth: "150px" }}
                  className="defaultLabel">
                  {clubData?.type}
                </Grid>

                <Grid>
                  <ShareResource
                    resourceType="Club"
                    postId={clubData?.id}
                    postLink={getClubLink()}
                  />
                </Grid>
              </Grid>
            </div>

            <div className="details">
              {(clubData?.profile?.description && (
                <>
                  <h1>{getLabelByName(DESCRIPTION)}</h1>
                  <p>{clubData.profile.description}</p>
                </>
              )) || (
                <p className="biggerScreens">
                  {getLabelByName(NO_DESCRIPTION_FOUND)}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="activityDetailsDates">
          <Grid
            container
            spacing={3}
            className="datesDetails">
            <Grid
              item
              sm={3}
              xs={12}
              className="dateDetailsItem borderRight">
              <Grid container spacing={3}>
                <Grid
                  item
                  sm={10}
                  xs={12}
                  className="dates">
                  <div>{getLabelByName(START_DATE)}</div>
                  <div style={{ fontWeight: "600" }}>
                    {moment(
                      clubData?.dateCreated * 1000,
                    ).format("DD MMM YYYY hh:mm ") ||
                      "DD MMM YYYY hh:mm"}
                  </div>
                </Grid>
                <Grid
                  item
                  sm={2}
                  xs={12}
                  className="calendarIcon">
                  <CalendarTodayIcon
                    style={{
                      width: "40px",
                      height: "35px",
                      color: "#F38F46",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={3}
              xs={12}
              className="dateDetailsItem borderRight">
              <Grid container spacing={3}>
                <Grid
                  item
                  sm={10}
                  xs={12}
                  className="dates">
                  <div style={{ paddingLeft: "10px" }}>
                    {getLabelByName(LAST_ACTIVITY)}
                  </div>
                  <div
                    style={{
                      paddingLeft: "10px",
                      fontWeight: "600",
                    }}>
                    {clubData?.last_activity
                      ? moment(
                          clubData?.last_activity * 1000,
                        ).format("DD MMM YYYY hh:mm ")
                      : "N/A"}
                  </div>
                </Grid>
                <Grid
                  item
                  sm={2}
                  xs={12}
                  className="calendarIcon">
                  <AccessTimeIcon
                    style={{
                      width: "40px",
                      height: "35px",
                      color: "#F38F46",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={3}
              xs={12}
              className="dateDetailsItem borderRight">
              <Grid container spacing={3}>
                <Grid
                  item
                  sm={10}
                  xs={12}
                  className="dates">
                  <div style={{ paddingLeft: "10px" }}>
                    {getLabelByName(ACTIVITIES)}
                  </div>
                  <div
                    style={{
                      paddingLeft: "10px",
                      fontWeight: "600",
                    }}>
                    {clubData?.campaigns}
                  </div>
                </Grid>
                <Grid
                  item
                  sm={2}
                  xs={12}
                  className="calendarIcon">
                  <ExtensionIcon
                    style={{
                      width: "40px",
                      height: "35px",
                      color: "#F38F46",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={3}
              xs={12}
              className="dateDetailsItem">
              <Grid container spacing={3}>
                <Grid
                  item
                  sm={10}
                  xs={12}
                  className="dates">
                  <div style={{ paddingLeft: "10px" }}>
                    {getLabelByName(MEMBERS)}
                  </div>
                  <div
                    style={{
                      paddingLeft: "10px",
                      fontWeight: "600",
                    }}>
                    {clubData?.members}
                  </div>
                </Grid>
                <Grid
                  item
                  sm={2}
                  xs={12}
                  className="calendarIcon">
                  <PeopleIcon
                    style={{
                      width: "40px",
                      height: "35px",
                      color: "#F38F46",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

ClubDetailsSection.propTypes = {
  clubData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  instituteData: state.instituteReducer.instituteData,
});

const mapDispatchToProps = {};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ClubDetailsSection);
