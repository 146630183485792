/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable global-require */
import "./reviewContributionHeader.scss";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Avatar, AvatarGroup } from "@mui/material";
import moment from "moment";
import TextInput from "react-autocomplete-input";
import {
  getAction,
  getActivity,
  getQuestionnaire,
  getParticipantContributionAction,
} from "../../../redux/actions/campaignsActions";
import {
  REVIEW,
  CONTRIBUTION,
  PROCESSING,
  APPROVE,
  REJECT,
  STATUS,
  UPDATED,
  ACTION,
  contributionStatusValues,
  contributionStatus,
  GO_TO_PROFILE,
  POST,
  POSTING,
  COMMENTS,
} from "../../../helpers/constants";
import {
  getLabelByName,
  getProfileDataFromLocalStorage,
  isActionError,
  isActionSuccess,
} from "../../../helpers/helperFunctions";
import Questionnaire from "../../Questionnaire";
import {
  getContributions,
  updateContribution,
  getContributionComments,
  contributionComment,
} from "../../../redux/actions/contributionsActions";
import { getInstituteMembers } from "../../../redux/actions/institutesActions";
import ActionRenderer from "../ParticipantAction/ActionRenderer";
import { retrieveActivity } from "../../../redux/actions/activities/activityActions";
import { retrieveParticipant } from "../../../redux/actions/activities/participantActions";

class ReviewContributionModal extends Component {
  state = {
    isLoading: false,
    questionnaireId: null,
    selectedContribution: this.props.contribution,
    showModal: this.props.isModalOpen,
    activity: null,
    approvedContribution: [],
    rejectedContribution: [],
    pendingContribution: [],
    clubMembersNames: [],
    userIds: [],
    commentButtonLoading: false,
  };

  componentDidMount() {
    console.log("Component Did Mount Props", this.props);
    // this.props.retrieveParticipant(
    //   this.props.getActivityData?.data?.id,
    //   this.props.contribution?.participantId
    // );

    // this.props.retrieveActivity(
    //   this.props.getActivityData.data.id,
    // );
  }

  async UNSAFE_componentWillMount() {
    const {
      getInstituteMembers: getInstituteMembersAction,
      match: {
        params: { clubId },
      },
    } = this.props;

    await getInstituteMembersAction(clubId);

    console.log("Component Will Mount Props", this.props);
    this.props.retrieveParticipant(
      this.props.getActivityData?.data?.id,
      this.props.contribution?.participantId
    );

    this.props.retrieveActivity(
      this.props.getActivityData.data.id,
    );    
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      selectedContribution: nextProps.contribution,
      showModal: nextProps.isModalOpen,
    });

    const { clubMembersData } = this.props;

    const clubMembers = [];

    const clubMembersIndex = {};

    if (clubMembersData?.data) {
      clubMembersData.data.forEach((member) => {
        const memberName = `${member.user.profile.firstName.replace(
          /\s/g,
          "",
        )}${member.user.profile.lastName.replace(
          /\s/g,
          "",
        )}`;

        clubMembers.push(memberName);

        clubMembersIndex[`@${memberName}`] = member.user.id;
      });
    }

    this.setState({
      clubMembersNames: clubMembers,
      clubMembersIndex,
    });

    if (
      nextProps.postContributionsCommentStatus ===
      "success" &&
      this.state.commentButtonLoading === true
    ) {
      this.setState({
        commentButtonLoading: false,
        postComment: "",
      });
      await this.props.getContributionComments(
        nextProps.contribution?.id,
      );
    }

    if (
      nextProps.contribution &&
      nextProps.contribution.actionId !==
      this.props?.contribution?.actionId
    ) {
      await this.props.getAction(
        nextProps.contribution.activityId,
        nextProps.contribution.actionId,
      );

      const {
        getParticipantContributionAction:
        getParticipantContribution,
        getContributionComments:
        getContributionCommentsAction,
      } = this.props;

      await getParticipantContribution(
        nextProps.contribution?.activityId,
        nextProps.contribution?.participantId,
      );

      await getContributionCommentsAction(
        nextProps.contribution?.id,
      );
    }

    if (
      isActionSuccess(
        nextProps,
        "getActivityStatus",
        "getActivityData",
      )
    ) {
      this.setState({
        activity: nextProps.getActivityData?.data,
      });
    }

    if (
      isActionSuccess(
        nextProps,
        "getActionStatus",
        "getActionData",
      )
    ) {
      this.setState({
        questionnaireId:
          nextProps.getActionData?.data?.meta
            ?.questionnaireId,
      });
      await this.props.getQuestionnaire(
        nextProps?.contribution?.clubId,
        nextProps.getActionData?.data?.meta
          ?.questionnaireId,
      );
    }

    if (
      isActionSuccess(
        nextProps,
        "updateContributionStatus",
        "updateContributionData",
      )
    ) {
      this.setState({ isLoading: false });
      const query = `?q={"activityId":${nextProps.contribution?.activityId}}`;
      await this.props.getContributions(query);
      toast.success(
        `${getLabelByName(STATUS)} ${getLabelByName(
          UPDATED,
        )}`,
      );
      window.location.reload();
      window.$("#reviewContributionModal").modal("hide");
    }

    if (
      isActionError(
        nextProps,
        "updateContributionStatus",
        "updateContributionData",
      )
    ) {
      this.setState({ isLoading: false });
      toast.error(
        "Oooops! Something went wrong.\nPlease try again!",
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { participantContributionData } = this.props;

    const approvedCont = [];
    const rejectedCont = [];
    const pendingCont = [];

    if (participantContributionData?.data?.length > 0) {
      participantContributionData.data.forEach(
        (contribution) => {
          if (
            contribution.status ===
            contributionStatusValues.approved
          ) {
            approvedCont.push(contribution.data);
          }
          if (
            contribution.status ===
            contributionStatusValues.rejected
          ) {
            rejectedCont.push(contribution.data);
          }
          if (
            contribution.status ===
            contributionStatusValues.pending
          ) {
            pendingCont.push(contribution.data);
          }
        },
      );
    }

    if (
      approvedCont.length !==
      prevState.approvedContribution.length
    ) {
      this.setApprovedContributionCount(approvedCont);
    }
    if (
      rejectedCont.length !==
      prevState.rejectedContribution.length
    ) {
      this.setRejectedContributionCount(rejectedCont);
    }
    if (
      pendingCont.length !==
      prevState.pendingContribution.length
    ) {
      this.setPendingContributionCount(pendingCont);
    }
  }

  setApprovedContributionCount = (value) => {
    this.setState({ approvedContribution: value });
  };

  setRejectedContributionCount = (value) => {
    this.setState({ rejectedContribution: value });
  };

  setPendingContributionCount = (value) => {
    this.setState({ pendingContribution: value });
  };

  updateStatus = async (contribution, status) => {
    this.setState({ isLoading: true });
    const payload = { ...contribution, status };

    await this.props.updateContribution(
      contribution.activityId,
      contribution.id,
      payload,
    );
  };

  handleChange = (e) => {
    const newUserIds = [];

    e.split(" ").forEach((value) => {
      const { clubMembersIndex } = this.state;

      if (value && clubMembersIndex[value]) {
        const newId = clubMembersIndex[value];

        const alreadyNewUserId = newUserIds.find(
          (id) => id === newId,
        );

        if (!alreadyNewUserId) {
          newUserIds.push(newId);
        }
      }
    });

    this.setState({
      postComment: e,
      userIds: newUserIds,
    });
  };

  render() {
    const {
      questionnaireId,
      isLoading,
      selectedContribution,
      showModal,
      activity,
      approvedContribution,
      rejectedContribution,
      pendingContribution,
      clubMembersNames,
      postComment,
      userIds,
      commentButtonLoading,
    } = this.state;

    const { getContributionsCommentsData } = this.props;

    const userProfile = getProfileDataFromLocalStorage().data;

    const commentPayload = {
      authorId: userProfile.id,
      content: {
        body: postComment,
      },
      files: {},
      images: {},
      mentions: userIds,
      parentId: selectedContribution?.id,
      status: "APPROVED",
      videos: {},
      visibility: "PUBLIC",
    };

    return (
      <Modal
        size="xl"
        show={showModal}
        onHide={this.props.toggleModal}>
        <div className="create-club-header">
          <div className="header-left">
            <p className="header-modal-title">
              {`${getLabelByName(REVIEW)} ${getLabelByName(
                CONTRIBUTION,
              )}`}
            </p>
            <p className="header-modal-subtitle">
              {!activity?.settings?.anonymous &&
                `by ${selectedContribution?.participant?.firstName} ${selectedContribution?.participant?.lastName}`}
            </p>
            <div className="row d-flex justify-content-around create-club-buttons review-contribution-buttons">
              {!isLoading && (
                <>
                  <button
                    type="button"
                    className="btn btn-lg submit-btn btn-reverse reject-button"
                    onClick={() =>
                      this.updateStatus(
                        selectedContribution,
                        "REJECTED",
                      )
                    }>
                    {getLabelByName(REJECT)}
                  </button>
                  <button
                    type="button"
                    className="btn btn-lg submit-btn"
                    onClick={() =>
                      this.updateStatus(
                        selectedContribution,
                        "APPROVED",
                      )
                    }>
                    {getLabelByName(APPROVE)}
                  </button>
                </>
              )}
              {isLoading && (
                <button
                  type="button"
                  className="btn btn-lg submit-btn btn-reverse">
                  <span>
                    {getLabelByName(PROCESSING)}
                    {"  "}
                  </span>
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status">
                    <span className="sr-only">
                      Loading...
                    </span>
                  </div>
                </button>
              )}
            </div>
          </div>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            style={{
              marginTop: "-5rem",
              outline: "none",
            }}
            onClick={() => this.props.toggleModal()}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="profile-link">
          <div className="action-details-section">
            <div className="action-details-details">
              <div className="details-title">
                <h3>{getLabelByName(ACTION)}</h3>
              </div>
              <div className="details-info">
                <span className="contribution-date">
                  <p>
                    {(selectedContribution?.lastUpdated !==
                      0 &&
                      moment(
                        selectedContribution?.lastUpdated *
                        1000,
                      ).format("DD MMM YYYY, hh:mm")) ||
                      moment(
                        selectedContribution?.dateCreated *
                        1000,
                      ).format("DD MMM YYYY, hh:mm")}
                  </p>
                </span>
                <span className="contribution-name">
                  <p>
                    {selectedContribution?.action[0].name}
                  </p>
                </span>
              </div>
            </div>
            <div className="action-details-status">
              <button
                type="button"
                className={`${selectedContribution?.status ===
                    contributionStatusValues.approved
                    ? "green-background"
                    : selectedContribution?.status ===
                      contributionStatusValues.rejected
                      ? "red-background"
                      : "yellow-background"
                  }`}>
                {selectedContribution?.status ===
                  contributionStatusValues.approved
                  ? contributionStatus.approved
                  : selectedContribution?.status ===
                    contributionStatusValues.pending
                    ? contributionStatus.pending
                    : selectedContribution?.status ===
                      contributionStatusValues.rejected
                      ? contributionStatus.rejected
                      : selectedContribution?.status}
              </button>
            </div>
          </div>
          {!activity?.settings?.anonymous && (
            <div className="profile-details-section">
              <div className="profile-details">
                <div className="profile-picture">
                  <img
                    src={
                      selectedContribution?.participant
                        ?.picture
                    }
                    alt=""
                  />
                </div>
                <div className="profile-name-contribution">
                  <h4>{`${selectedContribution?.participant?.firstName} ${selectedContribution?.participant?.lastName}`}</h4>
                  <p>{`${this.props.participantContributionData?.data?.length} Contributions made`}</p>
                </div>
                <div className="profile-contributions-indicators">
                  <AvatarGroup>
                    <Avatar
                      alt="0"
                      src="#"
                      style={{
                        backgroundColor: "rgb(230, 177, 0)",
                      }}>
                      {pendingContribution.length}
                    </Avatar>
                    <Avatar
                      alt="0"
                      src="#"
                      style={{
                        backgroundColor: "rgb(16, 167, 40)",
                      }}>
                      {approvedContribution.length}
                    </Avatar>
                    <Avatar
                      alt="0"
                      src="#"
                      style={{
                        backgroundColor: "rgb(244, 5, 5)",
                      }}>
                      {rejectedContribution.length}
                    </Avatar>
                  </AvatarGroup>
                </div>
              </div>
              <div className="link-button">
                <button
                  type="button"
                  onClick={() =>
                    this.props.history.push(
                      `/manage/activities/${selectedContribution?.activityId}/${this.props.match.params.handle}/participants/${selectedContribution?.participantId}`,
                    )
                  }>
                  {getLabelByName(GO_TO_PROFILE)}
                </button>
              </div>
            </div>
          )}
        </div>

        <ActionRenderer
          activity={this.props.getActivityData?.data}
          participant={this.props.participant}
          action={null}
          participantActions={null}
          pageAction="review"
          isPreviewMode={true}
          participantActionId={null}
        />

        <div className="comments-section">
          <div className="comment-section-header">
            <h1>{getLabelByName(COMMENTS)}</h1>
            <p>
              {`This contribution has ${getContributionsCommentsData?.data?.length} comments`}
            </p>
          </div>
          <div className="comment-section-content">
            {getContributionsCommentsData?.data?.length >
              0 &&
              getContributionsCommentsData.data.map(
                (comment) => (
                  <>
                    <div className="one-comment">
                      <div className="comment-content-title">
                        <img
                          src={
                            comment.author.profile.picture
                          }
                          alt=""
                        />
                        <div className="contribution-name">
                          <h3>{`${comment.author.profile.firstName} ${comment.author.profile.lastName}`}</h3>
                          <p>
                            {moment(
                              comment.dateCreated * 1000,
                            ).format("DD MMM YYYY, HH:MM")}
                          </p>
                        </div>
                      </div>
                      <div className="comment-content-body">
                        <p>{comment.content.body}</p>
                      </div>
                    </div>
                  </>
                ),
              )}
            <div className="contribution-comment-input-section">
              <div className="comment-author">
                <img
                  src={userProfile?.profile?.picture}
                  alt=""
                />
              </div>
              <div className="comment-author-input">
                <TextInput
                  maxOptions={0}
                  options={clubMembersNames}
                  placeholder="Add a comment"
                  Component="input"
                  onChange={(value) =>
                    this.handleChange(value)
                  }
                  value={postComment}
                />
              </div>
            </div>
            <div className="reply-button">
              <button
                type="button"
                onClick={() => {
                  this.props.contributionComment(
                    selectedContribution?.id,
                    commentPayload,
                  );
                  this.setState({
                    commentButtonLoading: true,
                  });
                }}>
                {commentButtonLoading
                  ? `${getLabelByName(POSTING)}...`
                  : getLabelByName(POST)}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ReviewContributionModal.propTypes = {
  contribution: PropTypes.any,
  getAction: PropTypes.func,
  getQuestionnaire: PropTypes.func,
  updateContribution: PropTypes.func,
  getContributions: PropTypes.func,
  getActionData: PropTypes.any,
  getActivityData: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  getActionData: state.getActionReducer.getActionData,
  getActionStatus: state.getActionReducer.getActionStatus,
  getActivityData: state.getActivityReducer.getActivityData,
  getActivityStatus:
    state.getActivityReducer.getActivityStatus,

  updateContributionData:
    state.updateContributionReducer.updateContributionData,
  updateContributionStatus:
    state.updateContributionReducer
      .updateContributionStatus,
  participantContributionData:
    state.getParticipantContributionReducer
      .participantContributionData,
  getContributionsCommentsData:
    state.getContributionsCommentsReducer
      .getContributionsCommentsData,
  postContributionsCommentStatus:
    state.postContributionsCommentReducer
      .postContributionsCommentStatus,
  clubMembersData: state.clubMembersReducer.clubMembersData,
  participant: state.participantCRUDReducer,
  activity: state.activityCRUDReducer,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getAction,
    getQuestionnaire,
    updateContribution,
    getContributions,
    getActivity,
    getParticipantContributionAction,
    getContributionComments,
    contributionComment,
    getInstituteMembers,
    retrieveParticipant,
    retrieveActivity,
  }),
)(ReviewContributionModal);
