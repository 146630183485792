/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import moment from 'moment';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { TODAY } from '../../helpers/constants';
import { getLabelByName } from '../../helpers/helperFunctions';

const CalendarToolbar = (toolbar) => {
  const goToBack = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    toolbar.onNavigate('prev');
  };

  const goToNext = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    toolbar.onNavigate('next');
  };

  const goToCurrent = () => {
    const now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.onNavigate('current');
  };

  const label = () => {
    const date = moment(toolbar.date);
    return (
      <span className="font-weight-bold">{date.format('MMMM')} {date.format('YYYY')}</span>
    );
  };

  return (
    <div className="toolbar-container d-flex mb-5 mt-5">
      <label className="label-date mr-5">{label()}</label>

      <div className="back-next-buttons">
        <ArrowBackIos
          className="calendar-arrow"
          style={{ fontSize: '30px' }}
          onClick={goToBack}
        />
        <button type="button" className="btn btn-lg submit-btn" onClick={goToCurrent}>
          {getLabelByName(TODAY)}
        </button>
        <ArrowForwardIos
          className="calendar-arrow ml-2"
          style={{ fontSize: '30px' }}
          onClick={goToNext}
        />
      </div>
    </div>
  );
};

export default CalendarToolbar;
