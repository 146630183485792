import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import logo from "../../assets/logo-opac.svg";
import whatWeDoImage from "../../assets/what-we-do.png";
import howWeDoImage from "../../assets/how-we-do.png";
import socialEmail from "../../assets/icons/social-email.svg";
import socialInstagram from "../../assets/icons/social-instagram.svg";
import socialLinkedin from "../../assets/icons/social-linkedin.svg";
import socialFacebook from "../../assets/icons/social-facebook.svg";
import socialTwitter from "../../assets/icons/social-twitter.svg";
import { getLabelByName } from "../../helpers/helperFunctions";
import {
  ACCOUNT,
  AN_WORD,
  COMMUNITIES,
  CREATE,
  DISCOVER,
  HOME,
  INTO_WORD,
  LOGIN,
  OVERVIEW_WORD,
  PRIVACY_POLICY,
  SOCIAL_MEDIA_CONNECT,
  SUPPORT,
  TERMS_CONDITIONS,
  YOUR_WORD,
} from "../../helpers/constants";

export class Landing extends Component {

  render() {
    const { landingData, landingDataLoading } = this.props;

    if (!landingData && landingDataLoading) {
      return (
        <div className="container">
          <div className="row">
            <div className="col d-flex justify-content-center">
              <div
                className="spinner-border text-warning"
                role="status"
                style={{
                  marginTop: "20rem",
                  height: "50px",
                  width: "50px",
                }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let landingInfo = null;
    if (landingData?.data) {
      landingInfo = landingData.data.filter(
        (x) => x.name === "landing-page"
      )[0];
    }

    let linksInfo = null;

    if (landingData?.data) {
      linksInfo = landingData.data.filter((x) => x.name === "links")[0];
    }

    if (!landingInfo) {
      return undefined;
    }

    if (!landingInfo.data) {
      return undefined;
    }

    return (
      <>
        {/* --------------------------------------------------------------------------
        landing introduction section
        -------------------------------------------------------------------------- */}
        <section
          className={
            landingInfo.data["background-image"]
              ? "landingIntro"
              : "landingIntro defaultBackground"
          }
        >
          <div className="leftDiv">
            <div
              className="small-screens"
              style={{
                backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ),url(${
                  landingInfo.data["background-image"]
                    ? landingInfo.data["background-image"]
                    : "none"
                })`,
                maxWidth: "100%",
                maxHeight: "100%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="content">
                <img
                  src={
                    landingInfo.data.logo ? landingInfo.data.logo : logo
                  }
                  alt="logo"
                />
                <h1>{landingInfo.data["welcome-header"]}</h1>
                <p>{landingInfo.data["welcome-text"]}</p>
                <div className="actionButtons">
                  <Link to="/signup">
                    <button type="button">
                      {`${getLabelByName(CREATE)} ${getLabelByName(
                        AN_WORD
                      )} ${getLabelByName(ACCOUNT).toLowerCase()}`}
                    </button>
                  </Link>
                  <Link to="/login" className="loginButton">
                    <button
                      type="button"
                      style={{
                        background: "transparent",
                        color: "#F38F46",
                      }}
                    >
                      {`${getLabelByName(LOGIN)} ${getLabelByName(
                        INTO_WORD
                      )} ${getLabelByName(YOUR_WORD)} ${getLabelByName(
                        ACCOUNT
                      ).toLowerCase()}`}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="content bigger-screens">
              <img
                src={landingInfo.data.logo ? landingInfo.data.logo : logo}
                alt="logo"
              />
              <h1>{landingInfo.data["welcome-header"]}</h1>
              <p>{landingInfo.data["welcome-text"]}</p>
              <div className="actionButtons">
                <Link to="/signup">
                  <button type="button">
                    {`${getLabelByName(CREATE)} ${getLabelByName(
                      AN_WORD
                    )} ${getLabelByName(ACCOUNT).toLowerCase()}`}
                  </button>
                </Link>
                <Link to="/login" className="loginButton">
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      color: "#F38F46",
                    }}
                  >
                    {`${getLabelByName(LOGIN)} ${getLabelByName(
                      INTO_WORD
                    )} ${getLabelByName(YOUR_WORD)} ${getLabelByName(
                      ACCOUNT
                    ).toLowerCase()}`}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="rightDiv">
            <div className="content">
              <div className="background-image">
                <img
                  src={landingInfo.data["background-image"]}
                  // src=''
                  alt=""
                />
              </div>
              <div className="site-slogan">
                {landingInfo.data["site-slogan"] && (
                  <>
                    <h1>{landingInfo.data["site-slogan"]}</h1>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* --------------------------------------------------------------------------
          Landing About section
          -------------------------------------------------------------------------- */}
        <section className="landingAbout">
          {(landingInfo.data.displayAbout === "1" ||
          landingInfo.data.displayAbout === true ) && (
          <div className="whatWeDo">
            <div className="leftDiv">
              <img
                src={landingInfo.data["about-image"] || whatWeDoImage}
                alt="about-img"
              />
            </div>
            <div className="rightDiv">
              <h1>{landingInfo.data["about-header"]}</h1>
              <p>{landingInfo.data["about-text"]}</p>
            </div>
          </div>
          )}

          {(landingInfo.data.displayServices === "1" ||
          landingInfo.data.displayServices === true) && (
          <div className="howWeDoIt">
            <div className="leftDiv">
              <img
                src={landingInfo.data["services-image"] || howWeDoImage}
                alt="services-img"
              />
            </div>
            <div className="rightDiv">
              <h1> {landingInfo.data["services-header"]}</h1>
              <p>
                {landingInfo.data["services-text"] ||
                  "This is how we can help you."}
              </p>
            </div>
          </div>
          )}
        </section>

        {/* --------------------------------------------------------------------------
        Landing Footer
        -------------------------------------------------------------------------- */}
        <section className="landingFooter">
          <div className="footerDetails">
            <div className="footerDescription">
              <a href="/">
                <img
                  src={
                    landingInfo.data.logo ? landingInfo.data.logo : logo
                  }
                  alt="logo"
                  style={{ width: "41px", height: "41px" }}
                />
              </a>
              {((linksInfo?.data?.displayEmail === "1" || linksInfo?.data?.displayEmail === true) ||
              (linksInfo?.data?.displayInstagram === "1" || linksInfo?.data?.displayInstagram === true) ||
              (linksInfo?.data?.displayLinkedIn === "1" || linksInfo?.data?.displayLinkedIn === true) ||
              (linksInfo?.data?.displayFacebook === "1" || linksInfo?.data?.displayFacebook === true) ||
              (linksInfo?.data?.displayTwitter === "1" || linksInfo?.data?.displayTwitter === true)) && (
              <>
                <p>{getLabelByName(SOCIAL_MEDIA_CONNECT)}</p>
                <div className="socialMediaFooter">
                  {(linksInfo?.data?.displayEmail === "1" ||
                    linksInfo?.data?.displayEmail === true) && (
                    <a href={`mailto:${linksInfo?.data?.email}`} target="to _blank">
                      <img
                        src={socialEmail}
                        alt="gmail"
                        style={{ marginLeft: 0 }}
                      />
                    </a>
                  )}
                  {(linksInfo?.data?.displayInstagram === "1" ||
                    linksInfo?.data?.displayInstagram === true) && (
                    <a href={linksInfo?.data?.instagram} target="to _blank">
                      <img src={socialInstagram} alt="instagram" />
                    </a>
                  )}
                  {(linksInfo?.data?.displayLinkedIn === "1" ||
                    linksInfo?.data?.displayLinkedIn === true) && (
                    <a href={linksInfo?.data?.linkedin} target="to _blank">
                      <img src={socialLinkedin} alt="linkedIn" />
                    </a>
                  )}
                  {(linksInfo?.data?.displayFacebook === "1" ||
                    linksInfo?.data?.displayFacebook === true) && (
                    <a href={linksInfo?.data?.facebook} target="to _blank">
                      <img src={socialFacebook} alt="facebook" />
                    </a>
                  )}
                  {(linksInfo?.data?.displayTwitter === "1" ||
                    linksInfo?.data?.displayTwitter === true) && (
                    <a href={linksInfo?.data?.twitter} target="to _blank">
                      <img
                        src={socialTwitter}
                        alt="twitter"
                        style={{ marginRight: 0 }}
                      />
                    </a>
                  )}
                </div>
              </>
              )
              }
            </div>
            {((landingInfo?.data?.displayHome === "1" || landingInfo?.data?.displayHome === true) ||
              (landingInfo?.data?.displayPrivacyPolicy === "1" || landingInfo?.data?.displayPrivacyPolicy === true) ||
              (landingInfo?.data?.displaySupport === "1" || landingInfo?.data?.displaySupport === true) ||
              (landingInfo?.data?.displayTermsConditions === "1" || landingInfo?.data?.displayTermsConditions === true)) && (
                <>
                  <div className="overview">
                    <h1>{getLabelByName(OVERVIEW_WORD)}</h1>
                    <ul>
                      {(landingInfo?.data?.displayHome === "1" ||
                      landingData?.data?.displayHome === true) && (
                        <li>
                          <Link to="/" style={{ color: "#424242" }}>
                            {getLabelByName(HOME)}
                          </Link>
                        </li>
                      )}
                      {(landingInfo?.data?.displayPrivacyPolicy === "1" ||
                      landingData?.data?.displayPrivacyPolicy === true) && (
                        <li>
                          <Link to="/privacy" style={{ color: "#424242" }}>
                            {getLabelByName(PRIVACY_POLICY)}
                          </Link>
                        </li>
                      )}
                      {(landingInfo?.data?.displaySupport === "1" ||
                      landingData?.data?.displaySupport === true) && (
                        <li>
                          <Link to="/" style={{ color: "#424242" }}>
                            {getLabelByName(SUPPORT)}
                          </Link>
                        </li>
                      )}
                      {(landingInfo?.data?.displayTermsConditions === "1" ||
                      landingData?.data?.displayTermsConditions === true) && (
                        <li>
                          <Link to="/terms" style={{ color: "#424242" }}>
                            {getLabelByName(TERMS_CONDITIONS)}
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </>
              )}
            <div className="footerSlogan">
              <p>
                {landingInfo.data["footer-text"] ||
                  "Bringing like minded people together in action"}
              </p>
              <Link
                to={
                  landingInfo.data["footer-link"]
                    ? landingInfo.data["footer-link"]
                    : "/signup"
                }
              >
                <button type="button">
                  {" "}
                  {landingInfo.data["footer-button"]
                    ? landingInfo.data["footer-button"]
                    : `${getLabelByName(DISCOVER)} ${getLabelByName(
                        COMMUNITIES
                      )}`}
                </button>
              </Link>
            </div>
          </div>
        </section>
        <section className="landingCopyright">
          <p>
            &#169;{" "}
            {landingInfo.data["copyright-text"] ||
              "Copyright 2021 SOSO Cameroon. All Rights Reserved."}
          </p>
        </section>
      </>
    );
  }
}

export const mapStateToProps = (state) => ({
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingDataLoading: state.landingReducer.landingDataLoading,
});

export default compose(withRouter, connect(mapStateToProps, ""))(Landing);
