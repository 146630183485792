import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CloudDownload } from '@material-ui/icons';

class FileDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { file } = this.props;

    return (
      <div className="mb-2">
        <a href={file} download target="_blank">
          <button
            type="button"
            className="btn btn-lg submit-btn"
          >
            Document
            <CloudDownload className="ml-3" />
          </button>
        </a>
      </div>
    );
  }
}

FileDownload.propTypes = {
  file: PropTypes.any,
};

export default FileDownload;
