import "./howToParticipate.scss";
import React, { Component } from "react";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  ArrowBackIos,
  Add as AddIcon,
  EditOutlined as EditOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from "@material-ui/icons";
import { Grid, Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import Navbar from "../../../Navbar";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import {
  ACTION,
  BACK,
  HOW_TO_PARTICIPATE,
  HOW_TO_PARTICIPATE_DESCRIPTION,
  ADD_NEW_ACTION,
  PREVIOUSLY_ADDED_ACTIONS,
  SEARCH,
  STEP,
  NO_ACTION_AVAILABLE,
  ACTION_IS_REMOVED_SUCCESSFULLY,
} from "../../../../helpers/constants";
import { getSessionAction } from "../../../../redux/actions/authActions";
import SearchBar from "../../../generics/SearchBar/SearchBar";
import {
  deleteAction,
  getActivity,
  exportActivities,
  getQuestionnaires,
} from "../../../../redux/actions/campaignsActions";
import CreateActionModal from "../CreateActionModal";
import ConfirmationDialog from "../../../new/ConfirmationDialog";
import ExportActionModal from "./ExportActionModal/ExportActionModal";

export class HowToParticipate extends Component {
  state = {
    searchedAction: null,
    displayedActions: null,
    editAction: null,
    confirmationDialogOpen: false,
    buttonLoading: false,
    isExportActionModalOpen: false,
  };

  async UNSAFE_componentWillMount() {
    const {
      getActivity: getActivityAction,
      match: {
        params: { id: activityId },
      },
      getQuestionnaires: getQuestionnairesAction,
    } = this.props;

    await getActivityAction(activityId);
    await getQuestionnairesAction();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      getActivityData,
      match: {
        params: { id: activityId },
      },
      getActivity: getActivityAction,
    } = this.props;

    if (nextProps.deleteActionStatus === "success") {
      await getActivityAction(activityId);
      this.setState({
        confirmationDialogOpen: false,
        buttonLoading: false,
      });
      toast.success(
        getLabelByName(ACTION_IS_REMOVED_SUCCESSFULLY),
      );
    }

    if (
      nextProps.getActivityData?.data?.actions !==
      getActivityData?.data?.actions
    ) {
      this.setState({
        displayedActions:
          nextProps.getActivityData?.data?.actions,
      });
    }
  }

  toggleExportActionModal = () => {
    this.setState({
      isExportActionModalOpen:
        !this.state.isExportActionModalOpen,
    });
  };

  render() {
    const { props } = this;

    const { match, getActivityData } = props;

    const {
      searchedAction,
      displayedActions,
      editAction,
      actionId,
      isExportActionModalOpen,
      confirmationDialogOpen,
    } = this.state;

    return (
      <div className="how-to-participate-page">
        <Navbar pathname="manage" />
        <div style={{ position: "relative", top: "130px" }}>
          <div style={{ margin: "0 1rem" }}>
            <div>
              <Link
                to={`/manage/activities/${match.params.id}/${match.params.handle}`}
                style={{
                  color: "black",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                }}>
                <ArrowBackIos className="arrow-back-icon" />
                <Typography display="inline">
                  {getLabelByName(BACK)}
                </Typography>
              </Link>
            </div>
            <div className="how-to-participate-header">
              <div className="header-title">
                <h1>
                  {`${
                    getActivityData?.data?.name
                  }: ${getLabelByName(HOW_TO_PARTICIPATE)}`}
                </h1>
              </div>
              <div className="title-description">
                <p>
                  {getLabelByName(
                    HOW_TO_PARTICIPATE_DESCRIPTION,
                  )}
                </p>
                <div className="search-participants">
                  <SearchBar
                    setSearchedItems={(searchedItems) =>
                      this.setState({
                        searchedAction: searchedItems,
                      })
                    }
                    triggerPagination
                    isActions
                    setTriggerPagination={() =>
                      this.setState({
                        triggerPagination: false,
                      })
                    }
                    displayedItems={displayedActions}
                    setDisplayedItems={(displayedItems) => {
                      this.setState({
                        displayedActions: displayedItems,
                      });
                    }}
                    placeholderText={`${getLabelByName(
                      SEARCH,
                    )} ${getLabelByName(
                      ACTION,
                    ).toLowerCase()}`}
                  />
                </div>
              </div>
            </div>
            <div className="actions-section">
              <div className="section-title d-flex justify-content-between align-items-center my-4">
                <p>{`${getLabelByName(
                  PREVIOUSLY_ADDED_ACTIONS,
                )}`}</p>

                <button
                  type="button"
                  className="export-btn"
                  onClick={this.toggleExportActionModal}
                  disabled={displayedActions?.length === 0}>
                  Export Actions
                </button>
              </div>

              <div className="section-actions">
                <Grid container className="steps">
                  {(searchedAction &&
                    searchedAction.length > 0 &&
                    searchedAction.map((action, index) => (
                      <>
                        <Grid
                          item
                          sm={3}
                          xs={12}
                          className="step"
                          key={index}
                          data-toggle="modal"
                          data-target=".create-action-modal"
                          onClick={() =>
                            this.setState({
                              editAction: action,
                            })
                          }
                          style={{ cursor: "pointer" }}>
                          <div className="step-edit-buttons">
                            <h1>{`${getLabelByName(STEP)} ${
                              index + 1
                            }`}</h1>
                            <button type="button">
                              delete
                            </button>
                          </div>
                          <p>{action.name}</p>
                        </Grid>
                      </>
                    ))) ||
                    (displayedActions?.length > 0 &&
                      displayedActions.map(
                        (action, index) => (
                          <>
                            <Grid
                              item
                              sm={3}
                              xs={12}
                              className="step"
                              key={index}
                              style={{ cursor: "pointer" }}>
                              <div className="step-edit-buttons">
                                <h1>{`${getLabelByName(
                                  STEP,
                                )} ${index + 1}`}</h1>
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.setState({
                                      confirmationDialogOpen: true,
                                      actionId: action.id,
                                    })
                                  }>
                                  <DeleteOutlineOutlinedIcon />
                                </button>
                                <button
                                  type="button"
                                  data-toggle="modal"
                                  data-target=".create-action-modal"
                                  onClick={() =>
                                    this.setState({
                                      editAction: action,
                                    })
                                  }>
                                  <EditOutlinedIcon />
                                </button>
                              </div>
                              <p>{action.name}</p>
                            </Grid>
                          </>
                        ),
                      )) || (
                      <div className="no-action-added">
                        <p style={{ padding: "20px 0" }}>
                          {getLabelByName(
                            NO_ACTION_AVAILABLE,
                          )}
                        </p>
                      </div>
                    )}
                </Grid>
              </div>
              <div className="add-new-action">
                <button
                  type="button"
                  data-target=".create-action-modal"
                  data-toggle="modal"
                  onClick={() => {
                    this.setState({
                      editAction: null,
                    });
                  }}>
                  <AddIcon />
                  <p>{getLabelByName(ADD_NEW_ACTION)}</p>
                </button>
              </div>
            </div>
            <>
              {isExportActionModalOpen ? (
                <ExportActionModal
                  isOpen={isExportActionModalOpen}
                  onClose={this.toggleExportActionModal}
                  actions={displayedActions}
                  exportActivities={
                    this.props.exportActivities
                  }
                />
              ) : null}

              <CreateActionModal
                editAction={editAction}
                activityId={match.params.id}
                clubId={getActivityData?.data?.parentId}
                onClose={() =>
                  this.setState({ editAction: null })
                }
              />

              <ConfirmationDialog
                activityId={match.params.id}
                actionId={actionId}
                open={confirmationDialogOpen}
                onClose={() =>
                  this.setState({
                    confirmationDialogOpen: false,
                  })
                }
                handleDeleteParticipant={() =>
                  props.deleteAction(
                    match.params.id,
                    actionId,
                  )
                }
                buttonLoading={this.state.buttonLoading}
                setLoading={(value) =>
                  this.setState({
                    buttonLoading: value,
                  })
                }
                actionToRemove={getLabelByName(
                  ACTION,
                ).toLowerCase()}
              />
            </>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getActivityData: state.getActivityReducer.getActivityData,
  deleteActionData:
    state.deleteActionReducer.deleteActionData,
  deleteActionStatus:
    state.deleteActionReducer.deleteActionStatus,
});

const mapDispatchToProps = {
  getSessionAction,
  getActivity,
  exportActivities,
  getQuestionnaires,
  deleteAction,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(HowToParticipate);
