import "./clubLayout.scss";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from "@material-ui/icons";
import ActivityDetails from "../../../generics/Activity/ActivityDetailsSection/ActivityDetails";
import Navbar from "../../../Navbar";
import {
  getInstitute,
  getInstituteMembers,
} from "../../../../redux/actions/institutesActions";
import { getContributions } from "../../../../redux/actions/contributionsActions";
import { getSessionAction } from "../../../../redux/actions/authActions";
import { getLanding } from "../../../../redux/actions/landingActions";
import {
  checkSession,
  getLabelByName,
} from "../../../../helpers/helperFunctions";
import CreateClubModal from "../CreateClubModal/CreateClubModal";
import {
  ACTIVITIES,
  ADVANCED_SETTINGS,
  ADVANCED_SETTINGS_DESCRIPTION,
  CLUB_ACTIVITIES_DESCRIPTION,
  CLUB_MEMBERS_DESCRIPTION,
  CLUB_OUTPUTS_DESCRIPTION,
  CONFIG_REGISTRATION_HOOK,
  CONFIG_REGISTRATION_HOOK_DESCRIPTION,
  EDIT,
  EDITING,
  MANAGE,
  MANAGE_PROFILE_DESCRIPTION,
  MEMBERS,
  OUTPUTS,
  PROFILE,
  PROMOTIONS,
  PROMOTIONS_DESCRIPTION,
  QUESTIONNAIRES,
  QUESTIONNAIRE_DESCRIPTION,
  CONTENT,
  CONTENT_DESCRIPTION,
} from "../../../../helpers/constants";
import Loader from "../../../Loader";
import Loading from "../../../generics/Loading/Loading";

export class ClubLayout extends Component {
  state = {
    pathname: this.props.location.pathname,
    editClub: null,
    isEditing: false,
    isCreateModalVisible: false,
    configurableSettings: [
      {
        name: getLabelByName(ADVANCED_SETTINGS),
        description: getLabelByName(
          ADVANCED_SETTINGS_DESCRIPTION,
        ),
        link: `${window.location.pathname}/settings/general`,
      },
      {
        name: getLabelByName(CONTENT),
        description: getLabelByName(CONTENT_DESCRIPTION),
        link: `${window.location.pathname}/content/posts`,
      },
      {
        name: getLabelByName(ACTIVITIES),
        description: getLabelByName(
          CLUB_ACTIVITIES_DESCRIPTION,
        ),
        link: `${window.location.pathname}/activities`,
      },
      {
        name: getLabelByName(QUESTIONNAIRES),
        description: getLabelByName(
          QUESTIONNAIRE_DESCRIPTION,
        ),
        link: `${window.location.pathname}/questionnaires`,
      },
      {
        name: getLabelByName(MEMBERS),
        description: getLabelByName(
          CLUB_MEMBERS_DESCRIPTION,
        ),
        link: `${window.location.pathname}/members`,
      },
      {
        name: getLabelByName(OUTPUTS),
        description: getLabelByName(
          CLUB_OUTPUTS_DESCRIPTION,
        ),
        link: `${window.location.pathname}/outputs`,
      },
      {
        name: getLabelByName(CONFIG_REGISTRATION_HOOK),
        description: getLabelByName(
          CONFIG_REGISTRATION_HOOK_DESCRIPTION,
        ),
        link: "#",
      },
      {
        name: `${getLabelByName(MANAGE)} ${getLabelByName(
          PROFILE,
        )}`,
        description: getLabelByName(
          MANAGE_PROFILE_DESCRIPTION,
        ),
        link: "#",
      },
      {
        name: `${getLabelByName(PROMOTIONS)}`,
        description: getLabelByName(PROMOTIONS_DESCRIPTION),
        link: `${window.location.pathname}/promotions`,
      },
    ],
  };

  async UNSAFE_componentWillMount() {
    const {
      getSessionAction: getSession,
      getLanding: getLandingAction,
      getInstitute: getInstituteAction,
      getInstituteMembers: getInstituteMembersAction,
      getContributions: getContributionsAction,
      match: {
        params: { id: clubId, handle },
      },
    } = this.props;

    const query = `?q={"clubId":${clubId}}`;

    await getInstituteAction(handle);

    await getContributionsAction(query);

    await getInstituteMembersAction(clubId);

    await getSession();

    await getLandingAction();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, status } = nextProps;
    checkSession(
      data,
      status,
      this.state.pathname,
      nextProps,
    );
  }

  async componentDidUpdate(prevProps) {
    const {
      getInstitute: getInstituteAction,
      updateClubData,
      match: {
        params: { handle },
      },
    } = this.props;

    if (prevProps.updateClubData !== updateClubData) {
      await getInstituteAction(handle);
    }
  }

  render() {
    const {
      configurableSettings,
      isEditing,
      editClub,
      isCreateModalVisible,
    } = this.state;

    const { instituteData, getContributionsData } =
      this.props;

    if (!instituteData) {
      return (
        <>
          <Navbar pathname="manage" />
          <Loading />
        </>
      );
    }

    return (
      <div className="admin-club-layout">
        <Navbar pathname="manage" />

        <div className="club-layout-details">
          <div className="title">
            <div className="club-name">
              <button
                type="button"
                style={{
                  background: "none",
                  border: "none",
                  outline: "none",
                  marginTop: "-8px",
                  marginLeft: "-5px",
                }}>
                <Link
                  to="/manage/clubs"
                  style={{
                    outline: "none",
                    color: "#424242",
                  }}>
                  <ArrowBackIosIcon />
                </Link>
              </button>
              <h1 style={{ paddingLeft: "10px" }}>
                {instituteData?.data?.name}
              </h1>
            </div>

            <div className="edit-button">
              <button
                type="submit"
                className="btn btn-lg submit-btn"
                disabled={
                  !instituteData || !instituteData.data
                }
                data-toggle="modal"
                data-target=".create-club-modal"
                onClick={() => {
                  this.setState({
                    editClub: instituteData?.data,
                  });
                  this.setState((prevState) => ({
                    isCreateModalVisible:
                      !prevState.isCreateModalVisible,
                  }));
                }}>
                {isEditing && (
                  <>
                    <span>
                      {getLabelByName(EDITING)}
                      {"  "}
                    </span>
                    <Loader />
                  </>
                )}
                {!isEditing && getLabelByName(EDIT)}
              </button>
            </div>
          </div>

          <div className="activityDetailsSection">
            <ActivityDetails
              activityData={instituteData?.data}
              getParticipants={() => {}}
              getContributionsData={getContributionsData}
              isClubLayout
            />
          </div>
        </div>
        <div className="configurable-settings">
          {configurableSettings.map((settings) => (
            <Link
              to={settings.link}
              style={{
                outline: "none",
                color: "#424242",
              }}>
              <div className="advanced-settings">
                <div className="left-details">
                  <h1>{settings.name}</h1>
                  <p>{settings.description}</p>
                </div>
                <div className="right-details">
                  <ArrowForwardIosIcon
                    style={{
                      color: "#F38F46",
                    }}
                  />
                </div>
              </div>
            </Link>
          ))}
        </div>
        <CreateClubModal
          isCreateModalVisible={isCreateModalVisible}
          changeIsCreateModal={() => {
            this.setState((prevState) => ({
              editClub: null,
              isCreateModalVisible:
                !prevState.isCreateModalVisible,
            }));
          }}
          editClub={editClub}
          getClubs={() => {}}
        />
      </div>
    );
  }
}

ClubLayout.propTypes = {
  getInstitute: PropTypes.func,
  instituteData: PropTypes.array,
  getContributionsData: PropTypes.object,
  getInstituteMembers: PropTypes.func,
  getSessionAction: PropTypes.func,
  getLanding: PropTypes.func,
};

const mapStateToProps = (state) => ({
  instituteDataError:
    state.instituteReducer.instituteDataError,
  instituteData: state.instituteReducer.instituteData,
  clubMembersData: state.clubMembersReducer.clubMembersData,
  getContributionsData:
    state.getContributionsReducer.getContributionsData,
  updateClubData: state.updateClubReducer.updateClubData,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
});

const mapDispatchToProps = {
  getInstitute,
  getContributions,
  getInstituteMembers,
  getSessionAction,
  getLanding,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ClubLayout);
