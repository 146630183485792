import { getLabelByName } from "./helperFunctions";
import imageIcon from "../assets/icons/files-icons/image-icon.svg";
import videoIcon from "../assets/icons/files-icons/video-icon.svg";
import audioIcon from "../assets/icons/files-icons/audio-icon.svg";
import powerpointIcon from "../assets/icons/files-icons/powerpoint-icon.svg";
import excelIcon from "../assets/icons/files-icons/excel-icon.svg";
import wordIcon from "../assets/icons/files-icons/word-icon.svg";
import pdfIcon from "../assets/icons/files-icons/pdf-icon.svg";

export const baseURL = process.env.REACT_APP_API_ENDPOINT;
export const serviceProvider =
  process.env.REACT_APP_SERVICE_PROVIDER;
export const colors = {
  primaryColor: "#F38F46",
  textPrimary: "#424242",
  textSecondary: "#A3A3A3",
  backgroundColor: "#0000000D",
};

export const languageApiMapping = {
  en: 7,
  fr: 8,
};

export const rolesMapping = {
  admin: "Admin",
  member: "Member",
};

export const cloudinaryFolders = {
  users: "users",
  clubs: "clubs",
  activities: "activities",
};

export const fileIconsMapping = {
  Images: imageIcon,
  Videos: videoIcon,
  Audio: audioIcon,
  PowerPoint: powerpointIcon,
  Excel: excelIcon,
  Word: wordIcon,
  PDF: pdfIcon,
};

export const ABOUT_US = "about_us";
export const AGREE_TO = "agree_to";
export const ALREADY_REGISTERED = "already_registered";
export const BACK = "back";
export const BACK_TO = "back_to";
export const CITY = "city";
export const COMMUNITIES = "communities";
export const DISCOVER = "discover";
export const COUNTRY = "country";
export const CREATE = "create";
export const EMAIL = "email";
export const EXPLORE = "explore";
export const FEMALE = "female";
export const FIRSTNAME = "firstname";
export const FORGOT_PASSWORD = "forgot_password";
export const FORGOT_PASSWORD_DESCRIPTION =
  "forgot_password_description";
export const GENDER = "gender";
export const HOME = "home";
export const LANDING_WELCOME = "landing_welcome";
export const LASTNAME = "lastname";
export const LOGGING_IN = "logging_in";
export const LOGIN = "login";
export const INTO_WORD = "into_word";
export const SOCIAL_MEDIA_CONNECT = "social_media_connect";
export const SOCIAL_MEDIA = "social_media";
export const MALE = "male";
export const NOT_REGISTERED = "not_registered";
export const OF_WORD = "of_word";
export const AN_WORD = "an_word";
export const AND_WORD = "and_word";
export const SIGN_WORD = "sign_word";
export const TO_WORD = "to_word";
export const OTHER = "other";
export const ANOTHER = "another";
export const NONE = "none";
export const THE_WORD = "the_word";
export const PASSWORD = "password";
export const POST = "post";

export const PAYMENT_DESCRIPTION = "payment_description";
export const AMOUNT = "amount";
export const CURRENCY = "currency";
export const SELECT_FREQUENCY = "select_frequency";
export const NUMBER_OF_PAYMENTS = "number_of_payments";

export const POSTING = "posting";
export const POSTED = "posted";
export const PRIVACY_POLICY = "privacy_policy";
export const PROFILE = "profile";
export const MANAGE_PROFILE_DESCRIPTION =
  "manage_profile_description";
export const PROMOTIONS_DESCRIPTION =
  "promotions_description";
export const REQUEST_LINK = "request_link";
export const REQUEST_LINK_SENT = "request_link_sent";
export const SEARCH = "search";
export const SENDING = "sending";
export const SIGNINGP_UP = "signing_up";
export const SIGN_IN = "sign_in";
export const welcome_back = "welcome_back";
export const HELLO = "hello";
export const HELLO_DESCRIPTION = "hello_description";
export const welcome_back_description =
  "welcome_back_description";
export const SIGNUP = "signup";
export const SUPPORT = "support";
export const TERMS_CONDITIONS = "terms_conditions";
export const ALL_ACTIVITY = "all_activity";
export const MY_CLUBS_ACTIVITY = "my_clubs_activity";
export const IN_WORD = "in_word";
export const SEE_SIMILAR_POSTS = "see_similar_posts";
export const SHARE = "share";
export const READ_MORE = "read_more";
export const COPY = "copy";
export const LINK = "link";
export const CAMPAIGN = "campaign";
export const FOR_WORD = "for_word";
export const SEARCH_RESULTS = "search_results";
export const MY_SINGULAR = "my_singular";
export const MY_PLURAL = "my_plural";
export const ACCOUNT = "account";
export const ADMIN_PANEL = "admin_panel";
export const MEMBER = "member";
export const ADMIN = "admin";
export const CHANGE = "change";
export const EDIT = "edit";
export const LOGOUT = "logout";
export const PICTURE = "picture";
export const UPLOAD = "upload";
export const UPLOADING = "uploading";
export const CLOSE = "close";
export const NO_WORD = "no_word";
export const YET_WORD = "yet_word";
export const SAVING = "saving";
export const SAVE = "save";
export const SAVE_DRAFT = "save_draft";
export const CLONING = "cloning";
export const CLONE = "Clone";
export const CANCEL = "cancel";
export const CURRENT = "current";
export const NEW = "new";
export const CONFIRM = "confirm";
export const CHANGING = "changing";
export const CHANGED_PASSWORD_SUCCESS =
  "changed_password_success";
export const USERNAME = "username";
export const CHANGED_USERNAME_SUCCESS =
  "changed_username_success";
export const CHANGED_PICTURE_SUCCESS =
  "changed_picture_success";
export const MANAGE = "manage";
export const SITE_CONTENT = "site_content";
export const ACTIVITY = "activity";
export const WRITE_SOMETHING = "write_something";
export const ENTER_TEXT = "enter_text";
export const POST_ACTION = "post_action";
export const POSTING_ACTION = "posting_action";
export const STARTED = "started";
export const JOIN = "join";
export const VISIT = "visit";
export const JOINING = "joining";
export const MISSION_STATEMENT = "mission_statement";
export const OBJECTIVES = "objectives";
export const JOIN_TO_VIEW = "join_to_view";
export const PREVIOUS = "previous";
export const NEXT = "next";
export const VIEW_REACTIONS = "view_reactions";
export const ALL_WORD = "all_word";
export const BY_WORD = "by_word";
export const COMMENTS = "comments";
export const WHAT_THOUGHTS = "what_thoughts";
export const REPLY = "reply";
export const EDITING = "editing";
export const DESCRIPTION = "description";
export const LAST = "last";
export const INFORMATION = "information";
export const CUSTOMISE_URL_HANDLE = "customise_url_handle";
export const SELECT_CLUB_STATUS = "select_club_status";
export const CONFIG_REGISTRATION_HOOK =
  "config_registration_hook";
export const CONFIG_REGISTRATION_HOOK_DESCRIPTION =
  "config_registration_hook_description";
export const PROFILE_DISPLAY = "profile_display";
export const PROFILE_DISPLAY_DESCRIPTION =
  "profile_display_description";
export const VERIFIED = "verified";
export const NOT_VERIFIED = "not_verified";
export const SINCE = "since";
export const CREATE_ARTICLE_DESCRIPTION =
  "create_article_description";
export const PREVIEW = "preview";
export const JOIN_TO_POST = "join_to_post";
export const ADD = "add";
export const HEADER = "header";
export const LINE = "line";
export const HEADER_IMAGE = "header_image";
export const WRITE = "write";
export const TITLE = "title";
export const AUTHOR_NAME = "author_name";
export const SUBTITLE = "subtitle";
export const A_WORD = "a_word";
export const DESCRIPTION_USE_PREVIEW =
  "description_use_preview";
export const CONTENT = "content";
export const TITLE_PREVIEW_PLACEHOLDER =
  "title_preview_placeholder";
export const DESCRIPTION_PREVIEW_PLACEHOLDER =
  "description_preview_placeholder";
export const CONTENT_PREVIEW_PLACEHOLDER =
  "content_preview_placeholder";
export const ONLINE = "online";
export const OFFLINE = "offline";
export const EDIT_ARTICLE_DESCRIPTION =
  "edit_article_description";
export const EDIT_LESSON_DESCRIPTION =
  "edit_lesson_description";
export const UPDATE = "update";
export const UPDATING = "updating";
export const WHAT_WE_DO = "what_we_do";
export const WHO_CAN_JOIN = "who_can_join";
export const WHO_CAN_CONTRIBUTE = "who_can_contribute";
export const END_ON = "end_on";
export const EXIT = "exit";
export const ALL_USERS = "all_users";
export const MEMBERS_ONLY = "members_only";
export const INVITATION_ONLY = "invitation_only";
export const MANAGE_QUESTIONNARE_DESCRIPTION =
  "manage_questionnaire_description";
export const MANAGE_CONTRIBUTIONS_SUBMITTED =
  "manage_contributions_submitted";
export const OK = "ok";
export const TERMS = "terms";
export const COOKIES = "cookies";
export const ADVERTISING = "advertising";
export const PRIVACY = "privacy";
export const SETTINGS = "settings";
export const GLOBAL = "global";
export const MOST_ACTIVE_MEMBERS = "most_active_members";
export const ADD_ACTIVITY_INFORMATION_DESCRIPTION =
  "add_activity_information_description";
export const CLUBS_RECENT_ACTIVITY =
  "clubs_recent_activity";
export const POPULAR_CLUBS = "popular_clubs";
export const MOST_ACTIVE_CAMPAIGNS =
  "most_active_campaigns";
export const NOTIFICATION_SETTINGS =
  "notification_settings";
export const PARTICIPATE = "participate";
export const PARTICIPANT = "participant";
export const PARTICIPANTS = "participants";
export const ADD_NEW_PARTICIPANT = "Add_New_Participant";
export const ADD_NEW_ACTION = "add_new_action";
export const ADD_LESSON = "add_lesson";
export const CONTRIBUTE = "contribute";
export const HOSTED = "hosted";
export const ENDED = "ended";
export const DAYS = "days";
export const SUBMIT = "submit";
export const PROCESSING = "processing";
export const CHARACTERS_REMAINING = "characters_remaining";
export const ADD_EXTERNAL_SOURCE = "add_external_source";
export const NOW_PARTICIPATING = "now_participating";
export const START = "start";
export const COMPLETE = "complete";
export const INCOMPLETE = "incomplete";
export const ADD_LISTING_DESCRIPTION =
  "add_listing_description";
export const ADD_LISTING_DESCRIPTION_UNLIMITED =
  "add_listing_description_unlimited";
export const INCOMPLETE_ENTRIES = "incomplete_entries";
export const COMPLETE_ENTRIES = "complete_entries";
export const INCOMPLETE_DRAFT_ENTRIES =
  "incomplete_draft_entries";
export const COMPLETE_DRAFT_ENTRIES =
  "complete_draft_entries";
export const YOU = "you";
export const WANT = "want";
export const YOUR_WORD = "your_word";
export const HAVE = "have";
export const HAS = "has";
export const LEFT_COUNT = "left_count";
export const ENTRIES = "entries";
export const ENTRY = "entry";
export const REMAINING = "remaining";
export const SHOWING = "showing";
export const NAME = "name";
export const STATUS = "status";
export const UNKNOWN = "unknown";
export const VISIBILITY = "visibility";
export const USER = "user";
export const DATE_JOINED = "date_joined";
export const CREATED = "created";
export const CREATED_ON = "created_on";
export const LAST_UPDATED = "last_updated";
export const SHARED = "shared";
export const REVIEW = "review";
export const RESET_PASSWORD = "reset_password";
export const RESETTING = "resetting";
export const VIEW_ACTIVITY_FEED = "view_activity_feed";
export const NOTIFICATIONS = "notifications";
export const VIEW = "view";
export const PROMOTION = "promotion";
export const PARTICIPANT_WELCOME_MESSAGE =
  "participant_Welcome_message";
export const NO_CONTRIBUTION_SUBMITTED_MESSAGE =
  "no_contribution_submitted_message";
export const CONTRIBUTING = "contributing";
export const ADD_NEW_ENTRY = "add_new_entry";
export const EDIT_ENTRY = "edit_entry";
export const ENTRY_PLURAL = "entries";

export const FIND = "find";
export const CLUB = "club";
export const CONTRIBUTION = "contribution";
export const CONTRIBUTION_DESCRIPTION =
  "contribution_description";
export const CONTRIBUTION_SAVED_SUCCESSFULLY =
  "contribution_saved_successfully";
export const CONTRIBUTION_SUBMITTED_SUCCESSFULLY =
  "contribution_submitted_successfully";
export const FIND_CLUB_DESCRIPTION =
  "find_club_description";
export const FIND_ACTIVITY_DESCRIPTION =
  "find_activity_description";
export const FIND_CONTRIBUTION_DESCRIPTION =
  "find_contribution_description";
export const PROMOTE = "promote";
export const PROMOTE_FORM_DESCRIPTION =
  "promote_form_description";

export const CAPTION = "caption";
export const SUMMARY = "summary";
export const CALL_TO_ACTION = "call_to_action";
export const SELECT_ACTION_TYPE = "select_action_type";
export const PROMO_IMAGE_DESCRIPTION =
  "promo_image_description";
export const IMAGE_TYPE_ALLOWED = "image_type_allowed";

export const CREATE_ACTIVITY_SUBTITLE =
  "create_activity_subtitle";
export const EDIT_ACTIVITY_SUBTITLE =
  "edit_activity_subtitle";
export const CHANGE_LOGO = "change_logo";
export const CHANGE_BACKGROUND_IMAGE_DESC =
  "change_background_image_desc";
export const BACKGROUND_IMAGE = "background_image";
export const PREVIEW_UPLOADED_IMAGE_DESC =
  "preview_uploaded_image_desc";
export const DATE = "date";
export const START_DATE = "start_date";
export const END_DATE = "end_date";
export const ACTIVITY_CONTINUES = "activity_continues";
export const ACTIVITY_STATUS_SELECT_DESCRIPTION =
  "activity_status_select_description";
export const ACTIVITIES = "activities";
export const CLUB_ACTIVITIES_DESCRIPTION =
  "club_activities_description";
export const NO_DESCRIPTION_FOUND = "no_description_found";
export const MORE_INFORMATION = "more_information";
export const HOW = "how";
export const NO_MORE_ACTIVITY_INFORMATION =
  "no_more_activity_information";
export const FOLLOW_STEPS = "follow_the_steps";
export const THIS_WORD = "this_word";
export const VIEW_ALL = "view_all";

export const SELECT = "select";
export const ARTICLE = "article";
export const LESSON = "lesson";
export const COMING_SOON = "coming_soon";
export const ADD_MEMBER_DESCRIPTION =
  "add_member_description";
export const EDIT_MEMBER_DESCRIPTION =
  "edit_member_description";
export const ROLE = "role";
export const QUESTIONNAIRE = "questionnaire";
export const QUESTION = "question";
export const PROMOTIONS = "promotions";
export const QUESTIONNAIRES = "questionnaires";
export const POST_DESCRIPTION = "post_description";
export const CONTENT_DESCRIPTION = "content_description";
export const QUESTIONNAIRE_DESCRIPTION =
  "questionnaire_description";
export const QUESTIONNAIRE_DESCRIPTION2 =
  "questionnaire_description2";
export const FORM_TITLE = "form_title";
export const FORM_DESCRIPTION = "form_description";
export const TYPE = "type";
export const QUESTION_TYPE = "question_type";
export const LABEL = "label";
export const REQUIRED = "required";
export const TEXT_AREA = "text_area";
export const TEXT = "text";
export const IMAGE = "image";
export const OPTION = "option";
export const MULTIPLE = "multiple";
export const INPUT_KEY = "input_key";
export const OUTPUTS = "outputs";
export const CLUB_OUTPUTS_DESCRIPTION =
  "club_outputs_description";
export const OUTPUT = "output";
export const SELECT_PROMOTION_TYPE =
  "select_promotion_type";
export const ACTIONS = "actions";
export const ACTION = "action";
export const STEP = "step";
export const CREATE_ACTION_SUBTITLE =
  "create_action_subtitle";
export const EDIT_ACTION_SUBTITLE = "edit_action_subtitle";
export const MAX_SUBMISSIONS = "max_submissions";
export const NO_QUESTIONNAIRE_IN_CLUB =
  "no_questionnaire_in_club";
export const CONTRIBUTIONS = "contributions";
export const REJECT = "reject";
export const REJECTED = "rejected";
export const APPROVE = "approve";
export const APPROVED = "approved";
export const UPDATED = "updated";
export const OVERVIEW_WORD = "overview_word";
export const ABOUT_CLUB = "about_club";
export const CHOOSE_OUTPUT_SOURCE_DESCRIPTION =
  "choose_output_source_description";
export const VIEW_CLUB_INFORMATION =
  "view_club_information";
export const STARTS_ON = "starts_on";
export const MINIMUM = "mininum";
export const MAXIMUM = "maximum";
export const CHOICES = "choices";
export const MULTIPLE_CHOICE = "multiple_choice";
export const PARAMETERS = "parameters";
export const MAX_CHOICES_REACHED = "max_choices_reached";
export const DEFAULTS = "defaults";
export const PENDING = "pending";
export const CONTRIBUTIONS_PENDING_REVIEW =
  "contributions_pending_review";
export const MANAGE_OUTPUTS_DESCRIPTION =
  "manage_outputs_description";
export const TARGET_AUDIENCE = "target_audience";
export const JOIN_THE_CAMPAIGN_TO_VIEW_PARTICIPANTS =
  "join_the_campaign_to_view_participants";
export const HERE = "here";
export const CREATE_OUTPUT_SUBTITLE =
  "create_output_subtitle";
export const EDIT_OUTPUT_SUBTITLE = "edit_output_subtitle";
export const OUTPUT_TYPE = "output_type";
export const KEY = "key";
export const VALUE = "value";
export const SOURCES = "sources";
export const SOURCE = "source";
export const REMOVE = "remove";
export const REMOVING = "removing";
export const ACTION_IS_REMOVED_SUCCESSFULLY =
  "action_is_removed_successfully";
export const POST_IS_DELETED_SUCCESSFULLY =
  "post_is_deleted_successfully";
export const MOST_ACTIVE_ACTIVITIES =
  "most_active_activities";
export const MOST_POPULAR_CLUBS = "most_popular_clubs";
export const CLUBS_WITH_RECENT_ACTIVITY =
  "clubs_with_recent_activity";
export const NO_RESULTS_TO_DISPLAY =
  "no_results_to_display";
export const NO_CLUBS_JOINED = "no_clubs_joined";
export const NO_ACTION_AVAILABLE = "no_action_Available";
export const NO_ACTIVITIES_PARTICIPATION =
  "no_activities_participation";
export const DELETE = "delete";
export const SEND = "send";
export const DELETING = "delete";
export const DELETED = "deleted";
export const DELETE_POST = "delete_post";
export const CONFIRMATION_TEXT = "confirmation_text";
export const CONFIRMATION = "confirmation";
export const ARE_YOU_SURE_TEXT = "are_you_sure_text";
export const REMOVE_MEMBER_CONFIRMATION_TEXT =
  "remove_member_confirmation_text";
export const REMOVE_CONFIRMATION_TEXT =
  "remove_confirmation_text";
export const DELETE_CONFIRMATION_TEXT =
  "delete_confirmation_text";
export const FROM = "from";
export const CALENDAR = "calendar";
export const TODAY = "today";
export const ADD_PARTICIPANT_DESCRIPTION =
  "add_participant_description";
export const ADD_PARTICIPANT_SUCCESS_MESSAGE =
  "add_participant_success_message";
export const RESPONSE = "response";
export const ADVANCED_SETTINGS = "advanced_settings";
export const ADVANCED_SETTINGS_DESCRIPTION =
  "advanced_settings_description";
export const ACTIVITY_STATUS_DESCRIPTION =
  "activity_status_description";
export const NO_OPTIONS_AVAILABLE = "no_options_available";
export const ANSWER_PREV_QUESTION = "answer_prev_question";
export const NO_PARTICIPANTS = "no_participants";
export const ERROR_UPLOADING_PICTURE =
  "error_uploading_picture";
export const CREATE_CLUB_SUBTITLE = "create_club_subtitle";
export const EDIT_CLUB_SUBTITLE = "edit_club_subtitle";
export const ABOUT = "about";
export const SERVICES_SECTION = "services_section";
export const ABOUT_SECTION = "about_section";
export const SECTION = "section";
export const SELECT_FROM_COMPUTER = "select_from_computer";
export const CLICK_HERE_TO_UPLOAD = "click_here_to_upload";
export const LOGO = "logo";
export const LANDING_PAGE = "landing_page";
export const FOOTER = "footer";
export const ERROR_PROCESSING_REQUEST =
  "error_processing_request";
export const SUCCESS_PROCESSING_REQUEST =
  "success_processing_request";
export const CREATED_SUCCESSFULLY = "created_successfully";
export const CLONED_SUCCESSFULLY = "cloned_successfully";
export const UPDATED_SUCCESSFULLY = "updated_successfully";
export const SUCCESSFULLY = "successfully";
export const SITE_ADMIN = "site_admin";
export const CLUB_ADMIN = "club_admin";
export const ACTIVITY_ADMIN = "activity_admin";
export const USERS_ADMIN = "users_admin";
export const POSTS_ADMIN = "posts_admin";
export const QUESTIONNAIRES_ADMIN = "questionnaires_admin";
export const USERS = "users";
export const UPDATE_ROLE_NOTIFICATION_SUCCESSFULLY =
  "update_role_notification_successfully";
export const DELETE_PARTICIPANT_NOTIFICATION_SUCCESSFULLY =
  "delete_participant_notification_successfully";
export const UPDATE_ROLE_NOTIFICATION_ERROR =
  "update_role_notification_error";
export const DELETE_PARTICIPANT_NOTIFICATION_ERROR =
  "delete_participant_notification_error";
export const NO_CONTRIBUTION_STEPS =
  "no_contribution_steps";
export const MEMBERS = "members";
export const LAST_ACTIVITY = "last_activity";
export const NO_MEMBERS = "no_members";
export const NO_ACTIVITIES = "no_activities";
export const NO_OUTPUTS = "no_outputs";
export const CLUB_ACTIVITIES = "club_activities";
export const CLUB_OUTPUTS = "club_outputs";
export const CLUB_MEMBERS = "club_members";
export const CLUB_MEMBERS_DESCRIPTION =
  "club_members_description";
export const SPECIFY_DATE_RANGE = "specify_date_range";
export const FILTERS = "filters";
export const DATE_ADDED = "date_added";
export const DOCUMENT_LIBRARY = "document_library";
export const SEARCH_LIBRARY = "search_library";
export const SORT = "sort";
export const EXPORT = "export";
export const FILES = "files";
export const PROFILE_DIRECTORY = "profile_directory";
export const DIRECTORY = "directory";
export const PROFILE_PAGE = "profile_page";
export const GALLERY_PAGE = "gallery_page";
export const MEDIA_LIBRARY = "media_library";
export const MEDIA = "media";
export const PAY = "pay";
export const PAYING = "paying";
export const LISTINGS = "listings";
export const LISTING = "listing";
export const DOWNLOAD = "download";
export const UPLOADED = "uploaded";
export const COPY_LINK = "copy_link";
export const COPIED = "copied";
export const CREATE_OUTPUT_STATUS_DESCRIPTION =
  "create_output_status_description";
export const BASIC = "basic";
export const POSTS = "posts";
export const CLUBS = "clubs";
export const WEB_CONTENT = "web_content";
export const STARTED_ON = "started_on";
export const STARTED_BY = "started_by";
export const CREATE_NEW = "create_new";
export const SELECT_USER_STATUS = "select_user_status";
export const ROWS_PER_PAGE = "rows_per_page";
export const ANONYMOUS = "anonymous";
export const CLICK_TO_REVIEW_CONTRIBUTION =
  "click_to_review_contribution";
export const OPEN_PARTICIPANT_VIEW =
  "open_participant_view";
export const ACTIVITY_SETTINGS_DESCRIPTION =
  "activity_settings_description";
export const HOW_TO_PARTICIPATE = "how_to_participate";
export const HOW_TO_PARTICIPATE_DESCRIPTION =
  "how_to_participate_description";
export const PARTICIPANT_DESCRIPTION =
  "participant_description";
export const GENERAL = "general";
export const PREVIOUSLY_ADDED_ACTIONS =
  "previously_added_actions";
export const CONFIGURE_ACTIVITY_ADVANCED_SETTINGS =
  "configure_activity_advanced_settings";
export const PARTICIPATE_CALL_TO_ACTION =
  "participate_call_to_action";
export const PARTICIPATE_CALL_TO_ACTION_DESCRIPTION =
  "participate_call_to_action_description";
export const CONTRIBUTE_CALL_TO_ACTION =
  "contribute_call_to_action";
export const CONTRIBUTE_CALL_TO_ACTION_DESCRIPTION =
  "contribute_call_to_action_description";
export const DISPLAY_CONTRIBUTIONS =
  "display_contributions";
export const DISPLAY_CONTRIBUTIONS_DESCRIPTION =
  "display_contributions_description";
export const DISPLAY_PARTICIPANTS = "display_participants";
export const DISPLAY_PARTICIPANTS_DESCRIPTION =
  "display_participants_description";
export const CTA_TEXT = "cta_text";
export const ACTIVITY_DETAILS_VISIBILITY =
  "activity_details_visibility";
export const CUSTOMIZE_URL_HANDLE = "customize_url_handle";
export const BACK_TO_ACTIVITY_PAGE =
  "back_to_activity_page";
export const PRIVACY_SETTINGS = "privacy_settings";
export const ANONYMOUS_CONTRIBUTIONS =
  "anonymous_contributions";
export const ANONYMOUS_CONTRIBUTIONS_DESCRIPTION =
  "anonymous_contributions_description";
export const DATA_EXPORT = "data_export";
export const DATA_EXPORT_DESCRIPTION =
  "data_export_description";
export const WELCOME_EMAIL = "welcome_email";
export const SEND_PARTICIPANT_wELCOME_EMAIL =
  "send_participant_welcome_email";
export const wELCOME_EMAIL_SENDER = "welcome_email_sender";
export const wELCOME_EMAIL_SUBJECT =
  "welcome_email_subject";
export const wELCOME_EMAIL_CONTENT =
  "welcome_email_content";
export const CONTRIBUTION_CONFIRMATION_EMAIL =
  "contribution_confirmation_email";
export const SEND_CONTRIBUTION_CONFIRMATION_EMAIL =
  "send_contribution_confirmation_email";
export const CONTRIBUTION_CONFIRMATION_EMAIL_SENDER =
  "contribution_confirmation_email_sender";
export const CONTRIBUTION_CONFIRMATION_EMAIL_SUBJECT =
  "contribution_confirmation_email_subject";
export const CONTRIBUTION_CONFIRMATION_EMAIL_CONTENT =
  "contribution_confirmation_email_content";
export const REVIEW_NOTIFICATION_EMAIL =
  "review_notification_email";
export const SEND_REVIEW_NOTIFICATION_EMAIL =
  "send_review_notification_email";
export const REVIEW_NOTIFICATION_EMAIL_SENDER =
  "review_notification_email_sender";
export const REVIEW_NOTIFICATION_EMAIL_SUBJECT =
  "review_notification_email_subject";
export const REVIEW_NOTIFICATION_EMAIL_CONTENT =
  "review_notification_email_content";
export const STATUS_APPROVED = "status_approved";
export const STATUS_REJECTED = "status_rejected";
export const STATUS_PENDING = "status_pending";
export const CONTRIBUTION_INTRO = "contribution_intro";
export const NO_CONTRIBUTIONS = "no_contributions";
export const GO_TO_PROFILE = "go_to_profile";
export const ADD_CONTRIBUTIONS = "add_contributions";
export const SELECT_ACTION_GET_STARTED =
  "select_action_get_started";
export const CHOOSE_ACTION_CONTRIBUTE_BEHALF =
  "choose_action_contribute_behalf";
export const SUBMIT_CONTRIBUTION_BEHALF =
  "submit_contribution_behalf";
export const CONFIGURE_CLUB_ADVANCED_SETTINGS =
  "configure_club_advanced_settings";
export const BACK_TO_CLUB_PAGE = "back_to_club_page";
export const JOIN_CALL_TO_ACTION = "join_call_to_action";
export const JOIN_CALL_TO_ACTION_DESCRIPTION =
  "join_call_to_action_description";
export const ENABLE_ACTIVITY_FEED = "enable_activity_feed";
export const ENABLE_ACTIVITY_FEED_DESCRIPTION =
  "enable_activity_feed_description";
export const MEMBERS_CREATE_POSTS = "members_create_posts";
export const MEMBERS_CREATE_POSTS_DESCRIPTION =
  "members_create_posts_description";
export const DISPLAY_MEMBERS_PANEL =
  "display_members_panel";
export const DISPLAY_MEMBERS_PANEL_DESCRIPTION =
  "display_members_panel_description";
export const CLUB_DETAILS_VISIBILITY =
  "club_details_visibility";
export const SEND_MEMBER_wELCOME_EMAIL =
  "send_member_welcome_email";
export const LOGIN_TO_CONTINUE = "login_to_continue";
export const CONFIGURE_YOUR = "configure_your";
export const EVENT_DETAILS = "event_details";
export const CHANGES_SAVED = "changes_saved";
export const MESSAGE = "message";
export const UPLOAD_IMAGE_TOOLTIP = "upload_image_tooltip";
export const WHAT_I_CURRENTLY_DO = "what_i_currently_do";
export const EN_LABELS = "en_labels";
export const FR_LABELS = "fr_labels";

export const userRoles = [
  {
    index: 0,
    text: getLabelByName(MEMBER),
    value: "member",
  },
  { index: 1, text: getLabelByName(ADMIN), value: "admin" },
  {
    index: 1,
    text: getLabelByName(SITE_ADMIN),
    value: "site_admin",
  },
  {
    index: 1,
    text: getLabelByName(CLUB_ADMIN),
    value: "club_admin",
  },
  {
    index: 1,
    text: getLabelByName(ACTIVITY_ADMIN),
    value: "activity_admin",
  },
  {
    index: 1,
    text: getLabelByName(USERS_ADMIN),
    value: "users_admin",
  },
  {
    index: 1,
    text: getLabelByName(POSTS_ADMIN),
    value: "posts_admin",
  },
  {
    index: 1,
    text: getLabelByName(QUESTIONNAIRES_ADMIN),
    value: "questionnaires_admin",
  },
];

export const outputTypes = {
  Default: getLabelByName(DIRECTORY),
  PROFILE_PAGE: getLabelByName(PROFILE_PAGE),
  CALENDAR: getLabelByName(CALENDAR),
  GALLERY_PAGE: getLabelByName(GALLERY_PAGE),
  MEDIA_LIBRARY: getLabelByName(MEDIA_LIBRARY),
};

export const sourceTypes = {
  listings: getLabelByName(LISTINGS),
  profile: getLabelByName(PROFILE),
};

export const outputSteps = {
  basicInformation: `${getLabelByName(
    BASIC,
  )} ${getLabelByName(INFORMATION)}`,
  sources: getLabelByName(SOURCES),
  settings: getLabelByName(SETTINGS),
};

export const visibilityOptions = [
  { value: "PUBLIC", name: getLabelByName(ALL_USERS) },
  {
    value: "PRIVATE",
    name: getLabelByName(MEMBERS_ONLY),
  },
  {
    value: "RESTRICTED",
    name: getLabelByName(INVITATION_ONLY),
  },
];

export const statuses = [
  { text: "Active", value: "ACTIVE" },
  { text: "Inactive", value: "INACTIVE" },
];

export const contributionStatusValues = {
  pending: "PENDING_REVIEW",
  approved: "APPROVED",
  rejected: "REJECTED",
};

export const contributionStatus = {
  pending: "Pending",
  approved: "Approved",
  rejected: "Rejected",
};

export const webContentCategory = {
  logo: "logo",
  landingPage: "landing page",
  footer: "footer",
  socialMedia: "social media",
  privacyPolicy: "privacy policy",
  termsConditions: "terms & conditions",
  en_labels: "en labels",
  fr_labels: "fr labels",
};

export const webContentSection = {
  logo: "logo",
  backgroundImage: "background-image",
  welcomeHeader: "welcome-header",
  welcomeText: "welcome-text",
  siteSlogan: "site-slogan",
  aboutHeader: "about-header",
  aboutText: "about-text",
  aboutImage: "about-image",
  servicesHeader: "services-header",
  servicesText: "services-text",
  servicesImage: "services-image",
  copyrightText: "copyright-text",
  loginText: "login-text",
  signupText: "signup-text",
  footerLink: "footer-link",
  footerText: "footer-text",
  footerButton: "footer-button",
  emailLink: "email",
  instagramLink: "instagram",
  linkedInLink: "linkedin",
  facebookLink: "facebook",
  twitterLink: "twitter",
  displayEmail: "displayEmail",
  displayInstagram: "displayInstagram",
  displayLinkedIn: "displayLinkedIn",
  displayFacebook: "displayFacebook",
  displayTwitter: "displayTwitter",
  displayHome: "displayHome",
  displayPrivacyPolicy: "displayPrivacyPolicy",
  displaySubscription: "displaySubscription",
  displaySupport: "displaySupport",
  displayTermsConditions: "displayTermsConditions",
  privacyPolicyMessage: "privacyPolicyMessage",
  displayAbout: "displayAbout",
  displayServices: "displayServices",
};

export const linkedPages = {
  home: getLabelByName(HOME),
  privacyPolicy: getLabelByName(PRIVACY_POLICY),
  support: getLabelByName(SUPPORT),
  TermsConditions: getLabelByName(TERMS_CONDITIONS),
  aboutSection: getLabelByName(ABOUT_SECTION),
  servicesSection: getLabelByName(SERVICES_SECTION),
  instagram: "Instagram",
  LinkedIn: "LinkedIn",
  facebook: "Facebook",
  twitter: "Twitter",
  email: "Email",
  subscription: "Subscription",
};

export const outputParams = {
  Default: {
    general_settings: [
      {
        name: "Enable Search",
        key: "enable_search",
        type: "boolean",
        displayOrder: 1,
      },
      {
        name: "Enable Filters",
        key: "enable_filters",
        type: "boolean",
        displayOrder: 2,
      },
      {
        name: "Enable Detail View",
        key: "enable_detail_view",
        type: "boolean",
        displayOrder: 3,
      },
    ],
    sources: ["listings", "profile"],
    source_settings: {
      listings: [
        {
          name: `${getLabelByName(TITLE)}`,
          key: "listing_title",
          type: "text",
          displayOrder: 1,
        },
        {
          name: `${getLabelByName(SUBTITLE)}`,
          key: "listing_subtitle",
          type: "text",
          displayOrder: 2,
        },
        {
          name: `${getLabelByName(IMAGE)}`,
          key: "listing_image",
          type: "url",
          displayOrder: 3,
        },
      ],
      profile: [
        {
          name: `${getLabelByName(TITLE)}`,
          key: "profile_title",
          type: "text",
          displayOrder: 1,
        },
        {
          name: `Header 1`,
          key: "header_1",
          type: "text",
          displayOrder: 2,
        },
        {
          name: `Body 1`,
          key: "body_1",
          type: "text",
          displayOrder: 3,
        },
        {
          name: `Header 2`,
          key: "header_2",
          type: "text",
          displayOrder: 4,
        },
        {
          name: `Body 2`,
          key: "body_2",
          type: "text",
          displayOrder: 5,
        },
        {
          name: `${getLabelByName(IMAGE)}`,
          key: "profile_picture",
          type: "url",
          displayOrder: 6,
        },
        {
          name: `Caption 1`,
          key: "caption_1",
          type: "text",
          displayOrder: 7,
        },
        {
          name: `Caption 2`,
          key: "caption_2",
          type: "text",
          displayOrder: 8,
        },
      ],
    },
  },
  MEDIA_LIBRARY: {
    general_settings: [
      {
        name: "Enable Filters",
        key: "enable_filters",
        type: "boolean",
        displayOrder: 1,
      },
      {
        name: "Enable Date Filters",
        key: "enable_date_filters",
        type: "boolean",
        displayOrder: 2,
      },
      {
        name: "Enable Type Filters",
        key: "enable_type_filters",
        type: "boolean",
        displayOrder: 3,
      },
    ],
    sources: ["listings"],
    source_settings: {
      listings: [
        {
          name: `${getLabelByName(TITLE)}`,
          key: "listing_title",
          type: "text",
          displayOrder: 1,
        },
        {
          name: `${getLabelByName(SUBTITLE)}`,
          key: "listing_subtitle",
          type: "text",
          displayOrder: 2,
        },
        {
          name: `${getLabelByName(IMAGE)}`,
          key: "listing_image",
          type: "url",
          displayOrder: 3,
        },
      ],
    },
  },
  GALLERY_PAGE: {
    general_settings: [
      {
        name: "Enable Search",
        key: "enable_search",
        type: "boolean",
        displayOrder: 1,
      },
      {
        name: "Enable Filters",
        key: "enable_filters",
        type: "boolean",
        displayOrder: 2,
      },
      {
        name: "Enable Detail View",
        key: "enable_detail_view",
        type: "boolean",
        displayOrder: 3,
      },
      {
        name: "Enable Voting",
        key: "enable_voting",
        type: "boolean",
        displayOrder: 3,
      },
    ],
    sources: ["listings"],
    source_settings: {
      listings: [
        {
          name: `${getLabelByName(TITLE)}`,
          key: "listing_title",
          type: "text",
          displayOrder: 1,
        },
        {
          name: `${getLabelByName(SUBTITLE)}`,
          key: "listing_subtitle",
          type: "text",
          displayOrder: 2,
        },
        {
          name: `${getLabelByName(IMAGE)}`,
          key: "listing_image",
          type: "url",
          displayOrder: 3,
        },
      ],
    },
  },
  CALENDAR: {
    general_settings: [
      {
        name: "Enable Search",
        key: "enable_search",
        type: "boolean",
        displayOrder: 1,
      },
      {
        name: "Enable Filters",
        key: "enable_filters",
        type: "boolean",
        displayOrder: 2,
      },
      {
        name: "Enable Date Filters",
        key: "enable_date_filters",
        type: "boolean",
        displayOrder: 3,
      },
      {
        name: "Enable Detail View",
        key: "enable_detail_view",
        type: "boolean",
        displayOrder: 4,
      },
    ],
    sources: ["listings"],
    source_settings: {
      listings: [
        {
          name: `${getLabelByName(TITLE)}`,
          key: "event_title",
          type: "text",
          displayOrder: 1,
        },
        {
          name: `${getLabelByName(SUBTITLE)}`,
          key: "event_name",
          type: "text",
          displayOrder: 2,
        },
        {
          name: `${getLabelByName(DESCRIPTION)}`,
          key: "event_description",
          type: "text",
          displayOrder: 3,
        },
        {
          name: `${getLabelByName(DATE)}`,
          key: "event_dates",
          type: "text",
          displayOrder: 4,
        },
      ],
    },
  },
  PROFILE_PAGE: {
    general_settings: [],
    sources: ["listings", "profile"],
    source_settings: {
      listings: [
        {
          name: `${getLabelByName(TITLE)}`,
          key: "profile_title",
          type: "text",
          displayOrder: 1,
        },
        {
          name: `${getLabelByName(SUBTITLE)}`,
          key: "profile_subtitle",
          type: "text",
          displayOrder: 2,
        },
        {
          name: `${getLabelByName(IMAGE)}`,
          key: "profile_picture",
          type: "url",
          displayOrder: 3,
        },
      ],
      profile: [
        {
          name: `Title`,
          key: "profile_title",
          type: "text",
          displayOrder: 1,
        },
        {
          name: `Subtitle 1`,
          key: "profile_subtitle_1",
          type: "text",
          displayOrder: 2,
        },
      ],
    },
  },
};
