/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { toast } from "react-toastify";
import { FormGroup } from "react-bootstrap";
import {
  ArrowBackIos,
  VisibilityOutlined,
} from "@material-ui/icons";
import { signupAction } from "../redux/actions/authActions";
import { getUserClubs } from "../redux/actions/institutesActions";
import {
  getLabelByName,
  userIsLoggedIn,
} from "../helpers/helperFunctions";
import {
  AGREE_TO,
  colors,
  FEMALE,
  GENDER,
  MALE,
  OF_WORD,
  OTHER,
  SIGNINGP_UP,
  SIGNUP,
  TERMS_CONDITIONS,
  FIRSTNAME,
  EMAIL,
  LASTNAME,
  PASSWORD,
} from "../helpers/constants";
import Checkbox from "./common/checkbox/checkbox";

export class Signup extends Component {
  state = {
    firstName: "",
    lastName: "",
    location_country: "",
    location_city: "",
    gender: "",
    emailAddress: "",
    password: "",
    extra: [],
    isLoading: false,
    hasAgreed: false,
    redirectTo: null,
    showPassword: true,
  };

  async UNSAFE_componentWillMount() {
    const { props } = this;
    const query = new URLSearchParams(
      props.location.search,
    );
    const redirectTo = query.get("redirect_to");

    if (userIsLoggedIn(this.state.profileData)) {
      this.props.history.push("/explore");
    }

    this.setState({ redirectTo });
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { dataError, status, data, history } = nextProps;
    switch (status) {
      case "success":
        this.setState({ isLoading: false });
        switch (data.status) {
          case 200:
            await this.props.getUserClubs();
            history.push(
              `/login${
                this.state.redirectTo
                  ? `?redirect_to=${this.state.redirectTo}`
                  : ""
              }`,
            );
            toast.success("Successfully registered!");
            break;
          default:
            toast.error(data.error.message);
            break;
        }
        break;
      case "error":
        this.setState({ isLoading: false });
        toast.error(dataError.message);
        break;
      default:
        break;
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleRadio = (e) => {
    this.setState({ gender: e.target.value });
  };

  updateAgree = () => {
    const { hasAgreed } = this.state;
    this.setState({ hasAgreed: !hasAgreed });
  };

  handleSubmit = (e) => {
    const { props } = this;
    e.preventDefault();

    const {
      firstName,
      lastName,
      location_country,
      location_city,
      gender,
      emailAddress,
      password,
      extra,
    } = this.state;
    const userData = {
      firstName,
      lastName,
      location_country,
      location_city,
      gender,
      emailAddress,
      password,
      extra,
    };

    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));

    props.signupAction(userData, "SIGNUP");
  };

  render() {
    const { REACT_APP_APPLICATION_NAME } = process.env;
    document.title = `${
      REACT_APP_APPLICATION_NAME || "SOSO"
    } - ${getLabelByName(SIGNUP)}`;
    const {
      // firstName,
      isLoading,
      hasAgreed,
    } = this.state;

    const genders = [
      {
        index: 0,
        text: getLabelByName(MALE),
        value: "male",
      },
      {
        index: 1,
        text: getLabelByName(FEMALE),
        value: "female",
      },
      {
        index: 2,
        text: getLabelByName(OTHER),
        value: "other",
      },
    ];

    const { landingData } = this.props;
    let countriesInfo = null;
    if (landingData && landingData.data) {
      countriesInfo = landingData.data.filter(
        (x) => x.name === "Countries List",
      )[0].data;
    }
    let countriesList = [];

    if (countriesInfo) {
      countriesList = Object.keys(countriesInfo).map(
        (item, index) => ({
          value: item,
          text: Object.values(countriesInfo)[index],
        }),
      );
    }

    let landingInfo = null;
    if (landingData?.data) {
      landingInfo = landingData.data.filter(
        (x) => x.name === "landing-page",
      )[0];
    }

    countriesList.sort((a, b) =>
      a.text > b.text ? 1 : b.text > a.text ? -1 : 0,
    );

    const signupText =
      landingInfo?.data["signup-text"] ||
      `Sign up to ${process.env.REACT_APP_APPLICATION_NAME} to discover and join communities`;

    return (
      <>
        <div className="back-homepage">
          <div>
            <Link
              to="/"
              style={{
                color: colors.textPrimary,
                opacity: "0.5",
              }}>
              <ArrowBackIos />
              {"  "}
              Back
            </Link>
          </div>
        </div>
        <div className="signup-form">
          <h5>{signupText}</h5>
          <form
            onSubmit={this.handleSubmit}
            className="auth-form mt-3">
            <FormGroup>
              <input
                type="text"
                id="firstName"
                name="firstName"
                className="form-control"
                onChange={this.handleChange}
                required
              />
              <label
                className="form-control-placeholder"
                htmlFor="firstName">
                {getLabelByName(FIRSTNAME)}
              </label>
            </FormGroup>
            <FormGroup>
              <input
                type="text"
                id="lastName"
                name="lastName"
                className="form-control"
                onChange={this.handleChange}
                required
              />
              <label
                className="form-control-placeholder"
                htmlFor="lastName">
                {getLabelByName(LASTNAME)}
              </label>
            </FormGroup>
            <FormGroup>
              <input
                type="email"
                id="emailAddress"
                name="emailAddress"
                onChange={this.handleChange}
                className="form-control"
                required
              />
              <label
                className="form-control-placeholder"
                htmlFor="emailAddress">
                {getLabelByName(EMAIL)}
              </label>
            </FormGroup>
            <FormGroup>
              <input
                type={
                  this.state.showPassword
                    ? "password"
                    : "text"
                }
                id="password"
                name="password"
                className="form-control"
                onChange={this.handleChange}
                required
              />
              <label
                className="form-control-placeholder"
                htmlFor="password">
                {getLabelByName(PASSWORD)}
              </label>
              <VisibilityOutlined
                onClick={() =>
                  this.setState((prevState) => ({
                    showPassword: !prevState.showPassword,
                  }))
                }
                style={{
                  position: "absolute",
                  top: "25%",
                  left: "90%",
                  color: "#ADADAD",
                  cursor: "pointer",
                }}
              />
            </FormGroup>
            <FormGroup>
              <input
                type="text"
                id="input-city"
                name="location_city"
                className="form-control"
                onChange={this.handleChange}
                required
              />
              <label
                className="form-control-placeholder"
                htmlFor="input-city">
                City
              </label>
            </FormGroup>
            <FormGroup>
              <select
                className="form-control"
                id="countries-select"
                onChange={(e) =>
                  this.setState({
                    location_country: e.target.value,
                  })
                }>
                {countriesList.map((country) => (
                  <option value={country.value}>
                    {country.text}
                  </option>
                ))}
              </select>
              <label
                className="form-control-placeholder"
                htmlFor="input-country">
                Country
              </label>
            </FormGroup>

            <div
              className="gender-input"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              {getLabelByName(GENDER)}
              {":  "}
              {genders.map((gender) => (
                <div
                  key={gender.index}
                  className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="radio-checkbox"
                    id={`check-${gender.value}`}
                    value={gender.value}
                    onChange={this.handleRadio}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`check-${gender.value}`}>
                    {gender.text}
                  </label>
                </div>
              ))}
            </div>
            <br />

            <div className="form-group">
              <Checkbox
                id="gridCheck"
                onChange={this.updateAgree}>
                <small>
                  {getLabelByName(AGREE_TO)}{" "}
                  <Link
                    to="/terms"
                    style={{
                      color: colors.primaryColor,
                      textDecoration: "underline",
                    }}>
                    <span>
                      {getLabelByName(TERMS_CONDITIONS)}
                    </span>
                  </Link>{" "}
                  {getLabelByName(OF_WORD)}{" "}
                  {process.env.REACT_APP_APPLICATION_NAME}
                </small>
              </Checkbox>
            </div>

            <div className="mt-4">
              <button
                type="submit"
                className="btn btn-lg submit-btn"
                disabled={!hasAgreed}>
                {isLoading ? (
                  <>
                    <span>
                      {getLabelByName(SIGNINGP_UP)}
                      {"  "}
                    </span>
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status">
                      <span className="sr-only">
                        Loading...
                      </span>
                    </div>
                  </>
                ) : (
                  getLabelByName(SIGNUP)
                )}
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }
}

Signup.propTypes = {
  signupAction: PropTypes.func.isRequired,
  getUserClubs: PropTypes.func.isRequired,
  dataError: PropTypes.object,
  data: PropTypes.object,
  history: PropTypes.object.isRequired,
  status: PropTypes.string,
  location: PropTypes.any,
  landingData: PropTypes.object,
};

export const mapStateToProps = (state) => ({
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,

  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { signupAction, getUserClubs }),
)(Signup);
