/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import imagePlaceholder from '../../../assets/icons/image-placeholder.svg';
import { getLabelByName } from '../../../helpers/helperFunctions';
import {
  IMAGE_TYPE_ALLOWED,
  PROMO_IMAGE_DESCRIPTION,
  UPLOAD,
  UPLOADING,
} from '../../../helpers/constants';

class UploadCardSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      imageFile: null,
      imagePreview: null,
    };
  }

  render() {
    const { isUploading } = this.state;

    return (
      <div
        className="content-sections"
        style={{
          width: '100%',
          border: 'none',
          borderRadius: '5px',
        }}>
        <div
          id="logo"
          className="card section-card image-card mb-0"
          style={{
            width: '100%',
            border: 'none',
            borderRadius: '5px',
            boxShadow: '0px 3px 5px #00000029',
          }}>
          <div className="card-body">
            <div className="card-details">
              <h5 className="card-title">
                Image <span className="asterisk">*</span>
              </h5>
              <div className="upload-doc-input">
                <input
                  type="file"
                  className="custom-file-inputz"
                  id="customFile"
                  onChange={(e) => this.fileChange(e)}
                  accept="image/*"
                />
                <label
                  htmlFor="customFile"
                  className="helper-text">
                  {getLabelByName(PROMO_IMAGE_DESCRIPTION)}
                </label>
              </div>
              <br />
              <p className="helper-text font-italic">
                {getLabelByName(IMAGE_TYPE_ALLOWED)}
              </p>

              <button
                type="submit"
                className="btn btn-sm submit-btn"
                disabled={
                  this.state.imageFile === null ||
                  isUploading
                }
                onClick={() => this.submitImage()}>
                {!isUploading
                  ? getLabelByName(UPLOAD)
                  : `${getLabelByName(UPLOADING)}...`}
              </button>
            </div>
            <div className="card-image-preview">
              <img
                src={this.getImagePreview()}
                alt="card-preview"
                style={{
                  objectFit: 'cover',
                  position: 'unset',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  submitImage = async () => {
    this.setState({ isUploading: true });
    const formData = new FormData();
    formData.append('file', this.state.imageFile);
    formData.append('upload_preset', 'qtt2g8ao');
    const options = {
      method: 'POST',
      body: formData,
    };
    await fetch(
      'https://api.Cloudinary.com/v1_1/ayudigital/image/upload',
      options,
    )
      .then((res) => res.json())
      .then(async (res) => {
        const { secure_url } = res;
        this.props.setImageUrl(secure_url);
      })
      .catch((err) =>
        toast.error(
          'Error uploading the picture!\nPlease try again.',
        ),
      );

    this.setState({ isUploading: false });
  };

  fileChange = (e) => {
    this.setState({ imageFile: e.target.files[0] });
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.setState({
        imagePreview: fileReader.result,
      });
    };
    fileReader.readAsDataURL(e.target.files[0]);
  };

  getImagePreview = () =>
    this.state.imagePreview || imagePlaceholder;
}

UploadCardSection.propTypes = {
  setImageUrl: PropTypes.any,
};

export default UploadCardSection;
