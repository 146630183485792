import {
  CHANGE_PASSWORD_ERROR, CHANGE_PASSWORD_SUCCESS,
} from '../actions/types';

const initialState = {
  changeData: null,
  changeDataError: null,
  changeStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changeData: action.payload,
        changeDataError: null,
        changeStatus: 'success',
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changeDataError: action.payload,
        changeData: null,
        changeStatus: 'error',
      };
    default:
      return {
        ...state,
        changeStatus: null,
      };
  }
};
