import {
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_ERROR,
  GET_ACTIVITY_LOADING,
} from '../actions/types';

const initialState = {
  getActivityData: null,
  getActivityDataError: null,
  getActivityStatus: null,
  getActivityLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVITY_SUCCESS:
      return {
        ...state,
        getActivityData: action.payload,
        getActivityDataError: null,
        getActivityStatus: 'success',
        getActivityLoading: false,
      };
    case GET_ACTIVITY_ERROR:
      return {
        ...state,
        getActivityDataError: action.payload,
        getActivityData: null,
        getActivityStatus: 'error',
        getActivityLoading: false,
      };
    case GET_ACTIVITY_LOADING:
      return {
        ...state,
        getActivityDataError: null,
        getActivityData: null,
        getActivityStatus: 'loading',
        getActivityLoading: true,
      };
    default:
      return {
        ...state,
        getActivityStatus: null,
      };
  }
};
