import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  CANCEL,
  DATE,
  DESCRIPTION,
  EVENT_DETAILS,
  SUBTITLE,
  TITLE,
} from "../../helpers/constants";
import { getLabelByName } from "../../helpers/helperFunctions";

export class CalendarModal extends Component {
  state = {};

  render() {
    const { open, handleClose, event } = this.props;

    return (
      <Modal
        size="lg"
        className="output-modal"
        show={open}
        onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {getLabelByName(EVENT_DETAILS)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{`${getLabelByName(TITLE)}: ${
            event?.title
          }`}</div>
          <div>{`${getLabelByName(SUBTITLE)}: ${
            event?.subtitle
          }`}</div>
          <div>{`${getLabelByName(DATE)}: ${
            event?.start || "N/A"
          } - ${event?.end || "N/A"}`}</div>
          <div>{`${getLabelByName(DESCRIPTION)}: ${
            event?.description
          }`}</div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="link"
            style={{ color: "#F38F46", height: "2.5rem" }}
            onClick={() => handleClose()}>
            {getLabelByName(CANCEL)}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

CalendarModal.propTypes = {};

export default CalendarModal;
