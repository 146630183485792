import {
  CREATE_ACTION_ERROR,
  CREATE_ACTION_SUCCESS,
  CREATE_ACTIVITY_ERROR,
  CREATE_ACTIVITY_SUCCESS,
  GET_ACTION_ERROR,
  GET_ACTION_SUCCESS,
  GET_ACTIVITIES_ERROR,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITY_ERROR,
  GET_ACTIVITY_SUCCESS,
  GET_CAMPAIGNS_ERROR,
  GET_CAMPAIGNS_SUCCESS,
  GET_CLUB_CAMPAIGNS_ERROR,
  GET_CLUB_CAMPAIGNS_SUCCESS,
  GET_PARTICIPANTS_ERROR,
  GET_PARTICIPANTS_SUCCESS,
  GET_PARTICIPANT_ACTIONS_ERROR,
  GET_PARTICIPANT_ACTIONS_SUCCESS,
  GET_QUESTIONNAIRES_ERROR,
  GET_QUESTIONNAIRES_SUCCESS,
  GET_QUESTIONNAIRE_ERROR,
  GET_QUESTIONNAIRE_SUCCESS,
  PARTICIPATE_CAMPAIGN_ERROR,
  PARTICIPATE_CAMPAIGN_SUCCESS,
  POST_PARTICIPANT_ACTION_ERROR,
  POST_PARTICIPANT_ACTION_SUCCESS,
  SEARCH_CAMPAIGNS_DESCRIPTION_ERROR,
  SEARCH_CAMPAIGNS_DESCRIPTION_SUCCESS,
  SEARCH_CAMPAIGNS_NAME_ERROR,
  SEARCH_CAMPAIGNS_NAME_SUCCESS,
  UPDATE_ACTIVITY_ERROR,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_PARTICIPANT_ACTION_ERROR,
  UPDATE_PARTICIPANT_ACTION_SUCCESS,
  EDIT_ACTION_SUCCESS,
  EDIT_ACTION_ERROR,
  ADD_PARTICIPANT_SUCCESS,
  ADD_PARTICIPANT_ERROR,
  GET_PARTICIPANT_ALL_ACTIONS_SUCCESS,
  GET_PARTICIPANT_ALL_ACTIONS_ERROR,
  GET_ACTIVITY_LOADING,
  DELETE_PARTICIPANT_ACTION_SUCCESS,
  DELETE_PARTICIPANT_ACTION_ERROR,
  GET_PARTICIPANT_CONTRIBUTION_ERROR,
  GET_PARTICIPANT_CONTRIBUTION_LOADING,
  GET_PARTICIPANT_CONTRIBUTION_SUCCESS,
  GET_QUESTIONNAIRE_LOADING,
  GET_PARTICIPANT_ACTIONS_LOADING,
  GET_ADMIN_CLUB_ACTIVITIES_LOADING,
  GET_ADMIN_CLUB_ACTIVITIES_SUCCESS,
  GET_ADMIN_CLUB_ACTIVITIES_ERROR,
  DELETE_ACTION_SUCCESS,
  DELETE_ACTION_ERROR,
  CLONE_ACTIVITY_ERROR,
  CLONE_ACTIVITY_SUCCESS,
  EXPORT_ACTIVITY_SUCCESS,
  EXPORT_ACTIVITY_ERROR,
  CREATE_LESSON_SUCCESS,
  CREATE_LESSON_ERROR,
  GET_LESSONS_SUCCESS,
  GET_LESSONS_ERROR,
  GET_POST_LESSON_SUCCESS,
  GET_POST_LESSON_ERROR,
  UPDATE_LESSON_SUCCESS,
  UPDATE_LESSON_ERROR,
} from "./types";
import apiCall from "../../helpers/apiCall";
import {
  getGuestHeaders,
  getHeaders,
} from "../../helpers/getHeaders";

const campaignType = (type, payload) => ({
  type,
  payload,
});

export const getCampaigns =
  (headers = getHeaders(null, "/institutes/campaigns")) =>
  (dispatch) => {
    apiCall
      .get("/institutes/campaigns", { headers })
      .then((response) => {
        dispatch(
          campaignType(
            GET_CAMPAIGNS_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(campaignType(GET_CAMPAIGNS_ERROR, err));
      });
  };

export const getClubActivities =
  (
    id,
    headers = getHeaders(
      null,
      `/user/activities?q={"parentId":${id}}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/user/activities?q={"parentId":${id}}`, {
        headers,
      })
      .then((response) => {
        dispatch(
          campaignType(
            GET_CLUB_CAMPAIGNS_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          campaignType(GET_CLUB_CAMPAIGNS_ERROR, err),
        );
      });
  };

export const getAdminClubActivities =
  (
    id,
    headers = getHeaders(null, `/club/${id}/activities`),
  ) =>
  (dispatch) => {
    dispatch(
      campaignType(GET_ADMIN_CLUB_ACTIVITIES_LOADING, true),
    );
    apiCall
      .get(`/club/${id}/activities`, {
        headers,
      })
      .then((response) => {
        dispatch(
          campaignType(
            GET_ADMIN_CLUB_ACTIVITIES_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          campaignType(
            GET_ADMIN_CLUB_ACTIVITIES_ERROR,
            err,
          ),
        );
      });
  };

export const searchCampaignsName =
  (
    searchParams,
    headers = getHeaders(
      null,
      `/institutes/campaigns?q=${searchParams}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/institutes/campaigns?q=${searchParams}`, {
        headers,
      })
      .then((response) => {
        dispatch(
          campaignType(
            SEARCH_CAMPAIGNS_NAME_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          campaignType(SEARCH_CAMPAIGNS_NAME_ERROR, err),
        );
      });
  };

export const searchCampaignsDescription =
  (
    searchParams,
    headers = getHeaders(
      null,
      `/institutes/campaigns?q=${searchParams}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/institutes/campaigns?q=${searchParams}`, {
        headers,
      })
      .then((response) => {
        dispatch(
          campaignType(
            SEARCH_CAMPAIGNS_DESCRIPTION_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          campaignType(
            SEARCH_CAMPAIGNS_DESCRIPTION_ERROR,
            err,
          ),
        );
      });
  };

export const getActivities =
  (headers = getHeaders(null, "/user/activities")) =>
  (dispatch) => {
    apiCall
      .get("/user/activities", { headers })
      .then((response) => {
        localStorage.setItem(
          "activitiesData",
          JSON.stringify(response.data.data),
        );
        dispatch(
          campaignType(
            GET_ACTIVITIES_SUCCESS,
            response.data,
          ),
        );
        dispatch(
          campaignType(
            GET_ACTIVITIES_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(campaignType(GET_ACTIVITIES_ERROR, err));
      });
  };

export const getAdminActivities =
  (headers = getHeaders(null, "/admin/activities")) =>
  async (dispatch) => {
    try {
      const response = await apiCall.get(
        "/admin/activities",
        { headers },
      );

      localStorage.setItem(
        "activitiesData",
        JSON.stringify(response.data.data),
      );
      dispatch(
        campaignType(GET_ACTIVITIES_SUCCESS, response.data),
      );
      dispatch(
        campaignType(GET_ACTIVITIES_SUCCESS, response.data),
      );

      return response.data;
    } catch (err) {
      dispatch(campaignType(GET_ACTIVITIES_ERROR, err));
    }
  };

export const searchActivitiesByName =
  (
    searchParams,
    headers = getHeaders(
      null,
      `/user/activities?q=${searchParams}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/user/activities?q=${searchParams}`, {
        headers,
      })
      .then((response) => {
        dispatch(
          campaignType(
            GET_ACTIVITIES_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(campaignType(GET_ACTIVITIES_ERROR, err));
      });
  };

export const searchAdminActivitiesByName =
  (
    searchParams,
    headers = getHeaders(
      null,
      `/admin/activities?q=${searchParams}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/admin/activities?q=${searchParams}`, {
        headers,
      })
      .then((response) => {
        dispatch(
          campaignType(
            GET_ACTIVITIES_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(campaignType(GET_ACTIVITIES_ERROR, err));
      });
  };

export const getActivity =
  (id, headers = getHeaders(null, `/activity/${id}`)) =>
  (dispatch) => {
    dispatch(campaignType(GET_ACTIVITY_LOADING, true));
    apiCall
      .get(`/activity/${id}`, { headers })
      .then((response) => {
        dispatch(
          campaignType(GET_ACTIVITY_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(campaignType(GET_ACTIVITY_ERROR, err));
      });
  };

export const getParticipants =
  (
    id,
    query,
    headers = getHeaders(
      null,
      `/activity/${id}/participants${query || ""}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/activity/${id}/participants${query || ""}`, {
        headers,
      })
      .then((response) => {
        dispatch(
          campaignType(
            GET_PARTICIPANTS_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(campaignType(GET_PARTICIPANTS_ERROR, err));
      });
  };

export const createActivity =
  (payload, headers = getHeaders(payload, "/activity")) =>
  (dispatch) => {
    apiCall
      .post("/activity", payload, { headers })
      .then((response) => {
        dispatch(
          campaignType(
            CREATE_ACTIVITY_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(campaignType(CREATE_ACTIVITY_ERROR, err));
      });
  };

export const updateActivity =
  (
    id,
    payload,
    headers = getHeaders(payload, `/activity/${id}`),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.put(
        `/activity/${id}`,
        payload,
        { headers },
      );

      dispatch(
        campaignType(
          UPDATE_ACTIVITY_SUCCESS,
          response.data,
        ),
      );

      return response.data;
    } catch (err) {
      dispatch(campaignType(UPDATE_ACTIVITY_ERROR, err));
    }
  };

export const cloneActivityReq =
  (
    activityId,
    payload,
    headers = getHeaders(
      payload,
      `/clone/activity/${activityId}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .post(`/clone/activity/${activityId}`, payload, {
        headers,
      })
      .then((response) => {
        dispatch(
          campaignType(
            CLONE_ACTIVITY_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(campaignType(CLONE_ACTIVITY_ERROR, err));
      });
  };

export const getQuestionnaire =
  (
    clubId,
    questionnaireId,
    headers = getHeaders(
      null,
      `/club/${clubId}/questionnaire/${questionnaireId}`,
    ),
  ) =>
  (dispatch) => {
    dispatch(campaignType(GET_QUESTIONNAIRE_LOADING, true));
    apiCall
      .get(
        `/club/${clubId}/questionnaire/${questionnaireId}`,
        {
          headers,
        },
      )
      .then((response) => {
        dispatch(
          campaignType(
            GET_QUESTIONNAIRE_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          campaignType(GET_QUESTIONNAIRE_ERROR, err),
        );
      });
  };

export const participateCampaign =
  (
    id,
    payload,
    headers = payload.guestHeaders
      ? getGuestHeaders(payload.guestHeaders)
      : getHeaders(payload, `/activity/${id}/participate`),
  ) =>
  async (dispatch) => {
    try {
      // remove guestHeaders from payload if its present
      delete payload.guestHeaders;

      const response = await apiCall.post(
        `/activity/${id}/participate`,
        payload,
        {
          headers,
        },
      );

      dispatch(
        campaignType(
          PARTICIPATE_CAMPAIGN_SUCCESS,
          response.data,
        ),
      );

      return response.data;
    } catch (err) {
      dispatch(
        campaignType(PARTICIPATE_CAMPAIGN_ERROR, err),
      );
    }
  };

export const getAction =
  (
    activityId,
    actionId,
    isAnonymousActivity,
    headers = getHeaders(
      null,
      `/activity/${activityId}/action/${actionId}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/activity/${activityId}/action/${actionId}`, {
        headers: isAnonymousActivity
          ? getGuestHeaders({})
          : headers,
      })
      .then((response) => {
        dispatch(
          campaignType(GET_ACTION_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(campaignType(GET_ACTION_ERROR, err));
      });
  };

export const getActionReq =
  (
    activityId,
    actionId,
    headers = getHeaders(
      null,
      `/activity/${activityId}/action/${actionId}`,
    ),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.get(
        `/activity/${activityId}/action/${actionId}`,
        {
          headers,
        },
      );

      dispatch(
        campaignType(GET_ACTION_SUCCESS, response.data),
      );

      return response.data;
    } catch (err) {
      dispatch(campaignType(GET_ACTION_ERROR, err));
    }
  };

export const postParticipantAction =
  (
    activityId,
    participantId,
    actionId,
    payload,
    isAnonymousActivity,
    headers = getHeaders(
      payload,
      `/activity/${activityId}/participant/${participantId}/action/${actionId}`,
    ),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.post(
        `/activity/${activityId}/participant/${participantId}/action/${actionId}`,
        payload,
        {
          headers: isAnonymousActivity
            ? getGuestHeaders({})
            : headers,
        },
      );

      dispatch(
        campaignType(
          POST_PARTICIPANT_ACTION_SUCCESS,
          response.data,
        ),
      );

      return response.data;
    } catch (err) {
      dispatch(
        campaignType(POST_PARTICIPANT_ACTION_ERROR, err),
      );
    }
  };

export const getParticipantActions =
  (
    activityId,
    participantId,
    query,
    isAnonymousActivity,
    headers = getHeaders(
      null,
      `/activity/${activityId}/participant/${participantId}/actions${
        query || ""
      }`,
    ),
  ) =>
  async (dispatch) => {
    try {
      dispatch(
        campaignType(GET_PARTICIPANT_ACTIONS_LOADING, true),
      );
      const response = await apiCall.get(
        `/activity/${activityId}/participant/${participantId}/actions${
          query || ""
        }`,
        {
          headers: isAnonymousActivity
            ? getGuestHeaders({})
            : headers,
        },
      );

      dispatch(
        campaignType(
          GET_PARTICIPANT_ACTIONS_SUCCESS,
          response.data,
        ),
      );

      return response.data;
    } catch (err) {
      dispatch(
        campaignType(GET_PARTICIPANT_ACTIONS_ERROR, err),
      );
    }
  };

export const updateParticipantAction =
  (
    activityId,
    participantId,
    payload,
    participantActionId,
    isAnonymousActivity,
    headers = getHeaders(
      payload,
      `/activity/${activityId}/participant/${participantId}/action/${participantActionId}`,
    ),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.put(
        `/activity/${activityId}/participant/${participantId}/action/${participantActionId}`,
        payload,
        {
          headers: isAnonymousActivity
            ? getGuestHeaders({})
            : headers,
        },
      );
      dispatch(
        campaignType(
          UPDATE_PARTICIPANT_ACTION_SUCCESS,
          response.data,
        ),
      );

      return response.data;
    } catch (err) {
      dispatch(
        campaignType(UPDATE_PARTICIPANT_ACTION_ERROR, err),
      );
    }
  };

export const exportActivities =
  (
    payload,
    headers = getHeaders(
      payload,
      `/admin/activities/export`,
    ),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.post(
        `/admin/activities/export`,
        payload,
        {
          headers,
        },
      );

      dispatch(
        campaignType(
          EXPORT_ACTIVITY_SUCCESS,
          response.data,
        ),
      );

      return response.data;
    } catch (err) {
      dispatch(campaignType(EXPORT_ACTIVITY_ERROR, err));
    }
  };

export const getQuestionnaires =
  (
    query,
    headers = getHeaders(
      null,
      `/questionnaires${query || ""}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/questionnaires${query || ""}`, { headers })
      .then((response) => {
        dispatch(
          campaignType(
            GET_QUESTIONNAIRES_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          campaignType(GET_QUESTIONNAIRES_ERROR, err),
        );
      });
  };

export const getClubQuestionnaires =
  (
    clubId,
    query,
    headers = getHeaders(
      null,
      `/club/${clubId}/questionnaires${query || ""}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/club/${clubId}/questionnaires${query || ""}`, {
        headers,
      })
      .then((response) => {
        dispatch(
          campaignType(
            GET_QUESTIONNAIRES_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          campaignType(GET_QUESTIONNAIRES_ERROR, err),
        );
      });
  };

export const createAction =
  (
    activityId,
    payload,
    headers = getHeaders(
      payload,
      `/activity/${activityId}/action`,
    ),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.post(
        `/activity/${activityId}/action`,
        payload,
        {
          headers,
        },
      );

      dispatch(
        campaignType(CREATE_ACTION_SUCCESS, response.data),
      );

      return response.data;
    } catch (err) {
      dispatch(campaignType(CREATE_ACTION_ERROR, err));
    }
  };

export const updateAction =
  (
    activityId,
    actionId,
    payload,
    headers = getHeaders(
      payload,
      `/activity/${activityId}/action/${actionId}`,
    ),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.put(
        `/activity/${activityId}/action/${actionId}`,
        payload,
        { headers },
      );

      dispatch(
        campaignType(EDIT_ACTION_SUCCESS, response.data),
      );

      return response.data;
    } catch (err) {
      dispatch(campaignType(EDIT_ACTION_ERROR, err));
    }
  };

export const deleteAction =
  (
    activityId,
    actionId,
    headers = getHeaders(
      null,
      `/activity/${activityId}/action/${actionId}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .delete(
        `/activity/${activityId}/action/${actionId}`,
        { headers },
      )
      .then((response) => {
        dispatch(
          campaignType(
            DELETE_ACTION_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(campaignType(DELETE_ACTION_ERROR, err));
      });
  };

export const createLesson =
  (
    activityId,
    actionId,
    payload,
    headers = getHeaders(
      payload,
      `/activity/${activityId}/course/${actionId}/lesson`,
    ),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.post(
        `/activity/${activityId}/course/${actionId}/lesson`,
        payload,
        {
          headers,
        },
      );

      dispatch(
        campaignType(CREATE_LESSON_SUCCESS, response.data),
      );

      return response.data;
    } catch (err) {
      dispatch(campaignType(CREATE_LESSON_ERROR, err));
    }
  };

export const updateLesson =
  (
    activityId,
    actionId,
    lessonId,
    payload,
    headers = getHeaders(
      payload,
      `/activity/${activityId}/course/${actionId}/lesson/${lessonId}`,
    ),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.post(
        `/activity/${activityId}/course/${actionId}/lesson`,
        payload,
        {
          headers,
        },
      );

      dispatch(
        campaignType(UPDATE_LESSON_SUCCESS, response.data),
      );

      return response.data;
    } catch (err) {
      dispatch(campaignType(UPDATE_LESSON_ERROR, err));
    }
  };

export const getLessons =
  (
    activityId,
    actionId,
    headers = getHeaders(
      null,
      `/activity/${activityId}/course/${actionId}/lessons`,
    ),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.get(
        `/activity/${activityId}/course/${actionId}/lessons`,
        {
          headers,
        },
      );

      dispatch(
        campaignType(GET_LESSONS_SUCCESS, response.data),
      );

      return response.data;
    } catch (err) {
      dispatch(campaignType(GET_LESSONS_ERROR, err));
    }
  };

export const getPostLessons =
  (
    clubId,
    headers = getHeaders(null, `/club/${clubId}/lessons`),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.get(
        `/club/${clubId}/lessons`,
        {
          headers,
        },
      );

      dispatch(
        campaignType(
          GET_POST_LESSON_SUCCESS,
          response.data,
        ),
      );

      return response.data;
    } catch (err) {
      dispatch(campaignType(GET_POST_LESSON_ERROR, err));
    }
  };

export const deleteParticipant =
  (
    activityId,
    participantId,
    headers = getHeaders(
      null,
      `/activity/${activityId}/participant/${participantId}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .delete(
        `/activity/${activityId}/participant/${participantId}`,
        {
          headers,
        },
      )
      .then((response) => {
        dispatch(
          campaignType(
            DELETE_PARTICIPANT_ACTION_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          campaignType(
            DELETE_PARTICIPANT_ACTION_ERROR,
            err,
          ),
        );
      });
  };

export const updateParticipantRole =
  (
    activityId,
    participantId,
    payload,
    headers = getHeaders(
      payload,
      `/activity/${activityId}/participant/${participantId}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .put(
        `/activity/${activityId}/participant/${participantId}`,
        payload,
        {
          headers,
        },
      )
      .then((response) => {
        dispatch(
          campaignType(
            UPDATE_PARTICIPANT_ACTION_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          campaignType(
            UPDATE_PARTICIPANT_ACTION_ERROR,
            err,
          ),
        );
      });
  };

export const addMemberToActivityAction =
  (
    activityId,
    payload,
    headers = getHeaders(
      payload,
      `/activity/${activityId}/add/participants`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .post(
        `/activity/${activityId}/add/participants`,
        payload,
        { headers },
      )
      .then((response) => {
        dispatch(
          campaignType(
            ADD_PARTICIPANT_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(campaignType(ADD_PARTICIPANT_ERROR, err));
      });
  };

export const getParticipantAllActions =
  (
    activityId,
    participantId,
    isAnonymousActivity,
    query,
    headers = getHeaders(
      null,
      `/activity/${activityId}/participant/${participantId}/actions`,
    ),
  ) =>
  (dispatch) => {
    console.log("GET_PARTICIPANT_ALL_ACTION", {
      isAnonymousActivity,
    });
    apiCall
      .get(
        `/activity/${activityId}/participant/${participantId}/actions`,
        {
          headers: isAnonymousActivity
            ? getGuestHeaders({})
            : headers,
        },
      )
      .then((response) => {
        dispatch(
          campaignType(
            GET_PARTICIPANT_ALL_ACTIONS_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          campaignType(
            GET_PARTICIPANT_ALL_ACTIONS_ERROR,
            err,
          ),
        );
      });
  };

export const getParticipantContributionAction =
  (
    activityId,
    participantId,
    headers = getHeaders(
      null,
      `/activity/${activityId}/participant/${participantId}/contributions`,
    ),
  ) =>
  (dispatch) => {
    dispatch(
      campaignType(
        GET_PARTICIPANT_CONTRIBUTION_LOADING,
        true,
      ),
    );
    apiCall
      .get(
        `/activity/${activityId}/participant/${participantId}/contributions`,
        null,
        { headers },
      )
      .then((response) => {
        dispatch(
          campaignType(
            GET_PARTICIPANT_CONTRIBUTION_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          campaignType(
            GET_PARTICIPANT_CONTRIBUTION_ERROR,
            err,
          ),
        );
      });
  };
