/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import OutputItem from "./OutputItem";

const PromotionsTable = ({
  category,
  allData,
  activitiesData,
  institutesData,
  outputsData,
  newsFeedData,
  updateOutput,
}) => {
  const [promotedOutput, setPromotedOutput] = useState([]);

  const promotionData = {
    all: allData,
    outputs: outputsData,
    activities: activitiesData,
    clubs: institutesData,
    posts: newsFeedData,
  };

  useEffect(() => {
    if (allData) {
      const result = allData.filter(
        (output) => output.meta?.promoted,
      );
      setPromotedOutput(result);
    }

    if (outputsData) {
      const result = outputsData.filter(
        (output) => output.meta?.promoted,
      );
      setPromotedOutput(result);
    }

    if (institutesData) {
      const result = institutesData.filter(
        (output) => output.meta?.promoted,
      );
      setPromotedOutput(result);
    }

    if (activitiesData) {
      const result = activitiesData.filter(
        (output) => output.meta?.promoted,
      );
      setPromotedOutput(result);
    }

    if (newsFeedData) {
      const result = newsFeedData.filter(
        (output) => output.meta?.promoted,
      );
      setPromotedOutput(result);
    }
  }, [
    allData,
    outputsData,
    activitiesData,
    institutesData,
    newsFeedData,
  ]);

  return (
    <div className="promotions-table px-4 pt-5 pb-3">
      {promotionData[category]?.map((output) => (
        <OutputItem
          key={output?.id}
          category={category}
          output={output}
          updateOutput={updateOutput}
          numOfPromotedOutputs={promotedOutput.length}
        />
      ))}
    </div>
  );
};

export default PromotionsTable;
