/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StarBorderRounded } from '@material-ui/icons';
import { colors } from '../../../../helpers/constants';

class Stars extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="d-flex">
        {this.props.options.map((option) => (
          <div className="text-center mr-3">
            <StarBorderRounded style={{ color: colors.primaryColor, fontSize: '50px' }} />
            <div>{option}</div>
          </div>
        ))}
      </div>
    );
  }
}

Stars.propTypes = {
  options: PropTypes.any,
};

export default Stars;
