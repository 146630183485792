/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  ACTIVITIES,
  CLUBS,
  colors,
  CREATE,
  POSTS,
  PROMOTION,
  PROMOTIONS,
  QUESTIONNAIRES,
  USERS,
  WEB_CONTENT,
} from "../../helpers/constants";
import { getLabelByName } from "../../helpers/helperFunctions";
import PromotionModal from "../PromotionModal";
import postIcon from "../../assets/manage-posts-orange.svg";
import clubsIcon from "../../assets/manage-clubs-orange.svg";
import activitiesIcon from "../../assets/manage-activities-orange.svg";
import usersIcon from "../../assets/manage-members-orange.svg";
import webContentIcon from "../../assets/manage-site-orange.svg";
import questionnairesIcon from "../../assets/manage-questionnaires-orange.svg";
import promotionsIcon from "../../assets/icons/loud-orange.svg";

class CreateDropdown extends Component {
  state = {
    showPromotionModal: false,
    navItems: [
      {
        iconInactive: postIcon,
        title: getLabelByName(POSTS),
        link: "/manage/posts",
      },
      {
        iconInactive: clubsIcon,
        title: getLabelByName(CLUBS),
        link: "/manage/clubs",
      },
      {
        iconInactive: activitiesIcon,
        title: getLabelByName(ACTIVITIES),
        link: "/manage/activities",
      },
      {
        iconInactive: usersIcon,
        title: getLabelByName(USERS),
        link: "/manage/users",
      },
      {
        iconInactive: webContentIcon,
        title: getLabelByName(WEB_CONTENT),
        link: "/manage/web/logo",
      },
      {
        iconInactive: questionnairesIcon,
        title: getLabelByName(QUESTIONNAIRES),
        link: "/manage/questionnaires",
      },
      {
        iconInactive: promotionsIcon,
        title: getLabelByName(PROMOTIONS),
        link: "/manage/promotions/all",
      },
    ],
  };

  handleModalDisplay = () => {
    this.setState((prevState) => ({
      showPromotionModal: !prevState.showPromotionModal,
    }));
  };

  render() {
    const { showPromotionModal, navItems } = this.state;

    const { settingsDropdown, createDropdown } = this.props;
    return (
      <>
        {createDropdown && (
          <>
            <div
              className={`${this.props.className} dropdown-menu dropdown-menu-right create-dropdown`}
              aria-labelledby="dropdownCreate">
              <Link
                className="dropdown-item nav-link"
                to="/create-article">
                <div className="side-nav-item">
                  <span
                    style={{
                      color: colors.primaryColor,
                      paddingRight: "0.5rem",
                    }}>
                    @
                  </span>
                  {`${getLabelByName(CREATE)} article`}
                </div>
              </Link>

              <Link
                className="dropdown-item nav-link"
                to="/create-lesson">
                <div className="side-nav-item">
                  <span
                    style={{
                      color: colors.primaryColor,
                      paddingRight: "0.5rem",
                    }}>
                    @
                  </span>
                  {`${getLabelByName(CREATE)} Lesson`}
                </div>
              </Link>

              <Link
                to="#"
                className="dropdown-item nav-link"
                onClick={() =>
                  this.setState({
                    showPromotionModal: true,
                  })
                }>
                <div className="side-nav-item">
                  <span
                    style={{
                      color: colors.primaryColor,
                      paddingRight: "0.5rem",
                    }}>
                    @
                  </span>
                  {getLabelByName(PROMOTION)}
                </div>
              </Link>
            </div>
            <PromotionModal
              showPromotionModal={showPromotionModal}
              handleModalDisplay={this.handleModalDisplay}
            />
          </>
        )}
        {settingsDropdown && (
          <div
            className="dropdown-menu dropdown-menu-right create-dropdown"
            aria-labelledby="dropdownCreate">
            {navItems.map((navItem) => (
              <Link
                key={navItems.indexOf(navItem)}
                className="dropdown-item nav-link"
                to={navItem.link}
                title={navItem.title}>
                <div className="side-nav-item">
                  <span
                    style={{
                      color: colors.primaryColor,
                      paddingRight: "0.5rem",
                    }}>
                    <img
                      src={navItem.iconInactive}
                      alt="item-icon"
                      style={{ width: "20px" }}
                    />
                  </span>
                  {navItem.title}
                </div>
              </Link>
            ))}
          </div>
        )}
      </>
    );
  }
}

CreateDropdown.propTypes = {
  className: PropTypes.any,
};

export default CreateDropdown;
