/* eslint-disable react/no-danger */
import React from "react";
import {
  optionTypes,
  questionComponentMappping,
  optionsPreviewMappping,
} from "../components/questionsHelpers";

const PreviewedQuestionnaire = ({
  questionsList,
  questionnaireName,
  handleCustomMeta,
}) => {
  return (
    <>
      <p
        className="font-weight-bold mt-4"
        style={{ fontSize: "20px" }}>
        {questionnaireName}
      </p>

      {questionsList.map((question) => (
        <div className="d-flex align-items-center justify-content-center">
          <div
            className="institute-card article-card"
            style={{ minHeight: "unset" }}>
            <div className="create-card-header d-block">
              {question.required && (
                <span className="asterisk">*</span>
              )}

              <div
                dangerouslySetInnerHTML={{
                  __html: question.content,
                }}
              />

              {question.meta?.labelImage && (
                <div style={{ maxWidth: "400px" }}>
                  <img
                    style={{ width: "100%" }}
                    alt="label img"
                    src={question.meta?.labelImage}
                  />
                </div>
              )}
            </div>

            {question?.settings?.responses?.map(
              (response, index) => (
                <>
                  <div className="d-flex align-items-center justify-content-center">
                    <div
                      className="institute-card article-card"
                      style={{
                        minHeight: "unset",
                      }}>
                      <div className="create-card-header">
                        <h5 className="card-title font-weight-bold">
                          {response.label}{" "}
                          {response.required && (
                            <span className="asterisk">
                              *
                            </span>
                          )}
                        </h5>
                      </div>

                      {/* Field Preview */}
                      {!optionTypes.includes(
                        response.type,
                      ) &&
                        questionComponentMappping(
                          handleCustomMeta,
                          index,
                          response,
                          true,
                        )[response.type]}

                      {/* Options Preview */}
                      {optionTypes.includes(
                        response.type,
                      ) &&
                        optionsPreviewMappping(response)[
                          response.type
                        ]}
                    </div>
                  </div>
                </>
              ),
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default PreviewedQuestionnaire;
