/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import {
  VisibilityOutlined,
  VisibilityOffOutlined,
} from "@material-ui/icons";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import {
  CLOSE,
  SUBMIT,
  PREVIEW,
  PROCESSING,
  colors,
} from "../../../../helpers/constants";

const QuestionnaireFooter = ({
  isLoading,
  preveiewQuestionnaire,
  switchPreviewQuestionnaire,
}) => {
  return (
    <div className="publish-article-button d-flex justify-content-end mt-3">
      <div className="edit-button">
        {!preveiewQuestionnaire ? (
          <span
            style={{ color: colors.primaryColor }}
            onClick={() => switchPreviewQuestionnaire()}
            title={`${getLabelByName(PREVIEW)} article`}>
            <VisibilityOutlined />
          </span>
        ) : (
          <span
            style={{ color: colors.primaryColor }}
            onClick={() => switchPreviewQuestionnaire()}
            title={`${getLabelByName(
              CLOSE,
            )} ${getLabelByName(PREVIEW)}`}>
            <VisibilityOffOutlined />
          </span>
        )}
        <button
          type="submit"
          className="btn btn-lg submit-btn"
          disabled={isLoading}>
          {!isLoading
            ? getLabelByName(SUBMIT)
            : `${getLabelByName(PROCESSING)}...`}
        </button>
      </div>
    </div>
  );
};

export default QuestionnaireFooter;
