/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import PropTypes from "prop-types";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { connect } from "react-redux";
import {
  Link,
  Redirect,
  withRouter,
} from "react-router-dom";
import { compose } from "redux";
import { toast } from "react-toastify";
import Loading from "../../generics/Loading/Loading";
import {
  getInstitute,
  getInstituteMembers,
  joinClub,
} from "../../../redux/actions/institutesActions";
import { getOutputs } from "../../../redux/actions/contributionsActions";
import { getClubActivities } from "../../../redux/actions/campaignsActions";
import Navbar from "../../Navbar";
import {
  getAllClubsFromLocalStorage,
  getLabelByName,
  getOGMetaTagsClub,
  getProfileDataFromLocalStorage,
  isActionError,
  isActionSuccess,
  renderOGMetaTags,
  userIsLoggedIn,
} from "../../../helpers/helperFunctions";
import {
  ERROR_PROCESSING_REQUEST,
  JOIN,
  JOINING,
  SUCCESS_PROCESSING_REQUEST,
} from "../../../helpers/constants";
import { getSessionAction } from "../../../redux/actions/authActions";
import Loader from "../../Loader";
import "./club.css";
import ClubDetailsSection from "./ClubDetailsSection/ClubDetailsSection";
import ClubMembersSection from "./ClubMembersSection/ClubMembersSection";
import ClubActivitiesSection from "./ClubActivitiesSection/ClubActivitiesSection";
import OutputsRow from "../../OutputsRow";
import ViewClubFeedCard from "../ViewClubFeedCard";
import ClubOutputsSection from "./ClubOutputsSection/ClubOutputsSection";
import LoginModal from "../../generics/LoginModal";
import UserVerificationModal from "../../generics/UserVerificationModal"

export class Club extends Component {
  state = {
    clubData: null,
    profileData: getProfileDataFromLocalStorage(),
    clubsData: getAllClubsFromLocalStorage(),
    pageTitle: `${
      process.env.REACT_APP_APPLICATION_NAME ||
      "The Alma Maters Club"
    } - Club`,
    pathname: this.props.location.pathname,
    isJoining: false,
    openLoginModal: false,
    openUserVerificationModal: false,
  };

  async UNSAFE_componentWillMount() {
    await this.fetchData();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      profileData: getProfileDataFromLocalStorage(),
    });

    if (
      isActionSuccess(
        nextProps,
        "joinClubStatus",
        "joinClubData",
      )
    ) {
      toast.success(
        getLabelByName(SUCCESS_PROCESSING_REQUEST),
      );
      await this.props.getSessionAction();
      await this.fetchData();
      this.setState({ isJoining: false });
    }
    if (
      isActionError(
        nextProps,
        "joinClubStatus",
        "joinClubData",
      )
    ) {
      toast.error(getLabelByName(ERROR_PROCESSING_REQUEST));
      this.setState({ isJoining: false });
    }
    if (
      isActionSuccess(
        nextProps,
        "instituteStatus",
        "instituteData",
      )
    ) {
      this.setState({ clubData: nextProps.instituteData });
    }
  }

  fetchData = async () => {
    const { props } = this;
    const { id: clubId, handle } = props.match.params;
    await props.getInstitute(handle);
    await props.getOutputs(`?q={"clubId":${clubId}}`);
    await props.getClubActivities(clubId);
    await props.getInstituteMembers(clubId);
  };

  isMember = () => {
    const { profileData } = this.state;
    let isMember = false;

    if (
      profileData?.data &&
      this.props.instituteData?.data
    ) {
      isMember = Object.keys(
        profileData?.data?.clubs,
      ).includes(
        this.props.instituteData?.data.id.toString(),
      );
    }

    return isMember;
  };

  onJoinClub = async () => {
    const { profileData } = this.state;
    const userIsVerified = profileData?.data?.meta.hasOwnProperty('verifiedAt')
    if (!userIsVerified) {
      return this.setState({openUserVerificationModal: true})
    }
    if (!profileData?.data) {
      this.setState({ openLoginModal: true });
    } else {
      this.setState({ isJoining: true });
      const { props } = this;
      const { id: clubId } = props.instituteData.data;
      const { id: userId } = profileData.data;
      await props.joinClub(clubId, { userId });
    }
  };

  generateOGMetaTags = () => {
    let tags = {};
    if (
      this.dataHasLoaded(this.props.instituteData) &&
      this.dataHasLoaded(this.props.landingData)
    ) {
      tags = getOGMetaTagsClub(
        this.props.instituteData.data,
        this.props.landingData,
      );
    }
    return tags;
  };

  toggleLoginModal = () =>
    this.setState((prevState) => ({
      openLoginModal: !prevState.openLoginModal,
    }));

  dataHasLoaded = (state) => !!(state && state.data);

  render() {
    const { instituteLoading, instituteData } = this.props;
    const { isJoining, openLoginModal, profileData, openUserVerificationModal } =
      this.state;

    const isAuthenticated = userIsLoggedIn(profileData);
    const isUserMember = this.isMember();

    if (
      !isAuthenticated &&
      instituteData?.data?.visibility &&
      instituteData?.data?.visibility === "RESTRICTED"
    ) {
      return <Redirect push to="/404" />;
    }

    const isPublicClub =
      instituteData?.data?.visibility === "PUBLIC";

    const isPrivateClub =
      instituteData?.data?.visibility === "PRIVATE";

    const isRestrictedClub =
      instituteData?.data?.visibility === "RESTRICTED";

    if (!instituteData && instituteLoading) {
      return <Loading />;
    }

    return (
      <>
        {renderOGMetaTags(this.generateOGMetaTags())}
        <Navbar pathname="club" />
        <div className="viewSingleClub">
          <div className="title">
            <button
              type="button"
              style={{
                background: "none",
                border: "none",
                outline: "none",
                marginTop: "-8px",
                marginLeft: "-5px",
              }}>
              <Link
                to="/home"
                style={{
                  outline: "none",
                  color: "#424242",
                }}>
                <ArrowBackIosIcon />
              </Link>
            </button>
            <h1 style={{ paddingLeft: "10px" }}>
              {this.props.instituteData?.data?.name}
            </h1>
          </div>

          {isAuthenticated &&
            this.props.getOutputsData?.data &&
            this.props.instituteData?.data && (
              <div className="outputs-row-section">
                <OutputsRow
                  outputs={this.props.getOutputsData.data}>
                  <ViewClubFeedCard
                    club={this.props.instituteData?.data}
                  />
                </OutputsRow>
              </div>
            )}

          <div className="club-details-section">
            <div className="club">
              <ClubDetailsSection />
            </div>
          </div>

          <div className="join-button-section">
            {!isUserMember && (
              <button
                type="button"
                disabled={
                  isUserMember ||
                  isJoining ||
                  !this.props.instituteData
                }
                onClick={() => this.onJoinClub()}>
                {isJoining && (
                  <>
                    <span>
                      {this.props.instituteData?.data
                        ?.profile?.callToAction ||
                        getLabelByName(JOINING)}
                      {"  "}
                    </span>
                    <Loader />
                  </>
                )}
                {!isJoining && getLabelByName(JOIN)}
              </button>
            )}
          </div>

          {isAuthenticated &&
          (isPrivateClub || isRestrictedClub) &&
          isUserMember ? (
            <>
              <div className="club-activities-section">
                <ClubActivitiesSection />
              </div>
              <div className="club-activities-section">
                <ClubOutputsSection />
              </div>

              <div className="club-members-section">
                <ClubMembersSection />
              </div>
            </>
          ) : (
            isPublicClub && (
              <>
                <div className="club-activities-section">
                  <ClubActivitiesSection />
                </div>
                <div className="club-activities-section">
                  <ClubOutputsSection />
                </div>
              </>
            )
          )}
        </div>

        <LoginModal
          isModalOpen={openLoginModal}
          toggleModal={this.toggleLoginModal}
        />
        <UserVerificationModal
          isModalOpen={openUserVerificationModal}
          toggleModal={() =>
            this.setState((prevState) => ({
              openUserVerificationModal: !prevState.openUserVerificationModal,
            }))
          }
        />
      </>
    );
  }
}

Club.propTypes = {
  match: PropTypes.any,
  getSessionAction: PropTypes.func,
  getInstitute: PropTypes.func,
};

const mapStateToProps = (state) => ({
  instituteDataError:
    state.instituteReducer.instituteDataError,
  instituteData: state.instituteReducer.instituteData,
  instituteStatus: state.instituteReducer.instituteStatus,
  instituteLoading: state.instituteReducer.instituteLoading,
  joinClubData: state.joinClubReducer.joinClubData,
  joinClubDataError:
    state.joinClubReducer.joinClubDataError,
  joinClubStatus: state.joinClubReducer.joinClubStatus,

  getOutputsData: state.getOutputsReducer.getOutputsData,
  getOutputsDataError:
    state.getOutputsReducer.getOutputsDataError,
  getOutputsStatus:
    state.getOutputsReducer.getOutputsStatus,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,
});
const mapDispatchToProps = {
  getSessionAction,
  getInstitute,
  getOutputs,
  getClubActivities,
  getInstituteMembers,
  joinClub,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Club);
