import { CLUBS_CRUD, CLUBS_SEARCH } from "../../actions/types";

const initialState = {
    id: 0,
    name: "The Alma Maters Club",
    handle: "the-alma-maters-club",
    type: "Default",
    status: "ACTIVE",
    verified: true,
    visibility: "RESTRICTED",
    settings: {},
    dateCreated: 0,
    lastUpdated: 0,
    meta: {},
    profile: {},
    posts: 0,
    members: 0,
    campaigns: 0
}

const initialSearchState = [];

export const clubCRUDReducer = (state = initialState, action) => {
    if (action.type !== CLUBS_CRUD) {
        return state;
    }

    const apiResponse = action.payload;
    if (apiResponse.status === 200) {
        return apiResponse.data;
    } else {
        return {
            ...state,
            error: apiResponse.error.message
        };
    }
}

export const clubSearchReducer = (state = initialSearchState, action) => {
    if (action.type !== CLUBS_SEARCH) {
        return state;
    }

    const apiResponse = action.payload;
    if (apiResponse.status === 200) {
        return apiResponse.data;
    } else {
        return {
            ...state,
            error: apiResponse.error.message
        };
    }
}