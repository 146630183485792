/***
 * @description function to find if item is last in array using id
 * @param arr - array to compare item
 * @param id - id to compare with array
 * @returns boolean
 */
export const isLastItemInArray = (arr, id) => {
  const lastIndex = arr.length - 1;
  if (arr[lastIndex].id === id) {
    return true;
  }
  return false;
};

export function handleSubmitContribution() {}
