import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { ArrowBackIos as ArrowBackIosIcon } from "@material-ui/icons";
import ClubList from "./clubList";
import Navbar from "../../../Navbar";
import FileUpload from "./fileUpload";
import Clipboard from "../../../../assets/clipboard.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/search.svg";
import { getAdminClubs } from "../../../../redux/actions/institutesActions";
import { importUsers } from "../../../../redux/actions/usersActions";
import { handleFileUpload } from "../../../../helpers/functions";

import "./importUsers.scss";

const ImportUsers = ({
  importUsersReq,
  getAdminClubsReq,
}) => {
  const [files, setFiles] = useState([]);
  const [assignUsersToClub, setAssignUsersToClub] =
    useState(false);
  const [isUploadingFile, setIsUploadingFile] =
    useState(false);
  const [isFetchingClubs, setIsFetchingClubs] =
    useState(false);
  const [clubsData, setClubsData] = useState([]);
  const [selectedClubs, setSelectedClubs] = useState([]);

  const handleFileChange = (incomingFile) => {
    setFiles(incomingFile);
  };

  const handleAssignClub = async () => {
    try {
      setAssignUsersToClub(true);
      setIsFetchingClubs(true);

      const response = await getAdminClubsReq();

      setClubsData(response.data);

      setIsFetchingClubs(false);
    } catch (err) {
      console.log(err);
      setIsFetchingClubs(false);
    }
  };

  const handleSelectClub = (clubId) => {
    setSelectedClubs([...selectedClubs, clubId]);
  };

  const handleUnSelectClub = (clubId) => {
    const result = selectedClubs.filter(
      (id) => id !== clubId,
    );

    setSelectedClubs(result);
  };

  const handleRemoveAll = () => setSelectedClubs([]);

  const handleUserImportCompletion = async () => {
    try {
      setIsUploadingFile(true);

      const { secure_url } = await handleFileUpload(
        files[0],
      );

      const payload = {
        data: secure_url,
        options: {
          passwords_gen: true,
          send_mails: false,
        },
        clubs: selectedClubs,
      };

      const response = await importUsersReq(payload);

      setIsUploadingFile(false);

      if (response.status === 200) {
        toast.success("User Import Successful!");
      } else {
        response.error(
          response?.error?.message || "An error occured!",
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Navbar pathname="manage" />

      <section className="import-users-page pb-5">
        <nav className="nav-links py-4 border-bottom">
          <div className="d-flex align-items-center justify-content-between">
            <button
              type="button"
              style={{
                background: "none",
                border: "none",
                outline: "none",
                marginTop: "-8px",
                marginLeft: "-5px",
              }}>
              <Link
                to="/manage/users"
                style={{
                  outline: "none",
                  color: "#424242",
                }}>
                <ArrowBackIosIcon /> Back
              </Link>
            </button>
          </div>
        </nav>

        <div className="d-flex justify-content-center py-5 import-header">
          <div className="max-width-wrapper text-center">
            <h2 className="title">Users import from CSV</h2>
            <p className="description">
              This section completes the user import from
              CSV to your organization account.
            </p>
          </div>
        </div>

        <div className="d-flex justify-content-center import-body">
          <div className="max-width-wrapper">
            <div className="import-members">
              <p className="mb-4 import-header">
                Import Members
              </p>

              <FileUpload
                files={files}
                handleFileChange={handleFileChange}
              />
            </div>

            <div className="assign-users-club">
              <div className="header mb-5">
                <p className="title">
                  Assign Users to clubs
                </p>
                <span className="desc">
                  You can assign users to club later. Do
                  this now or skip
                </span>

                <div className="my-4 d-flex justify-content-start align-items-center">
                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className={
                        clubsData.length ||
                        files.length === 0
                          ? "assign-btn d-flex align-items-center"
                          : "assign-btn active d-flex align-items-center"
                      }
                      onClick={handleAssignClub}
                      disabled={files.length === 0}>
                      {isFetchingClubs ? (
                        <Spinner
                          animation="border"
                          size="sm"
                          variant="light"
                        />
                      ) : (
                        "Assign Club"
                      )}
                    </button>

                    {!assignUsersToClub && (
                      <button
                        type="button"
                        onClick={handleUserImportCompletion}
                        className={`ml-3 import-btn d-flex justify-content-center align-items-center ${
                          files.length === 0
                            ? "disabled"
                            : ""
                        }`}
                        disabled={files.length === 0}>
                        {isUploadingFile ? (
                          <Spinner
                            className="my-2"
                            animation="border"
                            size="sm"
                            variant="light"
                          />
                        ) : (
                          "Complete User Import"
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {assignUsersToClub &&
                !isFetchingClubs &&
                (clubsData.length === 0 ? (
                  <div className="no-selected-club text-center">
                    <div className="mb-4">
                      <img
                        src={Clipboard}
                        alt="Clipboard"
                      />
                    </div>

                    <p className="msg">
                      No club selected. Select a user to see
                      available clubs.
                    </p>
                  </div>
                ) : (
                  <div className="club-list-wrapper">
                    <div className="px-3">
                      <p className="header mb-0">Clubs</p>
                      <span className="desc d-block mb-3">
                        Select one or more clubs to add a
                        user to
                      </span>

                      <form className="club-search-form mb-3">
                        <input placeholder="Search clubs" />

                        <button type="button">
                          <SearchIcon />
                        </button>
                      </form>
                    </div>

                    <div className="club-section">
                      <ClubList
                        clubsData={clubsData}
                        selectedClubs={selectedClubs}
                        handleSelectClub={handleSelectClub}
                        handleUnSelectClub={
                          handleUnSelectClub
                        }
                      />

                      <div className="py-3 action-btn d-flex justify-content-end">
                        <div className="d-flex align-items-center">
                          <button
                            type="button"
                            onClick={handleRemoveAll}
                            className="remove"
                            disabled={
                              selectedClubs.length === 0
                            }>
                            Remove all
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              {assignUsersToClub && (
                <div className="mt-4">
                  <button
                    type="button"
                    onClick={handleUserImportCompletion}
                    className={`px-3 import-btn d-flex justify-content-center align-items-center ${
                      files.length === 0 ? "disabled" : ""
                    }`}
                    disabled={files.length === 0}>
                    {isUploadingFile ? (
                      <Spinner
                        className="my-2"
                        animation="border"
                        size="sm"
                        variant="light"
                      />
                    ) : (
                      "Complete User Import"
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapDispatchToProps = {
  getAdminClubsReq: getAdminClubs,
  importUsersReq: importUsers,
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(ImportUsers);
