/* eslint-disable global-require */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { Clear } from "@material-ui/icons";

import {
  getUserClubs,
  searchClubsByName,
} from "../../redux/actions/institutesActions";
import {
  getActivities,
  searchActivitiesByName,
} from "../../redux/actions/campaignsActions";

import { getSessionAction } from "../../redux/actions/authActions";
import Footer from "../Footer";
import { Carousel } from "./Carousel";
import Navbar from "../Navbar";
import Fallback from "../Fallback";
import clubIcon from "../../assets/manage-clubs.svg";
import campaignIcon from "../../assets/manage-campaigns.svg";
import searchOrange from "../../assets/icons/search-orange.svg";
import { getLabelByName } from "../../helpers/helperFunctions";
import {
  CAMPAIGN,
  SEARCH_RESULTS,
  FOR_WORD,
  SEARCH,
} from "../../helpers/constants";

export class Search extends Component {
  state = {
    searchInput: "",
    isLoading: false,
    displaySearch: false,
  };

  async UNSAFE_componentWillMount() {
    const { props } = this;

    await props.getSessionAction();

    const { searchTerm } = props.match.params;
    this.setState({ isLoading: true });
    if (searchTerm) {
      this.searchCommunities();
      this.setState({ displaySearch: true });
    } else {
      await this.fetchData();
      this.setState({ displaySearch: false });
    }
    this.setState({ isLoading: false });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  fetchData = async () => {
    const { props } = this;
    await props.getUserClubs();
    await props.getActivities();
  };

  submitSearch = async (e) => {
    e.preventDefault();
    if (this.state.searchInput) {
      await this.props.history.push(
        `/search/${this.state.searchInput}`,
      );
      this.searchCommunities();
      this.setState({ displaySearch: true });
    }
  };

  clearSearch = async () => {
    await this.props.history.push("/explore");
    this.fetchData();
    this.setState({ displaySearch: false });
  };

  searchCommunities = async () => {
    const { props } = this;
    const { searchTerm } = props.match.params;
    const searchParams = (property) =>
      JSON.stringify({
        [property]: {
          _like: `%${searchTerm}%`,
        },
      });
    this.setState({ isLoading: true });
    await props.searchClubsByName(searchParams("name"));
    await props.searchActivitiesByName(
      searchParams("name"),
    );
    this.setState({ isLoading: false });
  };

  render() {
    const { REACT_APP_APPLICATION_NAME } = process.env;
    document.title = `${
      REACT_APP_APPLICATION_NAME || "The Alma Maters"
    } - Search`;
    const { searchTerm } = this.props.match.params;
    const { isLoading, searchInput, displaySearch } =
      this.state;
    const {
      getUserClubsData,
      getUserClubsStatus,
      getActivitiesData,
      searchClubsNameData,
      searchCampaignsNameData,
      campaignsStatus,
      searchClubsNameStatus,
      searchCampaignsNameStatus,
    } = this.props;

    if (
      getUserClubsStatus &&
      campaignsStatus &&
      (!getUserClubsData || !getActivitiesData)
    ) {
      return <Fallback route="/explore" />;
    }

    if (
      getUserClubsStatus &&
      campaignsStatus &&
      getUserClubsData &&
      getActivitiesData &&
      (getUserClubsData.status === 500 ||
        getActivitiesData.status === 500)
    ) {
      return <Fallback route="/explore" />;
    }

    if (
      searchClubsNameStatus &&
      searchCampaignsNameStatus &&
      (!searchClubsNameData || !searchCampaignsNameData)
    ) {
      return <Fallback route="/explore" />;
    }

    if (
      searchClubsNameStatus &&
      searchCampaignsNameStatus &&
      searchClubsNameData &&
      searchCampaignsNameData &&
      (searchClubsNameData.status === 500 ||
        searchCampaignsNameData.status === 500)
    ) {
      return <Fallback route="/explore" />;
    }

    return (
      <>
        <Navbar pathname="search" />
        <div className="search-page">
          <div className="container">
            <div id="search-component" className="row mt-5">
              <form
                className="form"
                onSubmit={this.submitSearch}>
                <div className="input-group mb-2 col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    id="inputSearch"
                    placeholder={`${getLabelByName(
                      SEARCH,
                    )} ${REACT_APP_APPLICATION_NAME}`}
                    name="searchInput"
                    value={searchInput}
                    onChange={this.handleChange}
                  />
                  {searchTerm && (
                    <div className="input-group-append">
                      <button
                        id="clear-search-btn"
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={() => this.clearSearch()}>
                        <Clear />
                      </button>
                    </div>
                  )}
                  <div className="input-group-append">
                    <button
                      id="search-btn"
                      className="btn btn-outline-secondary"
                      type="submit">
                      <img
                        src={searchOrange}
                        alt="search-icon"
                        style={{ width: "20px" }}
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="row-results mt-3">
              {searchTerm && (
                <>
                  <div className="result-term">
                    {`${getLabelByName(
                      SEARCH_RESULTS,
                    )} ${getLabelByName(FOR_WORD)}:`}{" "}
                    <span className="font-weight-bold">
                      {`"${searchTerm}"`}
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className="mt-3">
              <Carousel
                title={`${getLabelByName(CAMPAIGN)}`}
                carouselData={getActivitiesData}
                type="campaign"
                isLoading={isLoading}
                defaultIcon={campaignIcon}
              />
            </div>
            <div className="mt-3">
              <Carousel
                title="Clubs"
                carouselData={
                  displaySearch
                    ? searchClubsNameData
                    : getUserClubsData
                }
                type="club"
                isLoading={isLoading}
                defaultIcon={clubIcon}
              />
            </div>
          </div>
          <div className="footer-block">
            <div className="footer-component">
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  }
}

Search.propTypes = {
  getUserClubs: PropTypes.func,
  getActivities: PropTypes.func,
  searchClubsByName: PropTypes.func,
  searchActivitiesByName: PropTypes.func,
  getSessionAction: PropTypes.func,
  getUserClubsData: PropTypes.any,
  getActivitiesData: PropTypes.any,
  searchClubsNameData: PropTypes.any,
  searchCampaignsNameData: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  location: PropTypes.any,
  getUserClubsStatus: PropTypes.any,
  campaignsStatus: PropTypes.any,
  searchClubsNameStatus: PropTypes.any,
  searchCampaignsNameStatus: PropTypes.any,
  dataError: PropTypes.object,
  data: PropTypes.object,
  status: PropTypes.string,
};

export const mapStateToProps = (state) => ({
  getUserClubsData:
    state.getUserClubsReducer.getUserClubsData,
  getUserClubsDataError:
    state.getUserClubsReducer.getUserClubsDataError,
  getUserClubsStatus:
    state.getUserClubsReducer.getUserClubsStatus,

  getActivitiesDataError:
    state.getActivitiesReducer.getActivitiesDataError,
  getActivitiesData:
    state.getActivitiesReducer.getActivitiesData,
  getActivitiesStatus:
    state.getActivitiesReducer.getActivitiesStatus,
  searchClubsNameDataError:
    state.searchClubsNameReducer.searchClubsNameDataError,
  searchClubsNameData:
    state.searchClubsNameReducer.searchClubsNameData,
  searchClubsNameStatus:
    state.searchClubsNameReducer.searchClubsNameStatus,
  searchClubsDescriptionDataError:
    state.searchClubsDescriptionReducer
      .searchClubsDescriptionDataError,
  searchClubsDescriptionData:
    state.searchClubsDescriptionReducer
      .searchClubsDescriptionData,
  searchClubsDescriptionStatus:
    state.searchClubsDescriptionReducer
      .searchClubsDescriptionStatus,
  searchCampaignsNameDataError:
    state.searchCampaignsNameReducer
      .searchCampaignsNameDataError,
  searchCampaignsNameData:
    state.searchCampaignsNameReducer
      .searchCampaignsNameData,
  searchCampaignsNameStatus:
    state.searchCampaignsNameReducer
      .searchCampaignsNameStatus,
  searchCampaignsDescriptionDataError:
    state.searchCampaignsDescriptionReducer
      .searchCampaignsDescriptionDataError,
  searchCampaignsDescriptionData:
    state.searchCampaignsDescriptionReducer
      .searchCampaignsDescriptionData,
  searchCampaignsDescriptionStatus:
    state.searchCampaignsDescriptionReducer
      .searchCampaignsDescriptionStatus,
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getUserClubs,
    getActivities,
    searchClubsByName,
    searchActivitiesByName,
    getSessionAction,
  }),
)(Search);
