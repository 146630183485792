/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Calendar,
  momentLocalizer,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import CalendarToolbar from "./CalendarToolbar";
import CalendarModal from "./CalendarModal";
import { getFormattedContributionSetting } from "../../helpers/helperFunctions";

const localizer = momentLocalizer(moment);

class OutputCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      event: null,
    };
  }

  render() {
    const { openModal, event } = this.state;

    return (
      <>
        <Calendar
          popup
          localizer={localizer}
          events={this.getFormattedContributions()}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 800 }}
          views={["month"]}
          components={{
            toolbar: CalendarToolbar,
          }}
          onSelectEvent={this.handleEventSelected}
        />

        <CalendarModal
          open={openModal}
          handleClose={this.handleClose}
          event={event}
        />
      </>
    );
  }

  getFormattedContributions = () => {
    const { contributions, output } = this.props;

    const formattedContributions = contributions.map(
      (contribution) => {
        const dates =
          output.sources.listings.settings.event_dates &&
          JSON.parse(
            getFormattedContributionSetting(
              output.sources.listings.settings.event_dates,
              contribution
            )
          );
        const startDate = dates?.startDate;
        const endDate = dates?.endDate;

        return {
          id: contribution.id,
          title: getFormattedContributionSetting(
            output.sources.listings.settings.event_title,
            contribution,
          ),
          subtitle: getFormattedContributionSetting(
            output.sources.listings.settings.event_name,
            contribution,
          ),
          description: getFormattedContributionSetting(
            output.sources.listings.settings.event_description,
            contribution,
          ),
          start: startDate,
          end: endDate,
          allDay: true,
          contribution,
        };
      },
    );

    return formattedContributions;
  };

  handleEventSelected = (event) => {
    this.setState({
      openModal: true,
      event,
    });
  };

  handleClose = () => this.setState({ openModal: false });
}

OutputCalendar.propTypes = {
  contributions: PropTypes.any,
  settings: PropTypes.any,
};

export default OutputCalendar;
