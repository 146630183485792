import React from "react";
import {
  PAYMENT_DESCRIPTION,
  AMOUNT,
  CURRENCY,
  SELECT_FREQUENCY,
  NUMBER_OF_PAYMENTS,
} from "../../../../helpers/constants";
import { getLabelByName } from "../../../../helpers/helperFunctions";

const PaymentFields = ({
  paymentType,
  editAction,
  amount,
  numOfPayments,
  paymentFrequency,
  paymentDescription,
  currencies,
  handleChange,
  paymentFrequencies,
}) => {
  return (
    <div className="mt-5">
      <h5 className="mb-4">Configure Options</h5>

      {/* ================== PAYMENT_DESCRIPTION ==================  */}
      <div className="row d-flex justify-content-center mb-3">
        <div className="form-icon col-md-12">
          <div className="form-group form-label-group">
            <input
              type="text"
              name="paymentDescription"
              className="form-control"
              id="paymentDescription-input-name"
              placeholder={getLabelByName(
                PAYMENT_DESCRIPTION,
              )}
              value={paymentDescription}
              onChange={handleChange}
              style={{
                backgroundColor: "#FFFFFF",
              }}
              required
            />

            <label htmlFor="paymentDescription-input-name">
              {getLabelByName(PAYMENT_DESCRIPTION)}
            </label>
          </div>
        </div>
      </div>
      {/* ================== PAYMENT_DESCRIPTION ==================  */}

      <div className="row d-flex mb-5">
        {/* ================== CURRENCY ==================  */}
        <div className="col-md-6">
          <div className="form-icon select-club-dropdown">
            <div className="select-club-header">
              {`${getLabelByName(CURRENCY)}`}
            </div>

            <div className="select-club-input">
              <select
                className="form-control"
                id="club-select"
                name="currency"
                required
                defaultValue={
                  editAction?.profile?.currency || "1"
                }
                onChange={handleChange}>
                {currencies.map((currency) => (
                  <option
                    value={currency}
                    selected={
                      editAction?.profile?.currency ===
                      currency
                    }>
                    {currency}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {/* ================== CURRENCY ==================  */}

        {/* ================== AMOUNT ==================  */}
        <div className="form-icon col-md-6">
          <div className="form-group form-label-group">
            <input
              type="text"
              name="amount"
              className="form-control"
              id="amount-input"
              placeholder={getLabelByName(AMOUNT)}
              value={amount}
              onChange={handleChange}
              style={{
                backgroundColor: "#FFFFFF",
              }}
              required
            />
            <label htmlFor="amount-input">
              {getLabelByName(AMOUNT)}
            </label>
          </div>
        </div>
        {/* ================== AMOUNT ==================  */}

        {/* ================== FREQUENCY ==================  */}
        {paymentType === "SUBSCRIPTION" && (
          <div className="col-md-6">
            <div className="form-icon select-club-dropdown">
              <div className="select-club-header">
                {`${getLabelByName(SELECT_FREQUENCY)}`}
              </div>

              <div className="select-club-input">
                <select
                  className="form-control"
                  id="club-select"
                  defaultValue={
                    editAction?.profile?.paymentFrequency
                  }
                  required
                  name="paymentFrequency"
                  onChange={handleChange}>
                  {paymentFrequencies.map((frequency) => (
                    <option
                      value={frequency}
                      selected={
                        editAction?.profile
                          ?.paymentFrequency ===
                        paymentFrequency
                      }>
                      {frequency}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
        {/* ================== FREQUENCY ==================  */}

        {/* ================== NUMBER OF PAYMENTS ==================  */}
        {paymentType === "SUBSCRIPTION" && (
          <div className="col-md-6">
            <div className="form-group form-label-group">
              <input
                type="text"
                name="numOfPayments"
                className="form-control"
                id="numOfPayments-input"
                placeholder={getLabelByName(
                  NUMBER_OF_PAYMENTS,
                )}
                value={numOfPayments}
                onChange={handleChange}
                style={{
                  backgroundColor: "#FFFFFF",
                }}
                required
              />
              <label htmlFor="numOfPayments-input">
                {getLabelByName(NUMBER_OF_PAYMENTS)}
              </label>
            </div>
          </div>
        )}
        {/* ================== NUMBER OF PAYMENTS ==================  */}
      </div>
    </div>
  );
};

export default PaymentFields;
