/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
import { ArrowBackIos, Clear } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import Navbar from "../../../Navbar";
import Footer from "../../../Footer";
import { getSessionAction } from "../../../../redux/actions/authActions";
import {
  checkSession,
  getLabelByName,
  getProfileDataFromLocalStorage,
  isActionSuccess,
} from "../../../../helpers/helperFunctions";
import {
  colors,
  OUTPUTS,
  PROMOTIONS,
  SEARCH,
} from "../../../../helpers/constants";
import { getInstitute } from "../../../../redux/actions/institutesActions";
import { getOutputs } from "../../../../redux/actions/contributionsActions";
import searchOrange from "../../../../assets/icons/search-orange.svg";
import { getClubActivities } from "../../../../redux/actions/campaignsActions";
import Promotions from "../../../generics/Promotions/Promotions";
import PaginationRow from "../../../generics/PaginationRow";

class ClubPromotions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileData: getProfileDataFromLocalStorage(),
      pageTitle: getLabelByName(OUTPUTS),
      nbPages: 1,
      currentPage: 1,
      pageDivider: 10,
      searchValue: "",
      dropDownValue: "all",
      pathname: this.props.location.pathname,
      filteredData: [],
    };
  }

  async UNSAFE_componentWillMount() {
    const { props, state } = this;
    const { profileData, pathname } = state;
    const { id: clubId, handle } = props.match.params;
    if (!profileData || profileData.status !== 200) {
      window.location.href = `/login?redirect_to=${pathname}`;
    }
    await this.props.getSessionAction();
    await props.getInstitute(handle);
    await props.getOutputs(`?q={"clubId":${clubId}}`);
    await props.getClubActivities(clubId);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { pageDivider, pathname } = this.state;
    const { getOutputsData, data, status } = nextProps;

    checkSession(data, status, pathname, this.props);

    if (getOutputsData && getOutputsData.data) {
      this.setState({
        data: getOutputsData.data || [],
        filteredData: getOutputsData.data || [],
        nbPages: Math.ceil(
          getOutputsData.data.length / pageDivider,
        ),
      });
    }

    if (
      isActionSuccess(
        nextProps,
        "instituteStatus",
        "instituteData",
      )
    ) {
      this.setState({
        clubName: nextProps.instituteData.data?.name,
      });
    }
  }

  feedDataHasLoaded() {
    return !!(
      this.props.getOutputsData &&
      this.props.getOutputsData.data
    );
  }

  timestampToDate(
    timestamp,
    format = "DD MMM YYYY, hh:mm",
  ) {
    if (!timestamp) {
      return null;
    }
    return moment(timestamp * 1000).format(format);
  }

  getPageRange() {
    const { currentPage, pageDivider } = this.state;
    const first = (currentPage - 1) * pageDivider + 1;
    let last = first + (pageDivider - 1);
    if (
      this.feedDataHasLoaded() &&
      last > this.props.getOutputsData.data.length
    ) {
      last = this.props.getOutputsData.data.length;
    }
    return { first, last };
  }

  getLastListItem() {
    return 10;
  }

  getTotalEntitites() {
    if (!this.feedDataHasLoaded()) {
      return 0;
    }
    return this.props.getOutputsData.data.length;
  }

  incrementPage = () => {
    let { nbPages, currentPage } = this.state;
    currentPage =
      currentPage === nbPages
        ? currentPage
        : currentPage + 1;
    this.setState({ currentPage });
  };

  decrementPage = () => {
    let { currentPage } = this.state;
    currentPage =
      currentPage === 1 ? currentPage : currentPage - 1;
    this.setState({ currentPage });
  };

  setCurrentPage = (currentPage) =>
    this.setState({ currentPage });

  clearSearch = () =>
    this.setState({
      filteredData: this.state.data,
      searchValue: "",
    });

  searchComponent = () => (
    <form className="form" onSubmit={this.searchEntities}>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          id="inputSearch"
          placeholder={`${getLabelByName(
            SEARCH,
          )} ${getLabelByName(PROMOTIONS).toLowerCase()}`}
          name="searchValue"
          value={this.state.searchValue}
          onChange={this.handleChange}
          style={{ height: "32px" }}
        />
        {this.state.searchValue && (
          <div
            className="input-group-append"
            style={{ height: "32px" }}>
            <button
              id="clear-search-btn"
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => this.clearSearch()}>
              <Clear style={{ fontSize: "16px" }} />
            </button>
          </div>
        )}
        <div
          className="input-group-append"
          style={{ height: "32px" }}>
          <button
            id="search-btn"
            className="btn btn-outline-secondary"
            type="submit"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img
              src={searchOrange}
              alt="search-icon"
              style={{ width: "12px" }}
            />
          </button>
        </div>
      </div>
    </form>
  );

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  searchEntities = (e) => {
    e.preventDefault();
    const { data } = this.state;

    if (!this.state.searchValue) {
      return this.setState({
        filteredData: this.state.data,
      });
    }

    const regex = new RegExp(
      this.state.searchValue.toLowerCase(),
      "g",
    );
    const filteredData = data.filter((item) =>
      item.name.toLowerCase().match(regex),
    );
    this.setState({ filteredData });
  };

  handleDropdownChange = (e) => {
    const value = e.target.value;

    this.setState({ dropDownValue: value });
    const outputsData = [...this.state.filteredData];

    if (value === "promoted") {
      const promotedOutputs = outputsData.filter(
        (output) => output.meta.promoted,
      );

      this.setState({ filteredData: promotedOutputs });
    } else {
      this.setState({ filteredData: this.state.data });
    }
  };

  render() {
    const {
      filteredData,
      currentPage,
      pageDivider,
      nbPages,
    } = this.state;

    document.title = this.state.pageTitle;
    document.title = `${
      process.env.REACT_APP_APPLICATION_NAME ||
      "The Alma Maters Club"
    } - ${this.state.pageTitle}`;

    const { id, handle } = this.props.match.params;

    return (
      <>
        <Navbar pathname="profile" />

        <div className="manage-clubs-page">
          <div className="container-fluid">
            <div className="buttons-row mt-5 mb-5">
              <div id="back-link">
                <div className="d-flex justify-content-center align-items-center">
                  <Link
                    to={`/manage/clubs/${id}/${handle}`}
                    style={{
                      color: colors.textPrimary,
                      font: "Book 20px/17px Avenir",
                    }}>
                    <ArrowBackIos className="arrow-back-icon" />
                  </Link>
                  <h1
                    className="mb-0"
                    style={{
                      paddingLeft: "10px",
                      fontWeight: "600",
                      color: "#424242",
                      fontSize: "28px",
                    }}>
                    {getLabelByName(PROMOTIONS)}
                  </h1>
                </div>
              </div>
            </div>

            <Promotions
              getOutputsData={{
                data: filteredData.slice(
                  (currentPage - 1) * pageDivider,
                  currentPage * pageDivider,
                ),
              }}
              searchOutput={this.searchComponent()}
              dropDownValue={this.state.dropDownValue}
              handleDropdownChange={
                this.handleDropdownChange
              }
            />

            {filteredData && (
              <PaginationRow
                className="mt-4"
                incrementPage={this.incrementPage}
                decrementPage={this.decrementPage}
                nbPages={nbPages}
                currentPage={currentPage}
                dataLength={filteredData.length}
                pageDivider={pageDivider}
                setCurrentPage={this.setCurrentPage}
              />
            )}
          </div>
        </div>

        <div className="footer-block">
          <div className="footer-component">
            <Footer />
          </div>
        </div>
      </>
    );
  }
}

ClubPromotions.propTypes = {
  getOutputs: PropTypes.func,
  getSessionAction: PropTypes.func,
  getInstitute: PropTypes.func,
  getClubActivities: PropTypes.func,
  data: PropTypes.any,
  status: PropTypes.any,
  match: PropTypes.any,
  location: PropTypes.any,
  getOutputsData: PropTypes.any,
  instituteData: PropTypes.any,
};

const mapStateToProps = (state) => ({
  getOutputsDataError:
    state.getOutputsReducer.getOutputsDataError,
  getOutputsData: state.getOutputsReducer.getOutputsData,
  getOutputsStatus:
    state.getOutputsReducer.getOutputsStatus,

  instituteDataError:
    state.instituteReducer.instituteDataError,
  instituteData: state.instituteReducer.instituteData,
  instituteStatus: state.instituteReducer.instituteStatus,

  clubCampaignsDataError:
    state.clubCampaignsReducer.clubCampaignsDataError,
  clubCampaignsData:
    state.clubCampaignsReducer.clubCampaignsData,
  clubCampaignsStatus:
    state.clubCampaignsReducer.clubCampaignsStatus,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
  updateOutputStatus:
    state.updateOutputReducer.updateOutputStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getSessionAction,
    getOutputs,
    getInstitute,
    getClubActivities,
  }),
)(ClubPromotions);
