import "./outputSources.scss";
import React, { Component } from "react";
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@material-ui/core";
import {
  AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@material-ui/icons";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  ACTION,
  ACTIVITY,
  ADD,
  ANOTHER,
  CHOOSE_OUTPUT_SOURCE_DESCRIPTION,
  GLOBAL,
  NAME,
  NONE,
  outputParams,
  SETTINGS,
  SOURCE,
  sourceTypes,
} from "../../../../../../helpers/constants";
import { getLabelByName } from "../../../../../../helpers/helperFunctions";
import { getQuestionnaire } from "../../../../../../redux/actions/campaignsActions";

export class OutputSources extends Component {
  state = {};

  UNSAFE_componentWillMount() {
    const {
      addGlobalSetting,
      payload,
      editOutput,
      setInitialGlobalSettings,
      sources,
      initializeSources,
    } = this.props;
    if (editOutput) {
      const general_settings = {};

      outputParams[payload.type].general_settings.forEach(
        (param) => {
          general_settings[param.key] =
            editOutput?.settings?.[param.key];
        },
      );

      setInitialGlobalSettings(
        payload.type,
        general_settings,
      );
    }

    if (!editOutput && sources.length === 0) {
      initializeSources([payload.type]);
    }

    // eslint-disable-next-line no-unused-expressions
    outputParams?.[payload.type]?.general_settings.forEach(
      (setting) => {
        addGlobalSetting(setting.key);
      },
    );
  }

  handleActionChange = (e, i) => {
    const { handleChange } = this.props;

    const { sources } = this.props;

    const sourceToUpdate = sources[i];

    sourceToUpdate.actionId = e.target.value;

    const newSources = [...sources].filter(
      (source, sourceIndex) => sourceIndex !== i,
    );

    newSources.splice(i, 0, sourceToUpdate);

    return handleChange(e);
  };

  handleActivityChange = (e, i) => {
    const { handleChange } = this.props;

    const { sources } = this.props;

    const sourceToUpdate = sources[i];

    sourceToUpdate.activityId = e.target.value;

    const newSources = [...sources].filter(
      (source, sourceIndex) => sourceIndex !== i,
    );

    newSources.splice(i, 0, sourceToUpdate);

    return handleChange(e);
  };

  handleSourceNameChange = (e, i) => {
    const { handleChange, sources } = this.props;

    const { sourceObject } = this.state;

    const sourceToUpdate = sources[i];

    sourceToUpdate.name = e.target.value;

    const newSources = [...sources].filter(
      (source, sourceIndex) => sourceIndex !== i,
    );

    newSources.splice(i, 0, sourceToUpdate);

    return handleChange(e);
  };

  selectedActivityActions = (activityId) => {
    if (!activityId) return [];

    const { clubActivitiesData } = this.props;

    const selectedActivity = clubActivitiesData?.data?.filter(
      (clubActivity) => clubActivity.id === activityId,
    );

    return selectedActivity[0].actions;
  };

  handleSourceUpdate = (prop, value, index) => {
    const { updateSource, clubActivitiesData } = this.props;

    updateSource(prop, value, index);

    let sourceActivity = null;

    if (prop === "activityId" || prop === "actionId") {
      sourceActivity = clubActivitiesData?.data.find(
        (activityData) =>
          parseInt(activityData.id, 10) ===
          parseInt(value, 10),
      );

      const sourceAction = sourceActivity?.actions.find(
        (action) => {
          return action.id === value;
        },
      );

      this.setState({ sourceAction });
    }
  };

  disabledSource = (selectedSource) => {
    const { payload } = this.props;

    return payload?.sources?.find(
      (source) => source.name === selectedSource,
    );
  };

  render() {
    const {
      clubActivitiesData,
      sources,
      addAnotherSourceHandler,
      removeItemHandler,
      handleGlobalSettingsOnChange,
      globalSettings,
      payload,
    } = this.props;

    return (
      <div className="output-sources-section">
        <div className="output-global-settings">
          <div className="enable-search">
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {`${getLabelByName(
                  GLOBAL,
                )} ${getLabelByName(SETTINGS)}`}
              </FormLabel>
              <FormGroup>
                {outputParams?.[
                  payload.type
                ]?.general_settings.map((setting) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          globalSettings?.[payload.type]?.[
                            setting.key
                          ] || false
                        }
                        onChange={
                          handleGlobalSettingsOnChange
                        }
                        name={setting.key}
                        color="primary"
                      />
                    }
                    label={setting.name}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </div>
        <div className="output-sources-description">
          <p>
            {getLabelByName(
              CHOOSE_OUTPUT_SOURCE_DESCRIPTION,
            )}
          </p>
        </div>
        {sources.map((source, index) => (
          <div
            className="output-sources-details"
            key={index}>
            <Grid container spacing={3}>
              <Grid item sm xs>
                <FormControl
                  variant="outlined"
                  className="create-output-visibility">
                  <InputLabel id="demo-simple-select-outlined-label">
                    {getLabelByName(NAME)}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={(e) =>
                      this.handleSourceUpdate(
                        "name",
                        e.target.value,
                        index,
                      )
                    }
                    name="name"
                    value={source.name || ""}
                    label="Name">
                    {Object.values(sourceTypes).map(
                      (type, typeIndex) => (
                        <MenuItem
                          selected={type}
                          disabled={this.disabledSource(
                            type.toLowerCase(),
                          )}
                          key={typeIndex}
                          value={type.toLowerCase()}>
                          {type}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm xs>
                <FormControl
                  variant="outlined"
                  className="create-output-visibility">
                  <InputLabel id="demo-simple-select-outlined-label">
                    {getLabelByName(ACTIVITY)}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={(e) =>
                      this.handleSourceUpdate(
                        "activityId",
                        e.target.value,
                        index,
                      )
                    }
                    name="activityId"
                    value={source.activityId || ""}
                    label="Activity">
                    {clubActivitiesData?.data?.length > 0 &&
                      clubActivitiesData?.data?.map(
                        (activity, index) => (
                          <MenuItem
                            selected={activity.name}
                            key={index}
                            value={activity.id}>
                            {activity.name}
                          </MenuItem>
                        ),
                      )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm xs className="action-cancel">
                <FormControl
                  variant="outlined"
                  className="create-output-visibility">
                  <InputLabel id="demo-simple-select-outlined-label">
                    {getLabelByName(ACTION)}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={(e) =>
                      this.handleSourceUpdate(
                        "actionId",
                        e.target.value,
                        index,
                      )
                    }
                    value={source.actionId || ""}
                    name="Action"
                    label="Action">
                    {(this.selectedActivityActions(
                      source.activityId,
                    ).length > 0 &&
                      this.selectedActivityActions(
                        source.activityId,
                      ).map((action, index) => (
                        <MenuItem
                          selected={action.name}
                          key={index}
                          value={action.id}>
                          {action.name}
                        </MenuItem>
                      ))) || (
                      <MenuItem value="">
                        <em>{getLabelByName(NONE)}</em>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
                <DeleteOutlineIcon
                  className="cancel-button"
                  onClick={() => removeItemHandler(index)}
                />
              </Grid>
            </Grid>
          </div>
        ))}
        <div className="add-another-source">
          <AddCircleOutlineOutlinedIcon />
          <button
            type="button"
            onClick={addAnotherSourceHandler}>
            {`${getLabelByName(ADD)} ${getLabelByName(
              ANOTHER,
            ).toLowerCase()} ${getLabelByName(
              SOURCE,
            ).toLowerCase()}`}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getQuestionnaireData:
    state.getQuestionnaireReducer.getQuestionnaireData,
});

const mapDispatchToProps = {
  getQuestionnaire,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OutputSources);
