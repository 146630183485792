/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { defaults } = this.props;

    return (
      <div
        className="form-icon select-dropdown"
        style={{
          backgroundColor: '#FFFFFF',
          paddingLeft: 0,
          border: 'none',
        }}
      >
        <div style={{ marginLeft: '1rem' }}>
          <div className="select-input">
            <select
              className="form-control"
              id="input-campaignType"
              style={{ minHeight: '56px', marginTop: 'unset' }}
            >
              {this.props.options.map((option) => (
                <option className="mb-2" selected={defaults && defaults[0] === option}>{option}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  options: PropTypes.any,
  defaults: PropTypes.any,
};

export default Dropdown;
