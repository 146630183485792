import { GET_PARTICIPANT_ALL_ACTIONS_SUCCESS, GET_PARTICIPANT_ALL_ACTIONS_ERROR } from '../actions/types';

const initialState = {
  getParticipantAllActionsData: null,
  getParticipantAllActionsDataError: null,
  getParticipantAllActionsStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTICIPANT_ALL_ACTIONS_SUCCESS:
      return {
        ...state,
        getParticipantAllActionsData: action.payload,
        getParticipantAllActionsDataError: null,
        getParticipantAllActionsStatus: 'success',
      };
    case GET_PARTICIPANT_ALL_ACTIONS_ERROR:
      return {
        ...state,
        getParticipantAllActionsDataError: action.payload,
        getParticipantAllActionsData: null,
        getParticipantAllActionsStatus: 'error',
      };
    default:
      return {
        ...state,
        getParticipantAllActionsStatus: null,
      };
  }
};
