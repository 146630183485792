import "./contentCard.scss";
import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Email,
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
} from "@material-ui/icons";
import logo from "../../../../assets/logo-opac.svg";
import {
  CHANGE_LOGO,
  EMAIL,
  IMAGE,
  linkedPages,
  LOGIN,
  OTHER,
  SIGNUP,
  UPLOAD,
  UPLOADING,
  UPLOAD_IMAGE_TOOLTIP,
  webContentSection,
} from "../../../../helpers/constants";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import { colors } from "@material-ui/core";

export class ContentCard extends Component {
  state = {};

  render() {
    const {
      header,
      title,
      subtitle,
      uploadImage,
      landingInfo,
      fileChange,
      contentSection,
      contentSection2,
      contentSection3,
      contentSection4,
      contentSection5,
      oneInputField,
      oneInputFieldTextArea,
      handleChange,
      value,
      value2,
      twoInputField,
      title2,
      checkboxes,
      titleParagraph,
      FieldAndDropdown,
      title2Exp,
      emailValue,
      instagramValue,
      facebookValue,
      twitterValue,
      linkedInValue,
      socialMedia,
      displayEmailValue,
      displayInstagramValue,
      displayLinkedInValue,
      displayFacebookValue,
      displayTwitterValue,
      displayHomeValue,
      displaySubscriptionValue,
      displayPrivacyPolicyValue,
      displaySupportValue,
      displayTermsConditionsValue,
      changeImageButtonText,
      displayLandingAboutPages,
      displayServicesValue,
      displayAboutValue,
      submitImage,
      stateValues,
    } = this.props;

    const getImagePreview = (prop, defaultImage) => {
      const { imagePreview } = this.props;

      if (imagePreview[prop]) {
        return imagePreview[prop];
      }
      if (
        landingInfo &&
        landingInfo.data &&
        landingInfo.data[prop]
      ) {
        return landingInfo.data[prop];
      }

      return defaultImage;
    };

    return (
      <div className="content-card">
        <div className="content-card-header">
          <h1>{header}</h1>
        </div>
        <div className="inside-content">
          {(uploadImage && (
            <>
              <div className="upload-image-inside-content">
                <div className="content-details">
                  <div className="content-card-title">
                    <p>{title}</p>
                  </div>
                  <div className="content-card-subtitle">
                    <p>{subtitle}</p>
                  </div>
                  <div className="change-logo">
                    <label htmlFor="customFile">
                      <span
                        style={{
                          color: colors.textPrimary,
                        }}>
                        {changeImageButtonText}
                      </span>
                    </label>
                    <input
                      type="file"
                      id="customFile  "
                      className="custom-file-inputz"
                      onChange={(e) =>
                        fileChange(e, contentSection)
                      }
                    />
                    <div className="save-image">
                      <button
                        type="button"
                        title={
                          stateValues?.enableUploadButton ===
                          false
                            ? getLabelByName(
                                UPLOAD_IMAGE_TOOLTIP,
                              )
                            : undefined
                        }
                        disabled={
                          stateValues?.enableUploadButton ===
                          false
                        }
                        onClick={(e) =>
                          submitImage(
                            e,
                            landingInfo,
                            contentSection,
                          )
                        }>
                        {stateValues?.saveLoading === true
                          ? `${getLabelByName(
                              UPLOADING,
                            )}...`
                          : `${getLabelByName(
                              UPLOAD,
                            )} ${getLabelByName(IMAGE)}`}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="image-preview">
                  <img
                    src={getImagePreview(
                      contentSection,
                      logo,
                    )}
                    alt=""
                  />
                </div>
              </div>
            </>
          )) ||
            (oneInputField && (
              <div className="one-field-content">
                <div className="one-field-content-title">
                  <h1>{title}</h1>
                </div>
                <div className="one-field-content-input">
                  <input
                    type="text"
                    name={contentSection}
                    placeholder="Write something"
                    onChange={(e) => handleChange(e)}
                    value={value}
                  />
                </div>
              </div>
            )) ||
            (oneInputFieldTextArea && (
              <div className="one-field-content">
                <div className="one-field-content-title">
                  <h1>{title}</h1>
                </div>
                <div className="one-field-content-input">
                  <textarea
                    type="text"
                    name={contentSection}
                    placeholder="Write something"
                    onChange={(e) => handleChange(e)}
                    value={value}
                  />
                </div>
              </div>
            )) ||
            (twoInputField && (
              <>
                <div className="one-field-content">
                  <div className="one-field-content-title">
                    <h1>{title}</h1>
                  </div>
                  <div className="one-field-content-input">
                    <input
                      type="text"
                      name={contentSection}
                      placeholder="Write something"
                      onChange={(e) => handleChange(e)}
                      value={value}
                    />
                  </div>
                </div>
                <div className="one-field-content">
                  <div className="one-field-content-title">
                    <h1>{title2}</h1>
                  </div>
                  <div className="one-field-content-input">
                    <textarea
                      name={contentSection2}
                      placeholder="Write something"
                      onChange={(e) => handleChange(e)}
                      value={value2}
                    />
                  </div>
                </div>
              </>
            )) ||
            (checkboxes && (
              <>
                <div className="one-field-content">
                  <div className="one-field-content-title">
                    <p>{titleParagraph}</p>
                  </div>
                  {(!displayLandingAboutPages && (
                    <div className="checkboxes-fields-section">
                      <div className="row-1">
                        <div className="overview">
                          <div className="row-title">
                            <h1>Overview</h1>
                          </div>
                          <div className="checkboxes">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    sx={{
                                      color: "#A3A3A3",
                                      "&.Mui-checked": {
                                        color: "#F38F46",
                                      },
                                    }}
                                    onChange={(e) =>
                                      handleChange(e)
                                    }
                                    checked={
                                      displayHomeValue
                                        ? true
                                        : displayHomeValue ===
                                          "1"
                                    }
                                    name={
                                      webContentSection.displayHome
                                    }
                                  />
                                }
                                label={linkedPages.home}
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    sx={{
                                      color: "#A3A3A3",
                                      "&.Mui-checked": {
                                        color: "#F38F46",
                                      },
                                    }}
                                    onChange={(e) =>
                                      handleChange(e)
                                    }
                                    checked={
                                      displayPrivacyPolicyValue
                                        ? true
                                        : displayPrivacyPolicyValue ===
                                          "1"
                                    }
                                    name={
                                      webContentSection.displayPrivacyPolicy
                                    }
                                  />
                                }
                                label={
                                  linkedPages.privacyPolicy
                                }
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    sx={{
                                      color: "#A3A3A3",
                                      "&.Mui-checked": {
                                        color: "#F38F46",
                                      },
                                    }}
                                    onChange={(e) =>
                                      handleChange(e)
                                    }
                                    checked={
                                      displaySupportValue
                                        ? true
                                        : displaySupportValue ===
                                          "1"
                                    }
                                    name={
                                      webContentSection.displaySupport
                                    }
                                  />
                                }
                                label={linkedPages.support}
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    sx={{
                                      color: "#A3A3A3",
                                      "&.Mui-checked": {
                                        color: "#F38F46",
                                      },
                                    }}
                                    onChange={(e) =>
                                      handleChange(e)
                                    }
                                    checked={
                                      displayTermsConditionsValue
                                        ? true
                                        : displayTermsConditionsValue ===
                                          "1"
                                    }
                                    name={
                                      webContentSection.displayTermsConditions
                                    }
                                  />
                                }
                                label={
                                  linkedPages.TermsConditions
                                }
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className="row-2">
                        <div className="row-title">
                          <h1>Social media</h1>
                        </div>
                        <div className="checkboxes">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{
                                    color: "#A3A3A3",
                                    "&.Mui-checked": {
                                      color: "#F38F46",
                                    },
                                  }}
                                  onChange={(e) =>
                                    handleChange(e)
                                  }
                                  checked={
                                    displayInstagramValue
                                      ? true
                                      : displayInstagramValue ===
                                        "1"
                                  }
                                  name={
                                    webContentSection.displayInstagram
                                  }
                                />
                              }
                              label={linkedPages.instagram}
                            />
                          </FormGroup>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{
                                    color: "#A3A3A3",
                                    "&.Mui-checked": {
                                      color: "#F38F46",
                                    },
                                  }}
                                  onChange={(e) =>
                                    handleChange(e)
                                  }
                                  checked={
                                    displayLinkedInValue
                                      ? true
                                      : displayLinkedInValue ===
                                        "1"
                                  }
                                  name={
                                    webContentSection.displayLinkedIn
                                  }
                                />
                              }
                              label={linkedPages.LinkedIn}
                            />
                          </FormGroup>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{
                                    color: "#A3A3A3",
                                    "&.Mui-checked": {
                                      color: "#F38F46",
                                    },
                                  }}
                                  onChange={(e) =>
                                    handleChange(e)
                                  }
                                  checked={
                                    displayFacebookValue
                                      ? true
                                      : displayFacebookValue ===
                                        "1"
                                  }
                                  name={
                                    webContentSection.displayFacebook
                                  }
                                />
                              }
                              label={linkedPages.facebook}
                            />
                          </FormGroup>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{
                                    color: "#A3A3A3",
                                    "&.Mui-checked": {
                                      color: "#F38F46",
                                    },
                                  }}
                                  onChange={(e) =>
                                    handleChange(e)
                                  }
                                  checked={
                                    displayTwitterValue
                                      ? true
                                      : displayTwitterValue ===
                                        "1"
                                  }
                                  name={
                                    webContentSection.displayTwitter
                                  }
                                />
                              }
                              label={linkedPages.twitter}
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row-3">
                        <div className="row-title">
                          <h1>{getLabelByName(OTHER)}</h1>
                        </div>
                        <div className="checkboxes">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{
                                    color: "#A3A3A3",
                                    "&.Mui-checked": {
                                      color: "#F38F46",
                                    },
                                  }}
                                  onChange={(e) =>
                                    handleChange(e)
                                  }
                                  checked={
                                    displayEmailValue
                                      ? true
                                      : displayEmailValue ===
                                        "1"
                                  }
                                  name={
                                    webContentSection.displayEmail
                                  }
                                />
                              }
                              label={linkedPages.email}
                            />
                          </FormGroup>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{
                                    color: "#A3A3A3",
                                    "&.Mui-checked": {
                                      color: "#F38F46",
                                    },
                                  }}
                                  onChange={(e) =>
                                    handleChange(e)
                                  }
                                  checked={
                                    displaySubscriptionValue
                                      ? true
                                      : displaySubscriptionValue ===
                                        "1"
                                  }
                                  name={
                                    webContentSection.displaySubscription
                                  }
                                />
                              }
                              label={
                                linkedPages.subscription
                              }
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  )) || (
                    <div className="checkboxes-fields-section">
                      <div className="row-1">
                        <div className="overview">
                          <div className="checkboxes">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    sx={{
                                      color: "#A3A3A3",
                                      "&.Mui-checked": {
                                        color: "#F38F46",
                                      },
                                    }}
                                    onChange={(e) =>
                                      handleChange(e)
                                    }
                                    checked={
                                      displayAboutValue
                                        ? true
                                        : displayAboutValue ===
                                          "1"
                                    }
                                    name={
                                      webContentSection.displayAbout
                                    }
                                  />
                                }
                                label={
                                  linkedPages.aboutSection
                                }
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    sx={{
                                      color: "#A3A3A3",
                                      "&.Mui-checked": {
                                        color: "#F38F46",
                                      },
                                    }}
                                    onChange={(e) =>
                                      handleChange(e)
                                    }
                                    checked={
                                      displayServicesValue
                                        ? true
                                        : displayServicesValue ===
                                          "1"
                                    }
                                    name={
                                      webContentSection.displayServices
                                    }
                                  />
                                }
                                label={
                                  linkedPages.servicesSection
                                }
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )) ||
            (FieldAndDropdown && (
              <>
                <div className="one-field-content">
                  <div className="one-field-content-title">
                    <h1>{title}</h1>
                  </div>
                  <div className="one-field-content-input">
                    <input
                      type="text"
                      name={contentSection}
                      placeholder="Write something"
                      onChange={(e) => handleChange(e)}
                      value={value}
                    />
                  </div>
                </div>
                <div className="one-field-content">
                  <div className="one-field-content-title">
                    <h1>
                      {title2} <span>({title2Exp})</span>{" "}
                    </h1>
                  </div>
                  <div className="one-field-content-input">
                    <FormControl
                      sx={{
                        border: "1px solid #CCCCCC",
                        borderRadius: "5px",
                        m: 1,
                        width: "395px",
                        outline: "none",
                        margin: "0",
                      }}>
                      <Select
                        value={value2 || "/signup"}
                        onChange={handleChange}
                        name={contentSection2}
                        displayEmpty>
                        <MenuItem value="/signup">
                          {getLabelByName(SIGNUP)}
                        </MenuItem>
                        <MenuItem value="/login">
                          {getLabelByName(LOGIN)}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </>
            )) ||
            (socialMedia && (
              <div className="card-body">
                <div className="card-details">
                  <p className="card-title">{title}</p>
                  <div className="social-form-group">
                    <div className="social-input-group">
                      <div className="social-input-group-prepend">
                        <span
                          className="input-group-text"
                          id="basic-addon1">
                          <span className="pr-3">
                            <Email
                              style={{ color: "#CCCCCC" }}
                            />
                          </span>
                          {getLabelByName(EMAIL)}
                        </span>
                      </div>
                      <input
                        type="email"
                        name={contentSection}
                        className="form-control"
                        id="input-social-email"
                        placeholder="Enter your email here..."
                        value={emailValue}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="social-input-group">
                      <div className="social-input-group-prepend">
                        <span
                          className="input-group-text"
                          id="basic-addon1">
                          <span className="pr-3">
                            <Instagram
                              style={{ color: "#CCCCCC" }}
                            />
                          </span>
                          Instagram
                        </span>
                      </div>
                      <input
                        type="text"
                        name={contentSection2}
                        className="form-control"
                        id="input-social-instagram"
                        placeholder="Link to your instagram profile..."
                        value={instagramValue}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="social-input-group">
                      <div className="social-input-group-prepend">
                        <span
                          className="input-group-text"
                          id="basic-addon1">
                          <span className="pr-3">
                            <LinkedIn
                              style={{ color: "#CCCCCC" }}
                            />
                          </span>
                          LinkedIn
                        </span>
                      </div>
                      <input
                        type="text"
                        name={contentSection3}
                        className="form-control"
                        id="input-social-linkedin"
                        placeholder="Link to your LinkedIn profile..."
                        value={linkedInValue}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="social-input-group">
                      <div className="social-input-group-prepend">
                        <span
                          className="input-group-text"
                          id="basic-addon1">
                          <span className="pr-3">
                            <Facebook
                              style={{ color: "#CCCCCC" }}
                            />
                          </span>
                          Facebook
                        </span>
                      </div>
                      <input
                        type="text"
                        name={contentSection4}
                        className="form-control"
                        id="input-social-facebook"
                        placeholder="Link to your Facebook profile..."
                        value={facebookValue}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="social-input-group">
                      <div className="social-input-group-prepend">
                        <span
                          className="input-group-text"
                          id="basic-addon1">
                          <span className="pr-3">
                            <Twitter
                              style={{ color: "#CCCCCC" }}
                            />
                          </span>
                          Twitter
                        </span>
                      </div>
                      <input
                        type="text"
                        name={contentSection5}
                        className="form-control"
                        id="input-social-twitter"
                        placeholder="Link to your twitter profile..."
                        value={twitterValue}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(ContentCard);
