/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

const roles = ['Member', 'Admin'];

const RoleDialog = (props) => {
  const {
 onClose, selectedValue, open,
} = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
      const payload = {
          ...selectedValue,
          role: value,
      };
      onClose(payload);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="role-dialog" open={open}>
      <DialogTitle id="role-dialog">Update user role</DialogTitle>
      <List>
        {roles.map((role) => (
          <ListItem selected={selectedValue?.role.toLowerCase() === role.toLowerCase()} button onClick={() => handleListItemClick(role)} key={role}>
            <ListItemText primary={role} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

RoleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default RoleDialog;
