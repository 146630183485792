import {
  POST_CONTRIBUTIONS_COMMENTS_ERROR,
  POST_CONTRIBUTIONS_COMMENTS_SUCCESS,
} from "../actions/types";

const initialState = {
  postContributionsCommentData: null,
  postContributionsCommentDataError: null,
  postContributionsCommentStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_CONTRIBUTIONS_COMMENTS_SUCCESS:
      return {
        ...state,
        postContributionsCommentData: action.payload,
        postContributionsCommentDataError: null,
        postContributionsCommentStatus: "success",
      };
    case POST_CONTRIBUTIONS_COMMENTS_ERROR:
      return {
        ...state,
        postContributionsCommentDataError: action.payload,
        postContributionsCommentData: null,
        postContributionsCommentStatus: "error",
      };
    default:
      return {
        ...state,
        postContributionsCommentStatus: null,
      };
  }
};
