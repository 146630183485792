import { CREATE_USER_ERROR, CREATE_USER_SUCCESS } from "../actions/types";

const initialState = {
  createUserData: null,
  createUserDataError: null,
  createUserStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserData: action.payload,
        createUserDataError: null,
        createUserStatus: "success",
      };
    case CREATE_USER_ERROR:
      return {
        ...state,
        createUserDataError: action.payload,
        createUserData: null,
        createUserStatus: "error",
      };
    default:
      return {
        ...state,
        createUserStatus: null,
      };
  }
};
