/* eslint-disable react/prop-types */
// @ts-nocheck
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'redux';
import { ArrowBackIos } from '@material-ui/icons';
import {
  IconButton,
  FormControl,
  Typography,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import { getLabelByName } from '../../../helpers/helperFunctions';
import Navbar from '../../Navbar';
import Footer from '../../Footer';
import './dedicated-page.css';
import { SEARCH } from '../../../helpers/constants';

class DedicatedPage extends Component {
  state = {};

  render() {
    const { match, pageTitle, resourceData, resource } = this.props;
    return (
      <div>
        <Navbar pathname="club" />
        <div style={{ position: 'relative', top: '130px' }}>
          <div className="resource-page">
            <div>
              <Link
                to={`/club/${match.params.id}/${match.params.handle}`}
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  width: 'fit-content',
                }}>
                <ArrowBackIos className="arrow-back-icon" />
                <Typography display="inline" variant="h4" component="h4">
                  {pageTitle}
                </Typography>
              </Link>
            </div>

            <div className="resource-page-search">
              {resourceData.length} {resource}
              <form>
                <FormControl variant="outlined" className="search-box">
                  <InputLabel htmlFor="search">{getLabelByName(SEARCH)}</InputLabel>
                  <OutlinedInput
                    id="search"
                    type="text"
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => { }}
                          onMouseDown={() => { }}
                          edge="end"
                        >
                          <SearchSharpIcon />
                        </IconButton>
                      </InputAdornment>
                    )}
                  />
                </FormControl>
              </form>
            </div>
            <div className="resource-list">
              {resourceData.map((resourceItem) => (
              <>{resourceItem}</>
              ))}
            </div>
          </div>
          <div className="footer-block">
            <div className="footer-component">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DedicatedPage.propTypes = {
 pageTitle: PropTypes.string,
 resource: PropTypes.string,
 resourceData: PropTypes.array,
};

export const mapStateToProps = (state) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps, {}),
)(DedicatedPage);
