import "./clubActivities.scss";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { ArrowBackIos as ArrowBackIosIcon } from "@material-ui/icons";
import Pagination from "../../../generics/Pagination/Pagination";
import Navbar from "../../../Navbar";
import { getLanding } from "../../../../redux/actions/landingActions";
import { getSessionAction } from "../../../../redux/actions/authActions";
import { getAdminClubActivities } from "../../../../redux/actions/campaignsActions";
import { getInstitute } from "../../../../redux/actions/institutesActions";
import Loading from "../../../generics/Loading/Loading";
import {
  checkSession,
  getLabelByName,
} from "../../../../helpers/helperFunctions";
import SearchBar from "../../../generics/SearchBar/SearchBar";
import campaignsIcon from "../../../../assets/manage-campaigns.svg";
import {
  ACTIVITIES,
  ACTIVITY,
  ADD,
  NEW,
  SEARCH,
} from "../../../../helpers/constants";
import Loader from "../../../Loader";
import CreateActivityModal from "../../../new/CreateActivityModal";

export class ClubActivities extends Component {
  state = {
    searchedActivities: null,
    displayedActivities: null,
    dataPageLimit: 15,
    triggerPagination: false,
    isAdding: false,
    isCreateModalVisible: false,
    editActivity: null,
    pathname: this.props.location.pathname,
  };

  async UNSAFE_componentWillMount() {
    const {
      getLanding: getLandingAction,
      getSessionAction: getSession,
      getAdminClubActivities: getAdminClubActivitiesAction,
      getInstitute: getInstituteAction,
      match: {
        params: { id: clubId, handle },
      },
    } = this.props;

    await getLandingAction();

    await getSession();

    await getAdminClubActivitiesAction(clubId);

    await getInstituteAction(handle);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, status } = nextProps;
    checkSession(data, status, this.state.pathname, nextProps);

    this.setState({
      displayedActivities: nextProps.adminClubActivitiesData?.data,
    });
  }

  render() {
    const {
      adminClubActivitiesData,
      adminClubActivitiesLoading,
      instituteData,
      match: {
        params: { id: clubId, handle },
      },
    } = this.props;

    const {
      displayedActivities,
      dataPageLimit,
      searchedActivities,
      triggerPagination,
      isAdding,
    } = this.state;

    if (adminClubActivitiesLoading && !displayedActivities) {
      return (
        <>
          <Navbar pathname="manage" />
          <Loading />
        </>
      );
    }

    return (
      <>
        <Navbar pathname="manage" />
        <div className="admin-club-activities">
          <div className="title">
            <div className="club-name">
              <button
                type="button"
                style={{
                  background: "none",
                  border: "none",
                  outline: "none",
                  marginTop: "-8px",
                  marginLeft: "-5px",
                }}
              >
                <Link
                  to={`/manage/clubs/${clubId}/${handle}`}
                  style={{
                    outline: "none",
                    color: "#424242",
                  }}
                >
                  <ArrowBackIosIcon />
                </Link>
              </button>
              <h1 style={{ paddingLeft: "10px" }}>
                {instituteData?.data.name}
              </h1>
            </div>
            <div className="edit-button bigger-screens">
              <button
                type="submit"
                className="btn btn-lg submit-btn"
                data-toggle="modal"
                data-target=".create-club-modal"
                onClick={() => {
                  this.setState({ editActivity: null });
                  this.setState((prevState) => ({
                    isCreateModalVisible: !prevState.isCreateModalVisible,
                  }));
                }}
              >
                {isAdding && (
                  <>
                    <span>
                      {`${getLabelByName(ADD)} ${getLabelByName(
                        NEW
                      ).toLowerCase()}`}
                    </span>
                    <Loader />
                  </>
                )}
                {!isAdding &&
                  `${getLabelByName(ADD)} ${getLabelByName(NEW).toLowerCase()}`}
              </button>
            </div>
          </div>
          <div className="activities-section">
            <div className="activities-header-details">
              <div className="activities-count bigger-screens">
                {(!triggerPagination && searchedActivities && (
                  <p>{`${searchedActivities.length} ${
                    (searchedActivities.length > 1 &&
                      getLabelByName(ACTIVITIES)) ||
                    getLabelByName(ACTIVITY)
                  }`}</p>
                )) ||
                  (displayedActivities && (
                    <p>{`${displayedActivities.length} ${
                      (displayedActivities.length > 1 &&
                        getLabelByName(ACTIVITIES)) ||
                      getLabelByName(ACTIVITY)
                    }`}</p>
                  ))}
              </div>
              <div className="search-activities">
                <SearchBar
                  setSearchedItems={(searchedItems) =>
                    this.setState({
                      searchedActivities: searchedItems,
                    })
                  }
                  triggerPagination
                  setTriggerPagination={() =>
                    this.setState({
                      triggerPagination: false,
                    })
                  }
                  displayedItems={displayedActivities}
                  setDisplayedItems={(displayedItems) => {
                    this.setState({
                      displayedActivities: displayedItems,
                    });
                  }}
                  placeholderText={`${getLabelByName(SEARCH)} ${getLabelByName(
                    ACTIVITY
                  ).toLowerCase()}`}
                />
              </div>
              <div className="activities-count small-screens">
                {(!triggerPagination && searchedActivities && (
                  <p>{`${searchedActivities.length} ${
                    (searchedActivities.length > 1 &&
                      getLabelByName(ACTIVITIES)) ||
                    getLabelByName(ACTIVITY)
                  }`}</p>
                )) ||
                  (displayedActivities && (
                    <p>{`${displayedActivities.length} ${
                      (displayedActivities.length > 1 &&
                        getLabelByName(ACTIVITIES)) ||
                      getLabelByName(ACTIVITY)
                    }`}</p>
                  ))}
                <div className="edit-button">
                  <button
                    type="submit"
                    className="btn btn-lg submit-btn"
                    data-toggle="modal"
                    data-target=".create-club-modal"
                    onClick={() => {
                      this.setState({ editActivity: null });
                      this.setState((prevState) => ({
                        isCreateModalVisible: !prevState.isCreateModalVisible,
                      }));
                    }}
                  >
                    {isAdding && (
                      <>
                        <span>
                          {`${getLabelByName(ADD)} ${getLabelByName(
                            NEW
                          ).toLowerCase()}`}
                        </span>
                        <Loader />
                      </>
                    )}
                    {!isAdding &&
                      `${getLabelByName(ADD)} ${getLabelByName(
                        NEW
                      ).toLowerCase()}`}
                  </button>
                </div>
              </div>
            </div>
            <div className="club-activities-cards">
              {(!triggerPagination &&
                searchedActivities &&
                searchedActivities?.map((activity) => (
                  <div className="one-activity-card">
                    <Link
                      style={{ color: "unset" }}
                      to={`/manage/activities/${activity.id}/${activity.handle}`}
                    >
                      <img
                        src={
                          activity.profile.picture !== ""
                            ? activity.profile.picture
                            : campaignsIcon
                        }
                        alt=""
                      />
                      <h1>{activity.name}</h1>
                    </Link>
                  </div>
                ))) ||
                displayedActivities?.map((activity) => (
                  <div className="one-activity-card">
                    <Link
                      style={{ color: "unset" }}
                      to={`/manage/activities/${activity.id}/${activity.handle}`}
                    >
                      <img
                        src={
                          activity.profile.picture !== ""
                            ? activity.profile.picture
                            : campaignsIcon
                        }
                        alt=""
                      />
                      <h1>{activity.name}</h1>
                    </Link>
                  </div>
                ))}
            </div>
            <div className="pagination">
              {adminClubActivitiesData?.data?.length > dataPageLimit && (
                <Pagination
                  data={adminClubActivitiesData?.data}
                  displayedData={searchedActivities || displayedActivities}
                  setDisplayedData={(data) => {
                    this.setState({
                      displayedActivities: data,
                    });
                  }}
                  pageLimit={adminClubActivitiesData?.data?.length}
                  dataLimit={dataPageLimit}
                  setTriggerPagination={() =>
                    this.setState({
                      triggerPagination: true,
                    })
                  }
                />
              )}
            </div>
          </div>
        </div>
        {/* Create Activity Modal */}
        <CreateActivityModal
          isCreateModalVisible={this.state.isCreateModalVisible}
          changeIsCreateModal={() => {
            this.setState((prevState) => ({
              editActivity: null,
              isCreateModalVisible: !prevState.isCreateModalVisible,
            }));
          }}
          editActivity={this.state.editActivity}
          getActivity={() => this.props.getAdminClubActivities(clubId)}
          propsClubData={instituteData}
          // clubId={clubId}
        />
      </>
    );
  }
}

ClubActivities.propTypes = {
  getSessionAction: PropTypes.func,
  getLanding: PropTypes.func,
  getAdminClubActivities: PropTypes.func,
  getInstitute: PropTypes.func,
};

const mapStateToProps = (state) => ({
  adminClubActivitiesData:
    state.getAdminClubActivitiesReducer.adminClubActivitiesData,
  adminClubActivitiesDataError:
    state.getAdminClubActivitiesReducer.adminClubActivitiesDataError,
  adminClubActivitiesLoading:
    state.getAdminClubActivitiesReducer.adminClubActivitiesLoading,
  instituteData: state.instituteReducer.instituteData,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
});

const mapDispatchToProps = {
  getSessionAction,
  getLanding,
  getAdminClubActivities,
  getInstitute,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ClubActivities);
