/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "./library-content.css";
import { MoreHoriz } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import LibraryActionsBar from "../LibraryActionsBar/LibraryActionsBar";
import LibrarySearch from "./LibrarySearch";
import { fileIconsMapping } from "../../../../helpers/constants";
import ItemDropdown from "./ItemDropdown";
import ItemDescriptionModal from "./ItemDescription";
import { getFormattedSetting } from "../../../../helpers/helperFunctions";

class LibraryContent extends Component {
  constructor(props) {
    super(props);
    console.log("Sources", this.props.sources);
    this.state = {
      selectedContribution: null,
      searchTerm: "",
      filteredContributions: this.props.contributions || [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      filteredContributions: nextProps.contributions,
    });
  }

  updateSearchTerm = (searchTerm) => {
    this.setState({ searchTerm });
    if (!searchTerm) {
      this.setState({
        filteredContributions: this.props.contributions,
      });
    } else {
      this.handleSearchTerm();
    }
  };

  clearSearchTerm = () => {
    this.setState({ searchTerm: "" });
    this.setState({
      filteredContributions: this.props.contributions,
    });
  };

  handleSearchTerm = () => {
    const { searchTerm } = this.state;
    const { contributions = [], settings } = this.props;
    const regex = new RegExp(searchTerm.toLowerCase());

    const filteredContributions = contributions.filter(
      (contribution) =>
        getFormattedSetting(
          settings?.listing_title,
          contribution.data,
        )
          .toLowerCase()
          .match(regex) ||
        getFormattedSetting(
          settings?.listing_subtitle,
          contribution.data,
        )
          .toLowerCase()
          .match(regex),
    );

    this.setState({ filteredContributions });
  };

  render() {
    const { contributions = [], settings } = this.props;
    const {
      searchTerm,
      selectedContribution,
      filteredContributions,
    } = this.state;

    return (
      <>
        <LibraryActionsBar
          title={settings?.title}
          contributions={contributions}
        />
        <LibrarySearch
          searchTerm={searchTerm}
          updateSearchTerm={this.updateSearchTerm}
          clearSearchTerm={this.clearSearchTerm}
        />

        <div className="library-items">
          {filteredContributions.map(
            (contribution, index) => (
              <div key={index} className="library-item">
                <div className="library-item__icon">
                  <img
                    src={
                      fileIconsMapping[
                        contribution.data[this.props.sources.listings.settings.listing_image] || "Images"
                      ]
                    }
                    alt="document-cover"
                  />
                </div>
                <div className="library-item__text">
                  <div
                    className="library-item__title"
                    data-toggle="modal"
                    data-target=".item-description-modal"
                    onClick={() =>
                      this.setState({
                        selectedContribution: contribution,
                      })
                    }>
                    {getFormattedSetting(
                      this.props.sources.listings.settings.listing_title,
                      contribution.data,
                    )}
                  </div>
                  <div className="library-item__description">
                    {getFormattedSetting(
                      this.props.sources.listings.settings.listing_subtitle,
                      contribution.data,
                    )}
                  </div>
                  <div className="library-item__bottom-section">
                    <div className="library-item__date">
                      {moment(
                        contribution.dateCreated * 1000,
                      ).format("DD MMMM YYYY")}
                    </div>
                    <div className="library-item__more dropdown">
                      <IconButton
                        type="button"
                        className="dropdown-toggle"
                        role="button"
                        id="dropdownItem"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        aria-haspopup="true">
                        <MoreHoriz />
                      </IconButton>
                      <ItemDropdown
                        contribution={contribution}
                        sources={this.props.sources}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ),
          )}
          <ItemDescriptionModal
            contribution={selectedContribution}
            sources={this.props.sources}
            settings={settings}
          />
        </div>
      </>
    );
  }
}

LibraryContent.propTypes = {
  contributions: PropTypes.array,
  settings: PropTypes.object,
};

export default LibraryContent;
