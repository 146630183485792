/* eslint-disable react/no-danger */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  ArrowBackIos,
  ArrowForwardIos,
  CreateTwoTone,
} from "@material-ui/icons";
import { toast } from "react-toastify";
import moment from "moment";

import { getSessionAction } from "../redux/actions/authActions";
import {
  getPost,
  createPost,
  getReactions,
  postReaction,
  deleteReaction,
} from "../redux/actions/postActions";

import Footer from "./Footer";
import Navbar from "./Navbar";
import ImageGridGallery from "./ImageGridGallery";
import timeIcon from "../assets/icons/time.svg";
import likingIcon from "../assets/reactions/like.svg";
import celebrateIcon from "../assets/reactions/celebrate.svg";
import supportIcon from "../assets/reactions/support.svg";
import loveIcon from "../assets/reactions/love.svg";
import insightfulIcon from "../assets/reactions/insightful.svg";
import curiousIcon from "../assets/reactions/curious.svg";
import dislikeIcon from "../assets/reactions/dislike.svg";
import likingIconOrange from "../assets/reactions/like-orange.svg";
import celebrateIconOrange from "../assets/reactions/celebrate-orange.svg";
import supportIconOrange from "../assets/reactions/support-orange.svg";
import loveIconOrange from "../assets/reactions/love-orange.svg";
import insightfulIconOrange from "../assets/reactions/insightful-orange.svg";
import curiousIconOrange from "../assets/reactions/curious-orange.svg";
import dislikeIconOrange from "../assets/reactions/dislike-orange.svg";
import commentIcon from "../assets/icons/comment.svg";
import commentIconOrange from "../assets/icons/comment-orange.svg";
import ReactionsModal from "./ReactionsModal";
import {
  renderOGMetaTags,
  getOGMetaTags,
  isSessionActive,
  getLabelByName,
  getProfileDataFromLocalStorage,
  getAllClubsFromLocalStorage,
} from "../helpers/helperFunctions";
import VideoPlayer from "./generics/VideoPlayer";
import FileDownload from "./generics/FileDownload";
import RedirectToAuth from "./generics/RedirectToAuth";
import {
  BACK,
  BY_WORD,
  CANCEL,
  COMMENTS,
  POST_ACTION,
  WHAT_THOUGHTS,
  REPLY,
  POSTING_ACTION,
  colors,
} from "../helpers/constants";
import Loader from "./Loader";

class Lesson extends Component {
  reactions = {};

  state = {
    listReactions: (postId) => this.fetchReactions(postId),
    isLoading: false,
    postComment: {},
    displayComments: [],
    selectedPost: null,
    profileData: getProfileDataFromLocalStorage(),
    pathname: this.props.location.pathname,
    isPosting: null,
  };

  async UNSAFE_componentWillMount() {
    const { props } = this;
    await props.getSessionAction();
    this.setState({ isLoading: true });
    await this.fetchData();
    this.setState({ isLoading: false });
  }

  async componentDidMount() {
    const commentHash = window.location.hash.slice(1);
    const element = document.getElementById(commentHash);
    if (element)
      element.scrollIntoView({ behavior: "smooth" });
  }

  async componentDidUpdate() {
    const commentHash = window.location.hash.slice(1);
    const element = document.getElementById(commentHash);
    if (element)
      element.scrollIntoView({ behavior: "smooth" });
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { clickedPostId, profileData } = this.state;
    const {
      createPostData,
      createPostStatus,
      getReactionsData,
      postReactionData,
      postReactionStatus,
      deleteReactionStatus,
    } = nextProps;

    switch (createPostStatus) {
      case "success":
        switch (createPostData.status) {
          case 200:
            await this.fetchData();
            this.setState({
              isLoading: false,
              postComment: {},
              displayComments: [],
              isPosting: null,
            });
            break;
          default:
            toast.error(
              "Posting error!\nPlease try again!",
            );
            break;
        }
        break;
      case "error":
        toast.error("Posting error!\nPlease try again!");
        break;
      default:
        break;
    }

    switch (postReactionStatus) {
      case "success":
        switch (postReactionData.status) {
          case 200:
            await this.fetchData();
            break;
          default:
            toast.error(
              "Posting error!\nPlease try again!",
            );
            break;
        }
        break;
      case "error":
        toast.error("Posting error!\nPlease try again!");
        break;
      default:
        break;
    }

    switch (deleteReactionStatus) {
      case "success":
        await this.fetchData();
        break;
      case "error":
        toast.error("Posting error!\nPlease try again!");
        break;
      default:
        break;
    }

    if (
      clickedPostId !== null &&
      getReactionsData &&
      getReactionsData.data &&
      Object.keys(getReactionsData.data).length > 0 &&
      !this.reactions[clickedPostId]
    ) {
      this.reactions[clickedPostId] = getReactionsData.data;
    }

    if (
      postReactionStatus &&
      postReactionData &&
      postReactionData.data &&
      clickedPostId !== null
    ) {
      if (!this.reactions[clickedPostId]) {
        this.reactions[clickedPostId] = {};
      }
      if (
        !this.reactions[clickedPostId][profileData.data.id]
      ) {
        this.reactions[clickedPostId][profileData.data.id] =
          postReactionData.data;
      }
    }
  }

  fetchData = async () => {
    const { props } = this;
    const { clubId, postId } = props.match.params;
    await props.getPost(clubId, postId);
  };

  fetchReactions = async (postId) => {
    this.setState({ clickedPostId: postId });
    const { props } = this;
    if (!this.reactions[postId]) {
      await props.getReactions(postId);
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleChangeComment = (e, postId) => {
    const { value } = e.target;
    const { postComment } = this.state;
    this.setState({
      postComment: {
        ...postComment,
        [postId]: value,
      },
    });
  };

  createPostReaction = async (
    postId,
    reaction,
    myReaction,
  ) => {
    const profileData = getProfileDataFromLocalStorage();
    const { props } = this;
    if (myReaction && myReaction === reaction) {
      await props.deleteReaction(postId, reaction);
      delete this.reactions[postId][profileData.data.id];
    }
    if (!myReaction) {
      await props.postReaction({}, postId, reaction);
    }
  };

  submitPost = async (e, authorId, parentId, postType) => {
    this.setState({ isPosting: parentId });
    e.preventDefault();
    const { props, state } = this;
    let postBody;
    if (postType === "post") {
      const { postMessage } = state;
      postBody = postMessage;
    } else {
      const { postComment } = state;
      postBody = postComment[parentId];
    }

    const { clubId } = props.match.params;

    const payload = {
      authorId,
      parentId,
      content: {
        body: postBody,
      },
      images: {},
      videos: {},
      files: {},
      status: "APPROVED",
    };
    await props.createPost(payload, clubId, postType);
  };

  onToggleComments = (postId) => {
    const { displayComments } = this.state;
    if (!displayComments.includes(postId)) {
      displayComments.push(postId);
      this.setState({ displayComments });
    } else {
      displayComments.splice(
        displayComments.indexOf(postId),
      );
      this.setState({ displayComments });
    }
  };

  generateOGMetaTags = () => {
    let tags = {};
    if (
      this.dataHasLoaded(this.props.getPostData) &&
      this.dataHasLoaded(this.props.landingData)
    ) {
      tags = getOGMetaTags(
        this.props.getPostData.data,
        this.props.landingData.data[4],
      );
    }
    return tags;
  };

  dataHasLoaded = (state) => !!(state && state.data);

  render() {
    const { props, state } = this;
    const { REACT_APP_APPLICATION_NAME } = process.env;
    document.title = `${
      REACT_APP_APPLICATION_NAME || "SOSO"
    } - Lesson`;
    const profileData = getProfileDataFromLocalStorage();
    const clubsData = getAllClubsFromLocalStorage();
    const { getPostData } = props;
    const {
      displayComments,
      postComment,
      selectedPost,
      isPosting,
    } = state;
    const { clubId, postId } = props.match.params;

    const dateCreated =
      getPostData && getPostData.data
        ? moment(
            getPostData.data.dateCreated * 1000,
          ).format("hh:mm, DD MMM YYYY")
        : "hh:mm, DD MMM YYYY";

    let contentImages = {};
    if (getPostData && getPostData.data) {
      contentImages = { ...getPostData.data.images };
      delete contentImages.headerImage;
    }

    const userRoles = profileData
      ? profileData.data.roles
      : [];
    const userIsEditor = profileData
      ? profileData.data.clubs[clubId] &&
        profileData.data.clubs[clubId].role === "editor"
      : [];
    const userIsClubAdmin = profileData
      ? profileData.data.clubs[clubId] &&
        profileData.data.clubs[clubId].role === "admin"
      : [];

    const userCanEdit =
      userIsEditor ||
      userIsClubAdmin ||
      userRoles.includes("admin") ||
      (profileData.data.id === getPostData &&
        getPostData.data &&
        getPostData.data.author.id);

    const commentHash = window.location.hash.slice(1);

    return (
      <>
        {renderOGMetaTags(this.generateOGMetaTags())}
        <Navbar pathname="search" />
        <div className="campaign-page container">
          {this.dataHasLoaded(this.props.data) &&
            !isSessionActive(this.props.data) && (
              <div className="redirect-to-auth">
                <RedirectToAuth
                  pathname={this.state.pathname}
                />
              </div>
            )}
          {this.dataHasLoaded(this.props.getPostData) &&
            isSessionActive(this.props.data) && (
              <div className="row">
                <div className="campaign-main col-md-8">
                  <div className="container">
                    <div className="buttons-row">
                      <div id="back-link">
                        <div
                          className="d-flex justify-content-center align-items-center"
                          onClick={() =>
                            this.props.history.goBack()
                          }
                          style={{ cursor: "pointer" }}>
                          <ArrowBackIos className="arrow-back-icon" />
                          {"  "}
                          {getLabelByName(BACK)}
                        </div>
                      </div>
                    </div>
                    <div className="display-article-title">
                      {getPostData &&
                        getPostData.data.content.title}
                    </div>
                    <div className="display-article-title-small">
                      {getPostData &&
                        getPostData.data.content
                          .description}
                    </div>
                    <div className="campaign-author">
                      {getLabelByName(BY_WORD)}{" "}
                      {getPostData
                        ? `${getPostData.data.author.profile.firstName} ${getPostData.data.author.profile.lastName}`
                        : "--------"}
                      {userCanEdit && (
                        <Link
                          className="ml-3"
                          to={`/club/${clubId}/${
                            clubsData &&
                            clubsData.filter(
                              (club) => club.id === clubId,
                            )[0].handle
                          }/lesson/${postId}/edit`}>
                          <span
                            style={{ cursor: "pointer" }}
                            title="Edit Lesson">
                            <CreateTwoTone
                              style={{
                                color: colors.primaryColor,
                              }}
                            />
                          </span>
                        </Link>
                      )}
                    </div>
                    <div className="activity-stats">
                      <div className="activity-stat-item dropup">
                        <div
                          className="dropdown-toggle activity-stat-icon handicon"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() =>
                            this.state.listReactions(
                              getPostData &&
                                getPostData.data.id,
                            )
                          }>
                          <img
                            src={likingIcon}
                            alt="like-icon"
                            width="25px"
                          />
                        </div>
                        {getPostData &&
                          Object.values(
                            getPostData &&
                              getPostData.data &&
                              getPostData.data.reactions,
                          )
                            .map((reaction) =>
                              parseInt(reaction.total, 10),
                            )
                            .reduce((a, b) => a + b, 0)}
                        <div className="dropdown-menu dropdown-menu-right">
                          <div className="reactions-icons justify-content-between">
                            <div className="d-flex">
                              <div className="activity-stat-item">
                                <div
                                  className="activity-stat-icon"
                                  onClick={() =>
                                    this.createPostReaction(
                                      getPostData &&
                                        getPostData.data.id,
                                      "like",
                                      this.reactions &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ] &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ][
                                          profileData.data
                                            .id
                                        ] &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ][
                                          profileData.data
                                            .id
                                        ].name,
                                    )
                                  }>
                                  <img
                                    src={
                                      this.reactions &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ] &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ][
                                        profileData.data.id
                                      ] &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ][profileData.data.id]
                                        .name === "like"
                                        ? likingIconOrange
                                        : likingIcon
                                    }
                                    alt="like-icon"
                                    width="25px"
                                  />
                                </div>
                                {(getPostData &&
                                  getPostData.data
                                    .reactions &&
                                  getPostData &&
                                  getPostData.data.reactions
                                    .like &&
                                  getPostData &&
                                  getPostData.data.reactions
                                    .like.total) ||
                                  0}
                              </div>
                              <div className="activity-stat-item">
                                <div
                                  className="activity-stat-icon"
                                  onClick={() =>
                                    this.createPostReaction(
                                      getPostData &&
                                        getPostData.data.id,
                                      "celebrate",
                                      this.reactions &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ] &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ][
                                          profileData.data
                                            .id
                                        ] &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ][
                                          profileData.data
                                            .id
                                        ].name,
                                    )
                                  }>
                                  <img
                                    src={
                                      this.reactions &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ] &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ][
                                        profileData.data.id
                                      ] &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ][profileData.data.id]
                                        .name ===
                                        "celebrate"
                                        ? celebrateIconOrange
                                        : celebrateIcon
                                    }
                                    alt="celebrate-icon"
                                    width="25px"
                                  />
                                </div>
                                {(getPostData &&
                                  getPostData.data
                                    .reactions &&
                                  getPostData &&
                                  getPostData.data.reactions
                                    .celebrate &&
                                  getPostData &&
                                  getPostData.data.reactions
                                    .celebrate.total) ||
                                  0}
                              </div>
                              <div className="activity-stat-item">
                                <div
                                  className="activity-stat-icon"
                                  onClick={() =>
                                    this.createPostReaction(
                                      getPostData &&
                                        getPostData.data.id,
                                      "support",
                                      this.reactions &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ] &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ][
                                          profileData.data
                                            .id
                                        ] &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ][
                                          profileData.data
                                            .id
                                        ].name,
                                    )
                                  }>
                                  <img
                                    src={
                                      this.reactions &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ] &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ][
                                        profileData.data.id
                                      ] &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ][profileData.data.id]
                                        .name === "support"
                                        ? supportIconOrange
                                        : supportIcon
                                    }
                                    alt="support-icon"
                                    width="25px"
                                  />
                                </div>
                                {(getPostData &&
                                  getPostData.data
                                    .reactions &&
                                  getPostData &&
                                  getPostData.data.reactions
                                    .support &&
                                  getPostData &&
                                  getPostData.data.reactions
                                    .support.total) ||
                                  0}
                              </div>
                              <div className="activity-stat-item">
                                <div
                                  className="activity-stat-icon"
                                  onClick={() =>
                                    this.createPostReaction(
                                      getPostData &&
                                        getPostData.data.id,
                                      "love",
                                      this.reactions &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ] &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ][
                                          profileData.data
                                            .id
                                        ] &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ][
                                          profileData.data
                                            .id
                                        ].name,
                                    )
                                  }>
                                  <img
                                    src={
                                      this.reactions &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ] &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ][
                                        profileData.data.id
                                      ] &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ][profileData.data.id]
                                        .name === "love"
                                        ? loveIconOrange
                                        : loveIcon
                                    }
                                    alt="love-icon"
                                    width="25px"
                                  />
                                </div>
                                {(getPostData &&
                                  getPostData.data
                                    .reactions &&
                                  getPostData &&
                                  getPostData.data.reactions
                                    .love &&
                                  getPostData &&
                                  getPostData.data.reactions
                                    .love.total) ||
                                  0}
                              </div>
                              <div className="activity-stat-item">
                                <div
                                  className="activity-stat-icon"
                                  onClick={() =>
                                    this.createPostReaction(
                                      getPostData &&
                                        getPostData.data.id,
                                      "insightful",
                                      this.reactions &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ] &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ][
                                          profileData.data
                                            .id
                                        ] &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ][
                                          profileData.data
                                            .id
                                        ].name,
                                    )
                                  }>
                                  <img
                                    src={
                                      this.reactions &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ] &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ][
                                        profileData.data.id
                                      ] &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ][profileData.data.id]
                                        .name ===
                                        "insightful"
                                        ? insightfulIconOrange
                                        : insightfulIcon
                                    }
                                    alt="insightful-icon"
                                    width="25px"
                                  />
                                </div>
                                {(getPostData &&
                                  getPostData.data
                                    .reactions &&
                                  getPostData &&
                                  getPostData.data.reactions
                                    .insightful &&
                                  getPostData &&
                                  getPostData.data.reactions
                                    .insightful.total) ||
                                  0}
                              </div>
                              <div className="activity-stat-item">
                                <div
                                  className="activity-stat-icon"
                                  onClick={() =>
                                    this.createPostReaction(
                                      getPostData &&
                                        getPostData.data.id,
                                      "curious",
                                      this.reactions &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ] &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ][
                                          profileData.data
                                            .id
                                        ] &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ][
                                          profileData.data
                                            .id
                                        ].name,
                                    )
                                  }>
                                  <img
                                    src={
                                      this.reactions &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ] &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ][
                                        profileData.data.id
                                      ] &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ][profileData.data.id]
                                        .name === "curious"
                                        ? curiousIconOrange
                                        : curiousIcon
                                    }
                                    alt="curious-icon"
                                    width="25px"
                                  />
                                </div>
                                {(getPostData &&
                                  getPostData.data
                                    .reactions &&
                                  getPostData &&
                                  getPostData.data.reactions
                                    .curious &&
                                  getPostData &&
                                  getPostData.data.reactions
                                    .curious.total) ||
                                  0}
                              </div>
                              <div className="activity-stat-item">
                                <div
                                  className="activity-stat-icon"
                                  onClick={() =>
                                    this.createPostReaction(
                                      getPostData &&
                                        getPostData.data.id,
                                      "dislike",
                                      this.reactions &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ] &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ][
                                          profileData.data
                                            .id
                                        ] &&
                                        this.reactions[
                                          getPostData &&
                                            getPostData.data
                                              .id
                                        ][
                                          profileData.data
                                            .id
                                        ].name,
                                    )
                                  }>
                                  <img
                                    src={
                                      this.reactions &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ] &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ][
                                        profileData.data.id
                                      ] &&
                                      this.reactions[
                                        getPostData &&
                                          getPostData.data
                                            .id
                                      ][profileData.data.id]
                                        .name === "dislike"
                                        ? dislikeIconOrange
                                        : dislikeIcon
                                    }
                                    alt="dislike-icon"
                                    width="25px"
                                  />
                                </div>
                                {(getPostData &&
                                  getPostData.data
                                    .reactions &&
                                  getPostData &&
                                  getPostData.data.reactions
                                    .dislike &&
                                  getPostData &&
                                  getPostData.data.reactions
                                    .dislike.total) ||
                                  0}
                              </div>
                              <div
                                className="reactions-modal-arrow d-flex align-items-center"
                                data-toggle="modal"
                                data-target=".reactions-modal"
                                onClick={() =>
                                  this.setState({
                                    selectedPost:
                                      getPostData &&
                                      getPostData.id,
                                  })
                                }>
                                <ArrowForwardIos
                                  style={{
                                    color: "#C6A177",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="activity-stat-item">
                        <div className="activity-stat-icon">
                          <img
                            src={commentIcon}
                            alt="comment-icon"
                            width="25px"
                          />
                        </div>
                        {getPostData
                          ? getPostData.data.totalResponses
                          : 0}
                      </div>
                      <div className="activity-stat-item">
                        <div className="activity-stat-icon">
                          <img
                            src={timeIcon}
                            alt="time-icon"
                            width="25px"
                          />
                        </div>
                        {dateCreated}
                      </div>
                    </div>

                    <div className="campaign-content mt-3">
                      {getPostData &&
                        getPostData.data &&
                        getPostData.data.images
                          .headerImage && (
                          <div className="article-header-image mb-2">
                            <img
                              src={
                                getPostData.data.images
                                  .headerImage
                              }
                              alt="header-image"
                              style={{ width: "100%" }}
                            />
                          </div>
                        )}
                      {getPostData && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              getPostData.data.content.body,
                          }}
                        />
                      )}

                      {/* ================ IMAGE GRID GALLERY ================ */}
                      {getPostData && getPostData.data && (
                        <div className="activity-content-images pb-3">
                          {Object.values(
                            getPostData.data.images,
                          ).length > 0 && (
                            <ImageGridGallery
                              images={Object.values(
                                contentImages,
                              )}
                            />
                          )}
                        </div>
                      )}

                      {/* ================ VIDEO PLAYER ================ */}
                      {getPostData && getPostData.data && (
                        <div className="activity-content-videos pb-3">
                          {Object.values(
                            getPostData.data.videos,
                          ).length > 0 &&
                            Object.values(
                              getPostData.data.videos,
                            ).map((video) => (
                              <VideoPlayer video={video} />
                            ))}
                        </div>
                      )}

                      {/* ================ FILE DOWNLOAD ================ */}
                      {getPostData && getPostData.data && (
                        <div className="activity-content-files pb-3">
                          {Object.values(
                            getPostData.data.files,
                          ).length > 0 &&
                            Object.values(
                              getPostData.data.files,
                            ).map((file) => (
                              <FileDownload file={file} />
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="campaign-comments">
                    <div className="comments-title">
                      {getLabelByName(COMMENTS)}
                      {" ("}
                      {getPostData
                        ? getPostData.data.totalResponses
                        : 0}
                      )
                    </div>
                    <div className="write-comment">
                      <div className="card">
                        <div className="card-body">
                          <form
                            onSubmit={(e) =>
                              this.submitPost(
                                e,
                                profileData.data.id,
                                postId,
                                "comment",
                                clubId,
                              )
                            }>
                            <div className="post-item-author">
                              <div className="post-avatar">
                                {profileData.data.profile
                                  .picture && (
                                  <img
                                    className="img-fluid"
                                    src={
                                      profileData.data
                                        .profile.picture
                                    }
                                    alt="club-avatar"
                                  />
                                )}
                                {!profileData.data.profile
                                  .picture && (
                                  <div className="member-initials">
                                    {profileData.data
                                      .profile
                                      .firstName[0] +
                                      profileData.data
                                        .profile
                                        .lastName[0]}
                                  </div>
                                )}
                              </div>
                              <div className="comment-author-name font-weight-bold">
                                {`${profileData.data.profile.firstName} ${profileData.data.profile.lastName}`}
                              </div>
                            </div>
                            <div className="post-textarea">
                              <textarea
                                rows="4"
                                placeholder={getLabelByName(
                                  WHAT_THOUGHTS,
                                )}
                                name="postComment"
                                value={
                                  postComment[postId] || ""
                                }
                                onChange={(e) =>
                                  this.handleChangeComment(
                                    e,
                                    postId,
                                  )
                                }
                                required
                              />
                            </div>
                            <div className="post-buttons">
                              <div className="cancel-button">
                                <button
                                  type="submit"
                                  className="btn btn-sm submit-btn btn-reverse"
                                  disabled={false}
                                  onClick={() => {}}>
                                  {getLabelByName(CANCEL)}
                                </button>
                              </div>
                              <div className="post-button">
                                <button
                                  type="submit"
                                  className="btn btn-sm submit-btn"
                                  disabled={
                                    isPosting === postId
                                  }
                                  onClick={() => {}}>
                                  {isPosting === postId ? (
                                    <>
                                      {getLabelByName(
                                        POSTING_ACTION,
                                      )}{" "}
                                      <Loader />
                                    </>
                                  ) : (
                                    getLabelByName(
                                      POST_ACTION,
                                    )
                                  )}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="comments-list">
                      {getPostData &&
                        getPostData.data &&
                        getPostData.data.responses.map(
                          (postItem) => (
                            <div
                              id={`commentId-${postItem.id}`}
                              className="card article-comment-card mb-2">
                              <div
                                id={
                                  `commentId-${postItem.id}` ===
                                  commentHash
                                    ? "highlighted-comment"
                                    : ""
                                }>
                                <div className="card-body">
                                  <div className="activity-content-row">
                                    <div className="activity-content">
                                      <div className="post-item-author">
                                        <div className="post-avatar">
                                          {postItem.author
                                            .profile
                                            .picture && (
                                            <img
                                              className="img-fluid"
                                              src={
                                                postItem
                                                  .author
                                                  .profile
                                                  .picture
                                              }
                                              alt="club-avatar"
                                            />
                                          )}
                                          {!postItem.author
                                            .profile
                                            .picture && (
                                            <div className="member-initials">
                                              {postItem
                                                .author
                                                .profile
                                                .firstName[0] +
                                                postItem
                                                  .author
                                                  .profile
                                                  .lastName[0]}
                                            </div>
                                          )}
                                        </div>
                                        <div className="post-author-name">
                                          {`${postItem.author.profile.firstName} ${postItem.author.profile.lastName}`}
                                          <br />
                                          <span className="post-item-member">
                                            {moment(
                                              postItem.dateCreated *
                                                1000,
                                            ).format(
                                              "DD MMMM YYYY",
                                            ) ||
                                              "DD MMMM YYYY"}
                                          </span>
                                        </div>
                                      </div>
                                      <p className="activity-content-text pb-3">
                                        {
                                          postItem.content
                                            .body
                                        }
                                      </p>
                                      <div className="activity-content-images pb-3">
                                        {Object.values(
                                          postItem.images,
                                        ).length > 0 && (
                                          <ImageGridGallery
                                            images={Object.values(
                                              postItem.images,
                                            )}
                                          />
                                        )}
                                      </div>
                                    </div>

                                    <div className="activity-stats justify-content-between">
                                      <div className="d-flex">
                                        <div className="activity-stat-item dropup">
                                          <div
                                            className="dropdown-toggle activity-stat-icon handicon"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            onClick={() =>
                                              this.state.listReactions(
                                                postItem.id,
                                              )
                                            }>
                                            <img
                                              src={
                                                likingIcon
                                              }
                                              alt="like-icon"
                                              width="25px"
                                            />
                                          </div>
                                          {Object.values(
                                            postItem.reactions,
                                          )
                                            .map(
                                              (reaction) =>
                                                parseInt(
                                                  reaction.total,
                                                  10,
                                                ),
                                            )
                                            .reduce(
                                              (a, b) =>
                                                a + b,
                                              0,
                                            )}
                                          <div className="dropdown-menu dropdown-menu-right">
                                            <div className="reactions-icons justify-content-between">
                                              <div className="d-flex">
                                                <div className="activity-stat-item">
                                                  <div
                                                    className="activity-stat-icon"
                                                    onClick={() =>
                                                      this.createPostReaction(
                                                        postItem.id,
                                                        "like",
                                                        this
                                                          .reactions &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ] &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ][
                                                            profileData
                                                              .data
                                                              .id
                                                          ] &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ][
                                                            profileData
                                                              .data
                                                              .id
                                                          ]
                                                            .name,
                                                      )
                                                    }>
                                                    <img
                                                      src={
                                                        this
                                                          .reactions &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ] &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ][
                                                          profileData
                                                            .data
                                                            .id
                                                        ] &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ][
                                                          profileData
                                                            .data
                                                            .id
                                                        ]
                                                          .name ===
                                                          "like"
                                                          ? likingIconOrange
                                                          : likingIcon
                                                      }
                                                      alt="like-icon"
                                                      width="25px"
                                                    />
                                                  </div>
                                                  {(postItem.reactions &&
                                                    postItem
                                                      .reactions
                                                      .like &&
                                                    postItem
                                                      .reactions
                                                      .like
                                                      .total) ||
                                                    0}
                                                </div>
                                                <div className="activity-stat-item">
                                                  <div
                                                    className="activity-stat-icon"
                                                    onClick={() =>
                                                      this.createPostReaction(
                                                        postItem.id,
                                                        "celebrate",
                                                        this
                                                          .reactions &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ] &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ][
                                                            profileData
                                                              .data
                                                              .id
                                                          ] &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ][
                                                            profileData
                                                              .data
                                                              .id
                                                          ]
                                                            .name,
                                                      )
                                                    }>
                                                    <img
                                                      src={
                                                        this
                                                          .reactions &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ] &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ][
                                                          profileData
                                                            .data
                                                            .id
                                                        ] &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ][
                                                          profileData
                                                            .data
                                                            .id
                                                        ]
                                                          .name ===
                                                          "celebrate"
                                                          ? celebrateIconOrange
                                                          : celebrateIcon
                                                      }
                                                      alt="celebrate-icon"
                                                      width="25px"
                                                    />
                                                  </div>
                                                  {(postItem.reactions &&
                                                    postItem
                                                      .reactions
                                                      .celebrate &&
                                                    postItem
                                                      .reactions
                                                      .celebrate
                                                      .total) ||
                                                    0}
                                                </div>
                                                <div className="activity-stat-item">
                                                  <div
                                                    className="activity-stat-icon"
                                                    onClick={() =>
                                                      this.createPostReaction(
                                                        postItem.id,
                                                        "support",
                                                        this
                                                          .reactions &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ] &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ][
                                                            profileData
                                                              .data
                                                              .id
                                                          ] &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ][
                                                            profileData
                                                              .data
                                                              .id
                                                          ]
                                                            .name,
                                                      )
                                                    }>
                                                    <img
                                                      src={
                                                        this
                                                          .reactions &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ] &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ][
                                                          profileData
                                                            .data
                                                            .id
                                                        ] &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ][
                                                          profileData
                                                            .data
                                                            .id
                                                        ]
                                                          .name ===
                                                          "support"
                                                          ? supportIconOrange
                                                          : supportIcon
                                                      }
                                                      alt="support-icon"
                                                      width="25px"
                                                    />
                                                  </div>
                                                  {(postItem.reactions &&
                                                    postItem
                                                      .reactions
                                                      .support &&
                                                    postItem
                                                      .reactions
                                                      .support
                                                      .total) ||
                                                    0}
                                                </div>
                                                <div className="activity-stat-item">
                                                  <div
                                                    className="activity-stat-icon"
                                                    onClick={() =>
                                                      this.createPostReaction(
                                                        postItem.id,
                                                        "love",
                                                        this
                                                          .reactions &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ] &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ][
                                                            profileData
                                                              .data
                                                              .id
                                                          ] &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ][
                                                            profileData
                                                              .data
                                                              .id
                                                          ]
                                                            .name,
                                                      )
                                                    }>
                                                    <img
                                                      src={
                                                        this
                                                          .reactions &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ] &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ][
                                                          profileData
                                                            .data
                                                            .id
                                                        ] &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ][
                                                          profileData
                                                            .data
                                                            .id
                                                        ]
                                                          .name ===
                                                          "love"
                                                          ? loveIconOrange
                                                          : loveIcon
                                                      }
                                                      alt="love-icon"
                                                      width="25px"
                                                    />
                                                  </div>
                                                  {(postItem.reactions &&
                                                    postItem
                                                      .reactions
                                                      .love &&
                                                    postItem
                                                      .reactions
                                                      .love
                                                      .total) ||
                                                    0}
                                                </div>
                                                <div className="activity-stat-item">
                                                  <div
                                                    className="activity-stat-icon"
                                                    onClick={() =>
                                                      this.createPostReaction(
                                                        postItem.id,
                                                        "insightful",
                                                        this
                                                          .reactions &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ] &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ][
                                                            profileData
                                                              .data
                                                              .id
                                                          ] &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ][
                                                            profileData
                                                              .data
                                                              .id
                                                          ]
                                                            .name,
                                                      )
                                                    }>
                                                    <img
                                                      src={
                                                        this
                                                          .reactions &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ] &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ][
                                                          profileData
                                                            .data
                                                            .id
                                                        ] &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ][
                                                          profileData
                                                            .data
                                                            .id
                                                        ]
                                                          .name ===
                                                          "insightful"
                                                          ? insightfulIconOrange
                                                          : insightfulIcon
                                                      }
                                                      alt="insightful-icon"
                                                      width="25px"
                                                    />
                                                  </div>
                                                  {(postItem.reactions &&
                                                    postItem
                                                      .reactions
                                                      .insightful &&
                                                    postItem
                                                      .reactions
                                                      .insightful
                                                      .total) ||
                                                    0}
                                                </div>
                                                <div className="activity-stat-item">
                                                  <div
                                                    className="activity-stat-icon"
                                                    onClick={() =>
                                                      this.createPostReaction(
                                                        postItem.id,
                                                        "curious",
                                                        this
                                                          .reactions &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ] &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ][
                                                            profileData
                                                              .data
                                                              .id
                                                          ] &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ][
                                                            profileData
                                                              .data
                                                              .id
                                                          ]
                                                            .name,
                                                      )
                                                    }>
                                                    <img
                                                      src={
                                                        this
                                                          .reactions &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ] &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ][
                                                          profileData
                                                            .data
                                                            .id
                                                        ] &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ][
                                                          profileData
                                                            .data
                                                            .id
                                                        ]
                                                          .name ===
                                                          "curious"
                                                          ? curiousIconOrange
                                                          : curiousIcon
                                                      }
                                                      alt="curious-icon"
                                                      width="25px"
                                                    />
                                                  </div>
                                                  {(postItem.reactions &&
                                                    postItem
                                                      .reactions
                                                      .curious &&
                                                    postItem
                                                      .reactions
                                                      .curious
                                                      .total) ||
                                                    0}
                                                </div>
                                                <div className="activity-stat-item">
                                                  <div
                                                    className="activity-stat-icon"
                                                    onClick={() =>
                                                      this.createPostReaction(
                                                        postItem.id,
                                                        "dislike",
                                                        this
                                                          .reactions &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ] &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ][
                                                            profileData
                                                              .data
                                                              .id
                                                          ] &&
                                                          this
                                                            .reactions[
                                                            postItem
                                                              .id
                                                          ][
                                                            profileData
                                                              .data
                                                              .id
                                                          ]
                                                            .name,
                                                      )
                                                    }>
                                                    <img
                                                      src={
                                                        this
                                                          .reactions &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ] &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ][
                                                          profileData
                                                            .data
                                                            .id
                                                        ] &&
                                                        this
                                                          .reactions[
                                                          postItem
                                                            .id
                                                        ][
                                                          profileData
                                                            .data
                                                            .id
                                                        ]
                                                          .name ===
                                                          "dislike"
                                                          ? dislikeIconOrange
                                                          : dislikeIcon
                                                      }
                                                      alt="dislike-icon"
                                                      width="25px"
                                                    />
                                                  </div>
                                                  {(postItem.reactions &&
                                                    postItem
                                                      .reactions
                                                      .dislike &&
                                                    postItem
                                                      .reactions
                                                      .dislike
                                                      .total) ||
                                                    0}
                                                </div>
                                                <div
                                                  className="reactions-modal-arrow d-flex align-items-center"
                                                  data-toggle="modal"
                                                  data-target=".reactions-modal"
                                                  onClick={() =>
                                                    this.setState(
                                                      {
                                                        selectedPost:
                                                          postItem.id,
                                                      },
                                                    )
                                                  }>
                                                  <ArrowForwardIos
                                                    style={{
                                                      color:
                                                        "#C6A177",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="activity-stat-item"
                                          style={{
                                            color:
                                              displayComments.includes(
                                                postItem.id,
                                              ) &&
                                              colors.primaryColor,
                                          }}
                                          onClick={() =>
                                            this.onToggleComments(
                                              postItem.id,
                                            )
                                          }>
                                          <div className="activity-stat-icon">
                                            <img
                                              src={
                                                displayComments.includes(
                                                  postItem.id,
                                                )
                                                  ? commentIconOrange
                                                  : commentIcon
                                              }
                                              alt="comment-icon"
                                              width="25px"
                                            />
                                          </div>
                                          {
                                            postItem.totalResponses
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    {displayComments.includes(
                                      postItem.id,
                                    ) && (
                                      <div
                                        className="comments-sections"
                                        style={{
                                          borderTop:
                                            postItem.totalResponses <=
                                              0 && "unset",
                                          paddingTop:
                                            postItem.totalResponses <=
                                              0 && "unset",
                                        }}>
                                        <div className="comments-list">
                                          {postItem
                                            .responses
                                            .length > 0 &&
                                            postItem.responses.map(
                                              (comment) => (
                                                <div
                                                  id={`commentId-${comment.id}`}
                                                  className="post-comment-block">
                                                  <div
                                                    id={
                                                      `commentId-${postItem.id}` ===
                                                      commentHash
                                                        ? "highlighted-comment"
                                                        : ""
                                                    }>
                                                    <div className="post-comment-item-author d-none d-lg-block d-xl-block">
                                                      <div className="post-avatar">
                                                        {comment
                                                          .author
                                                          .profile
                                                          .picture && (
                                                          <img
                                                            className="img-fluid"
                                                            src={
                                                              comment
                                                                .author
                                                                .profile
                                                                .picture
                                                            }
                                                            alt="club-avatar"
                                                          />
                                                        )}
                                                        {!comment
                                                          .author
                                                          .profile
                                                          .picture && (
                                                          <div className="member-initials">
                                                            {comment
                                                              .author
                                                              .profile
                                                              .firstName[0] +
                                                              comment
                                                                .author
                                                                .profile
                                                                .lastName[0]}
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="post-comment-item">
                                                      <div className="activity-content">
                                                        <div className="post-item-author">
                                                          <div className="post-avatar d-lg-none d-sm-block">
                                                            {comment
                                                              .author
                                                              .profile
                                                              .picture && (
                                                              <img
                                                                className="img-fluid"
                                                                src={
                                                                  comment
                                                                    .author
                                                                    .profile
                                                                    .picture
                                                                }
                                                                alt="club-avatar"
                                                              />
                                                            )}
                                                            {!comment
                                                              .author
                                                              .profile
                                                              .picture && (
                                                              <div className="member-initials">
                                                                {comment
                                                                  .author
                                                                  .profile
                                                                  .firstName[0] +
                                                                  comment
                                                                    .author
                                                                    .profile
                                                                    .lastName[0]}
                                                              </div>
                                                            )}
                                                          </div>
                                                          <div className="post-author-name">
                                                            {`${comment.author.profile.firstName} ${comment.author.profile.lastName}`}
                                                            <br />
                                                            <span className="post-item-member">
                                                              {moment(
                                                                comment.dateCreated *
                                                                  1000,
                                                              ).format(
                                                                "DD MMMM YYYY",
                                                              ) ||
                                                                "DD MMMM YYYY"}
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <p className="activity-content-text pb-3">
                                                          {
                                                            comment
                                                              .content
                                                              .body
                                                          }
                                                        </p>
                                                      </div>
                                                      <div className="activity-stats">
                                                        <div className="activity-stat-item dropup">
                                                          <div
                                                            className="dropdown-toggle activity-stat-icon handicon"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            onClick={() =>
                                                              this.state.listReactions(
                                                                comment.id,
                                                              )
                                                            }>
                                                            <img
                                                              src={
                                                                likingIcon
                                                              }
                                                              alt="like-icon"
                                                              width="25px"
                                                            />
                                                          </div>
                                                          {Object.values(
                                                            comment.reactions,
                                                          )
                                                            .map(
                                                              (
                                                                reaction,
                                                              ) =>
                                                                parseInt(
                                                                  reaction.total,
                                                                  10,
                                                                ),
                                                            )
                                                            .reduce(
                                                              (
                                                                a,
                                                                b,
                                                              ) =>
                                                                a +
                                                                b,
                                                              0,
                                                            )}
                                                          <div className="dropdown-menu dropdown-menu-right">
                                                            <div className="reactions-icons justify-content-between">
                                                              <div className="d-flex">
                                                                <div className="activity-stat-item">
                                                                  <div
                                                                    className="activity-stat-icon"
                                                                    onClick={() =>
                                                                      this.createPostReaction(
                                                                        comment.id,
                                                                        "like",
                                                                        this
                                                                          .reactions &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ] &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ][
                                                                            profileData
                                                                              .data
                                                                              .id
                                                                          ] &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ][
                                                                            profileData
                                                                              .data
                                                                              .id
                                                                          ]
                                                                            .name,
                                                                      )
                                                                    }>
                                                                    <img
                                                                      src={
                                                                        this
                                                                          .reactions &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ] &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ][
                                                                          profileData
                                                                            .data
                                                                            .id
                                                                        ] &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ][
                                                                          profileData
                                                                            .data
                                                                            .id
                                                                        ]
                                                                          .name ===
                                                                          "like"
                                                                          ? likingIconOrange
                                                                          : likingIcon
                                                                      }
                                                                      alt="like-icon"
                                                                      width="25px"
                                                                    />
                                                                  </div>
                                                                  {(comment.reactions &&
                                                                    comment
                                                                      .reactions
                                                                      .like &&
                                                                    comment
                                                                      .reactions
                                                                      .like
                                                                      .total) ||
                                                                    0}
                                                                </div>
                                                                <div className="activity-stat-item">
                                                                  <div
                                                                    className="activity-stat-icon"
                                                                    onClick={() =>
                                                                      this.createPostReaction(
                                                                        comment.id,
                                                                        "celebrate",
                                                                        this
                                                                          .reactions &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ] &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ][
                                                                            profileData
                                                                              .data
                                                                              .id
                                                                          ] &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ][
                                                                            profileData
                                                                              .data
                                                                              .id
                                                                          ]
                                                                            .name,
                                                                      )
                                                                    }>
                                                                    <img
                                                                      src={
                                                                        this
                                                                          .reactions &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ] &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ][
                                                                          profileData
                                                                            .data
                                                                            .id
                                                                        ] &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ][
                                                                          profileData
                                                                            .data
                                                                            .id
                                                                        ]
                                                                          .name ===
                                                                          "celebrate"
                                                                          ? celebrateIconOrange
                                                                          : celebrateIcon
                                                                      }
                                                                      alt="celebrate-icon"
                                                                      width="25px"
                                                                    />
                                                                  </div>
                                                                  {(comment.reactions &&
                                                                    comment
                                                                      .reactions
                                                                      .celebrate &&
                                                                    comment
                                                                      .reactions
                                                                      .celebrate
                                                                      .total) ||
                                                                    0}
                                                                </div>
                                                                <div className="activity-stat-item">
                                                                  <div
                                                                    className="activity-stat-icon"
                                                                    onClick={() =>
                                                                      this.createPostReaction(
                                                                        comment.id,
                                                                        "support",
                                                                        this
                                                                          .reactions &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ] &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ][
                                                                            profileData
                                                                              .data
                                                                              .id
                                                                          ] &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ][
                                                                            profileData
                                                                              .data
                                                                              .id
                                                                          ]
                                                                            .name,
                                                                      )
                                                                    }>
                                                                    <img
                                                                      src={
                                                                        this
                                                                          .reactions &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ] &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ][
                                                                          profileData
                                                                            .data
                                                                            .id
                                                                        ] &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ][
                                                                          profileData
                                                                            .data
                                                                            .id
                                                                        ]
                                                                          .name ===
                                                                          "support"
                                                                          ? supportIconOrange
                                                                          : supportIcon
                                                                      }
                                                                      alt="support-icon"
                                                                      width="25px"
                                                                    />
                                                                  </div>
                                                                  {(comment.reactions &&
                                                                    comment
                                                                      .reactions
                                                                      .support &&
                                                                    comment
                                                                      .reactions
                                                                      .support
                                                                      .total) ||
                                                                    0}
                                                                </div>
                                                                <div className="activity-stat-item">
                                                                  <div
                                                                    className="activity-stat-icon"
                                                                    onClick={() =>
                                                                      this.createPostReaction(
                                                                        comment.id,
                                                                        "love",
                                                                        this
                                                                          .reactions &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ] &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ][
                                                                            profileData
                                                                              .data
                                                                              .id
                                                                          ] &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ][
                                                                            profileData
                                                                              .data
                                                                              .id
                                                                          ]
                                                                            .name,
                                                                      )
                                                                    }>
                                                                    <img
                                                                      src={
                                                                        this
                                                                          .reactions &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ] &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ][
                                                                          profileData
                                                                            .data
                                                                            .id
                                                                        ] &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ][
                                                                          profileData
                                                                            .data
                                                                            .id
                                                                        ]
                                                                          .name ===
                                                                          "love"
                                                                          ? loveIconOrange
                                                                          : loveIcon
                                                                      }
                                                                      alt="love-icon"
                                                                      width="25px"
                                                                    />
                                                                  </div>
                                                                  {(comment.reactions &&
                                                                    comment
                                                                      .reactions
                                                                      .love &&
                                                                    comment
                                                                      .reactions
                                                                      .love
                                                                      .total) ||
                                                                    0}
                                                                </div>
                                                                <div className="activity-stat-item">
                                                                  <div
                                                                    className="activity-stat-icon"
                                                                    onClick={() =>
                                                                      this.createPostReaction(
                                                                        comment.id,
                                                                        "insightful",
                                                                        this
                                                                          .reactions &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ] &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ][
                                                                            profileData
                                                                              .data
                                                                              .id
                                                                          ] &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ][
                                                                            profileData
                                                                              .data
                                                                              .id
                                                                          ]
                                                                            .name,
                                                                      )
                                                                    }>
                                                                    <img
                                                                      src={
                                                                        this
                                                                          .reactions &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ] &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ][
                                                                          profileData
                                                                            .data
                                                                            .id
                                                                        ] &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ][
                                                                          profileData
                                                                            .data
                                                                            .id
                                                                        ]
                                                                          .name ===
                                                                          "insightful"
                                                                          ? insightfulIconOrange
                                                                          : insightfulIcon
                                                                      }
                                                                      alt="insightful-icon"
                                                                      width="25px"
                                                                    />
                                                                  </div>
                                                                  {(comment.reactions &&
                                                                    comment
                                                                      .reactions
                                                                      .insightful &&
                                                                    comment
                                                                      .reactions
                                                                      .insightful
                                                                      .total) ||
                                                                    0}
                                                                </div>
                                                                <div className="activity-stat-item">
                                                                  <div
                                                                    className="activity-stat-icon"
                                                                    onClick={() =>
                                                                      this.createPostReaction(
                                                                        comment.id,
                                                                        "curious",
                                                                        this
                                                                          .reactions &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ] &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ][
                                                                            profileData
                                                                              .data
                                                                              .id
                                                                          ] &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ][
                                                                            profileData
                                                                              .data
                                                                              .id
                                                                          ]
                                                                            .name,
                                                                      )
                                                                    }>
                                                                    <img
                                                                      src={
                                                                        this
                                                                          .reactions &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ] &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ][
                                                                          profileData
                                                                            .data
                                                                            .id
                                                                        ] &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ][
                                                                          profileData
                                                                            .data
                                                                            .id
                                                                        ]
                                                                          .name ===
                                                                          "curious"
                                                                          ? curiousIconOrange
                                                                          : curiousIcon
                                                                      }
                                                                      alt="curious-icon"
                                                                      width="25px"
                                                                    />
                                                                  </div>
                                                                  {(comment.reactions &&
                                                                    comment
                                                                      .reactions
                                                                      .curious &&
                                                                    comment
                                                                      .reactions
                                                                      .curious
                                                                      .total) ||
                                                                    0}
                                                                </div>
                                                                <div className="activity-stat-item">
                                                                  <div
                                                                    className="activity-stat-icon"
                                                                    onClick={() =>
                                                                      this.createPostReaction(
                                                                        comment.id,
                                                                        "dislike",
                                                                        this
                                                                          .reactions &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ] &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ][
                                                                            profileData
                                                                              .data
                                                                              .id
                                                                          ] &&
                                                                          this
                                                                            .reactions[
                                                                            comment
                                                                              .id
                                                                          ][
                                                                            profileData
                                                                              .data
                                                                              .id
                                                                          ]
                                                                            .name,
                                                                      )
                                                                    }>
                                                                    <img
                                                                      src={
                                                                        this
                                                                          .reactions &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ] &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ][
                                                                          profileData
                                                                            .data
                                                                            .id
                                                                        ] &&
                                                                        this
                                                                          .reactions[
                                                                          comment
                                                                            .id
                                                                        ][
                                                                          profileData
                                                                            .data
                                                                            .id
                                                                        ]
                                                                          .name ===
                                                                          "dislike"
                                                                          ? dislikeIconOrange
                                                                          : dislikeIcon
                                                                      }
                                                                      alt="dislike-icon"
                                                                      width="25px"
                                                                    />
                                                                  </div>
                                                                  {(comment.reactions &&
                                                                    comment
                                                                      .reactions
                                                                      .dislike &&
                                                                    comment
                                                                      .reactions
                                                                      .dislike
                                                                      .total) ||
                                                                    0}
                                                                </div>
                                                                <div
                                                                  className="reactions-modal-arrow d-flex align-items-center"
                                                                  data-toggle="modal"
                                                                  data-target=".reactions-modal"
                                                                  onClick={() =>
                                                                    this.setState(
                                                                      {
                                                                        selectedPost:
                                                                          comment.id,
                                                                      },
                                                                    )
                                                                  }>
                                                                  <ArrowForwardIos
                                                                    style={{
                                                                      color:
                                                                        "#C6A177",
                                                                    }}
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ),
                                            )}
                                        </div>

                                        <div className="post-comment">
                                          <form
                                            className="form"
                                            onSubmit={(e) =>
                                              this.submitPost(
                                                e,
                                                profileData
                                                  .data.id,
                                                postItem.id,
                                                "comment",
                                                postItem.clubId,
                                              )
                                            }>
                                            <div className="post-form-row">
                                              <div className="post-item-author">
                                                <div className="post-avatar">
                                                  {profileData
                                                    .data
                                                    .profile
                                                    .picture && (
                                                    <img
                                                      className="img-fluid"
                                                      src={
                                                        profileData
                                                          .data
                                                          .profile
                                                          .picture
                                                      }
                                                      alt="club-avatar"
                                                    />
                                                  )}
                                                  {!profileData
                                                    .data
                                                    .profile
                                                    .picture && (
                                                    <div className="member-initials">
                                                      {profileData
                                                        .data
                                                        .profile
                                                        .firstName[0] +
                                                        profileData
                                                          .data
                                                          .profile
                                                          .lastName[0]}
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                              <div className="input-group mb-2">
                                                <textarea
                                                  rows="1"
                                                  name="postComment"
                                                  className="form-control"
                                                  placeholder="Add a comment"
                                                  value={
                                                    postComment[
                                                      postItem
                                                        .id
                                                    ] || ""
                                                  }
                                                  onChange={(
                                                    e,
                                                  ) =>
                                                    this.handleChangeComment(
                                                      e,
                                                      postItem.id,
                                                    )
                                                  }
                                                  required
                                                />
                                              </div>
                                            </div>
                                            <div className="post-buttons">
                                              <div className="cancel-button">
                                                <button
                                                  type="button"
                                                  className="btn btn-sm submit-btn btn-reverse"
                                                  disabled={
                                                    false
                                                  }
                                                  onClick={() => {}}>
                                                  {getLabelByName(
                                                    CANCEL,
                                                  )}
                                                </button>
                                              </div>
                                              <div className="post-button">
                                                <button
                                                  type="submit"
                                                  className="btn btn-sm submit-btn"
                                                  disabled={
                                                    isPosting ===
                                                    postItem.id
                                                  }>
                                                  {isPosting ===
                                                  postItem.id ? (
                                                    <>
                                                      {`${getLabelByName(
                                                        REPLY,
                                                      )}...`}{" "}
                                                      <Loader />
                                                    </>
                                                  ) : (
                                                    getLabelByName(
                                                      REPLY,
                                                    )
                                                  )}
                                                </button>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ),
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
        {/* Reactions Modal */}
        <ReactionsModal
          reactions={
            selectedPost &&
            this.reactions &&
            this.reactions[selectedPost]
          }
        />
        <div className="footer-block">
          <div className="footer-component">
            <Footer />
          </div>
        </div>
      </>
    );
  }
}

Lesson.propTypes = {
  getPost: PropTypes.func,
  getSessionAction: PropTypes.func,
  createPost: PropTypes.func,
  postReaction: PropTypes.func,
  deleteReaction: PropTypes.func,
  getReactions: PropTypes.func,

  match: PropTypes.any,
  history: PropTypes.any,
  location: PropTypes.any,
  data: PropTypes.any,
  status: PropTypes.any,

  createPostData: PropTypes.any,
  createPostStatus: PropTypes.any,
  getPostData: PropTypes.any,
  postReactionData: PropTypes.object,
  postReactionStatus: PropTypes.string,
  deleteReactionStatus: PropTypes.string,
  getReactionsData: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,

  createPostData: state.createPostReducer.createPostData,
  createPostDataError:
    state.createPostReducer.createPostDataError,
  createPostStatus:
    state.createPostReducer.createPostStatus,

  getPostData: state.getPostReducer.getPostData,
  getPostDataError: state.getPostReducer.getPostDataError,
  getPostStatus: state.getPostReducer.getPostStatus,

  postReactionDataError:
    state.postReactionReducer.postReactionDataError,
  postReactionData:
    state.postReactionReducer.postReactionData,
  postReactionStatus:
    state.postReactionReducer.postReactionStatus,

  deleteReactionDataError:
    state.deleteReactionReducer.deleteReactionDataError,
  deleteReactionData:
    state.deleteReactionReducer.deleteReactionData,
  deleteReactionStatus:
    state.deleteReactionReducer.deleteReactionStatus,

  getReactionsData:
    state.getReactionsReducer.getReactionsData,
  getReactionsDataError:
    state.getReactionsReducer.getReactionsDataError,
  getReactionsStatus:
    state.getReactionsReducer.getReactionsStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getSessionAction,
    getPost,
    createPost,
    getReactions,
    deleteReaction,
    postReaction,
  }),
)(Lesson);
