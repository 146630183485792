import React, { useEffect } from "react";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search-orange.svg";

const CourseSidebar = ({ isOpen, selectLesson, courseProgress, action, slides, getSlideIsComplete }) => {
  return (
    <aside className={isOpen ? "" : "is-closed"}>
      <div className="wrapper">
        <div className="name-of-action">
          <div className="desc">
            <span>{action.name}</span>
          </div>
        </div>

        <div className="lessons-wrapper">
          {slides.map((slide, slideIndex) => (
            <div className="lesson-item" key={slide?.id} onClick={() => selectLesson(slideIndex)}>
              <div className="checkbox-wrapper">
                <input type="checkbox" checked={getSlideIsComplete(slide)} disabled />
              </div>
              <div className="desc">
                <p>Slide {slideIndex + 1}:</p>
                <p>{slide?.content?.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </aside>
  );
};

export default CourseSidebar;
