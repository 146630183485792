import React, { useState } from "react";

const DatePickerInput = (props) => {
    const { settings, currentValue, participantAction, handleUpdate, isPreviewMode } = props;
    const inputType = settings.options[0]?.allow_time ? 'datetime-local' : 'date';
 
    let selectedDates;
    
    if (!currentValue) {
        selectedDates = {
            [`${settings.input_key}_start`]: Date(),
            [`${settings.input_key}_end`]: Date(),
        }
    } else {
        selectedDates = JSON.parse(currentValue);
    }

    const handleChange = (e) => {
        handleUpdate({
            [settings.input_key]: JSON.stringify({
                ...selectedDates,
                [e.target.name]: e.target.value,
            })
        });
    }

    return (
        <div className="card section-card article-card p-0">
            <div className="card-body">
            <div className="card-details">
                <h5 className="card-title">{settings.label} {settings.required ? ' (required)' : null}</h5>
                
                <div className="row ml-0">
                    <div className="col-md-6">
                        <div className="help-text mb-3">Start Date:</div>
                        <div className="">
                            <input
                                type={inputType}
                                className="form-control"
                                name={`${settings.input_key}_start`}
                                max={selectedDates[`${settings.input_key}_end`] || ""}
                                value={selectedDates[`${settings.input_key}_start`]}
                                onChange={handleChange}
                                disabled={isPreviewMode}
                            />
                        </div>
                    </div>

                    {settings.type === "DATE_TIME_RANGE" &&
                        <div className="col-md-6">
                            <div className="help-text mb-3">End Date:</div>
                            <div className="">
                                <input
                                    type={inputType}
                                    className="form-control"
                                    name={`${settings.input_key}_end`}
                                    min={selectedDates[`${settings.input_key}_start`]}
                                    value={selectedDates[`${settings.input_key}_end`]}
                                    onChange={handleChange}
                                    disabled={isPreviewMode}
                                />
                            </div>
                        </div>
                    }

                </div>
            </div>
            </div>
        </div>
    )
}

export default DatePickerInput;