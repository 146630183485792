/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
import "react-autocomplete-input/dist/bundle.css";
import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import { compose } from "redux";
import { toast } from "react-toastify";
import { useParams, withRouter } from "react-router-dom";
import TextInput from "react-autocomplete-input";
import {
  ACTION,
  ACTIVITY,
  COMPLETE,
  outputParams,
  SAVING,
} from "../../../../../../helpers/constants";
import { getLabelByName } from "../../../../../../helpers/helperFunctions";
import {
  createOutput,
  updateOutput,
  cloneOutput,
  getOutputs,
} from "../../../../../../redux/actions/contributionsActions";
import { getQuestionnaires } from "../../../../../../redux/actions/campaignsActions";
import {
  useWizardContext,
  WIZARD_CONSTANTS,
} from "../wizardContextProvider";

import "./outputSourcesForm.scss";

const OutputSourcesForm = ({
  closeModal,
  getOutputsReq,
  createOutputReq,
  updateOutputReq,
  cloneOutputReq,
  getQuestionnairesReq,
  clubActivitiesData,
  getQuestionnairesData,
}) => {
  const [expanded, setExpanded] = useState();
  const [sourceTypeIndex, setSourceTypeIndex] = useState();
  const [optnInputs, setOptnInputs] = useState([]);
  const [questionnaireIds, setQuestionnaireIds] = useState(
    [],
  );

  const { id: clubId } = useParams();

  const [wizardState, dispatch] = useWizardContext();

  const { type, outputSources, isLoading } = wizardState;

  const getActivityData = (id) => {
    const result = clubActivitiesData?.data?.find(
      (activity) => activity.id === id,
    );

    return result;
  };

  const getActionData = (actionId, activityData) => {
    return activityData?.actions.find(
      (action) => action.id === actionId,
    );
  };

  const outputSourcesWithActivityData = outputSources.map(
    (source) => {
      const activityData = getActivityData(
        source.activityId,
      );

      return {
        ...source,
        activityData,
        actionData: getActionData(
          source.actionId,
          activityData,
        ),
      };
    },
  );

  const loading = (value) =>
    dispatch({
      type: WIZARD_CONSTANTS.LOADING,
      payload: value,
    });

  useEffect(() => {
    if (outputSourcesWithActivityData.length > 0) {
      const newQuestionnaireIds =
        outputSourcesWithActivityData.map(
          (source) =>
            source?.actionData?.meta?.questionnaireId,
        );

      setQuestionnaireIds(newQuestionnaireIds);
    }

    const query = `?q={"id":{"_in":[${questionnaireIds}]}}&questions=true`;

    getQuestionnairesReq(query);
  }, []);

  const handleRequests = async ({
    outputData,
    payload,
    isEdit,
    isClone,
  }) => {
    if (isEdit) {
      const response = await updateOutputReq(
        clubId,
        outputData.id,
        payload,
      );

      if (response.status === 200) {
        toast.success("Output Created Successfully");

        getOutputsReq(`?q={"clubId":${clubId}}`);

        closeModal();
      } else {
        toast.error("An error occured!");
      }
    } else if (isClone) {
      const response = await cloneOutputReq(
        outputData?.club.id,
        outputData.id,
        {
          ...payload,
          clubId,
          description: wizardState.description,
        },
      );

      if (response.status === 200) {
        toast.success("Output Cloned Successfully");

        getOutputsReq(`?q={"clubId":${clubId}}`);

        closeModal();
      } else {
        toast.error("An error occured!");
      }
    } else {
      const response = await createOutputReq(
        clubId,
        payload,
      );

      if (response.status === 200) {
        toast.success("Output Created Successfully");

        getOutputsReq(`?q={"clubId":${clubId}}`);

        closeModal();
      } else {
        toast.error("An error occured!");
      }
    }
  };

  const handleSubmitOutput = async () => {
    const {
      name,
      meta,
      isEdit,
      isClone,
      description,
      visibility,
      status,
      globalSettings,
      selectedFile,
      outputData,
    } = wizardState;

    const sources = {};

    outputSources.forEach((source) => {
      sources[source.name] = source;
    });

    const payload = {
      name,
      type,
      meta,
      visibility,
      status,
      settings: {
        title: name,
        description,
        // cover_image,
        ...globalSettings,
      },
      sources,
    };

    try {
      loading(true);

      if (!selectedFile) {
        await handleRequests({
          outputData,
          payload,
          isEdit,
          isClone,
        });

        loading(false);
      } else {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("upload_preset", "qtt2g8ao");
        const options = {
          method: "POST",
          body: formData,
        };

        const imgResponse = await fetch(
          "https://api.Cloudinary.com/v1_1/ayudigital/auto/upload",
          options,
        );

        const { secure_url } = await imgResponse.json();

        payload.settings.cover_image = secure_url;

        await handleRequests({
          outputData,
          payload,
          isEdit,
          isClone,
        });

        loading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Error creating output!\nPlease try again.",
      );
    }
  };

  const handleOutputSourceChange = (inputVlaue, key, i) => {
    dispatch({
      type: WIZARD_CONSTANTS.HANDLE_SOURCE_CHANGE,
      payload: {
        name: key,
        value: inputVlaue,
        index: i,
        isFromOutputSettings: true,
      },
    });
  };

  const getOutputSourceName = (source) =>
    outputParams[type].source_settings[
      source.toLowerCase()
    ];

  const handlePanelChange =
    (panel, source, index) => (event, isExpanded) => {
      const optionInputs = [];

      if (getQuestionnairesData?.data?.length > 0) {
        getQuestionnairesData.data.forEach(
          (questionnaireData) => {
            if (
              Number(questionnaireData.id) ===
                Number(
                  source.actionData?.meta?.questionnaireId,
                ) &&
              questionnaireData?.questions?.length > 0
            ) {
              questionnaireData.questions.forEach(
                (question) => {
                  if (
                    question.settings &&
                    question.settings !== null
                  ) {
                    if (
                      typeof question.settings === "string"
                    ) {
                      optionInputs.push(
                        JSON.parse(question.settings)
                          .responses,
                      );
                    }
                    optionInputs.push(
                      question.settings.responses,
                    );
                  }
                },
              );
            }
          },
        );
      }

      const newOptionInputs = [
        "participant_firstName",
        "participant_lastName",
        "participant_gender",
        "participant_location_city",
        "participant_location_country",
        "participant_picture",
      ];

      if (optionInputs.length > 0 && optionInputs[0]) {
        optionInputs[0].forEach((option) => {
          newOptionInputs.push(option.input_key);
        });
      }

      setExpanded(isExpanded ? panel : false);
      setOptnInputs(newOptionInputs);
      setSourceTypeIndex(index);
    };

  const selectedInputKey = (key) => {
    let currentValue;

    const outputSoucesSettings =
      outputSources[sourceTypeIndex]?.settings;

    if (outputSoucesSettings) {
      currentValue = Object.entries(
        outputSoucesSettings,
      ).find((setting) => setting[0] === key);
    }

    return currentValue
      ? { key: currentValue[0], value: currentValue[1] }
      : undefined;
  };

  return (
    <>
      <div className="output-settings px-4 min-height-500">
        {outputSourcesWithActivityData.length > 0
          ? outputSourcesWithActivityData.map(
              (outputSource, index) => (
                <div
                  className="settings-accordion"
                  key={index}>
                  <Accordion
                    className={`${
                      !expanded
                        ? "box-shadow-none"
                        : undefined
                    }`}
                    expanded={expanded === `panel${index}`}
                    onChange={handlePanelChange(
                      `panel${index}`,
                      outputSource,
                      index,
                    )}>
                    <AccordionSummary
                      className="accordion-summary"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Typography className="output-settings-activity">
                        <span className="color-grayish pr-10">
                          {getLabelByName(ACTIVITY)}:
                        </span>
                        {outputSource?.activityData?.name}
                      </Typography>

                      <Typography className="output-settings-action">
                        <span className="color-grayish pr-10">
                          {getLabelByName(ACTION)}:
                        </span>
                        {outputSource?.actionData?.name}
                      </Typography>
                    </AccordionSummary>

                    {getOutputSourceName(
                      outputSource.name,
                    )?.map((sourceObj, idx) => (
                      <AccordionDetails key={idx}>
                        <FormGroup
                          row
                          className="form-group-row">
                          <div className="source-settings-details">
                            <div className="setting-label">
                              <h1>{sourceObj.name}</h1>
                            </div>

                            <TextInput
                              trigger=":"
                              maxOptions={0}
                              Component="input"
                              onChange={(val) =>
                                handleOutputSourceChange(
                                  val,
                                  sourceObj.key,
                                  index,
                                )
                              }
                              name={sourceObj.key}
                              value={
                                selectedInputKey(
                                  sourceObj.key,
                                )?.value
                              }
                              options={
                                optnInputs?.length > 0
                                  ? optnInputs
                                  : []
                              }
                            />
                          </div>
                        </FormGroup>
                      </AccordionDetails>
                    ))}
                  </Accordion>
                </div>
              ),
            )
          : null}
      </div>

      <div>
        <hr className="border-separator" />

        <div className="create-outputs-buttons">
          <input
            type="button"
            value="Cancel"
            className="cancel-button"
            onClick={closeModal}
          />

          <button
            type="button"
            onClick={() => handleSubmitOutput()}
            className="btn btn-lg submit-btn">
            {isLoading ? (
              <>
                <span>
                  {getLabelByName(SAVING)}
                  {"  "}
                </span>
                <div
                  className="spinner-border spinner-border-sm"
                  role="status">
                  <span className="sr-only">
                    Loading...
                  </span>
                </div>
              </>
            ) : (
              getLabelByName(COMPLETE)
            )}
          </button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  getQuestionnairesData:
    state.getQuestionnairesReducer.getQuestionnairesData,
  clubActivitiesData:
    state.clubCampaignsReducer.clubCampaignsData,
});

const mapDispatchToProps = {
  getOutputsReq: getOutputs,
  createOutputReq: createOutput,
  updateOutputReq: updateOutput,
  cloneOutputReq: cloneOutput,
  getQuestionnairesReq: getQuestionnaires,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OutputSourcesForm);
