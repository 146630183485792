/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prefer-stateless-function */
import React, { useEffect, useState } from "react";
import {
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import { Clear } from "@material-ui/icons";
import { compose } from "redux";
import PromotionsTable from "./PromotionsTable";
import StatusDropdown from "./StatusDropdown";
import { ReactComponent as OutputHamburger } from "../../../assets/icons/output-hamburger.svg";
import searchOrange from "../../../assets/icons/search-orange.svg";
import { SEARCH } from "../../../helpers/constants";
import PromotionsNav from "./PromotionsNav";
import { getOutputs } from "../../../redux/actions/contributionsActions";
import { getAdminClubs } from "../../../redux/actions/institutesActions";
import { getAdminActivities } from "../../../redux/actions/campaignsActions";
import { getNewsFeed } from "../../../redux/actions/postActions";
import { getLabelByName } from "../../../helpers/helperFunctions";

import "./Promotions.scss";
import PaginationRow from "../PaginationRow";

const Promotions = (props) => {
  const [state, setState] = useState({
    outputsData: [],
  });
  const [searchValue, setSearchValue] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 1,
    nbPages: 1,
    pageDivider: 10,
    filteredData: [],
  });

  const setPaginationState = (incomingState) => {
    setPagination((prevState) => ({
      ...prevState,
      ...incomingState,
    }));
  };

  const resetPaginationState = () => {
    setPaginationState({
      currentPage: 1,
      nbPages: 1,
      pageDivider: 10,
      filteredData: [],
    });

    console.log("RESET PAGINATION STATE");
  };

  const handleSearchChange = (e) => {};

  const clearSearch = (e) => {};

  const match = useRouteMatch();

  const category = match.params.category;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (category) {
      resetPaginationState();

      switch (category) {
        case "all":
          props.getAdminActivitiesReq();
          props.getOutputsReq();
          props.getOutputsReq();
          props.getNewsFeedReq();
          break;

        case "activities":
          props.getAdminActivitiesReq();
          break;

        case "clubs":
          props.getAdminClubsReq();
          break;

        case "outputs":
          props.getOutputsReq();
          break;

        case "posts":
          props.getNewsFeedReq();
          break;

        default:
          break;
      }
    }
  }, [match, category]);

  const allData = [
    ...(props?.institutesData?.data || []),
    ...(props?.getActivitiesData?.data || []),
    ...(props?.getNewsFeedData?.data || []),
    ...(props?.getOutputsData?.data || []),
  ];

  useEffect(() => {
    const {
      getNewsFeedData,
      getActivitiesData,
      getOutputsData,
      institutesData,
    } = props;

    const resourceData = {
      all: allData,
      posts: getNewsFeedData?.data,
      activities: getActivitiesData?.data,
      outputs: getOutputsData?.data,
      clubs: institutesData?.data,
    };

    if (category && resourceData[category]) {
      setPaginationState({
        filteredData: resourceData[category],
        nbPages: Math.ceil(
          resourceData[category]?.length /
            pagination.pageDivider,
        ),
      });
    }
  }, [
    category,
    props.getNewsFeedData,
    props.getActivitiesData,
    props.getOutputsData,
    props.institutesData,
  ]);

  const totalDataLength = allData.length || 0;

  const total = {
    all: totalDataLength,
    clubs: props?.institutesData?.data?.length,
    activities: props?.getActivitiesData?.data?.length,
    posts: props?.getNewsFeedData?.data?.length,
    outputs:
      state.outputsData.length ||
      props?.getOutputsData?.data?.length,
  };

  const setCurrentPage = (currentPage) =>
    setPaginationState({ currentPage });

  const incrementPage = () => {
    let { nbPages, currentPage } = pagination;
    currentPage =
      currentPage === nbPages
        ? currentPage
        : currentPage + 1;
    setPaginationState({ currentPage });
  };

  const decrementPage = () => {
    let { currentPage } = pagination;
    currentPage =
      currentPage === 1 ? currentPage : currentPage - 1;
    setPaginationState({ currentPage });
  };

  const searchComponent = () => {
    return (
      <form className="form" onSubmit={() => {}}>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            id="inputSearch"
            placeholder={`${getLabelByName(
              SEARCH,
            )} ${category?.toLowerCase()}`}
            name="searchValue"
            value={searchValue}
            onChange={handleSearchChange}
            style={{ height: "32px" }}
          />
          {searchValue && (
            <div
              className="input-group-append"
              style={{ height: "32px" }}>
              <button
                id="clear-search-btn"
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => clearSearch()}>
                <Clear style={{ fontSize: "16px" }} />
              </button>
            </div>
          )}
          <div
            className="input-group-append"
            style={{ height: "32px" }}>
            <button
              // disabled={!this.state.searchValue}
              id="search-btn"
              className="btn btn-outline-secondary"
              type="submit"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <img
                src={searchOrange}
                alt="search-icon"
                style={{ width: "12px" }}
              />
            </button>
          </div>
        </div>
      </form>
    );
  };

  const getTitle = (category) => {
    return (
      <div className="table-actions__gettitle">
        <div className="output-title d-flex align-items-center gap-15">
          <p>
            <OutputHamburger />

            <strong className="ml-1 mr-2 text-capitalize">
              {category}
            </strong>
            <span>{total[category]}</span>
          </p>
        </div>
      </div>
    );
  };

  const getActions = () => {
    return (
      <div className="table-actions__actions d-flex align-items-center gap-15">
        {searchComponent()}

        <StatusDropdown
          dropDownValue={props.dropDownValue}
          handleChange={props.handleDropdownChange}
        />
      </div>
    );
  };

  const renderTable = () => {
    switch (category) {
      case "all":
        return (
          <>
            <div className="py-3 px-4 d-flex align-items-center justify-content-between table-header border-bottom">
              {getTitle("All Promotions")}

              {getActions()}
            </div>

            <PromotionsTable
              category={category}
              allData={allData
                .slice(
                  (pagination.currentPage - 1) *
                    pagination.pageDivider,
                  pagination.currentPage *
                    pagination.pageDivider,
                )
                .map((item) => item)}
            />
          </>
        );
        break;

      case "posts":
        return (
          <>
            <div className="py-3 px-4 d-flex align-items-center justify-content-between table-header border-bottom">
              {getTitle("posts")}

              {getActions()}
            </div>

            <PromotionsTable
              category={category}
              newsFeedData={props.getNewsFeedData?.data
                ?.slice(
                  (pagination.currentPage - 1) *
                    pagination.pageDivider,
                  pagination.currentPage *
                    pagination.pageDivider,
                )
                .map((item) => item)}
            />
          </>
        );
        break;

      case "outputs":
        return (
          <>
            <div className="py-3 px-4 d-flex align-items-center justify-content-between table-header border-bottom">
              {getTitle("outputs")}

              {getActions()}
            </div>

            <PromotionsTable
              category={category}
              outputsData={props.getOutputsData?.data
                ?.slice(
                  (pagination.currentPage - 1) *
                    pagination.pageDivider,
                  pagination.currentPage *
                    pagination.pageDivider,
                )
                .map((item) => item)}
            />
          </>
        );
        break;

      case "activities":
        return (
          <>
            <div className="py-3 px-4 d-flex align-items-center justify-content-between table-header border-bottom">
              {getTitle("activities")}

              {getActions()}
            </div>

            <PromotionsTable
              category={category}
              activitiesData={props.getActivitiesData?.data
                ?.slice(
                  (pagination.currentPage - 1) *
                    pagination.pageDivider,
                  pagination.currentPage *
                    pagination.pageDivider,
                )
                .map((item) => item)}
            />
          </>
        );
        break;

      case "clubs":
        return (
          <>
            <div className="py-3 px-4 d-flex align-items-center justify-content-between table-header border-bottom">
              {getTitle("clubs")}

              {getActions()}
            </div>

            <PromotionsTable
              category={category}
              institutesData={props.institutesData?.data
                ?.slice(
                  (pagination.currentPage - 1) *
                    pagination.pageDivider,
                  pagination.currentPage *
                    pagination.pageDivider,
                )
                .map((item) => item)}
            />
          </>
        );
        break;

      default:
        return null;
    }
  };

  return (
    <div className="manage-web-content">
      <PromotionsNav />

      <div className="table-block pt-1 promotions-table">
        {renderTable()}
      </div>

      {pagination.filteredData && (
        <PaginationRow
          incrementPage={incrementPage}
          decrementPage={decrementPage}
          nbPages={pagination.nbPages}
          currentPage={pagination.currentPage}
          dataLength={pagination.filteredData.length}
          pageDivider={pagination.pageDivider}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  // ------------------------OUTPUTS-------------------------------------------
  getOutputsData: state.getOutputsReducer.getOutputsData,
  getOutputsDataError:
    state.getOutputsReducer.getOutputsDataError,
  getOutputsStatus:
    state.getOutputsReducer.getOutputsStatus,

  // ------------------------NEWSFEED-------------------------------------------

  getNewsFeedDataError:
    state.getNewsFeedReducer.getNewsFeedDataError,
  getNewsFeedData: state.getNewsFeedReducer.getNewsFeedData,
  getNewsFeedStatus:
    state.getNewsFeedReducer.getNewsFeedStatus,

  // ------------------------ACTIVITIES-------------------------------------------
  getActivitiesDataError:
    state.getActivitiesReducer.getActivitiesDataError,
  getActivitiesData:
    state.getActivitiesReducer.getActivitiesData,
  getActivitiesStatus:
    state.getActivitiesReducer.getActivitiesStatus,

  // ------------------------CLUBS-------------------------------------------
  institutesDataError:
    state.institutesReducer.institutesDataError,
  institutesData: state.institutesReducer.institutesData,
  institutesStatus:
    state.institutesReducer.institutesStatus,
});

const mapDispatchToProps = {
  getAdminActivitiesReq: getAdminActivities,
  getAdminClubsReq: getAdminClubs,
  getNewsFeedReq: getNewsFeed,
  getOutputsReq: getOutputs,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Promotions);
