import React from "react";

const ClubList = ({
  clubsData,
  selectedClubs,
  handleSelectClub,
  handleUnSelectClub,
}) => {
  return (
    <div className="club-list-wrapper px-2">
      <div className="clubs">
        {clubsData?.map((club) => (
          <div className="club-list-item">
            <div className="d-flex align-items-center">
              <img
                className="club-avatar"
                src={club?.profile?.picture}
                alt="Club Avatar"
              />

              <p className="club-name ml-3 mb-0">
                {club.name}
              </p>
            </div>

            <div className="d-flex align-items-center">
              <span className="d-block info-text">
                Assign to this club
              </span>

              <button
                onClick={() => {
                  const isSelected = selectedClubs.includes(
                    club.id,
                  );

                  isSelected
                    ? handleUnSelectClub(club.id)
                    : handleSelectClub(club.id);
                }}
                type="button"
                className={`club-assign-btn ${
                  selectedClubs.includes(club.id)
                    ? "selected"
                    : ""
                }`}>
                Assign
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClubList;
