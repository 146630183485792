import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import {GlossaryMain} from '../GlossaryCard/GlossaryMain';
import {GlossaryList} from '../GlossaryCard/GlossaryList';

// eslint-disable-next-line react/prefer-stateless-function
export class PrivacyPolicy extends Component {

  render() {
    const { handleChange, policyInfo } = this.props;

    const policyInfoMain = policyInfo?.data?.main ? JSON.parse(policyInfo.data.main) : {};
    const policyInfoList = policyInfo?.data?.list ? JSON.parse(policyInfo.data.list) : [];

    return (
      <>
        <GlossaryMain
          prevData={policyInfoMain}
          handleChange={handleChange}
          headerLabel="Message"
          textLabel="Text"
        />

        <GlossaryList
          glossaryList={policyInfoList}
          handleChange={handleChange}
          headerLabel="Policy"
          titleLabel="Title"
          textLabel="Text"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(PrivacyPolicy);
