import {
  DELETE_ACTION_SUCCESS,
  DELETE_ACTION_ERROR,
} from "../actions/types";

const initialState = {
  deleteActionData: null,
  deleteActionDataError: null,
  deleteActionStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DELETE_ACTION_SUCCESS:
      return {
        ...state,
        deleteActionData: action.payload,
        deleteActionDataError: null,
        deleteActionStatus: "success",
      };
    case DELETE_ACTION_ERROR:
      return {
        ...state,
        deleteActionDataError: action.payload,
        deleteActionData: null,
        deleteActionStatus: "error",
      };
    default:
      return {
        ...state,
        deleteActionStatus: null,
      };
  }
};
