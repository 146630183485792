import {
  GET_ACTIVITIES_SUCCESS, GET_ACTIVITIES_ERROR,
} from '../actions/types';

const initialState = {
  getActivitiesData: null,
  getActivitiesDataError: null,
  getActivitiesStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        getActivitiesData: action.payload,
        getActivitiesDataError: null,
        getActivitiesStatus: 'success',
      };
    case GET_ACTIVITIES_ERROR:
      return {
        ...state,
        getActivitiesDataError: action.payload,
        getActivitiesData: null,
        getActivitiesStatus: 'error',
      };
    default:
      return {
        ...state,
        getActivitiesStatus: null,
      };
  }
};
