import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { FiberManualRecord } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import moment from "moment";
import { toast } from "react-toastify";
import Navbar from "../Navbar";
import {
  getActivity,
  updateActivity,
  getParticipants,
  getClubQuestionnaires,
  addMemberToActivityAction,
  getParticipantAllActions,
  getQuestionnaires,
} from "../../redux/actions/campaignsActions";
import { getSessionAction } from "../../redux/actions/authActions";
import { getContributions } from "../../redux/actions/contributionsActions";
import { getInstitute } from "../../redux/actions/institutesActions";
import "./CampaignDetails.css";
import ActivityDetails from "../generics/Activity/ActivityDetailsSection/ActivityDetails";
import ActivityLinksForPrticipant, {
  ActivityLinks,
} from "../generics/Activity/ActivityLinks/ActivityLinks";
import logo from "../../assets/logo-opac.svg";
import {
  checkSession,
  getLabelByName,
  getProfileDataFromLocalStorage,
} from "../../helpers/helperFunctions";
import {
  colors,
  PARTICIPANTS,
  REVIEW,
  STATUS,
  ADD_PARTICIPANT_SUCCESS_MESSAGE,
  EDITING,
  EDIT,
  CONTRIBUTIONS,
  ANONYMOUS,
  ADVANCED_SETTINGS,
  ACTIVITY_SETTINGS_DESCRIPTION,
  HOW_TO_PARTICIPATE,
  HOW_TO_PARTICIPATE_DESCRIPTION,
  PARTICIPANT_DESCRIPTION,
  CONTRIBUTION_DESCRIPTION,
  CREATED_ON,
} from "../../helpers/constants";
import Fallback from "../Fallback";
import NotFound from "../NotFound";
import Footer from "../Footer";
import Loader from "../Loader";

class ActivityDetailsPage extends Component {
  state = {
    activityData: null,
    otherInfoChanged: null,
    newHandle: null,
    selectedContribution: null,

    nbPages: 1,
    currentPage: 1,
    pageDivider: 5,

    isModalOpen: false,
    participants: [],

    isButtonLoading: false,

    contributionStatuses: {
      PENDING_REVIEW: "Pending Review",
      APPROVED: "Approved",
      REJECTED: "Rejected",
    },
    statusColor: {
      PENDING_REVIEW: colors.primaryColor,
      APPROVED: "#10A728",
      REJECTED: "#FF0D0D",
    },
    editAction: null,
    isCreateModalVisible: false,
    isEditing: false,
    editActivity: null,
    contributionModalOpen: false,
    profileData: getProfileDataFromLocalStorage(),
    pathname: this.props.location.pathname,
  };

  UNSAFE_componentWillMount() {
    this.props.getSessionAction();
    this.fetchData();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, status } = nextProps;
    checkSession(
      data,
      status,
      this.state.pathname,
      nextProps,
    );
  }

  async componentDidUpdate(prevProps) {
    const {
      getActivityData,
      getContributionsData,
      addParticipantData,
      match,
      getClubQuestionnaires: getClubQuestionnairesAction,
    } = this.props;
    const { id: actionId } = match.params;
    if (getActivityData === null) {
      this.fetchData();
    } else {
      if (
        getActivityData.data &&
        prevProps.getActivityData !== getActivityData
      ) {
        this.updateState(getActivityData.data);
        await getClubQuestionnairesAction(
          getActivityData.data.parentId,
        );
      }
      if (
        prevProps.getContributionsData !==
        getContributionsData
      ) {
        this.updatePagination(getContributionsData);
      }
      if (
        prevProps.getContributionsData !==
        getContributionsData
      ) {
        this.updatePagination(getContributionsData);
      }
      if (
        prevProps.addParticipantData !== addParticipantData
      ) {
        await getParticipants(actionId);
        toast.success(
          getLabelByName(ADD_PARTICIPANT_SUCCESS_MESSAGE),
        );
        this.setIsButtonLoadingFalse();
        this.setModalOpenFalse();
        this.clearParticipants();
      }
    }
  }

  setModalOpenFalse = () => {
    this.setState({ isModalOpen: false });
  };

  clearParticipants = () => {
    this.setState({ participants: [] });
  };

  setIsButtonLoadingFalse = () => {
    this.setState({ isButtonLoading: false });
  };

  updatePagination = (getContributionsData) => {
    const { pageDivider } = this.state;
    this.setState({
      nbPages: Math.ceil(
        getContributionsData?.data.filter(
          (contribution) =>
            contribution.status === "PENDING_REVIEW",
        ).length / pageDivider,
      ),
    });
  };

  fetchData = async () => {
    const { props } = this;
    const { id: activityId } = props.match.params;
    const query = `?q={"activityId":${activityId}}`;

    await props.getActivity(activityId);
    await props.getParticipants(activityId);
    await props.getContributions(query);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      activityData: {
        ...prevState.activityData,
        handle: value,
      },
      [name]: value,
      otherInfoChanged: true,
    }));
  };

  dataHasLoaded = (state) => !!(state && state.data);

  getUserTableHeaders = () => {
    return {
      name: "Participant",
      action: "Action",
      status: getLabelByName(STATUS),
      dateCreated: getLabelByName(CREATED_ON),
      review: "",
    };
  };

  getTableData = () => {
    if (
      !this.dataHasLoaded(this.props.getContributionsData)
    ) {
      return [];
    }

    if (
      !this.dataHasLoaded(this.props.getContributionsData)
    ) {
      return [];
    }

    const {
      currentPage,
      pageDivider,
      contributionStatuses,
      statusColor,
    } = this.state;
    const {
      match: { params },
    } = this.props;

    const { getActivityData } = this.props;

    return this.props.getContributionsData.data
      .filter(
        (contribution) =>
          contribution.status === "PENDING_REVIEW",
      )
      .slice(
        (currentPage - 1) * pageDivider,
        currentPage * pageDivider,
      )
      .map((contribution) => {
        const participantName = (
          <p className="font-weight-bold">
            {getActivityData?.data?.settings?.anonymous ===
              true && getLabelByName(ANONYMOUS)}
            {getActivityData?.data?.settings?.anonymous ===
              false &&
              `${contribution?.participant?.firstName} ${contribution?.participant?.lastName}`}
          </p>
        );

        const contributionStatus = (
          <span>
            <FiberManualRecord
              style={{
                color: statusColor[contribution.status],
                width: "15px",
                marginRight: "0.5rem",
              }}
            />
            {contributionStatuses[contribution.status]}
          </span>
        );

        const reviewLink = (
          <div
            className="contribution-review"
            data-toggle="modal"
            data-target=".review-contribution-modal"
            style={{ cursor: "pointer", color: "#FFFFFF" }}
            onClick={async () => {
              this.setState({
                selectedContribution: contribution,
                contributionModalOpen: true,
              });
              await this.props.getParticipantAllActions(
                params.id,
                contribution.participantId,
              );
            }}>
            {getLabelByName(REVIEW)}
          </div>
        );

        return {
          name: participantName,
          action: contribution?.action[0]?.name,
          status: contributionStatus,
          dateCreated: this.timestampToDate(
            contribution.dateCreated,
          ),
          review: reviewLink,
        };
      });
  };

  timestampToDate = (
    timestamp,
    format = "DD MMM YYYY, hh:mm",
  ) => {
    if (!timestamp) {
      return null;
    }
    return moment(timestamp * 1000).format(format);
  };

  incrementPage = () => {
    let { nbPages, currentPage } = this.state;
    currentPage =
      currentPage === nbPages
        ? currentPage
        : currentPage + 1;
    this.setState({ currentPage });
  };

  decrementPage = () => {
    let { currentPage } = this.state;
    currentPage =
      currentPage === 1 ? currentPage : currentPage - 1;
    this.setState({ currentPage });
  };

  getContributionsLength = () =>
    this.dataHasLoaded(this.props.getContributionsData) &&
    this.props.getContributionsData.data.length;

  setParticipantRoleHandler = (
    participantRole,
    participantIndex,
  ) => {
    const stateParticipants = this.state.participants;
    const participantToUpdate = stateParticipants.find(
      (partcipant, index) => participantIndex === index,
    );
    participantToUpdate.role = participantRole;

    const newParticipants = stateParticipants.filter(
      (participant, index) => index !== participantIndex,
    );

    newParticipants.splice(
      participantIndex,
      0,
      participantToUpdate,
    );

    this.setState({ participants: newParticipants });
  };

  handleActivityModal = async () => {
    this.setState({
      isCreateModalVisible: false,
    });

    const {
      getActivity: getActivityAction,
      match: {
        params: { id: activityId },
      },
    } = this.props;

    await getActivityAction(activityId);
  };

  handleAddUser = (data) => {
    this.setState({ participants: [...data] });
  };

  handleUpdateParticipant = (actionId, payload) => {
    const { updateParticipantAction } = this.props;
    updateParticipantAction();
  };

  handleRadio = (e) => {
    const val = e.target.value;
    this.setState((prevState) => ({
      activityData: {
        ...prevState.activityData,
        status: val,
      },
      otherInfoChanged: true,
    }));
  };

  submitForm = async () => {
    const { activityData } = this.state;
    const { updateActivity: updateActivityAction } =
      this.props;
    const payload = {
      ...activityData,
    };

    await updateActivityAction(activityData.id, payload);
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      contributionModalOpen:
        !prevState.contributionModalOpen,
    }));
  };

  updateState(data) {
    this.setState({
      activityData: data,
      otherInfoChanged: false,
    });
  }

  render() {
    const { activityData, isEditing } = this.state;
    const {
      getInstitute: getInstituteAction,
      getParticipants: getParticipantsAction,
      getContributionsData,
      getActivityStatus,
      getParticipantsStatus,
      getActivityData,
      getParticipantsData,
      landingData,
      match,
    } = this.props;
    const { id, handle } = match.params;

    if (
      getActivityStatus &&
      getParticipantsStatus &&
      (!getActivityData || !getParticipantsData)
    ) {
      return (
        <Fallback
          route={`/manage/activities/${id}/${handle}`}
        />
      );
    }

    if (
      getActivityStatus &&
      getParticipantsStatus &&
      (getActivityData || getParticipantsData) &&
      (getActivityData.status === 500 ||
        getParticipantsData.status === 500)
    ) {
      return (
        <Fallback
          route={`/manage/activities/${id}/${handle}`}
        />
      );
    }

    if (getActivityData && getActivityData.status === 500) {
      return <NotFound />;
    }

    let campaignInfo = null;
    if (getActivityData && getActivityData.data) {
      campaignInfo = getActivityData.data;
    }

    const startDate = campaignInfo
      ? moment(campaignInfo.startDate * 1000).format(
          "DD MMM YYYY hh:mm",
        )
      : "DD MMM YYYY hh:mm";
    const endDate =
      campaignInfo && campaignInfo.endDate
        ? moment(campaignInfo.endDate * 1000).format(
            "DD MMM YYYY hh:mm",
          )
        : "N/A";

    let defaultLogo = logo;
    if (landingData && landingData.data) {
      const landingInfo = landingData.data.filter(
        (x) => x.name === "landing-page",
      )[0];
      if (
        landingInfo &&
        landingInfo.data &&
        landingInfo.data.logo
      ) {
        defaultLogo = landingInfo.data.logo;
      }
    }
    return (
      <div className="campaign-detail-page">
        <div className="header">
          <Navbar pathname="manage" />
        </div>
        <div className="main">
          <div className="activityDetailsSection">
            <div className="subheader">
              <div className="title">
                <button
                  type="button"
                  style={{
                    background: "none",
                    border: "none",
                    outline: "none",
                    marginTop: "-8px",
                    marginLeft: "-5px",
                  }}>
                  <button
                    type="button"
                    onClick={() =>
                      this.props.history.push(
                        "/manage/activities",
                      )
                    }
                    style={{
                      outline: "none",
                      color: "#424242",
                      border: "none",
                      background: "transparent",
                    }}>
                    <ArrowBackIosIcon />
                  </button>
                </button>
                <h1
                  style={{
                    paddingLeft: "10px",
                    fontSize: "2rem",
                  }}>
                  {activityData?.name}
                </h1>
              </div>
              {match.path ===
                "/manage/activities/:id/:handle" && (
                <div className="edit-button">
                  <Button
                    style={{
                      outline: "none !important",
                      padding: "10px 30px",
                    }}
                    type="button"
                    color="primary"
                    variant="contained"
                    disabled={
                      !getActivityData ||
                      !getActivityData.data
                    }
                    onClick={() => {
                      this.setState((prevState) => ({
                        editActivity: getActivityData.data,
                        isCreateModalVisible:
                          !prevState.isCreateModalVisible,
                      }));
                    }}>
                    {isEditing && (
                      <>
                        <span>
                          {getLabelByName(EDITING)}
                          {"  "}
                        </span>
                        <Loader />
                      </>
                    )}
                    {!isEditing && getLabelByName(EDIT)}
                  </Button>
                </div>
              )}
            </div>
            <div className="activity mb-5">
              {activityData && (
                <ActivityDetails
                  activityData={activityData}
                  getInstitute={getInstituteAction}
                  getParticipants={getParticipantsAction}
                  getContributionsData={
                    getContributionsData
                  }
                  isFromActivityDetailsPage
                />
              )}
            </div>
            <ActivityLinks
              title={getLabelByName(ADVANCED_SETTINGS)}
              count={null}
              info={getLabelByName(
                ACTIVITY_SETTINGS_DESCRIPTION,
              )}
              link={`/manage/activities/${id}/${handle}/settings/general`}
            />
            <ActivityLinks
              title={getLabelByName(HOW_TO_PARTICIPATE)}
              count={null}
              info={getLabelByName(
                HOW_TO_PARTICIPATE_DESCRIPTION,
              )}
              link={`${window.location.pathname}/how-to-participate`}
            />
            <ActivityLinksForPrticipant
              title={getLabelByName(PARTICIPANTS)}
              info={getLabelByName(PARTICIPANT_DESCRIPTION)}
              getParticipants={getParticipantsAction}
              activityData={activityData}
              link={`${window.location.pathname}/participants`}
            />
            <ActivityLinks
              title={getLabelByName(CONTRIBUTIONS)}
              count={`${this.getContributionsLength()}`}
              info={getLabelByName(
                CONTRIBUTION_DESCRIPTION,
              )}
              link={`${window.location.pathname}/contributions`}
            />
          </div>
        </div>
        {/* the div below is to create space between the main content & the footer */}
        <div style={{ height: "80px" }}>.</div>
        <div className="footer-block">
          <div className="footer-component">
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => ({
  getActivityDataError:
    state.getActivityReducer.getActivityDataError,
  getActivityData: state.getActivityReducer.getActivityData,
  getActivityStatus:
    state.getActivityReducer.getActivityStatus,

  getParticipantsDataError:
    state.getParticipantsReducer.getParticipantsDataError,
  getParticipantsData:
    state.getParticipantsReducer.getParticipantsData,
  getParticipantsStatus:
    state.getParticipantsReducer.getParticipantsStatus,

  addParticipantError:
    state.addParticipantReducer.addParticipantError,
  addParticipantData:
    state.addParticipantReducer.addParticipantData,

  updateActivityData:
    state.updateActivityReducer.updateActivityData,
  updateActivityDataError:
    state.updateActivityReducer.updateActivityDataError,
  updateActivityStatus:
    state.updateActivityReducer.updateActivityStatus,

  getQuestionnairesData:
    state.getQuestionnairesReducer.getQuestionnairesData,
  getQuestionnairesDataError:
    state.getQuestionnairesReducer
      .getQuestionnairesDataError,
  getQuestionnairesStatus:
    state.getQuestionnairesReducer.getQuestionnairesStatus,

  getContributionsDataError:
    state.getContributionsReducer.getContributionsDataError,
  getContributionsData:
    state.getContributionsReducer.getContributionsData,
  getContributionsStatus:
    state.getContributionsReducer.getContributionsStatus,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,
  clubData: state.instituteReducer.instituteData,
});

ActivityDetailsPage.propTypes = {};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getActivity,
    updateActivity,
    getSessionAction,
    getParticipants,
    getClubQuestionnaires,
    getContributions,
    addMemberToActivityAction,
    getParticipantAllActions,
    getInstitute,
    getQuestionnaires,
  }),
)(ActivityDetailsPage);
