import {
  CREATE_OUTPUT_SUCCESS, CREATE_OUTPUT_ERROR,
} from '../actions/types';

const initialState = {
  createOutputData: null,
  createOutputDataError: null,
  createOutputStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_OUTPUT_SUCCESS:
      return {
        ...state,
        createOutputData: action.payload,
        createOutputDataError: null,
        createOutputStatus: 'success',
      };
    case CREATE_OUTPUT_ERROR:
      return {
        ...state,
        createOutputDataError: action.payload,
        createOutputData: null,
        createOutputStatus: 'error',
      };
    default:
      return {
        ...state,
        createOutputStatus: null,
      };
  }
};
