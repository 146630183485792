/* eslint-disable global-require */
/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { CreateOutlined } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";

import {
  CREATED,
  EDIT,
  ENTRY,
  LAST_UPDATED,
} from "../../helpers/constants";
import { getLabelByName } from "../../helpers/helperFunctions";
import { getParticipantActions } from "../../redux/actions/campaignsActions";

class ListingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleEditingOnclick = async () => {
    const {
      setShowQuestionnaire,
      setShowListing,
      pathParams: { campaignId },
      participantId,
      listingItem,
      getParticipantActions: participantActions,
    } = this.props;

    setShowQuestionnaire(true);

    setShowListing(false);

    const query = `?q={"id":${listingItem.id}}`;

    const activityId = campaignId;

    await participantActions(
      activityId,
      participantId,
      query,
    );
  };

  render() {
    const { editListing, actionDetails, listingItem } =
      this.props;

    return (
      <div className="listing-card-ItemCard">
        <>
          <h1>{actionDetails?.name}</h1>
          <p>
            {getLabelByName(CREATED)}:{" "}
            {moment(listingItem?.dateCreated * 1000).format(
              "DD MMM YYYY hh:mm",
            ) || "DD MMM YYYY"}
          </p>
          <p>
            {getLabelByName(LAST_UPDATED)}:{" "}
            {(listingItem?.lastUpdated &&
              moment(
                listingItem?.lastUpdated * 1000,
              ).format("DD MMM YYYY hh:mm")) ||
              "N/A"}
          </p>
          {editListing && (
            <div className="edit-listing">
              <button
                type="button"
                onClick={this.handleEditingOnclick}>
                <CreateOutlined className="mr-2" />
                {`${getLabelByName(EDIT)} ${getLabelByName(
                  ENTRY,
                )}`}
              </button>
            </div>
          )}
        </>
      </div>
    );
  }

  getImagePreview = (listingCover) => {
    let image = require("../../assets/manage-campaigns.svg");
    if (listingCover) {
      image = listingCover;
    }

    return image;
  };
}

ListingCard.propTypes = {
  listingItem: PropTypes.object,
  pathParams: PropTypes.object,
  editListing: PropTypes.bool,
};

const mapDispatchToProps = {
  getParticipantActions,
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(ListingCard);
