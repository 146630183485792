import "./searchBar.scss";
import React, { Component } from "react";
import searchOrange from "../../../assets/icons/search-orange.svg";
import { getLabelByName } from "../../../helpers/helperFunctions";
import { SEARCH, STEP } from "../../../helpers/constants";

export default class SearchBar extends Component {
  handleSearch = (event) => {
    const { displayedItems, isClubMembers, isActions } =
      this.props;

    const searchQuery = event.target.value.toLowerCase();

    let searchedItems;

    if (isActions) {
      searchedItems = displayedItems.filter(
        (item) =>
          item.name.toLowerCase().indexOf(searchQuery) !==
          -1,
      );
    } else if (isClubMembers) {
      searchedItems = displayedItems.filter(
        (item) =>
          item.user.profile.firstName
            .toLowerCase()
            .indexOf(searchQuery) !== -1 ||
          item.user.profile.lastName
            .toLowerCase()
            .indexOf(searchQuery) !== -1,
      );
    } else {
      searchedItems = displayedItems.filter(
        (item) =>
          item.name.toLowerCase().indexOf(searchQuery) !==
          -1,
      );
    }

    const { setSearchedItems, setTriggerPagination } =
      this.props;

    setSearchedItems(searchedItems);

    setTriggerPagination();

    if (!event.target.value || event.target.value === "") {
      const { setDisplayedItems } = this.props;

      setDisplayedItems(displayedItems);
    }
  };

  render() {
    const { placeholderText } = this.props;

    return (
      <div className="search-items">
        <form className="form">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              id="inputSearch"
              placeholder={
                placeholderText || getLabelByName(SEARCH)
              }
              name="searchValue"
              onChange={this.handleSearch}
            />
            <div className="input-group-append">
              <button
                id="search-btn"
                className="btn"
                type="button">
                <img
                  src={searchOrange}
                  alt="search-icon"
                  style={{ width: "20px" }}
                />
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
