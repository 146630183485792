import {
  CREATE_CLUB_ERROR, CREATE_CLUB_SUCCESS,
} from '../actions/types';

const initialState = {
  createClubData: null,
  createClubDataError: null,
  createClubStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CLUB_SUCCESS:
      return {
        ...state,
        createClubData: action.payload,
        createClubDataError: null,
        createClubStatus: 'success',
      };
    case CREATE_CLUB_ERROR:
      return {
        ...state,
        createClubDataError: action.payload,
        createClubData: null,
        createClubStatus: 'error',
      };
    default:
      return {
        ...state,
        createClubStatus: null,
      };
  }
};
