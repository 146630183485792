import React from "react";
import Modal from "@material-ui/core/Modal";
import { getLabelByName } from "../../../helpers/helperFunctions";
import {
  ARE_YOU_SURE_TEXT,
  CANCEL,
  DELETE,
  DELETE_CONFIRMATION_TEXT,
  DELETING,
  SEND,
  POST,
  SENDING,
  THIS_WORD,
  TO_WORD,
  WANT,
  YOU,
} from "../../../helpers/constants";

const PasswordResetModal = ({
  isOpen,
  closeModal,
  handleSendPasswordResetEmail,
  isButtonLoading,
  setButtonLoading,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="delete-confirmation-modal">
      <div className="delete-confirmation-modal-content">
        <div className="delete-confirmation-modal-title">
          <h1>
            {`${getLabelByName(
              ARE_YOU_SURE_TEXT,
            )} ${getLabelByName(
              YOU,
            ).toLowerCase()} ${getLabelByName(
              WANT,
            ).toLowerCase()} ${getLabelByName(
              TO_WORD,
            )} ${getLabelByName(
              SEND,
            ).toLowerCase()} a Password Reset Token?`}
          </h1>
        </div>
        {/* <div className="delete-confirmation-modal-body">
          <p>
            {`${getLabelByName(
              DELETE_CONFIRMATION_TEXT,
            )} ${getLabelByName(POST).toLowerCase()}`}
          </p>
        </div> */}

        <div className="delete-confirmation-call-to-action-buttons">
          <div className="d-flex justify-content-center align-items-center mt-5">
            <div className="confirmation-cancel-button">
              <button type="button" onClick={closeModal}>
                {getLabelByName(CANCEL)}
              </button>
            </div>

            <div className="confirmation-remove-button">
              <button
                type="button"
                onClick={() => {
                  setButtonLoading();
                  handleSendPasswordResetEmail();
                }}>
                {isButtonLoading ? (
                  <>
                    <span>{`${getLabelByName(
                      SENDING,
                    )}  `}</span>
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status">
                      <span className="sr-only">
                        Loading...
                      </span>
                    </div>
                  </>
                ) : (
                  getLabelByName(SEND)
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PasswordResetModal;
