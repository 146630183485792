import { combineReducers } from "redux";
import authReducer from "./authReducer";
import forgotPasswordReducer from "./forgotPasswordReducer";
import resetPasswordReducer from "./resetPasswordReducer";
import changePasswordReducer from "./changePasswordReducer";
import institutesReducer from "./institutesReducer";
import instituteReducer from "./instituteReducer";
import campaignsReducer from "./campaignsReducer";
import clubCampaignsReducer from "./clubCampaignsReducer";
import editProfileReducer from "./editProfileReducer";
import clubMembersReducer from "./clubMembersReducer";
import joinClubReducer from "./joinClubReducer";
import searchClubsNameReducer from "./searchClubsNameReducer";
import searchClubsDescriptionReducer from "./searchClubsDescriptionReducer";
import searchCampaignsNameReducer from "./searchCampaignsNameReducer";
import searchCampaignsDescriptionReducer from "./searchCampaignsDescriptionReducer";
import landingReducer from "./landingReducer";
import adminReducer from "./adminReducer";
import getPostsReducer from "./getPostsReducer";
import getPostReducer from "./getPostReducer";
import deletePostReducer from "./deletePostReducer";
import getCommentsReducer from "./getCommentsReducer";
import createPostReducer from "./createPostReducer";
import createClubReducer from "./createClubReducer";
import updateClubReducer from "./updateClubReducer";
import createActivityReducer from "./createActivityReducer";
import updateActivityReducer from "./updateActivityReducer";
import cloneActivityReducer from "./cloneActivityReducer";
import getMemberReducer from "./getMemberReducer";
import getNewsFeedReducer from "./getNewsFeedReducer";
import getActivitiesReducer from "./getActivitiesReducer";
import getActivityReducer from "./getActivityReducer";
import getParticipantsReducer from "./getParticipantsReducer";
import updatePostReducer from "./updatePostReducer";
import postReactionReducer from "./postReactionReducer";
import deleteReactionReducer from "./deleteReactionReducer";
import getReactionsReducer from "./getReactionsReducer";
import getUsersReducer from "./getUsersReducer";
import getQuestionnaireReducer from "./getQuestionnaireReducer";
import participateCampaignReducer from "./participateCampaignReducer";
import getActionReducer from "./getActionReducer";
import postParticipantActionReducer from "./postParticipantActionReducer";
import getParticipantActionsReducer from "./getParticipantActionsReducer";
import updateParticipantActionReducer from "./updateParticipantActionReducer";
import deleteParticipantActionReducer from "./deleteParticipantReducer";
import getContributionsReducer from "./getContributionsReducer";
import submitContributionReducer from "./submitContributionReducer";
import getOutputsReducer from "./getOutputsReducer";
import getOutputReducer from "./getOutputReducer";
import getLabelsReducer from "./getLabelsReducer";
import filtersReducer from "./filtersReducer";
import getUserClubsReducer from "./getUserClubsReducer";
import getNotificationsReducer from "./getNotificationsReducer";
import markNotificationReducer from "./markNotificationReducer";
import createUserReducer from "./createUserReducer";
import getQuestionnairesReducer from "./getQuestionnairesReducer";
import createQuestionnaireReducer from "./createQuestionnaireReducer";
import updateQuestionnaireReducer from "./updateQuestionnaireReducer";
import cloneQuestionnaireReducer from "./cloneQuestionnaireReducer";
import createActionReducer from "./createActionReducer";
import editActionReducer from "./editActionReducer";
import updateContributionReducer from "./updateContributionReducer";
import createOutputReducer from "./createOutputReducer";
import updateOutputReducer from "./updateOutputReducer";
import cloneOutputReducer from "./cloneOutputReducer";
import addParticipantReducer from "./addParticipantReducer";
import getParticipantAllActionsReducer from "./getParticipantAllActionsReducer";
import getParticipantContributionReducer from "./getParticipantContributionReducer";
import getAdminClubActivitiesReducer from "./getAdminClubActivitiesReducer";
import editClubMembersReducer from "./editClubMembersReducer";
import deleteClubMembersReducer from "./deleteClubMemberReducer";
import deleteActionReducer from "./deleteActionReducer";
import postContributionsCommentReducer from "./postContributionsCommentReducer";
import getContributionsCommentsReducer from "./getContributionsCommentsReducer";
import getPostLessonsReducer from "./getPostLessonsReducer";
import getClubPostsReducer from "./getClubPostsReducer";
import getClubArticlesReducer from "./getClubArticlesReducer";
import getClubLessonsReducer from "./getClubLessonsReducer";

import {
  activityCRUDReducer,
} from "./activities/activityReducers";

import {
  participantCRUDReducer
} from "./activities/participantReducers";

import {
  PARTICIPANT_ACTION_REDUCERS,
} from "./activities/participantActionReducers";

import {
  participantContributionCRUDReducer,
  participantContributionsSearchReducer,
  activityContributionsSearchReducer,
  activityContributionCommentsSearchReducer,
} from "./activities/contributionReducers";

import {
  questionnaireRetrieveReducer
} from "./questionnaires/questionnaireReducers";

import {
  courseRetrieveReducer,
  courseRetrieveLessonsReducer
} from './courses/coursesReducer'

import {
  sessionUserReducer
} from "./users/sessionReducers";

import {
  userCRUDReducer
} from "./users/userReducers";

import {
  clubCRUDReducer,
  clubSearchReducer
} from "./clubs/clubReducers";

export default combineReducers({
  authReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  changePasswordReducer,
  institutesReducer,
  instituteReducer,
  campaignsReducer,
  clubCampaignsReducer,
  editProfileReducer,
  clubMembersReducer,
  joinClubReducer,
  searchClubsNameReducer,
  searchClubsDescriptionReducer,
  searchCampaignsNameReducer,
  searchCampaignsDescriptionReducer,
  landingReducer,
  adminReducer,
  getPostsReducer,
  getPostReducer,
  deletePostReducer,
  getCommentsReducer,
  createPostReducer,
  createClubReducer,
  updateClubReducer,
  createActivityReducer,
  cloneActivityReducer,
  updateActivityReducer,
  getClubPostsReducer,
  getClubArticlesReducer,
  getClubLessonsReducer,
  getMemberReducer,
  getNewsFeedReducer,
  getPostLessonsReducer,
  getActivitiesReducer,
  getActivityReducer,
  getParticipantsReducer,
  updatePostReducer,
  postReactionReducer,
  deleteReactionReducer,
  getReactionsReducer,
  getUsersReducer,
  getQuestionnaireReducer,
  participateCampaignReducer,
  getActionReducer,
  postParticipantActionReducer,
  getParticipantActionsReducer,
  updateParticipantActionReducer,
  deleteParticipantActionReducer,
  getContributionsReducer,
  submitContributionReducer,
  getOutputsReducer,
  getOutputReducer,
  getLabelsReducer,
  filtersReducer,
  getUserClubsReducer,
  getNotificationsReducer,
  markNotificationReducer,
  createUserReducer,
  getQuestionnairesReducer,
  createQuestionnaireReducer,
  updateQuestionnaireReducer,
  cloneQuestionnaireReducer,
  createActionReducer,
  editActionReducer,
  updateContributionReducer,
  createOutputReducer,
  updateOutputReducer,
  cloneOutputReducer,
  addParticipantReducer,
  getParticipantAllActionsReducer,
  getParticipantContributionReducer,
  getAdminClubActivitiesReducer,
  editClubMembersReducer,
  deleteClubMembersReducer,
  deleteActionReducer,
  postContributionsCommentReducer,
  getContributionsCommentsReducer,
  activityCRUDReducer,
  participantCRUDReducer,
  ...PARTICIPANT_ACTION_REDUCERS,
  participantContributionCRUDReducer,
  participantContributionsSearchReducer,
  activityContributionsSearchReducer,
  activityContributionCommentsSearchReducer,
  questionnaireRetrieveReducer,
  courseRetrieveReducer,
  courseRetrieveLessonsReducer,
  sessionUserReducer,
  userCRUDReducer,
  clubCRUDReducer,
  clubSearchReducer,
});
