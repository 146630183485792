import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete-icon.svg";
import { ReactComponent as Duplicate } from "../../../assets/icons/duplicate.svg";
import { ReactComponent as Download } from "../../../assets/icons/download.svg";
import { ReactComponent as Clip } from "../../../assets/icons/clip.svg";

const OutputDropdown = () => (
  <div
    className="dropdown-menu dropdown-menu-right output-dropdown py-0"
    aria-labelledby="dropdownOutput">
    <header className="border-bottom py-2 px-2 d-flex justify-content-between align-items-center">
      <strong>Status</strong>

      <span className="success-badge px-1">Success</span>
    </header>

    <div className=" border-bottom">
      <Link className="dropdown-item nav-link" to="#">
        <span>
          <DeleteIcon className="mr-2" />
          Delete
        </span>
      </Link>

      <Link to="#" className="dropdown-item nav-link">
        <span>
          <Duplicate className="mr-2" />
          Duplicate
        </span>
      </Link>
    </div>

    <div className=" border-bottom">
      <Link className="dropdown-item nav-link" to="#">
        <span>
          <Download className="mr-2" />
          Download as CSV
        </span>
      </Link>

      <Link to="#" className="dropdown-item nav-link">
        <span>
          <Clip className="mr-2" />
          Export: PDF, JPG
        </span>
      </Link>
    </div>

    <div className="">
      <Link
        className="dropdown-item nav-link d-flex align-items-center justify-content-between"
        to="#">
        <span>Last edited:</span>

        <span className="last-edited-text">
          Today at 5:04 PM
        </span>
      </Link>
    </div>
  </div>
);

export default OutputDropdown;
