import {
  GET_NEWSFEED_SUCCESS, GET_NEWSFEED_ERROR,
} from '../actions/types';

const initialState = {
  getNewsFeedData: null,
  getNewsFeedDataError: null,
  getNewsFeedStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NEWSFEED_SUCCESS:
      return {
        ...state,
        getNewsFeedData: action.payload,
        getNewsFeedDataError: null,
        getNewsFeedStatus: 'success',
      };
    case GET_NEWSFEED_ERROR:
      return {
        ...state,
        getNewsFeedDataError: action.payload,
        getNewsFeedData: null,
        getNewsFeedStatus: 'error',
      };
    default:
      return {
        ...state,
        getNewsFeedStatus: null,
      };
  }
};
