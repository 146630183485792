import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import moment from "moment";
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { retrieveActivity } from "../../../redux/actions/activities/activityActions.js";
import { searchActivityContributions, updateParticipantContribution } from "../../../redux/actions/activities/contributionActions.js";
import Navbar from "../../Navbar";

import commentIcon from "../../../assets/icons/comment.svg";
import { Modal } from 'react-bootstrap';
import ContributionRenderer from './ContributionRenderer.js';
import ContributionActionPanel from './ContributionActionPanel.js';
import { setRef } from '@material-ui/core';


const ContributionsConsole = (props) => {
    const { activityId } = props.match.params
    const { activity, activityContributions, updatedContribution } = props

    const [showContributionModal, setShowContributionModal] = useState(false);
    const [selectedContribution, setSelectedContribution] = useState(null);

    const closeContributionModal = () => {
        setShowContributionModal(false);
    }

    const reviewContribution = (contribution) => {
        setSelectedContribution(contribution);
        setShowContributionModal(true);
    }

    const handleContributionAction = (action) => {
        const updateStatus = {
            Approve: "APPROVED",
            Reject: "REJECTED",
        };

        props.updateParticipantContribution(
            activity.id,
            selectedContribution.id,
            {
                ...selectedContribution,
                status: updateStatus[action],
            }
        );
    }

    useEffect(() => {
        props.retrieveActivity(activityId);
        props.searchActivityContributions(activityId);

    }, [activityId]);

    useEffect(() => {
        if (updatedContribution && updatedContribution.id) {
            props.searchActivityContributions(activityId);
            setSelectedContribution(updatedContribution);
        }
    }, [updatedContribution]);

    if (!activityContributions || !activity) {
        return <h5>loading</h5>
    }

    return (
        <>
            <Navbar pathname="explore" />
            <section className="contribute-section">
                <div className="right-div px-3" style={{ width: '100%', marginLeft: 'auto', marginRight: "auto" }}>
                    <div className="contributions-content">
                        <div className="contribution-cards-content">
                            {activityContributions.map(contribution => (
                                <ContributionCard
                                    contribution={contribution}
                                    key={contribution.id}
                                    onClickHandler={reviewContribution} />
                            ))}
                        </div>
                    </div>
                </div>
                <Modal size="xl" show={showContributionModal} onHide={closeContributionModal} className="behalf-modal">
                    <ContributionActionPanel
                        activity={activity}
                        contribution={selectedContribution}
                        actions={["Approve", "Reject", "Mark as Received"]}
                        actionHandler={handleContributionAction}
                    />
                    <ContributionRenderer
                        contribution={selectedContribution}
                        activity={activity}
                    />
                    {/* ContributionComments Panel */}
                </Modal>
            </section>
        </>
    );
}

const mapStateToProps = (state) => ({
    activity: state.activityCRUDReducer,
    activityContributions: state.activityContributionsSearchReducer.contributions,
    getContributionsCommentsData: state.getContributionsCommentsReducer.getContributionsCommentsData,
    clubMembersData: state.clubMembersReducer.clubMembersData,
    updatedContribution: state.participantContributionCRUDReducer,
});

export default compose(
    withRouter,
    connect(mapStateToProps, {
        retrieveActivity,
        searchActivityContributions,
        updateParticipantContribution,
    }),
)(ContributionsConsole);



const ContributionCard = ({ contribution, onClickHandler }) => {

    const [showComment, setShowComment] = useState(false)
    const statusDictionary = {
        PENDING_REVIEW: {
            text: "Pending",
            backgroundColor: "#E6B100",
        },
        APPROVED: {
            text: "Accepted",
            backgroundColor: "#10A728",
        },
        REJECTED: {
            text: "Rejected",
            backgroundColor: "#F40505",
        },
        showComment: false,
        currentModalIndex: 0,
        isAddedDiv: false,
    }

    const contributionClickHandler = (contribution) => {
        onClickHandler(contribution);
    }

    return (
        <div className="contribution-card-area">
            <div className="contribution-card">
                <div className="inside-content">
                    <div
                        className="contribution-details"
                        aria-hidden
                        onClick={() => contributionClickHandler(contribution)}>
                        <h1>{contribution.participant.firstName} {contribution.participant.lastName}</h1>
                        <h1>{contribution.action.name}</h1>
                        <p>
                            {moment(
                                contribution.dateCreated * 1000,
                            ).format("DD MMM YYYY hh:mm ") ||
                                "DD MMM YYYY hh:mm"}
                        </p>
                    </div>
                    <div className="flex-content">
                        <div className="status-div">
                            <button
                                type="button"
                                style={{
                                    backgroundColor: statusDictionary[contribution.status].backgroundColor,
                                    cursor: "default",
                                }}>
                                {statusDictionary[contribution.status].text}
                            </button>
                        </div>
                        <div className="comment-toggle-icon">
                            <div
                                className="comment-icon"
                                aria-hidden
                                onClick={() => contributionClickHandler(contribution)}>
                                <img
                                    src={commentIcon}
                                    alt="comment-icon"
                                    width="25px"
                                />
                                <span className="comment-number">
                                    {contribution?.comments}
                                </span>
                            </div>
                            {/* {showComment &&
                    contributionIndex === currentModalIndex && (
                        <ContributionComment
                        closeModal={() => {
                            this.setState({
                            showComment: false,
                            isAddedDiv: false,
                            });
                        }}
                        contribution={contribution}
                        />
                    )} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}