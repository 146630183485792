import React from "react";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import WizardHeader from "./WizardHeader";
import WizardForms from "./WizardForms";
import WizardContextProvider from "./wizardContextProvider";

const OutputWizardModal = ({
  open,
  handleClose,
  title,
  description,
  outputData,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="output-modal"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <WizardContextProvider outputData={outputData}>
          <div className="output-modal-content">
            <WizardHeader
              title={title}
              description={description}
            />
            <WizardForms closeModal={handleClose} />
          </div>
        </WizardContextProvider>
      </Fade>
    </Modal>
  );
};

export default OutputWizardModal;
