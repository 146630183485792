/* eslint-disable no-nested-ternary */
import "./userContribute.scss";
import React, { Component } from "react";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import moment from "moment";
import { toast } from "react-toastify";
import Navbar from "../../Navbar";
import {
  getActivity,
  getParticipants,
  participateCampaign,
  getParticipantContributionAction,
  getParticipantActions,
  getParticipantAllActions,
  postParticipantAction,
  updateParticipantAction,
  getAction,
} from "../../../redux/actions/campaignsActions";
import { getLanding } from "../../../redux/actions/landingActions";
import { getSessionAction } from "../../../redux/actions/authActions";
import { getInstitute } from "../../../redux/actions/institutesActions";
import { submitContribution } from "../../../redux/actions/contributionsActions";
import {
  getLabelByName,
  isActionSuccess,
  isActionError,
  getGuestDataFromLocalStorage,
} from "../../../helpers/helperFunctions";
import Loading from "../../generics/Loading/Loading";
import ContributionCard from "../../generics/Contribution/ContributionCard/ContributionCard";
import ListingCard from "../../generics/ListingCard";
import Questionnaire from "../../Questionnaire";
import {
  ACTIVITY,
  ADD,
  CANCEL,
  COMPLETE_DRAFT_ENTRIES,
  CONTRIBUTING,
  CONTRIBUTION,
  CONTRIBUTION_SAVED_SUCCESSFULLY,
  CONTRIBUTION_SUBMITTED_SUCCESSFULLY,
  ENDED,
  END_ON,
  ENTRIES,
  ENTRY,
  EXIT,
  HAS,
  INCOMPLETE_DRAFT_ENTRIES,
  NEW,
  NO_CONTRIBUTION_STEPS,
  NO_CONTRIBUTION_SUBMITTED_MESSAGE,
  OF_WORD,
  PARTICIPANT_WELCOME_MESSAGE,
  PROCESSING,
  REMAINING,
  SAVE_DRAFT,
  START,
  STEP,
  SUBMIT,
} from "../../../helpers/constants";
import noContributionImage from "../../../assets/undraw_Portfolio_update.svg";
import activityDefaultImage from "../../../assets/manage-campaigns.svg";
import ViewContributionModal from "../../generics/Contribution/ViewContributionModal";
import PaymentView from "./PaymentView";
import ContributionConfirmationModal from "./ContributionConfirmationModal"

export class UserContribute extends Component {

  state = {
    isDropDownOpen: false,
    activityData: null,
    contributionModalOpen: false,
    showContributionConfirmationModal: false,
    selectedContribution: null,
    showQuestionnaire: false,
    questionnaireId: null,
    actionId: null,
    questionnaireState: {},
    allParticipantActions: [],
    saveAndSubmit: false,
    enableSubmission: false,
    isSaving: false,
    paymentView: false,
    paymentActionData: {},
    participantId: null,
    noActionFound: false,
    showListing: false,
    newQuestionnaire: false,
    actionDetails: null,
  };

  async UNSAFE_componentWillMount() {
    console.log("DISPLAY PROPS: ", this.props);

    const {
      getActivity: getActivityAction,
      getSessionAction: getSession,
      getLanding: getLandingAction,
      getInstitute: getInstituteAction,
      getParticipants: getParticipantsAction,
      match: {
        params: { id: activityId },
      },
      location: {
        search,
        state: { clubId },
      },
      profileData,
    } = this.props;

    const query = new URLSearchParams(search);

    const reviewMode = query.get("review");

    this.setState({ reviewMode: !!reviewMode });

    // await checkSession(data, status, pathname);

    const isAnonymousActivity =
      this.state.activityData?.settings?.anonymous;

    await getActivityAction(activityId);

    if (
      profileData &&
      profileData?.data?.status !== "GUEST"
    ) {
      await getSession();

      await getInstituteAction(clubId);
    }

    await getLandingAction();

    await getParticipantsAction(
      activityId,
      null,
      null,
      isAnonymousActivity,
    );
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      getParticipantContributionAction:
      getParticipantContribution,
      getInstitute: getInstituteAction,
      getParticipantActions: getParticipantAction,
      getParticipantAllActions: getParticipantAllAction,
      getAction: getActions,
      match: {
        params: { id: activityId },
      },
    } = this.props;

    const { actionId, showQuestionnaire, activityData } =
      this.state;

    const isAnonymousActivity =
      activityData?.settings?.anonymous;

    const { profileData } = this.props;

    // All participants that has contributed to this activity
    if (nextProps.participantsData) {
      this.handleIsUserParticipant(nextProps);
    }

    this.insertDataIntoState(
      nextProps.getActivityData?.data,
    );

    if (
      nextProps.participantsData?.data &&
      !nextProps.participantContributionData
    ) {
      let participantData = {};
      const guestData = getGuestDataFromLocalStorage();

      if (isAnonymousActivity) {
        participantData =
          nextProps.participantsData?.data?.find(
            (d) =>
              parseInt(d.id) ===
              parseInt(guestData?.data?.id),
          );
      } else {
        participantData =
          nextProps.participantsData?.data?.find(
            (d) =>
              parseInt(d.id) ===
              parseInt(profileData?.data?.id),
          );
      }

      let participantId;

      this.setState({
        participantId: participantData?.id,
      });

      if (participantData) {
        await getInstituteAction(
          nextProps.location?.state?.clubId ||
          activityData?.parentId,
        );

        await getParticipantContribution(
          activityId,
          participantData?.id,
        );

        participantId =
          this.getParticipantId() ?? participantData?.id;
      }

      const participantActionId =
        this.actionParticipantId();

      if (
        participantId &&
        participantActionId &&
        showQuestionnaire
      ) {
        const query2 = `?q={"id":${participantActionId}}`;

        await getParticipantAction(
          activityId,
          participantId,
          query2,
          isAnonymousActivity,
        );

        await getParticipantAllAction(
          activityId,
          participantId,
          isAnonymousActivity,
        );

        await getActions(
          activityId,
          actionId,
          isAnonymousActivity,
        );
      }
    }

    if (
      nextProps.getActionStatus === "success" &&
      nextProps.getActionData.status === 200
    ) {
      const { questionnaireId } =
        nextProps.getActionData.data.meta;
      this.setState({
        questionnaireId,
      });
    }

    if (
      isActionSuccess(
        nextProps,
        "getParticipantActionsStatus",
        "getParticipantActionsData",
      ) &&
      nextProps.getParticipantActionsData &&
      nextProps.getParticipantActionsData.length > 0 &&
      nextProps.getParticipantActionsData.data[0].status ===
      "COMPLETE"
    ) {
      this.setState({ enableSubmission: true });
    }

    if (
      isActionSuccess(
        nextProps,
        "getParticipantAllActionsStatus",
        "getParticipantAllActionsData",
      )
    ) {
      this.setState({
        allParticipantActions:
          nextProps.getParticipantAllActionsData.data,
      });
    }

    // if (
    //   isActionSuccess(
    //     nextProps,
    //     "postParticipantActionStatus",
    //     "postParticipantActionData",
    //   )
    // ) {
    //   toast.success(
    //     getLabelByName(CONTRIBUTION_SAVED_SUCCESSFULLY),
    //   );

    //   this.actionAfterSave(
    //     nextProps.postParticipantActionData,
    //   );
    // }

    if (
      isActionSuccess(
        nextProps,
        "updateParticipantActionStatus",
        "updateParticipantActionData",
      )
    ) {
      toast.success(
        getLabelByName(CONTRIBUTION_SAVED_SUCCESSFULLY),
      );

      this.actionAfterSave(
        nextProps.updateParticipantActionData,
      );
    }

    // if (
    //   isActionSuccess(
    //     nextProps,
    //     "submitContributionStatus",
    //     "submitContributionData",
    //   )
    // ) {
    //   toast.success(
    //     getLabelByName(CONTRIBUTION_SUBMITTED_SUCCESSFULLY),
    //   );

    //   this.redirectOnSuccessSave();
    // }

    if (
      isActionError(
        nextProps,
        "postParticipantActionStatus",
        "postParticipantActionData",
      ) ||
      isActionError(
        nextProps,
        "updateParticipantActionStatus",
        "updateParticipantActionData",
      ) ||
      isActionError(
        nextProps,
        "submitContributionStatus",
        "submitContributionData",
      )
    ) {
      this.setState({ isSaving: false });
      toast.error(
        nextProps.submitContributionData?.error?.message ||
        nextProps.postParticipantActionData?.error
          ?.message ||
        nextProps.updateParticipantActionData?.error
          ?.message,
      );
    }

    if (
      nextProps.getActionData?.data?.meta
        ?.maxSubmissions !== "-1"
    ) {
      const { participantId } = this.state;

      const {
        history,
        location: { pathname },
      } = this.props;

      if (
        !participantId &&
        this.dataHasLoaded(nextProps.getActionData) &&
        this.dataHasLoaded(
          nextProps.getParticipantActionsData,
        ) &&
        nextProps.getParticipantActionsData.status ===
        200 &&
        nextProps.getActionData.data.meta.maxSubmissions ===
        0
      ) {
        toast.error(
          "You have reached the maximum of listings you can create.",
        );
        history.push(`${pathname}/listings`);
      }
    }

    setTimeout(
      () => this.setState({ noActionFound: false }),
      1000,
    );
  }

  async componentDidUpdate(prevState, nextState) {
    const {
      getAction: getActions,
      match: {
        params: { id: activityId },
      },
    } = this.props;

    const { actionId, activityData } = this.state;

    const isAnonymousActivity =
      activityData?.settings?.anonymous;

    if (actionId !== nextState.actionId) {
      await getActions(
        activityId,
        actionId,
        isAnonymousActivity,
      );
    }
  }

  handleIsUserParticipant = (props) => {
    const isUserParticipant = () => {
      const userPart =
        props.participantsData &&
        props.participantsData.data.filter(
          (data) =>
            data.user.id ===
            this.props.profileData?.data?.id,
        );

      if (userPart?.length > 0) {
        return true;
      }
      return false;
    };

    const activityData = props.getActivityData?.data;

    if (
      activityData?.visibility === "PRIVATE" &&
      !isUserParticipant()
    ) {
      this.props.history.push("/home");
    }
  };

  insertDataIntoState = (data) => {
    this.setState({ activityData: data });
  };

  setDropDown = () => {
    const { isDropDownOpen } = this.state;
    this.setState({ isDropDownOpen: !isDropDownOpen });
  };

  closeModal = () =>
    this.setState({ contributionModalOpen: false });

  closeContributionConfirmationModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showContributionConfirmationModal: false,
      saveAndSubmit: false,
    }))
  }

  updateActionId = (actionId) => {
    this.setState((prevState) => ({
      ...prevState,
      actionId,
    }))
  }

  selectContribution = (contribution) =>
    this.setState({
      selectedContribution: contribution,
      contributionModalOpen: true,
    });

  updateQuestionnaireState = (input_key, value) => {
    const { questionnaireState } = this.state;

    this.setState({
      questionnaireState: {
        ...questionnaireState,
        [input_key]: value,
      },
    });
  };

  isAnonymousActivity = () =>
    this.state.activityData?.settings?.anonymous;

  getParticipantAction = () => {
    const { getParticipantActionsData } = this.props;

    let participantAction;

    if (this.dataHasLoaded(getParticipantActionsData)) {
      participantAction = getParticipantActionsData.data[0];
    }

    return participantAction;
  };

  dataHasLoaded = (state) => !!(state && state.data);

  saveButtonsRow = () => {
    const { isSaving, enableSubmission } = this.state;

    const isAnonymousActivity =
      this.state.activityData?.settings?.anonymous;

    return (
      <div
        className="complete-button"
        style={{ display: "flex" }}>
        {!isSaving ? (
          <>
            {!isAnonymousActivity ? (
              <>
                <div className="button-div">
                  <button
                    type="button"
                    className="btn btn-lg submit-btn mr-2"
                    disabled={
                      (!enableSubmission &&
                        !this.questionnaireHasUpdated()) ||
                      isSaving
                    }
                    onClick={() => this.saveAction()}>
                    {getLabelByName(SAVE_DRAFT)}
                  </button>
                </div>

                <div className="button-div">
                  <button
                    type="button"
                    className="btn btn-lg submit-btn w-100"
                    disabled={!enableSubmission || isSaving}
                    onClick={() => this.saveSubmitAction()}>
                    {getLabelByName(SUBMIT)}
                  </button>
                </div>
              </>
            ) : (
              <div className="button-div">
                <button
                  type="button"
                  className="btn btn-lg submit-btn mr-2"
                  disabled={
                    (!enableSubmission &&
                      !this.questionnaireHasUpdated()) ||
                    isSaving
                  }
                  onClick={() =>
                    this.submitAnonymousContribution()
                  }>
                  {getLabelByName(SUBMIT)}
                </button>
              </div>
            )}

            <div className=" button-div cancel-button">
              <button
                type="button"
                className="btn btn-lg submit-btn w-100"
                onClick={() => this.cancelOnclickHandler()}>
                {`${getLabelByName(CANCEL)}`}
              </button>
            </div>
          </>
        ) : (
          <button
            type="button"
            className="btn btn-lg submit-btn w-100"
            disabled>
            {`${getLabelByName(PROCESSING)}...`}
          </button>
        )}
      </div>
    );
  };

  cancelOnclickHandler = () => {
    const { actionDetails } = this.state;

    if (
      parseInt(actionDetails?.meta.maxSubmissions, 10) >
      1 ||
      actionDetails?.meta.maxSubmissions === "-1"
    ) {
      this.redirectOnSuccessSave();
    } else {
      this.setState({
        showQuestionnaire: false,
        newQuestionnaire: false,
        questionnaireState: {},
      });
    }
    this.setState({
      newQuestionnaire: false,
      questionnaireState: {},
    });
  };

  redirectOnSuccessSave = async () => {
    const { actionDetails, actionId, activityData } =
      this.state;

    const {
      match: {
        params: { id: campaignId, handle },
      },
    } = this.props;

    if (
      parseInt(actionDetails?.meta.maxSubmissions, 10) >
      1 ||
      actionDetails?.meta.maxSubmissions === "-1"
    ) {
      const participantId = this.getParticipantId();

      const isAnonymousActivity =
        activityData?.settings?.anonymous;

      const {
        getParticipantActions: getParticipantAction,
        match: {
          params: { id: activityId },
        },
      } = this.props;

      const query2 = `?q={"actionId":${actionId}}`;

      await getParticipantAction(
        activityId,
        participantId,
        query2,
        isAnonymousActivity,
      );

      this.setState({
        showQuestionnaire: false,
        showListing: true,
        questionnaireState: {},
      });

      return;
    }

    const manageListingsUrl = `/campaign/${campaignId}/${handle}/participate`;

    window.location.href = manageListingsUrl;
  };

  getParticipantId = () => {
    const {
      match: {
        params: { id: campaignId },
      },
      location,
    } = this.props;

    const isAnonymousActivity =
      this.state.activityData?.settings?.anonymous;

    if (isAnonymousActivity && location.state) {
      return location.state?.participantId;
    }

    const {
      profileData: {
        data: { activities },
      },
    } = this.props;

    const participantId = Object.values(activities).find(
      (activity) => activity.activityId === campaignId,
    )?.participantId;

    return participantId;
  };

  isMultiEntryAction = () => {
    const { getActionData } = this.props;
    return (
      this.dataHasLoaded(getActionData) &&
      getActionData.data.meta.maxSubmissions > 1
    );
  };

  questionnaireHasUpdated = () => {
    const { questionnaireState } = this.state;

    return Object.keys(questionnaireState).length > 0;
  };

  saveAction = () => {
    this.setState({
      isSaving: true,
      newQuestionnaire: false,
      questionnaireState: {},
    });
    const participantActionId = this.actionParticipantId();

    if (participantActionId) {
      this.updateAction();
    } else {
      this.postAction();
    }
  };

  submitAnonymousContribution = async () => {
    this.setState({
      saveAndSubmit: true,
      newQuestionnaire: false,
      isSaving: true,
      questionnaireState: {},
    });

    const { activityData } = this.state;

    const response = await this.postAction();

    if (response.status === 200) {
      const isAnonymousActivity =
        activityData?.settings?.anonymous;

      const participantActionData = response.data;

      const {
        match: {
          params: { id: campaignId },
        },
        submitContribution: submitContributionAction,
      } = this.props;

      const participantId = this.getParticipantId();

      const payload = {
        participantActionId: participantActionData.id,
      };

      if (
        isAnonymousActivity &&
        payload.participantActionId
      ) {
        const submitResponse =
          await submitContributionAction(
            campaignId,
            participantId,
            payload,
            isAnonymousActivity,
          );

        if (submitResponse.status === 200) {
          this.setState((prevState) => ({
            ...prevState,
            showContributionConfirmationModal: true,
          }))
          // toast.success(
          //   getLabelByName(
          //     CONTRIBUTION_SUBMITTED_SUCCESSFULLY,
          //   ),
          // );
        }
      }

      this.setState({ isSaving: false });
    }
  };

  saveSubmitAction = () => {
    this.setState({
      saveAndSubmit: true,
      newQuestionnaire: false,
      isSaving: true,
      questionnaireState: {},
    });

    const participantActionId = this.actionParticipantId();

    if (participantActionId) {
      this.updateAction();
    } else {
      this.postAction();
    }
    // toast.success(
    //   getLabelByName(CONTRIBUTION_SAVED_SUCCESSFULLY),
    // );
  };

  postAction = async () => {
    const { props } = this;

    const { id: campaignId } = props.match.params;

    const { actionId, questionnaireState, activityData } =
      this.state;

    const isAnonymousActivity =
      activityData?.settings?.anonymous;

    const participantId = this.getParticipantId();

    const payload = {
      status: "ACTIVE",
      visibility: "PUBLIC",
      data: { ...questionnaireState },
      meta: {},
    };

    const response = await props.postParticipantAction(
      campaignId,
      participantId,
      actionId,
      payload,
      isAnonymousActivity,
    );

    // Submit Contribution only if activity being contributed to is not anonymous
    if (response.status === 200 && !isAnonymousActivity) {
      this.setState((prevState) => ({
        ...prevState,
        showContributionConfirmationModal: true,
      }))
      // toast.success(
      //   getLabelByName(CONTRIBUTION_SAVED_SUCCESSFULLY),
      // );

      this.actionAfterSave(response);
    }

    return response;
  };

  updateAction = async () => {
    const {
      updateParticipantAction: updateParticipant,
      match: {
        params: { id: campaignId },
      },
    } = this.props;

    const { questionnaireState } = this.state;

    const participantId = this.getParticipantId();

    const participantAction = this.getParticipantAction();

    participantAction.data = {
      ...participantAction.data,
      ...questionnaireState,
    };

    const submitResponse = await updateParticipant(
      campaignId,
      participantId,
      participantAction,
      participantAction.actionId,
    );

    if (submitResponse.status === 200) {
      this.setState((prevState) => ({
        ...prevState,
        showContributionConfirmationModal: true,
      }))
      // toast.success(
      //   getLabelByName(
      //     CONTRIBUTION_SUBMITTED_SUCCESSFULLY,
      //   ),
      // );
    }
  };

  actionAfterSave = async (participantActionData) => {
    const {
      match: {
        params: { id: campaignId },
      },
      submitContribution: submitContributionAction,
    } = this.props;

    const { saveAndSubmit } = this.state;

    if (saveAndSubmit) {
      const participantId = this.getParticipantId();

      const payload = {
        participantActionId: participantActionData.data.id,
      };

      const submitResponse = await submitContributionAction(
        campaignId,
        participantId,
        payload,
      );

      if (submitResponse.status === 200) {
        // toast.success(
        //   getLabelByName(
        //     CONTRIBUTION_SUBMITTED_SUCCESSFULLY,
        //   ),
        // );

        this.redirectOnSuccessSave();
      }
    }

    this.setState({ isSaving: false });
  };

  setNoActionFound = () => {
    const { noActionFound } = this.state;

    if (noActionFound === false) {
      this.setState({ noActionFound: true });
    }
  };

  actionParticipantId = () => {
    const { actionId, newQuestionnaire } = this.state;

    const { getParticipantAllActionsData } = this.props;

    let participantActionId;

    if (getParticipantAllActionsData?.data?.length > 0) {
      getParticipantAllActionsData.data.forEach(
        (participantAction) => {
          if (actionId === participantAction.actionId) {
            participantActionId = participantAction.id;
          }
        },
      );
    }

    return !newQuestionnaire ? participantActionId : null;
  };

  getListingsData = () => {
    const { getParticipantActionsData } = this.props;

    let listings = [];
    if (this.dataHasLoaded(getParticipantActionsData)) {
      listings = getParticipantActionsData?.data.sort(
        (a, b) =>
          (b.lastUpdated || b.dateCreated) -
          (a.lastUpdated || a.dateCreated),
      );
    }

    return listings;
  };

  getListingsByStatus = (status) => {
    const listings = this.getListingsData();

    return listings.filter(
      (listing) => listing.status === status,
    );
  };

  getListingsLengthByStatus = (status) => {
    return this.getListingsByStatus(status).length;
  };

  setEnableSubmission = (value) => {
    const { enableSubmission } = this.state;

    if (enableSubmission !== value) {
      this.setState({
        enableSubmission: value,
      });
    }
  };

  renderListingsByStatus = (status) => {
    const {
      getParticipantActionsData,
      match: {
        params: { id: campaignId, handle },
      },
    } = this.props;

    const {
      actionId,
      showQuestionnaire,
      showListing,
      actionDetails,
    } = this.state;

    return (
      <div className="d-flex flex-wrap">
        {this.getListingsByStatus(status).map((listing) => (
          <ListingCard
            listingItem={listing}
            pathParams={{ campaignId, handle, actionId }}
            editListing
            setShowQuestionnaire={(value) =>
              this.setState({ showQuestionnaire: value })
            }
            showQuestionnaire={showQuestionnaire}
            setShowListing={(value) =>
              this.setState({ showListing: value })
            }
            showListing={showListing}
            participantId={this.getParticipantId()}
            participantActionData={
              getParticipantActionsData
            }
            actionDetails={actionDetails}
          />
        ))}
      </div>
    );
  };

  renderCompleteListingsHeader = () => (
    <div className="d-flex justify-content-between">
      <div className="contribution-header-title">
        {getLabelByName(COMPLETE_DRAFT_ENTRIES)}{" "}
        <span className="contribution-length">
          ({this.getListingsLengthByStatus("COMPLETE")})
        </span>
      </div>
      <div className="">
        {this.getListingsLengthByStatus("INCOMPLETE") < 1 &&
          this.renderContributionsLeft()}
      </div>
    </div>
  );

  renderIncompleteListingsHeader = () => {
    return (
      <div className="d-flex justify-content-between">
        <div className="contribution-header-title">
          {getLabelByName(INCOMPLETE_DRAFT_ENTRIES)}{" "}
          <span className="contribution-length">
            ({this.getListingsLengthByStatus("INCOMPLETE")})
          </span>
        </div>
        <div className="">
          {this.getListingsLengthByStatus("INCOMPLETE") >
            0 && this.renderContributionsLeft()}
        </div>
      </div>
    );
  };

  renderContributionsLeft = () => {
    if (!this.getActivityAction()) {
      return null;
    }

    return this.getActivityAction()?.meta.maxSubmissions !==
      "-1" ? (
      <div className="contributions-left">
        {`${this.getContributionsLeftLength()} ${getLabelByName(
          OF_WORD,
        )} ${this.getActivityAction()?.meta.maxSubmissions
          } ${getLabelByName(ENTRIES)} ${getLabelByName(
            REMAINING,
          ).toLowerCase()}`}
      </div>
    ) : (
      ""
    );
  };

  getContributionsLeftLength = () => {
    const { getActionData } = this.props;

    let contributionsLeft = 0;

    if (
      this.dataHasLoaded(getActionData) &&
      this.getActivityAction()
    ) {
      contributionsLeft =
        this.getActivityAction()?.meta.maxSubmissions -
        this.getListingsData().length;
    }
    return contributionsLeft;
  };

  getActivityAction = () => {
    const { getActionData } = this.props;

    return getActionData?.data;
  };

  handleCourseAction = (action) => {
    const { history, location } = this.props;

    history.push(
      `${location.pathname}/course/${action?.id}`,
      {
        from: {
          where: location.pathname,
          action,
          clubId: this.props?.clubData?.data?.id,
          activityData: this.state.activityData,
          participantId: this.getParticipantId(),
        },
      },
    );
  };

  handlePaymentAction = (action) => {
    this.setState({
      paymentView: true,
      paymentActionData: action,
    });
  };

  onClickStepHandler = async (action, index) => {
    this.setState({
      questionnaireId: action?.meta.questionnaireId,
      showQuestionnaire: true,
      actionId: action.id,
      isDropDownOpen: false,
      actionName: action.name,
      actionDescription: action.profile.description,
      currentStep: index + 1,
      actionDetails: action,
      newQuestionnaire: false,
      paymentView: false,
      questionnaireState: {},
    });

    const participantId = this.getParticipantId();

    const isAnonymousActivity =
      this.state.activityData?.settings?.anonymous;

    const {
      getParticipantActions: getParticipantAction,
      match: {
        params: { id: activityId },
      },
    } = this.props;

    const query2 = `?q={"actionId":${action.id}}`;

    await getParticipantAction(
      activityId,
      participantId,
      query2,
      isAnonymousActivity,
    );

    if (
      parseInt(action?.meta.maxSubmissions, 10) > 1 ||
      action?.meta.maxSubmissions === "-1"
    ) {
      this.setState({
        showListing: true,
        showQuestionnaire: false,
      });
    } else {
      this.setState({
        showListing: false,
        showQuestionnaire: true,
      });
    }
  };

  handleOnClickAction = (action, index) => {
    if (action.type === "COURSE") {
      this.handleCourseAction(action);
    }

    if (action.type === "DATA_COLLECTION") {
      this.onClickStepHandler(action, index);
    }

    if (action.type === "PAYMENT") {
      this.handlePaymentAction(action);
    }
  };

  handleStartContribution = () => {
    const { activityData } = this.state;

    if (activityData?.actions?.length > 0) {
      if (activityData?.actions[0].type === "COURSE") {
        return this.handleCourseAction(
          activityData?.actions[0],
        );
      }

      if (activityData?.actions[0].type === "PAYMENT") {
        return this.handlePaymentAction(
          activityData?.actions[0],
        );
      }

      return this.onClickStepHandler(
        activityData.actions[0],
        0,
      );
    }

    return this.setNoActionFound();
  };

  render() {
    const {
      isDropDownOpen,
      activityData,
      showQuestionnaire,
      actionName,
      currentStep,
      questionnaireId,
      reviewMode,
      allParticipantActions,
      noActionFound,
      actionId,
      selectedContribution,
      contributionModalOpen,
      showListing,
      newQuestionnaire,
      actionDescription,
      actionDetails,
      paymentView,
      paymentActionData,
    } = this.state;

    const {
      getActivityLoading,
      clubData,
      participantContributionData,
      match: {
        params: { id: activityId },
      },
      getParticipantContributionAction:
      getUserContributionsAction,
    } = this.props;

    if (!activityData && getActivityLoading) {
      return <Loading />;
    }

    if (noActionFound) {
      toast.warn(getLabelByName(NO_CONTRIBUTION_STEPS));
    }

    return (
      <>
        <Navbar pathname="explore" />
        <section className="contribute-section">
          {/* ================= LEFT SIDEBAR =================  */}
          <div className="left-div">
            <div className="left-title">
              <div className="publications">
                <img
                  src={
                    activityData?.profile?.picture ||
                    activityDefaultImage
                  }
                  alt=""
                  style={{ backgroundColor: "#e8daca" }}
                />
                <div className="profile">
                  <button
                    type="button"
                    style={{
                      border: "none",
                      outline: "none",
                      background: "none",
                      textAlign: "start",
                    }}
                    onClick={async () => {
                      this.setState({
                        showListing: false,
                        showQuestionnaire: false,
                        questionnaireState: {},
                      });
                      await getUserContributionsAction(
                        activityId,
                        this.getParticipantId(),
                      );
                    }}>
                    <h1>{activityData?.name}</h1>
                  </button>
                  <div className="profile-info">
                    <img
                      src={clubData?.data?.profile?.picture}
                      alt=""
                    />

                    <h1>{clubData?.data?.name}</h1>
                  </div>
                </div>
              </div>
              <div className="contribution-timer">
                {(activityData?.endDate !== null &&
                  activityData?.endDate * 1000 <
                  new Date() && (
                    <p>
                      {`${getLabelByName(
                        ACTIVITY,
                      )} ${getLabelByName(
                        HAS,
                      )} ${getLabelByName(ENDED)}`}
                    </p>
                  )) ||
                  (activityData?.endDate !== null && (
                    <>
                      <p>
                        {`${getLabelByName(
                          ACTIVITY,
                        )} ${getLabelByName(END_ON)} `}
                        {moment(
                          activityData?.endDate * 1000,
                        ).format("DD MMM YYYY hh:mm ")}
                      </p>
                    </>
                  ))}
              </div>
            </div>

            <div className="responsiveness-only">
              <div className="arrow-down-dropdown">
                <button
                  type="button"
                  onClick={() => this.setDropDown()}>
                  <KeyboardArrowDownIcon />
                </button>
              </div>
            </div>

            <div
              className={`${isDropDownOpen
                  ? "left-body show-steps"
                  : "left-body not-show-step"
                }`}>
              {activityData?.actions &&
                activityData.actions.map(
                  (action, index) =>
                    action.status === "ACTIVE" && (
                      <div className="contribute-step">
                        <button
                          type="button"
                          onClick={() => {
                            window.scrollTo(0, 0);
                            this.handleOnClickAction(
                              action,
                              index,
                            )
                          }
                          }>
                          <p>{`${getLabelByName(STEP)} ${index + 1
                            }`}</p>
                          <h1>{action.name}</h1>
                        </button>
                      </div>
                    ),
                )}
              {/* 
              <div className="contribute-step">
                <button
                  type="button"
                  onClick={() =>
                    this.handlePaymentAction(action)
                  }>
                  <p>Step 6</p>
                  <h1>Payment Action</h1>
                </button>
              </div> */}

              <div className="exit-button">
                <button type="button">
                  <Link
                    to={`/campaign/${activityData?.id}/${activityData?.handle}`}
                    style={{ color: "#f38f46" }}>
                    {`${getLabelByName(
                      EXIT,
                    )} ${getLabelByName(
                      CONTRIBUTION,
                    ).toLowerCase()}`}
                  </Link>
                </button>
              </div>
            </div>
          </div>
          {/* ================= LEFT SIDEBAR =================  */}

          {/* ================= MAIN CONTENT =================  */}
          <div className="right-div px-3">
            {paymentView ? (
              <PaymentView
                action={paymentActionData}
                participantId={this.getParticipantId()}
              />
            ) : showListing ? (
              <>
                <div className="action-step-header">
                  <div className="current-step">
                    <h1>
                      {`${getLabelByName(
                        STEP,
                      )} ${currentStep} : ${actionName}`}
                    </h1>
                    <p>{actionDescription}</p>
                  </div>

                  {(this.getContributionsLeftLength() > 0 ||
                    this.getActivityAction()?.meta
                      .maxSubmissions === "-1") && (
                      <div className="add-listing-button">
                        <button
                          type="button"
                          onClick={() => {
                            this.setState({
                              questionnaireId:
                                this.getActivityAction()?.meta
                                  .questionnaireId,
                              showQuestionnaire: true,
                              actionId,
                              isDropDownOpen: false,
                              actionName,
                              showListing: false,
                              newQuestionnaire: true,
                              questionnaireState: {},
                            });
                          }}>
                          {`${getLabelByName(
                            ADD,
                          )} ${getLabelByName(
                            NEW,
                          ).toLowerCase()} ${getLabelByName(
                            ENTRY,
                          )}`}
                        </button>
                      </div>
                    )}
                </div>

                <div className="manage-listings-card">
                  {this.getListingsLengthByStatus(
                    "INCOMPLETE",
                  ) > 0 && (
                      <div className="mb-4 incomplete-contributions-section">
                        {this.renderIncompleteListingsHeader()}
                        <hr />
                        {this.renderListingsByStatus(
                          "INCOMPLETE",
                        )}
                      </div>
                    )}
                  <div className="mb-4 complete-contributions-section">
                    {this.renderCompleteListingsHeader()}
                    <hr />
                    {this.renderListingsByStatus(
                      "COMPLETE",
                    )}
                  </div>
                </div>
              </>
            ) : showQuestionnaire && clubData ? (
              <>
                <div className="current-step">
                  <h1>
                    {`${getLabelByName(
                      STEP,
                    )} ${currentStep} : ${actionName}`}
                  </h1>
                  <p>{actionDescription}</p>
                </div>
                <>
                  <Questionnaire
                    questionnaireId={questionnaireId}
                    clubId={clubData?.data?.id}
                    updateQuestionnaireState={
                      this.updateQuestionnaireState
                    }
                    editAction={
                      !newQuestionnaire &&
                      this.getParticipantAction()
                    }
                    reviewMode={reviewMode}
                    allParticipantActions={
                      allParticipantActions
                    }
                    setEnableSubmission={
                      this.setEnableSubmission
                    }
                    actionId={actionId}
                    participantId={this.getParticipantId()}
                    participantActionId={this.actionParticipantId()}
                    activityId={activityData?.id}
                    actionDetails={actionDetails}
                    newQuestionnaire={newQuestionnaire}
                  />
                  <div className="endSaveButtons">
                    {this.saveButtonsRow()}
                  </div>
                </>
              </>
            ) : participantContributionData &&
              participantContributionData?.data?.length >
              0 ? (
              <div className="contributions-content">
                <p>
                  {getLabelByName(
                    PARTICIPANT_WELCOME_MESSAGE,
                  )}
                </p>
                <div className="contribution-cards-content">
                  {participantContributionData &&
                    participantContributionData.data.map(
                      (contribution, index) => (
                        <div className="contribution-card-area">
                          <ContributionCard
                            contributionData={contribution}
                            selectContribution={
                              this.selectContribution
                            }
                            contributionIndex={index}
                          />
                        </div>
                      ),
                    )}
                </div>
              </div>
            ) : (
              <div className="inside-content">
                <img src={noContributionImage} alt="" />
                <p>
                  {getLabelByName(
                    NO_CONTRIBUTION_SUBMITTED_MESSAGE,
                  )}
                </p>
                <button
                  type="button"
                  onClick={this.handleStartContribution}>
                  {activityData?.meta
                    ?.contribute_call_to_action ||
                    `${getLabelByName(
                      START,
                    )} ${getLabelByName(CONTRIBUTING)}`}
                </button>
              </div>
            )}
          </div>
          {/* ================= MAIN CONTENT =================  */}

          {/* View Contribution Modal */}
          {contributionModalOpen && (
            <ViewContributionModal
              contribution={selectedContribution}
              isModalOpen={contributionModalOpen}
              closeModal={this.closeModal}
            />
          )}
          {/* Contribution Confirmation Modal */}
          <ContributionConfirmationModal
            isModalOpen={this.state.showContributionConfirmationModal}
            activityData={this.state.activityData}
            actionId={this.state.actionId}
            isSubmit={this.state.saveAndSubmit}
            updateActionId={this.updateActionId}
            submitAction={this.saveSubmitAction}
            closeModal={this.closeContributionConfirmationModal}
          />
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profileData: state.authReducer.data,

  getActivityData: state.getActivityReducer.getActivityData,

  getActivityLoading:
    state.getActivityReducer.getActivityLoading,

  clubData: state.instituteReducer.instituteData,

  participantsData:
    state.getParticipantsReducer.getParticipantsData,

  participantContributionData:
    state.getParticipantContributionReducer
      .participantContributionData,

  participantContributionLoading:
    state.getParticipantContributionReducer
      .participantContributionLoading,

  participantContributionError:
    state.getParticipantContributionReducer
      .participantContributionError,

  getParticipantActionsDataError:
    state.getParticipantActionsReducer
      .getParticipantActionsDataError,

  getParticipantActionsData:
    state.getParticipantActionsReducer
      .getParticipantActionsData,

  getParticipantActionsStatus:
    state.getParticipantActionsReducer
      .getParticipantActionsStatus,

  getParticipantActionsLoading:
    state.getParticipantActionsReducer
      .getParticipantActionsLoading,

  getParticipantAllActionsDataError:
    state.getParticipantAllActionsReducer
      .getParticipantAllActionsDataError,

  getParticipantAllActionsData:
    state.getParticipantAllActionsReducer
      .getParticipantAllActionsData,

  getParticipantAllActionsStatus:
    state.getParticipantAllActionsReducer
      .getParticipantAllActionsStatus,

  postParticipantActionDataError:
    state.postParticipantActionReducer
      .postParticipantActionDataError,

  postParticipantActionData:
    state.postParticipantActionReducer
      .postParticipantActionData,

  postParticipantActionStatus:
    state.postParticipantActionReducer
      .postParticipantActionStatus,

  updateParticipantActionDataError:
    state.updateParticipantActionReducer
      .updateParticipantActionDataError,

  updateParticipantActionData:
    state.updateParticipantActionReducer
      .updateParticipantActionData,

  updateParticipantActionStatus:
    state.updateParticipantActionReducer
      .updateParticipantActionStatus,

  submitContributionDataError:
    state.submitContributionReducer
      .submitContributionDataError,

  submitContributionData:
    state.submitContributionReducer.submitContributionData,

  submitContributionStatus:
    state.submitContributionReducer
      .submitContributionStatus,

  getActionDataError:
    state.getActionReducer.getActionDataError,

  getActionData: state.getActionReducer.getActionData,

  getActionStatus: state.getActionReducer.getActionStatus,
});

const mapDispatchToProps = {
  getActivity,
  getParticipants,
  participateCampaign,
  getLanding,
  getAction,
  getSessionAction,
  getInstitute,
  getParticipantContributionAction,
  getParticipantActions,
  postParticipantAction,
  updateParticipantAction,
  submitContribution,
  getParticipantAllActions,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(UserContribute);
