import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Clear } from '@material-ui/icons';
import { SEARCH_LIBRARY } from '../../../../helpers/constants';
import { getLabelByName } from '../../../../helpers/helperFunctions';
import searchWhite from '../../../../assets/icons/search-white.svg';


class LibrarySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  submitSearch = () => {};

  render() {
    const { searchTerm } = this.props;

    return (
      <div className="library-search">
        <form className="form" onSubmit={this.submitSearch}>
          <div className="input-group mb-2">
            <input
              type="text"
              className="form-control"
              id="inputSearch"
              placeholder={getLabelByName(SEARCH_LIBRARY)}
              name="searchTerm"
              value={searchTerm}
              onChange={(e) => this.props.updateSearchTerm(e.target.value)}
            />
            {searchTerm && (
              <div className="input-group-append">
                <button
                  id="clear-search-btn"
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() => this.props.clearSearchTerm()}
                >
                  <Clear />
                </button>
              </div>
            )}
            <div className="input-group-append">
              <button
                id="search-btn"
                className="btn btn-outline-secondary"
                type="submit"
              >
                <img src={searchWhite} alt="search-icon" />
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default LibrarySearch;
