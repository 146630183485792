import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  CheckRounded,
  FileCopyRounded,
  ReplyRounded,
} from "@material-ui/icons";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { getLabelByName } from "../../helpers/helperFunctions";
import {
  colors,
  COPY,
  LINK,
  SHARE,
} from "../../helpers/constants";

class ShareResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkCopied: false,
    };
  }

  copyLink = (linkUrl) => {
    navigator.clipboard.writeText(linkUrl);
    this.setState({ linkCopied: true });
    setTimeout(() => {
      this.setState({ linkCopied: false });
    }, 3000);
  };

  render() {
    const { postId, postLink, resourceType, isMobile } =
      this.props;
    const { linkCopied } = this.state;
    const domain = window.location.origin;
    const linkUrl = domain + postLink;

    return (
      <>
        <div
          className="share-button"
          data-toggle="modal"
          data-target={`.${resourceType?.toLowerCase()}-${postId}-${
            isMobile ? "mobile" : ""
          }`}
          style={{ cursor: "pointer" }}>
          <ReplyRounded
            className="share-button-icon"
            style={{ transform: "rotateY(180deg)" }}
          />
          <span className="share-button-label">
            {getLabelByName(SHARE)}
          </span>
        </div>
        <div
          className={`modal fade ${resourceType?.toLowerCase()}-${postId}-${
            isMobile ? "mobile" : ""
          }`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="shareResouceModal"
          aria-hidden="true"
          id="shareResouceModal">
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document">
            <div className="modal-content modal-card p-4">
              <div className="modal-title mb-2 font-weight-bold">
                {getLabelByName(SHARE)} {resourceType}
              </div>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  className="form-control mr-2 share-link-input"
                  disabled
                  value={linkUrl}
                />
                {!linkCopied ? (
                  <FileCopyRounded
                    titleAccess={`${getLabelByName(
                      COPY,
                    )} ${getLabelByName(LINK)}`}
                    style={{
                      color: colors.primaryColor,
                      cursor: "pointer",
                    }}
                    onClick={() => this.copyLink(linkUrl)}
                  />
                ) : (
                  <CheckRounded
                    titleAccess="Link Copied"
                    style={{ color: colors.primaryColor }}
                  />
                )}
              </div>
              <div className="mt-3">
                <WhatsappShareButton
                  className="mr-3"
                  url={linkUrl}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <FacebookShareButton
                  className="mr-3"
                  url={linkUrl}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton
                  className="mr-3"
                  url={linkUrl}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <TelegramShareButton
                  className="mr-3"
                  url={linkUrl}>
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
                <LinkedinShareButton
                  className="mr-3"
                  url={linkUrl}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ShareResource.propTypes = {
  postLink: PropTypes.string,
  postId: PropTypes.any,
};

export default ShareResource;
