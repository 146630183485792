import React, {useEffect, useState} from 'react'
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {confirmVerification} from "../redux/actions/usersActions"
import Loading from "./generics/Loading/Loading"

const Verify = ({confirmUserVerification}) => {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const verificationToken = urlParams.get("token")
  const [isVerifiedModal, setIsVerifiedModal] = useState(true);

  const setVerified = () => setTimeout(history.push("/"), 2000)

  const closeIsVerifiedModal = () => {
    setIsVerifiedModal(false)
    setVerified()
  }

  useEffect(async () => {
    if (!verificationToken){
      return setVerified()
    }
    const verResponse = await confirmUserVerification({token: verificationToken})

    if (verResponse.status === 500){
        toast.error(verResponse?.error?.message || "Verfication error, try again")
        return setVerified()
    }
    if (verResponse.status === 200){
        return setIsVerifiedModal(true)
    }
  
  }, [])
  return (
    <>
    {isVerifiedModal ?
    <Modal
      size="md"
      centered
      show={isVerifiedModal}
      onHide={closeIsVerifiedModal}
    >
      <div className="create-club-header">
          <div className="header-left">
            <p className="header-modal-title">
              Your account has been verified successfully
            </p>
          </div>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            style={{ marginTop: "-5rem", outline: "none" }}
            onClick={closeIsVerifiedModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="row d-flex justify-content-around create-club-buttons">
          <>
            <Link
              to='#'>
              <button
                type="button"
                onClick={closeIsVerifiedModal}
                className="btn btn-lg submit-btn">
                Continue
              </button>
            </Link>
          </>
        </div>
    </Modal>
      : <Loading />
    }
    </>
    
  )
}

export const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {confirmUserVerification: confirmVerification})(Verify);
