/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { FiberManualRecord, CalendarToday, AccessTime } from '@material-ui/icons';
import userIcon from '../../assets/icons/user.svg';
import { getLabelByName } from '../../helpers/helperFunctions';
import {
  ACTIVITY, CAMPAIGN, COUNTRY, LAST, MEMBER, MISSION_STATEMENT, STARTED, WHAT_WE_DO, WHO_CAN_JOIN,
  ABOUT_CLUB, VIEW_CLUB_INFORMATION,
} from '../../helpers/constants';
import timeIcon from '../../assets/icons/time.svg';
import campaignIcon from '../../assets/icons/campaign-dark.svg';
import membersIcon from '../../assets/manage-members.svg';

class ClubInfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="modal fade club-info-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="clubInfoModal"
        aria-hidden="true"
        id="clubInfoModal"
        style={{ background: 'unset' }}
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content modal-card p-4 institute-card mt-0">
            <div className="d-flex align-items-center justify-content-between">
              <div className="modal-title">
                <div className="clubs-title">
                  {getLabelByName(ABOUT_CLUB)}
                </div>
                <div className="">{getLabelByName(VIEW_CLUB_INFORMATION)}</div>
              </div>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" style={{ fontSize: '40px', color: '#C6A177' }}>&times;</span>
              </button>
            </div>
            {this.getInstituteData() && (
              <div>
                <div className="institute-card-details">
                  <div className="card-details-left">
                    <div className="institute-picture">
                      <img
                        className="img-fluid rounded-circle mr-3"
                        src={
                          this.getInstituteData().profile.picture
                            ? this.getInstituteData().profile.picture
                            : userIcon
                        }
                        alt="club-avatar"
                        style={{
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                    <div className="institute-name">
                      {this.getInstituteData()
                        && `${this.getInstituteData().name}`}
                    </div>
                    <div className="institute-status">
                      <FiberManualRecord
                        style={{
                          color:
                            this.getInstituteData().status === 'ACTIVE'
                              ? '#10A728'
                              : '#FF0D0D',
                          width: '15px',
                          marginRight: '0.5rem',
                        }}
                      />
                      {this.getInstituteData().status}
                    </div>
                  </div>
                  <div className="card-details-right">
                    <div className="card-details-description">
                      <h5>{getLabelByName(WHAT_WE_DO)}</h5>
                      {this.getInstituteData().profile.description}
                    </div>
                    <div className="card-details-statement">
                      <h5>{getLabelByName(MISSION_STATEMENT)}</h5>
                      {this.getInstituteData().profile.mission_statement}
                    </div>
                    <div className="card-details-statement">
                      <h5>{getLabelByName(WHO_CAN_JOIN)}</h5>
                      {this.getInstituteData().profile.objectives}
                    </div>
                  </div>
                </div>
                <div className="institute-card-stats" style={{ margin: '-1.5rem' }}>
                  <div>
                    <div className="stats-icon">
                      <CalendarToday className="icon" />
                    </div>
                    <div className="card-stats-date-created">
                      {`${getLabelByName(STARTED)}`}
                      <div className="stats-item">{this.formatDate(this.getInstituteData().dateCreated)}</div>
                    </div>
                  </div>
                  <div>
                    <div className="stats-icon">
                      <AccessTime className="icon" />
                    </div>
                    <div className="card-stats-last-activity">
                      {`${getLabelByName(LAST)} ${getLabelByName(ACTIVITY)}`}
                      <div className="stats-item">{this.formatDate(this.getInstituteData().lastUpdated)}</div>
                    </div>
                  </div>
                  <div>
                    <div className="stats-icon">
                      <img src={campaignIcon} alt="" width="35px" />
                    </div>
                    <div className="card-stats-campaigns">
                      {`Club ${getLabelByName(CAMPAIGN)}`}
                      <div className="stats-item">
                        {this.getInstituteData().campaigns}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="stats-icon">
                      <img src={membersIcon} alt="" width="35px" />
                    </div>
                    <div className="card-stats-members">
                      Participants
                      <div className="stats-item">
                        {this.getInstituteData().members}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  dataHasLoaded = (state) => !!(state && state.data);

  getInstituteData = () => this.dataHasLoaded(this.props.instituteData) && this.props.instituteData.data;

  formatDate = (date) => moment(date * 1000).format('DD MMMM YYYY');
}

ClubInfoModal.propTypes = {
  instituteData: PropTypes.any,
};

const mapStateToProps = (state) => ({
  instituteDataError: state.instituteReducer.instituteDataError,
  instituteData: state.instituteReducer.instituteData,
  instituteStatus: state.instituteReducer.instituteStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(ClubInfoModal);
