import {
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
  MARK_NOTIFICATION_SUCCESS,
  MARK_NOTIFICATION_ERROR,
  ADD_DEVICE_TOKEN_SUCCESS,
  ADD_DEVICE_TOKEN_ERROR,
} from "./types";
import apiCall from "../../helpers/apiCall";
import { getHeaders } from "../../helpers/getHeaders";

const notificationsType = (type, payload) => ({
  type,
  payload,
});

export const getNotifications =
  (
    userId,
    query,
    headers = getHeaders(
      null,
      `/user/${userId}/notifications${query || ""}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .get(`/user/${userId}/notifications${query || ""}`, {
        headers,
      })
      .then((response) => {
        dispatch(
          notificationsType(
            GET_NOTIFICATIONS_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          notificationsType(GET_NOTIFICATIONS_ERROR, err),
        );
      });
  };

export const markNotification =
  (
    payload,
    userId,
    notificationId,
    headers = getHeaders(
      payload,
      `/user/${userId}/notification/${notificationId}`,
    ),
  ) =>
  (dispatch) => {
    apiCall
      .put(
        `/user/${userId}/notification/${notificationId}`,
        payload,
        { headers },
      )
      .then((response) => {
        dispatch(
          notificationsType(
            MARK_NOTIFICATION_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          notificationsType(MARK_NOTIFICATION_ERROR, err),
        );
      });
  };

export const addFirebaseDeviceToken =
  (
    payload,
    headers = getHeaders(
      payload,
      `/user/push/service/firebase`,
    ),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.post(
        `/user/push/service/firebase`,
        payload,
        {
          headers,
        },
      );

      dispatch(
        notificationsType(
          ADD_DEVICE_TOKEN_SUCCESS,
          response.data,
        ),
      );

      return response.data;
    } catch (error) {
      dispatch(notificationsType(ADD_DEVICE_TOKEN_ERROR));
    }
  };
