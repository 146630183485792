import "./ContributionComment.scss";
import "react-autocomplete-input/dist/bundle.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CancelOutlined as CancelOutlinedIcon } from "@mui/icons-material";
import TextInput from "react-autocomplete-input";
import moment from "moment";
import {
  contributionComment,
  getContributionComments,
} from "../../../../redux/actions/contributionsActions";
import { getInstituteMembers } from "../../../../redux/actions/institutesActions";
import { getAction } from "../../../../redux/actions/campaignsActions";
import { getProfileDataFromLocalStorage } from "../../../../helpers/helperFunctions";

const ContributionComment = ({
  closeModal,
  contribution,
}) => {
  const [postComment, setPostComment] = useState("");

  const [userIds, setUserIds] = useState([]);

  const [clubMembers, setClubMembers] = useState([]);

  const [
    commentButtonLoading,
    setCommentButtonLoading,
  ] = useState(false);

  const [clubMembersIndex, setClubMembersIndex] = useState(
    {},
  );

  const dispatch = useDispatch();

  const contributionComments = useSelector((state) => {
    return state.getContributionsCommentsReducer
      .getContributionsCommentsData?.data;
  });

  const activityData = useSelector((state) => {
    return state.getActivityReducer
    // return state.getActivityReducer.getActivityData.data;
  });


  const { activityId, actionId } = contribution;

  const getContributionCommentsAction = async () => {
    await dispatch(
      getContributionComments(contribution.id),
    );
  };

  const getActionAction = async () => {
    await dispatch(getAction(activityId, actionId));
  };

  const getInstituteMembersAction = async () => {
    await dispatch(
      getInstituteMembers(activityData.parentId),
    );
  };

  useEffect(() => {
    getActionAction();
    getInstituteMembersAction();
    getContributionCommentsAction();
  }, []);

  const clubMembersData = useSelector((state) => {
    return state.clubMembersReducer.clubMembersData?.data;
  });

  const postContributionCommentStatus = useSelector(
    (state) =>
      state.postContributionsCommentReducer
        .postContributionsCommentStatus,
  );

  useEffect(() => {
    // set club members according to the activity of a certain action,
    // this helps us to get people(members) you can mention in a comment
    const memberIndex = {};

    const members = [];

    if (clubMembersData) {
      clubMembersData.forEach((member) => {
        const memberName = `${member.user.profile.firstName.replace(
          /\s/g,
          "",
        )} ${member.user.profile.lastName.replace(
          /\s/g,
          "",
        )}`;

        members.push(memberName);

        memberIndex[`@${memberName}`] = member.user.id;
      });
    }
    setClubMembers(members);
    setClubMembersIndex(memberIndex);

    // make scroll starts from the bottom
    if (contributionComments) {
      const commentsSection = document.getElementById(
        "comments-section",
      );

      const isScrolledToBottom =
        commentsSection.scrollHeight -
          commentsSection.clientHeight <=
        commentsSection.scrollTop + 1;

      if (!isScrolledToBottom) {
        commentsSection.scrollTop =
          commentsSection.scrollHeight -
          commentsSection.clientHeight;
      }
    }

    // actions to dispatch after the posting a comment
    if (postContributionCommentStatus === "success") {
      getContributionCommentsAction();
      setPostComment("");
      setCommentButtonLoading(false);
    }
  }, [
    clubMembersData,
    contributionComments,
    postContributionCommentStatus,
  ]);

  const profileData = getProfileDataFromLocalStorage().data;

  const payload = {
    authorId: profileData.id,
    content: {
      body: postComment,
    },
    files: {},
    images: {},
    mentions: userIds,
    parentId: contribution.id,
    status: "APPROVED",
    videos: {},
    visibility: "PUBLIC",
  };

  const handleChange = (value) => {
    const newUserIds = [];

    value.split(" ").forEach((word) => {
      if (word && clubMembersIndex[word]) {
        const newId = clubMembersIndex[word];

        const alreadyNewUserId = newUserIds.find(
          (id) => id === newId,
        );

        if (!alreadyNewUserId) {
          newUserIds.push(newId);
        }
      }
    });

    setPostComment(value);
    setUserIds(newUserIds);
  };

  return (
    <>
      <div className="contribution-comment-card">
        <div
          className="cancel-icon"
          aria-hidden
          onClick={closeModal}>
          <CancelOutlinedIcon />
        </div>
        <div className="contribution-comment">
          <div
            className="comments-section"
            id="comments-section">
            {contributionComments &&
              contributionComments.map((comment) => (
                <div
                  key={contributionComments.indexOf(
                    comment,
                  )}>
                  <div className="top-content">
                    <img
                      src={comment.author.profile.picture}
                      alt=""
                    />
                    <div className="contribution-name">
                      <h3>{`${comment.author.profile.firstName} ${comment.author.profile.lastName}`}</h3>
                      <p>
                        {moment(
                          comment.dateCreated * 1000,
                        ).format("DD MMM YYYY, hh:mm")}
                      </p>
                    </div>
                  </div>
                  <div className="comment-content">
                    <p>{comment.content.body}</p>
                  </div>
                </div>
              ))}
          </div>
          <div className="comment-input-section">
            <TextInput
              maxOptions={0}
              options={clubMembers}
              placeholder="Add a comment"
              onChange={(value) => handleChange(value)}
              value={postComment}
            />
          </div>
          <div className="reply-button">
            <button
              type="button"
              onClick={() => {
                dispatch(
                  contributionComment(
                    contribution.id,
                    payload,
                  ),
                );
                setCommentButtonLoading(true);
              }}>
              {commentButtonLoading ? "Posting..." : "Post"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContributionComment;
