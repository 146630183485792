/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ArrowBackIos } from "@material-ui/icons";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { getSessionAction } from "../../redux/actions/authActions";
import {
  checkSession,
  getLabelByName,
  getProfileDataFromLocalStorage,
} from "../../helpers/helperFunctions";
import { BACK } from "../../helpers/constants";
import FindResource from "./FindResource/FindResource";
import PromotionForm from "./PromotionForm/PromotionForm";

class Promote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileData: getProfileDataFromLocalStorage(),
      pathname: this.props.location.pathname,
      selectedResource: null,
      pageTracking: "find-resource",
    };
  }

  render() {
    const { resourceType } = this.props.match.params;
    const { selectedResource, pageTracking } = this.state;

    return (
      <>
        <Navbar pathname="create-article" />
        <div className="club-page">
          <div className="container">
            <div className="buttons-row">
              <div id="back-link">
                <div
                  className="d-flex justify-content-center align-items-center"
                  onClick={() =>
                    this.props.history.goBack()
                  }
                  style={{ cursor: "pointer" }}>
                  <ArrowBackIos className="arrow-back-icon" />
                  {"  "}
                  {getLabelByName(BACK)}
                </div>
              </div>
            </div>

            {pageTracking === "find-resource" && (
              <FindResource
                resourceType={resourceType}
                selectedResource={selectedResource}
                selectResource={this.selectResource}
                pageTracking={pageTracking}
                setPageTracking={this.setPageTracking}
              />
            )}

            {pageTracking === "create-promo" && (
              <PromotionForm
                resourceType={resourceType}
                selectedResource={selectedResource}
                setPageTracking={this.setPageTracking}
              />
            )}
          </div>
          <div className="footer-block">
            <div className="footer-component">
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  }

  async UNSAFE_componentWillMount() {
    const { props, state } = this;
    const { profileData, pathname } = state;
    if (!profileData || profileData.status !== 200) {
      window.location.href = `/login?redirect_to=${pathname}`;
    }

    if (this.props.location?.state?.resource) {
      this.selectResource(
        this.props.location?.state?.resource,
      );

      this.setPageTracking("create-promo");
    }

    await props.getSessionAction();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { props, state } = this;
    const { pathname } = state;
    const { status, data } = nextProps;

    checkSession(data, status, pathname, props);

    if (
      nextProps.location?.state?.resource !==
      props.location?.state?.resource
    ) {
      this.selectResource(
        nextProps.location?.state?.resource,
      );

      this.setPageTracking("create-promo");
    }
  }

  selectResource = (Resource) => {
    this.setState({ selectedResource: Resource });
  };

  setPageTracking = (page) => {
    this.setState({ pageTracking: page });
  };
}

Promote.propTypes = {
  getSessionAction: PropTypes.func,
  resourceType: PropTypes.any,
  history: PropTypes.any,
  location: PropTypes.any,
  match: PropTypes.any,
  status: PropTypes.any,
  data: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { getSessionAction }),
)(Promote);
