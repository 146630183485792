import React, { Component } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import {
  convertToHTML,
  convertFromHTML,
} from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FileUpload from "../../../generics/FileUpload";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import { LABEL } from "../../../../helpers/constants";
import FilePreview from "../../../generics/FilePreview";

class LabelInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      imageFiles: [],
      imagePreview: [],
      imagesToRemove: [],
      isUploading: false,
    };
  }

  async UNSAFE_componentWillMount() {
    const { props } = this;

    if (props.content) {
      const content = props.content;

      this.setState({
        editorState: EditorState.createWithContent(
          convertFromHTML(content || "<p></p>"),
        ),
      });
    }
  }

  handleEditorChange = (content) => {
    this.setState({ editorState: content });
    this.convertContentToHTML();
  };

  convertContentToHTML = () => {
    const { handleQuestionChange, questionIndex } =
      this.props;

    const currentContentAsHTML = convertToHTML(
      this.state.editorState.getCurrentContent(),
    );

    handleQuestionChange(
      currentContentAsHTML,
      "content",
      questionIndex,
    );
  };

  multiFileChange = (e, fileType) => {
    const { imageFiles, imagePreview } = this.state;
    const { handleImageChange, questionIndex } = this.props;

    let selectedFiles = [];

    if (fileType === "imageFiles") {
      selectedFiles = imageFiles;
    }

    let lastIndex = 0;
    let newFiles = [];

    if (selectedFiles.length > 0) {
      lastIndex =
        selectedFiles[selectedFiles.length - 1].index;
    }

    [...e.target.files].forEach((fileItem) => {
      lastIndex += 1;
      newFiles = [
        ...newFiles,
        {
          index: lastIndex,
          fileItem,
        },
      ];

      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        this.setState({
          imagePreview: [
            ...imagePreview,
            {
              index: lastIndex,
              fileData: fileReader.result,
            },
          ],
        });
      };

      fileReader.readAsDataURL(fileItem);
    });

    this.setState({
      [fileType]: [...selectedFiles, ...newFiles],
    });

    handleImageChange(
      [...selectedFiles, ...newFiles],
      fileType,
      questionIndex,
    );
  };

  removeFile = (
    image,
    fileType,
    previewList,
    filesList,
  ) => {
    const { handleImageChange, questionIndex } = this.props;

    const newPreview = previewList.filter(
      (previewItem) => previewItem !== image,
    );

    const selectedImage = filesList.filter(
      (fileItem) => fileItem.index === image.index,
    );

    const newSelectedFiles = filesList.filter(
      (selectedItem) => selectedItem !== selectedImage[0],
    );

    this.setState({
      [`${fileType}Preview`]: newPreview,
      [`${fileType}Files`]: newSelectedFiles,
    });

    handleImageChange(
      newSelectedFiles,
      `${fileType}Files`,
      questionIndex,
    );
  };

  removeExistingFile = (key, fileType, filesToRemove) => {
    const { handleDeleteLabelImage, questionIndex } =
      this.props;

    // this.setState({
    //   [`${fileType}sToRemove`]: [...filesToRemove, key],
    // });

    handleDeleteLabelImage("", "labelImage", questionIndex);
  };

  render() {
    const {
      isUploading,
      imageFiles,
      imagesToRemove,
      imagePreview,
    } = this.state;

    const { labelImage } = this.props;

    const bodyImagesObjects = [
      {
        key: "labelImage",
        value: labelImage,
      },
    ];

    return (
      <div className="activity-post mb-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="card-header-title mb-0">
            <h5>{getLabelByName(LABEL)} </h5>
          </div>

          <div className="post-buttons">
            <div className="actions-buttons">
              <FileUpload
                componentId="customFileImage"
                fileType="image"
                accept="image/*"
                multiFileChange={this.multiFileChange}
              />
            </div>
          </div>
        </div>

        <Editor
          editorState={this.state.editorState}
          onEditorStateChange={this.handleEditorChange}
          wrapperClassName="wrapper-class px-0"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          toolbarHidden
        />

        <div className="d-flex flex-wrap">
          {!labelImage ? (
            <FilePreview
              isUploading={isUploading}
              fileType="image"
              listFiles={imageFiles}
              listPreview={imagePreview}
              removeFile={this.removeFile}
            />
          ) : (
            <FilePreview
              isUploading={isUploading}
              fileType="image"
              filesToRemove={imagesToRemove}
              listPreview={bodyImagesObjects}
              removeExistingFile={this.removeExistingFile}
              existingContent={true}
            />
          )}
        </div>
      </div>
    );
  }
}

export default LabelInput;
