import React, { Component } from "react";
import ClubContentBody from "./ClubContentBody/ClubContentBody";
import ClubContentNav from "./ClubContentNav/ClubContentNav";

class ClubContent extends Component {
  render() {
    const { category } = this.props.match.params;

    return (
      <div className="settings-container row mx-0">
        <div className="manage-web-content px-4 w-100">
          <div className="web-content-header">
            <div className="manage-web-content-title">
              <h1>Content</h1>
            </div>
          </div>

          <div className="web-content-nav">
            <ClubContentNav />

            <ClubContentBody category={category} />
          </div>
        </div>
      </div>
    );
  }
}

export default ClubContent;
