import React from "react";
import { useWizardContext } from "./wizardContextProvider";

const WizardHeader = ({ title, description }) => {
  const [wizardState] = useWizardContext();

  const { name, isClone } = wizardState;

  const outputName = name || title;

  return (
    <div className="output-modal-title p-x-50">
      <h1>{isClone ? `Copy of "${name}"` : outputName}</h1>
      <p>{description}</p>
    </div>
  );
};

export default WizardHeader;
