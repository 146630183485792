import {
  GET_OUTPUT_SUCCESS, GET_OUTPUT_ERROR,
} from '../actions/types';

const initialState = {
  getOutputData: null,
  getOutputDataError: null,
  getOutputStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OUTPUT_SUCCESS:
      return {
        ...state,
        getOutputData: action.payload,
        getOutputDataError: null,
        getOutputStatus: 'success',
      };
    case GET_OUTPUT_ERROR:
      return {
        ...state,
        getOutputDataError: action.payload,
        getOutputData: null,
        getOutputStatus: 'error',
      };
    default:
      return {
        ...state,
        getOutputStatus: null,
      };
  }
};
