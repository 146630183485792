import React, { Component } from 'react';
import { getLabelByName } from '../../../../helpers/helperFunctions';
import { TEXT } from '../../../../helpers/constants';

class ShortInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="col-md-12">
        <input
          type="text"
          name="title"
          className="form-control"
          id="input-name"
          placeholder={getLabelByName(TEXT)}
          style={{ backgroundColor: '#FFFFFF' }}
        />
      </div>
    );
  }
}

export default ShortInput;
