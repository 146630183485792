import {
  FORGOT_PASSWORD_ERROR, FORGOT_PASSWORD_SUCCESS,
} from '../actions/types';

const initialState = {
  forgotData: null,
  forgotDataError: null,
  forgotStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotData: action.payload,
        forgotDataError: null,
        forgotStatus: 'success',
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotDataError: action.payload,
        forgotData: null,
        forgotStatus: 'error',
      };
    default:
      return {
        ...state,
        forgotStatus: null,
      };
  }
};
