import { makeStyles } from "@material-ui/core/styles";

const FormStyles = makeStyles(() => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    margin: "2rem 0",
  },
  input: {
    width: "100%",
  },
  inputLeft50: {
    width: "calc(50% - 8px)",
    marginRight: "8px",
  },
  inputRight50: {
    width: "calc(50% - 8px)",
    marginLeft: "8px",
  },
  inputLeft: {
    width: "calc(70% - 8px)",
    marginRight: "8px",
  },
  inputRight: {
    width: "calc(30% - 8px)",
    marginLeft: "8px",
  },
  uploadInput: {
    display: "none",
  },
  button: {
    width: "50%",
    borderRadius: "10px",
    textTransform: "none",
    border: "none !important",
    outline: "none !important",
  },
}));

export default FormStyles;
