import {
  GET_CLUB_LESSONS_SUCCESS,
  GET_CLUB_LESSONS_ERROR,
} from "../actions/types";

const initialState = {
  getClubLessonsData: null,
  getClubLessonsDataError: null,
  getClubLessonsStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CLUB_LESSONS_SUCCESS:
      return {
        ...state,
        getClubLessonsData: action.payload,
        getClubLessonsDataError: null,
        getClubLessonsStatus: "success",
      };
    case GET_CLUB_LESSONS_ERROR:
      return {
        ...state,
        getClubLessonsDataError: action.payload,
        getClubLessonsData: null,
        getClubLessonsStatus: "error",
      };
    default:
      return {
        ...state,
        getClubLessonsStatus: null,
      };
  }
};
