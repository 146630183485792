import {
  GET_CLUB_MEMBERS_ERROR, GET_CLUB_MEMBERS_SUCCESS,
} from '../actions/types';

const initialState = {
  clubMembersData: null,
  clubMembersDataError: null,
  clubMembersStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CLUB_MEMBERS_SUCCESS:
      return {
        ...state,
        clubMembersData: action.payload,
        clubMembersDataError: null,
        clubMembersStatus: 'success',
      };
    case GET_CLUB_MEMBERS_ERROR:
      return {
        ...state,
        clubMembersDataError: action.payload,
        clubMembersData: null,
        clubMembersStatus: 'error',
      };
    default:
      return {
        ...state,
        clubMembersStatus: null,
      };
  }
};
