/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable global-require */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import arrayMove from "array-move";
import { toast } from "react-toastify";
import {
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import {
  getActionReq,
  createAction,
  updateAction,
  getPostLessons,
  createLesson,
  updateLesson,
} from "../../../redux/actions/campaignsActions";
import { getNewsFeed as getNewsFeedReq } from "../../../redux/actions/postActions";
import {
  ALL_USERS,
  CANCEL,
  colors,
  CREATE,
  EDIT,
  INVITATION_ONLY,
  MEMBERS_ONLY,
  NAME,
  SAVE,
  SAVING,
  VISIBILITY,
  ACTIVITY_STATUS_SELECT_DESCRIPTION,
  ACTION,
  CREATE_ACTION_SUBTITLE,
  EDIT_ACTION_SUBTITLE,
  DESCRIPTION,
  REQUIRED,
  TYPE,
  OF_WORD,
  QUESTIONNAIRE,
  SELECT,
  A_WORD,
  MAX_SUBMISSIONS,
  NO_QUESTIONNAIRE_IN_CLUB,
} from "../../../helpers/constants";
import {
  getLabelByName,
  isActionError,
  isActionSuccess,
} from "../../../helpers/helperFunctions";
import Switch from "../../generics/Switch";
import AddCourseLessons from "./AddCourseLessons";
import PaymentFields from "./PaymentFields";

class CreateActionModal extends Component {
  state = {
    name: "",
    type: "DATA_COLLECTION",
    visibility: "PUBLIC",
    description: "",
    status: "ACTIVE",
    required: false,
    statusClicked: null,
    switchClicked: null,
    maxSubmissions: -1,
    courseMaxSubmissions: 1,
    questionnaireId: null,

    // ======== PAYMENT ========
    amount: 0,
    currencies: ["GBP", "EUR", "USD"],
    paymentFrequencies: [
      // "One-Time",
      "Weekly",
      "Monthly",
      "Yearly",
    ],
    paymentDescription: "",
    currency: "GBP",
    paymentFrequency: "Weekly",
    numOfPayments: 0,
    // ======== PAYMENT ========

    // ======== COURSE STATE ========
    sequential_order: false,
    cta_mark_complete: "",
    cta_mark_incomplete: "",
    lessons: [],
    // ======== COURSE STATE ========

    isLoading: false,
    visibilityOptions: [
      { value: "PUBLIC", name: getLabelByName(ALL_USERS) },
      {
        value: "PRIVATE",
        name: getLabelByName(MEMBERS_ONLY),
      },
      {
        value: "RESTRICTED",
        name: getLabelByName(INVITATION_ONLY),
      },
    ],

    actionTypes: [
      "DATA_COLLECTION",
      "COURSE",
      "PAYMENT",
      "SUBSCRIPTION",
    ],
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      name: "",
      type: nextProps.editAction?.type || "DATA_COLLECTION",
      visibility:
        nextProps.editAction?.visibility || "PUBLIC",
      description: "",
      status: nextProps.editAction?.status || "ACTIVE",
      required: nextProps.editAction?.required || false,
      maxSubmissions:
        nextProps.editAction?.meta?.maxSubmissions || -1,
      questionnaireId:
        nextProps.editAction?.meta?.questionnaireId ||
        nextProps?.getQuestionnairesData?.data?.[0]?.id,
      statusClicked: null,
      switchClicked: null,
      isLoading: false,

      // ======== COURSE STATE ========
      sequential_order:
        nextProps.editAction?.meta?.sequential_order ||
        false,
      cta_mark_complete:
        nextProps.editAction?.profile?.cta_mark_complete ||
        "",
      cta_mark_incomplete:
        nextProps.editAction?.profile
          ?.cta_mark_incomplete || "",
      // lessons: nextProps.editAction?.lessons || [],
      // ======== COURSE STATE ========

      // ======== PAYMENT ========
      amount: nextProps.editAction?.profile?.amount || 0,
      currencies: ["GBP", "EUR", "USD"],
      paymentFrequencies: ["Weekly", "Monthly", "Yearly"],
      paymentDescription:
        nextProps.editAction?.profile?.paymentDescription ||
        "",
      currency:
        nextProps.editAction?.profile?.currency || "GBP",
      paymentFrequency:
        nextProps.editAction?.profile?.paymentFrequency ||
        "",
      numOfPayments:
        nextProps.editAction?.profile?.numOfPayments || 0,
      // ======== PAYMENT ========
    });

    // if (
    // isActionSuccess(
    //   nextProps,
    //   "createActionStatus",
    //   "createActionData",
    // ) ||
    // isActionSuccess(
    //   nextProps,
    //   "editActionStatus",
    //   "editActionData",
    // )
    // ) {
    // window.location.reload();
    // }

    if (
      isActionError(
        nextProps,
        "createActionStatus",
        "createActionData",
      ) ||
      isActionError(
        nextProps,
        "editActionStatus",
        "editActionData",
      )
    ) {
      this.setState({ isLoading: false });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { editAction, clubId } = this.props;

    if (prevProps.editAction !== editAction) {
      if (editAction) {
        const response = await this.props.getActionReq(
          editAction?.activityId,
          editAction?.id,
        );

        this.setState({
          lessons: response?.data?.lessons ?? [],
        });
      }
    }

    if (prevProps.clubId !== clubId) {
      this.props.getPostLessons(clubId);
    }
  }

  dataHasLoaded = (state) => !!(state && state.data);

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]:
        name === ("amount" || "numOfPayments")
          ? parseInt(value)
          : value,
    });
  };

  handleFieldsChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "sequential_order") {
      this.setState({
        sequential_order: checked,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleLessonsChange = ({ oldIndex, newIndex }) => {
    this.setState((prevState) => ({
      lessons: [
        ...arrayMove(prevState.lessons, oldIndex, newIndex),
      ],
    }));
  };

  onLessonChange = (index, articleId) => {
    this.setState((prevState) => ({
      lessons: [
        ...prevState.lessons.filter(
          (lesson, idx) => idx !== index,
        ),
        {
          ...prevState.lessons[index],
          articleId,
          displayOrder: index,
        },
      ],
    }));
  };

  handleRadio = (e) => {
    this.setState({
      status: e.target.value,
      statusClicked: "clicked",
    });
  };

  addMoreLesson = () => {
    this.setState((prevState) => ({
      lessons: [
        ...prevState.lessons,
        {
          lesson: `lesson_${prevState.lessons.length + 1}`,
          articleId: null,
          displayOrder: null,
        },
      ],
    }));
  };

  removeLesson = (index) => {
    const fields = [...this.state.lessons];
    fields.splice(index, 1);

    this.setState({
      lessons: fields,
    });
  };

  submitForm = async (e, editAction) => {
    e.preventDefault();

    this.setState({ isLoading: true });
    const { props, state } = this;
    const {
      name,
      type,
      visibility,
      description,
      status,
      required,
      maxSubmissions,
      courseMaxSubmissions,
      questionnaireId,
      switchClicked,

      // ======== PAYMENT ========
      numOfPayments,
      paymentDescription,
      currency,
      amount,
      paymentFrequency,
      // ======== PAYMENT ========

      // ====== COURSE ======
      sequential_order,
      cta_mark_complete,
      cta_mark_incomplete,
      // ====== COURSE ======
    } = state;

    let payload;

    if (type === "COURSE") {
      payload = {
        name: name || editAction?.name,
        type: type || editAction?.type,
        visibility: visibility || editAction?.visibility,
        status: status || editAction?.status,
        profile: {
          description:
            description || editAction?.profile?.description,
          cta_mark_complete:
            cta_mark_complete ||
            editAction?.profile?.cta_mark_complete,
          cta_mark_incomplete:
            cta_mark_incomplete ||
            editAction?.profile?.cta_mark_incomplete,
        },
        meta: {
          sequential_order:
            sequential_order ||
            editAction?.meta?.sequential_order,
          maxSubmissions:
            courseMaxSubmissions ||
            editAction?.meta?.maxSubmissions,
        },
      };
    }

    if (type === "DATA_COLLECTION") {
      payload = {
        name: name || editAction.name,
        type: type || editAction.type,
        visibility: visibility || editAction.visibility,
        status: status || editAction.status,
        required: switchClicked
          ? required
          : editAction?.required,
        profile: {
          description:
            description || editAction.profile.description,
        },
        meta: {
          maxSubmissions:
            maxSubmissions ||
            editAction.meta.maxSubmissions,
          questionnaireId:
            questionnaireId ||
            editAction.meta.questionnaireId,
        },
      };
    }

    if (type === "PAYMENT") {
      if (amount < 1 || editAction?.profile?.amount < 1) {
        this.setState({ isLoading: false });

        return toast.error(
          `Amount has to be at least 1${
            currency || editAction?.profile?.currency
          }`,
        );
      }

      payload = {
        name: name || editAction?.name,
        type: type || editAction?.type,
        visibility: visibility || editAction?.visibility,
        status: status || editAction?.status,
        profile: {
          description:
            description || editAction?.profile?.description,

          paymentDescription:
            editAction?.profile?.paymentDescription ||
            paymentDescription,
          currency:
            editAction?.profile?.currency || currency,
          amount: editAction?.profile?.amount || amount,
          paymentFrequency:
            editAction?.profile?.paymentFrequency ||
            paymentFrequency,
          // numOfPayments:
          //   editAction?.profile?.numOfPayments ||
          //   numOfPayments,
        },
        meta: {
          sequential_order:
            sequential_order ||
            editAction?.meta?.sequential_order,
          maxSubmissions:
            courseMaxSubmissions ||
            editAction?.meta?.maxSubmissions,
        },
      };
    }

    if (type === "SUBSCRIPTION") {
      if (amount < 1 || editAction?.profile?.amount < 1) {
        this.setState({ isLoading: false });

        return toast.error(
          `Amount has to be at least 1${
            currency || editAction?.profile?.currency
          }`,
        );
      }

      payload = {
        name: name || editAction?.name,
        type: type || editAction?.type,
        visibility: visibility || editAction?.visibility,
        status: status || editAction?.status,
        profile: {
          description:
            description || editAction?.profile?.description,

          paymentDescription:
            editAction?.profile?.paymentDescription ||
            paymentDescription,
          currency:
            editAction?.profile?.currency || currency,
          amount: editAction?.profile?.amount || amount,
          paymentFrequency:
            editAction?.profile?.paymentFrequency ||
            paymentFrequency,
          numOfPayments:
            editAction?.profile?.numOfPayments ||
            numOfPayments,
        },
        meta: {
          sequential_order:
            sequential_order ||
            editAction?.meta?.sequential_order,
          maxSubmissions:
            courseMaxSubmissions ||
            editAction?.meta?.maxSubmissions,
        },
      };
    }

    // console.log(payload);

    if (!editAction) {
      this.handleCreateAction(props.activityId, payload);
    } else {
      this.handleUpdateAction(editAction, payload);
    }
  };

  async handleUpdateAction(editAction, payload) {
    const updatedPayload = {
      ...editAction,
      ...payload,
    };
    const response = await this.props.updateAction(
      this.props.activityId,
      updatedPayload.id,
      updatedPayload,
    );

    if (
      payload.type !== "COURSE" &&
      response.status === 200
    ) {
      return window.location.reload();
    }

    // Create Lessons if EditAction has no lessons or if EditAction Lessons Length is 0
    if (
      !editAction?.lessons ||
      editAction.lessons.length === 0
    ) {
      // Create Lessons
      const allResponses = await this.createLessons(
        this.props.activityId,
        response,
      );

      if (
        allResponses.every((resp) => resp.status === 200)
      ) {
        toast.success("Action created successfully.");
      } else {
        toast.error(
          "An error occured while creating the action",
        );
      }
    } else {
      // Update Lessons
      const allResponses = await this.updateLessons(
        this.props.activityId,
        response,
      );

      if (
        allResponses.every((resp) => resp.status === 200)
      ) {
        toast.success("Action edited successfully.");
      } else {
        toast.error(
          "An error occured while editing the action",
        );
      }
    }

    return window.location.reload();
  }

  async handleCreateAction(activityId, payload) {
    // Create Action
    const response = await this.props.createAction(
      activityId,
      payload,
    );

    if (response.status !== 200)
      return toast.error(response.message);

    if (payload.type !== "COURSE") {
      return window.location.reload();
    }

    // Create Lessons
    const allResponses = await this.createLessons(
      activityId,
      response,
    );

    if (
      allResponses.every(
        (response) => response.status === 200,
      )
    ) {
      toast.success("Action created successfully.");
    } else {
      toast.error(
        "An error occured while creating the action",
      );
    }

    return window.location.reload();
  }

  async createLessons(activityId, response) {
    const requests = this.state.lessons.map((lesson) =>
      this.props
        .createLesson(activityId, response.data.id, {
          articleId: lesson.articleId,
          displayOrder: lesson.displayOrder,
          status: "ACTIVE",
          meta: {},
        })
        .catch((e) => e),
    );

    const allResponses = await Promise.all(requests);

    return allResponses;
  }

  async updateLessons(activityId, response) {
    const requests = this.state.lessons.map((lesson) =>
      lesson?.id
        ? this.props
            .updateLesson(
              activityId,
              response.data.id,
              lesson.id,
              {
                articleId: lesson.articleId,
                displayOrder: lesson.displayOrder,
                status: "ACTIVE",
                meta: {},
              },
            )
            .catch((e) => e)
        : this.props
            .createLesson(activityId, response.data.id, {
              articleId: lesson.articleId,
              displayOrder: lesson.displayOrder,
              status: "ACTIVE",
              meta: {},
            })
            .catch((e) => e),
    );

    const allResponses = await Promise.all(requests);

    return allResponses;
  }

  render() {
    const { editAction } = this.props;

    const {
      name,
      type,
      visibilityOptions,
      description,
      status,
      statusClicked,
      required,
      isLoading,
      actionTypes,

      // ======== PAYMENT ========
      amount,
      currencies,
      paymentFrequencies,
      numOfPayments,
      paymentFrequency,
      paymentDescription,
      // ======== PAYMENT ========

      // ======== COURSE STATE ========
      cta_mark_complete,
      cta_mark_incomplete,
      sequential_order,
      lessons,
      // ======== COURSE STATE ========
    } = this.state;

    const statuses = [
      { index: 0, text: "Active", value: "ACTIVE" },
      { index: 1, text: "Inactive", value: "INACTIVE" },
    ];

    const questionnaires = this.dataHasLoaded(
      this.props.getQuestionnairesData,
    )
      ? this.props.getQuestionnairesData.data
          .filter(
            (questionnaire) =>
              parseInt(questionnaire.parentId) ===
              parseInt(this.props.clubId),
          )
          .map((questionnaire) => ({
            value: questionnaire.id,
            text: questionnaire.name,
          }))
      : [];

    return (
      <div
        className="modal fade create-action-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="createActionModal"
        aria-hidden="true"
        id="createActionModal">
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document">
          <div className="modal-content modal-card p-4">
            <div className="create-club-header">
              <div className="header-left">
                <p className="header-modal-title">
                  {!editAction
                    ? `${getLabelByName(
                        CREATE,
                      )} ${getLabelByName(ACTION)}`
                    : `${getLabelByName(
                        EDIT,
                      )} ${getLabelByName(ACTION)}`}
                </p>
                <p className="header-modal-subtitle">
                  {!editAction
                    ? getLabelByName(CREATE_ACTION_SUBTITLE)
                    : getLabelByName(EDIT_ACTION_SUBTITLE)}
                </p>
              </div>
            </div>

            <div className="mt-3 create-club-form">
              <form
                onSubmit={(e) =>
                  this.submitForm(e, editAction)
                }>
                {/* ================== NAME ==================  */}
                <div className="row d-flex justify-content-center">
                  <div className="form-icon col-md-12">
                    <div className="form-group form-label-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="action-input-name"
                        placeholder={getLabelByName(NAME)}
                        value={
                          editAction && name === ""
                            ? editAction?.name
                            : name
                        }
                        onChange={this.handleChange}
                        style={{
                          backgroundColor: "#FFFFFF",
                        }}
                        required
                      />
                      <label htmlFor="action-input-name">
                        {getLabelByName(NAME)}
                      </label>
                    </div>
                  </div>
                </div>
                {/* ================== NAME ==================  */}

                {/* ================== DESCRIPTION ==================  */}
                <div className="row d-flex justify-content-center">
                  <div className="form-icon col-md-12">
                    <div className="form-group form-label-group">
                      <textarea
                        rows={3}
                        name="description"
                        className="form-control"
                        id="action-input-description"
                        placeholder={getLabelByName(
                          DESCRIPTION,
                        )}
                        value={
                          editAction && description === ""
                            ? editAction?.profile
                                ?.description
                            : description
                        }
                        onChange={this.handleChange}
                        style={{
                          backgroundColor: "#FFFFFF",
                        }}
                        required
                      />
                      <label htmlFor="action-input-description">
                        {getLabelByName(DESCRIPTION)}
                      </label>
                    </div>
                  </div>
                </div>
                {/* ================== DESCRIPTION ==================  */}

                <div className="row d-flex justify-content-center">
                  {/* ================== ACTION TYPE ==================  */}
                  <div className="col-md-6">
                    <div className="form-icon select-club-dropdown">
                      <div className="select-club-header">
                        {`${getLabelByName(
                          TYPE,
                        )} ${getLabelByName(
                          OF_WORD,
                        )} ${getLabelByName(ACTION)}`}
                      </div>

                      <div className="select-club-input">
                        <select
                          className="form-control"
                          id="club-select"
                          defaultValue={
                            editAction?.type || "1"
                          }
                          onChange={(e) =>
                            this.setState({
                              type: e.target.value,
                            })
                          }>
                          {actionTypes.map((actionType) => (
                            <option
                              value={actionType}
                              selected={
                                editAction?.type ===
                                actionType
                              }>
                              {actionType}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* ================== ACTION TYPE ==================  */}

                  {/* ================== VISIBILITY ==================  */}
                  <div className="col-md-6">
                    <div
                      className="form-icon select-dropdown"
                      style={{
                        backgroundColor: "#FFFFFF",
                        paddingLeft: 0,
                        border: "none",
                      }}>
                      <div style={{ marginLeft: "1rem" }}>
                        <div className="select-header">
                          {getLabelByName(VISIBILITY)}
                        </div>
                        <div className="select-input">
                          <select
                            className="form-control"
                            id="input-campaignType"
                            defaultValue={
                              editAction?.visibility
                            }
                            onChange={(e) =>
                              this.setState({
                                visibility: e.target.value,
                              })
                            }>
                            {visibilityOptions.map(
                              (visibilityOption) => (
                                <option
                                  value={
                                    visibilityOption.value
                                  }
                                  selected={
                                    editAction?.visibility ===
                                    visibilityOption.value
                                  }>
                                  {visibilityOption.name}
                                </option>
                              ),
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ================== VISIBILITY ==================  */}
                </div>

                {type === "DATA_COLLECTION" && (
                  <div className="row d-flex justify-content-center">
                    {/* ================== SELECT QUESTIONNAIRE ==================  */}
                    <div className="col-md-6">
                      <div className="form-icon select-club-dropdown">
                        <div className="select-club-header">
                          {`${getLabelByName(
                            SELECT,
                          )} ${getLabelByName(
                            A_WORD,
                          )} ${getLabelByName(
                            QUESTIONNAIRE,
                          )}`}
                        </div>
                        <div className="select-club-input">
                          <select
                            className="form-control"
                            id="club-select"
                            defaultValue={
                              editAction?.meta
                                ?.questionnaireId
                            }
                            onChange={(e) =>
                              this.setState({
                                questionnaireId:
                                  e.target.value,
                              })
                            }>
                            {questionnaires.length > 0 &&
                              questionnaires.map(
                                (questionnaire) => (
                                  <option
                                    value={
                                      questionnaire.value
                                    }
                                    selected={
                                      Number(
                                        editAction?.meta
                                          ?.questionnaireId,
                                      ) ===
                                      Number(
                                        questionnaire.value,
                                      )
                                    }>
                                    {questionnaire.text}
                                  </option>
                                ),
                              )}
                            {questionnaires.length ===
                              0 && (
                              <option selected disabled>
                                {getLabelByName(
                                  NO_QUESTIONNAIRE_IN_CLUB,
                                )}
                              </option>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* ================== SELECT QUESTIONNAIRE ==================  */}

                    {/* ================== MAX SUBMISSIONS ==================  */}

                    <div className="col-md-6">
                      <div
                        className="form-icon select-dropdown"
                        style={{
                          backgroundColor: "#FFFFFF",
                          paddingLeft: 0,
                          border: "none",
                        }}>
                        <div style={{ marginLeft: "1rem" }}>
                          <div className="select-header">
                            {getLabelByName(
                              MAX_SUBMISSIONS,
                            )}
                          </div>
                          <div className="select-input">
                            <select
                              className="form-control"
                              id="input-campaignType"
                              defaultValue={-1}
                              onChange={(e) =>
                                this.setState({
                                  maxSubmissions:
                                    e.target.value,
                                })
                              }>
                              <option
                                value={-1}
                                selected={
                                  Number(
                                    editAction?.meta
                                      ?.maxSubmissions,
                                  ) === -1
                                }>
                                Unlimited
                              </option>
                              {Array.from(
                                { length: 10 },
                                (_, index) => index + 1,
                              ).map((number) => (
                                <option
                                  value={number}
                                  selected={
                                    Number(
                                      editAction?.meta
                                        ?.maxSubmissions,
                                    ) === Number(number)
                                  }>
                                  {number}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ================== MAX SUBMISSIONS ==================  */}
                  </div>
                )}

                {type === "COURSE" ? (
                  <AddCourseLessons
                    articles={
                      this.props.getPostLessonsData &&
                      this.props?.getPostLessonsData.data?.filter(
                        (postItem) =>
                          postItem.clubId ===
                          parseInt(this.props?.clubId),
                      )
                    }
                    handleChange={this.handleFieldsChange}
                    handleLessonsChange={
                      this.handleLessonsChange
                    }
                    courseState={{
                      cta_mark_complete,
                      cta_mark_incomplete,
                      sequential_order,
                      lessons,
                    }}
                    editAction={editAction}
                    onLessonChange={this.onLessonChange}
                    removeLesson={this.removeLesson}
                    addMoreLesson={this.addMoreLesson}
                  />
                ) : null}

                {type === "DATA_COLLECTION" && (
                  <div className="d-flex">
                    <FormGroup>
                      <FormControlLabel
                        className="p-0 m-0"
                        control={
                          <Switch
                            checked={required}
                            onChange={() =>
                              this.setState({
                                required: !required,
                                switchClicked: true,
                              })
                            }
                          />
                        }
                        label={
                          <span
                            style={{
                              color: colors.textSecondary,
                            }}>
                            {getLabelByName(REQUIRED)}
                          </span>
                        }
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </div>
                )}

                {(type === "COURSE" ||
                  type === "DATA_COLLECTION") && (
                  <>
                    <div className="row pl-3 mb-3 help-text">
                      {getLabelByName(
                        ACTIVITY_STATUS_SELECT_DESCRIPTION,
                      )}
                    </div>

                    {/* ================== ACTIVITY STATUS ==================  */}
                    <div className="row pl-3 status-radios">
                      {statuses.map((statusItem) => (
                        <div
                          key={statusItem.index}
                          className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="radio-checkbox"
                            id={`action-check-${statusItem.value}`}
                            value={statusItem.value}
                            onChange={(e) =>
                              this.handleRadio(e)
                            }
                            checked={
                              editAction === null ||
                              statusClicked !== null
                                ? status ===
                                  statusItem.value
                                : editAction.status ===
                                  statusItem.value
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`action-check-${statusItem.value}`}>
                            {statusItem.text}
                          </label>
                        </div>
                      ))}
                    </div>
                    {/* ================== ACTIVITY STATUS ==================  */}
                  </>
                )}

                {(type === "PAYMENT" ||
                  type === "SUBSCRIPTION") && (
                  <PaymentFields
                    paymentType={type}
                    editAction={editAction}
                    currencies={currencies}
                    handleChange={this.handleChange}
                    paymentFrequencies={paymentFrequencies}
                    amount={amount}
                    // currency={currency}
                    numOfPayments={numOfPayments}
                    paymentFrequency={paymentFrequency}
                    paymentDescription={paymentDescription}
                  />
                )}

                {/* ================== CANCEL/SAVE BUTTON ==================  */}
                <div className="row create-club-buttons mb-2">
                  <button
                    type="submit"
                    className="btn btn-lg submit-btn btn-reverse"
                    data-dismiss="modal"
                    aria-label="Close">
                    {getLabelByName(CANCEL)}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-lg submit-btn">
                    {isLoading ? (
                      <>
                        <span>
                          {getLabelByName(SAVING)}
                          {"  "}
                        </span>
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status">
                          <span className="sr-only">
                            Loading...
                          </span>
                        </div>
                      </>
                    ) : (
                      getLabelByName(SAVE)
                    )}
                  </button>
                </div>
                {/* ================== CANCEL/SAVE BUTTON ==================  */}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CreateActionModal.propTypes = {
  createAction: PropTypes.func,
  updateAction: PropTypes.func,
  editAction: PropTypes.any,
  activityId: PropTypes.any,
  getQuestionnairesData: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  createActionData:
    state.createActionReducer.createActionData,
  createActionDataError:
    state.createActionReducer.createActionDataError,
  createActionStatus:
    state.createActionReducer.createActionStatus,

  editActionData: state.editActionReducer.editActionData,
  editActionDataError:
    state.editActionReducer.editActionDataError,
  editActionStatus:
    state.editActionReducer.editActionStatus,

  getQuestionnairesData:
    state.getQuestionnairesReducer.getQuestionnairesData,
  getQuestionnairesDataError:
    state.getQuestionnairesReducer
      .getQuestionnairesDataError,
  getQuestionnairesStatus:
    state.getQuestionnairesReducer.getQuestionnairesStatus,

  getPostLessonsData:
    state.getPostLessonsReducer.getPostLessonsData,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getActionReq,
    createAction,
    updateAction,
    createLesson,
    updateLesson,
    getPostLessons,
    getNewsFeedReq,
  }),
)(CreateActionModal);
