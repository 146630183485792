/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  getLabelByName,
  getProfileDataFromLocalStorage,
} from "../../../helpers/helperFunctions";
import {
  ACTIVITIES,
  CLUBS,
  POSTS,
  PROMOTIONS,
  QUESTIONNAIRES,
  USERS,
  WEB_CONTENT,
} from "../../../helpers/constants";
import "./sidenav.css";

class SideNav extends Component {
  state = {
    profileData: getProfileDataFromLocalStorage(),
    navItems: [
      {
        iconInactive: require("../../../assets/manage-posts.svg"),
        iconActive: require("../../../assets/manage-posts-orange.svg"),
        title: getLabelByName(POSTS),
        link: "/manage/posts",
      },
      {
        iconInactive: require("../../../assets/manage-clubs.svg"),
        iconActive: require("../../../assets/manage-clubs-orange.svg"),
        title: getLabelByName(CLUBS),
        link: "/manage/clubs",
      },
      {
        iconInactive: require("../../../assets/manage-activities.svg"),
        iconActive: require("../../../assets/manage-activities-orange.svg"),
        title: getLabelByName(ACTIVITIES),
        link: "/manage/activities",
      },
      {
        iconInactive: require("../../../assets/manage-members.svg"),
        iconActive: require("../../../assets/manage-members-orange.svg"),
        title: getLabelByName(USERS),
        link: "/manage/users",
      },
      {
        iconInactive: require("../../../assets/manage-site.svg"),
        iconActive: require("../../../assets/manage-site-orange.svg"),
        title: getLabelByName(WEB_CONTENT),
        link: "/manage/web/logo",
      },
      {
        iconInactive: require("../../../assets/manage-questionnaires.svg"),
        iconActive: require("../../../assets/manage-questionnaires-orange.svg"),
        title: getLabelByName(QUESTIONNAIRES),
        link: "/manage/questionnaires",
      },
      {
        iconInactive: require("../../../assets/icons/loud.svg"),
        iconActive: require("../../../assets/icons/loud-orange.svg"),
        title: getLabelByName(PROMOTIONS),
        link: "/manage/promotions/all",
      },
    ],
  };

  render() {
    const { navItems } = this.state;
    const { toggleMenu, entity } = this.props;
    return (
      <div className="container-fluid">
        {" "}
        {navItems.map((item) => (
          <Link
            key={navItems.indexOf(item)}
            to={item.link}
            className={`nav-link side-nav-item ${
              entity === item?.title?.toLowerCase()
                ? "side-nav-item__active"
                : ""
            }`}
            onClick={() =>
              toggleMenu ? toggleMenu() : {}
            }>
            <img
              src={
                entity === item?.title?.toLowerCase()
                  ? item.iconActive
                  : item.iconInactive
              }
              alt="item-icon"
            />
            <div> {item?.title} </div>{" "}
          </Link>
        ))}{" "}
      </div>
    );
  }
}

SideNav.propTypes = {};

export const mapStateToProps = (state) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(SideNav);
