/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import TextInput from "react-autocomplete-input";
import {
  getLabelByName,
  getProfileDataFromLocalStorage,
  isActionSuccess,
} from "../../helpers/helperFunctions";
import {
  CREATED_SUCCESSFULLY,
  ENTER_TEXT,
  POST,
  POSTING_ACTION,
  POST_ACTION,
  WRITE_SOMETHING,
} from "../../helpers/constants";
import FileUpload from "../generics/FileUpload";
import FilePreview from "../generics/FilePreview";
import { createPost } from "../../redux/actions/postActions";
import { getInstituteMembers } from "../../redux/actions/institutesActions";

class FeedPostForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileData: getProfileDataFromLocalStorage(),
      isUploading: false,
      postMessage: "",
      imageFiles: [],
      videoFiles: [],
      documentFiles: [],
      imagePreview: [],
      videoPreview: [],
      documentPreview: [],
      clubMembersNames: [],
      userIds: [],
      isPostedSuccessfully: false,
    };
  }

  async UNSAFE_componentWillMount() {
    const {
      getInstituteMembers: getInstituteMembersAction,
      match: {
        params: { clubId },
      },
    } = this.props;

    await getInstituteMembersAction(clubId);
  }

  render() {
    const {
      isUploading,
      imageFiles,
      imagePreview,
      videoFiles,
      videoPreview,
      documentFiles,
      documentPreview,
      postMessage,
      isPostedSuccessfully,
      clubMembersNames,
    } = this.state;

    if (isPostedSuccessfully) {
      toast.success(
        `${getLabelByName(POST)} ${getLabelByName(
          CREATED_SUCCESSFULLY,
        ).toLowerCase()}`,
      );
      this.setState({ isPostedSuccessfully: false });
    }

    return (
      <div className="card activity-card activity-post">
        <div className="card-body">
          <form
            onSubmit={(e) =>
              this.submitPost(
                e,
                this.state.profileData.data.id,
                null,
                "post",
              )
            }>
            <div className="post-textarea">
              <TextInput
                name="postMessage"
                rows="5"
                onChange={(value) =>
                  this.handleChange(value)
                }
                value={postMessage}
                options={clubMembersNames}
                changeOnSelect={(trigger, slug) =>
                  this.handleChangeSelect(trigger, slug)
                }
                placeholder={`${getLabelByName(
                  ENTER_TEXT,
                )}...`}
                required
              />
            </div>
            <div className="d-flex flex-wrap">
              <FilePreview
                isUploading={isUploading}
                fileType="image"
                listFiles={imageFiles}
                listPreview={imagePreview}
                removeFile={this.removeFile}
              />
              <FilePreview
                isUploading={isUploading}
                fileType="video"
                listFiles={videoFiles}
                listPreview={videoPreview}
                removeFile={this.removeFile}
              />
              <FilePreview
                isUploading={isUploading}
                fileType="document"
                listFiles={documentFiles}
                listPreview={documentPreview}
                removeFile={this.removeFile}
              />
            </div>
            <div className="post-buttons">
              <div className="actions-buttons">
                <FileUpload
                  componentId="customFileImage"
                  fileType="image"
                  accept="image/*"
                  multiFileChange={this.multiFileChange}
                />
                <FileUpload
                  componentId="customFileVideo"
                  fileType="video"
                  accept="video/*"
                  multiFileChange={this.multiFileChange}
                />
                <FileUpload
                  componentId="customFileDocument"
                  fileType="document"
                  accept=".pdf, .docx, .doc, .xls, .xlsx, .txt, .odt, .zip"
                  multiFileChange={this.multiFileChange}
                />
              </div>
              <div className="post-button">
                <button
                  type="submit"
                  className="btn btn-lg submit-btn"
                  disabled={
                    !this.dataHasLoaded(
                      this.props.instituteData,
                    ) ||
                    isUploading ||
                    this.isFormEmpty()
                  }>
                  {!isUploading
                    ? getLabelByName(POST_ACTION)
                    : `${getLabelByName(
                        POSTING_ACTION,
                      )}...`}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.createPostData !== this.props.createPostData
    ) {
      this.setState({
        isUploading: false,
        postMessage: "",
        imageFiles: [],
        videoFiles: [],
        documentFiles: [],
        imagePreview: [],
        videoPreview: [],
        documentPreview: [],
        isPostedSuccessfully: true,
      });
    }

    const { clubMembersData } = nextProps;

    const clubMembers = [];

    const clubMembersIndex = {};

    if (clubMembersData?.data) {
      clubMembersData.data.forEach((member) => {
        const memberName = `${member.user.profile?.firstName?.replace(
          /\s/g,
          "",
        )} ${member.user.profile?.lastName?.replace(
          /\s/g,
          "",
        )}`;

        if (!clubMembers.includes(memberName)) {
          clubMembers.push(memberName);
          clubMembersIndex[`@${memberName}`] =
            member.user.id;
        }
      });
    }

    this.setState({
      clubMembersNames: clubMembers,
      clubMembersIndex,
    });
  }

  handleChange = (e) => {
    const newUserIds = [];

    e.split(" ").forEach((value) => {
      const { clubMembersIndex } = this.state;

      if (value && clubMembersIndex[value]) {
        const newId = clubMembersIndex[value];

        const alreadyNewUserId = newUserIds.find(
          (id) => id === newId,
        );

        if (!alreadyNewUserId) {
          newUserIds.push(newId);
        }
      }
    });

    this.setState({
      postMessage: e,
      userIds: newUserIds,
    });
  };

  handleChangeSelect = (trigger, slug) => {
    return trigger + slug;
  };

  submitPost = async (e, authorId, parentId, postType) => {
    e.preventDefault();
    const { props, state } = this;
    const { postMessage } = state;
    const postBody = postMessage;

    const { id: clubId } = props.instituteData.data;
    const { imageFiles, videoFiles, documentFiles } = state;
    const listImages = {};
    const listVideos = {};
    const listFiles = {};

    const fileMapping = {
      0: "image",
      1: "video",
      2: "file",
    };

    this.setState({ isUploading: true });
    await Promise.all(
      [imageFiles, videoFiles, documentFiles].map(
        async (list, index1) => {
          await Promise.all(
            list.map(async (file, index2) => {
              const formData = new FormData();
              formData.append("file", file.fileItem);
              formData.append("upload_preset", "qtt2g8ao");
              const options = {
                method: "POST",
                body: formData,
              };
              await fetch(
                "https://api.Cloudinary.com/v1_1/ayudigital/auto/upload",
                options,
              )
                .then((res) => res.json())
                .then(async (res) => {
                  const { secure_url } = res;
                  switch (index1) {
                    case 0:
                      listImages[
                        `${fileMapping[index1]}_${
                          index2 + 1
                        }`
                      ] = secure_url;
                      break;
                    case 1:
                      listVideos[
                        `${fileMapping[index1]}_${
                          index2 + 1
                        }`
                      ] = secure_url;
                      break;
                    case 2:
                      listFiles[
                        `${fileMapping[index1]}_${
                          index2 + 1
                        }`
                      ] = secure_url;
                      break;
                    default:
                      break;
                  }
                })
                .catch((err) =>
                  toast.error(
                    "Error uploading media!\nPlease try again.",
                  ),
                );
            }),
          );
        },
      ),
    );

    const { userIds } = this.state;

    const payload = {
      authorId,
      parentId,
      content: {
        body: postBody,
      },
      images: listImages,
      videos: listVideos,
      files: listFiles,
      status: "APPROVED",
      mentions: userIds,
    };
    await props.createPost(payload, clubId, postType);
  };

  multiFileChange = (e, fileType) => {
    const {
      imageFiles,
      videoFiles,
      documentFiles,
      imagePreview,
      videoPreview,
      documentPreview,
    } = this.state;
    let selectedFiles = [];

    if (fileType === "imageFiles") {
      selectedFiles = imageFiles;
    }
    if (fileType === "videoFiles") {
      selectedFiles = videoFiles;
    }
    if (fileType === "documentFiles") {
      selectedFiles = documentFiles;
    }

    let lastIndex = 0;
    let newFiles = [];
    if (selectedFiles.length > 0) {
      lastIndex =
        selectedFiles[selectedFiles.length - 1].index;
    }

    [...e.target.files].forEach((fileItem) => {
      lastIndex += 1;
      newFiles = [
        ...newFiles,
        {
          index: lastIndex,
          fileItem,
        },
      ];
      const fileReader = new FileReader();
      switch (fileType) {
        case "imageFiles":
          fileReader.onloadend = () => {
            this.setState({
              imagePreview: [
                ...imagePreview,
                {
                  index: lastIndex,
                  fileData: fileReader.result,
                },
              ],
            });
          };
          break;
        case "videoFiles":
          fileReader.onloadend = () => {
            this.setState({
              videoPreview: [
                ...videoPreview,
                {
                  index: lastIndex,
                  fileData: fileReader.result,
                },
              ],
            });
          };
          break;
        case "documentFiles":
          fileReader.onloadend = () => {
            this.setState({
              documentPreview: [
                ...documentPreview,
                {
                  index: lastIndex,
                  fileData: fileReader.result,
                },
              ],
            });
          };
          break;
        default:
          break;
      }
      fileReader.readAsDataURL(fileItem);
    });

    this.setState({
      [fileType]: [...selectedFiles, ...newFiles],
    });
  };

  removeFile = (
    image,
    fileType,
    previewList,
    filesList,
  ) => {
    const newPreview = previewList.filter(
      (previewItem) => previewItem !== image,
    );
    const selectedImage = filesList.filter(
      (fileItem) => fileItem.index === image.index,
    );
    const newSelectedFiles = filesList.filter(
      (selectedItem) => selectedItem !== selectedImage[0],
    );
    this.setState({
      [`${fileType}Preview`]: newPreview,
      [`${fileType}Files`]: newSelectedFiles,
    });
  };

  dataHasLoaded = (state) => !!(state && state.data);

  isFormEmpty = () => {
    const {
      postMessage,
      imageFiles,
      documentFiles,
      videoFiles,
    } = this.state;
    return (
      postMessage.trim().length <= 0 &&
      imageFiles.length <= 0 &&
      documentFiles.length <= 0 &&
      videoFiles.length <= 0
    );
  };
}

FeedPostForm.propTypes = {
  club: PropTypes.any,
  createPost: PropTypes.func,
  instituteData: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  instituteDataError:
    state.instituteReducer.instituteDataError,
  instituteData: state.instituteReducer.instituteData,
  instituteStatus: state.instituteReducer.instituteStatus,

  createPostData: state.createPostReducer.createPostData,
  createPostDataError:
    state.createPostReducer.createPostDataError,
  createPostStatus:
    state.createPostReducer.createPostStatus,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,
  clubMembersData: state.clubMembersReducer.clubMembersData,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    createPost,
    getInstituteMembers,
  }),
)(FeedPostForm);
