import React, { useEffect, useState } from "react";

const RadioButtonsInput = (props) => {
    const { settings, currentValue, participantAction, handleUpdate, isPreviewMode } = props;

    const handleChange = (e) => {
        if (settings.options.includes(e.target.value)) {
            handleUpdate({
                [settings.input_key]: e.target.value
            });
        }
    }

    return (
        <div className="card section-card article-card p-0">
            <div className="card-body">
                <div className="card-details">
                    <h5 className="card-title">
                        {settings.required ? '*' : ''}
                        {settings.label}
                    </h5>
                    <br />
                    {settings.options.map((option, key) => {
                        return <p key={key}>
                            <input
                                type="radio"
                                className="form-check-input"
                                name={settings.input_key}
                                value={option}
                                id={`${settings.input_key}_${key}`}
                                onChange={handleChange}
                                disabled={isPreviewMode}
                                checked={option === (currentValue)}
                            />
                            <label className="form-check-label" htmlFor={`${settings.input_key}_${key}`}>{option}</label>
                        </p>;
                    })}
                </div>
            </div>
        </div>
    )
}

export default RadioButtonsInput;