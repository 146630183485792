import React, { Component, useEffect } from "react";
import { retrieveActivity } from "./redux/actions/activities/activityActions.js";
import { retrieveParticipant } from "./redux/actions/activities/participantActions.js";
import { searchParticipantActions } from "./redux/actions/activities/participantActionActions.js";
import { searchParticipantContributions } from "./redux/actions/activities/contributionActions.js";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

const Play = (props) => {

    useEffect(() => {
        props.retrieveActivity(1)
        props.retrieveParticipant(1, 5)
        props.searchParticipantActions(1, 5)
        props.searchParticipantContributions(1, 5)
    }, [])

    console.log("Retrieved Activity", props.activity);
    console.log("Retrieved Participant", props.participant);
    console.log("Retrieved Participant Actions", props.participantActions);
    console.log("Retrieved Participant Contributions", props.participantContributions);
    return <>
        Hello {props.participant.participant?.user?.profile?.firstName}
    </>
}

const mapStateToProps = (state) => ({
    activity: state.activityCRUDReducer,
    participant: state.participantCRUDReducer,
    participantActions: state.participantActionsSearchReducer,
    participantContributions: state.participantContributionsSearchReducer,
});

export default compose(
    withRouter,
    connect(mapStateToProps, { 
        retrieveActivity,
        retrieveParticipant, 
        searchParticipantActions,
        searchParticipantContributions,
    }),
)(Play);

