import { POST_PARTICIPANT_ACTION_SUCCESS, POST_PARTICIPANT_ACTION_ERROR } from '../actions/types';

const initialState = {
  postParticipantActionData: null,
  postParticipantActionDataError: null,
  postParticipantActionStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_PARTICIPANT_ACTION_SUCCESS:
      return {
        ...state,
        postParticipantActionData: action.payload,
        postParticipantActionDataError: null,
        postParticipantActionStatus: 'success',
      };
    case POST_PARTICIPANT_ACTION_ERROR:
      return {
        ...state,
        postParticipantActionDataError: action.payload,
        postParticipantActionData: null,
        postParticipantActionStatus: 'error',
      };
    default:
      return {
        ...state,
        postParticipantActionStatus: null,
      };
  }
};
