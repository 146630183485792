import "./clubMembers.scss";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  ArrowBackIos as ArrowBackIosIcon,
  DeleteOutline as DeleteOutlineIcon,
  EditOutlined as EditOutlineIcon,
} from "@material-ui/icons";
import Modal from "@material-ui/core/Modal";
import { toast } from "react-toastify";
import Navbar from "../../../Navbar";
import {
  checkSession,
  getLabelByName,
} from "../../../../helpers/helperFunctions";
import {
  getInstitute,
  getInstituteMembers,
  editClubMembers,
  deleteClubMember,
  joinClub,
} from "../../../../redux/actions/institutesActions";
import { getUsers } from "../../../../redux/actions/usersActions";
import { getLanding } from "../../../../redux/actions/landingActions";
import { getSessionAction } from "../../../../redux/actions/authActions";
import AvatarDefaultImage from "../../../../assets/avatar-placeholder.png";
import {
  ADD,
  ADD_MEMBER_DESCRIPTION,
  ADMIN,
  ARE_YOU_SURE_TEXT,
  CANCEL,
  CLUB,
  DELETED,
  EDIT,
  FROM,
  MEMBER,
  MEMBERS,
  NEW,
  REMOVE,
  REMOVE_MEMBER_CONFIRMATION_TEXT,
  REMOVING,
  ROLE,
  SEARCH,
  SUCCESSFULLY,
  THE_WORD,
  THIS_WORD,
  TO_WORD,
  UPDATED_SUCCESSFULLY,
  WANT,
  YOU,
} from "../../../../helpers/constants";
import Loader from "../../../Loader";
import SearchBar from "../../../generics/SearchBar/SearchBar";
import Pagination from "../../../generics/Pagination/Pagination";
import Loading from "../../../generics/Loading/Loading";
import { AddMemberModal } from "../../../generics/AddMemberModal/AddMemberModal";

export class ClubMembers extends Component {
  state = {
    isAdding: false,
    triggerPagination: false,
    searchedMembers: null,
    displayedMembers: null,
    dataPageLimit: 15,
    isEditModalOpen: false,
    isDeleteMember: false,
    selectedMemberObject: null,
    confirmationModalOpen: false,
    isAddMemberModalOpen: false,
    isButtonLoading: false,
    members: [],
    pathname: this.props.location.pathname,
  };

  async componentDidMount() {
    const {
      getLanding: getLandingAction,
      getSessionAction: getSession,
      getInstituteMembers: getInstituteMembersAction,
      match: {
        params: { id: clubId },
      },
    } = this.props;

    await getLandingAction();

    await getSession();

    await getInstituteMembersAction(clubId);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, status } = nextProps;
    checkSession(
      data,
      status,
      this.state.pathname,
      nextProps,
    );

    const {
      getInstituteMembers: getInstituteMembersAction,
      match: {
        params: { id: clubId },
      },
      editClubMembersLoading,
      editClubMembersData,
      deleteClubMember: deleteClubMemberAction,
      deleteClubMembersLoading,
      deleteClubMembersStatus,
      joinClubData,
    } = this.props;

    const {
      displayedMembers,
      searchedMembers,
      isDeleteMember,
    } = this.state;

    if (nextProps.joinClubData !== joinClubData) {
      await getInstituteMembersAction(clubId);
      toast.success(
        "Member(s) have been added successfully",
      );
      this.setState({
        isButtonLoading: false,
        isAddMemberModalOpen: false,
      });
      this.clearMembers();
    }

    if (isDeleteMember) {
      deleteClubMemberAction();
    }

    if (!editClubMembersLoading && editClubMembersData) {
      this.setState({
        isEditModalOpen: false,
      });
      await getInstituteMembersAction(clubId);
      toast.success(
        `${getLabelByName(MEMBER)} ${getLabelByName(
          ROLE,
        ).toLowerCase()} ${getLabelByName(
          UPDATED_SUCCESSFULLY,
        ).toLowerCase()}`,
      );
    }

    if (
      !deleteClubMembersLoading &&
      deleteClubMembersStatus === "success"
    ) {
      this.setState({
        confirmationModalOpen: false,
        isButtonLoading: false,
      });
      await getInstituteMembersAction(clubId);
      toast.success(
        `${getLabelByName(MEMBER)} ${getLabelByName(
          DELETED,
        ).toLowerCase()} ${getLabelByName(
          SUCCESSFULLY,
        ).toLowerCase()}`,
      );
    }

    if (
      nextProps.clubMembersData?.data?.length !==
        displayedMembers?.length ||
      nextProps.clubMembersData?.data?.length !==
        searchedMembers?.length
    ) {
      this.setState({
        displayedMembers: nextProps.clubMembersData?.data,
      });
    }
  }

  clearMembers = () => {
    this.setState({ members: [] });
  };

  setClubMemberRoleHandler = (memberRole, memberIndex) => {
    const { members } = this.state;

    const memberToUpdate = members.find(
      (member, index) => memberIndex === index,
    );

    memberToUpdate.role = memberRole;
    const newMembers = members.filter(
      (member, index) => index !== memberIndex,
    );

    newMembers.splice(memberIndex, 0, memberToUpdate);

    this.setState({ members: newMembers });
  };

  render() {
    const {
      isAdding,
      triggerPagination,
      searchedMembers,
      displayedMembers,
      dataPageLimit,
      isEditModalOpen,
      selectedMemberObject,
      confirmationModalOpen,
      isAddMemberModalOpen,
      isButtonLoading,
      members,
    } = this.state;

    const {
      editClubMembers: editClubMembersAction,
      deleteClubMember: deleteClubMemberAction,
      clubMembersData,
      getUsers: getUsersAction,
      getUsersData,
      joinClub: joinClubAction,
      match: {
        params: { id: clubId, handle },
      },
    } = this.props;

    if (!displayedMembers) {
      return (
        <>
          <Navbar pathname="manage" />
          <Loading />
        </>
      );
    }

    return (
      <>
        <Navbar pathname="manage" />
        <div className="admin-club-members">
          <div className="title">
            <div className="club-name">
              <button
                type="button"
                style={{
                  background: "none",
                  border: "none",
                  outline: "none",
                  marginTop: "-8px",
                  marginLeft: "-5px",
                }}>
                <Link
                  to={`/manage/clubs/${clubId}/${handle}`}
                  style={{
                    outline: "none",
                    color: "#424242",
                  }}>
                  <ArrowBackIosIcon />
                </Link>
              </button>
              <h1 style={{ paddingLeft: "10px" }}>
                {getLabelByName(MEMBERS)}
              </h1>
            </div>

            <div className="edit-button bigger-screens">
              <button
                type="button"
                onClick={() =>
                  this.setState({
                    isAddMemberModalOpen: true,
                  })
                }
                className="btn btn-lg submit-btn"
                data-toggle="modal"
                data-target=".create-club-modal">
                {isAdding && (
                  <>
                    <span>
                      {`${getLabelByName(
                        ADD,
                      )} ${getLabelByName(
                        NEW,
                      ).toLowerCase()}`}
                    </span>
                    <Loader />
                  </>
                )}
                {!isAdding &&
                  `${getLabelByName(ADD)} ${getLabelByName(
                    NEW,
                  ).toLowerCase()}`}
              </button>
            </div>
          </div>
          <div className="club-members-header">
            <div className="activities-count bigger-screens">
              {(!triggerPagination && searchedMembers && (
                <p>{`${searchedMembers.length} ${
                  (searchedMembers.length > 1 &&
                    getLabelByName(MEMBERS)) ||
                  getLabelByName(MEMBER)
                }`}</p>
              )) ||
                (displayedMembers && (
                  <p>{`${displayedMembers.length} ${
                    (displayedMembers.length > 1 &&
                      getLabelByName(MEMBERS)) ||
                    getLabelByName(MEMBER)
                  }`}</p>
                ))}
            </div>
            <div className="search-activities">
              <SearchBar
                isClubMembers
                setSearchedItems={(searchedItems) =>
                  this.setState({
                    searchedMembers: searchedItems,
                  })
                }
                triggerPagination
                setTriggerPagination={() =>
                  this.setState({
                    triggerPagination: false,
                  })
                }
                displayedItems={displayedMembers}
                setDisplayedItems={(displayedItems) => {
                  this.setState({
                    displayedMembers: displayedItems,
                  });
                }}
                placeholderText={`${getLabelByName(
                  SEARCH,
                )} ${getLabelByName(MEMBER).toLowerCase()}`}
              />
            </div>
            <div className="activities-count small-screens">
              {(!triggerPagination && searchedMembers && (
                <p>{`${searchedMembers.length} ${
                  (searchedMembers.length > 1 &&
                    getLabelByName(MEMBERS)) ||
                  getLabelByName(MEMBER)
                }`}</p>
              )) ||
                (displayedMembers && (
                  <p>{`${displayedMembers.length} ${
                    (displayedMembers.length > 1 &&
                      getLabelByName(MEMBERS)) ||
                    getLabelByName(MEMBER)
                  }`}</p>
                ))}
              <div className="edit-button">
                <button
                  type="button"
                  onClick={() =>
                    this.setState({
                      isAddMemberModalOpen: true,
                    })
                  }
                  className="btn btn-lg submit-btn"
                  data-toggle="modal"
                  data-target=".create-club-modal">
                  {isAdding && (
                    <>
                      <span>
                        {`${getLabelByName(
                          ADD,
                        )} ${getLabelByName(
                          NEW,
                        ).toLowerCase()}`}
                      </span>
                      <Loader />
                    </>
                  )}
                  {!isAdding &&
                    `${getLabelByName(
                      ADD,
                    )} ${getLabelByName(
                      NEW,
                    ).toLowerCase()}`}
                </button>
              </div>
            </div>
          </div>
          <div className="admin-club-members-section">
            {(!triggerPagination &&
              searchedMembers &&
              searchedMembers.map((member) => (
                <div className="admin-member-card">
                  <div className="member-card-inside-content">
                    <div className="delete-member">
                      <button
                        type="button"
                        onClick={() => {
                          this.setState({
                            confirmationModalOpen: true,
                            selectedMemberObject: member,
                          });
                        }}>
                        <DeleteOutlineIcon />
                      </button>
                    </div>
                    <div className="admin-member-avatar">
                      <img
                        src={
                          member.user.profile.picture ||
                          AvatarDefaultImage
                        }
                        alt=""
                      />
                    </div>
                    <div className="admin-member-details">
                      <h1>{`${member.user.profile.firstName} ${member.user.profile.lastName}`}</h1>
                      <p>
                        {member.role
                          .charAt(0)
                          .toUpperCase() +
                          member.role.slice(1)}
                      </p>
                    </div>
                    <div className="edit-member">
                      <button
                        type="button"
                        onClick={() =>
                          this.setState({
                            isEditModalOpen: true,
                            selectedMemberObject: member,
                          })
                        }>
                        <EditOutlineIcon />
                      </button>
                    </div>
                  </div>
                </div>
              ))) ||
              (displayedMembers &&
                displayedMembers.map((member) => (
                  <div className="admin-member-card">
                    <div className="member-card-inside-content">
                      <div className="delete-member">
                        <button
                          type="button"
                          onClick={() => {
                            this.setState({
                              confirmationModalOpen: true,
                              selectedMemberObject: member,
                            });
                          }}>
                          <DeleteOutlineIcon />
                        </button>
                      </div>
                      <div className="admin-member-avatar">
                        <img
                          src={
                            member.user.profile.picture ||
                            AvatarDefaultImage
                          }
                          alt=""
                        />
                      </div>
                      <div className="admin-member-details">
                        <h1>{`${member.user.profile.firstName} ${member.user.profile.lastName}`}</h1>
                        <p>
                          {member.role
                            .charAt(0)
                            .toUpperCase() +
                            member.role.slice(1)}
                        </p>
                      </div>
                      <div className="edit-member">
                        <button
                          type="button"
                          onClick={() =>
                            this.setState({
                              isEditModalOpen: true,
                              selectedMemberObject: member,
                            })
                          }>
                          <EditOutlineIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                )))}
          </div>
          <div className="pagination">
            {clubMembersData?.data?.length >
              dataPageLimit && (
              <Pagination
                isClubMembers
                data={clubMembersData?.data}
                displayedData={
                  searchedMembers || displayedMembers
                }
                setDisplayedData={(data) => {
                  this.setState({
                    displayedMembers: data,
                  });
                }}
                pageLimit={clubMembersData?.data?.length}
                dataLimit={dataPageLimit}
                setTriggerPagination={() =>
                  this.setState({
                    triggerPagination: true,
                  })
                }
              />
            )}
          </div>
          <Modal
            open={isEditModalOpen}
            onClose={() => {
              this.setState({ isEditModalOpen: false });
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className="edit-modal">
            <div className="edit-modal-content">
              <div className="modal-title">
                <h1>{`${getLabelByName(
                  EDIT,
                )} ${getLabelByName(
                  MEMBER,
                ).toLowerCase()} ${getLabelByName(
                  ROLE,
                ).toLowerCase()}`}</h1>
              </div>
              <div className="modal-body">
                <button
                  type="button"
                  disabled={
                    selectedMemberObject?.role === "member"
                  }
                  onClick={() => {
                    editClubMembersAction(
                      Number(selectedMemberObject?.id),
                      {
                        ...selectedMemberObject,
                        userId:
                          selectedMemberObject?.user.id,
                        role: "member",
                      },
                    );
                  }}>
                  {getLabelByName(MEMBER)}
                </button>
                <button
                  type="button"
                  disabled={
                    selectedMemberObject?.role === "admin"
                  }
                  onClick={() => {
                    editClubMembersAction(
                      Number(selectedMemberObject?.id),
                      {
                        ...selectedMemberObject,
                        userId:
                          selectedMemberObject?.user.id,
                        role: "admin",
                      },
                    );
                  }}>
                  {getLabelByName(ADMIN)}
                </button>
              </div>
            </div>
          </Modal>
          <Modal
            open={confirmationModalOpen}
            onClose={() =>
              this.setState({
                confirmationModalOpen: false,
              })
            }
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className="delete-confirmation-modal">
            <div className="delete-confirmation-modal-content">
              <div className="delete-confirmation-modal-title">
                <h1>
                  {`${getLabelByName(
                    ARE_YOU_SURE_TEXT,
                  )} ${getLabelByName(
                    YOU,
                  ).toLowerCase()} ${getLabelByName(
                    WANT,
                  ).toLowerCase()} ${getLabelByName(
                    TO_WORD,
                  )} ${getLabelByName(
                    REMOVE,
                  ).toLowerCase()} ${getLabelByName(
                    THIS_WORD,
                  ).toLowerCase()} ${getLabelByName(
                    MEMBER,
                  ).toLowerCase()}?`}
                </h1>
              </div>
              <div className="delete-confirmation-modal-body">
                <p>
                  {`${getLabelByName(
                    REMOVE_MEMBER_CONFIRMATION_TEXT,
                  )} ${getLabelByName(
                    FROM,
                  ).toLowerCase()} ${getLabelByName(
                    THE_WORD,
                  ).toLowerCase()} ${getLabelByName(
                    CLUB,
                  ).toLowerCase()}`}
                </p>
              </div>
              <div className="delete-confirmation-call-to-action-buttons">
                <div className="confirmation-remove-button">
                  <button
                    type="button"
                    onClick={() => {
                      this.setState({
                        isButtonLoading: true,
                      });
                      deleteClubMemberAction(
                        selectedMemberObject?.id,
                      );
                    }}>
                    {isButtonLoading ? (
                      <>
                        <span>{`${getLabelByName(
                          REMOVING,
                        )}  `}</span>
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status">
                          <span className="sr-only">
                            Loading...
                          </span>
                        </div>
                      </>
                    ) : (
                      getLabelByName(REMOVE)
                    )}
                  </button>
                </div>
                <div className="confirmation-cancel-button">
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({
                        confirmationModalOpen: false,
                      })
                    }>
                    {getLabelByName(CANCEL)}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {isAddMemberModalOpen && (
            <AddMemberModal
              actionId={clubId}
              isOpen={isAddMemberModalOpen}
              modalTitle={`${getLabelByName(
                ADD,
              )} ${getLabelByName(MEMBER)}`}
              modalDescription={getLabelByName(
                ADD_MEMBER_DESCRIPTION,
              )}
              isButtonLoading={isButtonLoading}
              membersFromReducer={clubMembersData}
              setIsButtonLoading={() =>
                this.setState({ isButtonLoading: true })
              }
              setIsOpen={() =>
                this.setState({
                  isAddMemberModalOpen: false,
                })
              }
              addMemberAction={async (id, payload) =>
                joinClubAction(id, payload, true)
              }
              isClub
              getUsers={() => getUsersAction()}
              getUsersData={getUsersData}
              members={members}
              setParticipantRole={
                this.setClubMemberRoleHandler
              }
              setMembers={(userId, role) =>
                this.setState({
                  members: [...members, { userId, role }],
                })
              }
            />
          )}
        </div>
      </>
    );
  }
}

ClubMembers.propTypes = {
  getSessionAction: PropTypes.func,
  getLanding: PropTypes.func,
};

const mapStateToProps = (state) => ({
  instituteData: state.instituteReducer.instituteData,
  clubMembersData: state.clubMembersReducer.clubMembersData,
  clubMembersDataError:
    state.clubMembersReducer.clubMembersDataError,
  editClubMembersLoading:
    state.editClubMembersReducer.editClubMembersLoading,
  editClubMembersData:
    state.editClubMembersReducer.editClubMembersData,
  deleteClubMembersLoading:
    state.deleteClubMembersReducer.deleteClubMembersLoading,
  deleteClubMembersStatus:
    state.deleteClubMembersReducer.deleteClubMembersStatus,
  joinClubData: state.joinClubReducer.joinClubData,
  joinClubDataError:
    state.joinClubReducer.joinClubDataError,
  getUsersData: state.getUsersReducer.getUsersData,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
});

const mapDispatchToProps = {
  getInstitute,
  getLanding,
  getSessionAction,
  getInstituteMembers,
  editClubMembers,
  deleteClubMember,
  joinClub,
  getUsers,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ClubMembers);
