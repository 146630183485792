import {
  GET_ADMIN_CLUB_ACTIVITIES_ERROR,
  GET_ADMIN_CLUB_ACTIVITIES_SUCCESS,
  GET_ADMIN_CLUB_ACTIVITIES_LOADING,
} from '../actions/types';

const initialState = {
  adminClubActivitiesData: null,
  adminClubActivitiesDataError: null,
  adminClubActivitiesStatus: null,
  adminClubActivitiesLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_CLUB_ACTIVITIES_LOADING:
      return {
        ...state,
        adminClubActivitiesData: null,
        adminClubActivitiesDataError: null,
        adminClubActivitiesLoading: true,
        adminClubActivitiesStatus: 'loading',
      };
    case GET_ADMIN_CLUB_ACTIVITIES_SUCCESS:
      return {
        ...state,
        adminClubActivitiesData: action.payload,
        adminClubActivitiesDataError: null,
        adminClubActivitiesLoading: false,
        adminClubActivitiesStatus: 'success',
      };
    case GET_ADMIN_CLUB_ACTIVITIES_ERROR:
      return {
        ...state,
        adminClubActivitiesDataError: action.payload,
        adminClubActivitiesData: null,
        adminClubActivitiesLoading: false,
        adminClubActivitiesStatus: 'error',
      };
    default:
      return {
        ...state,
        adminClubActivitiesStatus: null,
      };
  }
};
