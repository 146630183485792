/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@material-ui/icons";
import SideNav from "./SideNav/SideNav";
import Navbar from "../../../Navbar";
import "./settings.css";
import {
  getActivity,
  updateActivity,
} from "../../../../redux/actions/campaignsActions";
import { getSessionAction } from "../../../../redux/actions/authActions";
import {
  checkSession,
  getLabelByName,
  getProfileDataFromLocalStorage,
  isActionError,
  isActionSuccess,
} from "../../../../helpers/helperFunctions";
import {
  SAVE,
  SAVING,
  UPDATED_SUCCESSFULLY,
} from "../../../../helpers/constants";
import SettingsContent from "./SettingsContent";

export class ActivitySettings extends Component {
  state = {
    profileData: getProfileDataFromLocalStorage(),
    pathname: this.props.location.pathname,
    activityData: null,
    otherInfoChanged: false,
    isSaving: false,
    showMenu: false,
  };

  async UNSAFE_componentWillMount() {
    await this.load();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { props, state } = this;
    const { pathname } = state;
    const { status, data, getActivityData } = nextProps;
    if (
      props.match.params.category !==
      nextProps.match.params.category
    ) {
      await this.load();
    }

    checkSession(data, status, pathname, props);

    if (
      getActivityData?.data &&
      this.props.getActivityData !== getActivityData
    ) {
      this.setState({ activityData: getActivityData.data });
    }

    if (
      isActionSuccess(
        nextProps,
        "updateActivityStatus",
        "updateActivityData",
      )
    ) {
      toast.success(getLabelByName(UPDATED_SUCCESSFULLY));
      await this.fetchData();
      this.setState({
        isSaving: false,
        otherInfoChanged: false,
      });
    }

    if (
      isActionError(
        nextProps,
        "updateActivityStatus",
        "updateActivityData",
      )
    ) {
      toast.error(
        "Oooops! Something went wrong.\nPlease try again!",
      );
      this.setState({ isSaving: false });
    }
  }

  load = async () => {
    const { props, state } = this;
    const { profileData, pathname } = state;
    if (!profileData || profileData.status !== 200) {
      window.location.href = `/login?redirect_to=${pathname}`;
    }
    await props.getSessionAction();
    this.setState({ isLoading: true });
    await this.fetchData();
  };

  fetchData = async () => {
    const { id: activityId } = this.props.match.params;
    await this.props.getActivity(activityId);
  };

  handleRadio = (e) => {
    const val = e.target.value;
    this.setState((prevState) => ({
      activityData: {
        ...prevState.activityData,
        status: val,
      },
      otherInfoChanged: true,
    }));
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      activityData: {
        ...prevState.activityData,
        [name]: value,
      },
      otherInfoChanged: true,
    }));
  };

  settingsChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      activityData: {
        ...prevState.activityData,
        settings: {
          ...prevState.activityData.settings,
          [name]: value,
        },
      },
      otherInfoChanged: true,
    }));
  };

  switchChange = (prop) => {
    this.setState((prevState) => ({
      activityData: {
        ...prevState.activityData,
        settings: {
          ...prevState.activityData.settings,
          [prop]: !prevState.activityData.settings[prop],
        },
      },
      otherInfoChanged: true,
    }));
  };

  notificationsChange = (value, notifCategory, prop) => {
    this.setState((prevState) => ({
      activityData: {
        ...prevState.activityData,
        settings: {
          ...prevState.activityData?.settings,
          notifications: {
            ...prevState.activityData?.settings
              ?.notifications,
            [notifCategory]: {
              ...prevState.activityData?.settings
                ?.notifications?.[notifCategory],
              [prop]: value,
            },
          },
        },
      },
      otherInfoChanged: true,
    }));
  };

  notifSwitchChange = (e, notifCategory, prop) => {
    this.setState((prevState) => ({
      activityData: {
        ...prevState.activityData,
        settings: {
          ...prevState.activityData?.settings,
          notifications: {
            ...prevState.activityData?.settings
              ?.notifications,
            [notifCategory]: {
              ...prevState.activityData?.settings
                ?.notifications?.[notifCategory],
              [prop]:
                !prevState.activityData?.settings
                  ?.notifications?.[notifCategory]?.[prop],
            },
          },
        },
      },
      otherInfoChanged: true,
    }));
  };

  onSave = async () => {
    const { activityData } = this.state;
    this.setState({ isSaving: true });
    await this.props.updateActivity(
      activityData.id,
      activityData,
    );
  };

  toggleMenu = () =>
    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }));

  render() {
    const { category } = this.props.match.params;
    const {
      activityData,
      otherInfoChanged,
      isSaving,
      showMenu,
    } = this.state;

    return (
      <div className="activity-settings">
        <Navbar pathname="manage" />
        <div className="activity-settings-container row">
          <div
            className="activity-settings-sidenav"
            style={{ paddingTop: "1rem" }}>
            <SideNav {...this.props} />
          </div>

          <div className="activity-settings-content">
            <div className="activity-settings-content-title d-flex justify-content-between">
              <div>{activityData?.name}</div>
              <div>
                <div className="join-button">
                  <button
                    type="button"
                    className="btn btn-lg submit-btn"
                    disabled={!otherInfoChanged || isSaving}
                    onClick={() => this.onSave()}>
                    {isSaving
                      ? getLabelByName(SAVING)
                      : getLabelByName(SAVE)}
                  </button>
                </div>
              </div>
            </div>

            <SettingsContent
              category={category}
              activityData={activityData}
              handleRadio={this.handleRadio}
              handleChange={this.handleChange}
              settingsChange={this.settingsChange}
              switchChange={this.switchChange}
              notificationsChange={this.notificationsChange}
              notifSwitchChange={this.notifSwitchChange}
            />
          </div>
        </div>

        <div className="activity-settings-mobile-container">
          <div className="activity-settings-mobile">
            <div className="activity-settings-mobile__menu-bar">
              <div
                className="current-entity"
                onClick={() => this.toggleMenu()}>
                {category[0].toUpperCase() +
                  category.slice(1)}
                {showMenu ? (
                  <KeyboardArrowUpRounded className="ml-3" />
                ) : (
                  <KeyboardArrowDownRounded className="ml-3" />
                )}
              </div>
              <div>
                <div className="join-button">
                  <button
                    type="button"
                    className="btn btn-lg submit-btn"
                    disabled={!otherInfoChanged || isSaving}
                    onClick={() => this.onSave()}
                    style={{
                      width: "7rem",
                      height: "32px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "0",
                    }}>
                    {isSaving
                      ? getLabelByName(SAVING)
                      : getLabelByName(SAVE)}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {showMenu && (
            <div className="mobile-nav">
              <SideNav
                {...this.props}
                toggleMenu={this.toggleMenu}
              />
            </div>
          )}

          <SettingsContent
            category={category}
            activityData={activityData}
            handleRadio={this.handleRadio}
            handleChange={this.handleChange}
            settingsChange={this.settingsChange}
            switchChange={this.switchChange}
            notificationsChange={this.notificationsChange}
            notifSwitchChange={this.notifSwitchChange}
          />
        </div>
      </div>
    );
  }
}

ActivitySettings.propTypes = {};

export const mapStateToProps = (state) => ({
  getActivityDataError:
    state.getActivityReducer.getActivityDataError,
  getActivityData: state.getActivityReducer.getActivityData,
  getActivityStatus:
    state.getActivityReducer.getActivityStatus,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,

  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,
  clubData: state.instituteReducer.instituteData,

  updateActivityData:
    state.updateActivityReducer.updateActivityData,
  updateActivityDataError:
    state.updateActivityReducer.updateActivityDataError,
  updateActivityStatus:
    state.updateActivityReducer.updateActivityStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getActivity,
    getSessionAction,
    updateActivity,
  }),
)(ActivitySettings);
