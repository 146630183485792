import {
  CREATE_QUESTIONNAIRE_SUCCESS, CREATE_QUESTIONNAIRE_ERROR,
} from '../actions/types';

const initialState = {
  createQuestionnaireData: null,
  createQuestionnaireDataError: null,
  createQuestionnaireStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        createQuestionnaireData: action.payload,
        createQuestionnaireDataError: null,
        createQuestionnaireStatus: 'success',
      };
    case CREATE_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        createQuestionnaireDataError: action.payload,
        createQuestionnaireData: null,
        createQuestionnaireStatus: 'error',
      };
    default:
      return {
        ...state,
        createQuestionnaireStatus: null,
      };
  }
};
