/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
import {
  ArrowBackIos,
  ArrowForwardIos,
  Clear,
} from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  Link,
  Redirect,
  withRouter,
} from "react-router-dom";
import Navbar from "../Navbar";
import searchOrange from "../../assets/icons/search-orange.svg";
import logo from "../../assets/logo-opac.svg";
import Footer from "../Footer";
import { getSessionAction } from "../../redux/actions/authActions";
import {
  checkSession,
  getLabelByName,
} from "../../helpers/helperFunctions";
import FilterAlphabet from "./FilterAlphabet";
import OutputContributionCard from "./OutputContributionCard";
import {
  getContributions,
  getOutput,
} from "../../redux/actions/contributionsActions";
import {
  BACK,
  colors,
  NEXT,
  OF_WORD,
  PREVIOUS,
  SHOWING,
} from "../../helpers/constants";
import OutputCalendar from "./OutputCalendar";
import GalleryOutput from "./GalleryOutput";
import MediaLibrary from "./MediaLibrary/MediaLibrary";

class OutputPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      pageTitle: `${
        process.env.REACT_APP_APPLICATION_NAME ||
        "The Alma Maters Club"
      } - `,
      outputData: null,
      isLoading: false,
      nbPages: 1,
      searchValue: "",
      displaySearch: false,
      currentPage: 1,
      pageDivider: 10,
      pathname: this.props.location.pathname,
      selectedOption: "All",
    };
  }

  displayOutput() {
    let dispaly = <p>Loading</p>;

    switch (this.getOutputDetails()?.type) {
      case "Default":
        dispaly = (
          <>
            <FilterAlphabet
              handleFilterClick={this.handleFilterClick}
              selectedOption={this.state.selectedOption}
            />
            <div className="authors-list">
              {this.getSelectedResourceAndFormat()?.length >
              0 ? (
                this.getSelectedResourceAndFormat()?.map(
                  (item) => (
                    <OutputContributionCard
                      key={item.id}
                      resource={item}
                      logo={this.getLogo()}
                      urlParams={this.props.match.params}
                      output={this.getOutputDetails()}
                      listing_profile_output={
                        this.getOutputDetails()?.settings
                          .listing_profile_output
                      }
                    />
                  ),
                )
              ) : (
                <div className="w-100 text-center font-weight-bold mb-5 mt-5">
                  No items to display
                </div>
              )}
            </div>
          </>
        );
        break;

      case "GALLERY_PAGE":
        dispaly = (
          <>
            {this.dataHasLoaded(
              this.props.getOutputData,
            ) && (
              <div className="authors-list">
                {this.getSelectedResourceAndFormat()
                  .length > 0 ? (
                  this.getSelectedResourceAndFormat().map(
                    (item, index) => (
                      <GalleryOutput
                        resource={item}
                        index={index}
                        urlParams={this.props.match.params}
                        outputData={
                          this.props.getOutputData.data
                        }
                        logo={this.getLogo()}
                        allResources={this.getSelectedResourceAndFormat()}
                      />
                    ),
                  )
                ) : (
                  <div className="w-100 text-center font-weight-bold mb-5 mt-5">
                    No data to display at this time
                  </div>
                )}
              </div>
            )}
          </>
        );
        break;

      case "CALENDAR":
        dispaly = this.dataHasLoaded(
          this.props.getOutputData,
        ) && (
          <OutputCalendar
            contributions={this.getResourceData()}
            output={this.getOutputDetails()}
          />
        );
        break;

      default:
        dispaly = (
          <>
            <FilterAlphabet
              handleFilterClick={this.handleFilterClick}
              selectedOption={this.state.selectedOption}
            />
            <div className="authors-list">
              {this.getSelectedResourceAndFormat().length >
              0 ? (
                this.getSelectedResourceAndFormat().map(
                  (item) => (
                    <OutputContributionCard
                      resource={item}
                      logo={this.getLogo()}
                      urlParams={this.props.match.params}
                      listing_profile_output={
                        this.getOutputDetails()?.settings
                          .listing_profile_output
                      }
                    />
                  ),
                )
              ) : (
                <div className="w-100 text-center font-weight-bold mb-5 mt-5">
                  No authors!
                </div>
              )}
            </div>
          </>
        );
        break;
    }

    return dispaly;
  }

  isMember = (props) => {
    const { profileData, match } = props;
    let isMember = false;

    const clubId = match?.params?.clubId;

    console.log("Member_Data", { clubId, profileData });

    if (profileData?.data) {
      isMember = Object.keys(
        profileData?.data?.clubs,
      ).includes(clubId.toString());
    }

    return isMember;
  };

  render() {
    document.title = this.state.pageTitle;

    const { getOutputData } = this.props;

    return (
      <>
        <Navbar pathname="explore" />
        {this.getOutputDetails()?.type !==
          "MEDIA_LIBRARY" && (
          <div className="manage-clubs-page authors-page">
            <div className="container-fluid">
              <div className="buttons-row">
                <div id="back-link">
                  <div className="d-flex justify-content-center align-items-center">
                    <Link
                      to="/home"
                      style={{
                        color: colors.textPrimary,
                        font: "Book 20px/17px Avenir",
                      }}>
                      <ArrowBackIos className="arrow-back-icon" />
                      {"  "}
                      {getLabelByName(BACK)}
                    </Link>
                  </div>
                </div>
              </div>

              {this.dataHasLoaded(
                this.props.getOutputData,
              ) && (
                <div className="manage-clubs-content authors-page-content">
                  <div className="clubs-details">
                    <h2
                      className="clubs-title"
                      style={{ marginBottom: "unset" }}>
                      {this.state.title}
                    </h2>
                    <p className="">
                      {this.state.description}
                    </p>
                    {this.getOutputDetails()?.type !==
                      "CALENDAR" && (
                      <div className="clubs-stats">
                        {this.dataHasLoaded(
                          this.props.getContributionsData,
                        ) && (
                          <div className="clubs-page-indicator">
                            {getLabelByName(SHOWING)}
                            <span className="font-weight-bold pl-1 pr-1">
                              {this.getPageRange().first}
                              {" - "}
                              {this.getPageRange().last}
                            </span>
                            {"  "}
                            {getLabelByName(OF_WORD)}
                            {"  "}
                            <span className="font-weight-bold pl-1 pr-1">
                              {this.getResourceLength()}
                            </span>
                            {this.state.title}
                          </div>
                        )}
                        <div className="search-clubs">
                          <form className="form">
                            <div className="input-group mb-2">
                              <input
                                type="text"
                                className="form-control"
                                id="inputSearch"
                                placeholder={`Search ${this.state.title}`}
                                name="searchValue"
                                value={
                                  this.state.searchValue
                                }
                                onChange={this.handleChange}
                              />
                              {this.state.searchValue && (
                                <div className="input-group-append">
                                  <button
                                    id="clear-search-btn"
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={() =>
                                      this.clearSearch()
                                    }>
                                    <Clear />
                                  </button>
                                </div>
                              )}
                              <div className="input-group-append">
                                <button
                                  id="search-btn"
                                  className="btn btn-outline-secondary"
                                  type="button">
                                  <img
                                    src={searchOrange}
                                    alt="search-icon"
                                    style={{
                                      width: "20px",
                                    }}
                                  />
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="authors-cards">
                    {this.displayOutput()}
                    <div className="navigation-buttons">
                      <div>
                        <button
                          type="button"
                          className="btn btn-sm prev-btn"
                          disabled={
                            this.state.currentPage === 1
                          }
                          onClick={() =>
                            this.decrementPage()
                          }>
                          <ArrowBackIos className="pagination-icon" />
                          {getLabelByName(PREVIOUS)}
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm next-btn"
                          disabled={
                            this.state.currentPage ===
                            this.state.nbPages
                          }
                          onClick={() =>
                            this.incrementPage()
                          }>
                          {getLabelByName(NEXT)}
                          <ArrowForwardIos className="pagination-icon pl-1" />
                        </button>
                      </div>
                      <div className="page-indicator">
                        Page{" "}
                        <span className="current-page">
                          {this.state.currentPage}
                        </span>
                        {" / "}
                        {this.state.nbPages}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {this.getOutputDetails()?.type ===
          "MEDIA_LIBRARY" && (
          <MediaLibrary
            contributions={this.getResourceData()}
            settings={this.state.outputData?.settings}
            sources={this.getOutputDetails().sources}
          />
        )}
        <div className="footer-block">
          <div className="footer-component">
            <Footer />
          </div>
        </div>
      </>
    );
  }

  async UNSAFE_componentWillMount() {
    const { props, state } = this;
    const { pathname } = state;

    // const { profileData } = props;

    // if (!profileData || profileData.status !== 200) {
    //   window.location.href = `/login?redirect_to=${pathname}`;
    // }
    await props.getSessionAction();
    await this.fetchData();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { pageDivider, pathname } = this.state;
    const {
      getOutputData,
      getOutputStatus,
      getContributionsData,
      data,
      status,
      history,
    } = nextProps;

    // checkSession(data, status, pathname, this.props);

    const isUserMember = this.isMember(nextProps);

    if (
      !isUserMember &&
      getOutputData?.data?.visibility === "PRIVATE"
    ) {
      console.log("IS_User_Member", { isUserMember });
      // return <Redirect push to="/404" />;

      return history.push("/404");
    }

    if (
      getOutputStatus === "success" &&
      this.dataHasLoaded(getOutputData)
    ) {
      this.setState({
        title: getOutputData.data.settings.title,
        description:
          getOutputData.data.settings.description,
        pageTitle:
          this.state.pageTitle +
          getOutputData.data.settings.title,
        outputData: getOutputData?.data,
      });
      const query = `?q={"status":"APPROVED","activityId":${
        this.getOutputSource(getOutputData.data)?.activityId
      },\
        "actionId":${
          this.getOutputSource(getOutputData.data)?.actionId
        } }`;
      await this.props.getContributions(query);
    }

    if (this.dataHasLoaded(getContributionsData)) {
      this.setState({
        nbPages: Math.ceil(
          getContributionsData.data.length / pageDivider,
        ),
      });
    }
  }

  fetchData = async () => {
    const { props } = this;
    const { clubId, outputId } = props.match.params;
    await props.getOutput(clubId, outputId);
  };

  getOutputDetails = () => this.state.outputData;

  getOutputSource = (output) => output.sources.listings;

  getResourceData = () =>
    this.dataHasLoaded(this.props.getContributionsData)
      ? this.props.getContributionsData.data
      : [];

  getSelectedResourceAndFormat = () => {
    let resource = this.getResourceData();

    let listingsToGet = [];

    if (
      this.getOutputDetails()?.sources.listings.settings
    ) {
      listingsToGet = Object.keys(
        this.getOutputDetails()?.sources.listings.settings,
      );
    }

    if (this.getOutputDetails()?.settings) {
      Object.keys(
        this.getOutputDetails()?.settings,
      ).forEach((setting) => {
        listingsToGet.push(setting);
      });
    }

    resource = resource.map((item) => {
      const itemFormatted = {};
      if (listingsToGet.length > 0) {
        listingsToGet.forEach((prop) => {
          let sourceSettingsToGet;
          if (prop) {
            sourceSettingsToGet =
              this.getOutputDetails()?.sources?.listings
                ?.settings[prop];
          }

          if (sourceSettingsToGet) {
            itemFormatted[prop] = this.getListingBySetting(
              item,
              sourceSettingsToGet,
            );
            itemFormatted.dateCreated = item.dateCreated;
            itemFormatted.participant = item.participant;
          }

          const settingsToGet =
            this.getOutputDetails()?.settings[prop];

          if (settingsToGet) {
            itemFormatted[prop] = this.getListingBySetting(
              item,
              settingsToGet,
            );
            itemFormatted.dateCreated = item.dateCreated;
            itemFormatted.participant = item.participant;
          }
        });

        itemFormatted.participantId = item.participantId;
      }
      return itemFormatted;
    });

    if (this.state.selectedOption !== "All") {
      resource = resource.filter(
        (item) =>
          item.listing_title[0] ===
          this.state.selectedOption,
      );
    }

    if (this.state.searchValue) {
      const regex = new RegExp(
        this.state.searchValue.toLowerCase(),
        "g",
      );
      resource = resource.filter((item) =>
        item.listing_title.toLowerCase().match(regex),
      );
    }

    return resource;
  };

  handleFilterClick = (selectedOption) => {
    this.setState({ selectedOption });
  };

  dataHasLoaded = (state) => !!(state && state.data);

  getPageRange() {
    const { currentPage, pageDivider } = this.state;
    const first = (currentPage - 1) * pageDivider + 1;
    let last = first + (pageDivider - 1);
    if (
      this.dataHasLoaded(this.props.getContributionsData) &&
      last > this.getResourceLength()
    ) {
      last = this.getResourceLength();
    }
    return { first, last };
  }

  getResourceLength() {
    return this.getResourceData().length;
  }

  incrementPage = () => {
    let { nbPages, currentPage } = this.state;
    currentPage =
      currentPage === nbPages
        ? currentPage
        : currentPage + 1;
    this.setState({ currentPage });
  };

  decrementPage = () => {
    let { currentPage } = this.state;
    currentPage =
      currentPage === 1 ? currentPage : currentPage - 1;
    this.setState({ currentPage });
  };

  getLogo = () => {
    let defaultLogo = logo;
    if (
      this.props.landingData &&
      this.props.landingData.data
    ) {
      const landingInfo =
        this.props.landingData.data.filter(
          (x) => x.name === "landing-page",
        )[0];
      if (
        landingInfo &&
        landingInfo.data &&
        landingInfo.data.logo
      ) {
        defaultLogo = landingInfo.data.logo;
      }
    }

    return defaultLogo;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  clearSearch = () => {
    this.setState({ searchValue: "" });
  };

  getListingBySetting = (contribution, setting) => {
    const words = setting.split(" ");
    const variables = words.filter(
      (word) => word[0] === ":",
    );
    const formattedVariables = variables.map((variable) =>
      variable.slice(1),
    );
    const values = formattedVariables.map((variable) => {
      if (
        variable.substring(0, "participant".length) ===
        "participant"
      ) {
        const participantVariable = variable.slice(
          "participant.".length,
        );
        return contribution.participant[
          participantVariable
        ];
      }
      if (
        variable.substring(0, "activity".length) ===
        "activity"
      ) {
        const activityVariable = variable.slice(
          "activity.".length,
        );
        return contribution.activity[0][activityVariable];
      }
      if (
        variable.substring(0, "action".length) === "action"
      ) {
        const actionVariable = variable.slice(
          "action.".length,
        );
        return contribution.action[0][actionVariable];
      }
      if (contribution.data[variable] !== "undefined") {
        return contribution.data[variable];
      }
    });
    let variableTracker = 0;
    const newWords = words.map((word) => {
      let newWord = word;
      if (word[0] === ":") {
        newWord = values[variableTracker];
        variableTracker += 1;
      }
      return newWord;
    });

    const listingSubtitle = newWords.join(" ");
    return listingSubtitle;
  };
}

OutputPage.propTypes = {
  getSessionAction: PropTypes.func,
  getOutput: PropTypes.func,
  getContributions: PropTypes.func,
  getOutputData: PropTypes.any,
  getOutputStatus: PropTypes.any,
  getContributionsData: PropTypes.any,
  landingData: PropTypes.any,
  data: PropTypes.any,
  status: PropTypes.any,
  match: PropTypes.any,
  location: PropTypes.any,
};

const mapStateToProps = (state) => ({
  getOutputDataError:
    state.getOutputReducer.getOutputDataError,
  getOutputData: state.getOutputReducer.getOutputData,
  getOutputStatus: state.getOutputReducer.getOutputStatus,

  getContributionsDataError:
    state.getContributionsReducer.getContributionsDataError,
  getContributionsData:
    state.getContributionsReducer.getContributionsData,
  getContributionsStatus:
    state.getContributionsReducer.getContributionsStatus,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  profileData: state.authReducer.data,
  status: state.authReducer.status,

  landingData: state.landingReducer.landingData,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getSessionAction,
    getOutput,
    getContributions,
  }),
)(OutputPage);
