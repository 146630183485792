import React from "react";
import { Route } from "react-router-dom";
import AuthContainer from "../components/AuthContainer";
import Login from "../components/Login";
import Signup from "../components/Signup";
import ResetPassword from "../components/ResetPassword";

const Authentication = () => (
  <>
    <Route exact path="/login">
      <>
        <AuthContainer>
          <Login />
        </AuthContainer>
      </>
    </Route>
    <Route exact path="/signup">
      <>
        <AuthContainer>
          <Signup />
        </AuthContainer>
      </>
    </Route>
    <Route exact path="/password/reset">
      <>
        <AuthContainer>
          <ResetPassword />
        </AuthContainer>
      </>
    </Route>
  </>
);

export default Authentication;
