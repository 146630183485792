import {
  GET_QUESTIONNAIRE_SUCCESS,
  GET_QUESTIONNAIRE_ERROR,
  GET_QUESTIONNAIRE_LOADING,
} from '../actions/types';

const initialState = {
  getQuestionnaireData: null,
  getQuestionnaireDataError: null,
  getQuestionnaireStatus: null,
  getQuestionnaireLoading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        getQuestionnaireData: action.payload,
        getQuestionnaireDataError: null,
        getQuestionnaireStatus: 'success',
        getQuestionnaireLoading: false,
      };
    case GET_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        getQuestionnaireDataError: action.payload,
        getQuestionnaireData: null,
        getQuestionnaireStatus: 'error',
        getQuestionnaireLoading: false,
      };
    case GET_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        getQuestionnaireDataError: null,
        getQuestionnaireData: null,
        getQuestionnaireStatus: 'loading',
        getQuestionnaireLoading: true,
      };
    default:
      return {
        ...state,
        getQuestionnaireStatus: null,
      };
  }
};
