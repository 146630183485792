/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable dot-notation */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { Menu } from "@material-ui/icons";
import { Slide } from "@mui/material";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../assets/logo-opac.svg";
import homeDark from "../assets/icons/home-black.svg";
import homeOrange from "../assets/icons/home-orange.svg";
import searchDark from "../assets/icons/search-black.svg";
import searchOrange from "../assets/icons/search-orange.svg";
import userDark from "../assets/icons/user-black.svg";
import userOrange from "../assets/icons/user-orange.svg";
import createDark from "../assets/icons/create-black.svg";
import createOrange from "../assets/icons/create-orange.svg";
import notificationsDark from "../assets/icons/notifications-black.svg";
import notificationsOrange from "../assets/icons/notifications-orange.svg";
import settingsIcon from "../assets/icons/settings.svg";
import settingsIconOrange from "../assets/icons/settings-orange.svg";
import {
  getLabelByName,
  getUserLanguage,
  isActionSuccess,
  isSectionAdmin,
} from "../helpers/helperFunctions";
import { getNotifications } from "../redux/actions/notificationsActions";
import { requestVerification } from "../redux/actions/usersActions";
import {
  colors,
  CREATE,
  EXPLORE,
  HOME,
  POSTED,
  NOTIFICATIONS,
  SETTINGS,
  languageApiMapping,
  SEARCH,
  AND_WORD,
  DISCOVER,
  PROFILE,
} from "../helpers/constants";
import NotificationsDropdown from "./notifications/NotificationsDropdown";
import CreateDropdown from "./generics/CreateDropdown";
import {
  getLabelsByLanguage,
  getLanding,
} from "../redux/actions/landingActions";
import SideNav from "./home/SideNav";

export class Navbar extends Component {
  state = {
    openToggleMenu: false,
  };

  toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ ...this.state, [anchor]: open });
  };

  handleClose = () => {
    this.setState({ openToggleMenu: false });
  };

  render() {
    const {
      pathname,
      campaignDate,
      profileData,
      landingData,
    } = this.props;

    let landingInfo = null;
    if (landingData && landingData.data) {
      landingInfo = landingData.data.filter(
        (x) => x.name === "landing-page",
      )[0];
    }

    const urls = [
      "/login",
      "/signup",
      "/",
      "/reset-password",
    ];
    const displayNavbar = !(
      urls.includes(pathname) || pathname.match(/password/)
    );

    const userRoles = profileData?.data?.roles || [];

    const userClubs = profileData?.data?.clubs
      ? Object.values(profileData?.data?.clubs)
      : [];

    const userIsClubAdmin =
      userClubs.filter((club) => club.role === "admin")
        .length > 0;

    const userIsSuperAdmin = userRoles.includes("admin");
    const userPicture = profileData?.data?.profile?.picture;

    const userIsVerified = profileData?.data?.meta.hasOwnProperty('verifiedAt')

    const SEARCH_AND_DISCOVER = `${getLabelByName(
      SEARCH,
    )} ${getLabelByName(
      AND_WORD,
    )?.toLowerCase()} ${getLabelByName(
      DISCOVER,
    )?.toLowerCase()}`;

    const userIsASectionAdmin =
      isSectionAdmin(["site_admin"]) ||
      isSectionAdmin(["club_admin"]) ||
      isSectionAdmin(["activity_admin"]) ||
      isSectionAdmin(["users_admin"]) ||
      isSectionAdmin(["posts_admin"]) ||
      isSectionAdmin(["questionnaires_admin"]);

    const requestVerification = () => {
      this.props.requestVerification({username: profileData?.data?.username})
    }

    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openToggleMenu}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Slide
            direction="right"
            in={this.state.openToggleMenu}>
            <Box
              style={{
                background: "#ffffff",
                display: "flex",
                outline: "none",
                height: "100%",
                width: "90%",
              }}>
              <SideNav
                updateLinkItemCount={
                  this.props.updateLinkItemCount
                }
                toggleFeed={this.props.toggleFeed}
                mobile
              />
              <div
                className="close-icon"
                aria-hidden
                onClick={() =>
                  this.setState({ openToggleMenu: false })
                }>
                <CloseIcon />
              </div>
            </Box>
          </Slide>
        </Modal>
        {displayNavbar && (
          // <nav className="navbar fixed-top navbar-expand-lg navbar-light">
          <div  className="fixed-top">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid top-nav-bar">
                <Link
                  to="/home"
                  className="navbar-brand-mobile mb-0 h5">
                  <img
                    src={
                      landingInfo &&
                      landingInfo.data &&
                      landingInfo.data["logo"]
                        ? landingInfo.data["logo"]
                        : logo
                    }
                    alt="logo"
                    className="nav-logo"
                  />
                </Link>
                <Link to="/explore" className="search-bar">
                  <div className="rectangle-search-bar">
                    <SearchIcon />
                    <span className="search-and-discover">
                      {SEARCH_AND_DISCOVER}
                    </span>
                  </div>
                </Link>
                <Link
                  className="nav-link text-center profile-image"
                  to="/profile">
                  <span
                    className={`${
                      pathname.match(/profile/) ||
                      pathname.match(/manage/)
                        ? "active-link"
                        : ""
                    }`}>
                    <img
                      src={
                        pathname.match(/profile/)
                          ? userPicture || userOrange
                          : userPicture || userDark
                      }
                      alt="user-icon"
                      style={{
                        width: "50px",
                        height: "50px",
                        border:
                          pathname.match(/profile/) &&
                          `2px solid ${colors.primaryColor}`,
                        borderRadius: "50%",
                        padding: 0,
                      }}
                    />
                    <br />
                  </span>
                </Link>
              </div>
              <div
                className="navbar-collapse justify-content-end"
                id="navbarSupportedContent">
                <div className="all-nav-items">
                  {!campaignDate ? (
                    <ul className="navbar-nav navbar-nav__custom">
                      <li
                        className="nav-item burger-icon"
                        title="menu"
                        aria-hidden
                        onClick={() =>
                          this.setState({
                            openToggleMenu:
                              !this.state.openToggleMenu,
                          })
                        }>
                        <Menu />
                      </li>
                      <li
                        className="nav-item"
                        title={getLabelByName(HOME)}>
                        <Link
                          className="nav-link text-center"
                          to="/home">
                          <span
                            className={`${
                              pathname.match(/home/)
                                ? "active-link"
                                : ""
                            }`}>
                            <img
                              src={
                                pathname.match(/home/)
                                  ? homeOrange
                                  : homeDark
                              }
                              alt="home-icon"
                              width="30px"
                            />
                            <br />
                          </span>
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        title={getLabelByName(EXPLORE)}>
                        <Link
                          className="nav-link text-center"
                          to="/explore">
                          <span
                            className={`${
                              pathname.match(/search/) ||
                              pathname.match(/explore/) ||
                              pathname.match(/club/) ||
                              pathname.match(/member/)
                                ? "active-link"
                                : ""
                            }`}>
                            <img
                              src={
                                pathname.match(/search/) ||
                                pathname.match(/explore/) ||
                                pathname.match(/club/) ||
                                pathname.match(/member/)
                                  ? searchOrange
                                  : searchDark
                              }
                              alt="search-icon"
                              width="30px"
                            />
                            <br />
                          </span>
                        </Link>
                      </li>
                      {(userIsClubAdmin ||
                        userIsSuperAdmin) && (
                        <li
                          className="nav-item nav-create"
                          title={getLabelByName(CREATE)}>
                          <div className="dropdown show">
                            <span
                              className="nav-link text-center dropdown-toggle"
                              role="button"
                              id="dropdownCreate"
                              data-toggle="dropdown"
                              aria-expanded="false">
                              <span
                                className={`${
                                  pathname.match(
                                    /create-article/,
                                  )
                                    ? "active-link"
                                    : ""
                                }`}>
                                <img
                                  src={
                                    pathname.match(
                                      /create-article/,
                                    )
                                      ? createOrange
                                      : createDark
                                  }
                                  alt="home-icon"
                                  width="30px"
                                />
                                <br />
                              </span>
                            </span>
                            <CreateDropdown createDropdown />
                          </div>
                        </li>
                      )}

                      {(userIsSuperAdmin ||
                        userIsASectionAdmin) && (
                        <li
                          className="nav-item nav-create"
                          id="nav-setting"
                          title={getLabelByName(SETTINGS)}>
                          <div className="dropdown show">
                            <span
                              className="nav-link text-center dropdown-toggle"
                              role="button"
                              id="dropdownCreate"
                              data-toggle="dropdown"
                              aria-expanded="false">
                              <span
                                className={`${
                                  pathname.match(/manage/)
                                    ? "active-link"
                                    : ""
                                }`}>
                                <img
                                  src={
                                    pathname.match(/manage/)
                                      ? settingsIconOrange
                                      : settingsIcon
                                  }
                                  alt="home-icon"
                                  width="30px"
                                />
                                <br />
                              </span>
                            </span>
                            <CreateDropdown
                              settingsDropdown
                            />
                          </div>
                        </li>
                      )}
                      <li
                        className="nav-item nav-notifications"
                        title={getLabelByName(NOTIFICATIONS)}>
                        <div className="dropdown show">
                          <span
                            className="nav-link text-center dropdown-toggle"
                            role="button"
                            id="dropdownNotifications"
                            data-toggle="dropdown"
                            aria-expanded="false">
                            <span
                              className={`${
                                pathname.match(
                                  /notifications/,
                                )
                                  ? "active-link"
                                  : ""
                              }`}>
                              <span>
                                <img
                                  src={
                                    pathname.match(
                                      /notifications/,
                                    )
                                      ? notificationsOrange
                                      : notificationsDark
                                  }
                                  alt="notifications-icon"
                                  width="30px"
                                />
                                {this.getUnreadNotifications()
                                  .length > 0 &&
                                  this.getUnreadNotifications()
                                    .length < 99 && (
                                    <span className="notifications-number-badge">
                                      {
                                        this.getUnreadNotifications()
                                          .length
                                      }
                                    </span>
                                  )}
                                {this.getUnreadNotifications()
                                  .length > 99 && (
                                  <span className="notifications-number-badge">
                                    99+
                                  </span>
                                )}
                              </span>
                              <br />
                            </span>
                          </span>
                          <NotificationsDropdown
                            unreadNotifications={this.getUnreadNotifications()}
                          />
                        </div>
                      </li>
                      <li
                        className="nav-item profile-web"
                        title={getLabelByName(PROFILE)}>
                        <Link
                          className="nav-link text-center"
                          to="/profile">
                          <span
                            className={`${
                              pathname.match(/profile/) ||
                              pathname.match(/manage/)
                                ? "active-link"
                                : ""
                            }`}>
                            <img
                              src={
                                pathname.match(/profile/)
                                  ? userPicture || userOrange
                                  : userPicture || userDark
                              }
                              alt="user-icon"
                              style={{
                                width: "50px",
                                height: "50px",
                                border:
                                  pathname.match(/profile/) &&
                                  `2px solid ${colors.primaryColor}`,
                                borderRadius: "50%",
                              }}
                            />
                            <br />
                          </span>
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    <div className="nav-campaign-date">
                      {getLabelByName(POSTED)}{" "}
                      <span className="campaign-date-nav">
                        {campaignDate}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </nav>
            {
              !userIsVerified ? (
                <div className="verify-account">
                  <p>Verify your account to have access to more features</p>
                  <p onClick={requestVerification}>Verify account now</p>
                </div>
              ) : null
            }
          </div>
        )}
      </>
    );
  }

  async UNSAFE_componentWillMount() {
    await this.fetchNotifications();
    await this.props.getLanding();
    await this.props.getLabelsByLanguage(
      languageApiMapping[getUserLanguage()],
    );
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      isActionSuccess(
        nextProps,
        "markNotificationStatus",
        "markNotificationData",
      )
    ) {
      await this.fetchNotifications();
    }
  }

  getUnreadNotifications = () =>
    this.getNotificationsData().filter(
      (notification) => notification.status === "UNREAD",
    );

  fetchNotifications = async () => {
    const { profileData } = this.props;

    const userId = profileData?.data?.id;

    if (userId) {
      await this.props.getNotifications(userId);
    }
  };

  getNotificationsData = () =>
    this.dataHasLoaded(this.props.getNotificationsData)
      ? this.props.getNotificationsData.data
      : [];

  dataHasLoaded = (state) => !!(state && state.data);
}

Navbar.propTypes = {
  pathname: PropTypes.string,
  campaignDate: PropTypes.string,
  landingData: PropTypes.object,
  getNotifications: PropTypes.func,
  getNotificationsData: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,

  profileData: state.authReducer.data,

  getNotificationsData:
    state.getNotificationsReducer.getNotificationsData,
  markNotificationData:
    state.markNotificationReducer.markNotificationData,
  markNotificationDataError:
    state.markNotificationReducer.markNotificationDataError,
  markNotificationStatus:
    state.markNotificationReducer.markNotificationStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getNotifications,
    getLanding,
    getLabelsByLanguage,
    requestVerification,
  }),
)(Navbar);
