import React, { useState } from "react";
import OutputDetailsForm from "./OutputDetailsForm";
import OutputSourcesForm from "./OutputSourcesForm";
import OutputSettingsForm from "./OutputSettingsForm";
import WizardStep from "./WizardStep";

const WizardForms = ({ closeModal }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    if (currentStep < forms.length - 1) {
      setCurrentStep(currentStep + 1);
    }
    return null;
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
    return null;
  };

  const jumpTo = (step) => {
    if (step >= 0 && step < forms.length) {
      setCurrentStep(step);
    }
  };

  const forms = [
    {
      name: "Output Details",
      title: "First Form",
      form: (
        <OutputDetailsForm
          nextStep={nextStep}
          closeModal={closeModal}
        />
      ),
    },
    {
      name: "Output Settings",
      title: "Second Form",
      form: (
        <OutputSettingsForm
          prevStep={prevStep}
          nextStep={nextStep}
          closeModal={closeModal}
        />
      ),
    },
    {
      name: "Source Settings",
      title: "Third Form",
      form: <OutputSourcesForm closeModal={closeModal} />,
    },
  ];

  return (
    <>
      <header className="box-shadow-none">
        <ul
          className="output-step-indicator mb-0 pl-0 pt-3"
          style={{ listStyle: "none" }}>
          {/* <WizardStep
            name={"Previous"}
            step={currentStep}
            onClick={prevStep}
          /> */}

          <hr className="first-line" />

          {forms.map((form, index) => (
            <WizardStep
              key={index}
              name={form.name}
              step={index}
              onClick={jumpTo}
              isActive={currentStep === index}
            />
          ))}

          <hr className="fourth-line" />

          {/* <WizardStep
            name={"Next"}
            step={currentStep}
            onClick={nextStep}
          /> */}
        </ul>
      </header>

      <form className="d-flex flex-column">
        {forms[currentStep].form}
      </form>
    </>
  );
};

export default WizardForms;
