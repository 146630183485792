import apiCall from "../../../helpers/apiCall";
import { getHeaders } from "../../../helpers/getHeaders";

import {
    ACTIVITIES_ACTIVITY_CRUD,
} from "../types";

export const createActivity = (
    payload,
    headers = getHeaders(
        payload,
        "/activity"
    ),
) => (dispatch) => {
    apiCall.post(
        "/activity",
        payload,
        { headers, }
    ).then((response) => {
        dispatch({
            type: ACTIVITIES_ACTIVITY_CRUD,
            payload: response.data
        });
    }).catch((err) => {
        dispatch({
            type: ACTIVITIES_ACTIVITY_CRUD,
            payload: err
        });
    })
}

export const retrieveActivity = (id) => (dispatch) => {
    apiCall.get(
        `/activity/${id}`,
        {
            headers: getHeaders(
                null,
                `/activity/${id}`
            ),
        }
    ).then((response) => {
        dispatch({
            type: ACTIVITIES_ACTIVITY_CRUD,
            payload: response.data
        });
    }).catch((err) => {
        dispatch({
            type: ACTIVITIES_ACTIVITY_CRUD,
            payload: err
        });
    });
}