/* eslint-disable no-use-before-define */
import moment from 'moment';
import { getAllActivitiesFromLocalStorage, getAllClubsFromLocalStorage } from './helperFunctions';

export const getResource = (notification) => {
  let resource;
  if (isNotificationClub(notification)) resource = getClub(notification);
  if (isNotificationCampaign(notification)) resource = getCampaign(notification);

  return resource;
};

export const getClub = (notification) => {
  const clubsData = getAllClubsFromLocalStorage();
  const notifClub = clubsData !== null
    && clubsData.filter(
      (club) => club.handle === notification.meta.handle,
    )[0];

  return notifClub;
};

export const getCampaign = (notification) => {
  const campaignsData = getAllActivitiesFromLocalStorage();
  const notifCampaign = campaignsData !== null
    && campaignsData.filter(
      (campaign) => campaign.handle === notification.meta.handle,
    )[0];

  return notifCampaign;
};

export const getResourceLink = (notification) => {
  let link;
  if (isNotificationClub(notification)) {
    link = `/club/${getClub(notification)?.id}/${getClub(notification)?.handle}`;
  }
  if (isNotificationCampaign(notification)) {
    link = `/campaign/${getCampaign(notification)?.id}/${getCampaign(notification)?.handle}`;
  }

  return link;
};

export const getViewLink = (notification) => {
  let link = getResourceLink(notification);
  if (isNotificationClub(notification) && getPostType(notification) !== 'comment' && notification.meta.postId) {
    link += `/post/${notification.meta.postId}`;
  } else if (isNotificationClub(notification) && getPostType(notification) === 'comment' && notification.meta.originalPostId) {
    link += `/post/${notification.meta.originalPostId}#commentId-${notification.meta.postId}`;
  } else {
    link += '/feed';
  }

  return link;
};

export const isNotificationOlderThan = (timestamp, timeRef) => moment(timestamp * 1000).add(timeRef.amount, timeRef.unit) < moment();

export const getNotificationTime = (timestamp) => (isNotificationOlderThan(timestamp, { amount: 7, unit: 'days' })
  ? moment(timestamp * 1000).format('hh:mm, DD/MM/YY')
  : moment(timestamp * 1000).fromNow());

export const isNotificationCampaign = (notification) => {
  const campaigns = ['campaign', 'contribution', 'activity'];
  return campaigns.some((campaign) => new RegExp(campaign).test(notification.type.toLowerCase()));
};

export const isNotificationClub = (notification) => {
  const clubs = ['club', 'post', 'response', 'article', 'comment', 'promo'];
  return clubs.some((club) => new RegExp(club).test(notification.type.toLowerCase()));
};

export const getPostType = (notification) => notification.type.replace('_NEW', '').toLowerCase();
