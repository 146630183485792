import sha1 from "js-sha1";
import { baseURL } from "../../../../helpers/constants";

export const authHeaders = {
    authToken: "X-AUTH-TOKEN",
    serviceProvider: "X-SERVICE-PROVIDER",
    username: "X-SERVICE-USER-NAME",
};

const defaultAuthHeader = {
    serviceProvider: process.env.REACT_APP_SERVICE_PROVIDER,
    username: "guest",
};

export const requestAuthHeaders = {
    serviceProvider: "X-SERVICE-PROVIDER",
    username: "X-SERVICE-USER-NAME",
};

export const getUserAuthHeaders = (endpoint, payload = null, apiHost = null) => {
    let userAuthHeaders = {};

    Object.entries(requestAuthHeaders).forEach(([key, header]) =>
        userAuthHeaders[header] = localStorage.getItem(`sessionUser.${key}`) || defaultAuthHeader[key]
    );

    let requestHashString = `${(apiHost || baseURL)}${endpoint}`;
    requestHashString += payload === null ? '' : JSON.stringify(payload);
    requestHashString += localStorage.getItem("sessionUser.authToken");

    return {
        ...userAuthHeaders,
        "X-REQUEST-HASH": sha1(requestHashString),
    };
}