/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import {
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { colors } from '../../../../helpers/constants';
import Switch from '../../../generics/Switch';

class BooleanRender extends Component {
  state = {
    isChecked: false,
    trueLabel: '',
    falseLabel: '',
    updated: false,
  };

  UNSAFE_componentWillMount() {
    const { booleanData, resource } = this.props;

    this.setState({
      trueLabel: resource?.options[0]?.trueLabel,
      falseLabel: resource?.options[0]?.falseLabel,
      isChecked: booleanData === 'true',
    });
  }

  componentDidUpdate = (prevState) => {
    const { updateValues } = this.props;
    const { isChecked, updated } = this.state;
    if (
      prevState.isChecked !== isChecked &&
      updated === true
    ) {
      updateValues(isChecked);
    }
  };

  handleIsChecked = () => {
    this.setState((prevState) => ({
      isChecked: !prevState.isChecked,
      updated: true,
    }));
  };

  render() {
    const { trueLabel, falseLabel, isChecked } = this.state;
    const { review } = this.props;

    return (
      <div className="boolean">
        <FormGroup
          style={{
            flexWrap: 'nowrap',
            flexDirection: 'row',
          }}>
          <span
            style={{
              color: colors.textSecondary,
              lineHeight: '2rem',
            }}>
            {falseLabel}
          </span>
          <FormControlLabel
            style={{
              margin: 0,
              padding: 0,
              width: 'unset',
            }}
            disabled={review}
            control={
              <Switch
                checked={isChecked}
                onChange={this.handleIsChecked}
              />
            }
          />
          <span
            style={{
              color: colors.textSecondary,
              lineHeight: '2rem',
            }}>
            {trueLabel}
          </span>
        </FormGroup>
      </div>
    );
  }
}

export default BooleanRender;
