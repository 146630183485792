/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Clear } from "@material-ui/icons";
import { toast } from "react-toastify";
import Navbar from "../../Navbar";
import SideNav from "../SideNav/SideNav";
import "./settings.css";
import ResourceTable from "../../generics/ResourceTable";
import Promotions from "../../generics/Promotions/Promotions";
import { getLabelByName } from "../../../helpers/helperFunctions";
import {
  CHANGES_SAVED,
  CREATE_NEW,
  POST_IS_DELETED_SUCCESSFULLY,
  SAVE,
  SAVING,
  SEARCH,
  SETTINGS,
  webContentCategory,
} from "../../../helpers/constants";
import { getSessionAction } from "../../../redux/actions/authActions";
import { getOutputs } from "../../../redux/actions/contributionsActions";
import {
  getAdminActivities,
  getQuestionnaires,
  searchAdminActivitiesByName,
} from "../../../redux/actions/campaignsActions";
import { getAdminClubs } from "../../../redux/actions/institutesActions";
import {
  getUsers,
  requestPasswordReset,
} from "../../../redux/actions/usersActions";
import {
  deletePost,
  getNewsFeed,
} from "../../../redux/actions/postActions";
import {
  adminTableMapping,
  renderNewEntityModal,
} from "../../../helpers/admin";
import SettingsMobile from "./SettingsMobile";
import PaginationRow from "../../generics/PaginationRow";
import searchOrange from "../../../assets/icons/search-orange.svg";
import WebContentNav from "../../generics/WebContent/WebContentNav/WebContentNav";
import WebContentSettings from "../../generics/WebContent/WebContentSettings/WebContentSettings";
import { getLanding } from "../../../redux/actions/landingActions";
import { postContent } from "../../../redux/actions/adminActions";
import DeleteModal from "./DeleteModal";
import PasswordResetModal from "./PasswordResetModal";
import CreateUserDropdown from "./CreateUserDropdown";

export class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // profileData: getProfileDataFromLocalStorage(),
      data: [],
      filteredData: [],
      nbPages: 1,
      currentPage: 1,
      pageDivider: 10,
      editEntity: null,
      cloneEntity: null,
      isCloneModalOpen: false,
      isPasswordResetModalOpen: false,
      show: false,
      searchValue: "",
      dropDownValue: "all",
      pathname: this.props.location.pathname,
      section: null,
      imagePreview: {},
      enableSubmission: false,
      logo: null,
      displayInstagram: false,
      displayLinkedIn: false,
      displayEmail: false,
      displayFacebook: false,
      displayTwitter: false,
      displayHome: false,
      displayPrivacyPolicy: false,
      displaySupport: false,
      displayTermsConditions: false,
      displaySubscription: false,
      displayServices: false,
      displayAbout: false,
      triggerCheckbox: false,
      saveLoading: false,
      "background-image": null,
      enableUploadButton: false,
      showDeleteModal: false,
      isButtonLoading: false,
      labelsObject: {
        "english-labels": {},
        "french-labels": {},
      },
    };
  }

  async UNSAFE_componentWillMount() {
    await this.load();

    const { getLanding: getLandingAction } = this.props;

    await getLandingAction();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { landingData } = nextProps;

    let landingInfo = null;
    let socialInfo = null;
    let enInfo = null;
    let frInfo = null;
    if (landingData && landingData.data) {
      landingInfo = landingData.data.filter(
        (x) => x.name === "landing-page",
      )[0];
    }

    if (landingData && landingData.data) {
      socialInfo = landingData.data.filter(
        (x) => x.name === "links",
      )[0];
    }

    if (landingData && landingData.data) {
      enInfo = landingData.data.filter(
        (x) => x.name === "english-labels",
      )[0];
    }

    if (landingData && landingData.data) {
      frInfo = landingData.data.filter(
        (x) => x.name === "french-labels",
      )[0];
    }

    this.setState({
      labelsObject: {
        "english-labels": enInfo || {},
        "french-labels": frInfo || {},
      },
    });

    const {
      displayFacebook,
      displayEmail,
      displayTwitter,
      displayLinkedIn,
      displayInstagram,
      displayHome,
      displayPrivacyPolicy,
      displaySubscription,
      displaySupport,
      displayTermsConditions,
      triggerCheckbox,
      displayServices,
      displayAbout,
      logo,
    } = this.state;

    if (landingInfo?.data?.logo !== logo) {
      this.setState({
        logo: landingInfo?.data.logo,
      });
    }

    if (
      landingInfo?.data["background-image"] !==
      this.state[["background-image"]]
    ) {
      this.setState({
        "background-image":
          landingInfo?.data["background-image"],
      });
    }

    if (
      landingInfo?.data["services-image"] !==
      this.state[["services-image"]]
    ) {
      this.setState({
        "services-image":
          landingInfo?.data["services-image"],
      });
    }

    if (
      landingInfo?.data?.displayAbout !== displayAbout &&
      triggerCheckbox === false
    ) {
      this.setState({
        displayAbout: landingInfo?.data.displayAbout,
      });
    }

    if (
      landingInfo?.data?.displayServices !==
        displayServices &&
      triggerCheckbox === false
    ) {
      this.setState({
        displayServices: landingInfo?.data.displayServices,
      });
    }
    if (
      landingInfo?.data?.displayHome !== displayHome &&
      triggerCheckbox === false
    ) {
      this.setState({
        displayHome: landingInfo?.data.displayHome,
      });
    }

    if (
      landingInfo?.data?.displayPrivacyPolicy !==
      displayPrivacyPolicy
    ) {
      this.setState({
        displayPrivacyPolicy:
          landingInfo?.data.displayPrivacyPolicy,
      });
    }

    if (
      landingInfo?.data?.displayTermsConditions !==
      displayTermsConditions
    ) {
      this.setState({
        displayTermsConditions:
          landingInfo?.data.displayTermsConditions,
      });
    }

    if (
      landingInfo?.data?.displaySubscription !==
      displaySubscription
    ) {
      this.setState({
        displaySubscription:
          landingInfo?.data.displaySubscription,
      });
    }

    if (
      landingInfo?.data?.displaySupport !== displaySupport
    ) {
      this.setState({
        displaySupport: landingInfo?.data.displaySupport,
      });
    }

    if (
      socialInfo?.data?.displayInstagram !==
      displayInstagram
    ) {
      this.setState({
        displayInstagram: socialInfo?.data.displayInstagram,
      });
    }
    if (
      socialInfo?.data?.displayLinkedIn !== displayLinkedIn
    ) {
      this.setState({
        displayLinkedIn: socialInfo?.data.displayLinkedIn,
      });
    }
    if (
      socialInfo?.data?.displayFacebook !== displayFacebook
    ) {
      this.setState({
        displayFacebook: socialInfo?.data.displayFacebook,
      });
    }
    if (socialInfo?.data?.displayEmail !== displayEmail) {
      this.setState({
        displayEmail: socialInfo?.data.displayEmail,
      });
    }
    if (
      socialInfo?.data?.displayTwitter !== displayTwitter
    ) {
      this.setState({
        displayTwitter: socialInfo?.data.displayTwitter,
      });
    }

    if (
      this.props.match.params.entity !==
      nextProps.match.params.entity
    ) {
      await this.load();
    }

    let data = [];

    if (
      this.props.match.params.entity ===
      nextProps.match.params.entity
    ) {
      switch (nextProps.match.params.entity) {
        case "activities":
          data = nextProps.getActivitiesData?.data;
          break;

        case "clubs":
          data = nextProps.institutesData?.data;
          break;

        case "users":
          data = nextProps.getUsersData?.data;
          break;

        case "questionnaires":
          data = nextProps.getQuestionnairesData?.data;
          break;

        case "posts":
          data = nextProps.getNewsFeedData?.data;
          break;

        default:
          break;
      }

      this.setState({
        data: data || [],
        filteredData: data || [],
        nbPages: Math.ceil(
          data?.length / this.state.pageDivider,
        ),
      });
    }

    if (
      nextProps.deletePostStatus === "success" &&
      nextProps.deletePostStatus !==
        this.props.deletePostStatus
    ) {
      this.setState({
        showDeleteModal: false,
        isButtonLoading: false,
      });
      await this.props.getNewsFeed();

      toast.success(
        getLabelByName(POST_IS_DELETED_SUCCESSFULLY),
      );
    }
  }

  async componentDidUpdate(nextProps) {
    const { getLanding: getLandingAction, adminData } =
      nextProps;

    const { enableSubmission } = this.state;

    if (
      enableSubmission &&
      adminData?.data !== this.props.adminData?.data
    ) {
      toast.success(getLabelByName(CHANGES_SAVED));
      await getLandingAction();
      this.setImageClickedToFalse();
    }
  }

  setImageClickedToFalse = () => {
    this.setState({
      enableSubmission: false,
      logo: null,
      saveLoading: false,
    });
  };

  load = async () => {
    const { props } = this;

    await props.getSessionAction();
    this.setState({ isLoading: true });
    await this.fetchData();
    this.setState({ isLoading: false });
  };

  fetchData = async () => {
    const { entity } = this.props.match.params;

    await this.props.getAdminClubs();

    switch (entity) {
      case "activities":
        await this.props.getAdminActivities();
        break;

      case "clubs":
        await this.props.getAdminClubs();
        break;

      case "users":
        await this.props.getUsers();
        break;

      case "questionnaires":
        await this.props.getQuestionnaires();
        break;

      case "posts":
        await this.props.getNewsFeed();
        break;

      default:
        break;
    }
  };

  timestampToDate = (timestamp, format = "DD MMM YYYY") => {
    if (!timestamp) {
      return null;
    }
    return moment(timestamp * 1000).format(format);
  };

  setCurrentPage = (currentPage) =>
    this.setState({ currentPage });

  incrementPage = () => {
    let { nbPages, currentPage } = this.state;
    currentPage =
      currentPage === nbPages
        ? currentPage
        : currentPage + 1;
    this.setState({ currentPage });
  };

  decrementPage = () => {
    let { currentPage } = this.state;
    currentPage =
      currentPage === 1 ? currentPage : currentPage - 1;
    this.setState({ currentPage });
  };

  setEditEntity = (entityData) => {
    this.setState({ editEntity: entityData });
  };

  toggleCloneEntity = (entityData) => {
    const { isCloneModalOpen } = this.state;

    this.setState({
      isCloneModalOpen: !isCloneModalOpen,
      cloneEntity: entityData,
    });
  };

  setShow = () => this.setState({ show: true });

  setHide = () =>
    this.setState({
      show: false,
      editEntity: null,
      isCloneModalOpen: false,
      CloseEntity: null,
    });

  searchComponent = () => {
    const { entity } = this.props.match.params;
    return (
      <form className="form" onSubmit={this.searchEntities}>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            id="inputSearch"
            placeholder={`${getLabelByName(
              SEARCH,
            )} ${getLabelByName(
              entity ? entity : "resource",
            )?.toLowerCase()}`}
            name="searchValue"
            value={this.state.searchValue}
            onChange={this.handleChange}
            style={{ height: "32px" }}
          />
          {this.state.searchValue && (
            <div
              className="input-group-append"
              style={{ height: "32px" }}>
              <button
                id="clear-search-btn"
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => this.clearSearch()}>
                <Clear style={{ fontSize: "16px" }} />
              </button>
            </div>
          )}
          <div
            className="input-group-append"
            style={{ height: "32px" }}>
            <button
              // disabled={!this.state.searchValue}
              id="search-btn"
              className="btn btn-outline-secondary"
              type="submit"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <img
                src={searchOrange}
                alt="search-icon"
                style={{ width: "12px" }}
              />
            </button>
          </div>
        </div>
      </form>
    );
  };

  handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (value && !checked) {
      this.setState({
        [name]: value,
        enableSubmission: true,
      });
    } else {
      this.setState({
        [name]: null,
      });
    }
    if (checked) {
      this.setState({
        [name]: checked,
        enableSubmission: true,
        triggerCheckbox: true,
      });
    }
    if (checked === false) {
      this.setState({
        triggerCheckbox: true,
        enableSubmission: true,
      });
    }
  };

  newHandleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  searchEntities = (e) => {
    e.preventDefault();
    const { data } = this.state;

    if (!this.state.searchValue) {
      return this.setState({
        filteredData: this.state.data,
      });
    }

    const filteredData = data.filter((d) => {
      const entityToFilter = this.props.match.params.entity;
      const searchValue =
        this.state.searchValue.toLowerCase();

      if (entityToFilter === "users") {
        return (
          d.profile.firstName
            .toLowerCase()
            .includes(searchValue) ||
          d.profile.lastName
            .toLowerCase()
            .includes(searchValue) ||
          d.username.toLowerCase().includes(searchValue)
        );
      }
      if (entityToFilter === "posts") {
        return d.content.body
          .toLowerCase()
          .includes(searchValue);
      }
      // other entities are activities, clubs, promotions, questionnaires
      return d.name.toLowerCase().includes(searchValue);

      // return Object.keys(d).some((key) =>
      //   String(d[key])
      //     .toLowerCase()
      //     .includes(this.state.searchValue.toLowerCase()),
      // );
    });

    const { pageDivider } = this.state;

    this.setState({
      filteredData,
      currentPage: 1,
      nbPages: Math.ceil(
        filteredData?.length / pageDivider,
      ),
    });
  };

  handleDropdownChange = (e) => {
    const value = e.target.value;

    this.setState({ dropDownValue: value });
    const outputsData = [...this.state.filteredData];

    if (value === "promoted") {
      const promotedOutputs = outputsData.filter(
        (output) => output.meta.promoted,
      );

      this.setState({ filteredData: promotedOutputs });
    } else {
      this.setState({ filteredData: this.state.data });
    }
  };

  clearSearch = () => {
    const { data, pageDivider } = this.state;

    this.setState({
      filteredData: data,
      searchValue: "",
      currentPage: 1,
      nbPages: Math.ceil(data?.length / pageDivider),
    });
  };

  returnFetchMethod = () => ({
    clubs: this.props.getAdminClubs,
    activities: this.props.getAdminActivities,
    users: this.props.getUsers,

    questionnaires: this.props.getQuestionnaires,
    posts: this.props.getNewsFeed,
  });

  fileChange = (e, prop) => {
    this.setState({ [prop]: e.target.files[0] });

    const fileReader = new FileReader();

    const { imagePreview } = this.state;

    fileReader.onloadend = () => {
      this.setState({
        imagePreview: {
          ...imagePreview,
          [prop]: fileReader.result,
        },
        enableSubmission: true,
        enableUploadButton: true,
      });
    };
    if (e.target.files[0]) {
      fileReader.readAsDataURL(e.target.files[0]);
    }
  };

  submitContent = async (e, valueObject) => {
    const { props } = this;
    e.preventDefault();
    const { id } = valueObject;
    const { name } = valueObject;

    const { state } = this;

    const payload = {
      id,
      name,
      data: {
        "welcome-header":
          state["welcome-header"] ||
          valueObject.data["welcome-header"],
        "welcome-text":
          state["welcome-text"] ||
          valueObject.data["welcome-text"],
        "site-slogan":
          state["site-slogan"] ||
          valueObject.data["site-slogan"],
        "about-header":
          state["about-header"] ||
          valueObject.data["about-header"],
        "about-text":
          state["about-text"] ||
          valueObject.data["about-text"],
        "services-header":
          state["services-header"] ||
          valueObject.data["services-header"],
        "services-text":
          state["services-text"] ||
          valueObject.data["services-text"],
        "copyright-text":
          state["copyright-text"] ||
          valueObject.data["copyright-text"],
        "login-text":
          state["login-text"] ||
          valueObject.data["login-text"],
        "signup-text":
          state["signup-text"] ||
          valueObject.data["signup-text"],
        "footer-text":
          state["footer-text"] ||
          valueObject.data["footer-text"],
        "footer-link":
          state["footer-link"] ||
          valueObject.data["footer-link"],
        "footer-button":
          state["footer-button"] ||
          valueObject.data["footer-button"],
        logo: state.logo || valueObject.data.logo,
        "background-image":
          state["background-image"] ||
          valueObject.data["background-image"],
        "about-image":
          state["about-image"] ||
          valueObject.data["about-image"],
        "services-image":
          state["services-image"] ||
          valueObject.data["services-image"],
        displayHome: state.displayHome || false,
        displayPrivacyPolicy:
          state.displayPrivacyPolicy || false,
        displaySubscription:
          state.displaySubscription || false,
        displaySupport: state.displaySupport || false,
        displayTermsConditions:
          state.displayTermsConditions || false,
        displayAbout: state.displayAbout || false,
        displayServices: state.displayServices || false,
      },
    };

    await props.postContent(payload, id);

    this.setState({
      enableSubmission: true,
      saveLoading: true,
    });
  };

  submitSocialLinks = async (e, socialInfo) => {
    e.preventDefault();
    const { props, state } = this;
    const id = socialInfo && socialInfo.id;
    const name = socialInfo && socialInfo.name;
    const payload = {
      id,
      name,
      data: {
        email:
          this.state.email ||
          (socialInfo ? socialInfo.data.email : ""),
        instagram:
          this.state.instagram ||
          (socialInfo ? socialInfo.data.instagram : ""),
        displayLinkedin:
          this.state.displayLinkedin ||
          socialInfo?.data?.displayLinkedin,
        linkedin:
          this.state.linkedin ||
          (socialInfo ? socialInfo.data.linkedin : ""),
        facebook:
          this.state.facebook ||
          (socialInfo ? socialInfo.data.facebook : ""),
        twitter:
          this.state.twitter ||
          (socialInfo ? socialInfo.data.twitter : ""),
        displayInstagram: state.displayInstagram || false,
        displayLinkedIn: state.displayLinkedIn || false,
        displayFacebook: state.displayFacebook || false,
        displayTwitter: state.displayTwitter || false,
        displayEmail: state.displayEmail || false,
      },
    };
    await props.postContent(payload, id);
    this.setState({
      saveLoading: true,
    });
  };

  submitPrivacyPolicy = async (e, valueObject) => {
    e.preventDefault();
    const { props } = this;

    const { id } = valueObject;

    const { name } = valueObject;

    const { state } = this;

    const payload = {
      id,
      name,
      data: {
        main: state.main || valueObject.data.main,
        list: state.list || valueObject.data.list,
      },
    };

    await props.postContent(payload, id);

    this.setState({
      enableSubmission: true,
      saveLoading: true,
    });
  };

  submitTermsConditions = async (e, valueObject) => {
    e.preventDefault();
    const { props } = this;

    const { id } = valueObject;

    const { name } = valueObject;

    const { state } = this;

    const payload = {
      id,
      name,
      data: {
        main: state.main || valueObject.data.main,
        list: state.list || valueObject.data.list,
      },
    };

    await props.postContent(payload, id);

    this.setState({
      enableSubmission: true,
      saveLoading: true,
    });
  };

  submitLabels = async (e, valueObject) => {
    e.preventDefault();
    const { props } = this;

    const { id, name, data } = valueObject;

    const payload = {
      id,
      name,
      data,
    };

    await props.postContent(payload, id);

    this.setState({
      enableSubmission: true,
      saveLoading: true,
    });
  };

  submitImage = (e, landingInfo, prop) => {
    const { props, state } = this;

    const { id } = landingInfo;

    const { name } = landingInfo;

    const formData = new FormData();

    formData.append("file", state[prop]);

    formData.append("upload_preset", "qtt2g8ao");

    const options = {
      method: "POST",
      body: formData,
    };

    fetch(
      "https://api.Cloudinary.com/v1_1/ayudigital/image/upload",
      options,
    )
      .then((res) => res.json())
      .then(async (res) => {
        const { secure_url } = res;
        const payload = {
          id,
          name,
          data: {
            ...landingInfo.data,
            [prop]: secure_url,
          },
        };
        await props.postContent(payload, id);
        this.setState({
          enableSubmission: true,
          Image: state[prop],
          saveLoading: true,
        });
      })
      .catch((err) =>
        toast.error(
          "Error uploading the picture!\nPlease try again.",
        ),
      );
  };

  handleDelete = async () => {
    const { clubId, postId, postType } = this.state;
    await this.props.deletePost(clubId, postId, postType);
  };

  setShowDeleteModalToTrue = (clubId, postId, postType) => {
    this.setState({
      showDeleteModal: true,
      clubId,
      postId,
      postType,
    });
  };

  handleSendPasswordResetEmail = async () => {
    const { username } = this.state;
    const response = await this.props.requestPasswordReset({
      username,
    });

    if (response.status === 200) {
      toast.success(response.message);
    }

    this.setState({
      isPasswordResetModalOpen: false,
      isButtonLoading: false,
      sername: "",
    });
  };

  togglePasswordResetMail = ({ username }) => {
    this.setState({
      isPasswordResetModalOpen: true,
      username,
    });
  };

  handleLabelsChange = (
    lang,
    label,
    value,
    index,
    inputType,
  ) => {
    const { labelsObject } = this.state;
    const labelUpdated = { [label]: value };
    let newData = {};

    if (inputType === "label") {
      const listLabels = Object.keys(
        labelsObject[lang].data,
      );

      const oldLabel = listLabels[index];

      const newListLabels = listLabels.filter(
        (label, labelIndex) => labelIndex !== index,
      );
      newListLabels.splice(index, 0, label);

      newListLabels.map((labelItem) => {
        newData[labelItem] =
          labelsObject[lang].data[labelItem];
      });

      newData[label] = labelsObject[lang].data[oldLabel];
    }

    if (inputType === "value") {
      newData = {
        ...labelsObject[lang].data,
        ...labelUpdated,
      };
    }

    const newLabels = {
      ...labelsObject,
      [lang]: {
        ...labelsObject[lang],
        data: newData,
      },
    };

    this.setState({
      labelsObject: newLabels,
      enableSubmission: true,
    });
  };

  handleSaveWebContent = (e) => {
    const { category } = this.props.match.params;
    const { labelsObject } = this.state;
    const { landingData } = this.props;

    let landingInfo = null;
    let socialInfo = null;
    if (landingData && landingData.data) {
      landingInfo = landingData.data.filter(
        (x) => x.name === "landing-page",
      )[0];
    }

    if (landingData && landingData.data) {
      socialInfo = landingData.data.filter(
        (x) => x.name === "links",
      )[0];
    }

    let policyInfo = null;
    if (landingData?.data) {
      policyInfo = landingData.data.filter(
        (x) => x.name === "privacy",
      )[0];
    }

    let termsInfo = null;
    if (landingData?.data) {
      termsInfo = landingData.data.filter(
        (x) => x.name === "terms",
      )[0];
    }

    if (category === webContentCategory.landingPage) {
      this.submitContent(e, landingInfo);
    }
    if (category === webContentCategory.footer) {
      this.submitContent(e, landingInfo);
      this.submitSocialLinks(e, socialInfo);
    }

    if (category === webContentCategory.socialMedia) {
      this.submitSocialLinks(e, socialInfo);
    }
    if (category === webContentCategory.privacyPolicy) {
      this.submitPrivacyPolicy(e, policyInfo);
    }

    if (category === webContentCategory.termsConditions) {
      this.submitTermsConditions(e, termsInfo);
    }

    if (category === webContentCategory.en_labels) {
      this.submitLabels(e, labelsObject["english-labels"]);
    }

    if (category === webContentCategory.fr_labels) {
      this.submitLabels(e, labelsObject["french-labels"]);
    }
  };

  toggleModal = () => {
    this.setState({
      editEntity: null,
      show: true,
    });
  };

  addNewLabel = (lang) => {
    const { labelsObject } = this.state;
    const listLabels = Object.keys(labelsObject[lang].data);
    const newData = {};
    listLabels.unshift("");

    listLabels.map((labelItem) => {
      newData[labelItem] =
        labelsObject[lang].data[labelItem] || "";
    });

    const newLabels = {
      ...labelsObject,
      [lang]: {
        ...labelsObject[lang],
        data: newData,
      },
    };

    this.setState({
      labelsObject: newLabels,
    });
  };

  removeLabel = (lang, index) => {
    const { labelsObject } = this.state;
    const listLabels = Object.keys(labelsObject[lang].data);
    const newData = {};
    listLabels.splice(index, 1);

    listLabels.map((labelItem) => {
      newData[labelItem] =
        labelsObject[lang].data[labelItem] || "";
    });

    const newLabels = {
      ...labelsObject,
      [lang]: {
        ...labelsObject[lang],
        data: newData,
      },
    };

    this.setState({
      labelsObject: newLabels,
      enableSubmission: true,
    });
  };

  getSideNavEntityProps = () => {
    if (this.props.match.path === "/manage/web/:category") {
      return "web content";
    }

    if (
      this.props.match.path ===
      "/manage/promotions/:category"
    ) {
      return "promotions";
    }

    return this.props.match.params.entity;
  };

  displayMainContent = () => {
    const { entity, category } = this.props.match.params;

    const {
      currentPage,
      pageDivider,
      nbPages,
      editEntity,
      cloneEntity,
      show,
      isCloneModalOpen,
      filteredData,
      section,
      imagePreview,
      triggerCheckbox,
      saveLoading,
      labelsObject,
    } = this.state;

    if (entity)
      document.title = `${
        process.env.REACT_APP_APPLICATION_NAME ||
        "The Alma Maters Club"
      } - ${getLabelByName(SETTINGS)} - ${
        entity[0].toUpperCase() + entity.slice(1)
      }`;

    const { landingData } = this.props;

    let landingInfo = null;
    let socialInfo = null;
    if (landingData && landingData.data) {
      landingInfo = landingData.data.filter(
        (x) => x.name === "landing-page",
      )[0];
    }

    if (landingData && landingData.data) {
      socialInfo = landingData.data.filter(
        (x) => x.name === "links",
      )[0];
    }

    let policyInfo = null;
    if (landingData?.data) {
      policyInfo = landingData.data.filter(
        (x) => x.name === "privacy",
      )[0];
    }

    let termsInfo = null;
    if (landingData?.data) {
      termsInfo = landingData.data.filter(
        (x) => x.name === "terms",
      )[0];
    }

    if (this.props.match.path === "/manage/web/:category") {
      return (
        <div className="manage-web-content">
          <div className="web-content-header">
            <div className="manage-web-content-title">
              <h1>Manage Site Content</h1>
            </div>
            <button
              type="submit"
              disabled={
                saveLoading === true ||
                this.state.enableSubmission === false
              }
              onClick={this.handleSaveWebContent}>
              {this.state.saveLoading
                ? `${getLabelByName(SAVING)}...`
                : getLabelByName(SAVE)}
            </button>
          </div>

          <div className="web-content-nav">
            <WebContentNav
              enableSubmission={this.state.enableSubmission}
            />
            <form action="">
              <WebContentSettings
                category={category}
                submitContent={this.submitContent}
                landingInfo={landingInfo}
                socialInfo={socialInfo}
                policyInfo={policyInfo}
                termsInfo={termsInfo}
                labelsObject={labelsObject}
                handleLabelsChange={this.handleLabelsChange}
                addNewLabel={this.addNewLabel}
                removeLabel={this.removeLabel}
                setSection={(sec) =>
                  this.setState({ section: sec })
                }
                section={section}
                submitImage={this.submitImage}
                fileChange={this.fileChange}
                imagePreview={imagePreview}
                triggerCheckbox={triggerCheckbox}
                // handleChange={(e) => console.log('working hiha 001...', e)}
                handleChange={(e) => this.handleChange(e)}
                newHandleChange={(val) =>
                  this.newHandleChange(val)
                }
                stateValues={{ ...this.state }}
              />
            </form>
          </div>
        </div>
      );
    }

    if (
      this.props.match.path ===
      "/manage/promotions/:category"
    ) {
      return (
        <Promotions
          getOutputsData={{
            data: filteredData.slice(
              (currentPage - 1) * pageDivider,
              currentPage * pageDivider,
            ),
          }}
          instituteData={this.props.instituteData?.data}
          activitiesData={
            this.props.getActivitiesData?.data
          }
          posts={this.props.getNewsFeedData?.data}
          searchOutput={this.searchComponent()}
          dropDownValue={this.state.dropDownValue}
          handleDropdownChange={this.handleDropdownChange}
        />
      );
    }

    return (
      <div className="settings-content">
        <div className="settings-content-title d-flex justify-content-between">
          <div>
            {entity[0].toUpperCase() + entity.slice(1)}
          </div>

          {entity !== "promotions" && (
            <div>
              <div className="join-button">
                {entity !== "users" ? (
                  <button
                    type="submit"
                    className="btn btn-lg submit-btn"
                    onClick={() => {
                      if (entity === "questionnaires") {
                        this.props.history.push(
                          "/manage/questionnaires/new",
                        );
                      }
                      if (entity === "posts") {
                        this.props.history.push(
                          "/create-article",
                        );
                      }
                      this.setState({
                        editEntity: null,
                        show: true,
                      });
                    }}>
                    {getLabelByName(CREATE_NEW)}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-lg submit-btn"
                    onClick={() => {}}
                    id="createUserDropdown"
                    data-toggle="dropdown"
                    aria-expanded="false">
                    {getLabelByName(CREATE_NEW)}
                  </button>
                )}

                <CreateUserDropdown
                  toggleModal={this.toggleModal}
                />
              </div>
            </div>
          )}
        </div>

        <div className="settings-content-table">
          <ResourceTable
            headers={
              adminTableMapping(filteredData)[entity]
                .tableHeader
            }
            tableData={adminTableMapping(
              filteredData,
              currentPage,
              pageDivider,
              this.setEditEntity,
              this.setShow,
              this.handleDelete,
              this.setShowDeleteModalToTrue,
              this.toggleCloneEntity,
              this.props?.institutesData?.data,
              this.togglePasswordResetMail,
            )[entity].getTableData()}
            tableActions={{
              title: `${filteredData.length} ${
                entity[0].toUpperCase() + entity.slice(1)
              }`,
              actions: [this.searchComponent()],
            }}
          />

          {filteredData && (
            <PaginationRow
              incrementPage={this.incrementPage}
              decrementPage={this.decrementPage}
              nbPages={nbPages}
              currentPage={currentPage}
              dataLength={filteredData.length}
              pageDivider={pageDivider}
              setCurrentPage={this.setCurrentPage}
            />
          )}
        </div>

        {renderNewEntityModal(
          editEntity,
          show,
          this.setHide,
          this.returnFetchMethod()[entity],
          isCloneModalOpen,
          cloneEntity,
        )[entity]()}
      </div>
    );
  };

  displayMobileContent = () => {
    const { entity, category } = this.props.match.params;

    const {
      data,
      currentPage,
      pageDivider,
      nbPages,

      section,
      imagePreview,
      triggerCheckbox,
      saveLoading,
      labelsObject,
    } = this.state;

    if (entity)
      document.title = `${
        process.env.REACT_APP_APPLICATION_NAME ||
        "The Alma Maters Club"
      } - ${getLabelByName(SETTINGS)} - ${
        entity[0].toUpperCase() + entity.slice(1)
      }`;

    const { landingData } = this.props;

    let landingInfo = null;
    let socialInfo = null;

    if (landingData && landingData.data) {
      landingInfo = landingData.data.filter(
        (x) => x.name === "landing-page",
      )[0];
    }

    if (landingData && landingData.data) {
      socialInfo = landingData.data.filter(
        (x) => x.name === "links",
      )[0];
    }

    let policyInfo = null;
    if (landingData?.data) {
      policyInfo = landingData.data.filter(
        (x) => x.name === "privacy",
      )[0];
    }

    let termsInfo = null;
    if (landingData?.data) {
      termsInfo = landingData.data.filter(
        (x) => x.name === "terms",
      )[0];
    }

    if (
      this.props.match.path ===
      "/manage/promotions/:category"
    ) {
      const { filteredData } = this.state;
      const entityText = "promotions";

      return (
        <div className="promotions-mobile-content">
          <SettingsMobile
            entity={entityText}
            data={data}
            setShow={this.setShow}
            toggleCloneEntity={this.toggleCloneEntity}
            setEditEntity={this.setEditEntity}
            history={this.props.history}
            currentPage={currentPage}
            pageDivider={pageDivider}
          />

          <Promotions
            getOutputsData={{
              data: filteredData.slice(
                (currentPage - 1) * pageDivider,
                currentPage * pageDivider,
              ),
            }}
            instituteData={this.props.instituteData?.data}
            activitiesData={
              this.props.getActivitiesData?.data
            }
            posts={this.props.getNewsFeedData?.data}
            searchOutput={this.searchComponent()}
            dropDownValue={this.state.dropDownValue}
            handleDropdownChange={this.handleDropdownChange}
          />
        </div>
      );
    }

    if (this.props.match.path === "/manage/web/:category") {
      const entityText = "web";

      return (
        <>
          <SettingsMobile
            entity={entityText}
            data={data}
            setShow={this.setShow}
            toggleCloneEntity={this.toggleCloneEntity}
            setEditEntity={this.setEditEntity}
            history={this.props.history}
            currentPage={currentPage}
            pageDivider={pageDivider}
          />
          <div className="web-content-nav-responsive">
            <WebContentNav />
            <div className="web-content-header">
              <button
                type="submit"
                disabled={saveLoading === true}
                onClick={this.handleSaveWebContent}>
                {this.state.saveLoading
                  ? `${getLabelByName(SAVING)}...`
                  : getLabelByName(SAVE)}
              </button>
            </div>
            <form action="">
              <WebContentSettings
                category={category}
                landingInfo={landingInfo}
                socialInfo={socialInfo}
                policyInfo={policyInfo}
                termsInfo={termsInfo}
                labelsObject={labelsObject}
                handleLabelsChange={this.handleLabelsChange}
                addNewLabel={this.addNewLabel}
                removeLabel={this.removeLabel}
                setSection={(sec) =>
                  this.setState({ section: sec })
                }
                section={section}
                fileChange={this.fileChange}
                imagePreview={imagePreview}
                triggerCheckbox={triggerCheckbox}
                handleChange={(e) => this.handleChange(e)}
                newHandleChange={(val) =>
                  console.log(
                    "working new hiha 001...",
                    val,
                  )
                }
                // newHandleChange={(val) => this.newHandleChange(val)}
                submitImage={this.submitImage}
                stateValues={{ ...this.state }}
              />
            </form>
          </div>
        </>
      );
    }

    if (this.props.match.path === "/manage/:entity") {
      return (
        <>
          <SettingsMobile
            entity={entity}
            data={data}
            setShow={this.setShow}
            setEditEntity={this.setEditEntity}
            toggleCloneEntity={this.toggleCloneEntity}
            history={this.props.history}
            currentPage={currentPage}
            pageDivider={pageDivider}
          />
          <div className="mobile-pagination">
            {data && (
              <PaginationRow
                incrementPage={this.incrementPage}
                decrementPage={this.decrementPage}
                nbPages={nbPages}
                currentPage={currentPage}
                dataLength={data.length}
                pageDivider={pageDivider}
                setCurrentPage={this.setCurrentPage}
              />
            )}
          </div>
        </>
      );
    }
  };

  render() {
    const { entity } = this.props.match.params;

    const { isPasswordResetModalOpen } = this.state;

    if (entity)
      document.title = `${
        process.env.REACT_APP_APPLICATION_NAME ||
        "The Alma Maters Club"
      } - ${getLabelByName(SETTINGS)} - ${
        entity[0].toUpperCase() + entity.slice(1)
      }`;

    return (
      <>
        {this.state.showDeleteModal && (
          <DeleteModal
            showDeleteModal={this.state.showDeleteModal}
            closeModal={() =>
              this.setState({
                showDeleteModal: false,
              })
            }
            setButtonLoading={() =>
              this.setState({
                isButtonLoading: true,
              })
            }
            handleDelete={this.handleDelete}
            isButtonLoading={this.state.isButtonLoading}
          />
        )}

        {isPasswordResetModalOpen && (
          <PasswordResetModal
            isOpen={isPasswordResetModalOpen}
            closeModal={() =>
              this.setState({
                isPasswordResetModalOpen: false,
              })
            }
            setButtonLoading={() =>
              this.setState({
                isButtonLoading: true,
              })
            }
            handleSendPasswordResetEmail={
              this.handleSendPasswordResetEmail
            }
            isButtonLoading={this.state.isButtonLoading}
          />
        )}

        <Navbar pathname="manage" />

        <div className="settings-container row">
          <div className="settings-sidenav">
            <SideNav
              entity={this.getSideNavEntityProps()}
            />
          </div>
          {this.displayMainContent()}
        </div>

        <>{this.displayMobileContent()}</>
      </>
    );
  }
}

export const mapStateToProps = (state) => ({
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,

  getActivitiesDataError:
    state.getActivitiesReducer.getActivitiesDataError,
  getActivitiesData:
    state.getActivitiesReducer.getActivitiesData,
  getActivitiesStatus:
    state.getActivitiesReducer.getActivitiesStatus,

  institutesDataError:
    state.institutesReducer.institutesDataError,
  institutesData: state.institutesReducer.institutesData,
  institutesStatus:
    state.institutesReducer.institutesStatus,

  getUsersData: state.getUsersReducer.getUsersData,
  getUsersError: state.getUsersReducer.getUsersDataError,
  getUsersSatus: state.getUsersReducer.getUsersStatus,

  getQuestionnairesData:
    state.getQuestionnairesReducer.getQuestionnairesData,
  getQuestionnairesError:
    state.getQuestionnairesReducer
      .getQuestionnairesDataError,
  getQuestionnairesSatus:
    state.getQuestionnairesReducer.getQuestionnairesStatus,

  getOutputsData: state.getOutputsReducer.getOutputsData,
  getOutputsDataError:
    state.getOutputsReducer.getOutputsDataError,
  getOutputsStatus:
    state.getOutputsReducer.getOutputsStatus,

  getNewsFeedDataError:
    state.getNewsFeedReducer.getNewsFeedDataError,
  getNewsFeedData: state.getNewsFeedReducer.getNewsFeedData,
  getNewsFeedStatus:
    state.getNewsFeedReducer.getNewsFeedStatus,

  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingDataLoading:
    state.landingReducer.landingDataLoading,
  adminData: state.adminReducer.adminData,
  deletePostStatus:
    state.deletePostReducer.deletePostStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getSessionAction,
    getAdminActivities,
    searchAdminActivitiesByName,
    getAdminClubs,
    getUsers,
    getNewsFeed,
    getQuestionnaires,
    getLanding,
    postContent,
    deletePost,
    getOutputs,
    requestPasswordReset,
  }),
)(Settings);
