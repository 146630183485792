import { DELETE_PARTICIPANT_ACTION_SUCCESS, DELETE_PARTICIPANT_ACTION_ERROR } from '../actions/types';

const initialState = {
  updateParticipantActionData: null,
  updateParticipantActionDataError: null,
  updateParticipantActionStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DELETE_PARTICIPANT_ACTION_SUCCESS:
      return {
        ...state,
        deleteParticipantActionData: action.payload,
        deleteParticipantActionDataError: null,
        deleteParticipantActionStatus: 'success',
      };
    case DELETE_PARTICIPANT_ACTION_ERROR:
      return {
        ...state,
        deleteParticipantActionDataError: action.payload,
        deleteParticipantActionData: null,
        deleteParticipantActionStatus: 'error',
      };
    default:
      return {
        ...state,
        updateParticipantActionStatus: null,
      };
  }
};
