import {
    ACTIVITIES_PARTICIPANT_ACTION_CRUD,
    ACTIVITIES_PARTICIPANT_ACTION_SEARCH
} from "../../actions/activities/types";


const initialCRUDState = {
    id: 0,
    data: {
        progress: "{}"
    }
}

const initialSearchState = {}

export const participantActionsCRUDReducer = (state = initialCRUDState, action) => {
    if (action.type !== ACTIVITIES_PARTICIPANT_ACTION_CRUD) {
        return state;
    }

    const apiResponse = action.payload;
    if (apiResponse.status === 200) {
        return {
            ...state,
            ...apiResponse.data,
        }
    } else {
        return {
            ...state,
            ...apiResponse.error.message,
        }
    }
}

export const participantActionsSearchReducer = (state = initialSearchState, action) => {
    if (action.type !== ACTIVITIES_PARTICIPANT_ACTION_SEARCH) {
        return state;
    }

    const apiResponse = action.payload;
    if (apiResponse.status === 200) {
        return {
            ...state,
            participantActions: apiResponse.data
        }
    } else {
        return {
            ...state,
            error: apiResponse.error.message
        }
    }
}

export const PARTICIPANT_ACTION_REDUCERS = {
    participantActionsCRUDReducer,
    participantActionsSearchReducer,
}