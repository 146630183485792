/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Cancel, CloudUploadRounded, Description } from '@material-ui/icons';
import VideoPlayer from './VideoPlayer';

class FilePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  cancelAction = (preview, listPreview) => {
    const {
      fileType, existingContent, removeFile, removeExistingFile, listFiles, filesToRemove,
    } = this.props;

    if (existingContent) {
      removeExistingFile(preview.key, fileType, filesToRemove);
    } else {
      removeFile(preview, fileType, listPreview, listFiles);
    }
  };

  render() {
    const {
      listPreview, fileType, existingContent = false, isUploading,
    } = this.props;

    return (
      <div className={`${fileType}-preview-row mt-2`}>
        {listPreview.map((preview, index) => (
          <div className={`${fileType}-preview-item`}>
            {this.getPreviewContent(preview, fileType, index, existingContent)}
            {!isUploading ? (
              <Cancel
                className={`${fileType}-cancel-button`}
                onClick={() => this.cancelAction(preview, listPreview)}
                titleAccess="Remove File"
              />
            ) : (
              <CloudUploadRounded
                className={`${fileType}-cancel-button`}
                titleAccess="Uploading File"
                style={{ cursor: 'not-allowed' }}
              />
            )}
          </div>
        ))}
      </div>
    );
  }

  getPreviewContent = (preview, fileType, index, existingContent) => {
    const { listFiles } = this.props;
    let content;

    switch (fileType) {
      case 'document':
        content = (
          <div className={`${fileType}-upload-preview`}>
            <Description style={{ fontSize: '50px' }} />
            {/* <p className={`${fileType}-preview-name`}>{listFiles[index].fileItem.name}</p> */}
            <p className={`${fileType}-preview-name`}>
              Document
              {' '}
              {index}
            </p>
          </div>
        );
        break;
      case 'image':
        content = (
          <img
            className="image-upload-preview"
            src={!existingContent ? preview.fileData : preview.value}
            alt="upload-preview"
          />
        );
        break;
      case 'video':
        content = (
          <VideoPlayer
            className="video-upload-preview"
            video={!existingContent ? preview.fileData : preview.value}
            width="150px"
          />
        );
        break;
      default:
        break;
    }

    return content;
  };
}

FilePreview.propTypes = {
  listPreview: PropTypes.array,
  fileType: PropTypes.string,
  listFiles: PropTypes.array,
  filesToRemove: PropTypes.array,
  removeFile: PropTypes.func,
  removeExistingFile: PropTypes.func,
  existingContent: PropTypes.bool,
  isUploading: PropTypes.bool,
};

export default FilePreview;
