/* eslint-disable react/prefer-stateless-function */
import "./activityDetails.css";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import {
  CalendarToday as CalendarTodayIcon,
  Extension as ExtensionIcon,
  People as PeopleIcon,
} from "@material-ui/icons";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import { getLabelByName } from "../../../helpers/helperFunctions";
import activityDefaultImage from "../../../assets/manage-campaigns.svg";
import {
  ACTIVITIES,
  ANONYMOUS,
  CONTRIBUTIONS,
  DATE_JOINED,
  DESCRIPTION,
  END_DATE,
  GENDER,
  LAST_ACTIVITY,
  MEMBERS,
  NO_DESCRIPTION_FOUND,
  PARTICIPANT,
  ROLE,
  START_DATE,
} from "../../../helpers/constants";

export class ParticipantDetails extends Component {
  // UNSAFE_componentWillMount() {
  //   const {
  //     activityData,
  //     getParticipants: getParticipantsAction,
  //     getInstitute: getInstituteAction,
  //   } = this.props;
  //   if (activityData?.parentId) {
  //     getInstituteAction(activityData.parentId);
  //   }

  //   if (activityData?.id) {
  //     getParticipantsAction(activityData.id);
  //   }
  // }

  render() {
    const { participant, activity } = this.props;

    return (
      <div className="m-5 activityDetails">
        <div className="activityDetailsDescription">
          <div className="profilePicture biggerScreens">
            <div className="avatar">
              {participant?.user?.profile?.picture ? (
                <img src={participant?.user?.profile?.picture} alt="" />
              ) : (
                <img src={activityDefaultImage} alt="" />
              )}
            </div>
          </div>
          <div className="descriptionDetails">
            <div className="descriptionDetailsHeader">
              <div className="profilePicture responsiveness">
                <div className="avatar">
                  {participant?.user?.profile?.picture ? (
                    <img src={participant?.user?.profile?.picture} alt="" />
                  ) : (
                    <img src={activityDefaultImage} alt="" />
                  )}
                </div>
              </div>
              <Grid container spacing={3} className="statusDiv">
                <Grid item xs className="activeDiv">
                  <div
                    className={
                      participant?.status === "APPROVED"
                        ? "activeIndicator"
                        : "inActiveIndicator"
                    }
                  />
                  <h4>{participant?.status}</h4>
                </Grid>
                <Grid
                  item
                  xs
                  style={{ maxWidth: "150px" }}
                  className="defaultLabel"
                >
                  {getLabelByName(GENDER)} {": "}{" "}
                  {participant?.user?.profile?.gender}
                </Grid>
                <Grid item xs className="name">
                  {/* <img src={clubData?.data?.profile?.picture} alt="" /> */}
                  <h1>{`${participant?.user?.profile?.location_city}, ${participant?.user?.profile?.location_country}`}</h1>
                </Grid>
              </Grid>
            </div>
            <div className="details">
              <h1>{`${participant?.user?.profile?.firstName} ${participant?.user?.profile?.lastName}`}</h1>
              <p>{participant?.user?.roles[0]}</p>
            </div>
          </div>
        </div>
        <div className="activityDetailsDates">
          <Grid container spacing={3} className="datesDetails">
            <Grid item sm={3} xs={12} className="dateDetailsItem borderRight">
              <Grid container spacing={3}>
                <Grid item sm={10} xs={12} className="dates">
                  <div>{getLabelByName(DATE_JOINED)}</div>
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    {moment(participant?.dateJoined * 1000).format(
                      "DD MMM YYYY"
                    )}
                  </div>
                </Grid>
                <Grid item sm={2} xs={12} className="calendarIcon">
                  <CalendarTodayIcon
                    style={{
                      width: "40px",
                      height: "35px",
                      color: "#F38F46",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={3} xs={12} className="dateDetailsItem borderRight">
              <Grid container spacing={3}>
                <Grid item sm={10} xs={12} className="dates">
                  <div>{getLabelByName(LAST_ACTIVITY)}</div>
                  <div
                    style={{
                      // paddingLeft: "10px",
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    {participant?.lastUpdated
                      ? moment(participant?.lastUpdated * 1000).format(
                          "DD MMM YYYY, HH:MM"
                        )
                      : "N/A"}
                  </div>
                </Grid>
                <Grid item sm={2} xs={12} className="calendarIcon">
                  <CalendarTodayIcon
                    style={{
                      width: "40px",
                      height: "35px",
                      color: "#F38F46",
                      margin: "auto",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={3} xs={12} className="dateDetailsItem borderRight">
              <Grid container spacing={3}>
                <Grid item sm={10} xs={12} className="dates">
                  <div style={{ paddingLeft: "10px" }}>
                    {getLabelByName(CONTRIBUTIONS)}
                  </div>
                  <div
                    style={{
                      paddingLeft: "10px",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    {activity?.settings?.anonymous === true &&
                      getLabelByName(ANONYMOUS)}
                    {(activity?.settings?.anonymous === false &&
                      participant?.contributions?.length) ||
                      0}
                  </div>
                </Grid>
                <Grid item sm={2} xs={12} className="calendarIcon">
                  <ExtensionIcon
                    style={{
                      width: "40px",
                      height: "35px",
                      color: "#F38F46",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={3} xs={12} className="dateDetailsItem">
              <Grid container spacing={3}>
                <Grid item sm={10} xs={12} className="dates">
                  <div style={{ paddingLeft: "10px" }}>
                    {getLabelByName(ROLE)}
                  </div>
                  <div
                    style={{
                      paddingLeft: "10px",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    {participant?.role}
                  </div>
                </Grid>
                <Grid item sm={2} xs={12} className="calendarIcon">
                  <PeopleIcon
                    style={{
                      width: "40px",
                      height: "35px",
                      color: "#F38F46",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

ParticipantDetails.propTypes = {
  getParticipants: PropTypes.func,
  participantsData: PropTypes.object,
  activityData: PropTypes.object,
  getInstitute: PropTypes.func,
  clubData: PropTypes.object,
  getContributionsData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  clubData: state.instituteReducer.instituteData,
  participantsData: state.getParticipantsReducer.getParticipantsData,
});

const mapDispatchToProps = {};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ParticipantDetails);
