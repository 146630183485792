import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Avatar, Card, CardContent, Typography } from "@material-ui/core";
import DedicatedPage from "../../generics/DedicatedPage/DedicatedPage";
import {
  getInstitute,
  getInstituteMembers,
} from "../../../redux/actions/institutesActions";
import userDark from "../../../assets/icons/user-black.svg";
import { CLUB_MEMBERS, MEMBERS } from "../../../helpers/constants";
import { checkSession, getLabelByName } from "../../../helpers/helperFunctions";
import { getSessionAction } from "../../../redux/actions/authActions";

class ClubMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: this.props.location.pathname,
    };
  }

  UNSAFE_componentWillMount = async () => {
    const { props } = this;
    const { id: clubId, handle } = props.match.params;
    await props.getSessionAction();
    await props.getInstitute(handle);
    await props.getInstituteMembers(clubId);
  };

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, status } = nextProps;
    checkSession(data, status, this.state.pathname, nextProps);
  }

  getMembersCards = () => {
    let membersCards = [];

    if (this.props.clubMembersData) {
      membersCards = this.props.clubMembersData?.data?.map((member, index) => (
        <Card
          key={member.user.id}
          className="participant-card participant-card__width"
          variant="outlined"
          raised={false}
        >
          <Avatar
            alt={`${member.user.profile.firstName} ${member.user.profile.lastName}`}
            src={member.user.profile.picture || userDark}
            style={{ width: "60px", height: "60px" }}
          />
          <CardContent>
            <Typography variant="subtitle1">
              {member.user.profile.firstName} {member.user.profile.lastName}
            </Typography>
            <Typography color="textSecondary">{member.role}</Typography>
          </CardContent>
        </Card>
      ));
    }

    return membersCards;
  };

  getPageTitle = () => {
    let title = getLabelByName(CLUB_MEMBERS);
    if (this.props.instituteData?.data?.name) {
      title = `${this.props.instituteData?.data?.name} - ${getLabelByName(
        MEMBERS
      )}`;
    }

    return title;
  };

  render() {
    return (
      <DedicatedPage
        pageTitle={this.getPageTitle()}
        resource={getLabelByName(MEMBERS)}
        resourceData={this.getMembersCards()}
        match={this.props.match}
      />
    );
  }
}

ClubMembers.propTypes = {
  clubMembersData: PropTypes.any,
  getInstituteMembers: PropTypes.func,
  instituteData: PropTypes.any,
  getInstitute: PropTypes.func,
  getSessionAction: PropTypes.func,
};

const mapStateToProps = (state) => ({
  clubMembersData: state.clubMembersReducer.clubMembersData,
  instituteData: state.instituteReducer.instituteData,
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getInstituteMembers,
    getInstitute,
    getSessionAction,
  })
)(ClubMembers);
