/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable global-require */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { Modal } from "react-bootstrap";
import TextInput from "react-autocomplete-input";
import moment from "moment";
import {
  getAction,
  getQuestionnaire,
} from "../../../redux/actions/campaignsActions";
import {
  COMMENTS,
  CONTRIBUTION,
  POST,
  POSTING,
} from "../../../helpers/constants";
import {
  getGuestDataFromLocalStorage,
  getLabelByName,
  getProfileDataFromLocalStorage,
  isActionSuccess,
} from "../../../helpers/helperFunctions";
import Questionnaire from "../../Questionnaire";
import {
  getContributions,
  updateContribution,
  getContributionComments,
  contributionComment,
} from "../../../redux/actions/contributionsActions";
import { getInstituteMembers } from "../../../redux/actions/institutesActions";

class ViewContributionModal extends Component {
  state = {
    questionnaireId: null,
    selectedContribution: this.props.contribution,
    clubMembersNames: [],
    userIds: [],
    commentButtonLoading: false,
  };

  async UNSAFE_componentWillMount() {
    const { contribution } = this.props;

    await this.props.getAction(
      contribution?.activityId,
      contribution?.actionId,
    );

    const {
      getInstituteMembers: getInstituteMembersAction,
      match: {
        params: { clubId },
      },
    } = this.props;

    await getInstituteMembersAction(clubId);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      selectedContribution: nextProps.contribution,
    });

    const { clubMembersData } = this.props;

    const clubMembers = [];

    const clubMembersIndex = {};

    if (clubMembersData?.data) {
      clubMembersData.data.forEach((member) => {
        const memberName = `${member.user.profile.firstName.replace(
          /\s/g,
          "",
        )}${member.user.profile.lastName.replace(
          /\s/g,
          "",
        )}`;

        clubMembers.push(memberName);

        clubMembersIndex[`@${memberName}`] = member.user.id;
      });
    }

    this.setState({
      clubMembersNames: clubMembers,
      clubMembersIndex,
    });

    if (
      nextProps.contribution &&
      nextProps.contribution.actionId !==
        this.props?.contribution?.actionId
    ) {
      await this.props.getAction(
        nextProps.contribution?.activityId,
        nextProps.contribution?.actionId,
      );
    }

    if (
      isActionSuccess(
        nextProps,
        "getActionStatus",
        "getActionData",
      )
    ) {
      this.setState({
        questionnaireId:
          nextProps.getActionData?.data?.meta
            ?.questionnaireId,
      });

      await this.props.getQuestionnaire(
        nextProps?.contribution?.clubId,
        nextProps.getActionData?.data?.meta
          ?.questionnaireId,
      );

      await this.props.getContributionComments(
        nextProps.contribution?.id,
      );
    }

    if (
      nextProps.postContributionsCommentStatus ===
        "success" &&
      this.state.commentButtonLoading === true
    ) {
      this.setState({
        commentButtonLoading: false,
        postComment: "",
      });
      await this.props.getContributionComments(
        nextProps.contribution?.id,
      );
    }
  }

  handleChange = (e) => {
    const newUserIds = [];

    e.split(" ").forEach((value) => {
      const { clubMembersIndex } = this.state;

      if (value && clubMembersIndex[value]) {
        const newId = clubMembersIndex[value];

        const alreadyNewUserId = newUserIds.find(
          (id) => id === newId,
        );

        if (!alreadyNewUserId) {
          newUserIds.push(newId);
        }
      }
    });

    this.setState({
      postComment: e,
      userIds: newUserIds,
    });
  };

  render() {
    const {
      selectedContribution,
      questionnaireId,
      clubMembersNames,
      postComment,
      userIds,
      commentButtonLoading,
    } = this.state;
    const {
      questionnaire,
      clubId,
      isQuestionnaire,
      getContributionsCommentsData,
    } = this.props;

    const userProfile =
      getProfileDataFromLocalStorage() ||
      getGuestDataFromLocalStorage();

    const commentPayload = {
      authorId: userProfile.id,
      content: {
        body: postComment,
      },
      files: {},
      images: {},
      mentions: userIds,
      parentId: selectedContribution?.id,
      status: "APPROVED",
      videos: {},
      visibility: "PUBLIC",
    };

    return (
      <Modal
        size="xl"
        show={this.props.isModalOpen}
        onHide={this.props.closeModal}>
        <div className="modal-content modal-card p-4">
          <div className="create-club-header">
            <div className="header-left">
              <p className="header-modal-title">
                {(isQuestionnaire && questionnaire.name) ||
                  getLabelByName(CONTRIBUTION)}
              </p>
              {!isQuestionnaire && (
                <p className="header-modal-subtitle">
                  {`by ${selectedContribution?.participant?.firstName} ${selectedContribution?.participant?.lastName}`}
                </p>
              )}
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              style={{
                marginTop: "-5rem",
                outline: "none",
              }}
              onClick={() => this.props.closeModal()}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <Questionnaire
            questionnaireId={
              questionnaireId || questionnaire?.id
            }
            clubId={selectedContribution?.clubId || clubId}
            editAction={selectedContribution}
            reviewMode
            allParticipantActions={
              this.state.allParticipantActions
            }
            setEnableSubmission={() => {}}
          />
          <div className="comments-section">
            <div className="comment-section-header">
              <h1>{getLabelByName(COMMENTS)}</h1>
              <p>
                {`This contribution has ${getContributionsCommentsData?.data?.length} comments activities`}
              </p>
            </div>
            <div className="comment-section-content">
              {getContributionsCommentsData?.data?.length >
                0 &&
                getContributionsCommentsData.data.map(
                  (comment) => (
                    <>
                      <div className="one-comment">
                        <div className="comment-content-title">
                          <img
                            src={
                              comment.author.profile.picture
                            }
                            alt=""
                          />
                          <div className="contribution-name">
                            <h3>{`${comment.author.profile.firstName} ${comment.author.profile.lastName}`}</h3>
                            <p>
                              {moment(
                                comment.dateCreated * 1000,
                              ).format(
                                "DD MMM YYYY, HH:MM",
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="comment-content-body">
                          <p>{comment.content.body}</p>
                        </div>
                      </div>
                    </>
                  ),
                )}
              <div className="contribution-comment-input-section">
                <div className="comment-author">
                  <img
                    src={userProfile?.profile?.picture}
                    alt=""
                  />
                </div>
                <div className="comment-author-input">
                  <TextInput
                    maxOptions={0}
                    options={clubMembersNames}
                    placeholder="Add a comment"
                    Component="input"
                    onChange={(value) =>
                      this.handleChange(value)
                    }
                    value={postComment}
                  />
                </div>
              </div>
              <div className="reply-button">
                <button
                  type="button"
                  onClick={() => {
                    this.props.contributionComment(
                      selectedContribution?.id,
                      commentPayload,
                    );
                    this.setState({
                      commentButtonLoading: true,
                    });
                  }}>
                  {commentButtonLoading
                    ? `${getLabelByName(POSTING)}...`
                    : getLabelByName(POST)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ViewContributionModal.propTypes = {
  contribution: PropTypes.any,
  getAction: PropTypes.func,
  getQuestionnaire: PropTypes.func,
  getActionData: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  getActionData: state.getActionReducer.getActionData,
  getActionStatus: state.getActionReducer.getActionStatus,

  updateContributionData:
    state.updateContributionReducer.updateContributionData,
  updateContributionStatus:
    state.updateContributionReducer
      .updateContributionStatus,
  clubMembersData: state.clubMembersReducer.clubMembersData,
  getContributionsCommentsData:
    state.getContributionsCommentsReducer
      .getContributionsCommentsData,
  postContributionsCommentStatus:
    state.postContributionsCommentReducer
      .postContributionsCommentStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getAction,
    getQuestionnaire,
    updateContribution,
    getContributions,
    getInstituteMembers,
    getContributionComments,
    contributionComment,
  }),
)(ViewContributionModal);