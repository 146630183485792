/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AuthorFilterAlphabet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alphabet: new Array(26).fill(1).map((_, i) => String.fromCharCode(65 + i)),
    };
  }

  render() {
    return (
      <div className="alphabet-list">
        {['All', ...this.state.alphabet].map((option) => (
          <div
            key={option.toString()}
            className="filter-option"
            id={`${this.props.selectedOption === option ? 'selected-option' : ''}`}
            onClick={() => this.props.handleFilterClick(option)}
          >
            {option}
          </div>
        ))}
      </div>
    );
  }
}

AuthorFilterAlphabet.propTypes = {
  handleFilterClick: PropTypes.func,
};

export default AuthorFilterAlphabet;
