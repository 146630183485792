import {
    ACTIVITIES_PARTICIPANT_CRUD,
} from "../../actions/types";

const initialState = {
    id: 0,
    user: {},
}

export const participantCRUDReducer = (state = initialState, action) => {
    if (action.type !== ACTIVITIES_PARTICIPANT_CRUD) {
        return state;
    }

    const apiResponse = action.payload;
    if (apiResponse.status === 200) {
        return {
            ...state,
            ...apiResponse.data
        };
    } else {
        return {
            ...state,
            error: apiResponse.error.message
        }
    }
}
