/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
import {
  colors,
  NEW,
  RESPONSE,
} from "../../../../helpers/constants";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import deleteIcon from "../../../../assets/icons/delete-dark-orange.svg";
import cloneIcon from "../../../../assets/icons/clone.svg";

const QuestionFooter = ({
  question,
  questionIndex,
  addResponse,
  cloneQuestion,
  removeQuestion,
}) => {
  return (
    <div className="d-flex justify-content-between">
      <div
        className="mt-3"
        style={{
          color: colors.primaryColor,
          textDecoration: "underline",
          fontWeight: "bold",
          cursor: "pointer",
          width: "fit-content",
        }}
        onClick={() => addResponse(questionIndex)}>
        <AddCircleOutlineOutlined
          style={{ fontSize: "27px" }}
          className="pr-2"
        />
        {`${getLabelByName(NEW)} ${getLabelByName(
          RESPONSE,
        )}`}
      </div>

      <div className="d-flex align-items-center">
        <div
          className="club-icon"
          style={{
            cursor: "pointer",
          }}
          onClick={() =>
            cloneQuestion(question, questionIndex)
          }>
          <img
            src={cloneIcon}
            alt="clone-action"
            width="24px"
          />
        </div>

        <div
          className="ml-3 club-icon"
          data-toggle="modal"
          style={{
            cursor: "pointer",
          }}
          onClick={() =>
            removeQuestion(question, questionIndex)
          }>
          <img
            src={deleteIcon}
            alt="edit-action"
            width="24px"
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionFooter;
