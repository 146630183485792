import {
  SEARCH_CLUBS_DESCRIPTION_ERROR,
  SEARCH_CLUBS_DESCRIPTION_SUCCESS,
} from '../actions/types';

const initialState = {
  searchClubsDescriptionData: null,
  searchClubsDescriptionDataError: null,
  searchClubsDescriptionStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_CLUBS_DESCRIPTION_SUCCESS:
      return {
        ...state,
        searchClubsDescriptionData: action.payload,
        searchClubsDescriptionDataError: null,
        searchClubsDescriptionStatus: 'success',
      };
    case SEARCH_CLUBS_DESCRIPTION_ERROR:
      return {
        ...state,
        searchClubsDescriptionDataError: action.payload,
        searchClubsDescriptionData: null,
        searchClubsDescriptionStatus: 'error',
      };
    default:
      return {
        ...state,
        searchClubsDescriptionStatus: null,
      };
  }
};
