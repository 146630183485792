import {
  GET_POST_SUCCESS, GET_POST_ERROR,
} from '../actions/types';

const initialState = {
  getPostData: null,
  getPostDataError: null,
  getPostStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_POST_SUCCESS:
      return {
        ...state,
        getPostData: action.payload,
        getPostDataError: null,
        getPostStatus: 'success',
      };
    case GET_POST_ERROR:
      return {
        ...state,
        getPostDataError: action.payload,
        getPostData: null,
        getPostStatus: 'error',
      };
    default:
      return {
        ...state,
        getPostStatus: null,
      };
  }
};
