/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
/* eslint-disable dot-notation */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Add,
  ArrowBackIos,
  ArrowForwardIos,
  FiberManualRecord,
} from '@material-ui/icons';
import moment from 'moment';
import { toast } from 'react-toastify';

import { getMember } from '../redux/actions/institutesActions';
import { getSessionAction } from '../redux/actions/authActions';
import Footer from './Footer';
import Navbar from './Navbar';
import NotFound from './NotFound';
import Fallback from './Fallback';
import timeIcon from '../assets/icons/time.svg';
import campaignIcon from '../assets/icons/campaign-dark.svg';
import membersIcon from '../assets/manage-members.svg';
import logo from '../assets/logo-opac.svg';
import { checkSession, getLabelByName, getProfileDataFromLocalStorage } from '../helpers/helperFunctions';
import {
  BACK, CAMPAIGN, CITY, COUNTRY, MEMBER, SINCE, LAST, ONLINE, OFFLINE,
} from '../helpers/constants';

export class ClubDetails extends Component {
  state = {
    isLoading: false,
    isEditing: false,
    profileData: getProfileDataFromLocalStorage(),
    pathname: this.props.location.pathname,
  };

  async componentDidMount() {
    const { props, state } = this;
    const { profileData, pathname } = state;
    if (!profileData || profileData.status !== 200) {
      window.location.href = `/login?redirect_to=${pathname}`;
    }
    await props.getSessionAction();
    await this.fetchData();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { props, state } = this;
    const { pathname } = state;
    const {
      dataError,
      status,
      data,
    } = nextProps;

    checkSession(data, status, pathname, props);
  }

  fetchData = async () => {
    const { props } = this;
    const { id } = props.match.params;
    await props.getMember(id);
  };

  render() {
    const { REACT_APP_APPLICATION_NAME } = process.env;
    document.title = `${REACT_APP_APPLICATION_NAME || 'SOSO'} - ${getLabelByName(MEMBER)} - Details`;
    const {
      getMemberData,
      getMemberStatus,

      landingData,
      landingDataError,
      landingStatus,
      match,
    } = this.props;

    const { id } = match;

    if (getMemberStatus && !getMemberData) {
      return <Fallback route={`/member/${id}`} />;
    }

    if (getMemberStatus && getMemberData && getMemberData.status === 500) {
      return <Fallback route={`/member/${id}`} />;
    }

    if (getMemberData && getMemberData.status === 500) {
      return <NotFound />;
    }

    let memberInfo = null;
    if (getMemberData && getMemberData.data) {
      memberInfo = getMemberData.data[0];
    }

    const dateJoined = memberInfo
      ? moment(memberInfo.dateJoined * 1000).format('DD MMM YYYY')
      : 'DD MMM YYYY';
    const lastActivy = memberInfo && memberInfo.lastUpdated
      ? moment(memberInfo.lastUpdated * 1000).format('DD MMM YYYY')
      : dateJoined;

    let defaultLogo = logo;
    if (landingData && landingData.data) {
      const landingInfo = landingData.data.filter((x) => x.name === 'landing-page')[0];
      if (landingInfo && landingInfo.data && landingInfo.data['logo']) {
        defaultLogo = landingInfo.data['logo'];
      }
    }

    return (
      <>
        <Navbar pathname="club" />
        <div className="club-page">
          <div className="container">
            <div className="buttons-row">
              <div id="back-link">
                <div
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => this.props.history.goBack()}
                  style={{ cursor: 'pointer' }}
                >
                  <ArrowBackIos className="arrow-back-icon" />
                  {'  '}
                  {getLabelByName(BACK)}
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              <div className="institute-card">
                <div className="institute-card-details">
                  <div className="card-details-left">
                    <div className="institute-picture">
                      <img
                        className="img-fluid rounded-circle mr-3"
                        src={
                          memberInfo && memberInfo.user.profile.picture
                            ? memberInfo.user.profile.picture
                            : defaultLogo
                        }
                        alt="club-avatar"
                        style={{
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                    <div className="institute-name">
                      {memberInfo
                        && `${memberInfo.user.profile.firstName} ${memberInfo.user.profile.lastName}`}
                    </div>
                    <div className="institute-status">
                      <FiberManualRecord
                        style={{
                          color:
                            memberInfo && memberInfo.status === 'ACTIVE'
                              ? '#10A728'
                              : '#FF0D0D',
                          width: '15px',
                          marginRight: '0.5rem',
                        }}
                      />
                      {memberInfo && memberInfo.status === 'ACTIVE'
                        ? getLabelByName(ONLINE)
                        : getLabelByName(OFFLINE)}
                    </div>
                  </div>
                  <div className="card-details-right">
                    <div className="card-details-description">
                      <h5>{getLabelByName(COUNTRY)}</h5>
                      {memberInfo && memberInfo.user.profile.location_country}
                    </div>
                    <div className="card-details-statement">
                      <h5>{getLabelByName(CITY)}</h5>
                      {memberInfo && memberInfo.user.profile.location_city}
                    </div>
                  </div>
                </div>
                <div className="institute-card-stats">
                  <div>
                    <div className="stats-icon">
                      <img src={timeIcon} alt="" width="35px" />
                    </div>
                    <div className="card-stats-date-created">
                      {`${getLabelByName(MEMBER)} ${getLabelByName(SINCE)}`}
                      <div className="stats-item">{dateJoined}</div>
                    </div>
                  </div>
                  <div>
                    <div className="stats-icon">
                      <img src={timeIcon} alt="" width="35px" />
                    </div>
                    <div className="card-stats-last-activity">
                      {`${getLabelByName(LAST)}`}
                      <div className="stats-item">{lastActivy}</div>
                    </div>
                  </div>
                  <div>
                    <div className="stats-icon">
                      <img src={campaignIcon} alt="" width="35px" />
                    </div>
                    <div className="card-stats-campaigns">
                      {`Club ${getLabelByName(CAMPAIGN)}`}
                      <div className="stats-item">
                        {memberInfo && memberInfo.user.activities
                          ? Object.keys(memberInfo.user.activities).length
                          : 0}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="stats-icon">
                      <img src={membersIcon} alt="" width="35px" />
                    </div>
                    <div className="card-stats-members">
                      Role
                      <div className="stats-item">
                        {memberInfo
                          && `${memberInfo.role
                            .slice(0, 1)
                            .toUpperCase()}${memberInfo.role.slice(
                            memberInfo.role.length
                              - (memberInfo.role.length - 1),
                          )}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-block">
            <div className="footer-component">
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  }
}

ClubDetails.propTypes = {
  getSessionAction: PropTypes.func,
  getMember: PropTypes.func,
  getMemberData: PropTypes.any,
  getMemberStatus: PropTypes.any,
  match: PropTypes.any,
  dataError: PropTypes.object,
  data: PropTypes.object,
  status: PropTypes.string,
  history: PropTypes.any,
  location: PropTypes.any,
  landingDataError: PropTypes.object,
  landingData: PropTypes.object,
  landingStatus: PropTypes.string,
};

export const mapStateToProps = (state) => ({
  getMemberData: state.getMemberReducer.getMemberData,
  getMemberDataError: state.getMemberReducer.getMemberDataError,
  getMemberStatus: state.getMemberReducer.getMemberStatus,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getMember,
    getSessionAction,
  }),
)(ClubDetails);
