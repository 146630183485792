import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './library-actions-bar.css';
import { getLabelByName } from '../../../../helpers/helperFunctions';
import { colors, DELETE, DOCUMENT_LIBRARY, EXPORT, FILES, SORT, UPLOAD } from '../../../../helpers/constants';
import { MoreHoriz, SwapVertRounded } from '@material-ui/icons';
import deleteIcon from '../../../../assets/icons/delete-grey.svg';
import exportIcon from '../../../../assets/icons/logout-grey.svg';
import uploadIcon from '../../../../assets/icons/upload-path.svg';

class LibraryActionsBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [
        { icon: <img className="actions-bar__actions__action-item__icon" src={uploadIcon} alt="upload-action" width="25px" />, name: getLabelByName(UPLOAD) },
        { icon: <SwapVertRounded className="actions-bar__actions__action-item__icon" />, name: getLabelByName(SORT) },
        { icon: <img className="actions-bar__actions__action-item__icon" src={deleteIcon} alt="delete-action" width="25px" />, name: getLabelByName(DELETE) },
        { icon: <img className="actions-bar__actions__action-item__icon" src={exportIcon} alt="export-action" width="25px" style={{ transform: 'rotate(-90deg)' }} />, name: getLabelByName(EXPORT) },
        { icon: <MoreHoriz className="actions-bar__actions__action-item__icon" style={{ color: colors.textSecondary }} />, name: '' },
      ],
    };
  }

  render() {
    const { actions } = this.state;
    const { title, contributions } = this.props;

    return (
      <div className="actions-bar">
        <div className="actions-bar__title">
          {title || getLabelByName(DOCUMENT_LIBRARY)}
          <span className="actions-bar__title__files-count">
            ({contributions.length}
            {'  '}
            {getLabelByName(FILES)})
          </span>
        </div>
        <div className="actions-bar__actions">
          {/* {actions.map((action) => (
            <div className="actions-bar__actions__action-item">
              {action.icon}
              {'  '}
              {action.name}
            </div>
          ))} */}
        </div>
      </div>
    );
  }
}

LibraryActionsBar.propTypes = {
  title: PropTypes.string,
  contributions: PropTypes.array,
};

export default LibraryActionsBar;
