import {
  GET_QUESTIONNAIRES_SUCCESS, GET_QUESTIONNAIRES_ERROR,
} from '../actions/types';

const initialState = {
  getQuestionnairesData: null,
  getQuestionnairesDataError: null,
  getQuestionnairesStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTIONNAIRES_SUCCESS:
      return {
        ...state,
        getQuestionnairesData: action.payload,
        getQuestionnairesDataError: null,
        getQuestionnairesStatus: 'success',
      };
    case GET_QUESTIONNAIRES_ERROR:
      return {
        ...state,
        getQuestionnairesDataError: action.payload,
        getQuestionnairesData: null,
        getQuestionnairesStatus: 'error',
      };
    default:
      return {
        ...state,
        getQuestionnairesStatus: null,
      };
  }
};
