import "./logo.scss";
import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ContentCard from "../ContentCard/ContentCard";
import {
  CHANGE_LOGO,
  webContentSection,
} from "../../../../helpers/constants";
import { getLabelByName } from "../../../../helpers/helperFunctions";

export class Logo extends Component {
  state = {};

  render() {
    const {
      landingInfo,
      fileChange,
      imagePreview,
      submitImage,
      stateValues,
    } = this.props;

    return (
      <div>
        <ContentCard
          header=""
          title="Upload your logo, this would be used on multiple pages across the app"
          subtitle="Can upload png, jpeg, jpg and svg format. Preview your upload on the right area of this card"
          changeImageButtonText={getLabelByName(
            CHANGE_LOGO,
          )}
          uploadImage
          landingInfo={landingInfo}
          fileChange={fileChange}
          imagePreview={imagePreview}
          submitImage={submitImage}
          contentSection={webContentSection.logo}
          stateValues={stateValues}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(Logo);
