import {
    QUESTIONNAIRES_CREATE,
    QUESTIONNAIRES_RETRIEVE,
    QUESTIONNAIRES_UPDATE,
    QUESTIONNAIRES_DELETE,
    QUESTIONNAIRES_SEARCH,
} from "../../actions/types";

const initialState = (defaultMessage) => (
    {
        id: 0,
        parentResource: "CLUB",
        parentId: 1,
        name: { defaultMessage },
        description: { defaultMessage },
        dateCreated: 0,
        lastUpdated: 0,
        questions: [],
        actions: [],
        meta: {}
    }
);

export const questionnaireRetrieveReducer = (state = initialState("Loading..."), action) => {
    if (action.type !== QUESTIONNAIRES_RETRIEVE) {
        return state;
    }

    const apiResponse = action.payload;
    if (apiResponse.status === 200) {
        return {
            ...state,
            ...apiResponse.data,
        };
    } else {
        return {
            ...state,
            ...initialState("Questionnaire Not Found"),
            error: apiResponse.error.message,
        }
    }
}

// export const baseReducer = (dispatchedAction, actionType, initialState, successState, errorState) => {
//     return (state = initialState("Loading..."), action) => {
//         if (dispatchedAction.type !== actionType) {
//             return initialState;
//         }

//         const apiResponse = dispatchedAction.payload;
//         if (apiResponse.status === 200) {
//             console.log(apiResponse.data, "successfully retrieved");
//             return successState;
//         } else {
//             return errorState;
//         }
//     }
// }

// export const questionnaireRetrieveReducer2 = baseReducer(action, QUESTIONNAIRES_RETRIEVE, {}, {}, {});