/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Add } from '@material-ui/icons';
import { colors } from '../../../helpers/constants';

class TimelineCardSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayEndDate: false,
    };
  }

  render() {
    const { handleChange, startDate, endDate } = this.props;
    const { displayEndDate } = this.state;

    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className="institute-card article-card">
          <div className="create-card-header">
            <div className="card-header-title">
              <h5>
                Timeline
                {' '}
                <span className="asterisk">*</span>
              </h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="help-text mb-3">Start Date</div>
              <div className="">
                <input
                  type="datetime-local"
                  name="startDate"
                  className="form-control"
                  id="input-startDate"
                  min={this.minStartDate()}
                  value={startDate || 'yyyy-MM-ddThh:mm'}
                  onChange={handleChange}
                  style={{ backgroundColor: '#FFFFFF' }}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              {!displayEndDate && (
                <div
                  className="d-flex align-items-center"
                  onClick={() => this.setState({ displayEndDate: true })}
                  style={{
                    cursor: 'pointer',
                    width: 'max-content',
                    marginTop: '3.5rem',
                  }}
                >
                  <Add
                    style={{
                      color: colors.primaryColor,
                      width: '25px',
                      marginRight: '1rem',
                    }}
                  />
                  <span style={{ color: colors.textSecondary }}>Add End date and time</span>
                </div>
              )}
              {displayEndDate && (
                <>
                  <div className="help-text mb-3">End Date</div>
                  <div className="">
                    <input
                      type="datetime-local"
                      name="endDate"
                      className="form-control"
                      id="input-endDate"
                      min={this.minEndDate()}
                      value={endDate || 'yyyy-MM-ddThh:mm'}
                      onChange={handleChange}
                      style={{ backgroundColor: '#FFFFFF' }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  minStartDate = () => moment(moment.now()).format('YYYY-MM-DDThh:mm');

  minEndDate = () => this.props.startDate && moment(this.props.startDate).format('YYYY-MM-DDThh:mm');
}

TimelineCardSection.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  handleChange: PropTypes.any,
};

export default TimelineCardSection;
