import React, { Component } from "react";
import PropTypes from "prop-types";
import { Add, ArrowForwardIos } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { compose } from "redux";
import {
  NO_MEMBERS,
  VIEW_ALL,
  ADD_NEW_PARTICIPANT,
  MEMBERS,
} from "../../../../helpers/constants";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import "./club-members.css";

export class ClubMembersSection extends Component {
  render() {
    const { clubMembersData, match } = this.props;

    return (
      <div className="club-members">
        <div className="header-title">
          <h1>
            {getLabelByName(MEMBERS)}
            <span style={{ color: "#A3A3A3", paddingLeft: "3px" }}>
              ({clubMembersData?.data?.length})
            </span>
          </h1>
          <span>
            <Link
              to={`${window.location.pathname}/members`}
              style={{ color: "#C6A177" }}
            >
              {getLabelByName(VIEW_ALL)}
              <ArrowForwardIos />
            </Link>
          </span>
        </div>
        <div className="body">
          <div className="members-details">
            {clubMembersData?.data?.length > 0 ? (
              clubMembersData?.data?.slice(0, 10).map((member, index) => (
                <div className="member-item" key={index}>
                  <div className="avatar">
                    {(member.user?.profile?.picture && (
                      <img src={member.user?.profile?.picture} alt="" />
                    )) || (
                      <h1>
                        {member.user?.profile?.firstName[0] +
                          member.user?.profile?.lastName[0]}
                      </h1>
                    )}
                  </div>
                  <h1>{member.user?.profile?.firstName} </h1>
                </div>
              ))
            ) : (
              <p>{getLabelByName(NO_MEMBERS)}</p>
            )}
          </div>
        </div>
        <div className="footer">
          {match.path === "/manage/clubs/:id/:handle" && (
            <Button
              style={{ textTransform: "none" }}
              color="primary"
              onClick={() => this.props.SetIsModalOpen()}
            >
              <Add />
              {getLabelByName(ADD_NEW_PARTICIPANT)}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

ClubMembersSection.propTypes = {
  clubMembersData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  clubMembersData: state.clubMembersReducer.clubMembersData,
});

const mapDispatchToProps = {};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ClubMembersSection);
