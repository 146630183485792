import "./basicInformation.scss";
import React from "react";
import {
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";

import { getLabelByName } from "../../../../../../helpers/helperFunctions";
import {
  CLICK_HERE_TO_UPLOAD,
  LOGO,
  THE_WORD,
  outputTypes,
  TYPE,
  CREATE_OUTPUT_STATUS_DESCRIPTION,
  VISIBILITY,
} from "../../../../../../helpers/constants";

export default function BasicInformation({
  visibilityOptions,
  statuses,
  payload,
  handleChange,
  handleRadio,
  fileChange,
  getImagePreview,
}) {
  const coverImage = payload?.settings.find(
    ({ key }) => key === "cover_image",
  );

  return (
    <div>
      <div className="output-information-details p-x-50">
        <div className="create-output-name">
          <TextField
            id="outlined-helperText"
            label="Name"
            variant="outlined"
            name="name"
            value={payload.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="output-type-visibility">
          <FormControl variant="outlined" className="create-output-type">
            <InputLabel id="demo-simple-select-outlined-label">
              {getLabelByName(TYPE)}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="type"
              required
              onChange={handleChange}
              value={payload.type || ""}
              label="Type"
            >
              {Object.values(outputTypes).map((outputType, index) => (
                <MenuItem key={index} value={Object.keys(outputTypes)[index]}>
                  {outputType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className="create-output-visibility">
            <InputLabel id="demo-simple-select-outlined-label">
              {getLabelByName(VISIBILITY)}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              onChange={handleChange}
              name="visibility"
              value={payload.visibility || ""}
              label="Visibility"
            >
              {visibilityOptions.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="create-output-description">
          <TextField
            id="outlined-textarea"
            label="Description"
            multiline
            variant="outlined"
            name="description"
            required
            value={payload.description}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="create-output-status p-x-50">
        <div className="output-status-description">
          <p>{getLabelByName(CREATE_OUTPUT_STATUS_DESCRIPTION)}</p>
        </div>
        <div className="create-output-status-buttons">
          {statuses.map((status, index) => (
            <FormControl component="fieldset" key={index}>
              <RadioGroup
                row
                aria-label="position"
                name="status"
                defaultValue="top"
              >
                <FormControlLabel
                  value={status.value}
                  onChange={handleRadio}
                  control={<Radio color="primary" />}
                  label={status.text}
                  checked={status.value === payload.status}
                />
              </RadioGroup>
            </FormControl>
          ))}
        </div>
      </div>
      <div className="create-output-logo p-x-50">
        <div className="section-card image-card">
          <div className="card-details">
            <div className="upload-file-div">
              <div className="custom-input">
                <input
                  type="file"
                  className="custom-file-inputz mb-5"
                  id="customFile"
                  onChange={fileChange}
                  accept="image/*"
                />
              </div>
              <div className="upload-custom-file">
                <label htmlFor="customFile">
                  {`${getLabelByName(CLICK_HERE_TO_UPLOAD)} ${getLabelByName(
                    THE_WORD
                  ).toLowerCase()} ${getLabelByName(LOGO).toLowerCase()}`}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="card-image-preview">
          <img
              src={
                coverImage
                  ? coverImage.value
                  : getImagePreview
              }
              alt="card-preview"
            />
        </div>
      </div>
    </div>
  );
}
