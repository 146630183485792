import {
  SEARCH_CLUBS_NAME_ERROR,
  SEARCH_CLUBS_NAME_SUCCESS,
} from '../actions/types';

const initialState = {
  searchClubsNameData: null,
  searchClubsNameDataError: null,
  searchClubsNameStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_CLUBS_NAME_SUCCESS:
      return {
        ...state,
        searchClubsNameData: action.payload,
        searchClubsNameDataError: null,
        searchClubsNameStatus: 'success',
      };
    case SEARCH_CLUBS_NAME_ERROR:
      return {
        ...state,
        searchClubsNameDataError: action.payload,
        searchClubsNameData: null,
        searchClubsNameStatus: 'error',
      };
    default:
      return {
        ...state,
        // searchClubsNameStatus: null,
      };
  }
};
