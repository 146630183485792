import {
  GET_REACTIONS_ERROR, GET_REACTIONS_SUCCESS,
} from '../actions/types';

const initialState = {
  getReactionsData: null,
  getReactionsDataError: null,
  getReactionsStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REACTIONS_SUCCESS:
      return {
        ...state,
        getReactionsData: action.payload,
        getReactionsDataError: null,
        getReactionsStatus: 'success',
      };
    case GET_REACTIONS_ERROR:
      return {
        ...state,
        getReactionsDataError: action.payload,
        getReactionsData: null,
        getReactionsStatus: 'error',
      };
    default:
      return {
        ...state,
        getReactionsStatus: null,
        getReactionsDataError: null,
        getReactionsData: null,
      };
  }
};
