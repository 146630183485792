import {
  UPDATE_OUTPUT_SUCCESS, UPDATE_OUTPUT_ERROR,
} from '../actions/types';

const initialState = {
  updateOutputData: null,
  updateOutputDataError: null,
  updateOutputStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_OUTPUT_SUCCESS:
      return {
        ...state,
        updateOutputData: action.payload,
        updateOutputDataError: null,
        updateOutputStatus: 'success',
      };
    case UPDATE_OUTPUT_ERROR:
      return {
        ...state,
        updateOutputDataError: action.payload,
        updateOutputData: null,
        updateOutputStatus: 'error',
      };
    default:
      return {
        ...state,
        updateOutputStatus: null,
      };
  }
};
