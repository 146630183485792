/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ArrowForwardIos } from '@material-ui/icons';
import { getProfileDataFromLocalStorage } from '../../helpers/helperFunctions';

class ReactionsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileData: getProfileDataFromLocalStorage(),
      reactionsList: [
        'like',
        'celebrate',
        'support',
        'love',
        'insightful',
        'curious',
        'dislike',
      ],
    };
  }

  render() {
    const {
      reactions,
      resourceItem,
      createPostReaction,
      updateSelectedReactions,
      position,
    } = this.props;
    const { reactionsList, profileData } = this.state;

    return (
      <div className={`dropdown-menu${position === 'right' ? ' dropdown-menu-right' : ''}`}>
        <div className="reactions-icons justify-content-between">
          <div className="d-flex">
            {reactionsList.map((reactionItem, index) => (
              <div key={index} className="activity-stat-item">
                <div
                  className="activity-stat-icon"
                  onClick={() => createPostReaction(
                    resourceItem.id,
                    reactionItem,
                    reactions
                        && reactions[resourceItem.id]
                        && reactions[resourceItem.id][profileData.data.id]
                        && reactions[resourceItem.id][profileData.data.id].name,
                  )}
                >
                  <img
                    src={
                      reactions
                      && reactions[resourceItem.id]
                      && reactions[resourceItem.id][profileData.data.id]
                      && reactions[resourceItem.id][profileData.data.id].name
                        === reactionItem
                        ? require(`../../assets/reactions/${reactionItem}-orange.svg`)
                        : require(`../../assets/reactions/${reactionItem}.svg`)
                    }
                    alt={`${reactionItem}-icon`}
                    width="25px"
                  />
                </div>
                {(resourceItem.reactions
                  && resourceItem.reactions[reactionItem]
                  && resourceItem.reactions[reactionItem].total)
                  || 0}
              </div>
            ))}
            <div
              className="reactions-modal-arrow d-flex align-items-center"
              data-toggle="modal"
              data-target=".reactions-modal"
              onClick={() => updateSelectedReactions(resourceItem.id)}
            >
              <ArrowForwardIos style={{ color: '#C6A177' }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReactionsMenu.propTypes = {
  reactions: PropTypes.any,
  resourceItem: PropTypes.any,
  createPostReaction: PropTypes.func,
  updateSelectedReactions: PropTypes.func,
  position: PropTypes.string,
};

export default ReactionsMenu;
