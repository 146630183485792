import {
  GET_LABELS_ERROR,
  GET_LABELS_SUCCESS,
  LANDING_ERROR,
  LANDING_LOADING,
  LANDING_SUCCESS,
} from "./types";
import apiCall from "../../helpers/apiCall";

const landingType = (type, payload) => ({
  type,
  payload,
});

export const getLanding = () => (dispatch) => {
  dispatch(landingType(LANDING_LOADING, true));
  apiCall
    .get("/utils/value-objects")
    .then((response) => {
      dispatch(landingType(LANDING_SUCCESS, response.data));
    })
    .catch((err) => {
      dispatch(landingType(LANDING_ERROR, err));
      // return err;
    });
};

export const getLabelsByLanguage =
  (languageId) => (dispatch) => {
    apiCall
      .get(`/utils/value-object/${languageId}`)
      .then((response) => {
        localStorage.setItem(
          "labels",
          JSON.stringify(response.data),
        );
        dispatch(
          landingType(GET_LABELS_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(landingType(GET_LABELS_ERROR, err));
      });
  };
