import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class ItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      item,
      type,
      index,
      defaultIcon,
    } = this.props;

    return (
      <Link
        className="club-link mb-2"
        to={`/${type}/${item.id}/${item.handle}`}
      >
        <div className="m-1" key={index + 1}>
          <div
            className="card m-1"
          >
            <div
              className="card-img"
              style={{
                backgroundColor: '#E8DACA',
                borderRadius: '5px 5px 0 0',
                display: 'grid',
                height: '227px',
                placeItems: 'center',
              }}
            >
              <img
                className="img-fluid"
                src={
                  item && item.profile.picture
                    ? item.profile.picture
                    : defaultIcon
                }
                alt="club-avatar"
                style={{
                  objectFit: 'cover',
                  height: (item && item.profile.picture) ? '100%' : 'auto',
                  maxHeight: '227px',
                  borderRadius: '5px 5px 0 0',
                  width: !(item && item.profile.picture) ? '100px' : '100%',
                }}
              />
            </div>
            <div className="card-body">
              <p className="card-text d-flex align-items-center justify-content-center">
                {item.name.length > 20
                  ? `${item.name.slice(0, 20)}...`
                  : item.name}
              </p>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

ItemCard.propTypes = {
  item: PropTypes.any,
  index: PropTypes.any,
  type: PropTypes.any,
  defaultIcon: PropTypes.any,
};

export default ItemCard;
