import React from "react";
import { ReactComponent as DeleteLessonIcon } from "../../../../assets/icons/delete-lesson-icon.svg";

const LessonItem = ({
  articles,
  articleId,
  fieldIndex,
  onLessonChange,
  remove,
}) => {
  return (
    <div className="d-flex align-items-center flex-grow-1 lesson-item">
      <p className="mb-0">Lesson {fieldIndex + 1}</p>

      <div className="select-club-dropdown flex-grow-1 mx-3 py-2">
        <div className="select-club-input">
          <select
            className="form-control"
            id="input-campaignType"
            defaultValue={articleId}
            placeholder="Select an article"
            onChange={(e) =>
              onLessonChange(fieldIndex, e.target.value)
            }>
            <option>Select Article</option>
            {articles.map((article) => (
              <option key={article.id} value={article.id}>
                {article?.content?.title}
              </option>
            ))}
          </select>
        </div>
      </div>

      <button
        className="delete-lesson-icon"
        type="button"
        onClick={() => remove(fieldIndex)}>
        <DeleteLessonIcon />
      </button>
    </div>
  );
};

export default LessonItem;
