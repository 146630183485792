import { USERS_SESSION_USER } from "../../actions/types";

const initialState = {
    "clubs": {},
    "activities": {},
    "interests": [],
    "images": {},
    "contact": {},
    "roles": [],
    "permissions": [],
    "id": 0,
    "username": "",
    "status": "",
    "dateCreated": 0,
    "lastUpdated": 0,
    "profile": {
        "firstName": "",
        "gender": "",
        "lastName": "",
        "location_city": "",
        "location_country": "",
        "picture": ""
    },
    "settings": {},
    "meta": {
        "verifiedAt": "0"
    }
}

export const sessionUserReducer = (state = initialState, action) => {
    if (action.type !== USERS_SESSION_USER) {
        return state;
    }

    let apiResponse = action.payload;
    if (apiResponse.status === 200) {
        return {
            ...state,
            ...apiResponse.data
        }
    } else {
        return {
            ...initialState,
            error: apiResponse.error.message,
        }
    }
}