import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  GET_SESSION_SUCCESS,
  GET_SESSION_ERROR,
} from "./types";
import apiCall from "../../helpers/apiCall";
import { serviceProvider } from "../../helpers/constants";
import { getHeaders } from "../../helpers/getHeaders";

const authType = (type, payload) => ({
  type,
  payload,
});

const headers = {
  "Content-Type": "application/json",
  "X-SERVICE-PROVIDER": serviceProvider,
};

export const signupAction =
  (userData, actionType) => (dispatch) =>
    apiCall
      .post("/users/registration", userData, { headers })
      .then((response) => {
        dispatch(
          authType(`${actionType}_SUCCESS`, response.data),
        );
      })
      .catch((err) => {
        dispatch(authType(`${actionType}_ERROR`, err));
      });

export const loginAction = (userData) => (dispatch) =>
  apiCall
    .post("/session", userData, { headers })
    .then((response) => {
      localStorage.setItem(
        "headers",
        JSON.stringify(response.headers),
      );
      localStorage.setItem(
        "profileData",
        JSON.stringify(response.data),
      );
      dispatch(authType(LOGIN_SUCCESS, response.data));
    })
    .catch((err) => {
      dispatch(authType(LOGIN_ERROR, err));
    });

export const createGuestUser = () => async (dispatch) => {
  try {
    const response = await apiCall.post("/guest");

    localStorage.setItem(
      "guestHeaders",
      JSON.stringify({
        ...response.headers,
        "x-service-provider":
          process.env.REACT_APP_SERVICE_PROVIDER,
      }),
    );
    localStorage.setItem(
      "guestData",
      JSON.stringify(response.data),
    );
    // dispatch(authType(GUEST_LOGIN_SUCCESS, response.data));

    return response;
  } catch (error) {
    return error;
  }
};

export const forgotPasswordAction =
  (userData) => (dispatch) =>
    apiCall
      .post("/user/password/request", userData, { headers })
      .then((response) => {
        dispatch(
          authType(FORGOT_PASSWORD_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(authType(FORGOT_PASSWORD_ERROR, err));
      });

export const resetPasswordAction =
  (passwordData) => (dispatch) =>
    apiCall
      .post("/user/password/reset", passwordData, {
        headers,
      })
      .then((response) => {
        dispatch(
          authType(RESET_PASSWORD_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(authType(RESET_PASSWORD_ERROR, err));
      });

export const changePasswordAction =
  (passwordData, userHeaders) => (dispatch) =>
    apiCall
      .post("/user/password", passwordData, {
        headers: userHeaders,
      })
      .then((response) => {
        dispatch(
          authType(CHANGE_PASSWORD_SUCCESS, response.data),
        );
      })
      .catch((err) => {
        dispatch(authType(CHANGE_PASSWORD_ERROR, err));
      });

export const getSessionAction = () => (dispatch) =>
  apiCall
    .get("/session", {
      headers: getHeaders(null, "/session"),
    })
    .then((response) => {
      // localStorage.setItem('headers', JSON.stringify(response.headers));
      localStorage.setItem(
        "profileData",
        JSON.stringify(response.data),
      );
      dispatch(
        authType(GET_SESSION_SUCCESS, response.data),
      );
    })
    .catch((err) => {
      dispatch(authType(GET_SESSION_ERROR, err));
    });
