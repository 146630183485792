/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getLabelByName } from '../../../helpers/helperFunctions';
import { QUESTION_TYPE, SELECT } from '../../../helpers/constants';
import { questionsTypesIcons, questionsTypesMapping } from './components/questionsHelpers';

class QuestionSelectionDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: this.props.selected,
    };
  }

  handleSelect = (option) => {
    this.setState({ selectedOption: option });
    this.props.handleSelectType(option, 'type', this.props.questionIndex, this.props.responseIndex);
  };

  render() {
    const { selectedOption } = this.state;
    return (
      <div
        className="form-icon select-dropdown"
        style={{
          backgroundColor: '#FFFFFF',
          paddingLeft: 0,
          border: 'none',
        }}
      >
        <div style={{ marginLeft: '1rem' }}>
          <div className="select-header">{getLabelByName(QUESTION_TYPE)}</div>
          <div className="dropdown">
            <button
              className="btn btn-default dropdown-toggle roles-dropdown-button"
              type="button"
              id="dropdownMenu1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
              style={{ boxShadow: 'unset' }}
            >
              <div>
                {selectedOption === null ? `${getLabelByName(SELECT)} ${getLabelByName(QUESTION_TYPE)}`
                  : (
                    <label className="p-0 m-0">
                      {questionsTypesIcons[selectedOption]}
                      {questionsTypesMapping[selectedOption]}
                    </label>
                  )}
              </div>
              <div className="caret" />
            </button>
            <ul className="dropdown-menu checkbox-menu allow-focus" aria-labelledby="dropdownMenu1">
              {Object.keys(questionsTypesMapping).map((option) => (
                <li className="mt-2 mb-2" onClick={() => this.handleSelect(option)}>
                  <label>
                    {questionsTypesIcons[option]}
                    {questionsTypesMapping[option]}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

QuestionSelectionDropdown.propTypes = {
  selected: PropTypes.any,
  handleSelectType: PropTypes.func,
  questionIndex: PropTypes.number,
  responseIndex: PropTypes.number,
};

export default QuestionSelectionDropdown;
