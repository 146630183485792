import {
  GET_POST_LESSON_SUCCESS,
  GET_POST_LESSON_ERROR,
} from "../actions/types";

const initialState = {
  getPostLessonsData: null,
  getPostLessonsDataError: null,
  getPostLessonStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_POST_LESSON_SUCCESS:
      return {
        ...state,
        getPostLessonsData: action.payload,
        getPostLessonsDataError: null,
        getPostLessonStatus: "success",
      };
    case GET_POST_LESSON_ERROR:
      return {
        ...state,
        getPostLessonsDataError: action.payload,
        getPostLessonsData: null,
        getPostLessonStatus: "error",
      };
    default:
      return {
        ...state,
        getPostLessonStatus: null,
      };
  }
};
