/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { getLabelByName } from '../../../../helpers/helperFunctions';
import {
  CHOICES, MAXIMUM, MAX_CHOICES_REACHED, MINIMUM, TEXT, DEFAULTS,
} from '../../../../helpers/constants';

class MultipleChoiceInput extends Component {
  state = {
    minChoices: this.props.response.choicesSettings && this.props.response.choicesSettings?.minChoices,
    maxChoices: this.props.response.choicesSettings && this.props.response.choicesSettings?.maxChoices,
    defaults: this.props.response.choicesSettings && this.props.response.choicesSettings?.defaults
      ? this.props.response.choicesSettings?.defaults
      : [],
  };

  handleChange = (e) => {
    const { handleChoicesSettings, questionIndex, responseIndex } = this.props;
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });

    const settings = {
      ...this.setState,
      minChoices: name === 'minChoices' ? value : this.state.minChoices,
      maxChoices: name === 'maxChoices' ? value : this.state.maxChoices,
    };

    handleChoicesSettings(settings, questionIndex, responseIndex);
  };

  handleCheckbox = (e) => {
    const { handleChoicesSettings, questionIndex, responseIndex } = this.props;
    const { defaults } = this.state;
    const { checked, value } = e.target;
    let newSelected = [...defaults];

    const hasReachedMaxChoices = this.props.response?.choicesSettings
      && this.props.response?.choicesSettings.maxChoices
      && defaults.length > Number(this.props.response?.choicesSettings).maxChoices;

    if (checked && !defaults.includes(value) && !hasReachedMaxChoices) {
      newSelected.push(value);
    } else if (!checked && defaults.includes(value)) {
      newSelected = defaults.filter((option) => option !== value);
    } else if (hasReachedMaxChoices) {
      toast.info(getLabelByName(MAX_CHOICES_REACHED));
    }

    this.setState({ defaults: newSelected });
    const settings = { ...this.state, defaults: newSelected };
    handleChoicesSettings(settings, questionIndex, responseIndex);
  };

  render() {
    const { minChoices, maxChoices, defaults } = this.state;
    const { questionIndex, responseIndex } = this.props;

    return (
      <>
        <div className="row boolean">
          <div className="col-md-6">
            <label htmlFor={`input-min_choices-${questionIndex}-${responseIndex}`}>
              {`${getLabelByName(MINIMUM)} ${getLabelByName(CHOICES)}`}
              <input
                value={minChoices}
                type="text"
                name="minChoices"
                className="form-control"
                id={`input-min_choices-${questionIndex}-${responseIndex}`}
                onChange={this.handleChange}
                // placeholder={getLabelByName(TEXT)}
                style={{ backgroundColor: '#FFFFFF' }}
              />
            </label>
          </div>
          <div className="col-md-6">
            <label htmlFor={`input-max_choices-${questionIndex}-${responseIndex}`}>
              {`${getLabelByName(MAXIMUM)} ${getLabelByName(CHOICES)}`}
              <input
                value={maxChoices}
                type="text"
                name="maxChoices"
                className="form-control"
                id={`input-max_choices-${questionIndex}-${responseIndex}`}
                onChange={this.handleChange}
                // placeholder={getLabelByName(TEXT)}
                style={{ backgroundColor: '#FFFFFF' }}
              />
            </label>
          </div>
        </div>
        <div><u>{getLabelByName(DEFAULTS)}</u></div>
        <div>
          {this.props.response.options.map((option, defaultIndex) => (
            <div className="form-check form-check-inline mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                id={`gridCheck-${questionIndex}-${responseIndex}-${defaultIndex}`}
                onChange={(e) => this.handleCheckbox(e)}
                value={option}
                checked={defaults.includes(option)}
              />
              <label
                className="form-check-label"
                htmlFor={`gridCheck-${questionIndex}-${responseIndex}-${defaultIndex}`}
              >
                {option || (<span className="invisible">-</span>)}
              </label>
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default MultipleChoiceInput;
