/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable global-require */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { toast } from "react-toastify";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import {
  getAction,
  getActivity,
  getQuestionnaire,
} from "../../../redux/actions/campaignsActions";
import {
  REVIEW,
  CONTRIBUTION,
  PROCESSING,
  APPROVE,
  REJECT,
  STATUS,
  UPDATED,
  SELECT_ACTION_GET_STARTED,
  CHOOSE_ACTION_CONTRIBUTE_BEHALF,
  CANCEL,
  NEXT,
  STEP,
  colors,
} from "../../../helpers/constants";
import {
  getLabelByName,
  isActionError,
  isActionSuccess,
} from "../../../helpers/helperFunctions";
import Questionnaire from "../../Questionnaire";
import {
  getContributions,
  updateContribution,
} from "../../../redux/actions/contributionsActions";
import { ActivityMoreInformation } from "../../generics/Activity/ActivityMoreInformationSection/ActivityMoreInformation";
import { Grid } from "@material-ui/core";

class BehalfModal extends Component {
  state = {
    selectedAction: null,
    showModal: this.props.isModalOpen,
    selectedIndex: 0,
  };

  render() {
    const { selectedAction, selectedIndex } = this.state;
    const { activity, isModalOpen, toggleModal, participant } = this.props;

    return (
      <Modal
        size="xl"
        show={isModalOpen}
        onHide={toggleModal}
        className="behalf-modal"
      >
        <div className="modal-content modal-card p-4">
          <div className="create-club-header">
            <div className="header-left">
              <p className="header-modal-title">
                {getLabelByName(SELECT_ACTION_GET_STARTED)}
              </p>
              <p className="header-modal-subtitle">
                {getLabelByName(CHOOSE_ACTION_CONTRIBUTE_BEHALF)}
              </p>
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              style={{ marginTop: "-5rem", outline: "none" }}
              onClick={() => toggleModal()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="body">
            <Grid container className="steps">
              <div className="activity-actions d-flex justify-content-between">
                {activity?.actions?.map((action, index) => (
                  <Grid
                    item
                    sm={3}
                    xs={12}
                    className="step"
                    key={index}
                    onClick={() =>
                      this.setState({
                        selectedAction: action,
                        selectedIndex: index,
                      })
                    }
                    style={{
                      cursor: "pointer",
                      border:
                        selectedAction === action &&
                        `1px solid ${colors.primaryColor}`,
                    }}
                  >
                    <h1>{`${getLabelByName(STEP)} ${index + 1}`}</h1>
                    <p>{action.name}</p>
                  </Grid>
                ))}
              </div>
            </Grid>
          </div>

          <div className="row d-flex justify-content-around create-club-buttons">
            <>
              <button
                type="button"
                className="btn btn-lg submit-btn btn-reverse"
                onClick={() => toggleModal()}
              >
                {getLabelByName(CANCEL)}
              </button>
              <Link
                to={{
                  pathname: `/manage/activities/${activity?.id}/${activity?.handle}/participant/${participant?.id}/action/${selectedAction?.id}`,
                  state: {
                    participant,
                    action: selectedAction,
                    index: selectedIndex,
                  },
                }}
              >
                <button type="button" className="btn btn-lg submit-btn">
                  {getLabelByName(NEXT)}
                </button>
              </Link>
            </>
          </div>
        </div>
      </Modal>
    );
  }
}

BehalfModal.propTypes = {
  contribution: PropTypes.any,
  getAction: PropTypes.func,
  getQuestionnaire: PropTypes.func,
  updateContribution: PropTypes.func,
  getContributions: PropTypes.func,
  getActivity: PropTypes.func,
  getActionData: PropTypes.any,
  getActivityData: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  getActionData: state.getActionReducer.getActionData,
  getActionStatus: state.getActionReducer.getActionStatus,
  getActivityData: state.getActivityReducer.getActivityData,
  getActivityStatus: state.getActivityReducer.getActivityStatus,

  updateContributionData:
    state.updateContributionReducer.updateContributionData,
  updateContributionStatus:
    state.updateContributionReducer.updateContributionStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getAction,
    getQuestionnaire,
    updateContribution,
    getContributions,
    getActivity,
  })
)(BehalfModal);
