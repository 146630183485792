/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/sort-comp */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import Loading from "../generics/Loading/Loading";
import {
  getQuestionnaire,
  getParticipantActions,
  getAction,
} from "../../redux/actions/campaignsActions";
import ActionRenderer from "../campaign/ParticipantAction/ActionRenderer";

class Questionnaire extends Component {
  state = {
    isFilledAndRequired: true,
    isRatingFilledAndRequired: true,
    isDropdownFilledAndRequired: true,
    isRadioButtonFilledAndRequired: true,
  };

  componentDidUpdate() {
    const {
      isFilledAndRequired,
      isRatingFilledAndRequired,
      isDropdownFilledAndRequired,
      isRadioButtonFilledAndRequired,
    } = this.state;

    const { setEnableSubmission } = this.props;

    if (
      isFilledAndRequired === true &&
      isRatingFilledAndRequired === true &&
      isDropdownFilledAndRequired === true &&
      isRadioButtonFilledAndRequired === true
    ) {
      setEnableSubmission(true);
    } else {
      setEnableSubmission(false);
    }
  }

  setRequiredAndFilled = (value) => {
    const { isFilledAndRequired } = this.state;

    if (isFilledAndRequired !== value) {
      this.setState({ isFilledAndRequired: value });
    }
  };

  setRadioButtonRequiredAndFilled = (value) => {
    const { isRadioButtonFilledAndRequired } = this.state;

    if (isRadioButtonFilledAndRequired !== value) {
      this.setState({
        isRadioButtonFilledAndRequired: value,
      });
    }
  };

  setRatingFilledAndRequired = (value) => {
    const { isRatingFilledAndRequired } = this.state;

    if (isRatingFilledAndRequired !== value) {
      this.setState({ isRatingFilledAndRequired: value });
    }
  };

  setDropdownFilledAndRequired = (value) => {
    const { isDropdownFilledAndRequired } = this.state;

    if (isDropdownFilledAndRequired !== value) {
      this.setState({ isDropdownFilledAndRequired: value });
    }
  };

  render() {
    const {
      updateQuestionnaireState,
      editAction,
      getQuestionnaireLoading,
      reviewMode,
      setEnableSubmission,
    } = this.props;

    const {
      isFilledAndRequired,
      isRatingFilledAndRequired,
      isDropdownFilledAndRequired,
      isRadioButtonFilledAndRequired,
    } = this.state;

    if (getQuestionnaireLoading) {
      return (
        <div className="question-loading">
          <Loading />;
        </div>
      );
    }

    return (<ActionRenderer
      // activity={activity}
      // action={action}
      // participant={participant}
      // participantActions={this.props.allParticipantActions}
      // pageAction="review"
      // isPreviewMode={true}
      // participantActionId={participantActionId}
    />);
  }

  async UNSAFE_componentWillMount() {
    const {
      questionnaireId,
      clubId,
      getQuestionnaire: getQuestionnaireAction,
    } = this.props;

    if (questionnaireId) {
      await getQuestionnaireAction(clubId, questionnaireId);
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      questionnaireId,
      clubId,
      actionId: actionIdFromNextProps,
    } = nextProps;

    const {
      getQuestionnaire: getQuestionnaireAction,
      actionId: actionIdFromProps,
    } = this.props;

    if (actionIdFromProps !== actionIdFromNextProps) {
      await getQuestionnaireAction(clubId, questionnaireId);
    }
  }

  dataHasLoaded = () => {
    const { getQuestionnaireData } = this.props;

    return !!(
      getQuestionnaireData && getQuestionnaireData.data
    );
  };

  getQuestions = () => {
    const { getQuestionnaireData } = this.props;

    if (!this.dataHasLoaded()) {
      return [];
    }

    return getQuestionnaireData.data.questions;
  };

  getMaxChoices = (response) => {
    const settings = response?.choicesSettings;
    const result =
      settings && settings.maxChoices
        ? Number(settings.maxChoices)
        : 0;

    return result;
  };

  getSourcesOptions = (response) => {
    const { allParticipantActions, editAction } =
      this.props;
    const options = [];
    if (response.sources) {
      response.sources.forEach((source) => {
        if (
          !source.actionId &&
          editAction?.data[source.input_key]
        ) {
          try {
            options.push(
              ...JSON.parse(
                editAction.data[source.input_key],
              ),
            );
          } catch {
            options.push(editAction.data[source.input_key]);
          }
        }
        if (source.actionId && allParticipantActions) {
          const action = allParticipantActions.find(
            (participantAction) =>
              Number(participantAction.actionId) ===
              Number(source.actionId),
          );
          if (
            action?.data &&
            action.data[source.input_key]
          ) {
            try {
              options.push(
                ...JSON.parse(
                  action.data[source.input_key],
                ),
              );
            } catch (error) {
              options.push(action.data[source.input_key]);
            }
          }
        }
      });
    }

    return options.length > 0 ? options : null;
  };

  getPrevQuestion = (response) => {
    const { getQuestionnairesData } = this.props;

    const prevQuestions = [];

    const allQuestionnaires = getQuestionnairesData?.data;
    if (response.sources) {
      response?.sources?.forEach((source) => {
        allQuestionnaires?.forEach((questionnaire) => {
          questionnaire?.questions?.forEach((question) => {
            question?.settings?.responses?.forEach(
              (responseItem) => {
                if (
                  source.input_key ===
                  responseItem.input_key
                ) {
                  prevQuestions.push(responseItem.label);
                }
              },
            );
          });
        });
      });
    }

    return prevQuestions;
  };
}

Questionnaire.propTypes = {
  questionnaireId: PropTypes.any,
  clubId: PropTypes.string,
  editAction: PropTypes.any,
  updateQuestionnaireState: PropTypes.any,
  getQuestionnaire: PropTypes.func,
  getQuestionnaireData: PropTypes.any,
  reviewMode: PropTypes.any,
  allParticipantActions: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  getQuestionnaireDataError:
    state.getQuestionnaireReducer.getQuestionnaireDataError,
  getQuestionnaireData:
    state.getQuestionnaireReducer.getQuestionnaireData,
  getQuestionnaireStatus:
    state.getQuestionnaireReducer.getQuestionnaireStatus,
  getQuestionnaireLoading:
    state.getQuestionnaireReducer.getQuestionnaireLoading,

  getQuestionnairesData:
    state.getQuestionnairesReducer.getQuestionnairesData,
  getQuestionnairesDataError:
    state.getQuestionnairesReducer
      .getQuestionnairesDataError,
  getQuestionnairesStatus:
    state.getQuestionnairesReducer.getQuestionnairesStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getQuestionnaire,
    getParticipantActions,
    getAction,
  }),
)(Questionnaire);
