/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@material-ui/icons";
import SideNav from "./SideNav/SideNav";
import Navbar from "../../../Navbar";
import "./settings.css";
import { getSessionAction } from "../../../../redux/actions/authActions";
import {
  checkSession,
  getLabelByName,
  getProfileDataFromLocalStorage,
  isActionError,
  isActionSuccess,
} from "../../../../helpers/helperFunctions";
import {
  SAVE,
  SAVING,
  UPDATED_SUCCESSFULLY,
} from "../../../../helpers/constants";
import SettingsContent from "./SettingsContent";
import {
  getInstitute,
  updateClub,
} from "../../../../redux/actions/institutesActions";

export class ClubSettings extends Component {
  state = {
    profileData: getProfileDataFromLocalStorage(),
    pathname: this.props.location.pathname,
    clubData: null,
    otherInfoChanged: false,
    isSaving: false,
    showMenu: false,
  };

  async UNSAFE_componentWillMount() {
    await this.load();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { props, state } = this;
    const { pathname } = state;
    const { status, data, instituteData } = nextProps;
    if (props.match.params.category !== nextProps.match.params.category) {
      await this.load();
    }

    checkSession(data, status, pathname, props);

    if (instituteData?.data && this.props.instituteData !== instituteData) {
      this.setState({ clubData: instituteData.data });
    }

    if (isActionSuccess(nextProps, "updateClubStatus", "updateClubData")) {
      toast.success(getLabelByName(UPDATED_SUCCESSFULLY));
      await this.fetchData();
      this.setState({ isSaving: false, otherInfoChanged: false });
    }

    if (isActionError(nextProps, "updateClubStatus", "updateClubData")) {
      toast.error("Oooops! Something went wrong.\nPlease try again!");
      this.setState({ isSaving: false });
    }
  }

  load = async () => {
    const { props, state } = this;
    const { profileData, pathname } = state;
    if (!profileData || profileData.status !== 200) {
      window.location.href = `/login?redirect_to=${pathname}`;
    }
    await props.getSessionAction();
    this.setState({ isLoading: true });
    await this.fetchData();
  };

  fetchData = async () => {
    const { id: clubId } = this.props.match.params;
    await this.props.getInstitute(clubId);
  };

  handleRadio = (e) => {
    const val = e.target.value;
    this.setState((prevState) => ({
      clubData: {
        ...prevState.clubData,
        status: val,
      },
      otherInfoChanged: true,
    }));
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      clubData: {
        ...prevState.clubData,
        [name]: value,
      },
      otherInfoChanged: true,
    }));
  };

  settingsChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      clubData: {
        ...prevState.clubData,
        settings: {
          ...prevState.clubData.settings,
          [name]: value,
        },
      },
      otherInfoChanged: true,
    }));
  };

  switchChange = (prop) => {
    this.setState((prevState) => ({
      clubData: {
        ...prevState.clubData,
        settings: {
          ...prevState.clubData.settings,
          [prop]: !prevState.clubData.settings[prop],
        },
      },
      otherInfoChanged: true,
    }));
  };

  notificationsChange = (value, notifCategory, prop) => {
    this.setState((prevState) => ({
      clubData: {
        ...prevState.clubData,
        settings: {
          ...prevState.clubData?.settings,
          notifications: {
            ...prevState.clubData?.settings?.notifications,
            [notifCategory]: {
              ...prevState.clubData?.settings?.notifications?.[notifCategory],
              [prop]: value,
            },
          },
        },
      },
      otherInfoChanged: true,
    }));
  };

  notifSwitchChange = (e, notifCategory, prop) => {
    this.setState((prevState) => ({
      clubData: {
        ...prevState.clubData,
        settings: {
          ...prevState.clubData?.settings,
          notifications: {
            ...prevState.clubData?.settings?.notifications,
            [notifCategory]: {
              ...prevState.clubData?.settings?.notifications?.[notifCategory],
              [prop]:
                !prevState.clubData?.settings?.notifications?.[notifCategory]?.[
                  prop
                ],
            },
          },
        },
      },
      otherInfoChanged: true,
    }));
  };

  onSave = async () => {
    const { clubData } = this.state;
    this.setState({ isSaving: true });
    await this.props.updateClub(clubData.id, clubData);
  };

  toggleMenu = () =>
    this.setState((prevState) => ({ showMenu: !prevState.showMenu }));

  render() {
    const { category } = this.props.match.params;
    const { clubData, otherInfoChanged, isSaving, showMenu } = this.state;

    return (
      <div className="club-settings">
        <Navbar pathname="manage" />
        <div className="club-settings-container row">
          <div className="club-settings-sidenav" style={{ paddingTop: "1rem" }}>
            <SideNav {...this.props} />
          </div>

          <div className="club-settings-content">
            <div className="club-settings-content-title d-flex justify-content-between">
              <div>{clubData?.name}</div>
              <div>
                <div className="join-button">
                  <button
                    type="button"
                    className="btn btn-lg submit-btn"
                    disabled={!otherInfoChanged || isSaving}
                    onClick={() => this.onSave()}
                  >
                    {isSaving ? getLabelByName(SAVING) : getLabelByName(SAVE)}
                  </button>
                </div>
              </div>
            </div>

            <SettingsContent
              category={category}
              clubData={clubData}
              handleRadio={this.handleRadio}
              handleChange={this.handleChange}
              settingsChange={this.settingsChange}
              switchChange={this.switchChange}
              notificationsChange={this.notificationsChange}
              notifSwitchChange={this.notifSwitchChange}
            />
          </div>
        </div>

        <div className="club-settings-mobile-container">
          <div className="club-settings-mobile">
            <div className="club-settings-mobile__menu-bar">
              <div className="current-entity" onClick={() => this.toggleMenu()}>
                {category[0].toUpperCase() + category.slice(1)}
                {showMenu ? (
                  <KeyboardArrowUpRounded className="ml-3" />
                ) : (
                  <KeyboardArrowDownRounded className="ml-3" />
                )}
              </div>
              <div>
                <div className="join-button">
                  <button
                    type="button"
                    className="btn btn-lg submit-btn"
                    disabled={!otherInfoChanged || isSaving}
                    onClick={() => this.onSave()}
                    style={{
                      width: "7rem",
                      height: "32px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "0",
                    }}
                  >
                    {isSaving ? getLabelByName(SAVING) : getLabelByName(SAVE)}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {showMenu && (
            <div className="mobile-nav">
              <SideNav {...this.props} toggleMenu={this.toggleMenu} />
            </div>
          )}

          <SettingsContent
            category={category}
            clubData={clubData}
            handleRadio={this.handleRadio}
            handleChange={this.handleChange}
            settingsChange={this.settingsChange}
            switchChange={this.switchChange}
            notificationsChange={this.notificationsChange}
            notifSwitchChange={this.notifSwitchChange}
          />
        </div>
      </div>
    );
  }
}

ClubSettings.propTypes = {};

export const mapStateToProps = (state) => ({
  instituteDataError: state.instituteReducer.instituteDataError,
  instituteData: state.instituteReducer.instituteData,
  getInstituteStatus: state.instituteReducer.getInstituteStatus,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,

  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,
  clubData: state.instituteReducer.instituteData,

  updateClubData: state.updateClubReducer.updateClubData,
  updateClubDataError: state.updateClubReducer.updateClubDataError,
  updateClubStatus: state.updateClubReducer.updateClubStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getInstitute,
    getSessionAction,
    updateClub,
  })
)(ClubSettings);
