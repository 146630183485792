/* eslint-disable global-require */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import arrowCircleIcon from '../../assets/icons/arrow-circled-orange-light.svg';
import { getLabelByName, getProfileDataFromLocalStorage } from '../../helpers/helperFunctions';
import { colors, VIEW } from '../../helpers/constants';
import { markNotification } from '../../redux/actions/notificationsActions';
import { getNotificationTime, getResource, getViewLink } from '../../helpers/notificationsHelper';

export class NotificationItemCard extends Component {
  state = {
    profileData: getProfileDataFromLocalStorage(),
  };

  markAsRead = async () => {
    const userId = this.state.profileData.data.id;
    const { id: notificationId } = this.props.notification;

    const payload = {
      ...this.props.notification,
      status: 'READ',
    };

    await this.props.markNotification(payload, userId, notificationId);
  };

  render() {
    const { notification } = this.props;
    const resource = getResource(notification);
    return (
      <Link
        to={getViewLink(notification)}
        style={{ textDecoration: 'none', color: 'unset' }}
        onClick={() => this.markAsRead()}
      >
        <div id={notification.id} className="card activity-card">
          <div className="card-body">
            <div className="activity-content-row">
              <div className="activity-content">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="post-item-author pb-0">
                    <div className="post-avatar">
                      <img
                        className="img-fluid"
                        src={resource?.profile?.picture || require('../../assets/icons/club-dark.svg')}
                        alt="notification-avatar"
                        style={{
                          width: '40px',
                          height: '40px',
                          objectFit: 'cover',
                          padding: !resource?.profile?.picture && '0.5rem',
                          filter: notification.status === 'READ' && 'grayscale(80%)',
                        }}
                      />
                    </div>
                    <div
                      className="post-author-name"
                      style={{ fontWeight: notification.status === 'READ' && 'normal' }}
                    >
                      {notification.data.description}
                      <br />
                    </div>
                  </div>
                  <div
                    className="activity-stats justify-content-between"
                    style={{ fontWeight: notification.status === 'READ' ? 'normal' : 'bold' }}
                  >
                    <div className="d-flex">
                      <div className="activity-stat-item">
                        {getNotificationTime(notification.dateCreated)}
                      </div>
                    </div>
                    <Link
                      to={getViewLink(notification)}
                      style={{ textDecoration: 'none' }}
                      onClick={() => this.markAsRead()}
                    >
                      <div
                        className="activity-stat-item mr-0"
                        style={{ color: colors.primaryColor }}
                      >
                        {getLabelByName(VIEW)}
                        <img
                          className="ml-2"
                          src={arrowCircleIcon}
                          alt="arrow-icon"
                          width="16px"
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

NotificationItemCard.propTypes = {
  notification: PropTypes.any,
  markNotification: PropTypes.func,
};

export const mapStateToProps = (state) => ({
  markNotificationData: state.markNotificationReducer.markNotificationData,
  markNotificationDataError: state.markNotificationReducer.markNotificationDataError,
  markNotificationStatus: state.markNotificationReducer.markNotificationStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { markNotification }),
)(NotificationItemCard);
