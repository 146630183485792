/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  AddCircleOutlineOutlined,
  CancelOutlined,
  Clear,
} from "@material-ui/icons";
import {
  ADD,
  colors,
  LABEL,
  REMOVE,
  SEARCH,
  webContentCategory,
} from "../../../../helpers/constants";
import ResourceTable from "../../ResourceTable";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import searchOrange from "../../../../assets/icons/search-orange.svg";

export class Labels extends Component {
  state = {
    searchValue: "",
    displaySearch: false,
  };

  getTableHeaders = () => {
    return {
      label: "Label",
      value: "Value",
      actions: (
        <div
          title={`${getLabelByName(ADD)} ${getLabelByName(
            LABEL,
          )}`}
          onClick={() =>
            this.props.addNewLabel(
              this.props.labelLang ===
                webContentCategory.fr_labels
                ? "french-labels"
                : "english-labels",
            )
          }
          style={{ cursor: "pointer" }}>
          <AddCircleOutlineOutlined
            style={{
              fontSize: "36px",
              color: colors.primaryColor,
            }}
          />
        </div>
      ),
    };
  };

  getTableData = () => {
    const { labelLang, labelsObject, handleLabelsChange } =
      this.props;

    const { searchValue, displaySearch } = this.state;

    const language =
      labelLang === webContentCategory.fr_labels
        ? "french-labels"
        : "english-labels";

    const labelInfo = { ...labelsObject[language] };

    if (searchValue && displaySearch) {
      const regex = new RegExp(
        searchValue.toLowerCase(),
        "g",
      );

      const filtered = Object.keys(labelInfo?.data || {})
        .filter((key) => key.toLowerCase().match(regex))
        .reduce((obj, key) => {
          obj[key] = labelInfo?.data[key];
          return obj;
        }, {});

      labelInfo.data = filtered;
    }

    const tableData = Object.keys(
      labelInfo?.data || {},
    ).map((key, index) => {
      const labelInput = () => (
        <input
          type="text"
          name={`label-input-${index}`}
          className="form-control"
          id={`label-input-${index}`}
          placeholder="Label..."
          value={key}
          onChange={(e) =>
            handleLabelsChange(
              language,
              e.target.value,
              labelInfo.data[key],
              index,
              "label",
            )
          }
        />
      );

      const valueInput = () => (
        <input
          type="text"
          name={`value-input-${index}`}
          className="form-control"
          id={`value-input-${index}`}
          placeholder="Value..."
          value={labelInfo.data[key]}
          onChange={(e) =>
            handleLabelsChange(
              language,
              key,
              e.target.value,
              index,
              "value",
            )
          }
        />
      );

      const removeLabel = () => (
        <div
          title={`${getLabelByName(
            REMOVE,
          )} ${getLabelByName(LABEL)}`}
          onClick={() =>
            this.props.removeLabel(
              this.props.labelLang ===
                webContentCategory.fr_labels
                ? "french-labels"
                : "english-labels",
              index,
            )
          }
          style={{ cursor: "pointer" }}>
          <CancelOutlined
            style={{
              fontSize: "24px",
              color: colors.primaryColor,
            }}
          />
        </div>
      );

      return {
        label: labelInput(),
        value: valueInput(),
        actions: removeLabel(),
      };
    });

    return tableData;
  };

  filterLabels = (e) => {
    e.preventDefault();
    this.setState({ displaySearch: true });
  };

  searchComponent = () => (
    <form className="form" onSubmit={this.filterLabels}>
      <div className="input-group mb-2">
        <input
          type="text"
          className="form-control"
          id="inputSearch"
          placeholder={`${getLabelByName(
            SEARCH,
          )} ${getLabelByName(LABEL)}`}
          name="searchValue"
          value={this.state.searchValue}
          onChange={this.handleChange}
          style={{ height: "32px" }}
        />
        {this.state.searchValue && (
          <div
            className="input-group-append"
            style={{ height: "32px" }}>
            <button
              id="clear-search-btn"
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => this.clearSearch()}>
              <Clear style={{ fontSize: "16px" }} />
            </button>
          </div>
        )}
        <div
          className="input-group-append"
          style={{ height: "32px" }}>
          <button
            disabled={!this.state.searchValue}
            id="search-btn"
            className="btn btn-outline-secondary"
            type="submit"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img
              src={searchOrange}
              alt="search-icon"
              style={{ width: "12px" }}
            />
          </button>
        </div>
      </div>
    </form>
  );

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  clearSearch = () => {
    this.setState({
      displaySearch: false,
      searchValue: "",
    });
    this.forceUpdate();
  };

  render() {
    return (
      <div className="mt-3">
        <ResourceTable
          headers={this.getTableHeaders()}
          tableData={this.getTableData()}
          tableActions={{
            title: "",
            actions: [this.searchComponent()],
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  landingData: state.landingReducer.landingData,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {}),
)(Labels);
