import React, {useEffect} from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getLanding } from '../redux/actions/landingActions';
import {TermPolicyPageComponent} from './term-privacy-component'

const Terms = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const termsObject = props?.landingData?.data.find(datum => datum.name === 'terms')
  const termsData = termsObject?.data || {}

  useEffect(() => {
    props.getLanding()
  }, [])
  
  return (
    <TermPolicyPageComponent pageTitle="Terms and Conditions" pageData={termsData}/>
  )
}

const mapStateToProps = (state) => ({
    landingDataError: state.landingReducer.landingDataError,
    landingData: state.landingReducer.landingData,
    landingStatus: state.landingReducer.landingStatus,
});
  
export default compose(
    withRouter,
    connect(mapStateToProps, { getLanding }),
  )(Terms);