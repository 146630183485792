import {
  GET_PARTICIPANT_CONTRIBUTION_SUCCESS,
  GET_PARTICIPANT_CONTRIBUTION_ERROR,
  GET_PARTICIPANT_CONTRIBUTION_LOADING,
} from '../actions/types';

const initialState = {
  participantContributionData: null,
  participantContributionLoading: false,
  participantContributionError: null,
  participantContributionStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTICIPANT_CONTRIBUTION_SUCCESS:
      return {
        ...state,
        participantContributionData: action.payload,
        participantContributionLoading: false,
        participantContributionError: null,
        participantContributionStatus: 'success',
      };
    case GET_PARTICIPANT_CONTRIBUTION_ERROR:
      return {
        ...state,
        participantContributionData: null,
        participantContributionLoading: false,
        participantContributionError: action.payload,
        participantContributionStatus: 'error',
      };
    case GET_PARTICIPANT_CONTRIBUTION_LOADING:
      return {
        ...state,
        participantContributionData: null,
        participantContributionLoading: true,
        participantContributionError: null,
        participantContributionStatus: 'loading',
      };
    default:
      return {
        ...state,
      };
  }
};
