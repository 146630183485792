import React from "react";
import Modal from "@material-ui/core/Modal";
import { getLabelByName } from "../../../helpers/helperFunctions";
import {
  ARE_YOU_SURE_TEXT,
  CANCEL,
  DELETE,
  DELETE_CONFIRMATION_TEXT,
  DELETING,
  POST,
  THIS_WORD,
  TO_WORD,
  WANT,
  YOU,
} from "../../../helpers/constants";

const DeleteModal = ({
  showDeleteModal,
  closeModal,
  handleDelete,
  isButtonLoading,
  setButtonLoading,
}) => {
  return (
    <Modal
      open={showDeleteModal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="delete-confirmation-modal">
      <div className="delete-confirmation-modal-content">
        <div className="delete-confirmation-modal-title">
          <h1>
            {`${getLabelByName(
              ARE_YOU_SURE_TEXT,
            )} ${getLabelByName(
              YOU,
            ).toLowerCase()} ${getLabelByName(
              WANT,
            ).toLowerCase()} ${getLabelByName(
              TO_WORD,
            )} ${getLabelByName(
              DELETE,
            ).toLowerCase()} ${getLabelByName(
              THIS_WORD,
            ).toLowerCase()} ${getLabelByName(
              POST,
            ).toLowerCase()}?`}
          </h1>
        </div>
        <div className="delete-confirmation-modal-body">
          <p>
            {`${getLabelByName(
              DELETE_CONFIRMATION_TEXT,
            )} ${getLabelByName(POST).toLowerCase()}`}
          </p>
        </div>
        <div className="delete-confirmation-call-to-action-buttons">
          <div className="confirmation-remove-button">
            <button
              type="button"
              onClick={() => {
                setButtonLoading();
                handleDelete();
              }}>
              {isButtonLoading ? (
                <>
                  <span>{`${getLabelByName(
                    DELETING,
                  )}  `}</span>
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status">
                    <span className="sr-only">
                      Loading...
                    </span>
                  </div>
                </>
              ) : (
                getLabelByName(DELETE)
              )}
            </button>
          </div>
          <div className="confirmation-cancel-button">
            <button type="button" onClick={closeModal}>
              {getLabelByName(CANCEL)}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
