import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { redirectTo } from '../../helpers/helperFunctions';

class RedirectToAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { pathname } = this.props;

    return (
      <div className="text-center">
        <div className="redirection-message">
          You must to be registered to view this content!
        </div>
        <div className="">
          <button
            type="button"
            className="btn btn-sm submit-btn m-3"
            onClick={() => redirectTo('login', pathname)}
          >
            Login
          </button>
          <button
            type="button"
            className="btn btn-sm submit-btn m-3"
            onClick={() => redirectTo('signup', pathname)}
          >
            Signup
          </button>
        </div>
      </div>
    );
  }
}

RedirectToAuth.propTypes = {
  pathname: PropTypes.string,
};

export default RedirectToAuth;
