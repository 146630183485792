/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@material-ui/core";
import {
  AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@material-ui/icons";
import { compose } from "redux";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  ACTION,
  ACTIVITY,
  ADD,
  ANOTHER,
  CHOOSE_OUTPUT_SOURCE_DESCRIPTION,
  GLOBAL,
  NAME,
  NONE,
  outputParams,
  SETTINGS,
  SOURCE,
  sourceTypes,
} from "../../../../../../helpers/constants";
import { getLabelByName } from "../../../../../../helpers/helperFunctions";
import { getClubActivities } from "../../../../../../redux/actions/campaignsActions";
import {
  useWizardContext,
  WIZARD_CONSTANTS,
} from "../wizardContextProvider";

import "./outputSettingsForm.scss";

const OutputSettingsForm = ({
  nextStep,
  closeModal,
  getClubActivitiesReq,
  clubActivitiesData,
}) => {
  const [selectedActivity, setSelectedActivity] = useState(
    {},
  );
  const [actions, setActions] = useState([]);

  const params = useParams();

  const [wizardState, dispatch] = useWizardContext();

  const { type, outputSources, globalSettings } =
    wizardState;

  useEffect(() => {
    getClubActivitiesReq(params.id);
  }, []);

  const getActivityData = (id) => {
    const result = clubActivitiesData?.data?.find(
      (activity) => activity.id === id,
    );

    return result;
  };

  const getActionData = (actionId, activityData) => {
    return activityData?.actions.find(
      (action) => action.id === actionId,
    );
  };

  const outputSourcesWithActivityData = outputSources.map(
    (source) => {
      const activityData = getActivityData(
        source.activityId,
      );

      return {
        ...source,
        activityData,
        actionData: getActionData(
          source.actionId,
          activityData,
        ),
      };
    },
  );

  useEffect(() => {
    if (outputSourcesWithActivityData?.length > 0) {
      outputSourcesWithActivityData.forEach(
        (source, idx) => {
          setSelectedActivity((prevState) => ({
            ...prevState,
            [idx]: source.activityData?.actions,
          }));
        },
      );
    }
  }, []);

  const { sources } = outputParams[type];

  const handleGlobalSettingsChange = (e) => {
    const { name, checked } = e.target;

    dispatch({
      type: WIZARD_CONSTANTS.GLOBAL_SETTINGS_CHANGE,
      payload: {
        [name]: checked,
      },
    });
  };

  const handleOutputSourceChange = (e, i) => {
    const { name, value } = e.target;

    dispatch({
      type: WIZARD_CONSTANTS.HANDLE_SOURCE_CHANGE,
      payload: {
        name,
        value,
        index: i,
      },
    });
  };

  const handleSelectActivity = (e, idx) => {
    const { value: activityId } = e.target;

    const result = clubActivitiesData?.data.find(
      (activity) => activity.id === activityId,
    );

    setSelectedActivity((prevState) => ({
      ...prevState,
      [idx]: result.actions,
    }));

    handleOutputSourceChange(e, idx);
  };

  const addNewOutputSourceHandler = () => {
    dispatch({
      type: WIZARD_CONSTANTS.ADD_NEW_OUTPUT_SOURCE,
    });
  };

  const removeOutputSource = (index) => {
    dispatch({
      type: WIZARD_CONSTANTS.REMOVE_OUTPUT_SOURCE,
      payload: {
        indexToRemove: index,
      },
    });
  };

  const isOutputSourceAvailable =
    outputSources.length > 0 &&
    outputSources[0].activityId &&
    outputSources[0].actionId &&
    outputSources[0].name;

  const addBtnText = `${getLabelByName(
    ADD,
  )} ${getLabelByName(
    ANOTHER,
  ).toLowerCase()} ${getLabelByName(SOURCE).toLowerCase()}`;

  return (
    <>
      <div className="output-sources-section px-5 mt-3 min-height-500">
        <div className="output-global-settings">
          <div className="enable-search">
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {`${getLabelByName(
                  GLOBAL,
                )} ${getLabelByName(SETTINGS)}`}
              </FormLabel>

              <FormGroup>
                {outputParams[type]?.general_settings?.map(
                  (setting) => (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            globalSettings[setting.key] ||
                            false
                          }
                          onChange={
                            handleGlobalSettingsChange
                          }
                          name={setting.key}
                          color="primary"
                        />
                      }
                      label={setting.name}
                    />
                  ),
                )}
              </FormGroup>
            </FormControl>
          </div>
        </div>

        <div className="output-sources-description">
          <p>
            {getLabelByName(
              CHOOSE_OUTPUT_SOURCE_DESCRIPTION,
            )}
          </p>
        </div>

        {outputSources.map((outputSource, index) => (
          <div
            key={outputSource.name + index}
            className="output-sources-details">
            <Grid container spacing={3}>
              {/* ============ NAME ============ */}
              <Grid item sm xs>
                <FormControl
                  variant="outlined"
                  className="create-output-visibility">
                  <InputLabel id="demo-simple-select-outlined-label">
                    {getLabelByName(NAME)}
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={(e) =>
                      handleOutputSourceChange(e, index)
                    }
                    className="text-capitalize"
                    name="name"
                    value={outputSource.name || ""}
                    label="Name">
                    {sources.map((src, srcIndex) => (
                      <MenuItem
                        selected={outputSource.name === src}
                        className="text-capitalize"
                        disabled={outputSource.name === src}
                        key={srcIndex}
                        value={src}>
                        {src}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* ============ NAME ============ */}

              {/* ============ ACTIVITY ============ */}
              <Grid item sm xs>
                <FormControl
                  variant="outlined"
                  className="create-output-visibility">
                  <InputLabel id="demo-simple-select-outlined-label">
                    {getLabelByName(ACTIVITY)}
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={(e) =>
                      handleSelectActivity(e, index)
                    }
                    name="activityId"
                    value={outputSource.activityId || ""}
                    label="Activity">
                    {clubActivitiesData?.data?.length > 0 &&
                      clubActivitiesData?.data?.map(
                        (activity, idx) => (
                          <MenuItem
                            selected={activity.name}
                            key={idx}
                            value={activity.id}>
                            {activity.name}
                          </MenuItem>
                        ),
                      )}
                  </Select>
                </FormControl>
              </Grid>
              {/* ============ ACTIVITY ============ */}

              {/* ============ ACTION ============ */}
              <Grid item sm xs className="action-cancel">
                <FormControl
                  variant="outlined"
                  className="create-output-visibility">
                  <InputLabel id="demo-simple-select-outlined-label">
                    {getLabelByName(ACTION)}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={(e) =>
                      handleOutputSourceChange(e, index)
                    }
                    value={outputSource.actionId || ""}
                    name="actionId"
                    label="Action">
                    {(selectedActivity[index]?.length > 0 &&
                      selectedActivity[index]?.map(
                        (action, i) => (
                          <MenuItem
                            selected={action.name}
                            key={i}
                            value={action.id}>
                            {action.name}
                          </MenuItem>
                        ),
                      )) || (
                      <MenuItem value="">
                        <em>{getLabelByName(NONE)}</em>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
                {/* ============ ACTION ============ */}

                <DeleteOutlineIcon
                  className="cancel-button"
                  onClick={() => removeOutputSource(index)}
                />
              </Grid>
            </Grid>
          </div>
        ))}

        <div className="add-another-source">
          <AddCircleOutlineOutlinedIcon />
          <button
            type="button"
            onClick={addNewOutputSourceHandler}>
            {addBtnText}
          </button>
        </div>
      </div>

      <div>
        <hr className="border-separator" />
        <div className="create-outputs-buttons d-flex justify-content-end mb-3">
          <input
            type="button"
            value="Cancel"
            className="cancel-button"
            onClick={closeModal}
          />
          <input
            type="button"
            className={`${
              isOutputSourceAvailable
                ? "next-button"
                : "next-button disabled"
            }`}
            value="Next"
            onClick={() => {
              return isOutputSourceAvailable
                ? nextStep()
                : undefined;
            }}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  clubActivitiesData:
    state.clubCampaignsReducer.clubCampaignsData,
});

const mapDispatchToProps = {
  getClubActivitiesReq: getClubActivities,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OutputSettingsForm);
