/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import PropTypes from "prop-types";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Add, ArrowForwardIos } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { compose } from "redux";
import {
  ALL_WORD,
  NO_PARTICIPANTS,
  PARTICIPANTS,
  PARTICIPANT,
  VIEW,
  VIEW_ALL,
  ADD_NEW_PARTICIPANT,
  ANONYMOUS,
} from "../../../../helpers/constants";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import "./activityParticipants.css";

export class ActivityParticipants extends Component {
  UNSAFE_componentWillMount() {
    const { activityData, getParticipants: getParticipantsAction } = this.props;

    if (activityData?.id) {
      getParticipantsAction(activityData.id);
    }
  }

  render() {
    const { participantsData, match, activityData } = this.props;

    return (
      <div className="activityParticipants">
        <div className="headerTitle">
          <h1>
            {getLabelByName(PARTICIPANTS)}
            <span style={{ color: "#A3A3A3", paddingLeft: "3px" }}>
              ({participantsData?.data?.length})
            </span>
          </h1>
          {match.path === "/manage/activities/:id/:handle" && (
            <span>
              {!activityData?.data?.settings?.anonymous && (
                <Link
                  to={`/manage/activities/${match.params.id}/${match.params.handle}/participants`}
                  style={{ color: "#C6A177" }}
                >
                  {getLabelByName(VIEW_ALL)}
                  <ArrowForwardIos />
                </Link>
              )}
            </span>
          )}
        </div>
        <div className="body">
          <div className="participantsDetails">
            {participantsData?.data?.length > 0 ? (
              <>
                {activityData?.data?.settings?.anonymous === true && (
                  <p>{getLabelByName(ANONYMOUS)}</p>
                )}
                {activityData?.data?.settings?.anonymous === false &&
                  participantsData?.data
                    ?.slice(0, 10)
                    .map((participant, index) => (
                      <Link
                        to={`/manage/activities/${match.params.id}/${match.params.handle}/participants/${participant.id}`}
                        style={{ color: "unset", textDecoration: "none" }}
                      >
                        <div className="participantItem" key={index}>
                          <div className="avatar">
                            {(participant.user?.profile?.picture && (
                              <img
                                src={participant.user?.profile?.picture}
                                alt=""
                              />
                            )) || (
                              <h1>
                                {participant.user?.profile?.firstName[0] +
                                  participant.user?.profile?.lastName[0]}
                              </h1>
                            )}
                          </div>
                          <h1>{participant.user?.profile?.firstName} </h1>
                        </div>
                      </Link>
                    ))}
              </>
            ) : (
              <p>{getLabelByName(NO_PARTICIPANTS)}</p>
            )}
          </div>
        </div>
        <div className="footer">
          {match.path === "/manage/activities/:id/:handle" && (
            <Button
              style={{ textTransform: "none" }}
              color="primary"
              onClick={() => this.props.SetIsModalOpen()}
            >
              <Add />
              {getLabelByName(ADD_NEW_PARTICIPANT)}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

ActivityParticipants.propTypes = {
  getParticipants: PropTypes.func,
  participantsData: PropTypes.object,
  activityData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  participantsData: state.getParticipantsReducer.getParticipantsData,
});

const mapDispatchToProps = {};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ActivityParticipants);
