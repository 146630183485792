/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment";
import { toast } from "react-toastify";
import { createPromo } from "../../../redux/actions/postActions";
import {
  getLabelByName,
  getProfileDataFromLocalStorage,
  isActionError,
  isActionSuccess,
} from "../../../helpers/helperFunctions";
import {
  CAPTION,
  PROMOTE,
  PROMOTE_FORM_DESCRIPTION,
  SUBMIT,
  SUMMARY,
  WRITE,
  CALL_TO_ACTION,
} from "../../../helpers/constants";
import ClubInformationSection from "./ClubInformationSection";
import InputCardSection from "./InputCardSection";
import UploadCardSection from "./UploadCardSection";
import TimelineCardSection from "./TimelineCardSection";

class PromotionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileData: getProfileDataFromLocalStorage(),
      startDate: null,
      endDate: null,
      caption: "",
      summary: "",
      callToAction: "",
      coverImage: "",
      hideCaption: false,
      hideSummary: false,
      hideCTA: false,
      isSubmitting: false,
      actionType: "",
      actionTypeOptions: ["Join", "Visit"],
      inputLimits: {
        caption: 60,
        summary: 160,
        callToAction: 32,
      },
    };
  }

  render() {
    const {
      resourceType,
      selectedResource,
      setPageTracking,
    } = this.props;
    const {
      caption,
      summary,
      callToAction,
      coverImage,
      hideCTA,
      actionTypeOptions,
      inputLimits,
      hideCaption,
      hideSummary,
      startDate,
      endDate,
      isSubmitting,
      actionType,
    } = this.state;

    return (
      <>
        <form onSubmit={(e) => this.submitPromotion(e)}>
          <div className="create-article-header">
            <div className="create-article-title">
              <div className="article-title">{`${getLabelByName(
                PROMOTE,
              )} ${getLabelByName(resourceType)}`}</div>
              <div className="article-helper">
                {getLabelByName(PROMOTE_FORM_DESCRIPTION)}
              </div>
            </div>

            <div>
              <div className="edit-button">
                <button
                  type="submit"
                  className="btn btn-lg submit-btn"
                  disabled={isSubmitting}>
                  {getLabelByName(SUBMIT)}
                </button>
              </div>
            </div>
          </div>

          <ClubInformationSection
            resourceType={this.props.resourceType}
            selectedResource={selectedResource}
            setPageTracking={setPageTracking}
          />
          <TimelineCardSection
            handleChange={this.handleChange}
            endDate={endDate}
            startDate={startDate}
          />
          <UploadCardSection
            imageUrl={coverImage}
            setImageUrl={this.setImageUrl}
          />
          <InputCardSection
            inputType="input"
            inputName="caption"
            title={getLabelByName(CAPTION)}
            input={caption}
            handleChange={this.handleChange}
            placeholder={`${getLabelByName(
              WRITE,
            )} ${getLabelByName(CAPTION).toLowerCase()}...`}
            showStatus={hideCaption}
            toggleShowStatus={this.toggleShowStatus}
            statusName="hideCaption"
            maxLength={inputLimits.caption}
          />
          <InputCardSection
            inputType="textarea"
            inputName="summary"
            title={getLabelByName(SUMMARY)}
            input={summary}
            handleChange={this.handleChange}
            placeholder={`${getLabelByName(
              WRITE,
            )} ${getLabelByName(SUMMARY).toLowerCase()}...`}
            showStatus={hideSummary}
            toggleShowStatus={this.toggleShowStatus}
            statusName="hideSummary"
            maxLength={inputLimits.summary}
          />
          <InputCardSection
            inputType="input"
            inputName="callToAction"
            title={getLabelByName(CALL_TO_ACTION)}
            input={callToAction}
            handleChange={this.handleChange}
            placeholder={`${getLabelByName(
              WRITE,
            )} ${getLabelByName(
              CALL_TO_ACTION,
            ).toLowerCase()}...`}
            showStatus={hideCTA}
            toggleShowStatus={this.toggleShowStatus}
            statusName="hideCTA"
            maxLength={inputLimits.callToAction}>
            <select
              className="form-control"
              id="action-type-dropdown"
              name="actionType"
              onChange={(e) => this.handleChange(e)}
              required>
              <option disabled selected>
                Select action type
              </option>
              {actionTypeOptions.map((option) => (
                <option
                  value={option}
                  selected={actionType === option}
                  disabled={
                    option === "Join" &&
                    resourceType === "article"
                  }>
                  {option}
                </option>
              ))}
            </select>
          </InputCardSection>
        </form>
      </>
    );
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      isActionSuccess(
        nextProps,
        "createPostStatus",
        "createPostData",
      )
    ) {
      this.props.history.push("/home");
    }

    if (
      isActionError(
        nextProps,
        "createPostStatus",
        "createPostData",
      )
    ) {
      this.setState({ isSubmitting: false });
      toast.error(
        "Error sumitting your promotion! \nTry again!",
      );
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  setImageUrl = (url) => this.setState({ coverImage: url });

  toggleShowStatus = (prop, value) => {
    this.setState({ [prop]: !value });
  };

  submitPromotion = async (e) => {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    const { selectedResource } = this.props;
    const {
      startDate,
      endDate,
      caption,
      summary,
      callToAction,
      actionType,
      hideCTA,
      hideCaption,
      hideSummary,
      coverImage,
      profileData,
      actionTypeOptions,
    } = this.state;

    const payload = {
      authorId: profileData.data.id,
      parentId: null,
      content: {
        startDate: startDate && moment(startDate).unix(),
        coverImage,
        caption,
        showCaption: !hideCaption,
        summary,
        showSummary: !hideSummary,
        callToAction,
        actionType: actionType || actionTypeOptions[0],
        showCTA: !hideCTA,
        typeId: selectedResource.id,
        name: selectedResource.name || null,
      },
      images: { image_1: coverImage },
      videos: {},
      files: {},
      status: "APPROVED",
    };

    if (endDate) {
      payload.content.endDate = moment(endDate).unix();
    }
    let resourceId;

    switch (this.props.resourceType) {
      case "club":
        resourceId = selectedResource.id;
        break;
      case "activity":
        resourceId = selectedResource.parentId;
        break;
      case "contribution":
        resourceId = selectedResource.clubId;
        break;
      case "article":
        resourceId = selectedResource.clubId;
        break;
      case "lesson":
        resourceId = selectedResource.clubId;
        break;
      case "output":
        resourceId = selectedResource.club.id;
        break;
      default:
        toast.error("Missing A PromoType");
        window.location.href = "/home";
        break;
    }

    await this.props.createPromo(
      payload,
      resourceId,
      "club",
      this.props.resourceType,
    );
  };
}

PromotionForm.propTypes = {
  createPromo: PropTypes.func,
  resourceType: PropTypes.any,
  selectedResource: PropTypes.any,
  setPageTracking: PropTypes.any,
  history: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  createPostData: state.createPostReducer.createPostData,
  createPostDataError:
    state.createPostReducer.createPostDataError,
  createPostStatus:
    state.createPostReducer.createPostStatus,
});
export default compose(
  withRouter,
  connect(mapStateToProps, { createPromo }),
)(PromotionForm);
