import React from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import './GlossaryCard.scss';

export const GlossaryCard = ({headerLabel, hasTitle, titleLabel, textLabel, updateGlossary, data, deleteGlossary}) => {

  const displayValue = data?.display ?? true

  return (
    <div className='GlossaryCard'>
        <div className='glossary-header'>
            <h1>{headerLabel || 'Section Header'}</h1>
            <span onClick={() => updateGlossary({display: !displayValue})}>
            { displayValue ? (
                <>
                    <VisibilityIcon sx={{fontSize: 40, color: '#f38f46'}}/>
                    <p className='show'>Reveal this Section</p>
                </> ) : (
                <>
                    <VisibilityOffIcon sx={{fontSize: 40, color: '#424242'}}/>
                    <p>Hide this Section</p>
            </>)}
            </span>
        </div>
        <div className='glossary-content'>
            {
                hasTitle && (
                    <>
                        <h3>{titleLabel || 'Section Title'}</h3>
                        <input
                            type="text"
                            name='title'
                            placeholder="Write something"
                            onChange={(e) => updateGlossary({title: e.target.value})}
                            value={data?.title || ''}
                        />
                    </>
                )
            }
            <h3>{textLabel || 'Section Text'}</h3>
            <textarea
                name='message'
                placeholder="Write something"
                onChange={(e) => updateGlossary({text: e.target.value})}
                value={data?.text || ''}
            />
            {
                deleteGlossary && (
                    <div className='button-block'>
                        <button onClick={deleteGlossary}>Delete</button>
                    </div>
                )
            }
        </div>
    </div>
  )
}
