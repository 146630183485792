/* eslint-disable prefer-destructuring */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Fallback from './Fallback';
import { getLanding } from '../redux/actions/landingActions';
import logo from '../assets/logo-opac.svg';
import socialEmail from '../assets/icons/social-email.svg';
import socialInstagram from '../assets/icons/social-instagram.svg';
import socialLinkedin from '../assets/icons/social-linkedin.svg';
import socialFacebook from '../assets/icons/social-facebook.svg';
import socialTwitter from '../assets/icons/social-twitter.svg';
import copyRightImg from '../assets/icons/copyright.svg';
import { getLabelByName } from '../helpers/helperFunctions';
import {
  HOME, PRIVACY_POLICY, SUPPORT, TERMS_CONDITIONS,
} from '../helpers/constants';

export class Footer extends Component {
  state = {};

  async UNSAFE_componentWillMount() {
    await this.props.getLanding();
  }

  render() {
    const {
      landingData, landingDataError,
    } = this.props;
    if (landingDataError || (landingData && landingData.status === '500')) {
      return <Fallback route="/" />;
    }

    let landingInfo = null;
    if (landingData && landingData.data) {
      landingInfo = landingData.data.filter((x) => x.name === 'landing-page')[0];
    }
    let linksInfo = null;
    if (landingData && landingData.data) {
      linksInfo = landingData.data.filter((x) => x.name === 'links')[0];
    }
    const copyrightText = landingInfo && (landingInfo.data['copyright-text'] || 'Copyright 2020 SOSO Cameroon. All Rights Reserved.');

    return (
      <>
        <div className="row d-flex justify-content-between align-items-center links-row" style={{marginTop: "134px !important"}}>
          <div className="col-lg-2 col-md-12 mini-logo">
            <img
              src={
                landingInfo && landingInfo.data && landingInfo.data['logo']
                  ? landingInfo.data['logo']
                  : logo
              }
              alt="logo"
              className="logo"
            />
          </div>
          <div className="col-lg-6 col-md-12 links d-flex justify-content-between">
            <Link className="links-text font-weight-bold" to="/">
              {getLabelByName(HOME)}
            </Link>
            <Link className="links-text" to="/privacy">
              {getLabelByName(PRIVACY_POLICY)}
            </Link>
            <Link className="links-text" to="/terms">
              {getLabelByName(TERMS_CONDITIONS)}
            </Link>
            <Link className="links-text" to="/">
              {getLabelByName(SUPPORT)}
            </Link>
          </div>

          <div className="col-lg-1 col-md-12" />

          <div className="col-lg-3 col-md-12 social-links d-flex justify-content-around">
            <a href={`mailto:${linksInfo && linksInfo.data.email}`} className="p-2">
              <img src={socialEmail} alt="socialEmail" style={{ width: '35%' }} />
            </a>
            <a href={linksInfo && linksInfo.data.instagram} className="p-2">
              <img src={socialInstagram} alt="socialInstagram" style={{ width: '35%' }} />
            </a>
            <a href={linksInfo && linksInfo.data.linkedin} className="p-2">
              <img src={socialLinkedin} alt="socialLinkedin" style={{ width: '35%' }} />
            </a>
            <a href={linksInfo && linksInfo.data.facebook} className="p-2">
              <img src={socialFacebook} alt="socialFacebook" style={{ width: '35%' }} />
            </a>
            <a href={linksInfo && linksInfo.data.twitter} className="p-2">
              <img src={socialTwitter} alt="socialTwitter" style={{ width: '35%' }} />
            </a>
          </div>
        </div>

        <footer className="d-flex align-items-center">
          <div className="mx-auto footer-text font-weight-bold">
            <img src={copyRightImg} alt="copyright" width="20px" />
            {' '}
            {copyrightText}
          </div>
        </footer>
      </>
    );
  }
}

Footer.propTypes = {
  getLanding: PropTypes.func.isRequired,
  landingDataError: PropTypes.object,
  landingData: PropTypes.object,
};

export const mapStateToProps = (state) => ({
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { getLanding }),
)(Footer);
