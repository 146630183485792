import React, {useState} from "react";
import imagePlaceholder from "../../../assets/icons/image-placeholder.svg";
import { getLabelByName } from "../../../helpers/helperFunctions";
import {
  IMAGE,
  SELECT_FROM_COMPUTER,
  UPLOAD,
  UPLOADING,
} from "../../../helpers/constants";

const FileUploadInput = (props) => {
    const { settings, currentValue, participantAction, handleUpdate, isPreviewMode } = props;

    const [isUploading, setIsUploading] = useState(false)

    const uploader = async (selectedFile) => {
        setIsUploading(true);
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("upload_preset", "qtt2g8ao");

        try {
            const responseJson = await fetch("https://api.Cloudinary.com/v1_1/ayudigital/image/upload", {
                method: "POST",
                body: formData
            })
            const response = await responseJson.json()
            handleUpdate({[settings.input_key]: response.secure_url});
        } catch (error) {
            console.log("Error uploading the picture!\nPlease try again.")
        } finally {
            setIsUploading(false)
        }
    };


    const handleSingleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        uploader(selectedFile)
    }

    return (<>
        <p>{settings.label}</p>
        <div className="card section-card image-card">
            <div className="card-body">
                <div className="card-details">
                <h5 className="card-title">
                    {settings?.label}{" "}
                    {settings?.required && <span className="asterisk">(required)</span>}
                </h5>
                <p className="helper-text">
                    {`${getLabelByName(UPLOAD)} ${getLabelByName(IMAGE)}`}
                </p>
                <br />
                <p className="helper-text font-italic">
                    Formats: png, jpeg, jpg and svg
                </p>
                <div className="upload-file-div">
                    <div className="custom-input">
                    <input
                        type="file"
                        className="custom-file-inputz mb-5"
                        id={`customFile - ${settings.input_key}`}
                        onChange={handleSingleFileChange}
                        required={settings.required}
                        accept="image/*"
                        disabled={isPreviewMode}
                    />
                    </div>
                    <div className="upload-custom-file">
                    <label htmlFor={`customFile - ${settings.input_key}`}>
                        {isUploading
                        ? `${getLabelByName(UPLOADING)}...`
                        : getLabelByName(SELECT_FROM_COMPUTER)}
                    </label>
                    </div>
                </div>
                </div>
                <div className="card-image-preview">
                <img src={currentValue || imagePlaceholder} alt="card-preview" />
                </div>
            </div>
        </div>
    </>)
}

export default FileUploadInput;