/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import OutputCard from "./OutputCard";

class PromotedResource extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      outputsData,
      activitiesData,
      clubsData,
      postsData,
    } = this.props;
    return (
      <div className="outputs-row d-flex">
        {outputsData?.map((output) => (
          <OutputCard key={output.id} category="outputs" output={output} />
        ))}

        {activitiesData?.map((output) => (
          <OutputCard
            category="activities"
            output={output}
          />
        ))}

        {clubsData?.map((output) => (
          <OutputCard category="clubs" output={output} />
        ))}

        {postsData?.map((output) => (
          <OutputCard category="posts" output={output} />
        ))}
      </div>
    );
  }
}

export default PromotedResource;
