import {
  SEARCH_CAMPAIGNS_DESCRIPTION_ERROR,
  SEARCH_CAMPAIGNS_DESCRIPTION_SUCCESS,
} from '../actions/types';

const initialState = {
  searchCampaignsDescriptionData: null,
  searchCampaignsDescriptionDataError: null,
  searchCampaignsDescriptionStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_CAMPAIGNS_DESCRIPTION_SUCCESS:
      return {
        ...state,
        searchCampaignsDescriptionData: action.payload,
        searchCampaignsDescriptionDataError: null,
        searchCampaignsDescriptionStatus: 'success',
      };
    case SEARCH_CAMPAIGNS_DESCRIPTION_ERROR:
      return {
        ...state,
        searchCampaignsDescriptionDataError: action.payload,
        searchCampaignsDescriptionData: null,
        searchCampaignsDescriptionStatus: 'error',
      };
    default:
      return {
        ...state,
        searchCampaignsDescriptionStatus: null,
      };
  }
};
