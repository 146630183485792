/* eslint-disable global-require */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { CheckCircle } from "@material-ui/icons";
import { colors } from "../../../helpers/constants";

export class ResourceItemCard extends Component {
  state = {};

  ContributionTemplate = (contribution) => {
    if (contribution.actionId === 1) {
      return (
        <div className="post-author-name">
          {contribution.data.author_name}
        </div>
      );
    }
    return (
      <div className="post-author-name">
        <h6>{contribution.data.publication_title}</h6>
        <p>
          by{" "}
          <span
            style={{
              color: "#F38F46",
              fontWeight: "lighter",
            }}>
            {contribution.data.publication_author ===
            undefined
              ? "unknown"
              : contribution.data.publication_author}
          </span>
        </p>
      </div>
    );
  };

  displayResourceTemplate = () => {
    let template;
    const { resource, resourceType } = this.props;
    switch (resourceType) {
      case "contribution":
        template = (
          <div className="post-item-author pb-0">
            <div className="post-avatar">
              <img
                className="img-fluid"
                src={
                  resource.data.author_profile_pic ||
                  require("../../../assets/contributions-dark.svg")
                }
                alt="contribution"
              />
            </div>
            {this.ContributionTemplate(resource)}
          </div>
        );
        break;

      case "article":
        template = (
          <div className="post-item-author pb-0">
            <div className="post-avatar">
              <img
                className="img-fluid"
                src={
                  resource?.images.image_1 ||
                  require("../../../assets/icons/club-dark.svg")
                }
                alt="notification-avatar"
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                  padding:
                    !resource?.images.image_1 && "0.5rem",
                }}
              />
            </div>
            <div className="post-author-name">
              {resource.content.title ||
                resource.content.body}
              <br />
            </div>
          </div>
        );
        break;

      case "lesson":
        template = (
          <div className="post-item-author pb-0">
            <div className="post-avatar">
              <img
                className="img-fluid"
                src={
                  resource?.images.image_1 ||
                  require("../../../assets/icons/club-dark.svg")
                }
                alt="notification-avatar"
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                  padding:
                    !resource?.images.image_1 && "0.5rem",
                }}
              />
            </div>
            <div className="post-author-name">
              {resource.content.title ||
                resource.content.body}
              <br />
            </div>
          </div>
        );
        break;

      default:
        template = (
          <div className="post-item-author pb-0">
            <div className="post-avatar">
              <img
                className="img-fluid"
                src={
                  resource?.profile?.picture ||
                  require("../../../assets/icons/club-dark.svg")
                }
                alt="notification-avatar"
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                  padding:
                    !resource?.profile?.picture && "0.5rem",
                }}
              />
            </div>
            <div className="post-author-name">
              {resource.name}
              <br />
            </div>
          </div>
        );
        break;
    }
    return template;
  };

  render() {
    const { resource, selectedResource, selectResource } =
      this.props;

    return (
      <div
        id={resource.id}
        className="card activity-card"
        style={{
          cursor: "pointer",
          borderColor:
            selectedResource &&
            selectedResource.id === resource.id &&
            colors.primaryColor,
        }}
        onClick={() => selectResource(resource)}>
        <div className="card-body">
          <div className="activity-content-row">
            <div className="activity-content">
              <div className="d-flex justify-content-between align-items-center">
                {this.displayResourceTemplate()}
                <div>
                  {selectedResource &&
                    selectedResource.id === resource.id && (
                      <CheckCircle
                        style={{
                          color: colors.primaryColor,
                          width: "30px",
                        }}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ResourceItemCard.propTypes = {
  resource: PropTypes.any,
  selectedResource: PropTypes.any,
  resourceType: PropTypes.any,
  selectResource: PropTypes.func,
};

export default ResourceItemCard;
