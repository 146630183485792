/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  TextField,
  FormControl,
  InputLabel,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Button,
  IconButton,
} from "@material-ui/core";
import userIcon from "../../../assets/manage-users.svg";
import {
  CANCEL,
  CITY,
  COUNTRY,
  CREATE,
  CREATED_SUCCESSFULLY,
  EDIT,
  ERROR_PROCESSING_REQUEST,
  ERROR_UPLOADING_PICTURE,
  SAVE,
  SELECT_FROM_COMPUTER,
  UPDATED_SUCCESSFULLY,
  ADD_MEMBER_DESCRIPTION,
  EDIT_MEMBER_DESCRIPTION,
  EMAIL,
  FEMALE,
  FIRSTNAME,
  GENDER,
  LASTNAME,
  MALE,
  OTHER,
  USER,
  ROLE,
  userRoles as rolesList,
  SELECT_USER_STATUS,
} from "../../../helpers/constants";
import { getAllClubsFromLocalStorage, getLabelByName } from "../../../helpers/helperFunctions";
import FormStyles from "./main.styles";
import Loader from "../../Loader";
import cameraOutline from "../../../assets/icons/cameraOutline.svg";
import { editProfile } from "../../../redux/actions/editProfileActions";
import { signupAction } from "../../../redux/actions/authActions";
import reduxStore from "../../../redux/store";
import { CREATE_POST_ERROR } from "../../../redux/actions/types";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const initialState = {
  firstName: null,
  lastName: null,
  username: null,
  gender: "male",
  roles: [],
  location_city: null,
  location_country: null,
  status: "ACTIVE",
  statusClicked: null,
  selectedFile: null,
  imagePreview: null,

  isSending: false,
};

const AddUserModal = ({
  isCreateModalVisible,
  changeIsCreateModal,
  signupAction: createUserAction,
  editProfile: updateUserAction,
  editUser,
  getUsers,
  createUserStatus,
  userEditStatus,
  landingData,
}) => {
  const classes = FormStyles();
  const [data, setdata] = useState({ ...initialState });
  const [clubsData, setClubsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const mobile = useMediaQuery("(max-width:768px)");

  const handleRadio = (e) => {
    setdata({
      ...data,
      status: e.target.value,
      statusClicked: "clicked",
    });
  };

  const genders = [
    { index: 0, name: getLabelByName(MALE), value: MALE },
    {
      index: 1,
      name: getLabelByName(FEMALE),
      value: FEMALE,
    },
    { index: 2, name: getLabelByName(OTHER), value: OTHER },
  ];

  let countriesInfo = null;
  if (landingData && landingData.data) {
    countriesInfo = landingData.data.filter(
      (x) => x.name === "Countries List",
    )[0].data;
  }
  let countriesList = [];

  if (countriesInfo) {
    countriesList = Object.keys(countriesInfo).map(
      (item, index) => ({
        value: item,
        name: Object.values(countriesInfo)[index],
      }),
    );
  }
  countriesList.sort((a, b) =>
    a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setdata({
      ...data,
      [name]: value,
    });
  };

  const fileChange = (e) => {
    const blob = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setdata({
        ...data,
        selectedFile: blob,
        imagePreview: fileReader.result,
      });
    };
    fileReader.readAsDataURL(e.target.files[0]);
  };

  const getInitialRoles = () => {
    const initialRoles = [];
    const tempRoles = editUser?.roles?.forEach((role) => {
      const selectedRole = rolesList.find(
        (roleItem) => roleItem.value === role,
      );
      initialRoles.push(selectedRole);
    });
    return initialRoles;
  };

  const submitForm = async (e, _editUser) => {
    setIsLoading(true);
    e.preventDefault();
    const { selectedFile } = data;

    if (!selectedFile) {
      const payload = {
        ...data,
        emailAddress: data.username,
        password: Math.random().toString(36).slice(-8),
        extra: [],
        permissions: {},
        settings: {},
        interests: {},
      };
      delete payload.imagePreview;
      delete payload.statusClicked;
      delete payload.selectedFile;

      if (!editUser) {
        await createUserAction(payload, "CREATE_USER");
      } else {
        const updatedPayload = {
          //   ...payload,
          status: data.status || editUser.status,
          profile: {
            firstName:
              data.firstName || editUser.profile.firstName,
            lastName:
              data.lastName || editUser.profile.lastName,
            gender: data.gender || editUser.profile.gender,
            location_city:
              data.location_city ||
              editUser.profile.location_city,
            location_country:
              data.location_country ||
              editUser.profile.location_country,
            picture: editUser.profile.picture || "",
          },
          roles: data.roles || editUser.roles,
          username: data.username || editUser.username,
          permissions: {},
          settings: {},
          interests: {},
        };
        delete updatedPayload.firstName;
        delete updatedPayload.lastName;
        delete updatedPayload.gender;
        delete updatedPayload.location_city;
        delete updatedPayload.location_country;
        await updateUserAction(updatedPayload, editUser.id);
      }
    }

    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("upload_preset", "qtt2g8ao");
      const options = {
        method: "POST",
        body: formData,
      };
      fetch(
        "https://api.Cloudinary.com/v1_1/ayudigital/image/upload",
        options,
      )
        .then((res) => res.json())
        .then(async (res) => {
          const { secure_url } = res;
          const payload = {
            ...data,
            emailAddress: data.username,
            password: Math.random().toString(36).slice(-8),
            picture: secure_url,
            extra: [],
            permissions: {},
            settings: {},
            interests: {},
          };
          delete payload.imagePreview;
          delete payload.statusClicked;
          delete payload.selectedFile;

          if (!editUser) {
            await createUserAction(payload, "CREATE_USER");
          } else {
            const updatedPayload = {
              //   ...payload,
              status: data.status || editUser.status,
              profile: {
                firstName:
                  data.firstName ||
                  editUser.profile.firstName,
                lastName:
                  data.lastName ||
                  editUser.profile.lastName,
                gender:
                  data.gender || editUser.profile.gender,
                location_city:
                  data.location_city ||
                  editUser.profile.location_city,
                location_country:
                  data.location_country ||
                  editUser.profile.location_country,
                picture: secure_url,
              },
              roles: data.roles || editUser.roles,
              username: data.username || editUser.username,
              permissions: {},
              settings: {},
              interests: {},
            };
            delete updatedPayload.firstName;
            delete updatedPayload.lastName;
            delete updatedPayload.gender;
            delete updatedPayload.location_city;
            delete updatedPayload.location_country;
            await updateUserAction(
              updatedPayload,
              editUser.id,
            );
          }
        })
        .catch(() => {
          toast.error(
            getLabelByName(ERROR_UPLOADING_PICTURE),
          );
          setdata({ ...data, isSending: false });
        });
    }
  };

  const {
    status,
    imagePreview,
    username,
    firstName,
    gender,
    lastName,
    location_city,
    location_country,
    roles,
  } = data;

  const statuses = [
    { index: 0, text: "Active", value: "ACTIVE" },
    { index: 1, text: "Inactive", value: "INACTIVE" },
  ];

  let previewToDisplay = userIcon;
  if (!imagePreview && editUser?.profile?.picture) {
    previewToDisplay = editUser?.profile?.picture;
  }
  if (imagePreview) {
    previewToDisplay = imagePreview;
  }

  const handleRoleChange = (newValues) => {
    const newRoles = newValues.map((role) => role.value);
    setdata({
      ...data,
      roles: newRoles,
    });
  };

  useEffect(() => {
    if (editUser !== null) {
      setdata({
        ...data,
        ...editUser,
        ...editUser.profile,
      });
    }
  }, [editUser]);
  useEffect(() => {
    setClubsData(
      getAllClubsFromLocalStorage(),
    );
  }, []);
  useEffect(() => {
    if (
      isCreateModalVisible === false &&
      editUser === null
    ) {
      setdata(initialState);
    }
  }, [isCreateModalVisible]);

  useEffect(() => {
    if (userEditStatus === "success") {
      getUsers();
      toast.success(getLabelByName(UPDATED_SUCCESSFULLY));
      changeIsCreateModal();
    }
    if (userEditStatus === "error") {
      toast.error(getLabelByName(ERROR_PROCESSING_REQUEST));
    }

    if (createUserStatus === "success") {
      getUsers();
      toast.success(getLabelByName(CREATED_SUCCESSFULLY));
      changeIsCreateModal();
      reduxStore.dispatch({
        type: CREATE_POST_ERROR,
        payload: null,
      });
    }
    if (createUserStatus === "error") {
      toast.error(getLabelByName(ERROR_PROCESSING_REQUEST));
    }
    setIsLoading(false);
  }, [createUserStatus, userEditStatus]);

  return (
    <Modal
      size="lg"
      show={isCreateModalVisible}
      onHide={changeIsCreateModal}>
      <div>
        <div
          style={{
            padding: mobile
              ? "1rem 1rem 0 1rem"
              : "1rem 2rem 0 2rem",
            font: ".8rem",
          }}>
          <div>
            <h2>
              {editUser
                ? getLabelByName(EDIT)
                : getLabelByName(CREATE)}{" "}
              {getLabelByName(USER)}
            </h2>
            <p>
              {editUser
                ? getLabelByName(EDIT_MEMBER_DESCRIPTION)
                : getLabelByName(ADD_MEMBER_DESCRIPTION)}
              .
            </p>
          </div>
        </div>
        <div
          style={{
            padding: mobile
              ? "0 1rem 1rem 1rem"
              : "0 2rem 1rem 2rem",
          }}>
          <form
            id="form"
            className={classes.form}
            onSubmit={(e) => submitForm(e, editUser)}>
            <FormControl
              className={
                mobile ? classes.input : classes.inputLeft50
              }
              margin="normal"
              fullWidth
              variant="outlined">
              <TextField
                id="firstName"
                label={getLabelByName(FIRSTNAME)}
                name="firstName"
                variant="outlined"
                onChange={handleChange}
                value={firstName}
                required
              />
            </FormControl>
            <FormControl
              className={
                mobile
                  ? classes.input
                  : classes.inputRight50
              }
              margin="normal"
              fullWidth
              variant="outlined">
              <TextField
                id="lastName"
                label={getLabelByName(LASTNAME)}
                name="lastName"
                variant="outlined"
                onChange={handleChange}
                value={lastName}
                required
              />
            </FormControl>
            <FormControl
              className={
                mobile ? classes.input : classes.inputLeft50
              }
              margin="normal"
              fullWidth
              variant="outlined">
              <TextField
                id="username"
                label={getLabelByName(EMAIL)}
                name="username"
                variant="outlined"
                onChange={handleChange}
                value={username}
                required
              />
            </FormControl>
            <FormControl
              className={
                mobile
                  ? classes.input
                  : classes.inputRight50
              }
              margin="normal"
              variant="outlined">
              <InputLabel id="gender">
                {getLabelByName(GENDER)}
              </InputLabel>
              <Select
                labelId="gender"
                id="gender"
                required
                value={gender}
                onChange={(e) =>
                  setdata({
                    ...data,
                    gender: e.target.value,
                  })
                }
                label={getLabelByName(GENDER)}>
                {genders.map((option) => (
                  <MenuItem
                    value={option.value}
                    selected={
                      editUser?.profile?.gender ===
                      option.value
                    }>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className={
                mobile ? classes.input : classes.inputLeft50
              }
              margin="normal"
              fullWidth
              variant="outlined">
              <TextField
                id="location_city"
                label={getLabelByName(CITY)}
                name="location_city"
                variant="outlined"
                onChange={handleChange}
                value={location_city}
                required
              />
            </FormControl>
            <FormControl
              className={
                mobile
                  ? classes.input
                  : classes.inputRight50
              }
              margin="normal"
              variant="outlined">
              <InputLabel id="country">
                {getLabelByName(COUNTRY)}
              </InputLabel>
              <Select
                labelId="country"
                id="country"
                required
                native={false}
                value={location_country || ""}
                onChange={(e) =>
                  setdata({
                    ...data,
                    location_country: e.target.value,
                  })
                }
                label={getLabelByName(COUNTRY)}>
                {countriesList.map((option) => (
                  <MenuItem
                    value={option.value}
                    selected={
                      location_country === option.value
                    }>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <FormControl
              className={mobile ? classes.input : classes.inputLeft50}
              margin="normal"
              variant="outlined"
            >
              <InputLabel id="roles">{getLabelByName(ROLE)}</InputLabel>
              <Select
                className="checkbox-menu allow-focus"
                labelId="roles"
                id="roles"
                required
                native={false}
                value={roles?.[0] || ""}
                label={getLabelByName(ROLE)}
              >
                {rolesList.map((roleOption) => (
                  <li>
                    <label>
                      <input
                        style={{
                          position: "unset",
                          marginRight: "0.5rem",
                          marginLeft: "0.5rem",
                        }}
                        type="checkbox"
                        value={roleOption.value}
                        onChange={handleChangeCheckbox}
                        checked={
                          roles.includes(roleOption.value) ||
                          editUser?.roles?.includes(roleOption.value)
                        }
                      />
                      {roleOption.text}
                    </label>
                  </li>
                ))}
              </Select>
            </FormControl> */}

            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={rolesList}
              disableCloseOnSelect
              onChange={(e, newValues) =>
                handleRoleChange(newValues)
              }
              getOptionLabel={(option) => option.text}
              defaultValue={getInitialRoles()}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.text}
                </>
              )}
              style={{ margin: "1rem 0" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={getLabelByName(ROLE)}
                  placeholder={getLabelByName(ROLE)}
                />
              )}
            />

            <FormControl
              className={
                mobile
                  ? classes.input
                  : classes.inputRight50
              }
              margin="normal"
              component="fieldset">
              <FormLabel component="legend">
                {getLabelByName(SELECT_USER_STATUS)}
              </FormLabel>
              <RadioGroup
                row
                aria-label="User-status"
                name="Status"
                onChange={handleRadio}>
                {statuses.map((statusItem) => (
                  <FormControlLabel
                    value={statusItem.value}
                    id={`check-${statusItem.value}`}
                    labelPlacement="end"
                    checked={status === statusItem.value}
                    control={<Radio color="primary" />}
                    label={statusItem.text}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            <input
              accept="image/*"
              className={classes.uploadInput}
              id="icon-button-file"
              onChange={(e) => fileChange(e)}
              type="file"
            />
            <label
              htmlFor="icon-button-file"
              style={{
                border: "dashed 1px #F38F46",
                backgroundColor: "#F7F4F2",
                padding: "10px",
                borderRadius: "5px",
                width: "100%",
              }}>
              <>
                {imagePreview ||
                editUser?.profile?.picture ? (
                  <img
                    src={previewToDisplay}
                    alt="header-logo"
                    style={{
                      margin: ".8rem",
                      width:
                        (imagePreview ||
                          editUser?.profile?.picture) &&
                        "25px",
                      height:
                        (imagePreview ||
                          editUser?.profile?.picture) &&
                        "25px",
                      objectFit:
                        (imagePreview ||
                          editUser?.profile?.picture) &&
                        "cover",
                      borderRadius:
                        (imagePreview ||
                          editUser?.profile?.picture) &&
                        "50%",
                    }}
                  />
                ) : (
                  <IconButton
                    aria-label="upload picture"
                    component="span">
                    <img
                      src={cameraOutline}
                      style={{ width: "20px" }}
                      alt="nav-icon"
                    />
                  </IconButton>
                )}
              </>
              {getLabelByName(SELECT_FROM_COMPUTER)}
            </label>
          </form>
        </div>
        <Modal.Footer className={classes.input}>
          <Button
            className={classes.button}
            color="primary"
            onClick={changeIsCreateModal}>
            {getLabelByName(CANCEL)}
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            form="form"
            type="submit">
            {isLoading ? <Loader /> : getLabelByName(SAVE)}
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

AddUserModal.propTypes = {
  isCreateModalVisible: PropTypes.bool.isRequired,
  changeIsCreateModal: PropTypes.func.isRequired,
  signupAction: PropTypes.func,
  editProfile: PropTypes.func,
  editUser: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  userEditDataError:
    state.editProfileReducer.userEditDataError,
  userEditData: state.editProfileReducer.userEditData,
  userEditStatus: state.editProfileReducer.userEditStatus,

  createUserDataError:
    state.createUserReducer.createUserDataError,
  createUserData: state.createUserReducer.createUserData,
  createUserStatus:
    state.createUserReducer.createUserStatus,

  landingData: state.landingReducer.landingData,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    signupAction,
    editProfile,
  }),
)(AddUserModal);
