/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/sort-comp */

import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { compose } from 'redux';

import SimpleTextInput from "./inputs/SimpleTextInput";
import FreeTextInput from "./inputs/FreeTextInput";
import DropDownInput from "./inputs/DropDownInput";
import RadioButtonsInput from "./inputs/RadioButtonsInput";
import CheckboxesInput from "./inputs/CheckboxesInput";
import MultipleChoiceInput from "./inputs/MultipleChoiceInput";
import DatePickerInput from "./inputs/DatePickerInput";
import FileUploadInput from "./inputs/FileUploadInput"
import DocumentUploadInput from "./inputs/DocUploadInput"

import { retrieveQuestionnaire } from "../../redux/actions/questionnaires/questionnaireActions";
import {
  createParticipantAction,
  updateParticipantAction,
  retrieveParticipantAction,
  deleteParticipantAction
} from "../../redux/actions/activities/participantActionActions";

import { createParticipantContribution } from "../../redux/actions/activities/contributionActions";
import './renderer.css'
import ConfirmationModal from '../notifications/ConfirmationModal.js'

const QuestionnaireRenderer = (props) => {
  const { activity, action, participant, questionnaire, participantActions, participantAction, isPreviewMode, contribution } = props;
  const { activityId, participantId, pageAction, participantActionId } = props.match.params;

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [submitType, setSubmitType] = useState("")
  const [confirmationModalSettings, setConfirmationModalSettings] = useState({
    title: "",
    description: "",
    button1Text: "",
    button1Handler: "",
    button2Text: "",
    button2Handler: "",
  });

  const inputRenderer = {
    SIMPLE_TEXT: SimpleTextInput,
    SHARE_LINK: SimpleTextInput,
    FREE_TEXT: FreeTextInput,
    DROPDOWN: DropDownInput,
    RADIOS: RadioButtonsInput,
    CHECKBOXES: CheckboxesInput,
    DATE_TIME: DatePickerInput,
    DATE_TIME_RANGE: DatePickerInput,
    FILE_UPLOAD: FileUploadInput,
    DOC_UPLOAD: DocumentUploadInput,
  };

  useEffect(() => {
    props.retrieveQuestionnaire(action?.meta?.questionnaireId);
  }, [])

  useEffect(() => {
    if (contribution.hasOwnProperty("error")) {
      setConfirmationModalSettings({
        title: "There's been an error",
        description: "We could not receive your submission at this time. Please resolve any errors and try again.",
        button1Text: "Continue",
        button1Handler: () => setOpenConfirmationModal(false),
        button2Text: "Return to Dashboard",
        button2Handler: () => {
          window.location.href = `/activity/${activity.id}/participant/${participant.id}/console`
        },
      });
    }

    if (contribution.id !== 0) {
      setConfirmationModalSettings({
        title: "Thank You",
        description: "Your submission has been received. Select an option to continue.",
        button1Text: "Continue",
        button1Handler: () => setOpenConfirmationModal(false),
        button2Text: "Return to Dashboard",
        button2Handler: () => {
          window.location.href = `/activity/${activity.id}/participant/${participant.id}/console`
        },
      });
    }
  }, [contribution]);

  const [formData, setFormData] = useState(participantAction?.data || {});
  const [canSubmit, setCanSubmit] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setFormData({
      ...formData,
      ...participantAction?.data,
    });

    if (participantAction?.hasOwnProperty("error")) {
      setConfirmationModalSettings({
        title: "There's been an error",
        description: "We were unable to save your changes. Please try again.",
        button1Text: "Continue",
        button1Handler: () => setOpenConfirmationModal(false),
        button2Text: "Return to Dashboard",
        button2Handler: () => {
          window.location.href = `/activity/${activity.id}/participant/${participant.id}/console`
        },
      });
    }
  }, [participantAction, participantActionId, participantAction?.data]);

  useEffect(() => {
    const validateFormDataBeforeSubmit = () => {
      let canSubmit = true;
      questionnaire?.questions.forEach(questionGroup => {
        questionGroup?.settings?.responses?.forEach(question => {
          if (canSubmit && question.required) {
            switch (question.type) {
              case "CHECKBOXES":
              case "DOC_UPLOAD":
                canSubmit = (!formData[question.input_key] || JSON.parse(formData[question.input_key]).length === 0) ?
                  false : true;
                break;
              default:
                canSubmit = (!formData[question.input_key] || formData[question.input_key].length === 0) ? false : true;
                break;
            }
          }
        })
      });
      setCanSubmit(canSubmit);
    }

    validateFormDataBeforeSubmit()

  }, [formData, questionnaire])

  const handleUpdate = (input) => {
    setFormData({
      ...formData,
      ...input,
    });
  }

  const handleSave = async (e) => {
    setSubmitType("isSave")
    setIsSaving(true)
    if (participantAction.id) {
      await props.updateParticipantAction(
        activity.id,
        participant.id,
        action.id,
        {
          ...participantAction,
          data: {
            ...formData,
          }
        },
      );
    } else {
      await props.createParticipantAction(
        activity.id,
        participant.id,
        action.id,
        {
          status: "INCOMPLETE",
          visibility: "PUBLIC",
          meta: {},
          data: {
            ...formData,
          }
        },
      );
    }
    setIsSaving(false)

    setConfirmationModalSettings({
      title: "Save successful",
      description: "Your changes have been saved. Select an option below to continue.",
      button1Text: "Continue",
      button1Handler: () => setOpenConfirmationModal(false),
      button2Text: "Return to Dashboard",
      button2Handler: () => {
        window.location.href = `/activity/${activity.id}/participant/${participant.id}/console`
      },
    });

    setOpenConfirmationModal(true);
  }

  const handleSubmit = async (e) => {
    setIsSubmitting(true)
    if (canSubmit) {
      setSubmitType("isSubmit")
      handleSave(e);
      await props.createParticipantContribution(
        activity.id,
        participant.id,
        participantAction.id,
      );
    } else {
      console.log("handle user can't submit")
    }
    setIsSubmitting(false)
  }

  return (
    <>
      <div className="right-div px-3">
        <form className="questionnaireForm">
          {(questionnaire.questions || []).map(
            (question, index) => {
              return (
                <div key={index} className="question-container">
                  {question?.content?.startsWith("<") ? (
                    <>
                      <h5
                        className="font-weight-bold card-title"
                        dangerouslySetInnerHTML={{
                          __html: question?.content,
                        }}
                      />
                      {" "}
                      {question?.required && (
                        <span className="asterisk">*</span>
                      )}
                    </>
                  ) : (
                    <h5 className="font-weight-bold card-title">
                      {question?.content}{" "}
                      {question?.required && (
                        <span className="asterisk">*</span>
                      )}
                    </h5>
                  )}

                  {question?.settings?.responses?.map(
                    (response, idx) => {

                      const ResponseType = inputRenderer[response.type] || FreeTextInput;

                      return (
                        <div key={idx} className="questionnaire-section">
                          <ResponseType
                            settings={response}
                            currentValue={formData[response.input_key]}
                            participantAction={participantAction}
                            isPreviewMode={isPreviewMode}
                            handleUpdate={handleUpdate} />
                        </div>
                      )
                    }
                  )}
                </div>
              );
            },
          )}
          {!isPreviewMode && (
            <div className="endSaveButtons" style={{ marginBottom: '50px', marginLeft: '10px' }}>
              <div className="complete-button" style={{ display: "flex" }}>
                <div className="button-div">
                  <button type="button" className="btn btn-lg submit-btn mr-2" onClick={handleSave} disabled={isSaving}>
                    {isSaving ? 'Saving...' : 'Save Draft'}
                  </button>
                </div>
                <div className="button-div">
                  <button type="button" className="btn btn-lg submit-btn w-100" onClick={handleSubmit} disabled={!canSubmit || isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
              </div>
              <div className="foot-notes">
                <h6>Questions marked with an asterisk (*) are required and must be completed before submitting.</h6>
              </div>
            </div>
          )}

        </form>
      </div>
      <ConfirmationModal
        isModalOpen={openConfirmationModal}
        closeModal={() => setOpenConfirmationModal(false)}
        header={confirmationModalSettings.title}
        description={confirmationModalSettings.description}
        button_1_text={confirmationModalSettings.button1Text}
        button_1_Handler={confirmationModalSettings.button1Handler}
        button_2_text={confirmationModalSettings.button2Text}
        button_2_Handler={confirmationModalSettings.button2Handler}
      />
    </>
  );
}


const mapStateToProps = (state) => ({
  questionnaire: state.questionnaireRetrieveReducer,
  createParticipantAction: state.participantActionsUpdateReducer,
  updateParticipantAction: state.participantActionsUpdateReducer,
  createParticipantContribution: state.createParticipantContributionReducer,
  contribution: state.participantContributionCRUDReducer,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    retrieveQuestionnaire,
    createParticipantAction,
    updateParticipantAction,
    createParticipantContribution,
  }),
)(QuestionnaireRenderer);
