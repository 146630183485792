import {
  EDIT_CLUB_MEMBERS_SUCCESS,
  EDIT_CLUB_MEMBERS_ERROR,
  EDIT_CLUB_MEMBERS_LOADING,
} from '../actions/types';

const initialState = {
  editClubMembersData: null,
  editClubMembersDataError: null,
  editClubMembersLoading: false,
  editClubMembersStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_CLUB_MEMBERS_SUCCESS:
      return {
        ...state,
        editClubMembersData: action.payload,
        editClubMembersDataError: null,
        editClubMembersStatus: 'success',
        editClubMembersLoading: false,
      };
    case EDIT_CLUB_MEMBERS_ERROR:
      return {
        ...state,
        editClubMembersDataError: action.payload,
        editClubMembersData: null,
        editClubMembersStatus: 'error',
        editClubMembersLoading: false,
      };
    case EDIT_CLUB_MEMBERS_LOADING:
      return {
        ...state,
        editClubMembersDataError: null,
        editClubMembersData: null,
        editClubMembersStatus: 'loading',
        editClubMembersLoading: true,
      };
    default:
      return {
        ...state,
        editClubMembersStatus: null,
      };
  }
};
