/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

class OutputContributionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { resource, logo } = this.props;
    console.log("Output", this.props.output);

    return (
      <Link
        className="output-contribution-link"
        to={
          resource.enable_detail_view === "1"
            ? this.getContributionLink()
            : "#"
        }>
        <div className="output-contribution-card">
          <div className="member-avatar">
            <img
              className="img-fluid"
              src={resource.listing_image || logo}
              alt="output-contribution-avatar"
            />
          </div>
          <div className="output-contribution-details">
            <div className="output-contribution-title mb-2">
              {resource.listing_title}
            </div>
            <div className="output-contribution-subtitle">
              {resource.listing_subtitle}
            </div>
          </div>
        </div>
      </Link>
    );
  }

  getContributionLink = () => {
    const { resource, urlParams } = this.props;
    const { clubId, clubHandle, outputId } = urlParams;
    const link = `/club/${clubId}/${clubHandle}/output/${outputId}/participant/${resource.participantId}`;
    return link;
  };
}

OutputContributionCard.propTypes = {
  resource: PropTypes.object,
  logo: PropTypes.object,
  urlParams: PropTypes.object,
};

export default OutputContributionCard;
