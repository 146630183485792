import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import clubDefaultImage from "../../../../assets/manage-clubs.svg";
import { OPEN_PARTICIPANT_VIEW } from "../../../../helpers/constants";
import ShareResource from "../../ShareResource";
import { getLabelByName } from "../../../../helpers/helperFunctions";

const ActivityStatus = ({
  activityData,
  clubData,
  path,
  isMobile = false,
}) => {
  const match = useRouteMatch();

  let link;
  let resourceType;
  let resourceId;

  if (
    match.path === "/campaign/:id/:handle" ||
    match.path === "/manage/activities/:id/:handle"
  ) {
    const activityLink = `/campaign/${activityData?.id}/${activityData?.handle}`;
    link = activityLink;
    resourceType = "Activity";
    resourceId = activityData?.id;
  } else {
    const clubLink = `/club/${clubData?.data?.id}/${clubData?.data?.handle}/about`;
    link = clubLink;
    resourceType = "Club";
    resourceId = clubData?.data?.id;
  }

  return (
    <div className="activityStatus">
      <p className="status">
        <span
          className={
            activityData?.status === "ACTIVE" && "active"
          }
        />
        {activityData?.status}
      </p>
      <p className="type">{activityData?.type}</p>
      <div className="clubInfo">
        <img
          src={
            clubData?.data?.profile?.picture ||
            clubDefaultImage
          }
          alt="club"
        />
        <p>{clubData?.data?.name}</p>
      </div>
      {path === "/manage/activities/:id/:handle" && (
        <NavLink
          to={`/campaign/${activityData?.id}/${activityData?.handle}`}>
          {getLabelByName(OPEN_PARTICIPANT_VIEW)}
          <span>&#10230;</span>
        </NavLink>
      )}

      <ShareResource
        isMobile={isMobile}
        resourceType={resourceType}
        postId={resourceId}
        postLink={link}
      />
    </div>
  );
};

export default ActivityStatus;
