import { toast } from "react-toastify";

export const handleImageUpload = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "qtt2g8ao");

    const options = {
      method: "POST",
      body: formData,
    };

    const res = await fetch(
      "https://api.Cloudinary.com/v1_1/ayudigital/image/upload",
      options,
    );

    const data = await res.json();

    return data;
  } catch (error) {
    toast.error(
      "Error uploading the picture!\nPlease try again.",
    );
  }
};

export const handleFileUpload = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "qtt2g8ao");

    const options = {
      method: "POST",
      body: formData,
    };

    const res = await fetch(
      "https://api.Cloudinary.com/v1_1/ayudigital/auto/upload",
      options,
    );

    const data = await res.json();

    return data;
  } catch (error) {
    toast.error(
      "Error uploading the picture!\nPlease try again.",
    );
  }
};
