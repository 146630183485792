/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import PropTypes from "prop-types";
import Checkbox from "../../../common/checkbox/checkbox";

class Checkboxes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { defaults } = this.props;

    return this.props.options.map((option, index) => (
      <Checkbox
        className="mb-3"
        checked={defaults && defaults.includes(option)}
        id={`gridCheck-${option}-${index}`}>
        {option}
      </Checkbox>
    ));
  }
}

Checkboxes.propTypes = {
  options: PropTypes.any,
  defaults: PropTypes.any,
};

export default Checkboxes;
