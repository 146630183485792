import apiCall from "../../../helpers/apiCall";
import { getHeaders } from "../../../helpers/getHeaders";

import {
    ACTIVITIES_PARTICIPANT_CRUD,
} from "../types";

export const retrieveParticipant = (
    activityId,
    participantId,
) => (dispatch) => {
    const apiEndpoint = `/activity/${activityId}/participant/${participantId}`
    apiCall.get(
        apiEndpoint,
        {
            headers: getHeaders(
                null,
                apiEndpoint
            )
        }
    ).then((response) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_CRUD,
            payload: response.data
        })
    }).catch((err) => {
        dispatch({
            type: ACTIVITIES_PARTICIPANT_CRUD,
            payload: err
        })
    });
}