import "./contentLandingPage.scss";
import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ContentCard from "../ContentCard/ContentCard";
import {
  BACKGROUND_IMAGE,
  CHANGE,
  CHANGE_BACKGROUND_IMAGE_DESC,
  IMAGE,
  PREVIEW_UPLOADED_IMAGE_DESC,
  webContentSection,
} from "../../../../helpers/constants";
import { getLabelByName } from "../../../../helpers/helperFunctions";

export class ContentLandingPage extends Component {
  state = {};

  setValue = (section, landingInfo) => {
    const { stateValues, triggerCheckbox } = this.props;

    if (section === webContentSection.siteSlogan) {
      return !stateValues[webContentSection.siteSlogan] &&
        stateValues[webContentSection.siteSlogan] !== null
        ? landingInfo &&
            landingInfo.data[webContentSection.siteSlogan]
        : stateValues[webContentSection.siteSlogan];
    }
    if (section === webContentSection.welcomeHeader) {
      return !stateValues[
        webContentSection.welcomeHeader
      ] &&
        stateValues[webContentSection.welcomeHeader] !==
          null
        ? landingInfo &&
            landingInfo.data[
              webContentSection.welcomeHeader
            ]
        : stateValues[webContentSection.welcomeHeader];
    }
    if (section === webContentSection.welcomeText) {
      return !stateValues[webContentSection.welcomeText] &&
        stateValues[webContentSection.welcomeText] !== null
        ? landingInfo &&
            landingInfo.data[webContentSection.welcomeText]
        : stateValues[webContentSection.welcomeText];
    }
    if (section === webContentSection.aboutHeader) {
      return !stateValues[webContentSection.aboutHeader] &&
        stateValues[webContentSection.aboutHeader] !== null
        ? landingInfo &&
            landingInfo.data[webContentSection.aboutHeader]
        : stateValues[webContentSection.aboutHeader];
    }
    if (section === webContentSection.aboutText) {
      return !stateValues[webContentSection.aboutText] &&
        stateValues[webContentSection.aboutText] !== null
        ? landingInfo &&
            landingInfo.data[webContentSection.aboutText]
        : stateValues[webContentSection.aboutText];
    }
    if (section === webContentSection.servicesHeader) {
      return !stateValues[
        webContentSection.servicesHeader
      ] &&
        stateValues[webContentSection.servicesHeader] !==
          null
        ? landingInfo &&
            landingInfo.data[
              webContentSection.servicesHeader
            ]
        : stateValues[webContentSection.servicesHeader];
    }
    if (section === webContentSection.servicesText) {
      return !stateValues[webContentSection.servicesText] &&
        stateValues[webContentSection.servicesText] !== null
        ? landingInfo &&
            landingInfo.data[webContentSection.servicesText]
        : stateValues[webContentSection.servicesText];
    }
    if (section === webContentSection.displayAbout) {
      return stateValues[webContentSection.displayAbout] ===
        false && triggerCheckbox === false
        ? landingInfo &&
            landingInfo.data[webContentSection.displayAbout]
        : stateValues[webContentSection.displayAbout];
    }
    if (section === webContentSection.displayServices) {
      return stateValues[
        webContentSection.displayServices
      ] === false && triggerCheckbox === false
        ? landingInfo &&
            landingInfo.data[
              webContentSection.displayServices
            ]
        : stateValues[webContentSection.displayServices];
    }
    return "";
  };

  render() {
    const {
      landingInfo,
      fileChange,
      imagePreview,
      handleChange,
      section,
      setSection,
      submitImage,
      stateValues,
    } = this.props;

    return (
      <div>
        <ContentCard
          header={getLabelByName(BACKGROUND_IMAGE)}
          title={getLabelByName(
            CHANGE_BACKGROUND_IMAGE_DESC,
          )}
          subtitle={getLabelByName(
            PREVIEW_UPLOADED_IMAGE_DESC,
          )}
          changeImageButtonText={`${getLabelByName(
            CHANGE,
          )} ${getLabelByName(IMAGE)}`}
          uploadImage
          landingInfo={landingInfo}
          fileChange={fileChange}
          imagePreview={imagePreview}
          submitImage={submitImage}
          contentSection={webContentSection.backgroundImage}
          setSection={setSection}
          stateValues={stateValues}
        />
        <ContentCard
          header="Slogan"
          oneInputField
          title="Brand Tagline"
          handleChange={handleChange}
          contentSection={webContentSection.siteSlogan}
          section={section}
          setSection={setSection}
          landingInfo={landingInfo}
          value={this.setValue(
            webContentSection.siteSlogan,
            landingInfo,
          )}
        />
        <ContentCard
          header="Welcome Section"
          twoInputField
          title="Header"
          title2="Text"
          handleChange={handleChange}
          contentSection={webContentSection.welcomeHeader}
          contentSection2={webContentSection.welcomeText}
          value={this.setValue(
            webContentSection.welcomeHeader,
            landingInfo,
          )}
          value2={this.setValue(
            webContentSection.welcomeText,
            landingInfo,
          )}
          section={section}
          setSection={setSection}
          landingInfo={landingInfo}
        />
        <ContentCard
          header="Display sections"
          checkboxes
          displayLandingAboutPages
          titleParagraph="Select the section you want the users to access from the landing page"
          handleChange={handleChange}
          // contentSection={webContentSection.displayAbout}
          section={section}
          setSection={setSection}
          landingInfo={landingInfo}
          displayAboutValue={this.setValue(
            webContentSection.displayAbout,
            landingInfo,
          )}
          displayServicesValue={this.setValue(
            webContentSection.displayServices,
            landingInfo,
          )}
        />
        <ContentCard
          header="About Section"
          twoInputField
          title="Header"
          title2="Text"
          handleChange={handleChange}
          contentSection={webContentSection.aboutHeader}
          contentSection2={webContentSection.aboutText}
          value={this.setValue(
            webContentSection.aboutHeader,
            landingInfo,
          )}
          value2={this.setValue(
            webContentSection.aboutText,
            landingInfo,
          )}
          section={section}
          setSection={setSection}
          landingInfo={landingInfo}
        />
        <ContentCard
          header="About Section Image"
          title="Upload your preferred image for this about section"
          subtitle="Can upload png, jpeg, jpg and svg format. Preview your upload on the right area of this card"
          changeImageButtonText="Change image"
          uploadImage
          landingInfo={landingInfo}
          fileChange={fileChange}
          imagePreview={imagePreview}
          contentSection={webContentSection.aboutImage}
          setSection={setSection}
          submitImage={submitImage}
          stateValues={stateValues}
        />
        <ContentCard
          header="Service Section"
          twoInputField
          title="Header"
          title2="Text"
          handleChange={handleChange}
          contentSection={webContentSection.servicesHeader}
          contentSection2={webContentSection.servicesText}
          value={this.setValue(
            webContentSection.servicesHeader,
            landingInfo,
          )}
          value2={this.setValue(
            webContentSection.servicesText,
            landingInfo,
          )}
          section={section}
          setSection={setSection}
          landingInfo={landingInfo}
        />
        <ContentCard
          header="Service Image"
          title="Upload your preferred image for this about section"
          subtitle="Can upload png, jpeg, jpg and svg format. Preview your upload on the right area of this card"
          changeImageButtonText="Change image"
          uploadImage
          landingInfo={landingInfo}
          fileChange={fileChange}
          imagePreview={imagePreview}
          contentSection={webContentSection.servicesImage}
          setSection={setSection}
          submitImage={submitImage}
          stateValues={stateValues}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(ContentLandingPage);
