import {
    COURSES_CREATE,
    COURSES_RETRIEVE,
    COURSES_UPDATE,
    COURSES_DELETE,
    COURSES_SEARCH,
    COURSES_SEARCH_LESSONS
} from '../../actions/types'

const initialState = (defaultMessage) => {
    return [];
}

export const courseRetrieveReducer = (state = initialState(''), action) => {
    if (action.type !== COURSES_RETRIEVE) {
        return state;
    }

    const apiResponse = action.payload;
    if (apiResponse.status === 200) {
        return apiResponse.data;
    } else {
        return {
            ...state,
            ...initialState("Activity not found..."),
            error: apiResponse.error.message
        }
    }
}

export const courseRetrieveLessonsReducer = (state = initialState(''), action) => {
    if (action.type !== COURSES_SEARCH_LESSONS) {
        return state;
    }

    const apiResponse = action.payload;
    if (apiResponse.status === 200) {
        return [
            ...apiResponse.data
        ];
    } else {
        return {
            ...state,
            error: apiResponse.error.message
        }
    }
}