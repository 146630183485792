import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Modal, TextField, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { propTypes } from "react-bootstrap/esm/Image";
import deleteIcon from "../../../assets/icons/delete-dark-orange.svg";
import { getUsers } from "../../../redux/actions/usersActions";
import { getLabelByName } from "../../../helpers/helperFunctions";
import {
  REMOVE,
  SAVE,
  SAVING,
  NAME,
  ROLE,
  rolesMapping,
  CANCEL,
  SEARCH,
  USER,
} from "../../../helpers/constants";
import "./AddMemberModal.css";

export class AddMemberModal extends Component {
  state = {
    selectedValues: [],
    valueSearched: null,
    selectedProfileIds: [],
  };

  componentDidMount() {
    const { getUsers: getUsersAction } = this.props;
    getUsersAction();
  }

  removeButtonHandler = (index) => {
    const { participants, isClub, members } = this.props;

    const {
      selectedValues,
      selectedProfileIds,
    } = this.state;

    if (index !== -1) {
      if (!isClub) {
        participants.splice(index, 1);
      } else {
        members.splice(index, 1);
      }
      selectedValues.splice(index, 1);
      selectedProfileIds.splice(index, 1);
      this.setState({
        selectedValues: [...selectedValues],
      });
      this.setState({ valueSearched: "" });
    }
    return undefined;
  };

  onSaveHandler = async () => {
    const {
      setIsButtonLoading,
      addMemberAction,
      isClub,
      actionId,
      members,
      participants,
    } = this.props;
    setIsButtonLoading(true);

    isClub
      ? await addMemberAction(actionId, { members })
      : await addMemberAction(actionId, { participants });
  };

  render() {
    const {
      getUsersData,
      membersFromReducer,
      isButtonLoading,
      isOpen,
      modalTitle,
      modalDescription,
      isClub,
      setParticipants,
      setMembers,
      setParticipantRole,
      participants,
      members,
      setIsOpen,
      isFromActivity,
      clubMembers,
    } = this.props;

    const {
      valueSearched,
      selectedProfileIds,
      selectedValues,
    } = this.state;

    const usersProfile = [];

    if (
      !isFromActivity &&
      getUsersData &&
      getUsersData.data &&
      getUsersData.data.length > 0
    ) {
      getUsersData.data.forEach((data) => {
        const { profile, id, username } = data;

        usersProfile.push({ profile, id, username });
      });
    }

    if (isFromActivity) {
      clubMembers.data.forEach((data) => {
        const { user, id } = data;

        usersProfile.push({
          profile: user.profile,
          id,
          username: user.profile.firstName,
        });
      });
    }

    const membersFromReducerIds = [];

    if (
      membersFromReducer &&
      membersFromReducer.data &&
      membersFromReducer.data.length > 0
    ) {
      membersFromReducer.data.forEach(
        (participantReducer) => {
          const { id } = participantReducer.user;
          membersFromReducerIds.push(parseInt(id, 10));
        },
      );
    }

    return (
      <Modal
        open={isOpen}
        setIsOpen={false}
        isOpen={isOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <div className="addMemberModal">
          <div className="addParticipant">
            <h1>{modalTitle}</h1>
            <p>{modalDescription}</p>
          </div>
          <div>
            <Autocomplete
              disableCloseOnSelect
              className="autocomplete"
              id="grouped-demo"
              options={usersProfile}
              loading
              getOptionLabel={(option) => {
                return !isFromActivity
                  ? `${option.profile?.firstName} ${option.profile?.lastName} (${option.username})`
                  : `${option.profile?.firstName} ${option.profile?.lastName}`;
              }}
              getOptionDisabled={(option, index) =>
                !![
                  ...selectedProfileIds,
                  ...membersFromReducerIds,
                ].find(
                  (selectedProfileId) =>
                    parseInt(selectedProfileId, index) ===
                    parseInt(option.id, index),
                )
              }
              onChange={(e, value) => {
                if (value) {
                  if (!isClub) {
                    setParticipants(value.id, "member");
                  } else {
                    setMembers(value.id, "member");
                  }
                  this.setState({
                    selectedValues: [
                      ...selectedValues,
                      value,
                    ],
                  });
                  this.setState({
                    selectedProfileIds: [
                      ...selectedProfileIds,
                      value.id,
                    ],
                  });
                  this.setState({
                    valueSearched: value.id,
                  });
                }
                return undefined;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={`${getLabelByName(
                    SEARCH,
                  )} ${getLabelByName(USER)}`}
                  value={valueSearched}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
            />
          </div>
          <div className="searchedUsersTable">
            <div className="searchedUsersTableTitles">
              <Grid
                container
                spacing={4}
                className="alignItemsCenter">
                <Grid item xs={3} sm={3}>
                  <h1>{getLabelByName(NAME)}</h1>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <h1>{getLabelByName(ROLE)}</h1>
                </Grid>
                <Grid item xs={3} sm={3}>
                  {}
                </Grid>
              </Grid>
            </div>
            <div className="searchedUsersAll">
              {selectedValues.length > 0 &&
                selectedValues.map(
                  (selectedValue, index) =>
                    selectedValue && (
                      <>
                        <div className="searchedTableContent">
                          <Grid
                            container
                            spacing={4}
                            className="alignItemsCenter">
                            <Grid
                              item
                              xs={3}
                              sm={3}
                              className="alignTextCenter">
                              <h1>
                                {`${selectedValue.profile.firstName} ${selectedValue.profile.lastName} `}
                                <span className="selectedUsername">
                                  {!isFromActivity
                                    ? `(${selectedValue.username})`
                                    : ""}
                                </span>
                              </h1>
                            </Grid>
                            <Grid item xs={3} sm={3}>
                              <select
                                onChange={(event) => {
                                  setParticipantRole(
                                    event.target.value,
                                    index,
                                  );
                                }}>
                                {Object.values(
                                  rolesMapping,
                                ).map((role, roleIndex) => (
                                  <option
                                    className="mb-2"
                                    value={
                                      Object.keys(
                                        rolesMapping,
                                      )[roleIndex]
                                    }
                                    selected={
                                      !isClub
                                        ? participants &&
                                          participants[
                                            index
                                          ].role ===
                                            Object.keys(
                                              rolesMapping,
                                            )[roleIndex]
                                        : members &&
                                          members[index]
                                            .role ===
                                            Object.keys(
                                              rolesMapping,
                                            )[roleIndex]
                                    }>
                                    {role}
                                  </option>
                                ))}
                              </select>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              sm={3}
                              className="removeUser">
                              <button
                                type="button"
                                onClick={() => {
                                  this.removeButtonHandler(
                                    index,
                                  );
                                }}>
                                <img
                                  src={deleteIcon}
                                  alt="edit-action"
                                  width="20px"
                                />{" "}
                                <span>
                                  {getLabelByName(REMOVE)}
                                </span>
                              </button>
                            </Grid>
                          </Grid>
                        </div>
                      </>
                    ),
                )}
            </div>
          </div>
          <div className="buttonActions">
            <button
              type="button"
              className="cancelButton"
              onClick={setIsOpen}>
              {getLabelByName(CANCEL)}
            </button>
            <button
              type="button"
              className="saveButton"
              onClick={this.onSaveHandler}
              disabled={
                isButtonLoading ||
                selectedValues.length <= 0
              }>
              {isButtonLoading ? (
                <>
                  <span>{`${getLabelByName(
                    SAVING,
                  )}  `}</span>
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status">
                    <span className="sr-only">
                      Loading...
                    </span>
                  </div>
                </>
              ) : (
                getLabelByName(SAVE)
              )}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

AddMemberModal.propTypes = {
  membersFromReducer: PropTypes.array,
  getUsersData: PropTypes.array,
  participants: PropTypes.array,
  isClub: PropTypes.bool,
  members: PropTypes.array,
  getUsers: propTypes.any,
  setIsButtonLoading: PropTypes.bool,
  addMemberAction: PropTypes.any,
  actionId: PropTypes.number,
  isButtonLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  modalDescription: PropTypes.string,
  setParticipants: PropTypes.any,
  setMembers: PropTypes.any,
  setParticipantRole: PropTypes.any,
  setIsOpen: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  getUsersData: state.getUsersReducer.getUsersData,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getUsers,
  }),
)(AddMemberModal);
