/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import slugify from 'slugify';
import { getLabelByName } from '../../helpers/helperFunctions';
import { VIEW_ACTIVITY_FEED } from '../../helpers/constants';
import feedIcon from '../../assets/icons/feed-orange-light.svg';

class ViewClubFeedCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { club } = this.props;

    return (
      <Link
        className="output-card-link p-2 ml-auto mr-0"
        to={`/club/${club.id}/${club.handle}`}
        style={{ backgroundColor: '#F7F4F2', padding: 0, display: 'grid' }}
      >
        <div
          className="output-card d-flex flex-column justify-content-center align-items-center"
        >
          <div className="card-image">
            <img
              src={feedIcon}
              alt="feed-icon"
              style={{
                width: '35px',
                height: '35px',
                objectFit: 'unset',
                minHeight: 'unset',
                maxWidth: 'unset',
              }}
            />
          </div>
          <div className="output-name text-center mt-3">
            {getLabelByName(VIEW_ACTIVITY_FEED)}
          </div>
        </div>
      </Link>
    );
  }
}

ViewClubFeedCard.propTypes = {
  club: PropTypes.any,
};

export default ViewClubFeedCard;
