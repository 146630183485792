import React from "react";

const FreeTextInput = (props) => {

  const { settings, currentValue, participantAction, handleUpdate, isPreviewMode } = props;

  const handleChange = (e) => {
    handleUpdate({
      [settings.input_key]: e.target.value
    })
  }

  const getSettingOrDefault = (property, defaultValue) => {
    if (settings.hasOwnProperty(property)) {
      return settings[property];
    } else {
      return defaultValue;
    }
  }

  return (
    <div className="card section-card">
      <div className="card-body">
        <div className="card-details">
          <div className="title-row">
            <h5 className="card-title">
              {settings.required ? '*' : ''}
              {settings.label}
            </h5>
          </div>
          <div className="form-group">
            <textarea
              id={settings.input_key}
              className="form-control"
              placeholder={getSettingOrDefault('placeholder', 'Enter text')}
              onChange={handleChange}
              required={settings.required}
              disabled={isPreviewMode}
              value={currentValue || getSettingOrDefault('default', '')} />
          </div>
        </div>
      </div>
    </div>
  )

}

export default FreeTextInput;