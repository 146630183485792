import React from "react";
import {
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import imagePlaceholder from "../../../../../../assets/icons/image-placeholder.svg";
import { getLabelByName } from "../../../../../../helpers/helperFunctions";
import {
  CLICK_HERE_TO_UPLOAD,
  LOGO,
  THE_WORD,
  outputTypes,
  TYPE,
  CREATE_OUTPUT_STATUS_DESCRIPTION,
  VISIBILITY,
  visibilityOptions,
  statuses,
} from "../../../../../../helpers/constants";

import "./outputDetailsForm.scss";
import {
  useWizardContext,
  WIZARD_CONSTANTS,
} from "../wizardContextProvider";

const OutputDetailsForm = ({
  nextStep,
  closeModal,
  ...rest
}) => {
  const [wizardState, dispatch] = useWizardContext();

  const handleFieldChange = (e) => {
    const { name: key, value } = e.target;

    dispatch({
      type: WIZARD_CONSTANTS.HANDLE_FIELD_CHANGE,
      payload: {
        key,
        value,
      },
    });
  };

  const coverImage = wizardState.settings.cover_image;

  const fileChange = (e) => {
    dispatch({
      type: WIZARD_CONSTANTS.FILE_CHANGE,
      payload: {
        selectedFile: e.target.files[0],
      },
    });

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      dispatch({
        type: WIZARD_CONSTANTS.SET_FILE_PREVIEW,
        payload: {
          cover_image: fileReader.result,
          imagePreview: fileReader.result,
        },
      });
    };

    fileReader.readAsDataURL(e.target.files[0]);
  };

  const getImagePreview = () => {
    const { resource, editAction } = rest;

    const { imagePreview } = wizardState;

    let image = imagePlaceholder;

    if (editAction && editAction[resource.input_key]) {
      image = editAction[resource.input_key];
    }

    if (imagePreview) {
      image = imagePreview;
    }
    return image;
  };

  const TYPES = Object.values(outputTypes);

  return (
    <>
      <div>
        <div className="output-information-details p-x-50">
          <div className="create-output-name">
            <TextField
              id="outlined-helperText"
              label="Name"
              variant="outlined"
              name="name"
              value={wizardState.name}
              onChange={handleFieldChange}
              required
            />
          </div>

          <div className="output-type-visibility">
            <FormControl
              variant="outlined"
              className="create-output-type">
              <InputLabel id="demo-simple-select-outlined-label">
                {getLabelByName(TYPE)}
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="type"
                required
                onChange={handleFieldChange}
                value={wizardState.type}
                label="Type">
                {TYPES.map((outputType, index) => (
                  <MenuItem
                    key={index}
                    value={Object.keys(outputTypes)[index]}>
                    {outputType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              variant="outlined"
              className="create-output-visibility">
              <InputLabel id="demo-simple-select-outlined-label">
                {getLabelByName(VISIBILITY)}
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                onChange={handleFieldChange}
                name="visibility"
                value={wizardState.visibility}
                label="Visibility">
                {visibilityOptions?.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="create-output-description">
            <TextField
              id="outlined-textarea"
              label="Description"
              multiline
              variant="outlined"
              name="description"
              required
              value={wizardState.description}
              onChange={handleFieldChange}
            />
          </div>
        </div>

        <div className="create-output-status p-x-50">
          <div className="output-status-description">
            <p>
              {getLabelByName(
                CREATE_OUTPUT_STATUS_DESCRIPTION,
              )}
            </p>
          </div>

          <div className="create-output-status-buttons">
            {statuses.map((status, index) => (
              <FormControl component="fieldset" key={index}>
                <RadioGroup
                  row
                  aria-label="position"
                  name="status"
                  defaultValue="top">
                  <FormControlLabel
                    value={status.value}
                    onChange={handleFieldChange}
                    control={<Radio color="primary" />}
                    label={status.text}
                    checked={
                      status.value === wizardState.status
                    }
                  />
                </RadioGroup>
              </FormControl>
            ))}
          </div>
        </div>

        <div className="create-output-logo p-x-50">
          <div className="section-card image-card">
            <div className="card-details">
              <div className="upload-file-div">
                <div className="custom-input">
                  <input
                    type="file"
                    className="custom-file-inputz mb-5"
                    id="customFile"
                    onChange={fileChange}
                    accept="image/*"
                  />
                </div>
                <div className="upload-custom-file">
                  <label htmlFor="customFile">
                    {`${getLabelByName(
                      CLICK_HERE_TO_UPLOAD,
                    )} ${getLabelByName(
                      THE_WORD,
                    ).toLowerCase()} ${getLabelByName(
                      LOGO,
                    ).toLowerCase()}`}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="card-image-preview">
            <img
              src={coverImage || getImagePreview()}
              alt="card-preview"
            />
          </div>
        </div>
      </div>

      <hr className="border-separator" />
      <div className="create-outputs-buttons d-flex justify-content-end mb-3">
        <input
          type="button"
          value="Cancel"
          className="cancel-button"
          onClick={closeModal}
        />

        <input
          type="button"
          className={`${
            !wizardState.name || !wizardState.description
              ? "next-button disabled"
              : "next-button"
          }`}
          value="Next"
          disabled={
            !wizardState.name || !wizardState.description
          }
          onClick={nextStep}
        />
      </div>
    </>
  );
};

export default OutputDetailsForm;
