/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getLabelByName } from '../../helpers/helperFunctions';
import { UPLOAD } from '../../helpers/constants';

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      fileType, componentId, accept, multiFileChange,
    } = this.props;

    return (
      <div className="action-button" title={`${getLabelByName(UPLOAD)} ${fileType}`}>
        <label htmlFor={componentId}>
          <img
            src={require(`../../assets/icons/attach-${fileType}.svg`)}
            alt="upload-icon"
            width="20px"
          />
        </label>
        <input
          type="file"
          className="custom-file-inputz mb-5"
          id={componentId}
          onChange={(e) => multiFileChange(e, `${fileType}Files`)}
          multiple
          accept={accept}
        />
      </div>
    );
  }
}

FileUpload.propTypes = {
  componentId: PropTypes.any,
  fileType: PropTypes.string,
  accept: PropTypes.string,
  multiFileChange: PropTypes.func,
};

export default FileUpload;
