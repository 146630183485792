/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable dot-notation */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { ArrowBackIos } from "@material-ui/icons";

import { toast } from "react-toastify";
import { getSessionAction } from "../../../redux/actions/authActions";
import Footer from "../../Footer";
import Navbar from "../../Navbar";
import {
  checkSession,
  getAllClubsFromLocalStorage,
  getLabelByName,
  getProfileDataFromLocalStorage,
  isActionSuccess,
} from "../../../helpers/helperFunctions";
import {
  BACK,
  QUESTIONNAIRE_DESCRIPTION,
  FORM_TITLE,
  CREATE,
  QUESTIONNAIRE,
  PROCESSING,
  FORM_DESCRIPTION,
  CLUB,
  SELECT,
  A_WORD,
  NEW,
} from "../../../helpers/constants";
import { createQuestionnaire } from "../../../redux/actions/adminActions";

export class QuestionnairePage extends Component {
  state = {
    profileData: getProfileDataFromLocalStorage(),
    clubsData: getAllClubsFromLocalStorage(),
    isLoading: false,
    name: "",
    description: "",
    clubId: null,
    isClubFromQuestionnaire: false,
    clubHandle: null,
  };

  async UNSAFE_componentWillMount() {
    const { props, state } = this;
    const { profileData, pathname } = state;
    if (!profileData || profileData.status !== 200) {
      window.location.href = `/login?redirect_to=${pathname}`;
    }
    await props.getSessionAction();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { status, data, location } = nextProps;

    if (location?.state?.clubId) {
      this.setState({
        clubId: location?.state?.clubId,
        isClubFromQuestionnaire: true,
        clubHandle: location?.state?.handle,
      });
    }

    const { props, state } = this;
    const { pathname } = state;

    checkSession(data, status, pathname, props);

    if (
      isActionSuccess(
        nextProps,
        "createQuestionnaireStatus",
        "createQuestionnaireData",
      )
    ) {
      this.setState({ isLoading: false });
      const { isClubFromQuestionnaire, clubHandle } =
        this.state;
      const { id: questionnaireId, parentId: clubId } =
        nextProps.createQuestionnaireData.data;
      this.props.history.push(
        `/manage/questionnaires/club/${clubId}/questionnaire/${questionnaireId}/edit`,
        { isClubFromQuestionnaire, clubHandle },
      );
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  createNewQuestionnre = async (e) => {
    e.preventDefault();

    const { clubId } = this.state;

    if (!clubId)
      return toast.info(
        `${getLabelByName(SELECT)} ${getLabelByName(
          A_WORD,
        )} ${getLabelByName(CLUB)}`,
      );

    this.setState({ isLoading: true });

    const payload = {
      name: this.state.name,
      description: this.state.description,
      actions: {},
      meta: {},
    };
    await this.props.createQuestionnaire(clubId, payload);
  };

  render() {
    const { REACT_APP_APPLICATION_NAME } = process.env;
    document.title = `${
      REACT_APP_APPLICATION_NAME || "The Alma Maters Club"
    } - ${getLabelByName(QUESTIONNAIRE)}`;

    const {
      isLoading,
      name,
      description,
      clubId,
      clubsData,
    } = this.state;

    const clubsOptions = clubsData
      ? clubsData.map((club) => ({
          value: club.id,
          text: club.name,
        }))
      : [];

    const { location } = this.props;

    let clubFromQuestionnaireProp;

    if (location?.state?.clubId) {
      const {
        location: {
          state: { clubId: clubIdFromQuestionnaire },
        },
      } = this.props;

      clubFromQuestionnaireProp = clubsOptions.filter(
        (option) =>
          Number(option.value) ===
          Number(clubIdFromQuestionnaire),
      )[0];
    }

    return (
      <>
        <Navbar pathname="profile" />
        <div className="club-page">
          <div className="container">
            <div className="buttons-row">
              <div id="back-link">
                <div
                  className="d-flex justify-content-center align-items-center"
                  onClick={() =>
                    this.props.history.goBack()
                  }
                  style={{ cursor: "pointer" }}>
                  <ArrowBackIos className="arrow-back-icon" />
                  {"  "}
                  {getLabelByName(BACK)}
                </div>
              </div>
            </div>

            <form
              onSubmit={(e) =>
                this.createNewQuestionnre(e)
              }>
              <div className="create-article-header">
                <div className="create-article-title">
                  <div className="article-title">{`${getLabelByName(
                    NEW,
                  )} ${getLabelByName(
                    QUESTIONNAIRE,
                  )}`}</div>
                  <div className="article-helper">
                    {getLabelByName(
                      QUESTIONNAIRE_DESCRIPTION,
                    )}
                  </div>
                </div>
                <div>
                  <div className="edit-button">
                    <button
                      type="submit"
                      className="btn btn-lg submit-btn"
                      disabled={isLoading}>
                      {!isLoading
                        ? getLabelByName(CREATE)
                        : `${getLabelByName(
                            PROCESSING,
                          )}...`}
                    </button>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <div className="institute-card article-card">
                  <div className="form-icon col-md-12">
                    <div className="form-group form-label-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="input-name"
                        placeholder={getLabelByName(
                          FORM_TITLE,
                        )}
                        value={name}
                        onChange={this.handleChange}
                        style={{
                          backgroundColor: "#FFFFFF",
                        }}
                        required
                      />
                      <label htmlFor="input-name">
                        {getLabelByName(FORM_TITLE)}
                      </label>
                    </div>
                  </div>

                  <div className="form-icon col-md-12">
                    <div className="form-group form-label-group">
                      <input
                        type="text"
                        name="description"
                        className="form-control"
                        id="input-description"
                        placeholder={getLabelByName(
                          FORM_DESCRIPTION,
                        )}
                        value={description}
                        onChange={this.handleChange}
                        style={{
                          backgroundColor: "#FFFFFF",
                        }}
                      />
                      <label htmlFor="input-description">
                        {getLabelByName(FORM_DESCRIPTION)}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="select-club-dropdown">
                      <div className="select-club-header">
                        {getLabelByName(CLUB)}
                      </div>
                      <div className="select-club-input">
                        <select
                          className="form-control"
                          id="club-select"
                          onChange={(e) =>
                            this.setState({
                              clubId: e.target.value,
                            })
                          }
                          value={
                            clubFromQuestionnaireProp
                              ? clubFromQuestionnaireProp.value
                              : null
                          }
                          disabled={
                            clubFromQuestionnaireProp
                          }>
                          {clubFromQuestionnaireProp ? (
                            <option
                              value={
                                clubFromQuestionnaireProp.value
                              }>
                              {
                                clubFromQuestionnaireProp.text
                              }
                            </option>
                          ) : (
                            <>
                              <option
                                selected
                                disabled>{`${getLabelByName(
                                SELECT,
                              )} ${getLabelByName(
                                CLUB,
                              )}`}</option>
                              {clubsOptions.map((club) => (
                                <option
                                  value={club.value}
                                  key={club.value}>
                                  {" "}
                                  {club.text}{" "}
                                </option>
                              ))}
                            </>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="footer-block">
            <div className="footer-component">
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  }
}

QuestionnairePage.propTypes = {
  getSessionAction: PropTypes.func,
  createQuestionnaire: PropTypes.func,
  data: PropTypes.object,
  status: PropTypes.string,
  history: PropTypes.any,
  createQuestionnaireData: PropTypes.object,
};

export const mapStateToProps = (state) => ({
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,

  createQuestionnaireData:
    state.createQuestionnaireReducer
      .createQuestionnaireData,
  createQuestionnaireDataError:
    state.createQuestionnaireReducer
      .createQuestionnaireDataError,
  createQuestionnaireStatus:
    state.createQuestionnaireReducer
      .createQuestionnaireStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getSessionAction,
    createQuestionnaire,
  }),
)(QuestionnairePage);
