import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { getLanding } from "../redux/actions/landingActions";
import aboutImage from "../assets/what-we-do.png";
import servicesImage from "../assets/how-we-do.png";

import LandingComponent from "../components/landing/Landing";
import {
  getProfileDataFromLocalStorage,
  userIsLoggedIn,
} from "../helpers/helperFunctions";

export class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicationName:
        process.env.REACT_APP_APPLICATION_NAME,
      pageTitle:
        process.env.REACT_APP_APPLICATION_NAME ||
        "The Alma Maters Club",
      profileData: getProfileDataFromLocalStorage(),
      landingData: this.props.landingData,
      landingDataError: this.props.landingDataError,
      landingStatus: props.landingStatus,
      landingInfo: this.getLandingInfo(),
    };
    this.init();
  }

  async UNSAFE_componentWillMount() {
    this.props.getLanding();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.landingData !== nextProps.landingData) {
      this.setState({
        landingData: nextProps.landingData,
        landingInfo: this.getLandingInfo(),
      });
    }
  }

  getLandingInfo() {
    let landingDefaults = {
      "footer-text":
        "Bringing like minded people together in action",
      "footer-link": "/signup",
      "footer-button": "Discover Communities",
      "welcome-header": "Welcome",
      "welcome-text": "Thank you for visiting our website",
      "about-header": "About Us",
      "about-text":
        "Here you will find information about what we do.",
      "services-header": "Services",
      "services-text": "This is how we can help you.",
      "services-image": servicesImage,
      "about-image": aboutImage,
    };

    const landingDataLoaded = () =>
      this.state?.landingData &&
      this.state?.landingData?.data;

    if (!landingDataLoaded()) {
      return landingDefaults;
    }

    const landingData = this.state.landingData.data.filter(
      (x) => x.name === "landing-page",
    )[0];

    landingDefaults = {
      ...landingDefaults,
      ...landingData?.data,
    };

    return landingDefaults;
  }

  init() {
    if (userIsLoggedIn(this.state.profileData)) {
      window.location.href = "home";
    }
  }

  render() {
    return (
      <div className="landing-container">
        <LandingComponent
          landingInfo={this.state.landingInfo}
        />
      </div>
    );
  }
}

Landing.propTypes = {
  getLanding: PropTypes.func.isRequired,
  landingDataError: PropTypes.object,
  landingData: PropTypes.object,
  landingStatus: PropTypes.string,
};

export const mapStateToProps = (state) => ({
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { getLanding }),
)(Landing);
