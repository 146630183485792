import React, { useState, useEffect } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { nanoid } from "nanoid";
import { GlossaryCard } from "./GlossaryCard";
import "./GlossaryCard.scss";

export const GlossaryList = ({
  glossaryList,
  handleChange,
  headerLabel,
  titleLabel,
  textLabel,
}) => {
  const [glossaryListState, setGlossaryListState] =
    useState(() => glossaryList || []);

  const addGlossary = () => {
    const newGlossaryList = [
      ...glossaryListState,
      { id: nanoid(), display: true },
    ];
    setGlossaryListState(newGlossaryList);
    handleChange({
      target: {
        value: JSON.stringify(newGlossaryList),
        name: "list",
      },
    });
  };

  const updateListGlossary = (id) => {
    return (update) => {
      const newGlossaryList = glossaryListState.map(
        (glossary) =>
          glossary.id === id
            ? { ...glossary, ...update }
            : { ...glossary },
      );
      setGlossaryListState([...newGlossaryList]);
      handleChange({
        target: {
          value: JSON.stringify(newGlossaryList),
          name: "list",
        },
      });
    };
  };

  const deleteGlossary = (id) => {
    const newGlossaryList = glossaryListState.filter(
      (glossary) => glossary.id !== id,
    );
    setGlossaryListState(newGlossaryList);
    handleChange({
      target: {
        value: JSON.stringify(newGlossaryList),
        name: "list",
      },
    });
  };

  useEffect(() => {
    if (glossaryListState.length === 0) {
      setGlossaryListState(glossaryList);
    }
  }, [glossaryList]);
  return (
    <>
      {glossaryListState.map((policy, index) => (
        <GlossaryCard
          data={policy}
          updateGlossary={updateListGlossary(policy.id)}
          headerLabel={`${headerLabel} ${index + 1}`}
          hasTitle
          titleLabel={titleLabel}
          textLabel={textLabel}
          deleteGlossary={() => deleteGlossary(policy.id)}
          key={policy.id}
        />
      ))}
      <div
        className="add-new-section"
        onClick={addGlossary}>
        <AddCircleOutlineIcon
          sx={{ fontSize: 40, color: "#f38f46" }}
        />
        <p>add a new policy</p>
      </div>
    </>
  );
};
