import React from "react";

const AddMultipleOption = ({
  index,
  optionValue,
  response,
  questionIndex,
  setOptionValue,
  generateOptionTypes,
  handleDisplayMultipleOption,
}) => {
  return (
    <div>
      <label className="px-0">
        Paste in the option values you would like to have
        for the questions.
      </label>

      <div className="mb-3">
        <textarea
          className="p-3 mb-1 w-100"
          value={optionValue}
          onChange={(e) => setOptionValue(e.target.value)}
          rows={4}
        />

        <small className="font-italic">
          NB: All values on a separate line are taking as a
          separate option.
        </small>
      </div>

      <div className="d-flex">
        <button
          type="button"
          className="btn btn-lg submit-btn"
          onClick={() => {
            generateOptionTypes({
              optionValue,
              questionIndex,
              responseIndex: index,
              responseType: response.type,
            });

            handleDisplayMultipleOption(false);

            setOptionValue("");
          }}>
          Save
        </button>

        <button
          type="button"
          className="btn btn-lg ml-3 submit-btn"
          onClick={() => {
            handleDisplayMultipleOption(false);
          }}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AddMultipleOption;
