/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
// @ts-nocheck
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { compose } from "redux";
import { ArrowBackIos } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import moment from "moment";
// import { PARTICIPANT } from "../../../helpers/constants";
// import { getLabelByName } from "../../../helpers/helperFunctions";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import {
  getActivity,
  getParticipants,
} from "../../../redux/actions/campaignsActions";
import ParticipantDetails from "./ParticipantDetails";
import { checkSession, getLabelByName } from "../../../helpers/helperFunctions";
import {
  ADD_CONTRIBUTIONS,
  CLICK_TO_REVIEW_CONTRIBUTION,
  colors,
  CONTRIBUTIONS,
} from "../../../helpers/constants";
import ReviewContributionModal from "./ReviewContributionModal";
import BehalfModal from "./BehalfModal";
import { getSessionAction } from "../../../redux/actions/authActions";
// import "./Participants.css";

class ParticipantProfile extends Component {
  state = {
    isModalOpen: false,
    behalfModalOpen: false,
    selectedContribution: null,
    participant: null,
    activity: null,

    pendingStatus: {
      text: "Pending",
      backgroundColor: "#E6B100",
    },
    acceptedStatus: {
      text: "Accepted",
      backgroundColor: "#10A728",
    },
    rejectedStatus: {
      text: "Rejected",
      backgroundColor: "#F40505",
    },
    pathname: this.props.location.pathname,
  };

  UNSAFE_componentWillMount() {
    const {
      getParticipants: getParticipantsAction,
      getSessionAction: getSession,
      match,
    } = this.props;
    const query = `?q={"id":${match.params.participantId}}`;

    getSession();
    getParticipantsAction(match.params.id, query);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, status } = nextProps;
    checkSession(data, status, this.state.pathname, nextProps);

    const { getParticipantsData } = this.props;
    if (nextProps.getParticipantsData?.data !== getParticipantsData?.data) {
      this.setState({
        participant: nextProps.getParticipantsData?.data[0],
      });
      await this.props.getActivity(
        nextProps.getParticipantsData?.data[0].activityId
      );
    }

    if (nextProps.getActivityData?.data) {
      this.setState({ activity: nextProps.getActivityData?.data });
    }
  }

  closeBehalfModal = () => this.setState({ behalfModalOpen: false });

  toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  render() {
    const {
      participant,
      pendingStatus,
      acceptedStatus,
      rejectedStatus,
      activity,
    } = this.state;

    const { match } = this.props;

    return (
      <div className="participant-page">
        <Navbar pathname="manage" />
        <div style={{ position: "relative", top: "130px" }}>
          {participant && (
            <div
              className="d-flex justify-content-between"
              style={{ margin: "0 3rem" }}
            >
              <div>
                <Link
                  to={`/manage/activities/${match.params.id}/${match.params.handle}/participants`}
                  style={{
                    color: colors.textPrimary,
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                  }}
                >
                  <ArrowBackIos className="arrow-back-icon" />
                  <Typography display="inline" variant="h4" component="h4">
                    {`${participant?.user?.profile?.firstName} ${participant?.user?.profile?.lastName}`}
                  </Typography>
                </Link>
              </div>
              <div className="join-button">
                <button
                  type="button"
                  className="btn btn-lg submit-btn"
                  onClick={() => this.setState({ behalfModalOpen: true })}
                >
                  {getLabelByName(ADD_CONTRIBUTIONS)}
                </button>
              </div>
            </div>
          )}

          {participant && activity && (
            <ParticipantDetails participant={participant} activity={activity} />
          )}

          <div className="m-5">
            <div className="mb-3">
              <h5 className="font-weight-bold">
                {getLabelByName(CONTRIBUTIONS)}
              </h5>
            </div>
            {participant &&
              activity?.settings?.anonymous === false &&
              participant.contributions.map((contribution) => (
                <div
                  className="contribution-card d-flex justify-content-between align-items-center"
                  onClick={() =>
                    this.setState({
                      selectedContribution: contribution,
                      isModalOpen: true,
                    })
                  }
                  style={{ margin: "1rem 0" }}
                >
                  <div className="inside-content">
                    <h1>{contribution.action[0].name}</h1>
                    <p>
                      {moment(contribution.dateCreated * 1000).format(
                        "DD MMM YYYY hh:mm "
                      ) || "DD MMM YYYY hh:mm"}
                    </p>
                    <button
                      type="button"
                      style={{
                        color: "#FFFFFF",
                        backgroundColor:
                          contribution.status === "PENDING_REVIEW"
                            ? pendingStatus.backgroundColor
                            : contribution.status === "REJECTED"
                            ? rejectedStatus.backgroundColor
                            : acceptedStatus.backgroundColor,
                        cursor: "default",
                      }}
                    >
                      {contribution.status === "PENDING_REVIEW"
                        ? pendingStatus.text
                        : contribution.status === "REJECTED"
                        ? rejectedStatus.text
                        : acceptedStatus.text}
                    </button>
                  </div>
                  <div className="pr-2" style={{ color: colors.primaryColor }}>
                    {getLabelByName(CLICK_TO_REVIEW_CONTRIBUTION)}
                  </div>
                </div>
              ))}
          </div>

          <ReviewContributionModal
            contribution={this.state.selectedContribution}
            isModalOpen={this.state.isModalOpen}
            toggleModal={this.toggleModal}
          />

          <BehalfModal
            activity={activity}
            participant={participant}
            isModalOpen={this.state.behalfModalOpen}
            toggleModal={this.closeBehalfModal}
          />

          <div className="footer-block">
            <div className="footer-component">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ParticipantProfile.propTypes = {
  getParticipantsData: PropTypes.shape({
    data: PropTypes.array,
  }).isRequired,
  getParticipants: PropTypes.func.isRequired,
  getActivity: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export const mapStateToProps = (state) => ({
  getParticipantsData: state.getParticipantsReducer.getParticipantsData,
  getActivityData: state.getActivityReducer.getActivityData,
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getParticipants,
    getActivity,
    getSessionAction,
  })
)(ParticipantProfile);
