/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable global-require */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { Modal } from "react-bootstrap";
import {
  ArrowBackIos,
  ArrowForwardIos,
} from "@material-ui/icons";
import moment from "moment";
import logo from "../../assets/logo-opac.svg";
import CommentForm from "../generics/CommentForm";
import "./gallery-modal.css";
import { getContributionComments } from "../../redux/actions/contributionsActions";
import { getProfileDataFromLocalStorage, isActionSuccess } from "../../helpers/helperFunctions";
import { colors } from "../../helpers/constants";
import timeIcon from "../../assets/icons/time.svg";
import likingIcon from "../../assets/reactions/like.svg";
import commentIcon from "../../assets/icons/comment.svg";
import commentIconOrange from "../../assets/icons/comment-orange.svg";
import {
  createPost,
  postReaction,
  getReactions,
  deleteReaction,
} from "../../redux/actions/postActions";
import ReactionsMenu from "../generics/ReactionsMenu";

class GalleryModal extends Component {
  reactions = {};

  state = {
    profileData: getProfileDataFromLocalStorage(),
    resourceDisplay: this.props.resource,
    indexDisplay: this.props.index,
    isPosting: false,
    postComment: {},
    displayComments: [],
    listReactions: (postId) => this.fetchReactions(postId),
    clickedPostId: null,
  };

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { profileData } = this.state;
    const { clickedPostId } = this.state;
    const {
      getReactionsData,
      postReactionData,
      postReactionStatus,
    } = nextProps;

    if (
      nextProps.isModalOpen &&
      nextProps.isModalOpen !== this.props.isModalOpen
    ) {
      await this.props.getContributionComments(
        nextProps.resource.id,
      );
    }

    if (
      isActionSuccess(
        nextProps,
        "postContributionsCommentStatus",
        "postContributionsCommentData",
      ) &&
      this.props.isModalOpen
    ) {
      await this.props.getContributionComments(
        this.props.resource.id,
      );
    }

    if (
      clickedPostId !== null &&
      getReactionsData &&
      getReactionsData.data &&
      Object.keys(getReactionsData.data).length > 0 &&
      !this.reactions[clickedPostId]
    ) {
      this.reactions[clickedPostId] = getReactionsData.data;
    }

    if (
      postReactionStatus &&
      postReactionData &&
      postReactionData.data &&
      clickedPostId !== null &&
      this.props.isModalOpen
    ) {
      if (!this.reactions[clickedPostId]) {
        this.reactions[clickedPostId] = {};
      }
      if (
        !this.reactions[clickedPostId][profileData.data.id]
      ) {
        this.reactions[clickedPostId][profileData.data.id] =
          postReactionData.data;
      }

      await this.props.getContributionComments(
        this.props.resource.id,
      );
    }
  }

  handlePrev = () => {
    const { allResources } = this.props;
    const { indexDisplay } = this.state;
    if (indexDisplay > 0) {
      this.setState({
        resourceDisplay: allResources[indexDisplay - 1],
        indexDisplay: indexDisplay - 1,
      });
    }
  };

  handleNext = () => {
    const { allResources } = this.props;
    const { indexDisplay } = this.state;
    if (indexDisplay < allResources.length - 1) {
      this.setState({
        resourceDisplay: allResources[indexDisplay + 1],
        indexDisplay: indexDisplay + 1,
      });
    }
  };

  handleChangeComment = (e, postId) => {
    const { value } = e.target;
    const { postComment } = this.state;
    this.setState({
      postComment: {
        ...postComment,
        [postId]: value,
      },
    });
  };

  onToggleComments = (postId) => {
    const { displayComments } = this.state;
    if (!displayComments.includes(postId)) {
      displayComments.push(postId);
      this.setState({ displayComments });
    } else {
      displayComments.splice(
        displayComments.indexOf(postId),
      );
      this.setState({ displayComments });
    }
  };

  submitPost = async (e, authorId, parentId, postType) => {
    this.setState({ isPosting: parentId });
    e.preventDefault();
    const { props, state } = this;
    let postBody;
    if (postType === "post") {
      const { postMessage } = state;
      postBody = postMessage;
    } else {
      const { postComment } = state;
      postBody = postComment[parentId];
    }

    const { clubId } = props.match.params;

    const payload = {
      authorId,
      parentId,
      content: {
        body: postBody,
      },
      images: {},
      videos: {},
      files: {},
      status: "APPROVED",
    };
    await props.createPost(payload, clubId, postType);
  };

  fetchReactions = async (postId) => {
    this.setState({ clickedPostId: postId });
    const { props } = this;
    if (!this.reactions[postId]) {
      await props.getReactions(postId);
    }
  };

  createPostReaction = async (
    postId,
    reaction,
    myReaction,
  ) => {
    const { profileData } = this.state;
    const { props } = this;
    if (myReaction && myReaction === reaction) {
      await props.deleteReaction(postId, reaction);
      delete this.reactions[postId][profileData.data.id];
    }
    if (!myReaction) {
      await props.postReaction({}, postId, reaction);
    }
  };

  updateSelectedReactions = (postId) => {
    this.props.updateSelectedReactions(
      this.reactions[postId],
    );
  };

  render() {
    const { isModalOpen, toggleModal, clubMembersData } =
      this.props;
    const {
      resourceDisplay,
      postComment,
      isPosting,
      displayComments,
      profileData,
    } = this.state;

    return (
      <Modal
        size="xl"
        show={isModalOpen}
        onHide={toggleModal}
        centered
        className="gallery-modal">
        <div className="row modal-container">
          <div className="col-md-8 gallery-modal-left">
            <div
              className="nav-arrows"
              style={{
                position: "absolute",
                top: "50%",
                cursor: "pointer",
                marginLeft: "1rem",
              }}>
              <ArrowForwardIos
                style={{ transform: "rotate(180deg)" }}
                onClick={() => this.handlePrev()}
              />
            </div>
            <img
              src={JSON.parse(resourceDisplay.listing_image)[0] || logo}
              alt="contribution"
              style={{
                width: "inherit",
                height: "calc(90vh - 0.1rem)",
                objectFit: "contain",
              }}
            />
            <div
              className="nav-arrows arrow-right"
              style={{
                position: "absolute",
                top: "50%",
                right: 0,
                cursor: "pointer",
                marginRight: "2rem",
              }}>
              <ArrowForwardIos
                onClick={() => this.handleNext()}
              />
            </div>
          </div>

          <div className="col-md-4 pt-2 gallery-modal-right">
            <div className="post-item-author">
              <div className="post-avatar">
                {resourceDisplay.participant.picture && (
                  <img
                    className="img-fluid"
                    src={
                      resourceDisplay.participant.picture
                    }
                    alt="club-avatar"
                  />
                )}
                {!resourceDisplay.participant.picture && (
                  <div className="member-initials">
                    {resourceDisplay.participant
                      .firstName[0] +
                      resourceDisplay.participant
                        .lastName[0]}
                  </div>
                )}
              </div>
              <div className="post-author-name">
                {`${resourceDisplay.participant.firstName} ${resourceDisplay.participant.lastName}`}
              </div>
            </div>
            <div className="mr-3">
              <small>
                {resourceDisplay.listing_subtitle}
              </small>
            </div>

            <div className="comments-sections">
              <div className="comments-list">
                {this.props.getContributionsCommentsData &&
                  this.props.getContributionsCommentsData
                    .data &&
                  this.props.getContributionsCommentsData.data.map(
                    (comment) => (
                      <>
                        <div className="post-comment-block">
                          <div className="post-comment-item-author d-none d-lg-block d-xl-block">
                            <div className="post-avatar">
                              {comment.author.profile
                                .picture && (
                                  <img
                                    className="img-fluid"
                                    src={
                                      comment.author.profile
                                        .picture
                                    }
                                    alt="club-avatar"
                                  />
                                )}
                              {!comment.author.profile
                                .picture && (
                                  <div className="member-initials">
                                    {comment.author.profile
                                      .firstName[0] +
                                      comment.author.profile
                                        .lastName[0]}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="post-comment-item">
                            <div className="activity-content">
                              <div className="post-item-author">
                                <div className="post-avatar d-lg-none d-sm-block">
                                  {comment.author.profile
                                    .picture && (
                                      <img
                                        className="img-fluid"
                                        src={
                                          comment.author
                                            .profile.picture
                                        }
                                        alt="club-avatar"
                                      />
                                    )}
                                  {!comment.author.profile
                                    .picture && (
                                      <div className="member-initials">
                                        {comment.author
                                          .profile
                                          .firstName[0] +
                                          comment.author
                                            .profile
                                            .lastName[0]}
                                      </div>
                                    )}
                                </div>
                                <div className="post-author-name">
                                  {`${comment.author.profile.firstName} ${comment.author.profile.lastName}`}
                                  <br />
                                  <span className="post-item-member">
                                    {`${comment.author.roles[0]
                                      .slice(0, 1)
                                      .toUpperCase()}${comment.author.roles[0].slice(
                                        comment.author
                                          .roles[0].length -
                                        (comment.author
                                          .roles[0].length -
                                          1),
                                      )}`}
                                  </span>
                                </div>
                              </div>
                              <p className="activity-content-text pb-3">
                                {comment.content.body}
                              </p>
                            </div>
                            <div className="activity-stats">
                              <div className="activity-stat-item dropup">
                                <div
                                  className="dropdown-toggle activity-stat-icon handicon"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  onClick={() =>
                                    this.state.listReactions(
                                      comment.id,
                                    )
                                  }>
                                  <img
                                    src={likingIcon}
                                    alt="like-icon"
                                    width="20px"
                                  />
                                </div>
                                {Object.values(
                                  comment.reactions,
                                )
                                  .map((reaction) =>
                                    parseInt(
                                      reaction.total,
                                      10,
                                    ),
                                  )
                                  .reduce(
                                    (a, b) => a + b,
                                    0,
                                  )}
                                <ReactionsMenu
                                  reactions={this.reactions}
                                  resourceItem={comment}
                                  createPostReaction={
                                    this.createPostReaction
                                  }
                                  updateSelectedReactions={
                                    this
                                      .updateSelectedReactions
                                  }
                                />
                              </div>
                              <div
                                className="activity-stat-item"
                                style={{
                                  color:
                                    displayComments.includes(
                                      comment.id,
                                    ) &&
                                    colors.primaryColor,
                                }}
                                onClick={() =>
                                  this.onToggleComments(
                                    comment.id,
                                  )
                                }>
                                <div className="activity-stat-icon">
                                  <img
                                    src={
                                      displayComments.includes(
                                        comment.id,
                                      )
                                        ? commentIconOrange
                                        : commentIcon
                                    }
                                    alt="comment-icon"
                                    width="20px"
                                  />
                                </div>
                                {comment.totalResponses}
                              </div>
                              <div className="activity-stat-item">
                                <div className="activity-stat-icon">
                                  <img
                                    src={timeIcon}
                                    alt="time-icon"
                                    width="20px"
                                  />
                                </div>
                                {moment
                                  .unix(comment.dateCreated)
                                  .fromNow() || "N/A"}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="comment-replies-block">
                          {displayComments.includes(
                            comment.id,
                          ) && (
                              <div>
                                <div className="comments-list">
                                  {comment.responses.length >
                                    0 &&
                                    comment.responses.map(
                                      (reply) => (
                                        <>
                                          <div
                                            id={`commentId-${reply.id}`}
                                            className="post-comment-block">
                                            <div className="post-comment-item-author d-none d-lg-block d-xl-block">
                                              <div className="post-avatar">
                                                {reply.author
                                                  .profile
                                                  .picture && (
                                                    <img
                                                      className="img-fluid"
                                                      src={
                                                        reply
                                                          .author
                                                          .profile
                                                          .picture
                                                      }
                                                      alt="club-avatar"
                                                    />
                                                  )}
                                                {!reply.author
                                                  .profile
                                                  .picture && (
                                                    <div className="member-initials">
                                                      {reply
                                                        .author
                                                        .profile
                                                        .firstName[0] +
                                                        reply
                                                          .author
                                                          .profile
                                                          .lastName[0]}
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                            <div className="post-comment-item">
                                              <div className="activity-content">
                                                <div className="post-item-author">
                                                  <div className="post-avatar d-lg-none d-sm-block">
                                                    {reply
                                                      .author
                                                      .profile
                                                      .picture && (
                                                        <img
                                                          className="img-fluid"
                                                          src={
                                                            reply
                                                              .author
                                                              .profile
                                                              .picture
                                                          }
                                                          alt="club-avatar"
                                                        />
                                                      )}
                                                    {!reply
                                                      .author
                                                      .profile
                                                      .picture && (
                                                        <div className="member-initials">
                                                          {reply
                                                            .author
                                                            .profile
                                                            .firstName[0] +
                                                            reply
                                                              .author
                                                              .profile
                                                              .lastName[0]}
                                                        </div>
                                                      )}
                                                  </div>
                                                  <div className="post-author-name">
                                                    {`${reply.author.profile.firstName} ${reply.author.profile.lastName}`}
                                                    <br />
                                                    <span className="post-item-member">
                                                      {`${reply.author.roles[0]
                                                        .slice(
                                                          0,
                                                          1,
                                                        )
                                                        .toUpperCase()}${reply.author.roles[0].slice(
                                                          reply
                                                            .author
                                                            .roles[0]
                                                            .length -
                                                          (reply
                                                            .author
                                                            .roles[0]
                                                            .length -
                                                            1),
                                                        )}`}
                                                    </span>
                                                  </div>
                                                </div>
                                                <p className="activity-content-text pb-3">
                                                  {
                                                    reply
                                                      .content
                                                      .body
                                                  }
                                                </p>
                                              </div>
                                              <div className="activity-stats">
                                                <div className="activity-stat-item dropup">
                                                  <div
                                                    className="dropdown-toggle activity-stat-icon handicon"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    onClick={() =>
                                                      this.state.listReactions(
                                                        reply.id,
                                                      )
                                                    }>
                                                    <img
                                                      src={
                                                        likingIcon
                                                      }
                                                      alt="like-icon"
                                                      width="20px"
                                                    />
                                                  </div>
                                                  {Object.values(
                                                    reply.reactions,
                                                  )
                                                    .map(
                                                      (
                                                        reaction,
                                                      ) =>
                                                        parseInt(
                                                          reaction.total,
                                                          10,
                                                        ),
                                                    )
                                                    .reduce(
                                                      (
                                                        a,
                                                        b,
                                                      ) =>
                                                        a + b,
                                                      0,
                                                    )}
                                                  <ReactionsMenu
                                                    reactions={
                                                      this
                                                        .reactions
                                                    }
                                                    resourceItem={
                                                      reply
                                                    }
                                                    createPostReaction={
                                                      this
                                                        .createPostReaction
                                                    }
                                                    updateSelectedReactions={
                                                      this
                                                        .updateSelectedReactions
                                                    }
                                                  />
                                                </div>
                                                <div className="activity-stat-item">
                                                  <div className="activity-stat-icon">
                                                    <img
                                                      src={
                                                        timeIcon
                                                      }
                                                      alt="time-icon"
                                                      width="20px"
                                                    />
                                                  </div>
                                                  {moment
                                                    .unix(
                                                      reply.dateCreated,
                                                    )
                                                    .fromNow() ||
                                                    "N/A"}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ),
                                    )}
                                </div>

                                <div className="post-comment post-comment-reply">
                                  <CommentForm
                                    resource={comment}
                                    createPost={
                                      this.props.createPost
                                    }
                                    commentType="Reply"
                                    clubMembersData={
                                      clubMembersData
                                    }
                                    isContribution
                                  />
                                </div>
                              </div>
                            )}
                        </div>
                      </>
                    ),
                  )}
              </div>
            </div>

            <div className="post-comment contribution-comment mr-3">
              <CommentForm
                resource={resourceDisplay}
                createPost={this.props.createPost}
                commentType="Comment"
                clubMembersData={clubMembersData}
                isContribution
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

GalleryModal.propTypes = {};

export const mapStateToProps = (state) => ({
  clubMembersData: state.clubMembersReducer.clubMembersData,
  postContributionsCommentData:
    state.postContributionsCommentReducer
      .postContributionsCommentData,
  postContributionsCommentDataError:
    state.postContributionsCommentReducer
      .postContributionsCommentDataError,
  postContributionsCommentStatus:
    state.postContributionsCommentReducer
      .postContributionsCommentStatus,

  getContributionsCommentsData:
    state.getContributionsCommentsReducer
      .getContributionsCommentsData,

  postReactionDataError:
    state.postReactionReducer.postReactionDataError,
  postReactionData:
    state.postReactionReducer.postReactionData,
  postReactionStatus:
    state.postReactionReducer.postReactionStatus,

  deleteReactionDataError:
    state.deleteReactionReducer.deleteReactionDataError,
  deleteReactionData:
    state.deleteReactionReducer.deleteReactionData,
  deleteReactionStatus:
    state.deleteReactionReducer.deleteReactionStatus,

  getReactionsData:
    state.getReactionsReducer.getReactionsData,
  getReactionsDataError:
    state.getReactionsReducer.getReactionsDataError,
  getReactionsStatus:
    state.getReactionsReducer.getReactionsStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    createPost,
    getContributionComments,
    createPost,
    postReaction,
    getReactions,
    deleteReaction,
  }),
)(GalleryModal);
