import { toast } from 'react-toastify';
import {
  UPDATE_ACTIVITY_ERROR, UPDATE_ACTIVITY_SUCCESS,
} from '../actions/types';

const initialState = {
  updateActivityData: null,
  updateActivityDataError: null,
  updateActivityStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        updateActivityData: action.payload,
        updateActivityDataError: null,
        updateActivityStatus: 'success',
      };
    case UPDATE_ACTIVITY_ERROR:
      return {
        ...state,
        updateActivityDataError: action.payload,
        updateActivityData: null,
        updateActivityStatus: 'error',
      };
    default:
      return {
        ...state,
        updateActivityStatus: null,
      };
  }
};
