/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getLabelByName } from '../../helpers/helperFunctions';
import {
  ALL_WORD, NOTIFICATIONS, NO_WORD, VIEW,
} from '../../helpers/constants';
import NotificationPanelItem from './NotificationPanelItem';

class NotificationsDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationsLimit: 5,
    };
  }

  render() {
    const { unreadNotifications } = this.props;
    return (
      <div
        className="dropdown-menu dropdown-menu-right notifications-dropdown"
        aria-labelledby="dropdownNotifications"
      >
        <div className="notifications-dropdown-title">{getLabelByName(NOTIFICATIONS)}</div>

        {unreadNotifications.length === 0 && (
          <div className="text-center">
            {`${getLabelByName(NO_WORD)} ${getLabelByName(NOTIFICATIONS)}`}
          </div>
        )}

        <div className="notifications-items">
          {unreadNotifications.slice(0, this.state.notificationsLimit).map((notification) => (
            <div className="dropdown-notification-item" key={unreadNotifications.indexOf(notification)}>
              <NotificationPanelItem notification={notification} />
            </div>
          ))}
        </div>
        <Link className="view-all-notifications" to="/notifications">
        {`${getLabelByName(VIEW)} ${getLabelByName(ALL_WORD)} ${getLabelByName(NOTIFICATIONS)}`}
        </Link>
      </div>
    );
  }
}

NotificationsDropdown.propTypes = {
  unreadNotifications: PropTypes.any,
};

export default NotificationsDropdown;
