/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import { ReactComponent as HamburgerIcon } from "../../../assets/icons/hamburger.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/arrow-left.svg";
import ImageGridGallery from "../../ImageGridGallery";
import VideoPlayer from "../../generics/VideoPlayer";
import FileDownload from "../../generics/FileDownload";
import { Checkbox } from "@mui/material";
import { ArrowLeft, ArrowRight, GolfCourseSharp } from "@material-ui/icons";

const CourseMainContent = ({
  isOpen,
  toggleSidebar,
  lessonArticle,
  courseProgress,
  courseCompletion,
  previousLessonHandler,
  nextLessonHandler,
  handleLessonCompletion,
  action,
}) => {
  const { headerImage, ...articleImages } =
    lessonArticle?.images ?? {};

  const [markAsComplete, setMarkAsComplete] = useState(true);

  let lesson = null;

  useEffect(() => {
    lesson = action.lessons.find(l => parseInt(l.articleId) === parseInt(lessonArticle.id));
  }, [courseProgress]);

  const handleNext = (e) => {
    window.scrollTo(0, 0);
    handleLessonCompletion(markAsComplete);
    setMarkAsComplete(true);
    nextLessonHandler();
  }

  const handlePrevious = (e) => {
    window.scrollTo(0, 0);
    handleLessonCompletion(markAsComplete);
    setMarkAsComplete(true);
    previousLessonHandler();
  }

  const handleLessonStatusUpdate = (e) => {
    setMarkAsComplete(e.target.checked);
    handleLessonCompletion(e.target.checked);
  }

  return (
    <main className={isOpen ? "" : "is-closed"}>
      <header>
        <div className="title-wrapper">
          <button
            type="button"
            className="toggle-btn"
            onClick={toggleSidebar}>
            {isOpen ? <CloseIcon /> : <HamburgerIcon />}
          </button>

          <span className={isOpen ? "hide" : ""}>{lessonArticle?.content?.title}</span>
        </div>

        <div className="progress">
          <span>{`${courseCompletion}%`} complete</span>
        </div>
      </header>

      <div className="content">
        <div className="wrapper">
          {headerImage && (
            <div className="article-image">
              <img
                src={headerImage}
                alt={lessonArticle?.content?.title}
              />
            </div>
          )}

          {/* ================ ARTICLE BODY ================ */}
          <article
            className="mb-3"
            dangerouslySetInnerHTML={{
              __html: lessonArticle?.content?.body,
            }}
          />

          {/* ================ IMAGE GRID GALLERY ================ */}
          <div className="activity-content-images mb-3 pb-3">
            {Object.values(articleImages).length > 0 && (
              <ImageGridGallery
                images={Object.values(articleImages)}
              />
            )}
          </div>

          {/* ================ VIDEO PLAYER ================ */}
          {lessonArticle?.videos && (
            <div className="activity-content-videos mb-3 pb-3">
              {Object.values(lessonArticle?.videos).length >
                0 &&
                Object.values(lessonArticle.videos).map(
                  (video) => <VideoPlayer video={video} />,
                )}
            </div>
          )}

          {/* ================ FILE DOWNLOAD ================ */}
          {lessonArticle?.files && (
            <div className="activity-content-files pb-3">
              {Object.values(lessonArticle.files).length > 0 &&
                Object.values(lessonArticle.files).map(
                  (file) => <FileDownload file={file} />,
                )}
            </div>
          )}
        </div>
      </div>

      <footer>
        <button
          className="footer-cta-button p-4"
          onClick={handlePrevious}>
          <ArrowLeft className="arrow-left-icon" />
          Previous
        </button>
        <span>
          <Checkbox onChange={handleLessonStatusUpdate} checked={markAsComplete} /> Mark as complete
        </span>
        <button
          className="footer-cta-button p-4"
          onClick={handleNext}>
          Next
          <ArrowRight className="arrow-left-icon" />
        </button>
      </footer>
    </main>
  );
};

export default CourseMainContent;
