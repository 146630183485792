/* eslint-disable no-nested-ternary */
import "./userContribute.scss";
import React, { Component } from "react";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import moment from "moment";
import { toast } from "react-toastify";
import Navbar from "../../Navbar";
import {
  getActivity,
  getParticipants,
  participateCampaign,
  getParticipantContributionAction,
  getParticipantActions,
  getParticipantAllActions,
  postParticipantAction,
  updateParticipantAction,
  getAction,
} from "../../../redux/actions/campaignsActions";
import { getLanding } from "../../../redux/actions/landingActions";
import { getSessionAction } from "../../../redux/actions/authActions";
import { getInstitute } from "../../../redux/actions/institutesActions";
import { submitContribution } from "../../../redux/actions/contributionsActions";
import {
  getLabelByName,
  isActionSuccess,
  isActionError,
  checkSession,
  getProfileDataFromLocalStorage,
} from "../../../helpers/helperFunctions";
import Loading from "../../generics/Loading/Loading";
import Questionnaire from "../../Questionnaire";
import {
  ACTIVITY,
  ADD,
  colors,
  CONTRIBUTION,
  ENDED,
  END_ON,
  ENTRY,
  EXIT,
  GO_TO_PROFILE,
  HAS,
  NEW,
  NO_CONTRIBUTION_STEPS,
  PARTICIPANT,
  STEP,
  SUBMIT_CONTRIBUTION_BEHALF,
} from "../../../helpers/constants";
import activityDefaultImage from "../../../assets/manage-campaigns.svg";
import ViewContributionModal from "../../generics/Contribution/ViewContributionModal";
import userIcon from "../../../assets/icons/user.svg";

export class ManageContribute extends Component {
  state = {
    isDropDownOpen: false,
    activityData: null,
    profileData: getProfileDataFromLocalStorage(),
    contributionModalOpen: false,
    selectedContribution: null,
    showQuestionnaire: false,
    questionnaireId: null,
    actionId: null,
    questionnaireState: {},
    allParticipantActions: [],
    saveAndSubmit: false,
    enableSubmission: false,
    isSaving: false,
    participantId: null,
    noActionFound: false,
    showListing: false,
    newQuestionnaire: false,
    actionDetails: null,
  };

  async UNSAFE_componentWillMount() {
    const action = this.props.location?.state?.action;
    const index = this.props.location?.state?.index || 0;

    const {
      getActivity: getActivityAction,
      getSessionAction: getSession,
      getLanding: getLandingAction,
      getParticipants: getParticipantsAction,
      match: {
        params: { id: activityId },
      },
      location: { search },
    } = this.props;

    const query = new URLSearchParams(search);

    const reviewMode = query.get("review");

    this.setState({ reviewMode: !!reviewMode });

    await getActivityAction(activityId);

    await getSession();

    await getLandingAction();

    await getParticipantsAction(activityId);

    if (action?.id) {
      this.onClickStepHandler(action, index);
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      getParticipantContributionAction: getParticipantContribution,
      getInstitute: getInstituteAction,
      getParticipantActions: getParticipantAction,
      getParticipantAllActions: getParticipantAllAction,
      getAction: getActions,
      match: {
        params: { id: activityId },
      },
    } = this.props;

    const { props, state } = this;
    const { pathname } = state;
    const { status, data } = nextProps;
    await checkSession(data, status, pathname, props);

    const { profileData, activityData, actionId, showQuestionnaire } =
      this.state;

    this.insertDataIntoState(nextProps.getActivityData?.data);

    if (
      nextProps.participantsData?.data &&
      !nextProps.participantContributionData
    ) {
      nextProps.participantsData.data.map(async (participantData) => {
        if (participantData.user.id === profileData.data.id) {
          this.setState({
            participantId: participantData.id,
          });

          await getParticipantContribution(activityId, participantData.id);

          await getInstituteAction(activityData?.parentId);

          const participantId = this.getParticipantId();

          const participantActionId = this.actionParticipantId();

          if (participantId && participantActionId && showQuestionnaire) {
            const query2 = `?q={"id":${participantActionId}}`;
            await getParticipantAction(activityId, participantId, query2);
          }

          await getParticipantAllAction(activityId, participantId);
          await getActions(activityId, actionId);
        }
      });
    }

    if (
      nextProps.getActionStatus === "success" &&
      nextProps.getActionData.status === 200
    ) {
      const { questionnaireId } = nextProps.getActionData.data.meta;
      this.setState({
        questionnaireId,
      });
    }

    if (
      isActionSuccess(
        nextProps,
        "getParticipantActionsStatus",
        "getParticipantActionsData"
      ) &&
      nextProps.getParticipantActionsData &&
      nextProps.getParticipantActionsData.length > 0 &&
      nextProps.getParticipantActionsData.data[0].status === "COMPLETE"
    ) {
      this.setState({ enableSubmission: true });
    }

    if (
      isActionSuccess(
        nextProps,
        "getParticipantAllActionsStatus",
        "getParticipantAllActionsData"
      )
    ) {
      this.setState({
        allParticipantActions: nextProps.getParticipantAllActionsData.data,
      });
    }

    if (
      isActionSuccess(
        nextProps,
        "postParticipantActionStatus",
        "postParticipantActionData"
      )
    ) {
      this.actionAfterSave(nextProps.postParticipantActionData);
    }

    if (
      isActionSuccess(
        nextProps,
        "submitContributionStatus",
        "submitContributionData"
      )
    ) {
      this.redirectOnSuccessSave();
    }

    if (
      isActionError(
        nextProps,
        "postParticipantActionStatus",
        "postParticipantActionData"
      )
    ) {
      this.setState({ isSaving: false });
      toast.error(nextProps.postParticipantActionData.error.message);
    }

    if (
      isActionError(
        nextProps,
        "submitContributionStatus",
        "submitContributionData"
      )
    ) {
      this.setState({ isSaving: false });
      toast.error(nextProps.submitContributionData.error.message);
    }

    if (nextProps.getActionData?.data?.meta?.maxSubmissions !== "-1") {
      const { participantId } = this.state;

      const {
        history,
        location: { pathname },
      } = this.props;

      if (
        !participantId &&
        this.dataHasLoaded(nextProps.getActionData) &&
        this.dataHasLoaded(nextProps.getParticipantActionsData) &&
        nextProps.getParticipantActionsData.status === 200 &&
        nextProps.getActionData.data.meta.maxSubmissions === 0
      ) {
        toast.error("You have reached the maximum of listings you can create.");
        history.push(`${pathname}/listings`);
      }

      const participant = this.props.location?.state?.participant;

      if (participant) {
        this.setState({
          questionnaireId: this.getActivityAction()?.meta.questionnaireId,
          showQuestionnaire: true,
          actionId,
          isDropDownOpen: false,
          actionName: nextProps.getActionData?.data?.name,
          showListing: false,
          newQuestionnaire: true,
          questionnaireState: {},
        });
      }
    }

    setTimeout(() => this.setState({ noActionFound: false }), 1000);
  }

  async componentDidUpdate(prevState, nextState) {
    const {
      getAction: getActions,
      match: {
        params: { id: activityId },
      },
    } = this.props;

    const { actionId } = this.state;

    if (actionId !== nextState.actionId) {
      await getActions(activityId, actionId);
    }
  }

  insertDataIntoState = (data) => {
    this.setState({ activityData: data });
  };

  setDropDown = () => {
    const { isDropDownOpen } = this.state;
    this.setState({ isDropDownOpen: !isDropDownOpen });
  };

  closeModal = () => this.setState({ contributionModalOpen: false });

  selectContribution = (contribution) =>
    this.setState({
      selectedContribution: contribution,
      contributionModalOpen: true,
    });

  updateQuestionnaireState = (input_key, value) => {
    const { questionnaireState } = this.state;
    this.setState({
      questionnaireState: {
        ...questionnaireState,
        [input_key]: value,
      },
    });
  };

  getParticipantAction = () => {
    const { getParticipantActionsData } = this.props;

    let participantAction;

    if (this.dataHasLoaded(getParticipantActionsData)) {
      participantAction = getParticipantActionsData.data[0];
    }

    return participantAction;
  };

  dataHasLoaded = (state) => !!(state && state.data);

  redirectOnSuccessSave = async () => {
    const { actionDetails, actionId } = this.state;
    const participant = this.props.location?.state?.participant;

    const {
      match: {
        params: { id: campaignId, handle },
      },
    } = this.props;

    this.props.history.push(
      `/manage/activities/${campaignId}/${handle}/participants/${participant?.id}`
    );
  };

  getParticipantId = () => {
    const participant = this.props.location?.state?.participant;

    if (participant?.id) {
      return participant.id;
    }

    const {
      match: {
        params: { id: campaignId },
      },
    } = this.props;

    const {
      profileData: {
        data: { activities },
      },
    } = this.state;

    const participantId = Object.values(activities).find(
      (activity) => activity.activityId === campaignId
    )?.participantId;

    return participantId;
  };

  isMultiEntryAction = () => {
    const { getActionData } = this.props;
    return (
      this.dataHasLoaded(getActionData) &&
      getActionData.data.meta.maxSubmissions > 1
    );
  };

  questionnaireHasUpdated = () => {
    const { questionnaireState } = this.state;

    return Object.keys(questionnaireState).length > 0;
  };

  saveSubmitAction = () => {
    this.setState({
      saveAndSubmit: true,
      newQuestionnaire: false,
      isSaving: true,
      questionnaireState: {},
    });
    this.postAction();
  };

  postAction = async () => {
    const { props } = this;

    const { id: campaignId } = props.match.params;

    const { actionId, questionnaireState } = this.state;

    const participantId = this.getParticipantId();

    const payload = {
      status: "ACTIVE",
      visibility: "PUBLIC",
      data: { ...questionnaireState },
      meta: {},
      participantId,
    };

    await props.postParticipantAction(
      campaignId,
      participantId,
      actionId,
      payload
    );
  };

  updateAction = async () => {
    const {
      updateParticipantAction: updateParticipant,
      match: {
        params: { id: campaignId },
      },
    } = this.props;

    const { questionnaireState } = this.state;

    const participantId = this.getParticipantId();

    const participantAction = this.getParticipantAction();

    participantAction.data = {
      ...participantAction?.data,
      ...questionnaireState,
    };

    await updateParticipant(
      campaignId,
      participantId,
      participantAction,
      participantAction.actionId
    );
  };

  actionAfterSave = async (participantActionData) => {
    const {
      match: {
        params: { id: campaignId },
      },
      submitContribution: submitContributionAction,
    } = this.props;

    const { saveAndSubmit } = this.state;

    if (saveAndSubmit) {
      const participantId = this.getParticipantId();

      const payload = {
        participantActionId: participantActionData.data.id,
      };
      await submitContributionAction(campaignId, participantId, payload);
    }
    this.setState({ isSaving: false });
  };

  setNoActionFound = () => {
    const { noActionFound } = this.state;

    if (noActionFound === false) {
      this.setState({ noActionFound: true });
    }
  };

  actionParticipantId = () => {
    const { actionId, newQuestionnaire } = this.state;

    const { getParticipantAllActionsData } = this.props;

    let participantActionId;

    if (getParticipantAllActionsData?.data?.length > 0) {
      getParticipantAllActionsData.data.forEach((participantAction) => {
        if (actionId === participantAction.actionId) {
          participantActionId = participantAction.id;
        }
      });
    }

    return !newQuestionnaire ? participantActionId : null;
  };

  getListingsData = () => {
    const { getParticipantActionsData } = this.props;

    let listings = [];
    if (this.dataHasLoaded(getParticipantActionsData)) {
      listings = getParticipantActionsData?.data.sort(
        (a, b) =>
          (b.lastUpdated || b.dateCreated) - (a.lastUpdated || a.dateCreated)
      );
    }

    return listings;
  };

  getListingsByStatus = (status) => {
    const listings = this.getListingsData();

    return listings.filter((listing) => listing.status === status);
  };

  getListingsLengthByStatus = (status) => {
    return this.getListingsByStatus(status).length;
  };

  setEnableSubmission = (value) => {
    const { enableSubmission } = this.state;

    if (enableSubmission !== value) {
      this.setState({ enableSubmission: value });
    }
  };

  getContributionsLeftLength = () => {
    const { getActionData } = this.props;

    let contributionsLeft = 0;

    if (this.dataHasLoaded(getActionData) && this.getActivityAction()) {
      contributionsLeft =
        this.getActivityAction()?.meta.maxSubmissions -
        this.getListingsData().length;
    }
    return contributionsLeft;
  };

  getActivityAction = () => {
    const { getActionData } = this.props;

    return getActionData?.data;
  };

  onClickStepHandler = async (action, index) => {
    this.setState({
      questionnaireId: action?.meta.questionnaireId,
      showQuestionnaire: true,
      actionId: action.id,
      isDropDownOpen: false,
      actionName: action.name,
      actionDescription: action.profile.description,
      currentStep: index + 1,
      actionDetails: action,
      newQuestionnaire: false,
      questionnaireState: {},
    });

    const participantId = this.getParticipantId();

    const {
      getParticipantActions: getParticipantAction,
      match: {
        params: { id: activityId },
      },
    } = this.props;

    const query2 = `?q={"actionId":${action.id}}`;

    await getParticipantAction(activityId, participantId, query2);

    if (
      parseInt(action?.meta.maxSubmissions, 10) > 1 ||
      action?.meta.maxSubmissions === "-1"
    ) {
      this.setState({
        showListing: true,
        showQuestionnaire: false,
      });
    } else {
      this.setState({
        showListing: false,
        showQuestionnaire: true,
      });
    }
  };

  render() {
    const {
      isDropDownOpen,
      activityData,
      showQuestionnaire,
      actionName,
      currentStep,
      questionnaireId,
      reviewMode,
      allParticipantActions,
      noActionFound,
      actionId,
      selectedContribution,
      contributionModalOpen,
      showListing,
      newQuestionnaire,
      actionDescription,
      actionDetails,
    } = this.state;

    const {
      getActivityLoading,
      clubData,
      participantContributionData,
      match: {
        params: { id: activityId },
      },
      getParticipantContributionAction: getUserContributionsAction,
    } = this.props;

    if (!activityData && getActivityLoading) {
      return <Loading />;
    }

    if (noActionFound) {
      toast.warn(getLabelByName(NO_CONTRIBUTION_STEPS));
    }

    const participant = this.props.location?.state?.participant;

    return (
      <>
        <Navbar pathname="explore" />
        <section className="contribute-section">
          <div className="left-div">
            <div className="left-title">
              <div className="publications">
                <img
                  src={activityData?.profile?.picture || activityDefaultImage}
                  alt=""
                  style={{ backgroundColor: "#e8daca" }}
                />
                <div className="profile">
                  <button
                    type="button"
                    style={{
                      border: "none",
                      outline: "none",
                      background: "none",
                      textAlign: "start",
                    }}
                    onClick={async () => {
                      this.setState({
                        showListing: false,
                        showQuestionnaire: false,
                        questionnaireState: {},
                      });
                      await getUserContributionsAction(
                        activityId,
                        this.getParticipantId()
                      );
                    }}
                  >
                    <h1>{activityData?.name}</h1>
                  </button>
                  <div className="profile-info">
                    <img src={clubData?.data?.profile?.picture} alt="" />

                    <h1>{clubData?.data?.name}</h1>
                  </div>
                </div>
              </div>
              <div className="contribution-timer">
                {(activityData?.endDate !== null &&
                  activityData?.endDate * 1000 < new Date() && (
                    <p>
                      {`${getLabelByName(ACTIVITY)} ${getLabelByName(
                        HAS
                      )} ${getLabelByName(ENDED)}`}
                    </p>
                  )) ||
                  (activityData?.endDate !== null && (
                    <>
                      <p>
                        {`${getLabelByName(ACTIVITY)} ${getLabelByName(
                          END_ON
                        )} `}
                        {moment(activityData?.endDate * 1000).format(
                          "DD MMM YYYY hh:mm "
                        )}
                      </p>
                    </>
                  ))}
              </div>
            </div>

            <div className="responsiveness-only">
              <div className="arrow-down-dropdown">
                <button type="button" onClick={() => this.setDropDown()}>
                  <KeyboardArrowDownIcon />
                </button>
              </div>
            </div>

            <div
              className={`${
                isDropDownOpen
                  ? "left-body show-steps"
                  : "left-body not-show-step"
              }`}
            >
              {activityData?.actions &&
                activityData.actions.map(
                  (action, index) =>
                    action.status === "ACTIVE" && (
                      <div className="contribute-step">
                        <button
                          type="button"
                          onClick={() => this.onClickStepHandler(action, index)}
                        >
                          <p>{`${getLabelByName(STEP)} ${index + 1}`}</p>
                          <h1>{action.name}</h1>
                        </button>
                      </div>
                    )
                )}

              <div className="exit-button">
                <button type="button">
                  <Link
                    to={`/manage/activities/${activityData?.id}/${activityData?.handle}/participants/${participant?.id}`}
                    style={{ color: "#f38f46" }}
                  >
                    {`${getLabelByName(EXIT)} ${getLabelByName(
                      CONTRIBUTION
                    ).toLowerCase()}`}
                  </Link>
                </button>
              </div>
            </div>
          </div>

          <div className="right-div">
            {participant && (
              <div className="behalf-block">
                <div className="d-flex justify-content-between behalf-header">
                  <div>{getLabelByName(PARTICIPANT)}</div>
                  <div>
                    <Link
                      to={`/manage/activities/${activityData?.id}/${activityData?.handle}/participants/${participant?.id}`}
                      style={{ color: colors.primaryColor }}
                    >
                      {getLabelByName(GO_TO_PROFILE)}
                    </Link>
                  </div>
                </div>

                <div className="d-flex justify-content-between behalf-participant">
                  <div className="d-flex align-items-center participant-details">
                    <div>
                      <img
                        className="mr-3"
                        src={participant?.user?.profile?.picture || userIcon}
                        alt="participant-avatar"
                        style={{ width: "48px", borderRadius: "50%" }}
                      />
                    </div>
                    <div className="font-weight-bold">
                      {`${participant?.user?.profile?.firstName} ${participant?.user?.profile?.lastName}`}
                    </div>
                  </div>
                  <div className="join-button">
                    <button
                      type="button"
                      className="btn btn-lg submit-btn"
                      onClick={() => this.saveSubmitAction()}
                      style={{ width: "auto" }}
                    >
                      {getLabelByName(SUBMIT_CONTRIBUTION_BEHALF)}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {showListing ? (
              <>
                <div className="action-step-header">
                  <div className="current-step">
                    <h1>
                      {`${getLabelByName(STEP)} ${currentStep}: ${actionName}`}
                    </h1>
                    <p>{actionDescription}</p>
                  </div>
                  {(this.getContributionsLeftLength() > 0 ||
                    this.getActivityAction()?.meta.maxSubmissions === "-1") && (
                    <div className="add-listing-button">
                      <button
                        type="button"
                        onClick={() => {
                          this.setState({
                            questionnaireId:
                              this.getActivityAction()?.meta.questionnaireId,
                            showQuestionnaire: true,
                            actionId,
                            isDropDownOpen: false,
                            actionName,
                            showListing: false,
                            newQuestionnaire: true,
                            questionnaireState: {},
                          });
                        }}
                      >
                        {`${getLabelByName(ADD)} ${getLabelByName(
                          NEW
                        ).toLowerCase()} ${getLabelByName(ENTRY)}`}
                      </button>
                    </div>
                  )}
                </div>
              </>
            ) : showQuestionnaire && clubData ? (
              <>
                <div className="current-step">
                  <h1>
                    {`${getLabelByName(STEP)} ${currentStep}: ${actionName}`}
                  </h1>
                  <p>{actionDescription}</p>
                </div>
                <>
                  <Questionnaire
                    questionnaireId={questionnaireId}
                    clubId={clubData?.data?.id}
                    updateQuestionnaireState={this.updateQuestionnaireState}
                    editAction={null}
                    reviewMode={reviewMode}
                    allParticipantActions={allParticipantActions}
                    setEnableSubmission={this.setEnableSubmission}
                    actionId={actionId}
                    participantId={this.getParticipantId()}
                    participantActionId={this.actionParticipantId()}
                    activityId={activityData?.id}
                    actionDetails={actionDetails}
                    newQuestionnaire={newQuestionnaire}
                  />
                </>
              </>
            ) : (
              <Loading />
            )}
          </div>

          {/* View Contribution Modal */}
          {contributionModalOpen && (
            <ViewContributionModal
              contribution={selectedContribution}
              isModalOpen={contributionModalOpen}
              closeModal={this.closeModal}
            />
          )}
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getActivityData: state.getActivityReducer.getActivityData,

  getActivityLoading: state.getActivityReducer.getActivityLoading,

  clubData: state.instituteReducer.instituteData,

  participantsData: state.getParticipantsReducer.getParticipantsData,

  participantContributionData:
    state.getParticipantContributionReducer.participantContributionData,

  participantContributionLoading:
    state.getParticipantContributionReducer.participantContributionLoading,

  participantContributionError:
    state.getParticipantContributionReducer.participantContributionError,

  getParticipantActionsDataError:
    state.getParticipantActionsReducer.getParticipantActionsDataError,

  getParticipantActionsData:
    state.getParticipantActionsReducer.getParticipantActionsData,

  getParticipantActionsStatus:
    state.getParticipantActionsReducer.getParticipantActionsStatus,

  getParticipantActionsLoading:
    state.getParticipantActionsReducer.getParticipantActionsLoading,

  getParticipantAllActionsDataError:
    state.getParticipantAllActionsReducer.getParticipantAllActionsDataError,

  getParticipantAllActionsData:
    state.getParticipantAllActionsReducer.getParticipantAllActionsData,

  getParticipantAllActionsStatus:
    state.getParticipantAllActionsReducer.getParticipantAllActionsStatus,

  postParticipantActionDataError:
    state.postParticipantActionReducer.postParticipantActionDataError,

  postParticipantActionData:
    state.postParticipantActionReducer.postParticipantActionData,

  postParticipantActionStatus:
    state.postParticipantActionReducer.postParticipantActionStatus,

  updateParticipantActionDataError:
    state.updateParticipantActionReducer.updateParticipantActionDataError,

  updateParticipantActionData:
    state.updateParticipantActionReducer.updateParticipantActionData,

  updateParticipantActionStatus:
    state.updateParticipantActionReducer.updateParticipantActionStatus,

  submitContributionDataError:
    state.submitContributionReducer.submitContributionDataError,

  submitContributionData:
    state.submitContributionReducer.submitContributionData,

  submitContributionStatus:
    state.submitContributionReducer.submitContributionStatus,

  getActionDataError: state.getActionReducer.getActionDataError,

  getActionData: state.getActionReducer.getActionData,

  getActionStatus: state.getActionReducer.getActionStatus,
});

const mapDispatchToProps = {
  getActivity,
  getParticipants,
  participateCampaign,
  getLanding,
  getAction,
  getSessionAction,
  getInstitute,
  getParticipantContributionAction,
  getParticipantActions,
  postParticipantAction,
  updateParticipantAction,
  submitContribution,
  getParticipantAllActions,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ManageContribute);
