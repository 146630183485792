/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getActivities } from '../../../redux/actions/campaignsActions';
import { getUserPosts } from '../../../redux/actions/postActions';
import { getContributions, getOutputs } from '../../../redux/actions/contributionsActions';
import { getLabelByName, getProfileDataFromLocalStorage } from '../../../helpers/helperFunctions';
import {
  FIND, FIND_ACTIVITY_DESCRIPTION, FIND_CLUB_DESCRIPTION, FIND_CONTRIBUTION_DESCRIPTION, NEXT,
} from '../../../helpers/constants';
import { getUserClubs } from '../../../redux/actions/institutesActions';
import SearchComponent from '../../generics/SearchComponent';
import ResourceItemCard from './ResourceItemCard';

class FindResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      findResourcesDescriptions: {
        club: FIND_CLUB_DESCRIPTION,
        activity: FIND_ACTIVITY_DESCRIPTION,
        contribution: FIND_CONTRIBUTION_DESCRIPTION,
      },
      searchInput: '',
      details: [],
      fliteredResources: [],
      profileId: getProfileDataFromLocalStorage().data.id,
    };
  }

  render() {
    const { resourceType, selectedResource } = this.props;
    const {
      findResourcesDescriptions, searchInput,
    } = this.state;

    return (
      <>
        <div className="create-article-header">
          <div className="create-article-title">
            <div className="article-title">{`${getLabelByName(FIND)} ${getLabelByName(resourceType)}`}</div>
            <div className="article-helper">
              {getLabelByName(findResourcesDescriptions[resourceType])}
            </div>
          </div>
          <div>
            <div className="edit-button">
              <button
                type="button"
                className="btn btn-lg submit-btn"
                disabled={!selectedResource}
                onClick={() => this.props.setPageTracking('create-promo')}
              >
                {getLabelByName(NEXT)}
              </button>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <SearchComponent
            searchInput={searchInput}
            handleChange={this.handleChange}
            clearSearch={this.clearSearch}
            resourceType={resourceType}
            submitSearch={this.submitSearch}
          />
        </div>
      <ul>
        {this.getResourcesToDisplay().map((resource) => (
            <ResourceItemCard
              resourceType={this.props.resourceType}
              resource={resource}
              selectedResource={selectedResource}
              selectResource={this.props.selectResource}
            />
        ))}
      </ul>
      </>
    );
  }

  async UNSAFE_componentWillMount() {
    if (this.props.resourceType === 'club') {
      await this.props.getUserClubs();
    }
    if (this.props.resourceType === 'activity') {
      await this.props.getActivities();
    }
    if (this.props.resourceType === 'output') {
      await this.props.getOutputs();
    }
    if (this.props.resourceType === 'article') {
      const query = '?q={"type":{"_in":["POST","ARTICLE", "COMMENT"]}}';
      await this.props.getUserPosts(query);
    }
    if (this.props.resourceType === 'contribution') {
      const query = `?q={"userId":${this.state.profileId}}`;
      await this.props.getContributions();
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.resourceType === 'club' && this.dataHasLoaded(nextProps.getUserClubsData)) {
      this.setState({ details: nextProps.getUserClubsData.data });
    }
    if (this.props.resourceType === 'activity' && this.dataHasLoaded(nextProps.getActivitiesData)) {
      this.setState({ details: nextProps.getActivitiesData.data });
    }
    if (this.props.resourceType === 'output' && this.dataHasLoaded(nextProps.getOutputsData)) {
      this.setState({ details: nextProps.getOutputsData.data });
    }
    if (this.props.resourceType === 'article' && this.dataHasLoaded(nextProps.getNewsFeedData)) {
      this.setState({ details: nextProps.getNewsFeedData.data });
    }
    if (this.props.resourceType === 'contribution' && this.dataHasLoaded(nextProps.getContributionsData)) {
      this.setState({ details: nextProps.getContributionsData.data });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    this.searchClubs();
  };

  submitSearch = async (e) => {
    e.preventDefault();
    this.searchClubs();
  };

  clearSearch = async () => {
    this.setState({ searchInput: '' });
  };

  dataHasLoaded = (state) => !!(state && state.data);

  getResourcesToDisplay = () => {
    const { searchInput, details, fliteredResources } = this.state;
    return !searchInput ? details : fliteredResources;
  };

  searchClubs = () => {
    const { searchInput, details } = this.state;
    const regex = new RegExp(searchInput.toLowerCase(), 'g');
    if (this.props.resourceType !== 'contribution') {
      const fliteredResources = details.filter((club) => club.name.toLowerCase().match(regex));
      this.setState({ fliteredResources });
    }

    const filteredContributions = details.filter((contribution) => (
      contribution.data.author_name
      && contribution.data.author_name.toLowerCase().match(regex))
      || (
        contribution.data.publication_title
        && contribution.data.publication_title.toLowerCase().match(regex)));
    this.setState({ fliteredResources: filteredContributions });
  };
}

FindResource.propTypes = {
  resourceType: PropTypes.any,
  selectedResource: PropTypes.any,
  selectResource: PropTypes.func,
  getUserPosts: PropTypes.func,
  getNewsFeedData: PropTypes.any,
  setPageTracking: PropTypes.func,
  getUserClubs: PropTypes.func,
  getUserClubsData: PropTypes.any,
  getActivities: PropTypes.any,
  getActivitiesData: PropTypes.shape({
    data: PropTypes.shape({}),
  }),
  getContributions: PropTypes.func,
  getContributionsData: PropTypes.any,
  getOutputsData: PropTypes.any,
  getOutputs: PropTypes.func,
};

export const mapStateToProps = (state) => ({
  getUserClubsData: state.getUserClubsReducer.getUserClubsData,
  getActivitiesData: state.getActivitiesReducer.getActivitiesData,
  getContributionData: state.getActivitiesReducer.getActivitiesData,
  getUserClubsDataError: state.getUserClubsReducer.getUserClubsDataError,
  getUserClubsStatus: state.getUserClubsReducer.getUserClubsStatus,
  getContributionsData: state.getContributionsReducer.getContributionsData,
  getNewsFeedData: state.getNewsFeedReducer.getNewsFeedData,
  getOutputsData: state.getOutputsReducer.getOutputsData,

});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getUserClubs, getActivities, getContributions, getUserPosts, getOutputs,
  }),
)(FindResource);
