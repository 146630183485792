/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import attachIcon from '../../../../assets/icons/attach-document.svg';

class UploadDoc extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="questionnaire-section">
        <div className="card section-card image-card">
          <div
            className="create-card-image-preview"
            style={{
              borderRadius: 'none',
              backgroundColor: 'unset',
            }}
          >
            <div className="actions-buttons">
              <div className="action-button">
                <label htmlFor="customFileHeader">
                  <img
                    src={attachIcon}
                    alt="upload-icon"
                    width="100%"
                    height="100%"
                  />
                </label>
                <input
                  type="file"
                  className="custom-file-inputz mb-5"
                  id="customFileHeader"
                  // onChange={(e) => this.fileChange(e)}
                  accept=".pdf, .docx, .doc, .xls, .xlsx, .txt, .odt, .zip"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UploadDoc;
