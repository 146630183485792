import {
  FILTER_MOST_POPULAR_CLUBS,
  FILTER_MOST_ACTIVE_CLUBS_ERROR,
  FILTER_MOST_ACTIVE_CLUBS,
  FILTER_MOST_POPULAR_CLUBS_ERROR,
  FILTER_MOST_ACTIVE_ACTIVITIES,
  FILTER_MOST_ACTIVE_ACTIVITIES_ERROR,
} from './types';
import apiCall from '../../helpers/apiCall';

const filterType = (type, payload) => ({
  type,
  payload,
});

export const getMostActiveActivities = () => (
  dispatch,
) => {
  apiCall.get('/activity/active_activities')
    .then((response) => {
      dispatch(filterType(FILTER_MOST_ACTIVE_ACTIVITIES, response.data));
    })
    .catch((err) => {
      dispatch(filterType(FILTER_MOST_ACTIVE_ACTIVITIES_ERROR, err));
    });
};

export const getMostPopularClubs = () => (
  dispatch,
) => {
  apiCall.get('/activity/popular_clubs')
    .then((response) => {
      dispatch(filterType(FILTER_MOST_POPULAR_CLUBS, response.data));
    })
    .catch((err) => {
      dispatch(filterType(FILTER_MOST_POPULAR_CLUBS_ERROR, err));
    });
};

export const getMostActiveClubs = () => (
  dispatch,
) => {
  apiCall.get('/activity/active_clubs')
    .then((response) => {
      dispatch(filterType(FILTER_MOST_ACTIVE_CLUBS, response.data));
    })
    .catch((err) => {
      dispatch(filterType(FILTER_MOST_ACTIVE_CLUBS_ERROR, err));
    });
};
