/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ItemCard from './ItemCard';

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  centerPadding: 30,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const Carousel = ({
  title,
  carouselData = [],
  type,
  isLoading,
  defaultIcon,
}) => {
  const displayData = carouselData && carouselData.data ? carouselData.data : [];

  const activeDisplayData = displayData.filter(data => data.status === "ACTIVE")

  return (
    <div className={`${title.toLowerCase()}-section`}>
      <div className="container ml-2">{title}</div>
      <div className="container my-3">
        <Slider className="" {...settings}>
          {isLoading && activeDisplayData.length === 0 && (
            <div className="spinner-border spinner-border-lg" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
          {activeDisplayData.length > 0
            && activeDisplayData.map((item, index) => (
              <ItemCard
                item={item}
                index={index}
                type={type}
                defaultIcon={defaultIcon}
              />
            ))}

          {!isLoading && activeDisplayData.length === 0 && (
            <p>
              No
              {' '}
              {title.toLowerCase()}
              {' '}
              found!
            </p>
          )}
        </Slider>
      </div>
    </div>
  );
};

Carousel.propTypes = {
  title: PropTypes.string,
  carouselData: PropTypes.any,
  listData: PropTypes.any,
  type: PropTypes.any,
  isLoading: PropTypes.any,
  defaultIcon: PropTypes.any,
};
