/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable global-require */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import likingIcon from '../assets/reactions/like.svg';
import celebrateIcon from '../assets/reactions/celebrate.svg';
import supportIcon from '../assets/reactions/support.svg';
import loveIcon from '../assets/reactions/love.svg';
import insightfulIcon from '../assets/reactions/insightful.svg';
import curiousIcon from '../assets/reactions/curious.svg';
import dislikeIcon from '../assets/reactions/dislike.svg';
import { getLabelByName } from '../helpers/helperFunctions';
import { ALL_WORD, VIEW_REACTIONS } from '../helpers/constants';

class ReactionsModal extends Component {
  state = {};

  render() {
    const { reactions } = this.props;
    const allReactions = reactions ? Object.values(reactions) : [];
    const likeReactions = allReactions.filter((reaction) => reaction.name === 'like');
    const celebrateReactions = allReactions.filter((reaction) => reaction.name === 'celebrate');
    const supportReactions = allReactions.filter((reaction) => reaction.name === 'support');
    const loveReactions = allReactions.filter((reaction) => reaction.name === 'love');
    const insightfulReactions = allReactions.filter((reaction) => reaction.name === 'insightful');
    const curiousReactions = allReactions.filter((reaction) => reaction.name === 'curious');
    const dislikeReactions = allReactions.filter((reaction) => reaction.name === 'dislike');

    return (
      <div
        className="modal fade reactions-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="reactionsModal"
        aria-hidden="true"
        id="reactionsModal"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content modal-card p-4" style={{ background: '#F7F4F2' }}>
            <div className="create-club-header">
              <div className="header-left">
                <p className="header-modal-title">Reactions</p>
                <p className="header-modal-subtitle">
                  {getLabelByName(VIEW_REACTIONS)}
                </p>
              </div>
            </div>

            <div className="reactions-content">
              <ul className="nav justify-content-center" id="reactionsTabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    id="all-reactions-tab"
                    data-toggle="tab"
                    href="#all-reactions"
                    role="tab"
                    aria-controls="all-reactions"
                    aria-selected="true"
                  >
                    {getLabelByName(ALL_WORD)}
                    <span className="reaction-number">
                      (
                      {allReactions.length || 0}
                      )
                    </span>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="like-tab"
                    data-toggle="tab"
                    href="#like"
                    role="tab"
                    aria-controls="like"
                    aria-selected="false"
                  >
                    <img
                      src={likingIcon}
                      alt="like-icon"
                      width="25px"
                    />
                    <span className="reaction-number">
                      (
                      {likeReactions.length || 0}
                      )
                    </span>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="celebrate-tab"
                    data-toggle="tab"
                    href="#celebrate"
                    role="tab"
                    aria-controls="celebrate"
                    aria-selected="false"
                  >
                    <img
                      src={celebrateIcon}
                      alt="celebrate-icon"
                      width="25px"
                    />
                    <span className="reaction-number">
                      (
                      {celebrateReactions.length || 0}
                      )
                    </span>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="support-tab"
                    data-toggle="tab"
                    href="#support"
                    role="tab"
                    aria-controls="support"
                    aria-selected="false"
                  >
                    <img
                      src={supportIcon}
                      alt="support-icon"
                      width="25px"
                    />
                    <span className="reaction-number">
                      (
                      {supportReactions.length || 0}
                      )
                    </span>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="love-tab"
                    data-toggle="tab"
                    href="#love"
                    role="tab"
                    aria-controls="love"
                    aria-selected="false"
                  >
                    <img
                      src={loveIcon}
                      alt="love-icon"
                      width="25px"
                    />
                    <span className="reaction-number">
                      (
                      {loveReactions.length || 0}
                      )
                    </span>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="insightful-tab"
                    data-toggle="tab"
                    href="#insightful"
                    role="tab"
                    aria-controls="insightful"
                    aria-selected="false"
                  >
                    <img
                      src={insightfulIcon}
                      alt="insightful-icon"
                      width="25px"
                    />
                    <span className="reaction-number">
                      (
                      {insightfulReactions.length || 0}
                      )
                    </span>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="curious-tab"
                    data-toggle="tab"
                    href="#curious"
                    role="tab"
                    aria-controls="curious"
                    aria-selected="false"
                  >
                    <img
                      src={curiousIcon}
                      alt="curious-icon"
                      width="25px"
                    />
                    <span className="reaction-number">
                      (
                      {curiousReactions.length || 0}
                      )
                    </span>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="dislike-tab"
                    data-toggle="tab"
                    href="#dislike"
                    role="tab"
                    aria-controls="dislike"
                    aria-selected="false"
                  >
                    <img
                      src={dislikeIcon}
                      alt="dislike-icon"
                      width="25px"
                    />
                    <span className="reaction-number">
                      (
                      {dislikeReactions.length || 0}
                      )
                    </span>
                  </a>
                </li>
              </ul>
              <hr style={{ width: '100%' }} />
              <div className="tab-content" id="reactionsTabsContent">
                <div
                  className="tab-pane fade show active"
                  id="all-reactions"
                  role="tabpanel"
                  aria-labelledby="all-reactions-tab"
                >
                  {allReactions.length <= 0 && 'No reaction yet!'}
                  <div className="d-flex">
                    {allReactions.map((member, index) => (
                      <Link to={`/member/${member.id}`}>
                        <div className="member-info" key={index}>
                          <div className="member-avatar">
                            {member.user.profile.picture && (
                              <img
                                className="img-fluid"
                                src={member.user.profile.picture}
                                alt="club-avatar"
                              />
                            )}
                            {!member.user.profile.picture && (
                              <div className="member-initials">
                                {member.user.profile.firstName[0]
                                  + member.user.profile.lastName[0]}
                              </div>
                            )}
                          </div>
                          <div className="member-name">
                            {member.user.profile.firstName}
                            {' '}
                            {member.user.profile.lastName}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="like"
                  role="tabpanel"
                  aria-labelledby="like-tab"
                >
                  <div className="d-flex">
                    {likeReactions.map((member, index) => (
                      <Link to={`/member/${member.id}`}>
                        <div className="member-info" key={index}>
                          <div className="member-avatar">
                            {member.user.profile.picture && (
                              <img
                                className="img-fluid"
                                src={member.user.profile.picture}
                                alt="club-avatar"
                              />
                            )}
                            {!member.user.profile.picture && (
                              <div className="member-initials">
                                {member.user.profile.firstName[0]
                                  + member.user.profile.lastName[0]}
                              </div>
                            )}
                          </div>
                          <div className="member-name">
                            {member.user.profile.firstName}
                            {' '}
                            {member.user.profile.lastName}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="celebrate"
                  role="tabpanel"
                  aria-labelledby="celebrate-tab"
                >
                  <div className="d-flex">
                    {celebrateReactions.map((member, index) => (
                      <Link to={`/member/${member.id}`}>
                        <div className="member-info" key={index}>
                          <div className="member-avatar">
                            {member.user.profile.picture && (
                              <img
                                className="img-fluid"
                                src={member.user.profile.picture}
                                alt="club-avatar"
                              />
                            )}
                            {!member.user.profile.picture && (
                              <div className="member-initials">
                                {member.user.profile.firstName[0]
                                  + member.user.profile.lastName[0]}
                              </div>
                            )}
                          </div>
                          <div className="member-name">
                            {member.user.profile.firstName}
                            {' '}
                            {member.user.profile.lastName}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="support"
                  role="tabpanel"
                  aria-labelledby="support-tab"
                >
                  <div className="d-flex">
                    {supportReactions.map((member, index) => (
                      <Link to={`/member/${member.id}`}>
                        <div className="member-info" key={index}>
                          <div className="member-avatar">
                            {member.user.profile.picture && (
                              <img
                                className="img-fluid"
                                src={member.user.profile.picture}
                                alt="club-avatar"
                              />
                            )}
                            {!member.user.profile.picture && (
                              <div className="member-initials">
                                {member.user.profile.firstName[0]
                                  + member.user.profile.lastName[0]}
                              </div>
                            )}
                          </div>
                          <div className="member-name">
                            {member.user.profile.firstName}
                            {' '}
                            {member.user.profile.lastName}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="love"
                  role="tabpanel"
                  aria-labelledby="love-tab"
                >
                  <div className="d-flex">
                    {loveReactions.map((member, index) => (
                      <Link to={`/member/${member.id}`}>
                        <div className="member-info" key={index}>
                          <div className="member-avatar">
                            {member.user.profile.picture && (
                              <img
                                className="img-fluid"
                                src={member.user.profile.picture}
                                alt="club-avatar"
                              />
                            )}
                            {!member.user.profile.picture && (
                              <div className="member-initials">
                                {member.user.profile.firstName[0]
                                  + member.user.profile.lastName[0]}
                              </div>
                            )}
                          </div>
                          <div className="member-name">
                            {member.user.profile.firstName}
                            {' '}
                            {member.user.profile.lastName}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="insightful"
                  role="tabpanel"
                  aria-labelledby="insightful-tab"
                >
                  <div className="d-flex">
                    {insightfulReactions.map((member, index) => (
                      <Link to={`/member/${member.id}`}>
                        <div className="member-info" key={index}>
                          <div className="member-avatar">
                            {member.user.profile.picture && (
                              <img
                                className="img-fluid"
                                src={member.user.profile.picture}
                                alt="club-avatar"
                              />
                            )}
                            {!member.user.profile.picture && (
                              <div className="member-initials">
                                {member.user.profile.firstName[0]
                                  + member.user.profile.lastName[0]}
                              </div>
                            )}
                          </div>
                          <div className="member-name">
                            {member.user.profile.firstName}
                            {' '}
                            {member.user.profile.lastName}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="curious"
                  role="tabpanel"
                  aria-labelledby="curious-tab"
                >
                  <div className="d-flex">
                    {curiousReactions.map((member, index) => (
                      <Link to={`/member/${member.id}`}>
                        <div className="member-info" key={index}>
                          <div className="member-avatar">
                            {member.user.profile.picture && (
                              <img
                                className="img-fluid"
                                src={member.user.profile.picture}
                                alt="club-avatar"
                              />
                            )}
                            {!member.user.profile.picture && (
                              <div className="member-initials">
                                {member.user.profile.firstName[0]
                                  + member.user.profile.lastName[0]}
                              </div>
                            )}
                          </div>
                          <div className="member-name">
                            {member.user.profile.firstName}
                            {' '}
                            {member.user.profile.lastName}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="dislike"
                  role="tabpanel"
                  aria-labelledby="dislike-tab"
                >
                  <div className="d-flex">
                    {dislikeReactions.map((member, index) => (
                      <Link to={`/member/${member.id}`}>
                        <div className="member-info" key={index}>
                          <div className="member-avatar">
                            {member.user.profile.picture && (
                              <img
                                className="img-fluid"
                                src={member.user.profile.picture}
                                alt="club-avatar"
                              />
                            )}
                            {!member.user.profile.picture && (
                              <div className="member-initials">
                                {member.user.profile.firstName[0]
                                  + member.user.profile.lastName[0]}
                              </div>
                            )}
                          </div>
                          <div className="member-name">
                            {member.user.profile.firstName}
                            {' '}
                            {member.user.profile.lastName}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReactionsModal.propTypes = {
  reactions: PropTypes.any,
};

export const mapStateToProps = (state) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps, {}),
)(ReactionsModal);
