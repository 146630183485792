import React from "react";
import { Modal } from "react-bootstrap";

const MembersOnlyModal = ({ isModalOpen, toggleModal }) => {
  return (
    <Modal
      show={isModalOpen}
      onHide={toggleModal}
      className="behalf-modal">
      <div className="create-club-header">
        <div className="header-left">
          <p className="header-modal-title">
            This content is for members only
          </p>
        </div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          style={{ marginTop: "-5rem", outline: "none" }}
          onClick={toggleModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div>
        <p>
          Join the club or contact a club administrator if
          you think this is a mistake.
        </p>
      </div>

      <button
        type="button"
        onClick={toggleModal}
        className="btn btn-lg submit-btn w-100">
        Ok
      </button>
    </Modal>
  );
};

export default MembersOnlyModal;
