import { USERS_USER_CRUD } from "../../actions/types";

const initialState = {
    "clubs": {},
    "activities": {},
    "interests": [],
    "images": {},
    "contact": {},
    "roles": [],
    "permissions": [],
    "id": 0,
    "username": "",
    "status": "",
    "dateCreated": 0,
    "lastUpdated": 0,
    "profile": {
        "firstName": "",
        "gender": "",
        "lastName": "",
        "location_city": "",
        "location_country": "",
        "picture": ""
    },
    "settings": {},
    "meta": {
        "verifiedAt": "0"
    }
}

export const userCRUDReducer = (state = initialState, action) => {
    if (action.type !== USERS_USER_CRUD) {
        return state;
    }

    let apiResponse = action.payload;
    if (apiResponse.status === 200) {
        return apiResponse.data;
    } else {
        return {
            ...state,
            error: apiResponse.error.message
        }
    }
}