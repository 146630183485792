/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FiberManualRecord } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  ADMIN,
  ARTICLE,
  BY_WORD,
  CLUB,
  colors,
  CREATED_ON,
  DATE_JOINED,
  DESCRIPTION,
  EMAIL,
  END_ON,
  LAST_ACTIVITY,
  LESSON,
  MEMBER,
  NAME,
  POST,
  ROLE,
  SHARED,
  STARTED_ON,
  STATUS,
  TYPE,
  VISIBILITY,
} from "./constants";
import {
  getAllClubsFromLocalStorage,
  getLabelByName,
} from "./helperFunctions";
import cloneIcon from "../assets/icons/clone.svg";
import mailIcon from "../assets/icons/mail-icon.svg";
import editIcon from "../assets/icons/edit-dark-orange.svg";
import deleteIcon from "../assets/icons/delete-dark-orange.svg";
import CreateClubModal from "../components/club/admin/CreateClubModal/CreateClubModal";
import CreateActivityModal from "../components/new/CreateActivityModal";
import AddUserModalNew from "../components/users/admin/AddUserModalNew";
import CloneQuestionnairesModal from "../components/new/CloneQuestionnairesModal";
import OutputItem from "../components/generics/Promotions/OutputItem";

export const timestampToDate = (
  timestamp,
  format = "DD MMM YYYY",
) => {
  if (!timestamp) {
    return null;
  }
  return moment(timestamp * 1000).format(format);
};

const roles = {
  admin: getLabelByName(ADMIN),
  member: getLabelByName(MEMBER),
};

const getPostClub = (post) => {
  const clubsData = getAllClubsFromLocalStorage();

  const postClub =
    post?.clubId &&
    clubsData &&
    clubsData.filter(
      (club) => club.id === post.clubId.toString(),
    )[0];

  return postClub;
};

export const formatPostRow = (
  post,
  handleDelete,
  setShowDeleteModalToTrue,
) => {
  const getPostType = (type) => {
    if (type === "LESSON") {
      return getLabelByName(LESSON);
    }

    if (type === "ARTICLE") {
      return getLabelByName(ARTICLE);
    }
    return getLabelByName(POST);
  };

  const authorName = `${post.author?.profile?.firstName} ${post.author?.profile?.lastName}`;
  const postType = getPostType(post.type);

  const postName = (
    <p>
      <span className="font-weight-bold">{authorName}</span>
      {` ${getLabelByName(SHARED)} `}
      <span className="font-weight-bold">
        {postType.toLowerCase()}
      </span>
    </p>
  );

  const postStatus = (
    <span>
      <FiberManualRecord
        style={{
          color:
            post.status === "APPROVED"
              ? "#10A728"
              : "#FF0D0D",
          width: "15px",
          marginRight: "0.5rem",
        }}
      />
      {post.status}
    </span>
  );

  const postPicture = (
    <img
      className="club-avatar"
      src={
        getPostClub(post)?.profile?.picture ||
        require(`../assets/manage-posts.svg`)
      }
      alt="post-avatar"
      style={{
        objectFit: "cover",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        maxHeight: "40px",
      }}
    />
  );

  let editAction = null;

  if (postType === "Article") {
    editAction = (
      <Link
        to={`/club/${getPostClub(post)?.id}/${
          getPostClub(post)?.handle
        }/article/${post.id}/edit`}>
        <img
          src={editIcon}
          alt="edit-action"
          width="24px"
        />
      </Link>
    );
  }

  if (postType === "Lesson") {
    editAction = (
      <Link
        to={`/club/${getPostClub(post)?.id}/${
          getPostClub(post)?.handle
        }/lesson/${post.id}/edit`}>
        <img
          src={editIcon}
          alt="edit-action"
          width="24px"
        />
      </Link>
    );
  }

  const deleteAction = (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => {
        setShowDeleteModalToTrue(
          getPostClub(post)?.id,
          post.id,
          postType,
        );
      }}
      role="button"
      aria-hidden>
      <img
        src={deleteIcon}
        alt="delete-action"
        width="24px"
      />
    </div>
  );

  return {
    id: post.id,
    picture: postPicture,
    name: postName,
    dateCreated: timestampToDate(post.dateCreated),
    type: postType,
    status: postStatus,
    club: getPostClub(post)?.name,
    edit: editAction,
    delete: deleteAction,
  };
};

export const formatClubRow = (
  club,
  setEditEntity,
  setShow,
) => {
  const renderPicture = () => (
    <img
      className="club-avatar"
      src={
        club.profile?.picture ||
        require(`../assets/manage-clubs.svg`)
      }
      alt="avatar"
      style={{
        objectFit: "cover",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
      }}
    />
  );

  const renderStatus = () => (
    <span>
      <FiberManualRecord
        style={{
          color:
            club.status === "ACTIVE"
              ? "#10A728"
              : "#FF0D0D",
          width: "15px",
          marginRight: "0.5rem",
        }}
      />
      {club.status}
    </span>
  );

  const renderClubName = () => (
    <Link
      to={`/manage/clubs/${club.id}/${club.handle}`}
      style={{ color: colors.textPrimary }}>
      {club.name}
    </Link>
  );

  const renderEditButton = () => (
    <span
      className="club-icon"
      data-toggle="modal"
      data-target=".add-club-modal"
      onClick={() => {
        setEditEntity(club);
        setShow();
      }}
      style={{ cursor: "pointer" }}>
      <img src={editIcon} alt="edit-action" width="24px" />
    </span>
  );

  const renderDeleteButton = () => (
    <span
      className="club-icon"
      data-toggle="modal"
      style={{ cursor: "pointer" }}>
      <img
        src={deleteIcon}
        alt="edit-action"
        width="24px"
      />
    </span>
  );

  return {
    id: club.id,
    picture: renderPicture(),
    name: renderClubName(),
    started_on: timestampToDate(club.dateCreated),
    type: club.type || "N/A",
    status: renderStatus(),
    last_activity:
      timestampToDate(club.lastUpdated) || "N/A",
    edit: renderEditButton(),
    delete: renderDeleteButton(),
  };
};

export const formatActivityRow = (
  activity,
  setEditEntity,
  setShow,
  toggleCloneEntity,
) => {
  const renderPicture = () => (
    <img
      className="club-avatar"
      src={
        activity.profile?.picture ||
        require(`../assets/manage-activities.svg`)
      }
      alt="avatar"
      style={{
        objectFit: "cover",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
      }}
    />
  );

  const renderActivityName = () => (
    <Link
      to={`/manage/activities/${activity.id}/${activity.handle}`}
      style={{ color: colors.textPrimary }}>
      {activity.name}
    </Link>
  );

  const renderStatus = () => (
    <span>
      <FiberManualRecord
        style={{
          color:
            activity.status === "ACTIVE"
              ? "#10A728"
              : "#FF0D0D",
          width: "15px",
          marginRight: "0.5rem",
        }}
      />
      {activity.status}
    </span>
  );

  const renderCloneButton = () => (
    <span
      className="club-icon"
      data-toggle="modal"
      onClick={() => toggleCloneEntity(activity)}
      style={{ cursor: "pointer" }}>
      <img
        src={cloneIcon}
        alt="clone-action"
        width="24px"
      />
    </span>
  );

  const renderEditButton = () => (
    <span
      className="club-icon"
      data-toggle="modal"
      data-target=".add-club-modal"
      onClick={() => {
        setEditEntity(activity);
        setShow();
      }}
      style={{ cursor: "pointer" }}>
      <img src={editIcon} alt="edit-action" width="24px" />
    </span>
  );

  const renderDeleteButton = () => (
    <span
      className="club-icon"
      data-toggle="modal"
      style={{ cursor: "pointer" }}>
      <img
        src={deleteIcon}
        alt="edit-action"
        width="24px"
      />
    </span>
  );

  return {
    id: activity.id,
    picture: renderPicture(),
    name: renderActivityName(),
    started_on: timestampToDate(activity.dateCreated),
    type: activity.type || "N/A",
    status: renderStatus(),
    end_on: activity.endDate
      ? timestampToDate(activity.endDate)
      : "N/A",
    last_activity:
      timestampToDate(activity.lastUpdated) || "N/A",
    visibility: activity.visibility,
    clone: renderCloneButton(),
    edit: renderEditButton(),
    delete: renderDeleteButton(),
  };
};

export const formatUserRow = (
  user,
  setEditEntity,
  setShow,
  togglePasswordResetMail,
) => {
  const renderPicture = () => (
    <img
      className="club-avatar"
      src={
        user.profile?.picture ||
        require(`../assets/manage-users.svg`)
      }
      alt="avatar"
      style={{
        objectFit: "cover",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
      }}
    />
  );

  const renderRole = () => (
    <span className="club-role">
      <div>
        {roles[user.roles?.[0]] || getLabelByName(MEMBER)}
      </div>
    </span>
  );

  const renderStatus = () => (
    <span>
      <FiberManualRecord
        style={{
          color:
            user.status === "ACTIVE"
              ? "#10A728"
              : "#FF0D0D",
          width: "15px",
          marginRight: "0.5rem",
        }}
      />
      {user.status}
    </span>
  );

  const renderPasswordResetButton = () => (
    <span
      className="club-icon"
      data-toggle="modal"
      data-target=".add-club-modal"
      onClick={() => togglePasswordResetMail(user)}
      style={{ cursor: "pointer" }}>
      <img
        src={mailIcon}
        alt="password-reset-action"
        width="24px"
      />
    </span>
  );

  const renderEditButton = () => (
    <span
      className="club-icon"
      data-toggle="modal"
      data-target=".add-club-modal"
      onClick={() => {
        setEditEntity(user);
        setShow();
      }}
      style={{ cursor: "pointer" }}>
      <img src={editIcon} alt="edit-action" width="24px" />
    </span>
  );

  const renderDeleteButton = () => (
    <span
      className="club-icon"
      data-toggle="modal"
      style={{ cursor: "pointer" }}>
      <img
        src={deleteIcon}
        alt="edit-action"
        width="24px"
      />
    </span>
  );

  return {
    id: user.id,
    picture: renderPicture(),
    name: `${user.profile?.firstName} ${user.profile?.lastName}`,
    email: (
      <div className="email-cell">{user.username}</div>
    ),
    date_joined: timestampToDate(user.dateCreated),
    status: renderStatus(),
    last_activity:
      timestampToDate(user.lastUpdated) || "N/A",
    roles: renderRole(),
    passwordReset: renderPasswordResetButton(),
    edit: renderEditButton(),
    delete: renderDeleteButton(),
  };
};

export const formatQuestionnaireRow = (
  questionnaire,
  toggleCloneEntity,
) => {
  const renderQuestionnaireName = () => (
    <Link
      to={`/manage/questionnaires/club/${questionnaire.parentId}/questionnaire/${questionnaire.id}/edit`}
      className="text-dark">
      {questionnaire.name}
    </Link>
  );

  const renderEditButton = () => (
    <Link
      to={`/manage/questionnaires/club/${questionnaire.parentId}/questionnaire/${questionnaire.id}/edit`}
      className="club-icon"
      style={{ cursor: "pointer" }}>
      <img src={editIcon} alt="edit-action" width="24px" />
    </Link>
  );

  const renderCloneButton = () => (
    <span
      className="club-icon"
      data-toggle="modal"
      onClick={() => toggleCloneEntity(questionnaire)}
      style={{ cursor: "pointer" }}>
      <img
        src={cloneIcon}
        alt="clone-action"
        width="24px"
      />
    </span>
  );

  const renderDeleteButton = () => (
    <span
      className="club-icon"
      data-toggle="modal"
      style={{ cursor: "pointer" }}>
      <img
        src={deleteIcon}
        alt="edit-action"
        width="24px"
      />
    </span>
  );

  return {
    id: questionnaire.id,
    name: renderQuestionnaireName(),
    club: questionnaire.clubName,
    description: questionnaire.description,
    dateCreated: timestampToDate(questionnaire.dateCreated),
    last_activity:
      timestampToDate(questionnaire.lastUpdated) || "N/A",
    clone: renderCloneButton(),
    edit: renderEditButton(),
    delete: renderDeleteButton(),
  };
};

export const adminTableMapping = (
  data,
  currentPage,
  pageDivider,
  setEditEntity,
  setShow,
  handleDelete,
  setShowDeleteModalToTrue,
  toggleCloneEntity,
  clubs,
  togglePasswordResetMail,
) => ({
  posts: {
    tableHeader: {
      id: "Id",
      picture: "  ",
      name: getLabelByName(NAME),
      dateCreated: getLabelByName(CREATED_ON),
      type: getLabelByName(TYPE),
      status: getLabelByName(STATUS),
      club: getLabelByName(CLUB),
      edit: "  ",
      delete: "  ",
    },
    getTableData: () =>
      data
        .slice(
          (currentPage - 1) * pageDivider,
          currentPage * pageDivider,
        )
        .map((item) =>
          formatPostRow(
            item,
            handleDelete,
            setShowDeleteModalToTrue,
          ),
        ),
  },
  clubs: {
    tableHeader: {
      // checkbox: "C",
      id: "Id",
      picture: "  ",
      name: getLabelByName(NAME),
      started_on: getLabelByName(STARTED_ON),
      type: getLabelByName(TYPE),
      status: getLabelByName(STATUS),
      // started_by: getLabelByName(STARTED_BY),
      last_activity: getLabelByName(LAST_ACTIVITY),
      // role: getLabelByName(ROLE),
      edit: "  ",
      delete: "  ",
    },
    getTableData: () =>
      data
        .slice(
          (currentPage - 1) * pageDivider,
          currentPage * pageDivider,
        )
        .map((item) =>
          formatClubRow(item, setEditEntity, setShow),
        ),
  },
  activities: {
    tableHeader: {
      id: "Id",
      picture: "  ",
      name: getLabelByName(NAME),
      started_on: getLabelByName(STARTED_ON),
      end_on: getLabelByName(END_ON),
      type: getLabelByName(TYPE),
      status: getLabelByName(STATUS),
      last_activity: getLabelByName(LAST_ACTIVITY),
      visibility: getLabelByName(VISIBILITY),
      clone: "  ",
      edit: "  ",
      delete: "  ",
    },
    getTableData: () =>
      data
        .slice(
          (currentPage - 1) * pageDivider,
          currentPage * pageDivider,
        )
        .map((item) =>
          formatActivityRow(
            item,
            setEditEntity,
            setShow,
            toggleCloneEntity,
          ),
        ),
  },
  users: {
    tableHeader: {
      id: "Id",
      picture: "  ",
      name: getLabelByName(NAME),
      email: getLabelByName(EMAIL),
      date_joined: getLabelByName(DATE_JOINED),
      status: getLabelByName(STATUS),
      last_activity: getLabelByName(LAST_ACTIVITY),
      roles: getLabelByName(ROLE),
      passwordReset: "  ",
      edit: "  ",
      delete: "  ",
    },
    getTableData: () =>
      data
        .filter((user) => user.status !== "GUEST")
        .slice(
          (currentPage - 1) * pageDivider,
          currentPage * pageDivider,
        )
        .map((item) =>
          formatUserRow(
            item,
            setEditEntity,
            setShow,
            togglePasswordResetMail,
          ),
        ),
  },
  questionnaires: {
    tableHeader: {
      id: "Id",
      name: getLabelByName(NAME),
      club: getLabelByName(CLUB),
      description: getLabelByName(DESCRIPTION),
      dateCreated: getLabelByName(CREATED_ON),
      // status: getLabelByName(STATUS),
      last_activity: getLabelByName(LAST_ACTIVITY),
      clone: "  ",
      edit: "  ",
      delete: "  ",
    },
    getTableData: () =>
      data
        .map((option) => ({
          ...option,
          clubName: clubs?.find(
            (club) =>
              parseInt(club.id) ===
              parseInt(option.parentId),
          )?.name,
        }))
        .slice(
          (currentPage - 1) * pageDivider,
          currentPage * pageDivider,
        )
        .map((item) =>
          formatQuestionnaireRow(item, toggleCloneEntity),
        ),
  },
});

export const renderNewEntityModal = (
  editEntity,
  show,
  onHide,
  fetchMethod,
  isCloneModalOpen,
  cloneEntity,
) => {
  return {
    posts: () => {},
    clubs: () => (
      <CreateClubModal
        editClub={editEntity}
        isCreateModalVisible={show}
        changeIsCreateModal={onHide}
        getClubs={fetchMethod}
      />
    ),
    activities: () => (
      <CreateActivityModal
        editActivity={editEntity}
        isCreateModalVisible={show || isCloneModalOpen}
        changeIsCreateModal={onHide}
        getActivity={fetchMethod}
        cloneActivity={cloneEntity}
      />
    ),
    users: () => (
      <AddUserModalNew
        editUser={editEntity}
        isCreateModalVisible={show}
        changeIsCreateModal={onHide}
        getUsers={fetchMethod}
      />
    ),
    questionnaires: () => (
      <CloneQuestionnairesModal
        isCreateModalVisible={isCloneModalOpen}
        cloneActivity={cloneEntity}
        closeModal={onHide}
      />
    ),
    promotions: () => null,
  };
};

const formatMobileEntity = (item, entity) => (
  <div className="mobile-entity">
    <div className="mobile-avatar">
      <img
        className="club-avatar"
        src={
          (entity === "posts"
            ? getPostClub(item)?.profile?.picture
            : item.profile?.picture) ||
          require(`../assets/manage-${entity}.svg`)
        }
        alt="avatar"
        style={{
          objectFit: "cover",
          borderRadius:
            !!(entity === "posts"
              ? getPostClub(item)?.profile?.picture
              : item.profile?.picture) && "50%",
          width: "60px",
          height: "60px",
        }}
      />
    </div>
    <div className="mobile-entity-info">
      <div className="mobile-entity-details">
        <div className="mobile-entity-name">
          <Link
            to={
              (entity !== "users" || entity !== "posts") &&
              `/manage/${entity}/${item.id}/${item.handle}`
            }
            style={{ color: colors.textPrimary }}>
            {entity === "posts" &&
              `${getLabelByName(BY_WORD)} ${
                item?.author?.profile?.firstName
              } ${item?.author?.profile?.lastName}`}
            {entity === "users" &&
              `${item.profile?.firstName} ${item.profile?.lastName}`}
            {(entity !== "users" || entity !== "posts") &&
              item.name}
          </Link>
        </div>
        <div className="mobile-entity-type">
          {item.type}
        </div>
        <div className="mobile-entity-status">
          <FiberManualRecord
            style={{
              color:
                item.status === "ACTIVE" ||
                item.status === "APPROVED"
                  ? "#10A728"
                  : "#FF0D0D",
              width: "15px",
              marginRight: "0.5rem",
            }}
          />
          {item.status}
        </div>
      </div>
      <div className="mobile-entity-last_activity">
        Active: {timestampToDate(item.lastUpdated) || "N/A"}
      </div>
    </div>
  </div>
);

const formatMobileQuestionnaire = (item, entity) => (
  <div className="mobile-entity">
    <div className="mobile-avatar">
      <img
        className="club-avatar"
        src={
          item.profile?.picture ||
          require(`../assets/manage-${entity}.svg`)
        }
        alt="avatar"
        style={{
          objectFit: "cover",
          borderRadius: item.profile?.picture && "50%",
          width: "60px",
          height: "60px",
        }}
      />
    </div>
    <div className="mobile-entity-info">
      <div className="mobile-entity-details">
        <div className="mobile-entity-name">
          {item.name}
        </div>
      </div>
      <div className="mobile-entity-last_activity">
        Active: {timestampToDate(item.lastUpdated) || "N/A"}
      </div>
    </div>
  </div>
);

const formatMobilePromotionsEntity = (
  item,
  numOfPromotedOutputs,
) => (
  <OutputItem
    output={item}
    updateOutput={() => {}}
    numOfPromotedOutputs={numOfPromotedOutputs}
  />
);

export const renderMobileEntity = (
  item,
  numOfPromotedOutputs,
) => ({
  posts: () => formatMobileEntity(item, "posts"),
  clubs: () => formatMobileEntity(item, "clubs"),
  activities: () => formatMobileEntity(item, "activities"),
  users: () => formatMobileEntity(item, "users"),
  questionnaires: () =>
    formatMobileQuestionnaire(item, "questionnaires"),
  promotions: () =>
    formatMobilePromotionsEntity(
      item,
      numOfPromotedOutputs,
    ),
});
