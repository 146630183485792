export const ACTIVITIES_ACTIVITY_CRUD = "ACTIVITIES_ACTIVITY_CRUD";
export const ACTIVITIES_PARTICIPANT_CRUD = "ACTIVITIES_PARTICIPANT_CREATE";

export const ACTIVITIES_PARTICIPANT_ACTION_CRUD = "ACTIVITIES_PARTICIPANT_ACTION_CRUD";
export const ACTIVITIES_PARTICIPANT_ACTION_SEARCH = "ACTIVITIES_PARTICIPANT_ACTION_SEARCH";

export const ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_CRUD = "ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_CREATE";
export const ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_SEARCH = "ACTIVITIES_PARTICIPANT_CONTRIBUTIONS_SEARCH";

export const ACTIVITIES_CONTRIBUTIONS_SEARCH = "ACTIVITIES_CONTRIBUTIONS_SEARCH";
export const ACTIVITIES_CONTRIBUTIONS_COMMENT_SEARCH = "ACTIVITIES_CONTRIBUTIONS_COMMENT_SEARCH";