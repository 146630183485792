import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import DedicatedPage from "../../generics/DedicatedPage/DedicatedPage";
import { getClubActivities } from "../../../redux/actions/campaignsActions";
import { getInstitute } from "../../../redux/actions/institutesActions";
import activityIcon from "../../../assets/manage-campaigns.svg";
import ItemCard from "../../explore/ItemCard";
import { ACTIVITIES, CLUB_ACTIVITIES } from "../../../helpers/constants";
import { checkSession, getLabelByName } from "../../../helpers/helperFunctions";
import { getSessionAction } from "../../../redux/actions/authActions";

class ClubActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: this.props.location.pathname,
    };
  }

  UNSAFE_componentWillMount = async () => {
    const { props } = this;
    const { id: clubId, handle } = props.match.params;
    await props.getSessionAction();
    await props.getInstitute(handle);
    await props.getClubActivities(clubId);
  };

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, status } = nextProps;
    checkSession(data, status, this.state.pathname, nextProps);
  }

  getActivitiesCards = () => {
    let activitiesCards = [];

    if (this.props.clubCampaignsData) {
      activitiesCards = this.props.clubCampaignsData?.data?.map(
        (activity, index) => (
          <ItemCard
            item={activity}
            type="activity"
            index={index}
            defaultIcon={activityIcon}
          />
        )
      );
    }

    return activitiesCards;
  };

  getPageTitle = () => {
    let title = getLabelByName(CLUB_ACTIVITIES);
    if (this.props.instituteData?.data?.name) {
      title = `${this.props.instituteData?.data?.name} - ${getLabelByName(
        ACTIVITIES
      )}`;
    }

    return title;
  };

  render() {
    return (
      <DedicatedPage
        pageTitle={this.getPageTitle()}
        resource={getLabelByName(ACTIVITIES)}
        resourceData={this.getActivitiesCards()}
        match={this.props.match}
      />
    );
  }
}

ClubActivities.propTypes = {
  clubCampaignsData: PropTypes.any,
  getClubActivities: PropTypes.func,
  getInstitute: PropTypes.func,
  instituteData: PropTypes.any,
};

const mapStateToProps = (state) => ({
  clubCampaignsData: state.clubCampaignsReducer.clubCampaignsData,
  instituteData: state.instituteReducer.instituteData,
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getClubActivities,
    getInstitute,
    getSessionAction,
  })
)(ClubActivities);
