import React from "react";
import { ReactComponent as SuccessIcon } from "../../../../assets/icons/check-mark.svg";
import { ReactComponent as ErrorIcon } from "../../../../assets/icons/credit-card wrong.svg";

const PaymentStatusOverlay = ({
  amount,
  currency,
  status,
  closeOverlay,
  handlePaymentRetry,
  handleNextActionAfterPayment,
}) => {
  return (
    <div className="payment-status-overlay">
      <div className="content-wrapper">
        <div className="content pb-3">
          {status === "SUCESS" ? (
            <SuccessIcon className="mb-5" />
          ) : (
            <ErrorIcon className="mb-5" />
          )}

          {status === "SUCCESS" ? (
            <>
              <div className="mb-5 text-center">
                <h4 className="mb-4 font-weight-bold">
                  Payment Successful
                </h4>

                <p>
                  Your membership fee payment of {amount}{" "}
                  {currency}
                  has been processed
                </p>
              </div>

              <div className="d-flex flex-column align-items-center">
                <button
                  type="button"
                  onClick={handleNextActionAfterPayment}
                  className="next-action-btn">
                  Go To Next Action
                </button>

                <button
                  type="button"
                  onClick={() => closeOverlay()}
                  className="cancel-btn">
                  Cancel
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="mb-5 text-center">
                <h4 className="mb-4 font-weight-bold">
                  Oops! Something went wrong
                </h4>

                <p>
                  An error occurred when we tried to process
                  the membership fee payment of 100.00 GBP,
                  please check that you have entered your
                  details correctly and try again
                </p>
              </div>

              <div className="d-flex flex-column align-items-center">
                <button
                  type="button"
                  onClick={handlePaymentRetry}
                  className="next-action-btn">
                  Try Again
                </button>

                <button
                  type="button"
                  onClick={() => closeOverlay()}
                  className="cancel-btn">
                  Cancel
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentStatusOverlay;
