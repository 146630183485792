import React, { useEffect, useState } from "react";

const ContributionActionPanel = ({ activity, contribution, actions, actionHandler }) => {

    const [selectedAction, setSelectedAction] = useState("__");
    const [enableAction, setEnableAction] = useState(false);

    useEffect(() => {
        if (selectedAction === "__") {
            setEnableAction(false);
        } else {
            setEnableAction(true);
        }
    }, [selectedAction])

    const handleSelectedAction = (e) => {
        if (actions.includes(e.target.value)) {
            setSelectedAction(e.target.value);
        }
    }

    const performSelectedAction = (e) => {
        actionHandler(selectedAction);
        setSelectedAction("__");
    }

    return <>
        <h2>
            {contribution.participant.firstName} {contribution.participant.lastName} -
            {contribution.action.name} ({contribution.status})
        </h2>

        <select
            onChange={handleSelectedAction}
            value={selectedAction}>
            <option key="__"
                value="__">
                Select an action
            </option>
            {actions.map((action, index) => {
                return (
                    <option
                        key={index}
                        value={action} >
                        {action}
                    </option>
                );
            })}
        </select>
        <button
            onClick={performSelectedAction}
            disabled={!enableAction}>
            Confirm
        </button>
    </>

}

export default ContributionActionPanel;