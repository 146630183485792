// @ts-nocheck
import React, {useState, useEffect} from 'react'
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useDynamicRefs from 'use-dynamic-refs';
import Footer from "./Footer";
import './term-privacy-component.css'

export const TermPolicyPageComponent = ({pageTitle, pageData}) => {
    const {goBack} = useHistory();
    const [getRef, setRef] =  useDynamicRefs();
    const [sectionToRef, setSectionToRef] = useState("")

    const mainSectionData = pageData?.main ? JSON.parse(pageData.main) : {}
    const {text: mainText, display: mainDisplay} = mainSectionData

    const listSectionData = pageData?.list ? JSON.parse(pageData.list) : []
    const visibleSections = listSectionData.filter(section => section.display)

    // useEffect(() => {
    //     const section = getRef(sectionToRef);
    //     section.current.focus();
    // }, [sectionToRef])

    const sectionFocus = (sectionId) => getRef(sectionId).current.focus()
    // const sectionFocus = (sectionId) => console.log(getRef(sectionId).current)
    
    return (
    <div className="term-policy-fullpage">
      <nav>
          <ol>
            {visibleSections.map(sectionData => (
            <li onClick={() => sectionFocus(sectionData.id)} key={sectionData.id}>{sectionData.title}</li>
            ))}
          </ol>
      </nav>
      <section className="term-policy-section">
            <div className="term-policy-header" onClick={goBack} >
                <ArrowBackIosIcon />
                <h4>{pageTitle}</h4>
            </div>
            {mainDisplay && <pre  className="term-policy-main-text">{mainText}</pre>}
            {visibleSections.map((sectionData, index) => {
                return(
                    <div className="term-policy-block" key={sectionData.id} ref={setRef(sectionData.id)}>
                        <h5>{`${index +1}. ${sectionData.title}`}</h5>
                        <pre>{sectionData.text}</pre>
                    </div>
                )

            })}
            <Footer />
        </section>
    </div>
  )
}
