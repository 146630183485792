import {
  UPDATE_POST_ERROR, UPDATE_POST_SUCCESS,
} from '../actions/types';

const initialState = {
  updatePostData: null,
  updatePostDataError: null,
  updatePostStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_POST_SUCCESS:
      return {
        ...state,
        updatePostData: action.payload,
        updatePostDataError: null,
        updatePostStatus: 'success',
      };
    case UPDATE_POST_ERROR:
      return {
        ...state,
        updatePostDataError: action.payload,
        updatePostData: null,
        updatePostStatus: 'error',
      };
    default:
      return {
        ...state,
        updatePostStatus: null,
      };
  }
};
