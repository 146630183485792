import {
  EDIT_ACTION_SUCCESS, EDIT_ACTION_ERROR,
} from '../actions/types';

const initialState = {
  editActionData: null,
  editActionDataError: null,
  editActionStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_ACTION_SUCCESS:
      return {
        ...state,
        editActionData: action.payload,
        editActionDataError: null,
        editActionStatus: 'success',
      };
    case EDIT_ACTION_ERROR:
      return {
        ...state,
        editActionDataError: action.payload,
        editActionData: null,
        editActionStatus: 'error',
      };
    default:
      return {
        ...state,
        editActionStatus: null,
      };
  }
};
