import React from "react";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search-orange.svg";

const LessonItem = ({ idx, lesson: article, onClick }) => (
  <div
    className="lesson-item"
    onClick={() => onClick(article?.id)}>
    <div className="checkbox-wrapper">
      <input
        type="checkbox"
        checked={article?.isComplete}
        disabled
      />
    </div>

    <div className="desc">
      <p>Lesson {idx}:</p>

      <p>{article?.content?.title}</p>
    </div>
  </div>
);

const LessonsSidebar = ({
  isOpen,
  action,
  lessonsData,
  currLesson,
  onSelectLesson,
  handleSearchChange,
  handleSearchSubmit,
}) => {
  return (
    <aside className={isOpen ? "" : "is-closed"}>
      <div className="wrapper">
        <div className="name-of-action">
          <div className="desc">
            <span>{action?.name}</span>
            <span>{currLesson?.content?.description}</span>
          </div>
        </div>

        <form
          className="searchBar"
          onSubmit={handleSearchSubmit}>
          <div>
            <input
              onChange={handleSearchChange}
              placeholder="Search by Lesson title"
            />
            <button type="submit" className="searchBtn">
              <SearchIcon />
            </button>
          </div>
        </form>

        <div className="lessons-wrapper">
          {lessonsData?.article?.map((lesson, idx) => (
            <LessonItem
              key={lesson?.id + idx}
              idx={idx + 1}
              lesson={lesson}
              onClick={onSelectLesson}
            />
          ))}
        </div>
      </div>
    </aside>
  );
};

export default LessonsSidebar;
