/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { ArrowBackIos } from "@material-ui/icons";
import { getLabelByName } from "../../../../../helpers/helperFunctions";
import {
  ADVANCED_SETTINGS,
  BACK_TO_CLUB_PAGE,
  CONFIGURE_CLUB_ADVANCED_SETTINGS,
  GENERAL,
  NOTIFICATIONS,
} from "../../../../../helpers/constants";
import "./sidenav.css";

class SideNav extends Component {
  state = {
    navItems: [
      {
        title: getLabelByName(GENERAL),
        link: `/manage/clubs/${this.props.match.params.id}/${this.props.match.params.handle}/settings/general`,
      },
      {
        title: getLabelByName(NOTIFICATIONS),
        link: `/manage/clubs/${this.props.match.params.id}/${this.props.match.params.handle}/settings/notifications`,
      },
      {
        title: "Interactions",
        link: `/manage/clubs/${this.props.match.params.id}/${this.props.match.params.handle}/settings/interactions`,
      },
    ],
  };

  render() {
    const { navItems } = this.state;
    const { category } = this.props.match.params;
    const { toggleMenu } = this.props;
    return (
      <div className="container-fluid">
        <div className="ml-3">{getLabelByName(ADVANCED_SETTINGS)}</div>
        <div className="helper-text mb-5">
          {getLabelByName(CONFIGURE_CLUB_ADVANCED_SETTINGS)}
        </div>
        {navItems.map((item) => (
          <Link
            to={item.link}
            className={`nav-link side-nav-item ${
              category === item?.title?.toLowerCase()
                ? "side-nav-item__active"
                : ""
            }`}
            onClick={() => (toggleMenu ? toggleMenu() : {})}
          >
            <div>{item.title} </div>
          </Link>
        ))}{" "}
        <Link
          className="back-link"
          to={`/manage/clubs/${this.props.match.params.id}/${this.props.match.params.handle}`}
        >
          <ArrowBackIos />
          <span>{getLabelByName(BACK_TO_CLUB_PAGE)}</span>
        </Link>
      </div>
    );
  }
}

SideNav.propTypes = {};

export const mapStateToProps = (state) => ({});

export default compose(withRouter, connect(mapStateToProps))(SideNav);
