import {
  UPDATE_CLUB_ERROR, UPDATE_CLUB_SUCCESS,
} from '../actions/types';

const initialState = {
  updateClubData: null,
  updateClubDataError: null,
  updateClubStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CLUB_SUCCESS:
      return {
        ...state,
        updateClubData: action.payload,
        updateClubDataError: null,
        updateClubStatus: 'success',
      };
    case UPDATE_CLUB_ERROR:
      return {
        ...state,
        updateClubDataError: action.payload,
        updateClubData: null,
        updateClubStatus: 'error',
      };
    default:
      return {
        ...state,
        updateClubStatus: null,
      };
  }
};
