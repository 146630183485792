import {
  ADD_PARTICIPANT_SUCCESS,
  ADD_PARTICIPANT_ERROR,
} from '../actions/types';

const initialState = {
  addParticipantData: null,
  addParticipantStatus: null,
  addParticipantError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PARTICIPANT_SUCCESS:
      return {
        ...state,
        addParticipantData: action.payload,
        addParticipantError: null,
        addParticipantStatus: 'success',
      };
    case ADD_PARTICIPANT_ERROR:
      return {
        ...state,
        addParticipantData: null,
        addParticipantError: action.payload,
        addParticipantStatus: 'error',
      };
    default:
      return {
        ...state,
      };
  }
};
