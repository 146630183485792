/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import "./settings.css";
import {
  Clear,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@material-ui/icons";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import SideNav from "../SideNav/SideNav";
import { renderMobileEntity } from "../../../helpers/admin";
import cloneIcon from "../../../assets/icons/clone.svg";
import editIcon from "../../../assets/icons/edit-dark-orange.svg";
import deleteIcon from "../../../assets/icons/delete-dark-orange.svg";
import {
  getAllClubsFromLocalStorage,
  getLabelByName,
} from "../../../helpers/helperFunctions";
import {
  colors,
  CREATE_NEW,
  SEARCH,
} from "../../../helpers/constants";
import searchOrange from "../../../assets/icons/search-orange.svg";
import StatusDropdown from "../../generics/Promotions/StatusDropdown";

export class SettingsMobile extends Component {
  state = {
    showMenu: false,
    searchValue: "",
    clubsData: getAllClubsFromLocalStorage(),
    dropDownValue: "all",
    filteredData: this.props.data,
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    this.setState({
      filteredData: nextProps.data,
    });
  };

  handleDropdownChange = (e) => {
    const value = e.target.value;

    this.setState({ dropDownValue: value });
    const outputsData = [...this.state.filteredData];

    if (value === "promoted") {
      const promotedOutputs = outputsData.filter(
        (output) => output.meta.promoted,
      );

      this.setState({ filteredData: promotedOutputs });
    } else {
      this.setState({ filteredData: this.props.data });
    }
  };

  leadingActions = (item) => (
    <LeadingActions>
      <SwipeAction
        // Tag="div"
        onClick={() => {
          if (this.props.entity === "questionnaires") {
            return this.props.history.push(
              `/manage/questionnaires/club/${item.parentId}/questionnaire/${item.id}/edit`,
            );
          }

          if (this.props.entity === "posts") {
            const postClub =
              item?.clubId &&
              this.state.clubsData?.filter(
                (club) =>
                  club.id === item.clubId.toString(),
              )[0];
            if (item.content?.title) {
              return this.props.history.push(
                `/club/${postClub && postClub.id}/${
                  postClub && postClub.handle
                }/article/${item.id}/edit`,
              );
            }
          }
          this.props.setShow();
          this.props.setEditEntity(item);
        }}>
        <img
          className="mx-auto"
          src={editIcon}
          alt="edit-action"
          width="24px"
        />
      </SwipeAction>

      {this.props.entity === "questionnaires" ||
      this.props.entity === "activities" ? (
        <SwipeAction
          Tag="div"
          onClick={() => {
            this.props.toggleCloneEntity(item);
          }}>
          <img
            className="mx-auto"
            src={cloneIcon}
            alt="clone-action"
            width="24px"
          />
        </SwipeAction>
      ) : (
        <span></span>
      )}
    </LeadingActions>
  );

  trailingActions = () => (
    <TrailingActions>
      <SwipeAction
        // destructive
        onClick={() => console.info("Delete Triggered")}>
        <img
          className="ml-5"
          src={deleteIcon}
          alt="edit-action"
          width="24px"
        />
      </SwipeAction>
    </TrailingActions>
  );

  toggleMenu = () =>
    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }));

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  searchEntities = (e) => {
    e.preventDefault();
    const { data } = this.props;
    const regex = new RegExp(
      this.state.searchValue.toLowerCase(),
      "g",
    );
    const filteredData = data.filter((item) =>
      item.name.toLowerCase().match(regex),
    );
    this.setState({ filteredData });
  };

  clearSearch = () =>
    this.setState({
      filteredData: this.props.data,
      searchValue: "",
    });

  render() {
    const { showMenu, searchValue, filteredData } =
      this.state;
    const { entity, currentPage, pageDivider } = this.props;

    const promotedOutputs = filteredData.filter(
      (output) => output.meta?.promoted,
    );

    return (
      <div className="settings-mobile-container">
        <div className="settings-mobile">
          <div className="settings-mobile__menu-bar">
            <div
              className="current-entity"
              onClick={() => this.toggleMenu()}>
              <img
                className="mr-3"
                src={
                  entity !== "web" &&
                  entity !== "promotions"
                    ? require(`../../../assets/manage-${entity}.svg`)
                    : require(`../../../assets/manage-site.svg`)
                }
                alt="entity-icon"
                width="30px"
              />
              {entity[0].toUpperCase() + entity.slice(1)}
              {showMenu ? (
                <KeyboardArrowUpRounded className="ml-3" />
              ) : (
                <KeyboardArrowDownRounded className="ml-3" />
              )}
            </div>
            <div>
              {entity !== "web" && entity !== "promotions" && (
                <div
                  style={{ color: colors.primaryColor }}
                  onClick={() => {
                    if (entity === "questionnaires") {
                      this.props.history.push(
                        "/manage/questionnaires/new",
                      );
                    }
                    if (entity === "posts") {
                      this.props.history.push(
                        "/create-article",
                      );
                    }
                    this.props.setShow();
                    this.props.setEditEntity(null);
                  }}>
                  {getLabelByName(CREATE_NEW)}
                </div>
              )}
            </div>
          </div>
        </div>
        {showMenu && (
          <div className="mobile-nav">
            <SideNav
              entity={entity}
              toggleMenu={this.toggleMenu}
            />
          </div>
        )}

        {entity !== "web" && entity !== "promotions" && (
          <div className="m-3 d-flex justify-content-between align-items-center">
            <p className="mb-0">
              {`${filteredData.length} ${
                entity[0].toUpperCase() + entity.slice(1)
              }`}
            </p>

            {entity === "promotions" && (
              <StatusDropdown
                dropDownValue={this.state.dropDownValue}
                handleChange={this.handleDropdownChange}
              />
            )}
          </div>
        )}

        {entity !== "web" && entity !== "promotions" && (
          <div className="m-3 search-clubs">
            <form
              className="form"
              onSubmit={this.searchEntities}>
              <div className="input-group mb-2">
                <input
                  type="text"
                  className="form-control"
                  id="inputSearch"
                  placeholder={
                    entity !== "web" &&
                    entity !== "promotions"
                      ? `${getLabelByName(
                          SEARCH,
                        )} ${getLabelByName(
                          entity,
                        ).toLowerCase()}`
                      : `${getLabelByName(SEARCH)}....`
                  }
                  name="searchValue"
                  value={searchValue}
                  onChange={this.handleChange}
                  style={{ height: "32px" }}
                />
                {searchValue && (
                  <div
                    className="input-group-append"
                    style={{ height: "32px" }}>
                    <button
                      id="clear-search-btn"
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => this.clearSearch()}>
                      <Clear style={{ fontSize: "16px" }} />
                    </button>
                  </div>
                )}
                <div
                  className="input-group-append"
                  style={{ height: "32px" }}>
                  <button
                    disabled={!searchValue}
                    id="search-btn"
                    className="btn btn-outline-secondary"
                    type="submit"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <img
                      src={searchOrange}
                      alt="search-icon"
                      style={{ width: "12px" }}
                    />
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}

        {entity !== "web" && entity !== "promotions" && (
          <div className="mobile-settings-items">
            {filteredData
              .slice(
                (currentPage - 1) * pageDivider,
                currentPage * pageDivider,
              )
              .map((item) => (
                <SwipeableList
                  key={filteredData.indexOf(item)}
                  type={ListType.IOS}>
                  <SwipeableListItem
                    leadingActions={
                      entity === "posts" &&
                      !item.content?.title
                        ? ""
                        : this.leadingActions(item)
                    }
                    trailingActions={this.trailingActions()}>
                    <div className="mobile-settings-item">
                      {renderMobileEntity(
                        item,
                        promotedOutputs.length,
                      )[entity]()}
                    </div>
                  </SwipeableListItem>
                </SwipeableList>
              ))}
          </div>
        )}
      </div>
    );
  }
}

SettingsMobile.propTypes = {};

export default SettingsMobile;
