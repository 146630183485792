/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ArrowBackIos } from '@material-ui/icons';
import { toast } from 'react-toastify';
import Footer from '../Footer';
import {
  checkSession, getLabelByName, getProfileDataFromLocalStorage, isActionError,
} from '../../helpers/helperFunctions';
import { BACK, NOTIFICATIONS } from '../../helpers/constants';
import { getSessionAction } from '../../redux/actions/authActions';
import { getNotifications } from '../../redux/actions/notificationsActions';
import Navbar from '../Navbar';
import Loader from '../Loader';
import NotificationItemCard from './NotificationItemCard';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileData: getProfileDataFromLocalStorage(),
      pageTitle: `${process.env.REACT_APP_APPLICATION_NAME || 'The Alma Maters Club'} - Notifications`,
      pathname: this.props.location.pathname,
    };
    this.init();
  }

  init = () => {
    document.title = this.state.pageTitle;
  };

  render() {
    return (
      <>
        <Navbar pathname="notifications" />
        <div className="club-page feed-page">
          <div className="container">
            <div className="buttons-row mb-2">
              <div id="back-link">
                <div
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => this.props.history.goBack()}
                  style={{ cursor: 'pointer' }}
                >
                  <ArrowBackIos className="arrow-back-icon" />
                  {'  '}
                  {getLabelByName(BACK)}
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center mt-4">
              <div className="feed-card">
                <div className="feed-card-header">
                  <div className="feed-card-header-title">{getLabelByName(NOTIFICATIONS)}</div>
                </div>
                <div className="feed-section">
                  {this.getNotificationsData()
                    && this.getNotificationsData().map((notification) => (
                      <NotificationItemCard notification={notification} />
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="footer-block">
            <div className="footer-component">
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  }

  UNSAFE_componentWillMount = async () => {
    const { props, state } = this;
    const { profileData, pathname } = state;
    if (!profileData || profileData.status !== 200) {
      window.location.href = `/login?redirect_to=${pathname}`;
    }
    await props.getSessionAction();
    await this.fetchData();
  };

  UNSAFE_componentWillReceiveProps = async (nextProps) => {
    const { data, status } = nextProps;
    checkSession(data, status, this.state.pathname, nextProps);

    if (
      (isActionError(nextProps, 'createPostStatus', 'createPostData'))
      || (isActionError(nextProps, 'postReactionStatus', 'postReactionData'))
      || (isActionError(nextProps, 'deleteReactionStatus', 'deleteReactionData'))
    ) {
      toast.error('Posting error!\nPlease try again!');
    }
  };

  fetchData = async () => {
    const { props, state } = this;
    const userId = state.profileData.data.id;
    await props.getNotifications(userId);
  };

  dataHasLoaded = (state) => !!(state && state.data);

  getNotificationsData = () => this.dataHasLoaded(this.props.getNotificationsData) && this.props.getNotificationsData.data;
}

Notifications.propTypes = {
  getSessionAction: PropTypes.func,
  getNotifications: PropTypes.func,
  getNotificationsData: PropTypes.any,
  history: PropTypes.any,
  location: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,

  getNotificationsDataError: state.getNotificationsReducer.getNotificationsDataError,
  getNotificationsData: state.getNotificationsReducer.getNotificationsData,
  getNotificationsStatus: state.getNotificationsReducer.getNotificationsStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getSessionAction,
    getNotifications,
  }),
)(Notifications);
