import React from "react";

import DataCollectionConsole from "../../actions/dataCollection/DataCollectionConsole";
import CourseConsole from "../../actions/course/CourseConsole";

const ActionRenderer = ({action, activity, participant, participantActions, pageAction, participantActionId, isPreviewMode=false}) => {

    let ActionToRender;

    switch (action.type) {
        case "DATA_COLLECTION":
            ActionToRender = DataCollectionConsole;
            break;
        case "COURSE":
            ActionToRender = CourseConsole;
            break;
        case "PAYMENT":
            // ActionToRender = PaymentRenderer;
            break;
        case "SUBSCRIPTION":
            // ActionToRender = SubscriptionRenderer;
            break;
        default:

    }

    return <>
        <ActionToRender
            activity={activity}
            action={action}
            participant={participant}
            participantActions={participantActions}
            pageAction={pageAction}
            participantActionId={participantActionId}
            isPreviewMode={isPreviewMode}
        />
    </>
}

export default ActionRenderer;