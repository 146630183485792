/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Radios extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return this.props.options.map((option) => (
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          name="radio-checkbox"
          // onChange={this.handleRadio}
        />
        <label
          className="form-check-label"
        >
          {option}
        </label>
      </div>
    ));
  }
}

Radios.propTypes = {
  options: PropTypes.any,
};

export default Radios;
