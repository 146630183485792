import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { retrieveActivity } from "../../../redux/actions/activities/activityActions.js";
import { retrieveParticipant } from "../../../redux/actions/activities/participantActions.js";
import { searchParticipantActions } from "../../../redux/actions/activities/participantActionActions.js";
import { searchParticipantContributions } from "../../../redux/actions/activities/contributionActions.js";
import Navbar from "../../Navbar";
import ConsoleSidebar from './ConsoleSidebar'
import ConsoleDashboard from './ConsoleDashboard.js';
import './participantConsole.scss'


const ParticipantConsole = (props) => {
    const { activityId, participantId } = props.match.params
    const { activity, participantContributions, participant, participantActions, getContributionsCommentsData, clubMembersData } = props
    const clubData = {}
    const [isDropDownOpen, setIsDropDownOpen] = useState(true)

    useEffect(() => {
        props.retrieveActivity(activityId)
        props.retrieveParticipant(activityId, participantId)
        props.searchParticipantActions(activityId, participantId)
        props.searchParticipantContributions(activityId, participantId)
    }, [])

    return (
        <>
            <Navbar pathname="explore" />
            <section className="contribute-section">
                {/* ================= LEFT SIDEBAR =================  */}
                <ConsoleSidebar
                    activity={activity}
                    clubData={clubData}
                    participant={participant}
                    isDropDownOpen={isDropDownOpen}
                    toggleIsDropDownOpen={() => setIsDropDownOpen(!isDropDownOpen)}
                />
                {/* ================= LEFT SIDEBAR =================  */}

                {/* ================= MAIN CONTENT =================  */}
                <ConsoleDashboard
                    activity={activity}
                    participant={participant}
                    participantActions = {participantActions}
                    participantContributions={participantContributions}
                    clubMembersData={clubMembersData}
                    getContributionsCommentsData={getContributionsCommentsData} />
                {/* ================= MAIN CONTENT =================  */}

            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    activity: state.activityCRUDReducer,
    participant: state.participantCRUDReducer,
    participantActions: state.participantActionsSearchReducer,
    participantContributions: state.participantContributionsSearchReducer,
    getContributionsCommentsData: state.getContributionsCommentsReducer.getContributionsCommentsData,
    clubMembersData: state.clubMembersReducer.clubMembersData,
});

export default compose(
    withRouter,
    connect(mapStateToProps, {
        retrieveActivity,
        retrieveParticipant,
        searchParticipantActions,
        searchParticipantContributions,
    }),
)(ParticipantConsole);