/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { ArrowBackIos } from '@material-ui/icons';

import { resetPasswordAction } from '../redux/actions/authActions';
import lock from '../assets/icons/lock.svg';
import {
  BACK_TO, colors, CONFIRM, HOME, NEW, PASSWORD, RESETTING, RESET_PASSWORD,
} from '../helpers/constants';
import { getLabelByName } from '../helpers/helperFunctions';

export class ResetPassword extends Component {
  state = {
    password: '',
    confirmation: '',
    isLoading: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      resetDataError, resetStatus, resetData, history,
    } = nextProps;
    switch (resetStatus) {
      case 'success':
        this.setState((state) => ({ ...state, isLoading: false }));
        switch (resetData.status) {
          case 200:
            history.push('/login');
            break;
          default:
            toast.error(resetData.error.message);
            break;
        }
        break;
      case 'error':
        this.setState((state) => ({ ...state, isLoading: false }));
        toast.error(resetDataError.message);
        break;
      default:
        break;
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { props, props: { location } } = this;
    const token = new URLSearchParams(location.search).get('token');
    const {
      password, confirmation,
    } = this.state;

    const passwordData = {
      password,
      confirmation,
      token,
    };

    this.setState((state) => ({ ...state, isLoading: true }));

    await props.resetPasswordAction(passwordData);
  };

  render() {
    const { REACT_APP_APPLICATION_NAME } = process.env;
    document.title = `${REACT_APP_APPLICATION_NAME || 'SOSO'} - Reset Password`;
    const {
      password, confirmation, isLoading,
    } = this.state;

    return (
      <div className="login-form d-flex flex-column align-items-center">
        <h5 className="text-center">{getLabelByName(RESET_PASSWORD)}</h5>
        <form onSubmit={this.handleSubmit} className="auth-form mt-3">
          <div className="row d-flex justify-content-center">
            <div className="form-icon col-md-12">
              <div className="form-group form-label-group">
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  id="input-password"
                  placeholder="New Password"
                  value={password}
                  onChange={this.handleChange}
                  style={{ backgroundColor: 'none' }}
                  required
                />
                <label htmlFor="input-password">{`${getLabelByName(NEW)} ${getLabelByName(PASSWORD)}`}</label>
              </div>
              <img src={lock} alt="lock icon" />
            </div>
            <div className="form-icon col-md-12">
              <div className="form-group form-label-group">
                <input
                  type="password"
                  name="confirmation"
                  className="form-control"
                  id="input-password-confirm"
                  placeholder="Confirm New Password"
                  value={confirmation}
                  onChange={this.handleChange}
                  style={{ backgroundColor: 'none' }}
                  required
                />
                <label htmlFor="input-password-confirm">
                  {`${getLabelByName(CONFIRM)} ${getLabelByName(NEW)} ${getLabelByName(PASSWORD)}`}
                </label>
              </div>
              <img src={lock} alt="lock icon" />
            </div>
          </div>

          <div className="text-center mt-3">
            <button
              type="submit"
              className="btn btn-lg submit-btn"
            >
              {isLoading ? (
                <>
                  <span>
                    {`${getLabelByName(RESETTING)}`}
                  </span>
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </>
              ) : getLabelByName(RESET_PASSWORD)}
            </button>
          </div>
        </form>
        <br />
        <div id="back-homepage">
          <div className="d-flex justify-content-center">
            <Link to="/" style={{ color: colors.textPrimary, font: 'Book 20px/17px Avenir' }}>
              <ArrowBackIos />
              {`  ${getLabelByName(BACK_TO)} ${getLabelByName(HOME)}`}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  resetPasswordAction: PropTypes.func.isRequired,
  resetDataError: PropTypes.object,
  resetData: PropTypes.object,
  history: PropTypes.object.isRequired,
  resetStatus: PropTypes.string,
};

export const mapStateToProps = (state) => ({
  resetDataError: state.resetPasswordReducer.resetDataError,
  resetData: state.resetPasswordReducer.resetData,
  resetStatus: state.resetPasswordReducer.resetStatus,
});

export default compose(withRouter, connect(mapStateToProps, { resetPasswordAction }))(ResetPassword);
