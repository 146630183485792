import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import CourseConsole from "../../actions/course/CourseConsole";
import QuestionnaireRenderer from "../../Questionnaire/QuestionnaireRenderer";
import { retrieveParticipant } from "../../../redux/actions/activities/participantActions";

const ContributionRenderer = ({ activity, contribution, participant, retrieveParticipant }) => {

    const [render, setRender] = useState(false);
    const Renderers = {
        DATA_COLLECTION: QuestionnaireRenderer,
        COURSE: CourseConsole,
    };

    useEffect(() => {
        if (contribution) {
            retrieveParticipant(contribution.activityId, contribution.participantId);
        }
        setRender(true);
    }, [contribution]);

    if (render) {

        const Renderer = Renderers[contribution.action.type] || QuestionnaireRenderer;
        return <>
            {participant.firstName} {participant.lastName}
            <Renderer
                activity={activity}
                action={activity.actions.find(action => action.id == contribution.actionId)}
                participant={participant}
                isPreviewMode={true}
                participantAction={contribution}
            />
        </>
    } else {
        return <>Loading...</>
    }

}

const mapStateToProps = (state) => ({
    participant: state.participantCRUDReducer,
});

export default compose(
    connect(mapStateToProps, {
        retrieveParticipant,
    }),
)(ContributionRenderer);