/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { getLabelByName } from '../../../../helpers/helperFunctions';
import { TEXT } from '../../../../helpers/constants';

class BooleanInput extends Component {
    state = {
      trueLabel: '',
      trueValue: '',
      falseLabel: '',
      falseValue: '',
      isNew: null,
    };

    handleChange = (e) => {
      const { name, value } = e.target;
      this.setState({
        [name]: value,
      });
    };

    componentDidMount = () => {
      const { settings } = this.props;
      if (settings && settings.length !== 0) {
        this.setState({
          trueLabel: settings[0].trueLabel,
          trueValue: settings[0].trueValue,
          falseLabel: settings[0].falseLabel,
          falseValue: settings[0].falseValue,
          isNew: false,
        });
      } else {
        this.setState({ isNew: true });
      }
    };

    componentDidUpdate = (prevProps, prevState) => {
      const {
        addOption, responseIndex, questionIndex, questionType,
        handleOptionChange,
      } = this.props;
      const {
        trueLabel, trueValue, falseLabel, falseValue, isNew,
      } = this.state;
      if (prevState.trueValue !== trueValue
        || prevState.falseValue !== falseValue
        || prevState.trueLabel !== trueLabel
        || prevState.falseLabel !== falseLabel) {
          if ((trueLabel !== '' && trueValue !== ''
          && falseLabel !== '' && falseValue !== '')) {
        if (isNew) {
          addOption(
            questionIndex,
            responseIndex,
            questionType,
            {
              trueLabel,
              trueValue,
              falseLabel,
              falseValue,
            },
          );
        } else {
          handleOptionChange({
            trueLabel,
            trueValue,
            falseLabel,
            falseValue,
          }, 0, questionIndex, responseIndex);
          }
        }
      }
    }

    render() {
      const {
        trueLabel, trueValue, falseLabel, falseValue,
      } = this.state;

      return (
      <div className="col-md-12 boolean">
        <div className="col-md-6">
          <label htmlFor="input-true_label">
            True Label :
            <input
              value={trueLabel}
              type="text"
              name="trueLabel"
              className="form-control"
              id="input-true_label"
              onChange={this.handleChange}
              placeholder={getLabelByName(TEXT)}
              style={{ backgroundColor: '#FFFFFF' }}
            />
          </label>
          <label htmlFor="input-true_value">
            True Value :
            <input
              value={trueValue}
              type="text"
              name="trueValue"
              className="form-control"
              id="input-true_value"
              onChange={this.handleChange}
              placeholder={getLabelByName(TEXT)}
              style={{ backgroundColor: '#FFFFFF' }}
            />
          </label>
        </div>
        <div className="col-md-6">
          <label htmlFor="input-false_label">
            False Label :
            <input
              value={falseLabel}
              type="text"
              name="falseLabel"
              className="form-control"
              id="input-false_label"
              onChange={this.handleChange}
              placeholder={getLabelByName(TEXT)}
              style={{ backgroundColor: '#FFFFFF' }}
            />
          </label>
          <label htmlFor="input-false_value">
            False Value :
            <input
              value={falseValue}
              type="text"
              name="falseValue"
              className="form-control"
              id="input-name"
              onChange={this.handleChange}
              placeholder={getLabelByName(TEXT)}
              style={{ backgroundColor: '#FFFFFF' }}
            />
          </label>
        </div>
      </div>
      );
    }
}

export default BooleanInput;
