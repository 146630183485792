/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import {
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@material-ui/icons";
import {
  PREVIEW,
  PROCESSING,
  QUESTIONNAIRE,
  QUESTIONNAIRE_DESCRIPTION,
  SUBMIT,
  CLOSE,
  colors,
} from "../../../../helpers/constants";
import { getLabelByName } from "../../../../helpers/helperFunctions";

const QuestionnairePageHeader = ({
  switchPreviewQuestionnaire,
  preveiewQuestionnaire,
  isLoading,
}) => {
  return (
    <div className="create-article-header">
      <div className="create-article-title">
        <div className="article-title">
          {getLabelByName(QUESTIONNAIRE)}
        </div>
        <div className="article-helper">
          {getLabelByName(QUESTIONNAIRE_DESCRIPTION)}
        </div>
      </div>

      <div>
        <div className="edit-button">
          {!preveiewQuestionnaire ? (
            <span
              style={{
                color: colors.primaryColor,
              }}
              onClick={() => switchPreviewQuestionnaire()}
              title={`${getLabelByName(
                PREVIEW,
              )} ${getLabelByName(QUESTIONNAIRE)}`}>
              <VisibilityOutlined />
            </span>
          ) : (
            <span
              style={{
                color: colors.primaryColor,
              }}
              onClick={() => switchPreviewQuestionnaire()}
              title={`${getLabelByName(
                CLOSE,
              )} ${getLabelByName(PREVIEW)}`}>
              <VisibilityOffOutlined />
            </span>
          )}
          <button
            type="submit"
            className="btn btn-lg submit-btn"
            disabled={isLoading}>
            {!isLoading
              ? getLabelByName(SUBMIT)
              : `${getLabelByName(PROCESSING)}...`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuestionnairePageHeader;
