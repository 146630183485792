import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { toast } from "react-toastify";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
} from "@material-ui/core";
import { getLabelByName } from "../../helpers/helperFunctions";
import {
  CANCEL,
  SAVE,
  SAVING,
} from "../../helpers/constants";
import { editProfile } from "../../redux/actions/editProfileActions";

const NotificationSettingsModal = ({
  isOpen,
  closeModal,
  profileData,
  editProfileReq,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    notificationFrequency: "daily summary",
    notificationMethod: "email",
  });

  const notificationFrequencies = [
    "real-time",
    "daily summary",
    "weekly summary",
    "month summary",
  ];
  const notificationMethods = [
    "email",
    "push notification",
    "both",
  ];
  //   const classes = FormStyles();

  const handleRadioChange = (e) => {
    const { name, checked, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: checked ? value : prevState[name],
    }));
  };

  const handleSaveOnClick = async () => {
    if (!profileData.data) return;

    try {
      const userId = profileData.data.id;

      const payload = {
        ...profileData.data,
        settings: {
          ...profileData.data.settings,
          notificationFrequency:
            state.notificationFrequency,
          notificationMethod: state.notificationMethod,
        },
      };

      setIsLoading(true);

      const response = await editProfileReq(
        payload,
        userId,
      );

      setIsLoading(false);

      if (response.status === 200) {
        toast.success(
          "Notification settings updated successfully",
        );
        closeModal();
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Modal size="lg" show={isOpen} onHide={closeModal}>
      <div>
        <div>
          <div>
            <h2 className="mb-3">Notification Settings</h2>
          </div>
        </div>

        <div>
          <form id="form">
            <FormControl fullWidth component="fieldset">
              <FormLabel
                className="font-weight-bold text-dark"
                component="legend">
                Notification Frequency
              </FormLabel>
              <RadioGroup
                className="ml-3"
                aria-label="notificationFrequency"
                name="notificationFrequency"
                onChange={handleRadioChange}>
                {notificationFrequencies.map(
                  (frequency) => (
                    <FormControlLabel
                      value={frequency}
                      id={`check-${frequency}`}
                      labelPlacement="end"
                      checked={
                        state.notificationFrequency ===
                        frequency
                      }
                      control={<Radio color="primary" />}
                      label={frequency}
                    />
                  ),
                )}
              </RadioGroup>
            </FormControl>

            <FormControl fullWidth component="fieldset">
              <FormLabel
                className="font-weight-bold text-dark"
                component="legend">
                Notification Method
              </FormLabel>
              <RadioGroup
                className="ml-3"
                aria-label="notificationMethod"
                name="notificationMethod"
                onChange={handleRadioChange}>
                {notificationMethods.map((method) => (
                  <FormControlLabel
                    value={method}
                    id={`check-${method}`}
                    labelPlacement="end"
                    checked={
                      state.notificationMethod === method
                    }
                    control={<Radio color="primary" />}
                    label={method}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </form>
        </div>

        <Modal.Footer>
          <Button color="primary" onClick={closeModal}>
            {getLabelByName(CANCEL)}
          </Button>
          <Button
            variant="contained"
            color="primary"
            form="form"
            type="button"
            onClick={handleSaveOnClick}
            disabled={isLoading}>
            {isLoading
              ? `${getLabelByName(SAVING)}...`
              : getLabelByName(SAVE)}
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  profileData: state.authReducer.data,
  landingData: state.landingReducer.landingData,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    editProfileReq: editProfile,
  }),
)(NotificationSettingsModal);
