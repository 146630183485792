/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { Clear } from "@material-ui/icons";
import {
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import {
  colors,
  PARAMETERS,
  ADVANCED_SETTINGS,
  MULTIPLE_CHOICE,
  LABEL,
  REQUIRED,
  ADD,
  INPUT_KEY,
  SOURCES,
  OPTION,
  MULTIPLE,
} from "../../../../../helpers/constants";
import { optionTypes } from "../../components/questionsHelpers";
import { getLabelByName } from "../../../../../helpers/helperFunctions";
import QuestionSelectionDropdown from "../../QuestionSelectionDropdown";
import Switch from "../../../../generics/Switch";
import deleteIcon from "../../../../../assets/icons/delete-dark-orange.svg";
import Option from "../../components/Option";
import MultipleChoiceInput from "../../components/MultipleChoiceInput";
import BooleanInput from "../../components/BooleanInput";
import AddMultipleOption from "./addMultipleOption";

const QuestionResponse = ({
  index,
  questionIndex,
  response,
  addOption,
  addSource,
  removeSource,
  removeOption,
  removeResponse,
  renderCheckbox,
  displayMultipleOption,
  addAdvancedSettings,
  handleOptionChange,
  handleResponseChange,
  handleSourcesChange,
  generateOptionTypes,
  handleChoicesSettings,
  displayAdvancedSettings,
  handleDisplayMultipleOption,
}) => {
  const [optionValue, setOptionValue] = useState("");

  return (
    <div
      style={{
        margin: "6px 0",
        padding: "18px",
        backgroundColor: "#E9DACB",
      }}>
      <div className="row ml-0">
        <div className="form-icon col-md-8">
          <div className="form-group form-label-group">
            <input
              type="text"
              name="question-label"
              className="form-control"
              id={`input-response-${questionIndex}-${index}`}
              placeholder={getLabelByName(LABEL)}
              value={response.label}
              onChange={(e) =>
                handleResponseChange(
                  e.target.value,
                  "label",
                  questionIndex,
                  index,
                )
              }
              style={{
                backgroundColor: "#FFFFFF",
              }}
              required
            />
            <label
              htmlFor={`input-response-${questionIndex}-${index}`}>
              {getLabelByName(LABEL)}
            </label>
          </div>
        </div>

        <div className="col-md-4">
          <QuestionSelectionDropdown
            handleSelectType={handleResponseChange}
            selected={response.type}
            questionIndex={questionIndex}
            responseIndex={index}
          />
        </div>
      </div>

      <div className="row ml-0">
        <div className="form-icon col-md-12">
          <small
            style={{
              color: colors.textSecondary,
              fontStyle: "italic",
            }}>
            e.g: profile_picture
          </small>

          <div className="form-group form-label-group">
            <input
              type="text"
              name="input-key-label"
              className="form-control"
              id={`input-key-${questionIndex}-${index}`}
              placeholder={getLabelByName(INPUT_KEY)}
              value={response.input_key}
              onChange={(e) =>
                handleResponseChange(
                  e.target.value,
                  "input_key",
                  questionIndex,
                  index,
                )
              }
              style={{
                backgroundColor: "#FFFFFF",
              }}
              required
            />
            <label
              htmlFor={`input-key-${questionIndex}-${index}`}>
              {getLabelByName(INPUT_KEY)}
            </label>
          </div>
        </div>

        {(response.type === "DATE_TIME_RANGE" ||
          response.type === "DATE_TIME") && (
          <FormGroup className="col-md-12">
            <FormControlLabel
              className="p-0 m-0"
              control={
                <Switch
                  checked={
                    response?.options?.[0]
                      ? response?.options?.[0]?.allow_time
                      : false
                  }
                  onChange={() =>
                    handleOptionChange(
                      {
                        allow_time: response?.options?.[0]
                          ? !response?.options?.[0]
                              ?.allow_time
                          : true,
                      },
                      0,
                      questionIndex,
                      index,
                    )
                  }
                />
              }
              label={
                <span
                  style={{
                    color: colors.textSecondary,
                  }}>
                  Include Time
                </span>
              }
              labelPlacement="end"
            />
          </FormGroup>
        )}
      </div>

      {/* ============= Add options section ============= */}
      {optionTypes.includes(response.type) &&
        response?.options?.map((option, optionIndex) => (
          <Option
            optionLabel={renderCheckbox(index, optionIndex)}
            handleOptionChange={handleOptionChange}
            optionIndex={optionIndex}
            questionIndex={questionIndex}
            responseIndex={index}
            value={option}
            removeOption={removeOption}
          />
        ))}

      {/* ============= Boolean Choice Settings Input ============= */}
      {response.type === "BOOLEAN_QUESTION" && (
        <BooleanInput
          responseIndex={index}
          questionIndex={questionIndex}
          addOption={addOption}
          handleOptionChange={handleOptionChange}
          questionType={response.type}
          settings={response.options}
        />
      )}

      {/* ============= Required and Remove Response Buttons ============= */}
      <div className="d-flex justify-content-between">
        <FormGroup>
          <FormControlLabel
            className="p-0 m-0"
            control={
              <Switch
                checked={response.required}
                onChange={() =>
                  handleResponseChange(
                    !response.required,
                    "required",
                    questionIndex,
                    index,
                  )
                }
              />
            }
            label={
              <span
                style={{
                  color: colors.textSecondary,
                }}>
                {getLabelByName(REQUIRED)}
              </span>
            }
            labelPlacement="start"
          />
        </FormGroup>

        <div>
          <div
            className="club-icon"
            data-toggle="modal"
            style={{
              cursor: "pointer",
            }}
            onClick={() =>
              removeResponse(questionIndex, index)
            }>
            <img
              src={deleteIcon}
              alt="edit-action"
              width="24px"
            />
          </div>
        </div>
      </div>

      {/* ============= Add Options and Advanced Settings Button ============= */}
      {optionTypes.includes(response.type) && (
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex align-items-center mb-3">
              <div
                className="mt-3"
                style={{
                  color: colors.primaryColor,
                  textDecoration: "underline",
                  fontWeight: "bold",
                  cursor: "pointer",
                  width: "fit-content",
                }}
                onClick={() =>
                  addOption(
                    questionIndex,
                    index,
                    response.type,
                  )
                }>
                {`${getLabelByName(ADD)} ${getLabelByName(
                  OPTION,
                )}`}
              </div>

              <div
                className="mt-3 ml-3"
                style={{
                  color: colors.primaryColor,
                  textDecoration: "underline",
                  fontWeight: "bold",
                  cursor: "pointer",
                  width: "fit-content",
                }}
                onClick={() =>
                  handleDisplayMultipleOption(true)
                }>
                {`${getLabelByName(ADD)} ${getLabelByName(
                  MULTIPLE,
                )} ${getLabelByName(OPTION)}`}
              </div>
            </div>

            {displayMultipleOption && (
              <AddMultipleOption
                index={index}
                optionValue={optionValue}
                response={response}
                questionIndex={questionIndex}
                setOptionValue={setOptionValue}
                generateOptionTypes={generateOptionTypes}
                handleDisplayMultipleOption={
                  handleDisplayMultipleOption
                }
              />
            )}
          </div>

          <div
            className="mt-3"
            style={{
              color: colors.primaryColor,
              textDecoration: "underline",
              fontWeight: "bold",
              cursor: "pointer",
              width: "fit-content",
            }}
            onClick={() => addAdvancedSettings()}>
            {getLabelByName(ADVANCED_SETTINGS)}
          </div>
        </div>
      )}

      {/* ============= Display Advanced Settings UI ============= */}
      {(displayAdvancedSettings || response.sources) &&
        response?.sources?.map((source, sourceIndex) => (
          <div className="row">
            <div className="col-md-11">
              <div className="row boolean">
                <div className="col-md-6">
                  <label
                    htmlFor={`input-sources-${questionIndex}-${index}-${sourceIndex}`}>
                    Action
                    <input
                      value={source.actionId}
                      type="text"
                      className="form-control"
                      id={`input-sources-actionId-${questionIndex}-${index}-${sourceIndex}`}
                      onChange={(e) =>
                        handleSourcesChange(
                          questionIndex,
                          index,
                          sourceIndex,
                          "actionId",
                          e.target.value,
                        )
                      }
                      style={{
                        backgroundColor: "#FFFFFF",
                      }}
                    />
                  </label>
                </div>

                <div className="col-md-6">
                  <label
                    htmlFor={`input-sources-${questionIndex}-${index}-${sourceIndex}`}>
                    Input Key
                    <input
                      value={source.input_key}
                      type="text"
                      className="form-control"
                      id={`input-sources-input_key-${questionIndex}-${index}-${sourceIndex}`}
                      onChange={(e) =>
                        handleSourcesChange(
                          questionIndex,
                          index,
                          sourceIndex,
                          "input_key",
                          e.target.value,
                        )
                      }
                      style={{
                        backgroundColor: "#FFFFFF",
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <Clear
                style={{
                  color: "#C6A177",
                }}
                onClick={() =>
                  removeSource(
                    questionIndex,
                    index,
                    sourceIndex,
                  )
                }
              />
            </div>
          </div>
        ))}

      <div
        className="mt-3"
        style={{
          color: colors.primaryColor,
          textDecoration: "underline",
          fontWeight: "bold",
          cursor: "pointer",
          width: "fit-content",
        }}
        onClick={() => addSource(questionIndex, index)}>
        {`${getLabelByName(ADD)} ${getLabelByName(
          SOURCES,
        )}`}
      </div>
      {/* End Add Options */}

      {/* ============= Multiple Choice Settings Input ============= */}
      {response.type === "MULTIPLE_CHOICE" && (
        <>
          <div className="mt-3 mb-3">
            <u>{`${getLabelByName(
              MULTIPLE_CHOICE,
            )} ${getLabelByName(PARAMETERS)}`}</u>
          </div>

          <MultipleChoiceInput
            response={response}
            questionIndex={questionIndex}
            responseIndex={index}
            handleChoicesSettings={handleChoicesSettings}
          />
        </>
      )}
    </div>
  );
};

export default QuestionResponse;
