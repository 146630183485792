import React, { useState } from "react";
import {
  Redirect,
  withRouter,
  useLocation,
} from "react-router-dom";
import { toast } from "react-toastify";
import { getProfileDataFromLocalStorage } from "../helpers/helperFunctions";

const withAuth = (Component) => {
  const AuthCheck = (props) => {
    const [authData, setAuthData] = useState(
      getProfileDataFromLocalStorage(),
    );

    const location = useLocation();

    // console.log("AUTH_DATA", authData);

    if (!authData || authData.status !== 200) {
      toast.error(
        "Your session has expired.\nPlease Login again!",
      );

      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location.pathname },
          }}
        />
      );
    }

    return <Component {...props} />;
  };

  return withRouter(AuthCheck);
};

export default withAuth;
