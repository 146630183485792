import sha1 from "js-sha1";
import { baseURL } from "./constants";

export const getHeaders = (payload, requestURI) => {
  try {
    const headers = JSON.parse(
      localStorage.getItem("headers"),
    );
    const hashString = `${baseURL}${requestURI}${
      payload !== null ? JSON.stringify(payload) : ""
    }${headers["x-auth-token"]}`;
    const userHeaders = {
      "Content-Type": "application/json",
      "X-SERVICE-PROVIDER": headers["x-service-provider"],
      "X-SERVICE-USER-NAME": headers["x-service-user-name"],
      "X-REQUEST-HASH": sha1(hashString),
    };

    return userHeaders;
  } catch (error) {
    console.error(error);
  }
};

export const getGuestHeaders = (incomingHeaders) => {
  const headers = JSON.parse(
    localStorage.getItem("guestHeaders"),
  );

  const userHeaders = {
    ...incomingHeaders,
    "Content-Type": "application/json",
    "x-service-provider": headers["x-service-provider"],
    "x-service-user-name": headers["x-service-user-name"],
  };

  return userHeaders;
};
