/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import {GlossaryMain} from '../GlossaryCard/GlossaryMain';
import {GlossaryList} from '../GlossaryCard/GlossaryList';

export class TermsConditions extends Component {

  render() {
    const { handleChange, termsInfo } = this.props;

    const termInfoMain = termsInfo?.data?.main ? JSON.parse(termsInfo.data.main) : {};
    const termInfoList = termsInfo?.data?.list ? JSON.parse(termsInfo.data.list) : [];

    return (
      <>
        <GlossaryMain
          prevData={termInfoMain}
          handleChange={handleChange}
          headerLabel="Message"
          textLabel="Text"
        />

        <GlossaryList
          glossaryList={termInfoList}
          handleChange={handleChange}
          headerLabel="Policy"
          titleLabel="Title"
          textLabel="Text"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(TermsConditions);
