import {
  GET_CLUB_ARTICLES_SUCCESS,
  GET_CLUB_ARTICLES_ERROR,
} from "../actions/types";

const initialState = {
  getClubArticlesData: null,
  getClubArticlesDataError: null,
  getClubArticlesStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CLUB_ARTICLES_SUCCESS:
      return {
        ...state,
        getClubArticlesData: action.payload,
        getClubArticlesDataError: null,
        getClubArticlesStatus: "success",
      };
    case GET_CLUB_ARTICLES_ERROR:
      return {
        ...state,
        getClubArticlesDataError: action.payload,
        getClubArticlesData: null,
        getClubArticlesStatus: "error",
      };
    default:
      return {
        ...state,
        getClubArticlesStatus: null,
      };
  }
};
