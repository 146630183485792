/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import {
  ExpandMore as ExpandMoreIcon,
  AddCircleOutlineOutlined,
} from "@material-ui/icons";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  colors,
  FORM_DESCRIPTION,
  FORM_TITLE,
  QUESTION,
  NEW,
} from "../../../../helpers/constants";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import LabelInput from "./labelInput";
import QuestionResponse from "./questionResponse/index";
import QuestionFooter from "./questionFooter";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  questionWrapper: {
    background: "#f7f4f2 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 5px #00000029",
  },
  questionHeading: {
    background: "#f7f4f2 0% 0% no-repeat padding-box",
  },
  details: {
    flexDirection: "column",
    background: "#f7f4f2 0% 0% no-repeat padding-box",
  },
}));

const UnpreviewedQuestionnaire = ({
  name,
  hasDataLoaded,
  description,
  addOption,
  addResponse,
  addQuestion,
  addSource,
  cloneQuestion,
  removeQuestion,
  removeSource,
  removeOption,
  removeResponse,
  handleChange,
  questionsList,
  renderCheckbox,
  handleQuestionChange,
  handleResponseChange,
  handleSourcesChange,
  handleOptionChange,
  handleChoicesSettings,
  handleImageChange,
  generateOptionTypes,
  handleDeleteLabelImage,
  displayAdvancedSettings,
  handleDisplayMultipleOption,
  displayMultipleOption,
  addAdvancedSettings,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <div className="institute-card article-card">
          <div className="form-icon col-md-12">
            <div className="form-group form-label-group">
              <input
                type="text"
                name="name"
                className="form-control"
                id="input-name"
                placeholder={getLabelByName(FORM_TITLE)}
                value={name}
                onChange={handleChange}
                style={{
                  backgroundColor: "#FFFFFF",
                }}
                required
              />
              <label htmlFor="input-name">
                {getLabelByName(FORM_TITLE)}
              </label>
            </div>
          </div>

          <div className="form-icon col-md-12">
            <div className="form-group form-label-group">
              <input
                type="text"
                name="description"
                className="form-control"
                id="input-description"
                placeholder={getLabelByName(
                  FORM_DESCRIPTION,
                )}
                value={description}
                onChange={handleChange}
                style={{
                  backgroundColor: "#FFFFFF",
                }}
                required
              />
              <label htmlFor="input-description">
                {getLabelByName(FORM_DESCRIPTION)}
              </label>
            </div>
          </div>
        </div>
      </div>

      {questionsList.map((question, questionIndex) => (
        <div className="d-flex align-items-center justify-content-center">
          <div className="institute-card article-card">
            <Accordion
              className={classes.questionWrapper}
              defaultExpanded>
              <AccordionSummary
                className={classes.questionHeading}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <div className="create-card-header">
                  <div className="card-header-title">
                    <h5>{`${getLabelByName(QUESTION)} ${
                      questionIndex + 1
                    }`}</h5>
                  </div>
                </div>
              </AccordionSummary>

              <AccordionDetails className={classes.details}>
                <LabelInput
                  handleImageChange={handleImageChange}
                  questionIndex={questionIndex}
                  labelImage={question.meta?.labelImage}
                  content={
                    question?.content?.startsWith("<p>")
                      ? question.content
                      : `<p>${question.content}</p>`
                  }
                  handleQuestionChange={
                    handleQuestionChange
                  }
                  handleDeleteLabelImage={
                    handleDeleteLabelImage
                  }
                />

                <div>
                  <p>Responses</p>
                  {question.settings?.responses?.map(
                    (response, index) => (
                      <QuestionResponse
                        index={index}
                        response={response}
                        questionIndex={questionIndex}
                        addOption={addOption}
                        addSource={addSource}
                        displayAdvancedSettings={
                          displayAdvancedSettings
                        }
                        addAdvancedSettings={
                          addAdvancedSettings
                        }
                        handleResponseChange={
                          handleResponseChange
                        }
                        handleOptionChange={
                          handleOptionChange
                        }
                        handleChoicesSettings={
                          handleChoicesSettings
                        }
                        removeResponse={removeResponse}
                        handleSourcesChange={
                          handleSourcesChange
                        }
                        generateOptionTypes={
                          generateOptionTypes
                        }
                        displayMultipleOption={
                          displayMultipleOption
                        }
                        removeOption={removeOption}
                        removeSource={removeSource}
                        renderCheckbox={renderCheckbox}
                        handleDisplayMultipleOption={
                          handleDisplayMultipleOption
                        }
                      />
                    ),
                  )}
                </div>

                <hr className="w-100 mt-3" />

                <QuestionFooter
                  question={question}
                  questionIndex={questionIndex}
                  addResponse={addResponse}
                  cloneQuestion={cloneQuestion}
                  removeQuestion={removeQuestion}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      ))}

      {/* ============= NEW QUESTION =========== */}
      {hasDataLoaded && (
        <div
          className="mt-3"
          style={{
            color: colors.primaryColor,
            textDecoration: "underline",
            fontWeight: "bold",
            cursor: "pointer",
            width: "fit-content",
          }}
          onClick={() => addQuestion()}>
          <AddCircleOutlineOutlined
            style={{ fontSize: "27px" }}
            className="pr-2"
          />
          {`${getLabelByName(NEW)} ${getLabelByName(
            QUESTION,
          )}`}
        </div>
      )}
    </>
  );
};

export default UnpreviewedQuestionnaire;
