/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { Modal } from "react-bootstrap";
import moment from "moment";
import slugify from "slugify";
import { toast } from "react-toastify";
import {
  TextField,
  FormControl,
  InputLabel,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Button,
  IconButton,
} from "@material-ui/core";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import clubIcon from "../../../../assets/manage-clubs.svg";
import {
  createClub,
  updateClub,
} from "../../../../redux/actions/institutesActions";
import {
  ABOUT,
  ALL_USERS,
  CANCEL,
  CLUB,
  CREATE,
  CREATED_SUCCESSFULLY,
  CREATE_CLUB_SUBTITLE,
  EDIT,
  EDIT_CLUB_SUBTITLE,
  ERROR_PROCESSING_REQUEST,
  ERROR_UPLOADING_PICTURE,
  INVITATION_ONLY,
  JOIN,
  MEMBERS_ONLY,
  NAME,
  SAVE,
  SELECT_CLUB_STATUS,
  SELECT_FROM_COMPUTER,
  UPDATED_SUCCESSFULLY,
  VISIBILITY,
} from "../../../../helpers/constants";
import { getAllClubsFromLocalStorage, getLabelByName } from "../../../../helpers/helperFunctions";
import FormStyles from "./main.styles";
import Loader from "../../../Loader";
import cameraOutline from "../../../../assets/icons/cameraOutline.svg";

const initialState = {
  name: "",
  type: "Default",
  visibility: "PUBLIC",
  status: "ACTIVE",
  handle: "",
  profile: {},
  meta: {},
  settings: {
    join_call_to_action: getLabelByName(JOIN),
    enable_activity_feed: true,
    members_create_posts: true,
    display_members_panel: true,
  },
  verified: true,
  selectedResource: 1,
  statusClicked: null,
  selectedFile: null,
  imagePreview: null,
  clubsData: [],
  visibilityOptions: [
    { value: "PUBLIC", name: getLabelByName(ALL_USERS) },
    {
      value: "PRIVATE",
      name: getLabelByName(MEMBERS_ONLY),
    },
    {
      value: "RESTRICTED",
      name: getLabelByName(INVITATION_ONLY),
    },
  ],
  typeOptions: [{ value: "DEFAULT", name: "Default" }],
};

const CreateClubModal = ({
  isCreateModalVisible,
  changeIsCreateModal,
  createClub: createClubAction,
  updateClub: updateClubAction,
  editClub,
  getClubs,
  createClubStatus,
  updateClubStatus,
}) => {
  const classes = FormStyles();
  const [data, setdata] = useState({ ...initialState });
  const [clubsData, setClubsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const mobile = useMediaQuery("(max-width:768px)");

  const handleRadio = (e) => {
    setdata({
      ...data,
      status: e.target.value,
      statusClicked: "clicked",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "description":
        setdata({
          ...data,
          profile: {
            ...data.profile,
            [name]: value,
          },
        });
        break;

      default:
        setdata({
          ...data,
          [name]: value,
        });
        break;
    }
  };

  const fileChange = (e) => {
    const blob = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setdata({
        ...data,
        selectedFile: blob,
        imagePreview: fileReader.result,
      });
    };
    fileReader.readAsDataURL(e.target.files[0]);
  };

  const submitForm = async (e, _editClub) => {
    setIsLoading(true);
    e.preventDefault();
    const {
      name,
      selectedFile,
      selectedResource,
      handle,
      profile,
      meta,
    } = data;

    if (!selectedFile) {
      const payload = {
        ...data,
        handle: handle || slugify(name, { lower: true }),
        profile: {
          ...profile,
          picture: profile?.picture || "",
        },
        meta: { ...data.meta },
      };
      delete payload.visibilityOptions;
      delete payload.selectedResource;
      delete payload.imagePreview;
      delete payload.statusClicked;
      delete payload.typeOptions;

      if (!editClub) {
        await createClubAction(payload);
      } else {
        const updatedPayload = {
          ...payload,
        };
        await updateClubAction(
          updatedPayload.id,
          updatedPayload,
        );
      }
    }

    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("upload_preset", "qtt2g8ao");
      const options = {
        method: "POST",
        body: formData,
      };
      fetch(
        "https://api.Cloudinary.com/v1_1/ayudigital/image/upload",
        options,
      )
        .then((res) => res.json())
        .then(async (res) => {
          const { secure_url } = res;
          const payload = {
            ...data,
            handle:
              handle || slugify(name, { lower: true }),
            profile: {
              ...profile,
              picture: secure_url,
            },
            meta: { ...data.meta },
          };
          delete payload.visibilityOptions;
          delete payload.selectedResource;
          delete payload.imagePreview;
          delete payload.statusClicked;
          delete payload.typeOptions;

          if (!editClub) {
            await createClubAction(payload);
          } else {
            const updatedPayload = {
              ...payload,
            };
            await updateClubAction(
              updatedPayload.id,
              updatedPayload,
            );
          }
        })
        .catch((err) => {
          toast.error(
            getLabelByName(ERROR_UPLOADING_PICTURE),
          );
          setdata({ ...data, isSending: false });
        });
    }
  };

  const {
    name,
    visibility,
    visibilityOptions,
    description,
    status,
    startDate,
    endDate,
    parentId,
    imagePreview,
    selectedResource,
    profile,
    meta,
  } = data;

  const statuses = [
    { index: 0, text: "Active", value: "ACTIVE" },
    { index: 1, text: "Inactive", value: "INACTIVE" },
  ];

  let previewToDisplay = clubIcon;
  if (!imagePreview && profile && profile.picture) {
    previewToDisplay = profile.picture;
  }
  if (imagePreview) {
    previewToDisplay = imagePreview;
  }

  useEffect(() => {
    if (editClub !== null) {
      setdata({
        ...data,
        ...editClub,
        meta: {
          ...data.meta,
          ...editClub.meta,
        },
      });
    }
  }, [editClub]);
  useEffect(() => {
    setClubsData(
      getAllClubsFromLocalStorage(),
    );
  }, []);
  useEffect(() => {
    if (
      isCreateModalVisible === false &&
      editClub === null
    ) {
      setdata(initialState);
    }
  }, [isCreateModalVisible]);

  useEffect(() => {
    if (updateClubStatus === "success") {
      getClubs();
      toast.success(getLabelByName(UPDATED_SUCCESSFULLY));
      changeIsCreateModal();
    }
    if (updateClubStatus === "error") {
      toast.error(getLabelByName(ERROR_PROCESSING_REQUEST));
    }

    if (createClubStatus === "success") {
      getClubs();
      toast.success(getLabelByName(CREATED_SUCCESSFULLY));
      changeIsCreateModal();
    }
    if (createClubStatus === "error") {
      toast.error(getLabelByName(ERROR_PROCESSING_REQUEST));
    }
    setIsLoading(false);
  }, [createClubStatus, updateClubStatus]);

  return (
    <Modal
      size="lg"
      show={isCreateModalVisible}
      onHide={changeIsCreateModal}>
      <div>
        <div
          style={{
            padding: mobile
              ? "1rem 1rem 0 1rem"
              : "1rem 2rem 0 2rem",
            font: ".8rem",
          }}>
          <div>
            <h2>
              {editClub
                ? getLabelByName(EDIT)
                : getLabelByName(CREATE)}{" "}
              {getLabelByName(CLUB)}
            </h2>
            <p>
              {editClub
                ? getLabelByName(EDIT_CLUB_SUBTITLE)
                : getLabelByName(CREATE_CLUB_SUBTITLE)}
              .
            </p>
          </div>
        </div>
        <div
          style={{
            padding: mobile
              ? "0 1rem 1rem 1rem"
              : "0 2rem 1rem 2rem",
          }}>
          <form
            id="form"
            className={classes.form}
            onSubmit={(e) => submitForm(e, editClub)}>
            <FormControl
              className={
                mobile ? classes.input : classes.inputLeft
              }
              margin="normal"
              fullWidth
              variant="outlined">
              <TextField
                id="name"
                label={getLabelByName(NAME)}
                name="name"
                variant="outlined"
                onChange={handleChange}
                value={name}
                required
              />
            </FormControl>
            <FormControl
              className={
                mobile ? classes.input : classes.inputRight
              }
              margin="normal"
              variant="outlined">
              <InputLabel id="club">
                {getLabelByName(VISIBILITY)}
              </InputLabel>
              <Select
                labelId="visibility"
                id="visibility"
                required
                value={visibility}
                onChange={(e) =>
                  setdata({
                    ...data,
                    visibility: e.target.value,
                  })
                }
                label="Visibility">
                {visibilityOptions.map((option) => (
                  <MenuItem
                    value={option.value}
                    selected={
                      editClub?.visibility === option.value
                    }>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className={classes.input}
              variant="outlined">
              <TextField
                id="description"
                label={getLabelByName(ABOUT)}
                name="description"
                multiline
                margin="normal"
                rows={10}
                variant="outlined"
                onChange={handleChange}
                value={data?.profile?.description}
              />
            </FormControl>

            <FormControl fullWidth component="fieldset">
              <FormLabel component="legend">
                {getLabelByName(SELECT_CLUB_STATUS)}
              </FormLabel>
              <RadioGroup
                row
                aria-label="Club-status"
                name="Status"
                onChange={handleRadio}>
                {statuses.map((statusItem) => (
                  <FormControlLabel
                    value={statusItem.value}
                    id={`check-${statusItem.value}`}
                    labelPlacement="end"
                    checked={status === statusItem.value}
                    control={<Radio color="primary" />}
                    label={statusItem.text}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            <input
              accept="image/*"
              className={classes.uploadInput}
              id="icon-button-file"
              onChange={(e) => fileChange(e)}
              type="file"
            />
            <label
              htmlFor="icon-button-file"
              style={{
                border: "dashed 1px #F38F46",
                backgroundColor: "#F7F4F2",
                padding: "10px",
                borderRadius: "10px",
              }}>
              <>
                {imagePreview || profile?.picture ? (
                  <img
                    src={previewToDisplay}
                    alt="header-logo"
                    style={{
                      margin: ".8rem",
                      width:
                        (imagePreview ||
                          profile?.picture) &&
                        "25px",
                      height:
                        (imagePreview ||
                          profile?.picture) &&
                        "25px",
                      objectFit:
                        (imagePreview ||
                          profile?.picture) &&
                        "cover",
                      borderRadius:
                        (imagePreview ||
                          profile?.picture) &&
                        "50%",
                    }}
                  />
                ) : (
                  <IconButton
                    aria-label="upload picture"
                    component="span">
                    <img
                      src={cameraOutline}
                      style={{ width: "20px" }}
                      alt="nav-icon"
                    />
                  </IconButton>
                )}
              </>
              {getLabelByName(SELECT_FROM_COMPUTER)}
            </label>
          </form>
        </div>
        <Modal.Footer className={classes.input}>
          <Button
            className={classes.button}
            color="primary"
            onClick={changeIsCreateModal}>
            {getLabelByName(CANCEL)}
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            form="form"
            type="submit">
            {isLoading ? <Loader /> : getLabelByName(SAVE)}
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

CreateClubModal.propTypes = {
  isCreateModalVisible: PropTypes.bool.isRequired,
  changeIsCreateModal: PropTypes.func.isRequired,
  createClub: PropTypes.func,
  updateClub: PropTypes.func,
  editClub: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  createClubData: state.createClubReducer.createClubData,
  createClubDataError:
    state.createClubReducer.createClubDataError,
  createClubStatus:
    state.createClubReducer.createClubStatus,
  updateClubData: state.updateClubReducer.updateClubData,
  updateClubDataError:
    state.updateClubReducer.updateClubDataError,
  updateClubStatus:
    state.updateClubReducer.updateClubStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    createClub,
    updateClub,
  }),
)(CreateClubModal);
