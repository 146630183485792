import {
  MARK_NOTIFICATION_SUCCESS, MARK_NOTIFICATION_ERROR,
} from '../actions/types';

const initialState = {
  markNotificationData: null,
  markNotificationDataError: null,
  markNotificationStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MARK_NOTIFICATION_SUCCESS:
      return {
        ...state,
        markNotificationData: action.payload,
        markNotificationDataError: null,
        markNotificationStatus: 'success',
      };
    case MARK_NOTIFICATION_ERROR:
      return {
        ...state,
        markNotificationDataError: action.payload,
        markNotificationData: null,
        markNotificationStatus: 'error',
      };
    default:
      return {
        ...state,
        markNotificationStatus: null,
      };
  }
};
