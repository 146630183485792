import {
  SEARCH_CAMPAIGNS_NAME_ERROR,
  SEARCH_CAMPAIGNS_NAME_SUCCESS,
} from '../actions/types';

const initialState = {
  searchCampaignsNameData: null,
  searchCampaignsNameDataError: null,
  searchCampaignsNameStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_CAMPAIGNS_NAME_SUCCESS:
      return {
        ...state,
        searchCampaignsNameData: action.payload,
        searchCampaignsNameDataError: null,
        searchCampaignsNameStatus: 'success',
      };
    case SEARCH_CAMPAIGNS_NAME_ERROR:
      return {
        ...state,
        searchCampaignsNameDataError: action.payload,
        searchCampaignsNameData: null,
        searchCampaignsNameStatus: 'error',
      };
    default:
      return {
        ...state,
        // searchCampaignsNameStatus: null,
      };
  }
};
