import {
  JOIN_CLUB_ERROR, JOIN_CLUB_SUCCESS,
} from '../actions/types';

const initialState = {
  joinClubData: null,
  joinClubDataError: null,
  joinClubStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case JOIN_CLUB_SUCCESS:
      return {
        ...state,
        joinClubData: action.payload,
        joinClubDataError: null,
        joinClubStatus: 'success',
      };
    case JOIN_CLUB_ERROR:
      return {
        ...state,
        joinClubDataError: action.payload,
        joinClubData: null,
        joinClubStatus: 'error',
      };
    default:
      return {
        ...state,
        joinClubStatus: null,
      };
  }
};
