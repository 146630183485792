import { PARTICIPATE_CAMPAIGN_SUCCESS, PARTICIPATE_CAMPAIGN_ERROR } from '../actions/types';

const initialState = {
  participateCampaignData: null,
  participateCampaignDataError: null,
  participateCampaignStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PARTICIPATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        participateCampaignData: action.payload,
        participateCampaignDataError: null,
        participateCampaignStatus: 'success',
      };
    case PARTICIPATE_CAMPAIGN_ERROR:
      return {
        ...state,
        participateCampaignDataError: action.payload,
        participateCampaignData: null,
        participateCampaignStatus: 'error',
      };
    default:
      return {
        ...state,
        participateCampaignStatus: null,
      };
  }
};
