import {
  CREATE_ACTION_SUCCESS, CREATE_ACTION_ERROR,
} from '../actions/types';

const initialState = {
  createActionData: null,
  createActionDataError: null,
  createActionStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ACTION_SUCCESS:
      return {
        ...state,
        createActionData: action.payload,
        createActionDataError: null,
        createActionStatus: 'success',
      };
    case CREATE_ACTION_ERROR:
      return {
        ...state,
        createActionDataError: action.payload,
        createActionData: null,
        createActionStatus: 'error',
      };
    default:
      return {
        ...state,
        createActionStatus: null,
      };
  }
};
