import apiCall from "../../../helpers/apiCall";
import { getHeaders } from "../../../helpers/getHeaders";
import {
    COURSES_CREATE,
    COURSES_RETRIEVE,
    COURSES_UPDATE,
    COURSES_DELETE,
    COURSES_SEARCH_LESSONS
} from '../types'

export const retrieveCourses = (
    activityId,
    actionId
) => (dispatch) => {
    apiCall.get(
        `/activity/${activityId}/course/${actionId}/lessons`,
        {
            headers: getHeaders(
                null,
                `/activity/${activityId}/course/${actionId}/lessons`
            ),
        }
    ).then((response) => {
        dispatch({
            type: COURSES_RETRIEVE,
            payload: response.data
        });
    }).catch((err) => {
        dispatch({
            type: COURSES_RETRIEVE,
            payload: err
        })
    });
}

export const searchCourseLessons = (
    clubId,
    query = {}
) => (dispatch) => {
    const apiEndpoint = `club/${clubId}/lessons?q=${JSON.stringify(query)}`
    apiCall.get(
        apiEndpoint,
        {
            headers: getHeaders(
                null,
                apiEndpoint,
            )
        }
    ).then((response) => {
        dispatch({
            type: COURSES_SEARCH_LESSONS,
            payload: response.data
        })
    }).catch((err) => {
        dispatch({
            type: COURSES_SEARCH_LESSONS,
            payload: err
        })
    });
}