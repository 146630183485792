/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import moment from 'moment';
import { getLabelByName } from '../../../../helpers/helperFunctions';
import { DATE } from '../../../../helpers/constants';

class DateTimePreview extends Component {
  state = {
    allow_time: false,
  };

  minDate = () => moment(moment.now()).format('YYYY-MM-DDThh:mm');

  getValues = (data, index) => {
    const { handleCustomMeta } = this.props;
    handleCustomMeta({ allow_time: data.allow_time }, index);
  };

  componentDidMount = () => {
    const { question } = this.props;
    this.setState({
      allow_time: question.options[0] ? question.options[0].allow_time : false,

    });
  };

  render() {
    const { allow_time } = this.state;
    return (
      <div className="row ml-0">
        <div className="col-md-6">
          <div className="help-text mb-3">{getLabelByName(DATE)}</div>
          <div className="">
            <input
              type={allow_time ? 'datetime-local' : 'date'}
              name="startDate"
              className="form-control"
              id="input-startDate"
              min={this.minDate()}
              style={{ backgroundColor: '#FFFFFF' }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DateTimePreview;
