import {
  CLONE_QUESTIONNAIRE_SUCCESS, CLONE_QUESTIONNAIRE_ERROR,
} from '../actions/types';

const initialState = {
  cloneQuestionnaireData: null,
  cloneQuestionnaireDataError: null,
  cloneQuestionnaireStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLONE_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        cloneQuestionnaireData: action.payload,
        cloneQuestionnaireDataError: null,
        cloneQuestionnaireStatus: 'success',
      };
    case CLONE_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        cloneQuestionnaireDataError: action.payload,
        cloneQuestionnaireData: null,
        cloneQuestionnaireStatus: 'error',
      };
    default:
      return {
        ...state,
        cloneQuestionnaireStatus: null,
      };
  }
};
