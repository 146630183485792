import React from "react";

const SimpleTextInput = (props) => {
    const { settings, currentValue, participantAction, handleUpdate, isPreviewMode } = props;

    const handleChange = (e) => {
        handleUpdate({
            [settings.input_key]: e.target.value
        })
    }

    const getSettingOrDefault = (property, defaultValue) => {
        if (settings.hasOwnProperty(property)) {
            return settings[property];
        } else {
            return defaultValue;
        }
    }

    return (
        <div className="card section-card">
            <div className="card-body">
                <div className="card-details">
                    <h5 className="card-title">
                        {settings.required ? '*' : ''}
                        {settings.label}
                    </h5>
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            id={settings.input_key}
                            value={currentValue || getSettingOrDefault('default', '')}
                            placeholder={getSettingOrDefault('placeholder', 'Enter text')}
                            onChange={handleChange}
                            disabled={isPreviewMode}
                            required={settings.required} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SimpleTextInput;