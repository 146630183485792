import React from 'react'
import moment from "moment";
import TextInput from "react-autocomplete-input";
import { getLabelByName } from "../../../helpers/helperFunctions";
import {
  COMMENTS,
  POST,
  POSTING,
} from "../../../helpers/constants";


export const ActionPreviewConversation = ({getContributionsCommentsData, commentButtonLoading, participant, replyComment, handleReplyChange, postComment, clubMembersNames}) => {

  const postNewComment = () => {
    replyComment();
    handleReplyChange("");
  }

    return (
        <div className="comments-section">
            <div className="comment-section-header">
              <h1>{getLabelByName(COMMENTS)}</h1>
              <p>
                {`This contribution has ${getContributionsCommentsData?.data?.length} comments activities`}
              </p>
            </div>
            <div className="comment-section-content">
              {getContributionsCommentsData?.data?.length >
                0 &&
                getContributionsCommentsData.data.map(
                  (comment) => (
                    <>
                      <div className="one-comment">
                        <div className="comment-content-title">
                          <img
                            src={
                              comment.author.profile.picture
                            }
                            alt=""
                          />
                          <div className="contribution-name">
                            <h3>{`${comment.author.profile.firstName} ${comment.author.profile.lastName}`}</h3>
                            <p>
                              {moment(
                                comment.dateCreated * 1000,
                              ).format(
                                "DD MMM YYYY, HH:MM",
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="comment-content-body">
                          <p>{comment.content.body}</p>
                        </div>
                      </div>
                    </>
                  ),
                )}
              <div className="contribution-comment-input-section">
                <div className="comment-author">
                  <img
                    src={participant.user.profile.picture}
                    alt=""
                  />
                </div>
                <div className="comment-author-input">
                  <TextInput
                    maxOptions={0}
                    options={clubMembersNames}
                    placeholder="Add a comment"
                    Component="input"
                    onChange={(value) => handleReplyChange(value)}
                    value={postComment}
                  />
                </div>
              </div>
              <div className="reply-button">
                <button
                  type="button"
                  onClick={postNewComment}>
                  {commentButtonLoading
                    ? `${getLabelByName(POSTING)}...`
                    : getLabelByName(POST)}
                </button>
              </div>
            </div>
          </div>
    )
}