/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { ArrowBackIos } from "@material-ui/icons";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import Navbar from "../Navbar";
import Footer from "../Footer";
import ReactionsModal from "../ReactionsModal";
import { getSessionAction } from "../../redux/actions/authActions";
import { getPost } from "../../redux/actions/postActions";
import {
  checkSession,
  renderOGMetaTags,
  getOGMetaTagsPosts,
  isSessionActive,
  getLabelByName,
  isActionSuccess,
  isActionError,
  getProfileDataFromLocalStorage,
  getAllClubsFromLocalStorage,
} from "../../helpers/helperFunctions";
import FeedPost from "../FeedPost";
import RedirectToAuth from "../generics/RedirectToAuth";
import { BACK } from "../../helpers/constants";
import PromotionTemplate from "../generics/PromotionTemplate";
import Article from "../Article";
import Lesson from "../Lesson";

class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: `${
        process.env.REACT_APP_APPLICATION_NAME ||
        "The Alma Maters Club"
      } - Post`,
      profileData: getProfileDataFromLocalStorage(),
      clubsData: getAllClubsFromLocalStorage(),
      selectedReactions: null,
      pathname: this.props.location.pathname,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { selectedReactions, pathname } = this.state;
    // const commentHash = window.location.hash.slice(1);

    return (
      <>
        {renderOGMetaTags(this.generateOGMetaTagsPost())}
        <Navbar pathname="explore" />
        {this.dataHasLoaded(this.props.getPostData) &&
          this.props.getPostData.data.type ===
            "ARTICLE" && (
            <Article
              {...this.props}
              getPostData={this.props.getPostData}
              // commentHash={commentHash}
            />
          )}

        {this.dataHasLoaded(this.props.getPostData) &&
          this.props.getPostData.data.type === "LESSON" && (
            <Lesson
              {...this.props}
              getPostData={this.props.getPostData}
              // commentHash={commentHash}
            />
          )}

        {this.dataHasLoaded(this.props.getPostData) &&
          this.props.getPostData.data.type !==
            "ARTICLE" && (
            <div className="club-page">
              <div className="container">
                <div className="buttons-row mb-3">
                  <div id="back-link">
                    <div
                      className="d-flex justify-content-center align-items-center"
                      onClick={() =>
                        this.props.history.goBack()
                      }
                      style={{ cursor: "pointer" }}>
                      <ArrowBackIos className="arrow-back-icon" />
                      {"  "}
                      {getLabelByName(BACK)}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="feed-card">
                    {this.dataHasLoaded(
                      this.props.getPostData,
                    ) &&
                      isSessionActive(this.props.data) && (
                        <>
                          <div className="feed-card-header">
                            <div className="feed-card-header-title">
                              {this.getClub().name}
                            </div>
                          </div>
                          <div className="feed-section">
                            {this.getPost()}
                          </div>
                        </>
                      )}
                    {this.dataHasLoaded(this.props.data) &&
                      !isSessionActive(this.props.data) && (
                        <RedirectToAuth
                          pathname={pathname}
                        />
                      )}
                  </div>
                </div>

                {/* Reactions Modal */}
                <ReactionsModal
                  reactions={selectedReactions}
                />
              </div>
              <div className="footer-block">
                <div className="footer-component">
                  <Footer />
                </div>
              </div>
            </div>
          )}
      </>
    );
  }

  async UNSAFE_componentWillMount() {
    const { props } = this;
    await props.getSessionAction();
    await this.fetchData();

    // const commentHash = window.location.hash.slice(1);
    // if (commentHash) this.setState({ commentHash });
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, status } = nextProps;
    checkSession(
      data,
      status,
      this.state.pathname,
      nextProps,
    );

    if (
      isActionSuccess(
        nextProps,
        "createPostStatus",
        "createPostData",
      ) ||
      isActionSuccess(
        nextProps,
        "postReactionStatus",
        "postReactionData",
      ) ||
      isActionSuccess(
        nextProps,
        "deleteReactionStatus",
        "deleteReactionData",
      )
    ) {
      this.fetchData();
    }

    if (
      isActionError(
        nextProps,
        "createPostStatus",
        "createPostData",
      ) ||
      isActionError(
        nextProps,
        "postReactionStatus",
        "postReactionData",
      ) ||
      isActionError(
        nextProps,
        "deleteReactionStatus",
        "deleteReactionData",
      )
    ) {
      toast.error("Error Posting! Try again!");
    }
  }

  fetchData = async () => {
    const { props } = this;
    const { clubId, postId, postType } = props.match.params;
    await props.getPost(clubId, postId);
  };

  dataHasLoaded = (state) => !!(state && state.data);

  getClub = () => {
    if (!this.dataHasLoaded(this.props.getPostData)) {
      return null;
    }

    const postItem = this.props.getPostData.data;
    const { clubsData } = this.state;
    const postClub =
      clubsData !== null &&
      clubsData.filter(
        (club) => club.id === postItem.clubId.toString(),
      )[0];

    return postClub;
  };

  getPost = () => {
    if (!this.dataHasLoaded(this.props.getPostData)) {
      return null;
    }
    const { postId } = this.props.match.params;

    if (this.props.getPostData.data.type === "PROMO") {
      return (
        <PromotionTemplate
          postItem={this.props.getPostData.data}
          profileData={this.state.profileData}
          updateSelectedReactions={
            this.updateSelectedReactions
          }
          openComments={postId}
          handleLinkItemCountUpdate={() => {}}
          // commentHash={this.state.commentHash}
        />
      );
    }
    if (this.props.getPostData.data.type === "POST") {
      return (
        <FeedPost
          postItem={this.props.getPostData.data}
          updateSelectedReactions={
            this.updateSelectedReactions
          }
          openComments={postId}
          // commentHash={this.state.commentHash}
        />
      );
    }
  };

  updateSelectedReactions = (reactions) => {
    const selectedReactions =
      reactions && Object.values(reactions).length > 0
        ? Object.values(reactions)
        : null;
    this.setState({ selectedReactions });
  };

  generateOGMetaTagsPost = () => {
    let tags = {};
    if (this.dataHasLoaded(this.props.getPostData)) {
      tags = getOGMetaTagsPosts(
        this.props.getPostData.data,
        this.getClub(),
      );
    }
    return tags;
  };
}

Post.propTypes = {
  getPost: PropTypes.func,
  getSessionAction: PropTypes.func,
  match: PropTypes.any,
  history: PropTypes.any,
  location: PropTypes.any,
  data: PropTypes.any,
  getPostData: PropTypes.any,
  landingData: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,

  getPostData: state.getPostReducer.getPostData,
  getPostDataError: state.getPostReducer.getPostDataError,
  getPostStatus: state.getPostReducer.getPostStatus,

  createPostData: state.createPostReducer.createPostData,
  createPostDataError:
    state.createPostReducer.createPostDataError,
  createPostStatus:
    state.createPostReducer.createPostStatus,

  postReactionDataError:
    state.postReactionReducer.postReactionDataError,
  postReactionData:
    state.postReactionReducer.postReactionData,
  postReactionStatus:
    state.postReactionReducer.postReactionStatus,
  deleteReactionDataError:
    state.deleteReactionReducer.deleteReactionDataError,
  deleteReactionData:
    state.deleteReactionReducer.deleteReactionData,
  deleteReactionStatus:
    state.deleteReactionReducer.deleteReactionStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getSessionAction,
    getPost,
  }),
)(Post);
