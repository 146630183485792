import {
  CLONE_OUTPUT_SUCCESS,
  CLONE_OUTPUT_ERROR,
} from "../actions/types";

const initialState = {
  cloneOutputData: null,
  cloneOutputDataError: null,
  cloneOutputStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLONE_OUTPUT_SUCCESS:
      return {
        ...state,
        cloneOutputData: action.payload,
        cloneOutputDataError: null,
        cloneOutputStatus: "success",
      };
    case CLONE_OUTPUT_ERROR:
      return {
        ...state,
        cloneOutputDataError: action.payload,
        cloneOutputData: null,
        cloneOutputStatus: "error",
      };
    default:
      return {
        ...state,
        cloneOutputStatus: null,
      };
  }
};
