import React, {useState, useEffect} from 'react'
import {GlossaryCard} from './GlossaryCard'

export const GlossaryMain = ({headerLabel, textLabel, handleChange, prevData}) => {

  const [glossaryState, setGlossaryState] = useState(() => ({
    display: prevData?.display ?? true, 
    text: prevData?.text,
    title: prevData?.title
  }))

  const updateGlossary = (update) => {
    const newState = {...glossaryState, ...update}
    setGlossaryState(newState)
    handleChange({
        target: {
            value: JSON.stringify(newState),
            name: "main"
        }
    })
  }

  useEffect(() => setGlossaryState(prevData), [prevData?.display, prevData?.text, prevData?.title])

  return (
    <GlossaryCard
        updateGlossary={updateGlossary}
        data={glossaryState}
        headerLabel={headerLabel}
        textLabel={textLabel}
    />
  )
};
