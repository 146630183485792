import {
  LANDING_SUCCESS,
  LANDING_ERROR,
  LANDING_LOADING,
} from '../actions/types';

const initialState = {
  landingData: null,
  landingDataError: null,
  landingDataLoading: false,
  landingStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LANDING_SUCCESS:
      return {
        ...state,
        landingData: action.payload,
        landingDataError: null,
        landingStatus: 'success',
        landingDataLoading: false,
      };
    case LANDING_ERROR:
      return {
        ...state,
        landingDataError: action.payload,
        landingData: null,
        landingStatus: 'error',
        landingDataLoading: false,
      };
    case LANDING_LOADING:
      return {
        ...state,
        landingDataError: null,
        landingData: null,
        landingStatus: 'loading',
        landingDataLoading: true,
      };
    default:
      return {
        ...state,
        landingStatus: null,
      };
  }
};
