import React, {useState} from "react";
import attachIcon from '../../../assets/icons/attach-document.svg';
import { getLabelByName } from "../../../helpers/helperFunctions";
import { UPLOAD, UPLOADING} from "../../../helpers/constants";
import UploadDocumentPreview from "../../generics/UploadDocumentPreview"

const DocumentUploadInput = (props) => {
    const { settings, currentValue, participantAction, handleUpdate, isPreviewMode } = props;

    const [isUploading, setIsUploading] = useState(false)
    const [localFiles, setLocalFiles] = useState([])

    const savedFiles = currentValue ? JSON.parse(currentValue) : []

    const multiUploader = async () => {
        setIsUploading(true);
        try {
            const allResults = await Promise.all(
                localFiles.map(async (file) => {
                    const formData = new FormData();
                    formData.append('file', file.fileItem);
                    formData.append('upload_preset', 'qtt2g8ao');
                    const uploadResponse = await fetch('https://api.Cloudinary.com/v1_1/ayudigital/auto/upload', {
                        method: 'POST',
                        body: formData,
                    })
                    return uploadResponse.json()
                })
            )
            console.log("allResults -- ", allResults)
            const secureUrls = allResults.map(result => result.secure_url)
            console.log("secureUrlss -- ", [...secureUrls, ...savedFiles])
            handleUpdate({[settings.input_key]: JSON.stringify([...secureUrls, ...savedFiles])});
        } catch (error) {
            console.log(error)
        } finally {
          setIsUploading(false)
        }
    };

    const handleMultiFileChange = (e) => {
        let lastLocalFilesIndex = localFiles.length > 0 ? localFiles.length - 1 : 0;
        let newFiles = [];
    
        [...e.target.files].forEach((fileItem) => {
            lastLocalFilesIndex += 1;
          newFiles = [
            ...newFiles,
            {
              index: lastLocalFilesIndex,
              fileItem,
            },
          ];
          const fileReader = new FileReader();
          fileReader.readAsDataURL(fileItem);
        });
        setLocalFiles(prevFiles => [
            ...prevFiles,
            ...newFiles
        ])
    };

    const removeLocalFile = (fileToRemove) => {
        const newFiles = localFiles.filter((file) => file.index !== fileToRemove.index);
        setLocalFiles([...newFiles])
    };
    
    const removeUploadedFile = (urlToRemove) => {
        const newSavedFiles = savedFiles.filter((url) => url !== urlToRemove);
        handleUpdate({[settings.input_key]: JSON.stringify([...newSavedFiles])});
    };

    return (<>
        <p>{settings.label}</p>
        <div className="card section-card article-card p-0">
            <div className="card-body">
                <div className="card-details">
                <h5 className="card-title">
                    {settings.label}
                    {' '}
                    {settings.required && (<span className="asterisk">(required)</span>)}
                </h5>
                <br />
                <div className="card section-card image-card">
                    <div
                    className="create-card-image-preview"
                    style={{
                        borderRadius: 'unset',
                        backgroundColor: 'unset',
                        boxShadow: 'unset',
                    }}
                    >
                    <div className="actions-buttons">
                        <div className="action-button">
                        <label htmlFor={`customFileHeader-${settings.input_key}`}>
                            <img
                            src={attachIcon}
                            alt="upload-icon"
                            width="100%"
                            height="100%"
                            />
                        </label>
                        <input
                            type="file"
                            className="custom-file-inputz mb-5"
                            id={`customFileHeader-${settings.input_key}`}
                            onChange={handleMultiFileChange}
                            accept=".pdf, .docx, .doc, .xls, .xlsx, .txt, .odt, .zip, .jpg, .png, .ppt"
                            multiple
                        />
                        </div>
                    </div>
                    </div>
                    <div className="activity-post">
                    <UploadDocumentPreview
                        isUploading={isUploading}
                        existingFiles={savedFiles}
                        files={localFiles}
                        removeFile={removeLocalFile}
                        removeExistingFile={removeUploadedFile}
                    />
                    </div>
                </div>
                <button
                    type="button"
                    className="btn btn-sm submit-btn"
                    disabled={localFiles.length === 0 || isUploading || isPreviewMode}
                    onClick={multiUploader}
                >
                    {!isUploading ? getLabelByName(UPLOAD) : `${getLabelByName(UPLOADING)}...`}
                </button>
                </div>
            </div>
        </div>
    </>)
}

export default DocumentUploadInput;