import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as UploadIcon } from "../../../../assets/file-upload-icon.svg";

const FileUpload = ({ files, handleFileChange }) => {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    handleFileChange(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone(
    {
      onDrop,
      accept: {
        "text/csv": [".csv"],
      },
    },
  );

  return (
    <>
      {files.length > 0 ? (
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-decoration-underline mb-0">
            {files[0]?.name || "Name"}
          </p>

          <button
            type="button"
            onClick={open}
            className="import-again-btn">
            Import again
          </button>
        </div>
      ) : (
        <div
          className="file-upload-wrapper"
          {...getRootProps()}>
          <p>Start user import from CSV</p>
          <div>
            <input {...getInputProps()} />
            Choose the file from which user data is going to
            be imported
            <div className="file-upload-input d-flex align-items-center justify-content-center">
              <div className="text-center">
                <div className="upload-icon-wrapper">
                  <UploadIcon />
                </div>

                <p>Drag and drop a file here</p>

                <p>or</p>

                <button
                  type="button"
                  className="browse-files">
                  Browse Files
                </button>

                <p className="format-msg">
                  Supported file format is CSV
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FileUpload;
