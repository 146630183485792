import React, { Component } from "react";
import PropTypes from "prop-types";
import TextInput from "react-autocomplete-input";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { contributionComment } from "../../redux/actions/contributionsActions";
import { getProfileDataFromLocalStorage } from "../../helpers/helperFunctions";

class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileData: getProfileDataFromLocalStorage(),
      postComment: null,
      clubMembersNames: [],
    };
  }

  UNSAFE_componentWillMount() {
    const { clubMembersData } = this.props;

    const clubMembers = [];

    const clubMembersIndex = {};

    if (clubMembersData?.data) {
      clubMembersData.data.forEach((member) => {
        const memberName = `${member.user.profile.firstName.replace(
          /\s/g,
          "",
        )} ${member.user.profile.lastName.replace(
          /\s/g,
          "",
        )}`;

        if (!clubMembers.includes(memberName)) {
          clubMembers.push(memberName);
          clubMembersIndex[`@${memberName}`] =
            member.user.id;
        }
      });
    }

    this.setState({
      clubMembersNames: clubMembers,
      clubMembersIndex,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { clubMembersData } = nextProps;

    const clubMembers = [];

    const clubMembersIndex = {};

    if (clubMembersData?.data) {
      clubMembersData.data.forEach((member) => {
        const memberName = `${member.user.profile.firstName.replace(
          /\s/g,
          "",
        )} ${member.user.profile.lastName.replace(
          /\s/g,
          "",
        )}`;

        if (!clubMembers.includes(memberName)) {
          clubMembers.push(memberName);
          clubMembersIndex[`@${memberName}`] =
            member.user.id;
        }
      });
    }

    this.setState({
      clubMembersNames: clubMembers,
      clubMembersIndex,
    });
  }

  handleChange = (value) => {
    const newUserIds = [];

    value.split(" ").forEach((word) => {
      const { clubMembersIndex } = this.state;

      if (word && clubMembersIndex[word]) {
        const newId = clubMembersIndex[word];

        const alreadyNewUserId = newUserIds.find(
          (id) => id === newId,
        );

        if (!alreadyNewUserId) {
          newUserIds.push(newId);
        }
      }
    });
    this.setState({
      postComment: value,
      userIds: newUserIds,
    });
  };

  submitComment = async (
    e,
    authorId,
    parentId,
    postType = "comment",
  ) => {
    e.preventDefault();
    const { props, state } = this;
    const { postComment, userIds } = state;
    const { clubId } = props.resource;
    const listImages = {};

    const payload = {
      authorId,
      parentId,
      content: {
        body: postComment,
      },
      images: listImages,
      videos: {},
      files: {},
      status: "APPROVED",
      mentions: userIds,
      visibility: "PUBLIC",
    };

    if (this.props.isContribution) {
      await props.contributionComment(parentId, payload);
    } else {
      await props.createPost(payload, clubId, postType);
    }
    this.setState({ postComment: null });
  };

  render() {
    const { profileData, postComment, clubMembersNames } =
      this.state;
    const { resource, commentType } = this.props;
    return (
      <form
        className="form"
        onSubmit={(e) =>
          this.submitComment(
            e,
            profileData.data.id,
            resource.id,
          )
        }>
        <div className="post-form-row">
          <div className="post-item-author">
            <div className="post-avatar">
              {profileData.data.profile.picture && (
                <img
                  className="img-fluid"
                  src={profileData.data.profile.picture}
                  alt="club-avatar"
                />
              )}
              {!profileData.data.profile.picture && (
                <div className="member-initials">
                  {profileData.data.profile.firstName[0] +
                    profileData.data.profile.lastName[0]}
                </div>
              )}
            </div>
          </div>
          <div className="input-group mb-2">
            <TextInput
              name="postComment"
              onChange={(value) => this.handleChange(value)}
              value={postComment || ""}
              options={clubMembersNames}
              placeholder={`Add a ${commentType.toLowerCase()}`}
            />
          </div>
        </div>
        <div className="post-buttons">
          <div className="cancel-button">
            <button
              type="button"
              className="btn btn-sm submit-btn btn-reverse"
              disabled={false}
              onClick={() =>
                this.setState({ postComment: null })
              }>
              Cancel
            </button>
          </div>
          <div className="post-button">
            <button
              type="submit"
              className="btn btn-sm submit-btn"
              disabled={!postComment}>
              {commentType}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

CommentForm.propTypes = {
  resource: PropTypes.any,
  commentType: PropTypes.string,
  createPost: PropTypes.func,
};

export const mapStateToProps = (state) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    contributionComment,
  }),
)(CommentForm);
