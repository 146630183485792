import React, { useState } from "react";
import { Add as AddIcon } from "@material-ui/icons";
import {
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { getLabelByName } from "../../../../helpers/helperFunctions";
import {
  ADD_LESSON,
  colors,
} from "../../../../helpers/constants";
import Switch from "../../../generics/Switch";
import LessonItem from "./LessonItem";

import "./addCourseLessons.scss";

const DragHandle = sortableHandle(() => (
  <span className="mr-3 text-bold">:::</span>
));

const SortableItem = sortableElement(
  ({ fieldIndex, articles, remove, onLessonChange }) => (
    <div className="d-flex align-items-center mb-3 lesson-wrapper">
      <DragHandle />
      <LessonItem
        fieldIndex={fieldIndex}
        remove={remove}
        articles={articles}
        onLessonChange={onLessonChange}
      />
    </div>
  ),
);

const SortableContainer = sortableContainer(
  ({ children }) => {
    return <div className="mt-5">{children}</div>;
  },
);

const AddCourseLessons = ({
  articles,
  editAction,
  handleChange,
  handleLessonsChange,
  courseState,
  removeLesson,
  addMoreLesson,
  onLessonChange,
}) => {
  const {
    lessons,
    cta_mark_complete,
    cta_mark_incomplete,
    sequential_order,
  } = courseState ?? {};

  return (
    <div className="add-course-lessons">
      <SortableContainer onSortEnd={handleLessonsChange}>
        {lessons.map((lesson, idx) => (
          // <SortableItem
          //   key={name + idx}
          //   fieldIndex={idx}
          //   remove={removeLesson}
          //   articles={articles}
          //   onLessonChange={onLessonChange}
          // />

          <div
            key={JSON.stringify(lesson) + idx}
            className="d-flex align-items-center mb-3 lesson-wrapper">
            <LessonItem
              fieldIndex={idx}
              remove={removeLesson}
              articles={articles}
              articleId={lesson.articleId}
              onLessonChange={onLessonChange}
            />
          </div>
        ))}

        <div className="add-new-action">
          <button type="button" onClick={addMoreLesson}>
            <AddIcon />
            <p>{getLabelByName(ADD_LESSON)}</p>
          </button>
        </div>
      </SortableContainer>

      <div>
        <h5 className="font-weight-bold mb-4">
          Configure Options
        </h5>

        <div className="mb-3 col-md-6 px-0">
          <div className="form-group">
            <label
              className="px-0"
              htmlFor="action-input-name">
              Enter text to mark lesson as complete
            </label>
            <input
              type="text"
              name="cta_mark_complete"
              className="form-control"
              id="action-input-name"
              value={cta_mark_complete}
              onChange={handleChange}
              style={{
                backgroundColor: "#FFFFFF",
              }}
              required
            />
          </div>
        </div>

        <div className="col-md-6 px-0">
          <div className="form-group">
            <label
              className="px-0"
              htmlFor="action-input-name">
              Enter text to mark lesson as incomplete
            </label>
            <input
              type="text"
              name="cta_mark_incomplete"
              className="form-control"
              id="action-input-name"
              value={cta_mark_incomplete}
              onChange={handleChange}
              style={{
                backgroundColor: "#FFFFFF",
              }}
              required
            />
          </div>
        </div>
      </div>

      <div className="d-flex mt-4 mb-4">
        <FormGroup>
          <FormControlLabel
            className="p-0 m-0"
            control={
              <Switch
                name="sequential_order"
                checked={sequential_order}
                onChange={handleChange}
              />
            }
            label={
              <span
                style={{
                  color: colors.textSecondary,
                }}>
                {/* {getLabelByName(REQUIRED)} */}
                Take Lessons in sequential order
              </span>
            }
            labelPlacement="start"
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default AddCourseLessons;
