/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable dot-notation */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { toast } from "react-toastify";

import moment from "moment";
import { getSessionAction } from "../../redux/actions/authActions";
import {
  createPost,
  getNewsFeed,
  postReaction,
  getReactions,
  deleteReaction,
} from "../../redux/actions/postActions";
import { getActivities } from "../../redux/actions/campaignsActions";
import {
  getMostActiveActivities,
  getMostActiveClubs,
  getMostPopularClubs,
} from "../../redux/actions/filterActions";
import { getUserClubs } from "../../redux/actions/institutesActions";

import Footer from "../Footer";
import Navbar from "../Navbar";
import Fallback from "../Fallback";
import FeedPost from "../FeedPost";
import FeedArticle from "../FeedArticle";
import ReactionsModal from "../ReactionsModal";
import {
  getLabelByName,
  getProfileDataFromLocalStorage,
} from "../../helpers/helperFunctions";
import { getOutputs } from "../../redux/actions/contributionsActions";
import OutputsRow from "../OutputsRow";
import PromotedResource from "../PromotedResource";
import {
  ACTIVITY,
  EXPLORE,
  NO_RESULTS_TO_DISPLAY,
} from "../../helpers/constants";
import SideNav from "./SideNav";
import PromotionTemplate from "../generics/PromotionTemplate";
import ClubsImage from "../../assets/manage-clubs.svg";
import MyClubs from "./MyClubs";
import MyActivities from "./MyActivities";
import UserVerificationModal from "../generics/UserVerificationModal"

const ActivityFeed = (props) => {
  const [state, setState] = useState({
    profileData: getProfileDataFromLocalStorage(),
    isLoading: false,
    postComment: {},
    displayComments: [],
    displayPersonalFeed: false,
    selectedFiles: [],
    imagePreview: [],
    currentDisplay: "default",
    selectedReactions: null,
    pathname: props.location.pathname,
    updateLinkItemCount: '',
    openUserVerificationModal: false,
  });

  const fetchData = async () => {
    await props.getUserClubs();
    await props.getOutputs();
    await props.getNewsFeed();
    await props.getActivities();
  };

  useEffect(() => {
    if (
      props.location?.state?.from === "/club/:id/:handle"
    ) {
      // setState((prevState) => ({
      //   ...prevState,
      //   currentDisplay: "my-clubs",
      // }));

      toggleFeed("my-clubs");
    }
  }, [props.location?.state?.from]);

  useEffect(() => {
    props.getSessionAction();

    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    fetchData();

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
  }, []);

  useEffect(() => {
    const {
      createPostStatus,
      createPostData,
      getUserClubsData,
      getUserClubsStatus,
      postReactionData,
      postReactionStatus,
      deleteReactionStatus,
    } = props;

    switch (getUserClubsStatus) {
      case "success":
        switch (
        getUserClubsData.data &&
        getUserClubsData.status
        ) {
          case 200:
            localStorage.setItem(
              "clubsData",
              JSON.stringify(getUserClubsData.data),
            );
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    switch (createPostStatus) {
      case "success":
        switch (createPostData.status) {
          case 200:
            fetchData();

            setState((prevState) => ({
              ...prevState,
              postMessage: "",
              postComment: {},
              selectedFiles: [],
              imagePreview: [],
            }));
            break;
          default:
            toast.error(
              "Posting error!\nPlease try again!",
            );
            break;
        }
        break;
      case "error":
        toast.error("Posting error!\nPlease try again!");
        break;
      default:
        break;
    }

    switch (postReactionStatus) {
      case "success":
        switch (postReactionData.status) {
          case 200:
            fetchData();
            break;
          default:
            toast.error(
              "Posting error!\nPlease try again!",
            );
            break;
        }
        break;
      case "error":
        toast.error("Posting error!\nPlease try again!");
        break;
      default:
        break;
    }

    switch (deleteReactionStatus) {
      case "success":
        fetchData();
        break;
      case "error":
        toast.error("Posting error!\nPlease try again!");
        break;
      default:
        break;
    }
  }, [
    props.status,
    props.data,
    props.createPostStatus,
    props.createPostData,
    props.getUserClubsData,
    props.getUserClubsStatus,
    props.postReactionData,
    props.postReactionStatus,
    props.deleteReactionStatus,
    props.deleteReactionData,
    props.joinClubFeedback,
  ]);

  const handleIfVerified = (Fn) => {
    const userIsVerified = state?.profileData?.data?.meta.hasOwnProperty('verifiedAt')
    if (!userIsVerified) {
      return setState({ ...state, openUserVerificationModal: true })
    }
    Fn()
  }

  const updateSelectedReactions = (reactions) => {
    const selectedReactions =
      reactions && Object.values(reactions).length > 0
        ? Object.values(reactions)
        : null;

    setState((prevState) => ({
      ...prevState,
      selectedReactions,
    }));
  };

  const toggleFeed = async (feedType) => {
    const { displayPersonalFeed } = state;

    setState((prevState) => ({
      ...prevState,
      displayPersonalFeed: true,
    }));

    if (feedType === "all" && displayPersonalFeed) {
      setState((prevState) => ({
        ...prevState,
        currentDisplay: "default",
        displayPersonalFeed: false,
      }));

      await props.getNewsFeed();
    }

    if (feedType === "personal" && !displayPersonalFeed) {
      setState((prevState) => ({
        ...prevState,
        currentDisplay: "default",
        displayPersonalFeed: true,
      }));

      await props.getNewsFeed("?personal=true");
    }

    if (feedType === "my-clubs") {
      setState((prevState) => ({
        ...prevState,
        displayPersonalFeed: false,
        currentDisplay: "my-clubs",
      }));
    }

    if (feedType === "my-activities") {
      setState((prevState) => ({
        ...prevState,
        displayPersonalFeed: false,
        currentDisplay: "my-activities",
      }));
    }

    if (feedType === "most-active-activities") {
      setState((prevState) => ({
        ...prevState,
        displayPersonalFeed: false,
        currentDisplay: "most-active-activities",
      }));

      await props.getMostActiveActivities();
    }

    if (feedType === "most-popular-clubs") {
      setState((prevState) => ({
        ...prevState,
        displayPersonalFeed: false,
        currentDisplay: "most-popular-clubs",
      }));

      await props.getMostPopularClubs();
    }

    if (feedType === "clubs-with-recent-activity") {
      setState((prevState) => ({
        ...prevState,
        displayPersonalFeed: false,
        currentDisplay: "clubs-with-recent-activity",
      }));

      await props.getMostActiveClubs();
    }

    window.scrollTo(0, 0);
  };

  const handleLinkItemCountUpdate = () => {
    setState((prevState) => ({
      ...prevState,
      updateLinkItemCount: !prevState.updateLinkItemCount,
    }));
  };

  const { REACT_APP_APPLICATION_NAME } = process.env;
  document.title = `${REACT_APP_APPLICATION_NAME || "The Alma Maters Club"
    } - ${getLabelByName(ACTIVITY)} - Feed`;
  const { selectedReactions } = state;
  const {
    getNewsFeedData,
    getNewsFeedStatus,
    mostActiveActivities,
    openUserVerificationModal,
  } = props;

  if (getNewsFeedStatus && !getNewsFeedData) {
    return <Fallback route="/home" />;
  }

  if (
    getNewsFeedStatus &&
    getNewsFeedData &&
    getNewsFeedData.status === 500
  ) {
    return <Fallback route="/home" />;
  }
  let displayContent;

  if (state.currentDisplay === "default") {
    displayContent =
      getNewsFeedData &&
      getNewsFeedData.data &&
      getNewsFeedData.data.map((postItem) => {
        if (postItem.type === "PROMO") {
          return (
            <PromotionTemplate
              key={postItem.id}
              handleLinkItemCountUpdate={
                handleLinkItemCountUpdate
              }
              postItem={postItem}
              handleIfVerified={handleIfVerified}
              profileData={state.profileData}
              updateSelectedReactions={
                updateSelectedReactions
              }
            />
          );
        }
        if (postItem.type === "POST") {
          return (
            <FeedPost
              key={postItem.id}
              postItem={postItem}
              handleIfVerified={handleIfVerified}
              updateSelectedReactions={
                updateSelectedReactions
              }
            />
          );
        }
        if (postItem.type === "ARTICLE") {
          return (
            <FeedArticle
              key={postItem.id}
              postItem={postItem}
              handleIfVerified={handleIfVerified}
              updateSelectedReactions={
                updateSelectedReactions
              }
            />
          );
        }
      });
  }
  if (state.currentDisplay === "my-clubs")
    displayContent = <MyClubs />;
  if (state.currentDisplay === "my-activities")
    displayContent = <MyActivities />;
  if (state.currentDisplay === "most-active-activities") {
    displayContent =
      mostActiveActivities?.data?.data?.length > 0 ? (
        mostActiveActivities.data.data.map((activity) => (
          <div className="card activity-card">
            <div className="card-body">
              <div className="activity-content-row">
                <div className="activity-content">
                  <div className="article-banner">
                    <img
                      src={ClubsImage}
                      alt="activity-banner"
                    />
                  </div>
                  <h4>{activity.name}</h4>
                  <small>@{activity.handle}</small>
                  <ul
                    style={{
                      display: "flex",
                      listStyle: "none",
                      padding: 0,
                      fontSize: ".8rem",
                      margin: "8px 0",
                      color: "grey",
                    }}>
                    <li>
                      Start Date:
                      <span
                        style={{
                          padding: "0 8px",
                          color: "#555353",
                        }}>
                        {moment(
                          Number(activity.startDate) * 1000,
                        ).format("DD/MM/YYYY")}
                      </span>
                    </li>
                    <li
                      style={{
                        padding: "0 8px",
                      }}>
                      End Date:
                      <span
                        style={{
                          padding: "0 8px",
                          color: "#555353",
                        }}>
                        {activity.endDate === null
                          ? ""
                          : moment(
                            Number(activity.endDate) *
                            1000,
                          ).format("DD/MM/YYYY")}
                      </span>
                    </li>
                    <li
                      style={{
                        padding: "0 8px",
                      }}>
                      status:
                      <span
                        style={{
                          padding: "0 8px",
                          color: "#555353",
                        }}>
                        {activity.status}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="mt-3">
          <p>{getLabelByName(NO_RESULTS_TO_DISPLAY)}</p>

          <div className="mt-3">
            <button
              type="submit"
              className="btn btn-lg submit-btn">
              <Link
                to="/explore"
                style={{ color: "unset" }}>
                {getLabelByName(EXPLORE)}
              </Link>
            </button>
          </div>
        </div>
      );
  }
  if (state.currentDisplay === "most-popular-clubs") {
    displayContent = mostActiveActivities?.data?.data
      ? mostActiveActivities.data.data.map((activity) => (
        <div className="card activity-card">
          <div className="card-body">
            <div className="activity-content-row">
              <div className="activity-content">
                <div className="article-banner">
                  <img
                    src={
                      activity.profile.picture ===
                        undefined
                        ? ClubsImage
                        : activity.profile.picture
                    }
                    alt="article-banner"
                  />
                </div>
                <h4>{activity.name}</h4>
                <div className="article-description">
                  <Link
                    to=""
                    style={{
                      textDecoration: "none",
                      color: "unset",
                    }}>
                    {activity.profile.description}
                  </Link>
                </div>

                <ul
                  style={{
                    display: "flex",
                    listStyle: "none",
                    padding: 0,
                    fontSize: ".8rem",
                    margin: "8px 0",
                    color: "grey",
                  }}>
                  <li>
                    Posts:
                    <span
                      style={{
                        padding: "0 8px",
                        color: "#555353",
                      }}>
                      {activity.posts}
                    </span>
                  </li>
                  <li
                    style={{
                      padding: "0 8px",
                    }}>
                    Members:
                    <span
                      style={{
                        padding: "0 8px",
                        color: "#555353",
                      }}>
                      {activity.members}
                    </span>
                  </li>
                  <li
                    style={{
                      padding: "0 8px",
                    }}>
                    campaigns:
                    <span
                      style={{
                        padding: "0 8px",
                        color: "#555353",
                      }}>
                      {activity.campaigns}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ))
      : getLabelByName(NO_RESULTS_TO_DISPLAY);
  }
  if (
    state.currentDisplay === "clubs-with-recent-activity"
  ) {
    displayContent = mostActiveActivities?.data?.data
      ? mostActiveActivities.data.data.map((activity) => (
        <div key={activity.id} className="card activity-card">
          <div className="card-body">
            <div className="activity-content-row">
              <div className="activity-content">
                <div className="article-banner">
                  <img
                    style={{ objectFit: "cover" }}
                    src={
                      activity.profile.picture ===
                        undefined
                        ? ClubsImage
                        : activity.profile.picture
                    }
                    alt="article-banner"
                  />
                </div>
                <h4>{activity.name}</h4>
                <div className="article-description">
                  <Link
                    to=""
                    style={{
                      textDecoration: "none",
                      color: "unset",
                    }}>
                    {activity.profile.description}
                  </Link>
                </div>

                <ul
                  style={{
                    display: "flex",
                    listStyle: "none",
                    padding: 0,
                    fontSize: ".8rem",
                    margin: "8px 0",
                    color: "grey",
                  }}>
                  <li>
                    Posts:
                    <span
                      style={{
                        padding: "0 8px",
                        color: "#555353",
                      }}>
                      {activity.posts}
                    </span>
                  </li>
                  <li
                    style={{
                      padding: "0 8px",
                    }}>
                    Members:
                    <span
                      style={{
                        padding: "0 8px",
                        color: "#555353",
                      }}>
                      {activity.members}
                    </span>
                  </li>
                  <li
                    style={{
                      padding: "0 8px",
                    }}>
                    campaigns:
                    <span
                      style={{
                        padding: "0 8px",
                        color: "#555353",
                      }}>
                      {activity.campaigns}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ))
      : getLabelByName(NO_RESULTS_TO_DISPLAY);
  }
  const { updateLinkItemCount } = state;

  return (
    <>
      <Navbar
        pathname="home"
        updateLinkItemCount={updateLinkItemCount}
        toggleFeed={toggleFeed}
      />

      <div className="club-page feed-page">
        <div className="row">
          <div className="feed-nav">
            <SideNav
              updateLinkItemCount={updateLinkItemCount}
              toggleFeed={toggleFeed}
            />
          </div>

          <div className="feed-main">
            <div className="container-fluid">
              <PromotedResource
                outputsData={props.getOutputsData?.data?.filter(
                  (output) => output?.meta?.promoted,
                )}
                activitiesData={props.getActivitiesData?.data?.filter(
                  (output) => output?.meta?.promoted,
                )}
                clubsData={props.getUserClubsData?.data?.filter(
                  (output) => output?.meta?.promoted,
                )}
                postsData={props.getNewsFeedData?.data?.filter(
                  (output) => output?.meta?.promoted,
                )}
              />

              {(state.currentDisplay === "default" ||
                (state.currentDisplay === "default" &&
                  state.displayPersonalFeed)) &&
                getNewsFeedData?.data?.length === 0 ? (
                <div className="mt-3">
                  <p>
                    Your activity feed is currently empty.
                    This is either because you are not yet a
                    member of any clubs or there is no
                    activity in the clubs you've joined.
                  </p>

                  <div className="mt-3">
                    <button
                      type="submit"
                      className="btn btn-lg submit-btn">
                      <Link
                        to="/explore"
                        style={{ color: "unset" }}>
                        {getLabelByName(EXPLORE)}
                      </Link>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <div className="feed-card">
                    <div className="feed-section">
                      {displayContent}
                    </div>
                  </div>
                </div>
              )}

              {/* Reactions Modal */}
              <ReactionsModal
                reactions={selectedReactions}
              />
            </div>
          </div>
        </div>
        <div className="footer-block">
          <div className="footer-component">
            <Footer />
          </div>
        </div>
      </div>
      <UserVerificationModal
        isModalOpen={state.openUserVerificationModal}
        toggleModal={() => setState({ ...state, openUserVerificationModal: !state.openUserVerificationModal })}
      />
    </>
  );
};

ActivityFeed.propTypes = {
  getSessionAction: PropTypes.func,
  getNewsFeed: PropTypes.func,
  getUserClubs: PropTypes.func,
  data: PropTypes.object,
  status: PropTypes.string,
  location: PropTypes.any,
  getNewsFeedData: PropTypes.object,
  getNewsFeedStatus: PropTypes.string,
  createPostData: PropTypes.object,
  createPostStatus: PropTypes.string,
  postReactionData: PropTypes.object,
  postReactionStatus: PropTypes.string,
  deleteReactionData: PropTypes.object,
  deleteReactionStatus: PropTypes.string,
  getUserClubsData: PropTypes.any,
  getUserClubsStatus: PropTypes.any,
  getOutputsData: PropTypes.any,
  getMostActiveActivities: PropTypes.any,
  mostActiveActivities: PropTypes.any,
  getMostActiveClubs: PropTypes.any,
  getMostPopularClubs: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,

  landingDataError: state.landingReducer.landingDataError,
  landingData: state.landingReducer.landingData,
  landingStatus: state.landingReducer.landingStatus,
  getNewsFeedDataError:
    state.getNewsFeedReducer.getNewsFeedDataError,
  getNewsFeedData: state.getNewsFeedReducer.getNewsFeedData,
  getNewsFeedStatus:
    state.getNewsFeedReducer.getNewsFeedStatus,

  createPostData: state.createPostReducer.createPostData,
  createPostDataError:
    state.createPostReducer.createPostDataError,
  createPostStatus:
    state.createPostReducer.createPostStatus,

  getActivitiesDataError:
    state.getActivitiesReducer.getActivitiesDataError,
  getActivitiesData:
    state.getActivitiesReducer.getActivitiesData,
  getActivitiesStatus:
    state.getActivitiesReducer.getActivitiesStatus,

  getUserClubsData:
    state.getUserClubsReducer.getUserClubsData,
  getUserClubsDataError:
    state.getUserClubsReducer.getUserClubsDataError,
  getUserClubsStatus:
    state.getUserClubsReducer.getUserClubsStatus,

  postReactionDataError:
    state.postReactionReducer.postReactionDataError,
  postReactionData:
    state.postReactionReducer.postReactionData,
  postReactionStatus:
    state.postReactionReducer.postReactionStatus,
  deleteReactionDataError:
    state.deleteReactionReducer.deleteReactionDataError,
  deleteReactionData:
    state.deleteReactionReducer.deleteReactionData,
  deleteReactionStatus:
    state.deleteReactionReducer.deleteReactionStatus,

  getReactionsData:
    state.getReactionsReducer.getReactionsData,
  getReactionsDataError:
    state.getReactionsReducer.getReactionsDataError,
  getReactionsStatus:
    state.getReactionsReducer.getReactionsStatus,

  getOutputsData: state.getOutputsReducer.getOutputsData,
  getOutputsDataError:
    state.getOutputsReducer.getOutputsDataError,
  getOutputsStatus:
    state.getOutputsReducer.getOutputsStatus,
  mostActiveActivities: state.filtersReducer,
});
export default compose(
  withRouter,
  connect(mapStateToProps, {
    getSessionAction,
    getNewsFeed,
    createPost,
    getUserClubs,
    getActivities,
    postReaction,
    deleteReaction,
    getReactions,
    getOutputs,
    getMostActiveActivities,
    getMostPopularClubs,
    getMostActiveClubs,
  }),
)(ActivityFeed);
