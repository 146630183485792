/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Clear } from '@material-ui/icons';
import { getLabelByName } from '../../../../helpers/helperFunctions';
import { TEXT } from '../../../../helpers/constants';

class Option extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      handleOptionChange, optionIndex, questionIndex, responseIndex, value, removeOption,
    } = this.props;

    return (
      <div className="row">
        <div className="col-md-1">
          {this.props.optionLabel}
        </div>
        <div className="input-group col-md-11 mb-2" style={{ padding: 0 }}>
          <input
            type="text"
            name="title"
            className="form-control"
            id="input-name"
            placeholder={getLabelByName(TEXT)}
            value={value}
            style={{ backgroundColor: '#FFFFFF' }}
            onChange={(e) => handleOptionChange(e.target.value, optionIndex, questionIndex, responseIndex)}
          />
          <div className="input-group-append">
            <button
              id="clear-search-btn"
              className="btn btn-outline-secondary"
              type="button"
              style={{ backgroundColor: '#FFFFFF' }}
              onClick={() => removeOption(questionIndex, responseIndex, optionIndex)}
            >
              <Clear style={{ color: '#C6A177' }} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Option.propTypes = {
  handleOptionChange: PropTypes.func,
  optionIndex: PropTypes.any,
  questionIndex: PropTypes.any,
  responseIndex: PropTypes.number,
  optionLabel: PropTypes.any,
  value: PropTypes.any,
  removeOption: PropTypes.func,
};

export default Option;
