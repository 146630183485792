import {
  CHANGE_USERNAME_ERROR,
  CHANGE_USERNAME_SUCCESS,
  UPLOAD_PICTURE_ERROR,
  UPLOAD_PICTURE_SUCCESS,
} from '../actions/types';

const initialState = {
  userEditData: null,
  userEditDataError: null,
  userEditStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_USERNAME_SUCCESS:
      return {
        ...state,
        userEditData: action.payload,
        userEditDataError: null,
        userEditStatus: 'success',
      };
    case CHANGE_USERNAME_ERROR:
      return {
        ...state,
        userEditDataError: action.payload,
        userEditData: null,
        userEditStatus: 'error',
      };
    case UPLOAD_PICTURE_SUCCESS:
      return {
        ...state,
        userEditData: action.payload,
        userEditDataError: null,
        userEditStatus: 'success',
      };
    case UPLOAD_PICTURE_ERROR:
      return {
        ...state,
        userEditDataError: action.payload,
        userEditData: null,
        userEditStatus: 'error',
      };
    default:
      return {
        ...state,
        userEditStatus: null,
      };
  }
};
