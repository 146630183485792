/* eslint-disable no-nested-ternary */
import "./outputSettings.scss";
import "react-autocomplete-input/dist/bundle.css";
import React, { Component } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import TextInput from "react-autocomplete-input";
import {
  ACTION,
  ACTIVITY,
  outputParams,
} from "../../../../../../helpers/constants";
import { getLabelByName } from "../../../../../../helpers/helperFunctions";
import { getQuestionnaires } from "../../../../../../redux/actions/campaignsActions";

export class outputSettings extends Component {
  state = {
    activities: [],
    questionnaireIds: [],
    optionInputs: [],
    sourceTypeIndex: 0,
  };

  async UNSAFE_componentWillMount() {
    const {
      sources,
      clubActivitiesData,
      addSettingTitle,
      addSettingDescription,
      getQuestionnaires: getQuestionnairesAction,
    } = this.props;

    addSettingTitle();

    addSettingDescription();

    const newActivities = [];

    if (sources.length > 0) {
      const newQuestionnaireIds = [];

      sources.forEach((source) => {
        const sourceActivity =
          clubActivitiesData?.data.find(
            (activityData) =>
              activityData.id === source.activityId,
          );

        const sourceAction = sourceActivity?.actions.find(
          (action) => {
            return action.id === source.actionId;
          },
        );

        newQuestionnaireIds.push(
          sourceAction?.meta.questionnaireId,
        );

        newActivities.push({
          sourceActivity,
          sourceAction,
          sourceName: source.name,
        });
      });

      this.setState({
        questionnaireIds: newQuestionnaireIds,
      });
    }

    this.setState({ activities: newActivities });

    const { questionnaireIds } = this.state;

    const query = `?q={"id":{"_in":[${questionnaireIds}]}}&questions=true`;

    await getQuestionnairesAction(query);
  }

  handleSettingUpdate = (prop, value, index, inputKey) => {
    const { updateSetting } = this.props;

    updateSetting(prop, value, index, inputKey);
  };

  handlePanelChange =
    (panel, source, index) => (event, isExpanded) => {
      const { getQuestionnairesData } = this.props;

      const optionInputs = [];

      if (getQuestionnairesData?.data?.length > 0) {
        getQuestionnairesData.data.forEach(
          (questionnaireData) => {
            if (
              Number(questionnaireData.id) ===
                Number(
                  source.sourceAction?.meta
                    ?.questionnaireId,
                ) &&
              questionnaireData?.questions?.length > 0
            ) {
              questionnaireData.questions.forEach(
                (question) => {
                  if (
                    question.settings &&
                    question.settings !== null
                  ) {
                    if (
                      typeof question.settings === "string"
                    ) {
                      optionInputs.push(
                        JSON.parse(question.settings)
                          .responses,
                      );
                    }
                    optionInputs.push(
                      question.settings.responses,
                    );
                  }
                },
              );
            }
          },
        );
      }

      const newOptionInputs = [
        "participant_firstName",
        "participant_lastName",
        "participant_gender",
        "participant_location_city",
        "participant_location_country",
        "participant_picture",
      ];

      if (optionInputs.length > 0 && optionInputs[0]) {
        optionInputs[0].forEach((option) => {
          newOptionInputs.push(option.input_key);
        });
      }

      this.setState({
        expanded: isExpanded ? panel : false,
        optionInputs: newOptionInputs,
        sourceTypeIndex: index,
      });
    };

  selectedInputKey = (key) => {
    const { payload } = this.props;

    const { sourceTypeIndex } = this.state;

    let currentValue;

    if (payload?.sources[sourceTypeIndex]?.settings) {
      currentValue = Object.entries(
        payload?.sources[sourceTypeIndex]?.settings,
      ).find((setting) => {
        return setting[0] === key;
      });
    }

    return currentValue
      ? { key: currentValue[0], value: currentValue[1] }
      : undefined;
  };

  render() {
    const { expanded, activities, optionInputs } =
      this.state;
    const { payload } = this.props;

    return (
      <div className="output-settings">
        {activities?.length > 0 &&
          activities.map((source, index) => (
            <div
              className="settings-accordion"
              key={activities.indexOf(source)}>
              <Accordion
                className={`${
                  !expanded ? "box-shadow-none" : undefined
                }`}
                expanded={expanded === `panel${index}`}
                onChange={this.handlePanelChange(
                  `panel${index}`,
                  source,
                  index,
                )}>
                <AccordionSummary
                  className="accordion-summary"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header">
                  <Typography className="output-settings-activity">
                    <span className="color-grayish pr-10">
                      {getLabelByName(ACTIVITY)}:
                    </span>
                    {source?.sourceActivity?.name}
                  </Typography>
                  <Typography className="output-settings-action">
                    <span className="color-grayish pr-10">
                      {getLabelByName(ACTION)}:
                    </span>
                    {source?.sourceAction?.name}
                  </Typography>
                </AccordionSummary>

                {outputParams[
                  payload.type
                ].source_settings?.[
                  source?.sourceName
                ]?.map((outputSetting, i) => (
                  <AccordionDetails key={i}>
                    <FormGroup
                      row
                      className="form-group-row">
                      <div className="source-settings-details">
                        <div className="setting-label">
                          <h1>{outputSetting.name}</h1>
                        </div>
                        <TextInput
                          trigger=":"
                          maxOptions={0}
                          Component="input"
                          onChange={(e) => {
                            this.handleSettingUpdate(
                              "value",
                              e,
                              i,
                              outputSetting.key,
                            );
                          }}
                          value={
                            this.selectedInputKey(
                              outputSetting.key,
                            )?.value
                          }
                          options={
                            optionInputs?.length > 0
                              ? optionInputs
                              : []
                          }
                        />
                      </div>
                    </FormGroup>
                  </AccordionDetails>
                ))}
              </Accordion>
            </div>
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getQuestionnairesData:
    state.getQuestionnairesReducer.getQuestionnairesData,
});

const mapDispatchToProps = {
  getQuestionnaires,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(outputSettings);
