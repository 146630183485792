import {
  CHANGE_USERNAME_ERROR,
  CHANGE_USERNAME_SUCCESS,
  UPLOAD_PICTURE_ERROR,
  UPLOAD_PICTURE_SUCCESS,
} from "./types";
import apiCall from "../../helpers/apiCall";
import { getHeaders } from "../../helpers/getHeaders";

const usernameType = (type, payload) => ({
  type,
  payload,
});

export const changeUsername =
  (
    payload,
    headers = getHeaders(payload, "/user/username"),
  ) =>
  (dispatch) => {
    apiCall
      .post("/user/username", payload, { headers })
      .then((response) => {
        dispatch(
          usernameType(
            CHANGE_USERNAME_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(usernameType(CHANGE_USERNAME_ERROR, err));
      });
  };

export const editProfile =
  (
    payload,
    userId,
    headers = getHeaders(payload, `/user/${userId}`),
  ) =>
  async (dispatch) => {
    try {
      const response = await apiCall.put(
        `/user/${userId}`,
        payload,
        { headers },
      );

      dispatch(
        usernameType(
          CHANGE_USERNAME_SUCCESS,
          response.data,
        ),
      );

      return response.data;
    } catch (err) {
      dispatch(usernameType(CHANGE_USERNAME_ERROR, err));
    }
  };

export const uploadPicture =
  (payload, headers = getHeaders(payload, "/uploads")) =>
  (dispatch) => {
    apiCall
      .post("/uploads", payload, { headers })
      .then((response) => {
        dispatch(
          usernameType(
            UPLOAD_PICTURE_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((err) => {
        dispatch(usernameType(UPLOAD_PICTURE_ERROR, err));
      });
  };
