/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import moment from 'moment';
import campaignIconOrange from '../../assets/icons/campaign-dark-orange.svg';
import userIcon from '../../assets/icons/user.svg';
import timeIcon from '../../assets/icons/time.svg';
import likingIcon from '../../assets/reactions/like.svg';
import commentIcon from '../../assets/icons/comment.svg';
import commentIconOrange from '../../assets/icons/comment-orange.svg';
import promotionIcon from '../../assets/promotionIcon.png';

export default class PromoTemplatePost extends Component {
    state={}

    display = () => {
      let sm = '';
      const {
        clubInfo, postItem,
      } = this.props;
      const {
        promoResource,
      } = postItem;
      switch (postItem.meta.promoType) {
        case 'article':
          sm = (
            <div className="custom-promotion-card__bottom">
              {promoResource === null
                ? (
                    <div className="promoTemp promoTemp__article">
                      <div className="custom-promotion-card__content__club-description promoTemp__container">
                        <small>No added resource</small>
                      </div>
                    </div>
                )
                : (
                <div className="promoTemp promoTemp__article">
                    <div
                      className="custom-promotion-card__content__club-description promoTemp__container"
                    >
                  <div>
                  <h6 style={{
                    textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '600px',
                  }}
                  >
                    {promoResource && promoResource.content.title}
                  </h6>
                  <div className="activity-stats justify-content-between flex-column-reverse flex-xl-row">
                  <div className="d-flex align-items-center mt-2">
                    <div className="activity-stat-item">
                    <span>
                      {promoResource && promoResource.author.profile.firstName}
                    </span>
                    </div>
                    <div className="activity-stat-item dropup" id="myDropdown">
                      <div
                        className="dropdown-toggle activity-stat-icon handicon"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src={likingIcon} alt="like-icon" width="25px" />
                      </div>
                      {promoResource && promoResource.reactions && Object.values(promoResource && promoResource.reactions)
                        .map((reaction) => parseInt(reaction.total, 10))
                        .reduce((a, b) => a + b, 0)}
                    </div>
                    <div
                      className="activity-stat-item"
                    >
                      <div className="activity-stat-icon">
                        <img
                          src={commentIcon}
                          alt="comment-icon"
                          width="25px"
                        />
                      </div>
                      {promoResource && promoResource.totalResponses}
                    </div>
                    <div className="activity-stat-item">
                      <div className="activity-stat-icon">
                        <img src={timeIcon} alt="time-icon" width="25px" />
                      </div>
                      {moment(promoResource && promoResource.dateCreated * 1000).format(
                        'DD/MM/YY',
                      ) || 'DD/MM/YY'}
                    </div>
                  </div>
                  <div
                    className="custom-promotion-card__content__calltoaction link"
                    onClick={postItem.content.actionType === 'Join'
                  && !this.props.isMember
                      ? () => this.props.joinHandler()
                      : () => { window.location.href = this.props.getCallToLink(); }}
                  >
                    <Image src={promotionIcon} width={16} />
                    <span style={{ marginLeft: '10px' }}>
                      Promoted
                      {' '}
                      {postItem.meta.promoType}
                    </span>
                  </div>
                  </div>
                  </div>

                    </div>
                </div>
                )}
            </div>
          );
          break;
        case 'activity':
          sm = (
            <div className="promoTemp promoTemp__other">
              {promoResource === null
                ? (
                    <div className="promoTemp__container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <small>No added resource</small>
                    </div>
                )
                : (
                <div className="promoTemp__container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="sm">
                    <h6 style={{ margin: 0, fontWeight: '900' }}>{promoResource && promoResource.name}</h6>
                    <small style={{ color: 'grey' }}>
                    Hosted by
                    {' '}
                    <span style={{ color: 'black' }}>{clubInfo?.name}</span>
                    </small>
                    <br />
                    <div style={{ display: 'flex' }}>
                      <small style={{}}>
                      From:
                      {' '}
                      {promoResource && promoResource.startDate && moment(promoResource && promoResource.startDate * 1000).format('DD/MM/YYYY')}
                      </small>
                      {promoResource && promoResource.endDate && (
                      <small style={{ marginLeft: '10px' }}>
                      To:
                      {' '}
                      {moment(promoResource && promoResource.endDate * 1000).format('DD/MMYYYY')}
                      </small>
                      )}
                    </div>
                </div>
                <div
                  className="custom-promotion-card__content__calltoaction link"
                  onClick={postItem.content.actionType === 'Join'
                && !this.props.isMember
                    ? () => this.props.joinHandler()
                    : () => { window.location.href = this.props.getCallToLink(); }}
                >
                <Image src={promotionIcon} width={16} />
                <span style={{ marginLeft: '10px', height: 'fit-content' }}>
                  Promoted
                  {' '}
                  {postItem.meta.promoType}
                </span>
                </div>
                </div>
                )}

            </div>
          );
          break;

        case 'contribution':
          sm = (
              <div className="promoTemp promoTemp__other">
                  <div className="promoTemp__container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {promoResource === null
                    ? <small>No added resource</small>
                    : (
                    <>
                      <div className="">
                          <h6 style={{ margin: 0, fontWeight: '100' }}>{promoResource && promoResource.data && promoResource.data.author_name}</h6>
                          <small style={{ display: 'flex' }}>Active</small>
                      </div>
                      <div
                        className="custom-promotion-card__content__calltoaction link"
                        onClick={postItem.content.actionType === 'Join'
                        && !this.props.isMember
                          ? () => this.props.joinHandler()
                          : () => { window.location.href = this.props.getCallToLink(); }}
                      >
                      <Image src={promotionIcon} width={16} />
                      <span style={{ marginLeft: '10px', height: 'fit-content' }}>
                        Promoted
                        {' '}
                        {postItem.meta.promoType}
                      </span>
                      </div>
                    </>
                    )}
                  </div>
              </div>
          );
          break;

        default:
          sm = (
            <div className="promoTemp promoTemp__other">
                <div className="promoTemp__container" style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <div className="">
                        <h5 style={{ margin: 0, fontWeight: '900' }}>{clubInfo?.name}</h5>
                        <small style={{ color: 'grey' }}>
                        From:
                        {' '}
                        {moment(clubInfo?.dateCreated).format('DD/MM/YYYY')}
                        </small>
                        <p>
                        <span className="club-summary__container">
                            <img
                              className="stats-icon club-summary__campaign"
                              src={campaignIconOrange}
                              alt="campaign-icon"
                            />
                            {clubInfo?.campaigns}
                        </span>
                        <span className="club-summary__container">
                            <img
                              className="stats-icon club-summary__user"
                              src={userIcon}
                              alt="user-icon"
                            />
                            {clubInfo?.members}
                        </span>
                        </p>
                    </div>
                    <div className="custom-promotion-card__content__calltoaction link" style={{ height: 'fit-content', maxHeight: '20px' }}>
                    <Image src={promotionIcon} width={16} />
                    <span style={{ marginLeft: '10px' }}>
                  Promoted
                  {' '}
                  {postItem.meta.promoType}
                    </span>
                    </div>
                </div>
            </div>
          );
          break;
      }
      return sm;
    };

    render() {
      return (
        <>
            {this.display()}
        </>
      );
    }
}
