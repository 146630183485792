import "./newOutputProfilePage.scss";
import { ArrowBackIos } from "@material-ui/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import {
  FemaleOutlined as FemaleOutlinedIcon,
  LocationOnOutlined as LocationOnOutlinedIcon,
} from "@mui/icons-material";
import Navbar from "../../Navbar";
import {
  getContributions,
  getOutput,
} from "../../../redux/actions/contributionsActions";
import { getSessionAction } from "../../../redux/actions/authActions";
import { getLabelByName, getFormattedSetting, getFormattedContributionSetting } from "../../../helpers/helperFunctions";
import { getLanding } from "../../../redux/actions/landingActions";
import Loading from "../../generics/Loading/Loading";
import {
  DESCRIPTION,
  statuses,
  WHAT_I_CURRENTLY_DO,
} from "../../../helpers/constants";

export class NewOutputProfilePage extends Component {
  state = {};

  async UNSAFE_componentWillMount() {
    const { props } = this;

    const { clubId, outputId } = props.match.params;

    await props.getOutput(clubId, outputId);

    await props.getLanding();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      getContributions: getContributionsAction,
      getOutputData,
      match,
    } = this.props;

    console.log(getContributions, getOutputData);
    const query = `?q={"activityId":${this.getOutputSources(nextProps.getOutputData?.data)
      ?.profile?.activityId
      },\
    "participantId":${match.params.participantId} }`;

    if (getOutputData !== nextProps.getOutputData) {
      await getContributionsAction(query);
    }
  }

  getOutputSources = (output) => output.sources;

  render() {
    const { history, getContributionsData, getOutputData } =
      this.props;

    console.log("Resources", getContributionsData, getOutputData);

    let outputProfile = null;

    if (getContributionsData) {
      outputProfile = getContributionsData.data.find(
        (contribution) =>
          contribution.actionId.toString() ===
          this.getOutputSources(getOutputData?.data)
            ?.profile?.actionId,
      );
    }

    return (
      <>
        <Navbar pathname="manage" />
        <div className="output-profile-page">
          {outputProfile && (
            <>
              <div className="profile-page-header">
                <button
                  type="button"
                  onClick={() => history.goBack()}>
                  <ArrowBackIos className="back-icon" />
                </button>
                <h1>{getFormattedContributionSetting(getOutputData.data.sources.profile.settings.profile_title, outputProfile)}</h1>
              </div>
              <div className="profile-card">
                <div className="profile-information">
                  <img
                    src={getFormattedContributionSetting(getOutputData.data.sources.profile.settings.profile_picture, outputProfile)}
                    alt={getFormattedContributionSetting(getOutputData.data.sources.profile.settings.profile_title, outputProfile)}
                  />
                  <p>{getFormattedContributionSetting(getOutputData.data.sources.profile.settings.caption_1, outputProfile)}</p>
                  <p>{getFormattedContributionSetting(getOutputData.data.sources.profile.settings.caption_2, outputProfile)}</p>
                </div>
                <div className="output-information">
                  <div className="indicators-div">
                    <div className="active">
                      <div className="active-icon" />
                      <p>{statuses[0].text}</p>
                    </div>
                    <div className="gender">
                      <div className="icon">
                        <FemaleOutlinedIcon />
                      </div>
                      <p>{outputProfile.data.gender}</p>
                    </div>
                    <div className="location">
                      <div className="icon">
                        <LocationOnOutlinedIcon />
                      </div>
                      <p>{outputProfile.data.location}</p>
                    </div>
                  </div>
                  <div className="profile-description">
                    <h3>{getFormattedContributionSetting(getOutputData.data.sources.profile.settings.header_1, outputProfile)}</h3>
                    <p>
                      {
                        getFormattedContributionSetting(getOutputData.data.sources.profile.settings.body_1, outputProfile)
                      }
                    </p>
                  </div>
                  <div className="profile-occupation">
                    <h3>{getFormattedContributionSetting(getOutputData.data.sources.profile.settings.header_2, outputProfile)}</h3>
                    <p>{getFormattedContributionSetting(getOutputData.data.sources.profile.settings.body_2, outputProfile)}</p>
                  </div>
                </div>
              </div>
            </>
          )}
          {outputProfile === null && (
            <div className="loading-container">
              <Loading />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getContributionsData:
    state.getContributionsReducer.getContributionsData,
  getContributionsStatus:
    state.getContributionsReducer.getContributionsStatus,
  getOutputData: state.getOutputReducer.getOutputData,
  getOutputStatus: state.getOutputReducer.getOutputStatus,
});

const mapDispatchToProps = {
  getContributions,
  getOutput,
  getSessionAction,
  getLanding,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NewOutputProfilePage);
