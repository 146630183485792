import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import {
    createClub,
    retrieveClub,
    updateClub,
    deleteClub,
    searchClubs,
    searchAdminClubs,
} from '../../redux/actions/clubs/clubActions';

const Clubs = (props) => {
    const { club, clubs } = props;

    useEffect(() => {
        props.retrieveClub(1);
        props.searchClubs({
            name: {
                _like: "Test%"
            }
        });
    }, []);

    useEffect(() => {
        console.log("Club Data", club);
    }, [club]);

    useEffect(() => {
        console.log("Clubs ", clubs);
    }, [clubs]);

    return <>Clubs!</>
}

const mapStateToProps = (state) => ({
    club: state.clubCRUDReducer,
    clubs: state.clubSearchReducer,
})

export default compose(
    withRouter,
    connect(mapStateToProps, {
        createClub,
        retrieveClub,
        updateClub,
        deleteClub,
        searchClubs,
        searchAdminClubs,
    }),
)(Clubs);
