import React, { Component } from "react";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import Navbar from "../../../Navbar";
import PaginationRow from "../../../generics/PaginationRow";
import ResourceTable from "../../../generics/ResourceTable";
import searchOrange from "../../../../assets/icons/search-orange.svg";
import editIcon from "../../../../assets/icons/edit-dark-orange.svg";
import deleteIcon from "../../../../assets/icons/delete-dark-orange.svg";
import {
  getClubArticles,
  deletePost,
} from "../../../../redux/actions/postActions";
import { getSessionAction } from "../../../../redux/actions/authActions";
import {
  checkSession,
  getAllClubsFromLocalStorage,
  getLabelByName,
  getProfileDataFromLocalStorage,
} from "../../../../helpers/helperFunctions";
import {
  ARTICLE,
  AUTHOR_NAME,
  CHANGES_SAVED,
  CLUB,
  CREATED_ON,
  CREATE_NEW,
  LESSON,
  NAME,
  POST,
  POST_IS_DELETED_SUCCESSFULLY,
  SEARCH,
  SETTINGS,
  SHARED,
  STATUS,
  TITLE,
  TYPE,
} from "../../../../helpers/constants";
import {
  Clear,
  FiberManualRecord,
} from "@material-ui/icons";
import DeleteModal from "../../../settings/Settings/DeleteModal";

class ClubArticles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileData: getProfileDataFromLocalStorage(),
      data: [],
      filteredData: [],
      nbPages: 1,
      currentPage: 1,
      pageDivider: 10,
      showDeleteModal: false,
      isButtonLoading: false,
      searchValue: "",
    };
  }

  async UNSAFE_componentWillMount() {
    await this.load();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data: sessionData, status } = nextProps;

    checkSession(
      sessionData,
      status,
      this.state.pathname,
      nextProps,
    );

    if (
      this.props.match.params.entity !==
      nextProps.match.params.entity
    ) {
      await this.load();
    }

    let data = [];

    if (
      this.props.match.params.entity ===
      nextProps.match.params.entity
    ) {
      data = nextProps?.getClubArticlesData?.data;

      this.setState({
        data: data || [],
        filteredData: data || [],
        nbPages: Math.ceil(
          data?.length / this.state.pageDivider,
        ),
      });
    }
  }

  load = async () => {
    const { props, state } = this;
    const { profileData, pathname } = state;
    if (!profileData || profileData.status !== 200) {
      window.location.href = `/login?redirect_to=${pathname}`;
    }
    await props.getSessionAction();
    this.setState({ isLoading: true });
    await this.fetchData();
    this.setState({ isLoading: false });
  };

  fetchData = async () => {
    const { id: clubId } = this.props?.match?.params;

    await this.props.getClubArticles(clubId);
  };

  searchComponent = () => (
    <form className="form" onSubmit={this.searchEntities}>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          id="inputSearch"
          //   placeholder={`${getLabelByName(
          //     SEARCH,
          //   )} ${getLabelByName(
          //     this.props.match.params.entity,
          //   ).toLowerCase()}`}
          name="searchValue"
          value={this.state.searchValue}
          onChange={this.handleChange}
          style={{ height: "32px" }}
        />
        {this.state.searchValue && (
          <div
            className="input-group-append"
            style={{ height: "32px" }}>
            <button
              id="clear-search-btn"
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => this.clearSearch()}>
              <Clear style={{ fontSize: "16px" }} />
            </button>
          </div>
        )}
        <div
          className="input-group-append"
          style={{ height: "32px" }}>
          <button
            // disabled={!this.state.searchValue}
            id="search-btn"
            className="btn btn-outline-secondary"
            type="submit"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img
              src={searchOrange}
              alt="search-icon"
              style={{ width: "12px" }}
            />
          </button>
        </div>
      </div>
    </form>
  );

  handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (value && !checked) {
      this.setState({
        [name]: value,
        enableSubmission: true,
      });
    } else {
      this.setState({
        [name]: null,
      });
    }
    if (checked) {
      this.setState({
        [name]: checked,
        enableSubmission: true,
        triggerCheckbox: true,
      });
    }
    if (checked === false) {
      this.setState({
        triggerCheckbox: true,
        enableSubmission: true,
      });
    }
  };

  searchEntities = (e) => {
    e.preventDefault();
    const { data } = this.state;

    if (!this.state.searchValue) {
      return this.setState({
        filteredData: this.state.data,
      });
    }

    const filteredData = data.filter((d) => {
      return Object.keys(d).some((key) =>
        String(d[key])
          .toLowerCase()
          .includes(this.state.searchValue.toLowerCase()),
      );
    });

    const { pageDivider } = this.state;

    this.setState({
      filteredData,
      currentPage: 1,
      nbPages: Math.ceil(
        filteredData?.length / pageDivider,
      ),
    });
  };

  getTableHeaders = () => ({
    id: "Id",
    picture: "  ",
    title: getLabelByName(TITLE),
    authorName: getLabelByName(AUTHOR_NAME),
    dateCreated: getLabelByName(CREATED_ON),
    // type: getLabelByName(TYPE),
    status: getLabelByName(STATUS),
    // club: getLabelByName(CLUB),
    edit: "  ",
    delete: "  ",
    // display: "  ",
    promote: "  ",
  });

  getTableData = () => {
    const { data, currentPage, pageDivider } = this.state;

    return data
      .slice(
        (currentPage - 1) * pageDivider,
        currentPage * pageDivider,
      )
      .map((item) => this.formatPostRow(item));
  };

  formatPostRow = (post) => {
    const { history } = this.props;

    const getPostType = (type) => {
      if (type === "LESSON") {
        return getLabelByName(LESSON);
      }

      if (type === "ARTICLE") {
        return getLabelByName(ARTICLE);
      }
      return getLabelByName(POST);
    };

    const authorName = `${post.author?.profile?.firstName} ${post.author?.profile?.lastName}`;
    const postType = getPostType(post.type);

    const postTitle = post?.content?.title;
    const postAuthorName = <span>{authorName}</span>;

    const postStatus = (
      <span>
        <FiberManualRecord
          style={{
            color:
              post.status === "APPROVED"
                ? "#10A728"
                : "#FF0D0D",
            width: "15px",
            marginRight: "0.5rem",
          }}
        />
        {post.status}
      </span>
    );

    const postPicture = (
      <img
        className="club-avatar"
        src={
          this.getPostClub(post)?.profile?.picture ||
          require(`../../../../assets/manage-posts.svg`)
        }
        alt="post-avatar"
        style={{
          objectFit: "cover",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          maxHeight: "40px",
        }}
      />
    );

    let editAction = null;
    let displayAction = null;
    let promoteAction = null;

    if (postType === "Article") {
      displayAction = "display";
    }

    if (postType === "Article") {
      promoteAction = (
        <Link
          to="/promote/article"
          className="text-dark"
          onClick={(e) => {
            e.preventDefault();

            history.push("/promote/article", {
              resource: post,
            });
          }}>
          Promote
        </Link>
      );
    }

    if (postType === "Lesson") {
      promoteAction = "promote";
    }

    if (postType === "Article") {
      editAction = (
        <Link
          to={`/club/${this.getPostClub(post)?.id}/${
            this.getPostClub(post)?.handle
          }/article/${post.id}/edit`}>
          <img
            src={editIcon}
            alt="edit-action"
            width="24px"
          />
        </Link>
      );
    }

    if (postType === "Lesson") {
      editAction = (
        <Link
          to={`/club/${this.getPostClub(post)?.id}/${
            this.getPostClub(post)?.handle
          }/lesson/${post.id}/edit`}>
          <img
            src={editIcon}
            alt="edit-action"
            width="24px"
          />
        </Link>
      );
    }

    const deleteAction = (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          this.setShowDeleteModalToTrue(
            this.getPostClub(post)?.id,
            post.id,
            postType,
          );
        }}
        role="button"
        aria-hidden>
        <img
          src={deleteIcon}
          alt="delete-action"
          width="24px"
        />
      </div>
    );

    return {
      id: post.id,
      picture: postPicture,
      // name: postName,
      title: postTitle,
      authorName: postAuthorName,
      dateCreated: this.timestampToDate(post.dateCreated),
      type: postType,
      status: postStatus,
      club: this.getPostClub(post)?.name,
      edit: editAction,
      delete: deleteAction,
      // display: displayAction,
      promote: promoteAction,
    };
  };

  timestampToDate = (timestamp, format = "DD MMM YYYY") => {
    if (!timestamp) {
      return null;
    }
    return moment(timestamp * 1000).format(format);
  };

  setCurrentPage = (currentPage) =>
    this.setState({ currentPage });

  incrementPage = () => {
    let { nbPages, currentPage } = this.state;
    currentPage =
      currentPage === nbPages
        ? currentPage
        : currentPage + 1;
    this.setState({ currentPage });
  };

  decrementPage = () => {
    let { currentPage } = this.state;
    currentPage =
      currentPage === 1 ? currentPage : currentPage - 1;
    this.setState({ currentPage });
  };

  handleDelete = async () => {
    const { clubId, postId, postType } = this.state;
    const response = await this.props.deletePost(
      clubId,
      postId,
      postType.toLowerCase(),
    );

    if (response.status === 200) {
      this.setState({
        showDeleteModal: false,
        isButtonLoading: false,
      });

      await this.load();

      toast.success(
        getLabelByName(POST_IS_DELETED_SUCCESSFULLY),
      );
    }
  };

  setShowDeleteModalToTrue = (clubId, postId, postType) => {
    this.setState({
      showDeleteModal: true,
      clubId,
      postId,
      postType,
    });
  };

  getPostClub = (post) => {
    const clubsData = getAllClubsFromLocalStorage();

    const postClub =
      post?.clubId &&
      clubsData &&
      clubsData.filter(
        (club) => club.id === post.clubId.toString(),
      )[0];

    return postClub;
  };

  render() {
    const { entity } = this.props.match.params;

    const {
      currentPage,
      pageDivider,
      nbPages,
      filteredData,
      showDeleteModal,
      isButtonLoading,
    } = this.state;

    if (entity)
      document.title = `${
        process.env.REACT_APP_APPLICATION_NAME ||
        "The Alma Maters Club"
      } - ${getLabelByName(SETTINGS)} - ${
        entity[0].toUpperCase() + entity.slice(1)
      }`;

    return (
      <>
        {showDeleteModal && (
          <DeleteModal
            showDeleteModal={showDeleteModal}
            closeModal={() =>
              this.setState({
                showDeleteModal: false,
              })
            }
            setButtonLoading={() =>
              this.setState({
                isButtonLoading: true,
              })
            }
            handleDelete={this.handleDelete}
            isButtonLoading={isButtonLoading}
          />
        )}

        <Navbar pathname="manage" />

        <div className="settings-container pt-0">
          <div className="settings-content w-100">
            <div className="settings-content-title d-flex justify-content-between">
              <div>Articles</div>
            </div>

            <div className="settings-content-table">
              <ResourceTable
                headers={this.getTableHeaders()}
                tableData={this.getTableData()}
                tableActions={{
                  title: `${filteredData.length} Articles`,
                  actions: [this.searchComponent()],
                }}
              />

              {filteredData && (
                <PaginationRow
                  incrementPage={this.incrementPage}
                  decrementPage={this.decrementPage}
                  nbPages={nbPages}
                  currentPage={currentPage}
                  dataLength={filteredData.length}
                  pageDivider={pageDivider}
                  setCurrentPage={this.setCurrentPage}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getClubArticlesDataError:
    state.getClubArticlesReducer.getClubArticlesDataError,
  getClubArticlesData:
    state.getClubArticlesReducer.getClubArticlesData,
  getClubArticlesStatus:
    state.getClubArticlesReducer.getClubArticlesStatus,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getSessionAction,
    getClubArticles,
    deletePost,
  }),
)(ClubArticles);
