/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
import {
  ArrowBackIos,
  ArrowForwardIos,
  Clear,
} from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import Navbar from "../../Navbar";
import searchOrange from "../../../assets/icons/search-orange.svg";
import Footer from "../../Footer";
import ResourceCard from "../../generics/ResourceCard";
import { getContributions } from "../../../redux/actions/contributionsActions";
import { getSessionAction } from "../../../redux/actions/authActions";
import {
  checkSession,
  getLabelByName,
  getProfileDataFromLocalStorage,
  isActionSuccess,
} from "../../../helpers/helperFunctions";
import {
  BACK,
  colors,
  CONTRIBUTIONS,
  CREATED_ON,
  NEXT,
  OF_WORD,
  PREVIOUS,
  SHOWING,
  STATUS,
} from "../../../helpers/constants";
import {
  getActivity,
  getParticipantAllActions,
  getQuestionnaires,
} from "../../../redux/actions/campaignsActions";
import ReviewContributionModal from "./ReviewContributionModal";

class ActivityContributions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileData: getProfileDataFromLocalStorage(),
      pageTitle: getLabelByName(CONTRIBUTIONS),
      isLoading: false,
      nbPages: 1,
      searchValue: "",
      displaySearch: false,
      currentPage: 1,
      pageDivider: 12,
      pathname: this.props.location.pathname,
      activityName: null,

      contributionStatuses: {
        PENDING_REVIEW: "Pending Review",
        APPROVED: "Approved",
        REJECTED: "Rejected",
      },
      statusColor: {
        PENDING_REVIEW: colors.primaryColor,
        APPROVED: "#10A728",
        REJECTED: "#FF0D0D",
      },
      selectedContribution: null,
      isModalOpen: false,
      isAddedDiv: false,
    };
  }

  getUserTableHeaders() {
    return {
      name: "Participant",
      action: "Action",
      status: getLabelByName(STATUS),
      dateCreated: getLabelByName(CREATED_ON),
      review: "",
    };
  }

  getTableData() {
    if (!this.feedDataHasLoaded()) {
      return [];
    }

    const {
      currentPage,
      pageDivider,
      contributionStatuses,
      statusColor,
    } = this.state;
    const { id: campaignId } = this.props.match.params;

    const { getActivityData } = this.props;

    // TODO: Check if hard coded info is now available and update
    return this.props.getContributionsData.data
      .slice(
        (currentPage - 1) * pageDivider,
        currentPage * pageDivider,
      )
      .map((contribution) => ({
        ...contribution,
        isAnonymous:
          getActivityData?.data?.settings?.anonymous,
        modalCall: async () => {
          this.setState({
            selectedContribution: contribution,
            isModalOpen: true,
          });
          await this.props.getParticipantAllActions(
            campaignId,
            contribution.participantId,
          );
        },
      }));
  }

  feedDataHasLoaded() {
    return !!(
      this.props.getContributionsData &&
      this.props.getContributionsData.data
    );
  }

  timestampToDate(
    timestamp,
    format = "DD MMM YYYY, hh:mm",
  ) {
    if (!timestamp) {
      return null;
    }
    return moment(timestamp * 1000).format(format);
  }

  getPageRange() {
    const { currentPage, pageDivider } = this.state;
    const first = (currentPage - 1) * pageDivider + 1;
    let last = first + (pageDivider - 1);
    if (
      this.feedDataHasLoaded() &&
      last > this.props.getContributionsData.data.length
    ) {
      last = this.props.getContributionsData.data.length;
    }
    return { first, last };
  }

  getLastListItem() {
    return 10;
  }

  getTotalEntitites() {
    if (!this.feedDataHasLoaded()) {
      return 0;
    }
    return this.props.getContributionsData.data.length;
  }

  incrementPage = () => {
    let { nbPages, currentPage } = this.state;
    currentPage =
      currentPage === nbPages
        ? currentPage
        : currentPage + 1;
    this.setState({ currentPage });
  };

  decrementPage = () => {
    let { currentPage } = this.state;
    currentPage =
      currentPage === 1 ? currentPage : currentPage - 1;
    this.setState({ currentPage });
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  render() {
    document.title = this.state.pageTitle;

    const { id, handle } = this.props.match.params;

    const { isAddedDiv } = this.state;

    return (
      <>
        <Navbar pathname="profile" />
        <div className="manage-clubs-page">
          <div className="container-fluid">
            <div className="buttons-row">
              <div id="back-link">
                <div className="d-flex justify-content-center align-items-center">
                  <Link
                    to={`/manage/activities/${id}/${handle}`}
                    style={{
                      color: colors.textPrimary,
                      font: "Book 20px/17px Avenir",
                    }}>
                    <ArrowBackIos className="arrow-back-icon" />
                    {`  ${getLabelByName(BACK)}`}
                  </Link>
                </div>
              </div>
            </div>

            <div className="manage-clubs-content">
              <div className="clubs-details">
                <div className="clubs-title">
                  {this.state.activityName &&
                    `${this.state.activityName}: `}
                  {this.state.pageTitle}
                </div>
                <div className="clubs-stats">
                  <div className="clubs-page-indicator">
                    {getLabelByName(SHOWING)}
                    <span className="font-weight-bold pl-1 pr-1">
                      {this.getPageRange().first}-
                      {this.getPageRange().last}
                    </span>
                    {`  ${getLabelByName(OF_WORD)}  `}
                    <span className="font-weight-bold pl-1 pr-1">
                      {this.getTotalEntitites()}
                    </span>
                    {this.state.pageTitle}
                  </div>
                  <div className="search-clubs">
                    <form
                      className="form"
                      onSubmit={this.searchCampaigns}>
                      <div className="input-group mb-2">
                        <input
                          type="text"
                          className="form-control"
                          id="inputSearch"
                          placeholder={`Search ${this.state.pageTitle}`}
                          name="searchValue"
                          value={this.state.searchValue}
                        />
                        {this.state.displaySearch && (
                          <div className="input-group-append">
                            <button
                              id="clear-search-btn"
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={() =>
                                this.clearSearch()
                              }>
                              <Clear />
                            </button>
                          </div>
                        )}
                        <div className="input-group-append">
                          <button
                            id="search-btn"
                            className="btn btn-outline-secondary"
                            type="submit">
                            <img
                              src={searchOrange}
                              alt="search-icon"
                              style={{ width: "20px" }}
                            />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="clubs-table table-responsive">
                <ResourceCard
                  contributions={this.getTableData()}
                  setIsAddedDiv={(value) =>
                    this.setState({ isAddedDiv: value })
                  }
                  isAddedDiv={isAddedDiv}
                />
                {isAddedDiv && (
                  <div className="added-div" />
                )}
                <table className="table table-hover" />
                <div className="navigation-buttons">
                  <button
                    type="button"
                    className="btn btn-sm prev-btn"
                    disabled={this.state.currentPage === 1}
                    onClick={() => this.decrementPage()}>
                    <ArrowBackIos className="pagination-icon" />
                    {getLabelByName(PREVIOUS)}
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm next-btn"
                    disabled={
                      this.state.currentPage ===
                      this.state.nbPages
                    }
                    onClick={() => this.incrementPage()}>
                    {getLabelByName(NEXT)}
                    <ArrowForwardIos className="pagination-icon pl-1" />
                  </button>
                  <div className="page-indicator">
                    Page{" "}
                    <span className="current-page">
                      {this.state.currentPage}
                    </span>
                    {" / "}
                    {this.state.nbPages}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Reviw Contribution Modal */}
        <ReviewContributionModal
          contribution={this.state.selectedContribution}
          isModalOpen={this.state.isModalOpen}
          toggleModal={this.toggleModal}
        />

        <div className="footer-block">
          <div className="footer-component">
            <Footer />
          </div>
        </div>
      </>
    );
  }

  async UNSAFE_componentWillMount() {
    const { props, state } = this;
    const { profileData, pathname } = state;
    const { id: activityId } = props.match.params;
    const query = `?q={"activityId":${activityId}}`;
    if (!profileData || profileData.status !== 200) {
      window.location.href = `/login?redirect_to=${pathname}`;
    }
    await this.props.getSessionAction();
    await this.props.getActivity(activityId);
    await this.props.getContributions(query);
    await props.getQuestionnaires();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { pageDivider, pathname } = this.state;
    const {
      getContributionsData,
      data,
      status,
    } = nextProps;

    checkSession(data, status, pathname, this.props);

    if (getContributionsData && getContributionsData.data) {
      this.setState({
        nbPages: Math.ceil(
          getContributionsData.data.length / pageDivider,
        ),
      });
    }

    if (
      isActionSuccess(
        nextProps,
        "getActivityStatus",
        "getActivityData",
      )
    ) {
      this.setState({
        activityName: nextProps.getActivityData.data?.name,
      });
    }
  }
}

ActivityContributions.propTypes = {
  getContributions: PropTypes.func,
  getSessionAction: PropTypes.func,
  getActivity: PropTypes.func,
  getParticipantAllActions: PropTypes.func,
  getQuestionnaires: PropTypes.func,
  getContributionsData: PropTypes.any,
  data: PropTypes.any,
  status: PropTypes.any,
  history: PropTypes.any,
  location: PropTypes.any,
};

const mapStateToProps = (state) => ({
  getContributionsDataError:
    state.getContributionsReducer.getContributionsDataError,
  getContributionsData:
    state.getContributionsReducer.getContributionsData,
  getContributionsStatus:
    state.getContributionsReducer.getContributionsStatus,

  getActivityDataError:
    state.getActivityReducer.getActivityDataError,
  getActivityData: state.getActivityReducer.getActivityData,
  getActivityStatus:
    state.getActivityReducer.getActivityStatus,

  dataError: state.authReducer.dataError,
  data: state.authReducer.data,
  status: state.authReducer.status,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getSessionAction,
    getContributions,
    getActivity,
    getParticipantAllActions,
    getQuestionnaires,
  }),
)(ActivityContributions);
