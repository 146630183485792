/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable global-require */
import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Button } from "@material-ui/core";
import {
  CLOSE,
  COPIED,
  COPY_LINK,
  DOWNLOAD,
  fileIconsMapping,
  TYPE,
  UPLOADED,
} from "../../../../helpers/constants";
import {
  getFormattedSetting,
  getLabelByName,
} from "../../../../helpers/helperFunctions";

class ItemDescriptionModal extends Component {
  state = {
    linkCopied: false,
    contribution: this.props.contribution,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ contribution: nextProps.contribution });
  }

  copyLink = (url) => {
    navigator.clipboard.writeText(url);
    this.setState({ linkCopied: true });
    setTimeout(() => {
      this.setState({ linkCopied: false });
    }, 3000);
  };

  getUrl = () => {
    let url = '';
    try {
      url = JSON.parse(getFormattedSetting(
        this.props.sources.listings.settings.listing_image,
        this.props.contribution.data
      ))[0];
    } catch (error) {
      console.error('error', error);
    }

    return url;
  }

  render() {
    const { contribution, settings } = this.props;
    const { linkCopied } = this.state;

    return (
      <div
        className="modal fade item-description-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="itemDescriptionModal"
        aria-hidden="true"
        id="itemDescriptionModal"
      >
        <div
          className="modal-dialog modal-md modal-dialog-centered"
          role="document"
        >
          <div className="modal-content modal-card">
            <div className="item-description__title">
              {contribution &&
                getFormattedSetting(
                  this.props.sources.listings.settings.listing_title,
                  contribution.data,
                )}
            </div>
            <div className="item-description__date">
              {getLabelByName(UPLOADED)}{" "}
              {moment(contribution?.dateCreated * 1000).format("DD MMMM YYYY")}
            </div>
            <div className="item-description__description">
              {contribution &&
                getFormattedSetting(
                  this.props.sources.listings.settings.listing_subtitle,
                  contribution?.data
                )}
            </div>
            <div className="item-description__type">
              <span className="item-description__type__title">
                {getLabelByName(TYPE)}
                {": "}
              </span>
              <img
                src={fileIconsMapping["Images"]}
                alt="document-cover"
              />{" "}
              {contribution?.data.file_type}
            </div>

            <div className="item-description__action-buttons">
              <a href={this.getUrl()} download target={`_blank`}>
                <button type="button">{getLabelByName(DOWNLOAD)}</button>
              </a>
              <button
                type="button"
                onClick={() => this.copyLink(this.getUrl())}
              >
                {linkCopied
                  ? getLabelByName(COPIED)
                  : getLabelByName(COPY_LINK)}
              </button>
            </div>

            <hr className="item-description__hr" />

            <div className="item-description__button-close">
              <Button
                color="primary"
                data-dismiss="modal"
                aria-label="Close"
                style={{ textTransform: "none" }}
              >
                {getLabelByName(CLOSE)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ItemDescriptionModal.propTypes = {
  contribution: PropTypes.any,
};

export default ItemDescriptionModal;
